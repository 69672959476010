import React, { Component } from "react";

import styles from '../../../../assets/scss/modules/profilpage.module.scss';
import SonstigeNavB2B from '../../../../components/NavbarComponentB2B/SonstigeNavB2B';
import ProfilpageNavB2B from '../../../../components/NavbarComponentB2B/ProfilpageNavB2B';
import BikeAnzeigeKarteEintraegeB2B from '../../../../components/Companys/BikeAnzeigeKarteEintraegeB2B';
import { Scrollbar } from "react-scrollbars-custom";
import Cookies from 'universal-cookie';
import MenuSideNavB2BikeMain from '../MainPageHandler/MenuSideNavB2BikeMain';
import LandingPageB2BikeNavRespoUser from '../../../../components/NavbarComponentB2B/LandingPageB2BikeNavRespoUser';
const cookies = new Cookies();


import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
	MDBSideNav
} from 'mdbreact';

import InterB2BAxios from '../../../../Service/InterB2BAxios';
let language = '';
class EintraegeB2BResponziv extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {myProfileStatus: true,
									showArticle: false};
  };

	componentDidMount() {
		this.props.addFunctionFromChild(this.onMessage);
		var params = window.location.href.split(".");
	  language = params[params.length - 1];
		this.onGetBikesWithUserId();
	};

	componentWillUnmount() {
		this.props.removeFunctionFromChild(this.onMessage);
		this.interAxios.cancel();
  }

	onMessage = (message) => {
    let type = message.type;
		if (type === 'NEWEBIKEPRODUCT'){
	    this.onGetBikesWithUserId();
	  }
  };

	onGetBikesWithUserId = () => {
		let self = this;
		this.interAxios.get('/api/allBikeProductWithUserId/', function(response) {
			self.setState({bikeslist: response.data});
		})
	};

  render() {
    return (
			<>
				<div ref={this.listInnerRefInterB2B}/>
				<MDBContainer fluid className="navbarcomponentRespo-nav-container-b2b">
					<div className={styles.bodywrapper}>
						<div className="side-nav-menu-b2b">
							<MDBSideNav
									right
									triggerOpening={this.props.isSideNavOpen}
									onOverlayClick={this.props.handleSideNavToggle}
									breakWidth={1300}
									className="deep-purple darken-4"
							>
								<MenuSideNavB2BikeMain
										userData={this.props.userData}
										toggle = {this.toggle}
										onMenuClose = {this.props.onEnableScroll}
										onItemClose = {this.props.onEnableScrollMain}
										activeItem = {1}
								/>
							</MDBSideNav>
						</div>
						<LandingPageB2BikeNavRespoUser  activeItem = {"4"}
																				 userData={this.props.userData}
																				 onMenuOpen = {this.props.handleSideNavToggle}
																				 onRefInterB2B={this.onRefInterB2B}/>
						<MDBRow className="pt-4 m-0 addbike-row-bottom-2">
							<MDBCol className="px-1" size="12">
								<MDBRow className="m-0 eigene-padding-bottom">
								{this.state.bikeslist?.length > 0 ?
										<>
										{this.state.bikeslist.map(
										(o, index) =>
										<MDBCol md="3" size="6" lg="4" className="mb-0 px-2 py-1">
														<BikeAnzeigeKarteEintraegeB2B
														 key={index}
														 userData={this.props.userData}
														 msg={o}/>
										</MDBCol>
									)}
									 </>:false}
							  </MDBRow>
						  </MDBCol>
						</MDBRow>
					</div>
				</MDBContainer>
			</>

    );
  }
}

export default EintraegeB2BResponziv;
