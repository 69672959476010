import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import logo from "../../../assets/media/logo/1.png";
import profile_Boda from "../../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../../assets/media/images/b2b/Group 8.png";
import rectangle_1 from "../../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../../assets/media/images/b2b/Interb2b.png";

import InterB2BAxios from '../../../Service/InterB2BAxios';
import FooterB2BLanding from '../../../components/Footer/FooterB2BLanding';


import styles from '../../../assets/scss/modules/landingB2b.module.scss';
import Cookies from 'universal-cookie';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
class LandingPageB2BikeDesktop extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefInterB2B = React.createRef();
		this.listInnerRefVideos = React.createRef();
		this.listInnerRefInfo = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'LandingPageB2Bike');
    this.state = {
								  activeItem: "1",
									activeVideoItem: "1",
									navbarPaddingTop: true,
									navbarPaddingMiddle: false,
									navbarPaddingBottom: false,
									loggedIn: false
									};
  };





	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
			var params = window.location.href.split(".");
	    language = params[params.length - 1];
			document.addEventListener("scroll", this.scrollEventListener);

	}

	componentWillUnmount() {
		this.interAxios.cancel();
		document.removeEventListener("scroll", this.scrollEventListener);
	}

	toggle = tab => e => {
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
					if (tab == 4) {
						//this.onLoginFormSubmitAsGast();
					}
					if (tab == 3) {
						fullpage_api.moveTo(3);
					}
					if (tab == 2) {
						fullpage_api.moveTo(2);
					}
					if (tab == 1) {
						fullpage_api.moveTo(1);
					}
				}

	}

	onEmailChange = (event) =>{
    this.setState({email: event.target.value});
  };

  onSubmit = (event) =>{
    event.preventDefault();
    let self = this;
    this.interAxios.post('/api/email/informations', {email: this.state.email}, function(response) {
     })
  };

	onRefVideos = () => {
		this.listInnerRefVideos.current.scrollIntoView({
 	 });
	}

	onRefInterB2B = () => {
		this.listInnerRefInterB2B.current.scrollIntoView({
	 });
	}

	onRefInfo = () => {
		this.listInnerRefInfo.current.scrollIntoView({
	 });
	}

	/*x = (a,b) => {
		const navPadding = b.scrollTop < 50 ? true : false;
		const tabscroll = b.scrollTop < 300 ? "1" : b.scrollTop < 1050 ? "2" : "3";
		this.setState({ navbarPaddingTop: navPadding,  activeItem: tabscroll});
	}*/

  render() {
		const { t } = this.props;
    const client = this.props.client;
		const anchors = ["InterB2Bike", "Markt", "Contacts"];
		let innerDiv = <div className="navlink-search2 text-right">
				<MDBBtn
					className="signin-btn-borse"
					type="submit"
					onClick={() => this.props.userData.onLogout()}
					>e-Bikebörse</MDBBtn>
		</div>;

		let link;
		if (this.props.userData.isRemoteB2BPage()) {
			link = <a href={process.env.REACT_APP_EBIKEBOERSE_FRONTEND_URL + this.props.userData.getBaseName() + "?interb2bike=true"} className="nav-link Ripple-parent">{innerDiv}</a>;
		} else {
			link = <MDBNavLink link to="/LandingPageMarkt" role="tab" >{innerDiv}</MDBNavLink>;
		}
    return (
			<>
			<div className={this.state.navbarPaddingTop ? "landingb2b-nav-container fixed-top" : this.state.navbarPaddingMiddle ? "landingb2b-nav-container-middle fixed-top" : this.state.navbarPaddingBottom ? "landingb2b-nav-container-bottom fixed-top" : false}>
					<MDBNav className="landingb2b-nav">
						<MDBNavItem>
							<MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab">
									<span>InterB2Bike</span>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
									<span>e-bikebörse</span>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="#" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" >
									<span>{t('kontakt')}</span>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="/SignUpHandler" active={this.state.activeItem === "4"} onClick={this.toggle("4")} role="tab">
									<span>{t('registrieren')}</span>
							</MDBNavLink>
						</MDBNavItem>
						<Link  to="/SignInB2B" className="pl-4">
							<MDBBtn
								className="signin-btn-landingb2b"
								color="primary"
								type="submit"
								>{t('anmelden')}</MDBBtn>
						</Link>
					</MDBNav>
				</div>
			<ReactFullpage
		    licenseKey = {'2C01A6F9-2F35483E-81C14CF4-C7FF7A52'}
		    scrollingSpeed = {500}
				anchors={anchors}
				menu='#myMenu'
				navigation={true}
				navigationPosition={'right'}
				showActiveTooltip= {true}
				responsiveWidth={1000}
				responsiveHeight={300}
				onLeave={(origin,destination,direction) => {
						if(destination.index === 1){
							this.setState({ navbarPaddingTop: false, navbarPaddingMiddle: true, navbarPaddingBottom: false,  activeItem: "2"});
						}else{
								if(destination.index === 2){
									this.setState({ navbarPaddingTop: false, navbarPaddingMiddle: false, navbarPaddingBottom: true,  activeItem: "3"});
								}else{
									this.setState({ navbarPaddingTop: true, navbarPaddingMiddle: false, navbarPaddingBottom: false,  activeItem: "1"});
								}
						}
					}}
		    render={({ state, fullpageApi }) => {
	      return (
					<>
		        <ReactFullpage.Wrapper>
							<div className={styles.bodywrapper}>
									<div ref={this.listInnerRefInterB2B}/>
									<section className="section landingb2b-top">

										<div className="landingb2b-advertwrapper d-none d-xl-block">
											<img className="group_6" src={group_6}></img>
										</div>
										<MDBContainer style={{maxWidth: "1250px"}}>
											<MDBRow className="landingb2b-top-padding">
												<MDBCol md="5" middle  className="px-0">
												<MDBRow className="px-0 m-0 pb-5">
												<img className="group_5" src={group_5}></img>
												</MDBRow>
												<MDBRow className="px-0 m-0">
												<MDBCol md="auto" middle className="px-0">
												<img className="rectangle_1" src={rectangle_1}></img>
												</MDBCol>
												<MDBCol md="11" middle>
												<span className="find-your-best-parts">{t('stop_searching')}</span>
												</MDBCol>
												</MDBRow>
												</MDBCol>
												<MDBCol md="7" bottom>
												<img className="group_3" src={group_3}></img>
												</MDBCol>
											</MDBRow>
											<MDBRow end className="pr-3 m-0 pb-2">
											<span className="join-to-the-interb-2-b text-uppercase">{t('join_to_the_interb2b_platform')}</span>
											<img className="group_8" src={group_8}></img>
											</MDBRow>
											<MDBRow end className="px-0 m-0">
											<Link  to="/SignUpHersteller" className="pl-1">
												<MDBBtn
													className="signin-btn-supplier"
													color="primary"
													type="submit"
													>{t('as_a_supplier')}</MDBBtn>
											</Link>
											<Link  to="/SignUpHandler" className="pl-1">
												<MDBBtn
													className="signin-btn-dealer"
													type="submit"
													>{t('as_a_dealer')}</MDBBtn>
											</Link>
											</MDBRow>
										</MDBContainer>
									</section>
									<div ref={this.listInnerRefVideos}/>
									<section className="section landingb2b-midle">

									<MDBContainer style={{maxWidth: "1250px"}}>
										<MDBRow className="landingb2b-middle-padding pb-5">
											<MDBCol md="5" middle  className="px-0">
												<MDBRow className="px-0 m-0 pb-5">
												<img className="group_5" src={e_Bikeborse}></img>
												</MDBRow>
												<MDBRow className="px-0 m-0">
												<MDBCol md="auto" middle className="px-0">
												<img className="rectangle_1" src={rectangle_2}></img>
												</MDBCol>
												<MDBCol md="11" middle>
												<span className="find-your-best-parts">{t('deutschlands_marktplatz')}</span>
												</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol md="7" bottom>
												<img className="group_3" src={group_4}></img>
											</MDBCol>
										</MDBRow>
										<MDBRow end className="pr-3 m-0 pb-2">
										<span className="join-to-the-interb-2-b text-uppercase">{t('Beitritt_zur_E_Bikeboerse')}</span>
										<img className="group_7" src={group_7}></img>
										</MDBRow>
										<MDBRow end className="px-0 m-0">
										<MDBNav className="landingb2b-nav">
										<MDBNavItem>
								      {link}
								    </MDBNavItem>
										</MDBNav>
										</MDBRow>
									</MDBContainer>
									</section>
									<div ref={this.listInnerRefInfo}/>
									<section className="section landingb2b-bottom">

										<div className="rectangle-4">
											<MDBContainer style={{maxWidth: "1250px"}}>
											<MDBRow className="landingb2b-bottom-padding pb-4">
												<MDBCol middle md="7">
													<MDBRow start className="m-0 py-4">
														<MDBCol md="auto" middle>
															<img className="profile" src={profile_Boda}></img>
														</MDBCol>
														<MDBCol middle>
														<span className="balazs-boda-interb-2-b">balazs.boda@interb2b.com</span><br/>
														<span className="balazs-boda-interb-2-b">({t('projektleitung_der_produktentwicklung')})</span><br/>
														</MDBCol>
													</MDBRow>
													<MDBRow start className="m-0 py-4">
														<MDBCol middle md="auto" className="m-0 pr-2">
															<img className="rectangle_1" src={rectangle_1}></img>
														</MDBCol>
														<MDBCol middle className="m-0 pl-2">
															<span className="liebe-interessenten">{t('Finden_Sie_Ihren_besten_Teilelieferanten')}</span>
														</MDBCol>
													</MDBRow>
													<MDBRow className="row-landing pl-4">
													<span className="liebe-interessenten-title">{t('brauche_mehr_infos')}</span><br/>
													<span className="liebe-interessenten pt-3 pb-3">{t('bitte_geben_sie_uns_ihre_E_Mail_Adresse_an')}</span><br/>
													</MDBRow>
													<MDBRow className="row-landing-input pl-4">
													<MDBFormInline className="landingb2b-form">
															<MDBInput
																className="landingb2b-input"
																label= "Email"
																type="text"
																success="right"
																value={this.state.email}
																onChange={this.onEmailChange}
																/>
															<MDBBtn
																		className="contact-btn-landingb2b"
																		type="submit"
																		onClick={async (event) => {await this.onSubmit(event);}}>{t('senden')}</MDBBtn>
														</MDBFormInline>
													</MDBRow>
												</MDBCol>
												<MDBCol middle md="5">
													<MDBRow end className="pb-4">
														<img className="firmen" src={interb2b}></img>
													</MDBRow>
													<MDBRow end className="py-4">
															<a 	style={{textAlign:"right"}}
																	href="https://www.studicore.hu/about"
																	target="_blank" rel="noopener noreferrer">
																	<img className="firmen" src={studiCore}></img>
															</a>
													</MDBRow>
													<MDBRow end className="pt-4">
														<a 	style={{textAlign:"right"}}
																href="https://appentum.de"
																target="_blank" rel="noopener noreferrer">
																<img className="firmen" src={appentum}></img>
														</a>
													</MDBRow>
												</MDBCol>
											</MDBRow>
											</MDBContainer>
										</div>
										<div className="rectangle-footer-markt">
											<MDBContainer style={{maxWidth: "1250px"}}>
												<FooterB2BLanding/>
											</MDBContainer>
										</div>
									</section>
							</div>

							</ReactFullpage.Wrapper>
				 </>
				 );
			 }}
		 />
		{ this.state.loggedIn ?
				<Redirect to='/MainPage'
			/>:
		false}

	</>
    );
  }
}

export default  withTranslation()(LandingPageB2BikeDesktop);
