import React, { Component } from "react";
import ReactDOM from 'react-dom';
import InterB2BAxios from '../../Service/InterB2BAxios';


import BoersePageDesktop from './BoersePage/BoersePageDesktop';
import BoersePageResponziv from './BoersePage/BoersePageResponziv';
import{
} from 'mdbreact';

class BoersePage extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };


	componentDidMount() {

	}

	componentWillUnmount() {

	}

  render() {
    return (
			<>
				{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<BoersePageDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<BoersePageResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
			</>
    );
  }
}

export default  BoersePage;
