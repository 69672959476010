import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { Link } from "react-router-dom";
import { ProfileType, UserStatus } from "../../../../Config/Config";
import Moment from 'moment';
import peoplewhiteicon from "../../../../assets/media/newicons/peoplewhite.png";
import peopleicon from "../../../../assets/media/newicons/people.png";
import InterB2BAxios from '../../../../Service/InterB2BAxios';
import {
  MDBListGroupItem,
  MDBRow,
  MDBCol,
  MDBLink
} from 'mdbreact';



class ChatMessageMainBikeB2B extends Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this, 'ChatMessageBike');
    this.state = {linkBackground: false
									};
  };

  componentDidMount() {
    this.onGetUser(this.props.partnerId);
  };

  componentWillUnmount() {
	}

  onGetUser = (id) => {
    let self = this;
    this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
      self.setState({senderUser: response.data});
    })
  };

  onMdbLink = () => {
    this.setState({linkBackground: true});
    this.props.handleChatClick();
  }


 render() {
   const { t } = this.props;
   const client = this.props.client;
   var isMyMessage = this.props.id == this.props.selectedChatroomId;
   let status = null;
   let tsmoment = Moment(Date.now());
   switch (this.props.status) {
     case UserStatus.Online:
       status = "avatar-online";
       break;
     case UserStatus.Offline:
       status = "avatar-offline";
       break;
     default:
       break;
   }



  return (
    <>

    <MDBListGroupItem
    	className={
        	"list-group-item " +
			(this.props.chatroomId && window.location.href.search(this.props.chatroomId) > 0 ? "active " : " ") +
			(this.props.badge ? "unread" : "")
      	}
      style={{backgroundColor: (isMyMessage ? "#00A441" : "#ffffff")}}
    >

    <MDBLink
        className="contacts-link py-1"
        to={this.props.to}
        onClick={this.onMdbLink}
      >

    	<MDBRow>
    		<MDBCol size="2" md="2" className="text-center pl-2">
          <div className={"avatar  avatar-sm " + status}>
            {this.state.senderUser?.companyUser ?
              <img alt="" className="photo" src={this.state.senderUser?.companyProfileUrl +'?' + Date.now()}></img>:<>
                {this.state.senderUser?.profileUrl !== undefined ?
                  <img alt="" className="photo" src={this.state.senderUser?.profileUrl +'?' + Date.now()}></img>:
                  <div className="userProfileName-icon">{this.state.senderUser?.profilName}</div>}</>}
          </div>
    		</MDBCol>
        <MDBCol top md="5" className="text-left pr-2">
          <h6 className="chat-name text-truncate">{this.props.name}</h6>
          <p className="text-truncate">
          {this.props.message === "Kein Nachrichten" ?
            t('kein_nachrichten') :
          this.props.message}
          </p>
        </MDBCol>
        <MDBCol top md="3" className="text-left px-1">
          {this.props.time !== null ?
          <p className="chat-contacts-time">
          {Moment(this.props.time).date() === tsmoment.date()?
                  <span>{Moment(this.props.time).format('h:mma')}</span>:
                  <span>{Moment(this.props.time).format('MM.DD.YY')} </span>} </p>:false}
        </MDBCol>
          {this.props.badge ?
           <MDBCol md="2">
                <div className="badge badge-rounded badge-primary ml-1">
                  {this.props.badge}
                </div>
            </MDBCol> : false}
        </MDBRow>

      </MDBLink>

	</MDBListGroupItem>
{/*!isMyMessage ?
<div className="bottom"></div>:false*/}
</>
    );
  }
}
export default withTranslation()(ChatMessageMainBikeB2B);
