import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import respo_ebike_logo_fent from "../../assets/media/images/b2b/landingrespo/E-Bikebörse Logo fent.png";
import bikeParts from "../../assets/media/images/b2b/landingrespo/bikeParts.png";
import hamburgericon from "../../assets/media/images/b2b/landingrespo/hamburger.png";
import group_1 from "../../assets/media/images/b2b/landingrespo/Group 1.png";
import group_2 from "../../assets/media/images/b2b/landingrespo/Group 2.png";
import group_3 from "../../assets/media/images/b2b/landingrespo/Group 3.png";
import rectangle_1 from "../../assets/media/images/b2b/landingrespo/Rectangle-1.png";
import rectangle_2 from "../../assets/media/images/b2b/landingrespo/Rectangle-2.png";
import rectangle_3 from "../../assets/media/images/b2b/landingrespo/Rectangle-3.png";

import InterB2BAxios from '../../Service/InterB2BAxios';
import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
class LandingPageB2BikeNavRespo extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'LandingPage');
    this.state = {
								  //activeItem: "1",
									activeVideoItem: "1",
									navbarPaddingTop: true,
									navbarPaddingMiddle: false,
									navbarPadding: false,
									collapseID: -1,
									};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.refresh();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	onSearchProductB2B = event => {
		this.setState({redirect: true});
	}

	getEBikeBoerseLink = () => {
		if (this.props.userData.isRemoteB2BPage()) {
			return <a href={process.env.REACT_APP_EBIKEBOERSE_FRONTEND_URL + this.props.userData.getBaseName() + "?interb2bike=true"}><img className="landing-boerse-logo" src={respo_ebike_logo_fent}/></a>
		} else {
			return <Link to="/LandingPageMarkt"><img className="landing-boerse-logo" src={respo_ebike_logo_fent}/></Link>;
		}
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
				<div className="landing-nav-container-scroll fixed-top-landingb2b-respo">
					<MDBRow end className="hamburegermenu-row x">
					<MDBCol middle size="4" sm="4" md="4" className="text-left m-0 p-0">
						<img className="landing-bikeparts-logo" src={bikeParts}/>
					</MDBCol>
					<MDBCol middle size="5" sm="6" md="6" className="text-right m-0 p-0">
						{this.getEBikeBoerseLink()}
					</MDBCol>
					<MDBCol middle size="3" sm="2" md="2" className="text-right m-0 p-0">
						<MDBBtn flat
										className="hambureger-menu"
										type="button"
										onClick= {this.props.onMenuOpen}>
										<img className="hambureger-icon" src={hamburgericon}/>
						</MDBBtn>
					</MDBCol>

					</MDBRow>

				</div>
				{this.state.redirect ?
				 <Redirect
				 to="/BoersePage"/>:false}
		</>
    );
  }
}

export default  withTranslation()(LandingPageB2BikeNavRespo);
