import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { Redirect, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import MainPageHandlerDesktop from './MainPageHandler/MainPageHandlerDesktop';
import MainPageHandlerResponziv from './MainPageHandler/MainPageHandlerResponziv';


class MainPageHandler extends Component {

  constructor(props) {
    super(props);
    this.state = {
									};
  };


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<MainPageHandlerDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<MainPageHandlerResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
	 		</>
    );
  }
}

export default withTranslation()(MainPageHandler);
