import React, { Component } from "react";
import Cookies from 'universal-cookie';
import InterB2BAxios from '../Service/InterB2BAxios';
import FeedbackItemWhitoutComment from '../components/RatingPage/FeedbackItemWhitoutComment';
import Feedback from '../components/RatingPage/Feedback';
import FooterB2BMainPage from '../components/Footer/FooterB2BMainPage';
import FooterMarktLanding from '../components/Footer/FooterMarktLanding';
import ProfilpageNavRespo from '../components/NavbarComponent/ProfilpageNavRespo';
import BoersePageNavRespo from '../components/NavbarComponent/BoersePageNavRespo';
import LandingPageB2BikeNavRespo2 from '../components/NavbarComponentB2B/LandingPageB2BikeNavRespo2';
import MenuSideNavBoerse from '../pages/markt/BoersePage/MenuSideNavBoerse';
import MenuSideNavB2Bike from '../pages/b2b/LandingPageB2Bike/MenuSideNavB2Bike';
import MenuSideNavB2BikeMain from '../pages/b2b/Handler/MainPageHandler/MenuSideNavB2BikeMain';
import BikePartsLinkNavItem from '../components/NavbarComponent/BikePartsLinkNavItem';
import EBikeBoerseLinkNavItem from '../components/NavbarComponentB2B/EBikeBoerseLinkNavItem';
import { Link } from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';
import logo from "../assets/media/logo/1.png";
import ReactStars from "react-rating-stars-component";
import styles from '../assets/scss/modules/about.module.scss';
import profile_Boda from "../assets/media/images/profile_Boda.jpg";
import ratingicon from "../assets/media/newicons/rating.png";
import emptyicon from "../assets/media/newicons/star.png";
import ratingLogo from "../assets/media/images/RatingLogo.png";
import ib2bicon from "../assets/media/images/ib2b.png";
import i18next from 'i18next';
const cookies = new Cookies();


import{
	MDBContainer,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
	MDBCardTitle,
	MDBRow,
	MDBCol,
	MDBBtn,
	MDBIcon,
	MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBSideNav
} from 'mdbreact';
let urlName = '';
class CookiePolicyB2B extends Component {
	constructor(props) {
    super(props);
		this.listInnerRefStart = React.createRef();
		this.interAxios = new InterB2BAxios(this);
    this.state = {newslist: [],
									pageName: "Cookie Policy",
									urlName: this.props.location?.state?.urlName !== undefined ? this.props.location.state.urlName : '',
									};

  };

  componentDidMount() {
		document.addEventListener("scroll", this.scrollEventListener);
		this.onRefStart();
		var params = window.location.href.split("/");
    urlName = params[params.length - 1];
  };


	componentWillUnmount() {
		this.interAxios.cancel();
		document.removeEventListener("scroll", this.scrollEventListener);
  }

	onRefStart = () => {
		this.listInnerRefStart.current.scrollIntoView({
	 });
	}

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}
	toggle = tab => e => {
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
				}

	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		let urltext = "LandingPageMarkt";
    return(
			<>
			<div ref={this.listInnerRefStart}></div>
<div className="d-none d-lg-block">
	<BikePartsLinkNavItem userData={this.props.userData}/>
</div>
<div className="d-lg-none d-block">
<div className="navbarcomponentRespo-nav-container-b2b"><LandingPageB2BikeNavRespo2  activeItem = {"4"}
																userData={this.props.userData}
																onMenuOpen = {this.props.handleSideNavToggle}
																/></div>
</div>
<div className={styles.bodywrapper}>
<div className="d-lg-none d-block">
	<div className="side-nav-menu">
		<MDBSideNav
			right
			triggerOpening={this.props.isSideNavOpen}
			onOverlayClick={this.props.handleSideNavToggle}
			breakWidth={1300}
			className="deep-purple darken-4"

		>
		{this.props.userData.isLoggedIn() ?
			<MenuSideNavB2BikeMain
				userData={this.props.userData}
				onMenuClose = {this.props.onEnableScroll}
				onItemClose = {this.props.onEnableScrollMain}
				activeItem = {this.state.activeItem}
				toggle = {this.toggle}
			/>:
			<MenuSideNavB2Bike
				userData={this.props.userData}
				onMenuClose = {this.props.onEnableScroll}
				onItemClose = {this.props.onEnableScrollMain}
				activeItem = {this.state.activeItem}
				toggle = {this.toggle}
			/>}
		</MDBSideNav>
	</div>
</div>
		<MDBContainer>
		<MDBRow className="m-0 py-4">
		<MDBCol md="12" size="12">
		<h2>Cookie-Richtlinie für die interB2B GmbH / Cookie Policy</h2>

	<p>Dies ist die Cookie-Richtlinie der interB2B GmbH, zugänglich unter www.interB2B.com</p>

	<p><strong>Was sind Cookies?</strong></p>
	<p>Wie bei fast allen professionellen Websites üblich, verwendet diese Website Cookies, bei denen es sich um kleine Dateien handelt, die auf Ihren Computer heruntergeladen werden, um Ihre Erfahrung zu verbessern. Auf dieser Seite wird beschrieben, welche Informationen sie sammeln, wie wir sie verwenden und warum wir diese Cookies manchmal speichern müssen. Wir teilen Ihnen auch mit, wie Sie die Speicherung dieser Cookies verhindern können, dies kann jedoch bestimmte Elemente der Website-Funktionalität beeinträchtigen oder "brechen".</p>

	<p><strong>Wie wir Cookies verwenden</strong></p>
	<p>Wir verwenden Cookies aus einer Vielzahl von Gründen, die im Folgenden beschrieben werden. Leider gibt es in den meisten Fällen keine branchenüblichen Optionen zum Deaktivieren von Cookies, ohne die Funktionen und Funktionen, die sie dieser Website hinzufügen, vollständig zu deaktivieren. Es wird empfohlen, alle Cookies beizubehalten, wenn Sie sich nicht sicher sind, ob Sie sie benötigen oder nicht, falls sie verwendet werden, um einen von Ihnen genutzten Dienst bereitzustellen.</p>

	<p><strong>Cookies deaktivieren</strong></p>
	<p>Sie können das Setzen von Cookies verhindern, indem Sie die Einstellungen Ihres Browsers anpassen (siehe dazu die Hilfe Ihres Browsers). Beachten Sie, dass die Deaktivierung von Cookies die Funktionalität dieser und vieler anderer Websites, die Sie besuchen, beeinträchtigt. Das Deaktivieren von Cookies führt normalerweise dazu, dass auch bestimmte Funktionen und Funktionen dieser Website deaktiviert werden. Daher wird empfohlen, Cookies nicht zu deaktivieren. Diese Cookie-Richtlinie wurde mit Hilfe des Cookie-Richtlinien-Generators von CookiePolicyGenerator.com erstellt .</p>


	<p><strong>Die von uns gesetzten Cookies</strong></p>
	<ul>
		<li><strong>Kontobezogene Cookies</strong><br/>
	Wenn Sie bei uns ein Konto erstellen, verwenden wir Cookies zur Verwaltung des Anmeldevorgangs und zur allgemeinen Verwaltung. Diese Cookies werden normalerweise gelöscht, wenn Sie sich abmelden, in einigen Fällen können sie jedoch auch danach verbleiben, um Ihre Site-Einstellungen zu speichern, wenn Sie sich abgemeldet haben.</li>

		<li><strong>Login-bezogene Cookies</strong><br/>
	Wir verwenden Cookies, wenn Sie eingeloggt sind, damit wir uns an diese Tatsache erinnern können. Dies verhindert, dass Sie sich jedes Mal neu einloggen müssen, wenn Sie eine neue Seite besuchen. Diese Cookies werden normalerweise entfernt oder gelöscht, wenn Sie sich abmelden, um sicherzustellen, dass Sie nur auf eingeschränkte Funktionen und Bereiche zugreifen können, wenn Sie angemeldet sind.</li>

		<li><strong>Auftragsverarbeitungsbezogene Cookies</strong><br/>
	Diese Website bietet E-Commerce- oder Zahlungsmöglichkeiten und einige Cookies sind unerlässlich, um sicherzustellen, dass Ihre Bestellung zwischen den Seiten gespeichert wird, damit wir sie ordnungsgemäß bearbeiten können.</li>

		<li><strong>Umfragebezogene Cookies</strong><br/>
	Von Zeit zu Zeit bieten wir Benutzerbefragungen und Fragebögen an, um Ihnen interessante Einblicke, hilfreiche Tools zu geben oder unsere Benutzerbasis genauer zu verstehen. Diese Umfragen können Cookies verwenden, um sich daran zu erinnern, wer bereits an einer Umfrage teilgenommen hat, oder um Ihnen nach einem Seitenwechsel genaue Ergebnisse zu liefern.</li>
	</ul>

	<p><strong>Cookies von Drittanbietern</strong></p>
	<p>In einigen besonderen Fällen verwenden wir auch Cookies, die von vertrauenswürdigen Drittanbietern bereitgestellt werden. Der folgende Abschnitt beschreibt, auf welche Cookies von Drittanbietern Sie möglicherweise über diese Website stoßen.</p>
	<ul>
		<li>Diese Website verwendet Google Analytics, eine der am weitesten verbreiteten und vertrauenswürdigsten Analyselösungen im Web, die uns hilft zu verstehen, wie Sie die Website nutzen und wie wir Ihre Erfahrung verbessern können. Diese Cookies können beispielsweise verfolgen, wie lange Sie auf der Website verbringen und welche Seiten Sie besuchen, damit wir weiterhin ansprechende Inhalte produzieren können.<br/><br/>
	Weitere Informationen zu Google Analytics-Cookies finden Sie auf der offiziellen Google Analytics-Seite.</li>
		<li>Wir verwenden Anzeigen, um die Kosten für den Betrieb dieser Website zu kompensieren und Mittel für die weitere Entwicklung bereitzustellen. Die von dieser Website verwendeten Cookies für verhaltensbezogene Werbung sollen sicherstellen, dass wir Ihnen nach Möglichkeit die relevantesten Anzeigen schalten, indem wir Ihre Interessen anonym verfolgen und ähnliche Dinge präsentieren, die von Interesse sein könnten.</li>
	</ul>

	<p><strong>Mehr Informationen</strong></p>
	<p>Hoffentlich hat dies die Dinge für Sie geklärt, und wie bereits erwähnt, ist es normalerweise sicherer, Cookies aktiviert zu lassen, falls Sie sich nicht sicher sind, ob Sie benötigen oder nicht, falls es mit einer der Funktionen, die Sie auf unserer Website verwenden, interagiert.</p>
	<p>Weitere allgemeine Informationen zu Cookies finden Sie unter „Was sind Cookies“ .</p>
	<p>Wenn Sie jedoch noch weitere Informationen benötigen, können Sie uns über eine unserer bevorzugten Kontaktmethoden kontaktieren:<br/>
	E-Mail: info@interB2B.com</p>
	</MDBCol>
	</MDBRow>
	</MDBContainer>
</div>
</>
    );
  }
}
export default withTranslation()(CookiePolicyB2B);
