import React, { Component, Fragment } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
import foto2 from "../../assets/media/fotos/2.jpg";
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import ImageManager from './ImageManager';
import MapBox from '../MapBox/MapBox';
import Moment from 'moment';
import 'moment/locale/de';
import logo from "../../assets/media/logo/1.png";

import trahsicon from "../../assets/media/newicons/trash.png";
import checkdone from "../../assets/media/newicons/check_done.png";
import arrowcirclelefticon from "../../assets/media/newicons/arrow-circle-left2.png";
import galleryicon from "../../assets/media/newicons/gallery.png";
import plusimgicon from "../../assets/media/newicons/plusImg.png";
import editicon from "../../assets/media/newicons/editProfil.png";
import logouticon from "../../assets/media/newicons/logout.png";
import ib2bicon from "../../assets/media/images/ib2b.png";
import styles from '../../assets/scss/modules/pages.module.scss';
import Footer from '../Footer/Footer';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';


import{
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBScrollbar,
  MDBContainer,
	MDBNav,
	MDBNavItem,
	MDBNavLink
} from 'mdbreact';

import InterB2BAxios from '../../Service/InterB2BAxios';

const cookies = new Cookies();

class MyCurrentCompany extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {myCompanies: [], activeCompany: this.props.userData.getCurrentCompany()?.id};
  };

	componentDidMount() {
    this.props.userData.refreshNavbar();
		//this.interAxios.refreshUserData(this.props.userData);
		this.onGetMyCompanies();
		this.props.userData.refresh();
  };

	componentWillUnmount() {
		this.interAxios.cancel();
	}


	onSelectCurrentCompany = (id) => {
		let self = this;
		this.interAxios.get('/api/user/selectCurrentCompany/' + id, function(response) {
			self.props.userData.refresh();
		})
	}

	onGetMyCompanies = () => {
		let self = this;
		this.interAxios.get('/api/user/getMyCompanies', function(response) {
			self.setState({myCompanies: response.data});
		});
	}


	togglecompany = tab => e => {
				if (this.state.activeCompany !== tab) {
					this.setState({
						activeCompany: tab,});
					this.onSelectCurrentCompany(tab)
				}
	}

  render() {
		const { t } = this.props;
		const client = this.props.client;
		let myCompaniesLis = {};
    myCompaniesLis = this.state.myCompanies.map(
      (o) =>
      <div key={o.id} className="currentcompany-nav-container">
			<MDBNav className="currentcompany-nav">
				<MDBNavItem>
					<MDBNavLink link className="pt-3" to="#" active={this.props.userData.getCurrentCompany()?.id === o.id} onClick={this.togglecompany(o.id)} role="tab">
						<MDBCard className="company-card">
						<MDBRow className="row-width">
						<MDBCol middle md="6" size="6" className="pr-2 pl-0">
							<img
							src={o.profileUrl +'?' + Date.now()}
							className="img-profile"
							alt=""
							/>
							</MDBCol>
						<MDBCol middle md="6" size="6" className="pl-2 pr-0">
								<span className="card-text text-capitalize">{o.name}</span>
						</MDBCol>
						</MDBRow>
						</MDBCard>
					</MDBNavLink>
				</MDBNavItem>
			</MDBNav>
      </div>
    );

    return (
      	<div className={styles.bodywrapper}>
<MDBContainer>
		<MDBRow>
		<MDBCol size="12" lg="8" xl="6">
		<MDBRow>
		<MDBCol size="5" md="4" lg="5">
		<MDBCard className="company-card current">
			<MDBRow className="row-width">
				<MDBCol middle md="6" size="5" className="pr-2 pl-0">
					<img
					src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
					className="img-profile"
					alt=""
					/>
					</MDBCol>
				<MDBCol middle md="6" size="6" className="pl-2 pr-0">
						<span className="card-text text-capitalize">{this.props.userData.getCurrentCompany()?.name}</span>
				</MDBCol>
			</MDBRow>
		</MDBCard>
		</MDBCol>
		<MDBCol size="6" md="5" lg="5" className="pl-4 pr-0">
		<MDBRow center className="pt-3">
		<span className="title-text">{t('aktuelles_unternehmen_auswahlen')}</span>
		</MDBRow>
		<MDBRow center>
		<img className="b2b-icon p-0" src={ib2bicon}/>
		</MDBRow>
		</MDBCol>
		</MDBRow>
		<MDBRow>
		<MDBCol size="12" md="12" lg="12" className="py-3">
			<span className="normal-text">{t('wenn_sie_weitere_unternehmen_bei_InterB2B')}</span>
		</MDBCol>
		</MDBRow>
		</MDBCol>
		<MDBCol md="6" lg="6">
		</MDBCol>
		</MDBRow>
					<MDBCard className="interb2b-card list-card p-2">
						<MDBRow center className="p-3 align-items-center" style={{minHeight: "290px"}}>
							{myCompaniesLis}
						</MDBRow>
					</MDBCard>
					<Footer homeStatus={false}/>
			</MDBContainer>
			</div>
    );
  }
}

export default withTranslation()(MyCurrentCompany);
