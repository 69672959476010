import React, { Component } from "react";
import ReactDOM from 'react-dom';



import EintraegeResponziv from './Eintraege/EintraegeResponziv';
import EintraegeDesktop from './Eintraege/EintraegeDesktop';

class Eintraege extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };

  render() {
    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<EintraegeDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<EintraegeResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
			</>
    );
  }
}

export default  Eintraege;
