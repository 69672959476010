import React, { Component, Fragment } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
import foto2 from "../../assets/media/fotos/2.jpg";
import ratingicon from "../../assets/media/newicons/rating.png";
import { Link } from "react-router-dom";

import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBLightbox } from
"mdbreact";
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import GoogleMaps5 from '../GoogleMaps/GoogleMaps5';
import FeedbackCompany from '../RatingPage/FeedbackCompany';
import FeedbackCardCompany from '../RatingPage/FeedbackCardCompany';
import ImageManager from './ImageManager';
import MapBox from '../MapBox/MapBox';
import Moment from 'moment';
import 'moment/locale/de';
import logo from "../../assets/media/logo/1.png";

import trahsicon from "../../assets/media/newicons/trash.png";
import staricon from '../../assets/media/newicons/star1.png';
import checkdone from "../../assets/media/newicons/check_done.png";
import arrowcirclelefticon from "../../assets/media/newicons/arrow-circle-left2.png";
import galleryicon from "../../assets/media/newicons/gallery.png";
import plusimgicon from "../../assets/media/images/b2b/addImgOrange.png";
import editicon from "../../assets/media/images/b2b/edit_company.png";
import closeicon from "../../assets/media/images/b2b/close.png";
import logouticon from "../../assets/media/newicons/logout.png";
import ib2bicon from "../../assets/media/images/b2b/ib2b_b2b.png";
import sms from "../../assets/media/images/b2b/sms.png";
import call from "../../assets/media/images/b2b/call.png";
import global_icon from "../../assets/media/images/b2b/global.png";
import group_5 from "../../assets/media/images/b2b/Group 17.png";
import Footer from '../Footer/Footer';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';

import{
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBScrollbar,
	MDBCardTitle
} from 'mdbreact';

import InterB2BAxios from '../../Service/InterB2BAxios';


const cookies = new Cookies();
const containerStyle = {
  width: '100%',
  height: '280px'
};


class MyProfileB2B extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,
									q: [0,0,0,0],
									isOpen: false,
									myCompanies: [],
									images: [],
									selectedFiles: [],
									selectedFotos: [],
									myCurrentCompanyId: '',
									showFotos: false,
									deletePhotosList: [],
									lastFeedbackCompany: {},
									selectedPhotos: [],
									editPhotos: false,
									deleteList: [],
									timeStamp: Date.now()
              	};
  };

	componentDidMount() {
		this.onGetMyCompanies();
  };

	componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let newSelectedPhotos = [];
      if (this.props.currentCompany.urls!== undefined && this.props.currentCompany.urls.length>0){
        for (const companyPhoto of this.props.currentCompany.urls){
          let urlArray = companyPhoto.split("\/");
          let name = urlArray[urlArray.length-1];
          newSelectedPhotos.push({url: companyPhoto, name: name, server: true});
        }
      }
      this.setState({selectedPhotos: newSelectedPhotos})
    }
  }


	onIsOpen = () => {
		this.setState({isOpen: !this.state.isOpen});
	}


	onUpdateLatLng = () => {
		this.interAxios.get('/api/updatelatlong/' + 50, function(response) {
		})
	};

	onSelectCurrentCompany = () => {
		let self = this;
		this.interAxios.get('/api/user/selectCurrentCompany/' + this.state.myCurrentCompanyId, function(response) {
			self.props.onCurrentCompany();
			cookies.set('authtoken', {
				token: self.state.token,
				userId: self.state.myUserId,
				currentCompanyId: self.state.myCurrentCompanyId
			}, {
				sameSite: 'None',
				secure: true,

			}
			);
		})
	}

	onCustomerPortal = () => {
		let self = this;
		this.interAxios.get('/ecommerce/stripe-customer-portal', function(response) {
			//window.location.href = response.data;
			window.open(response.data);
		});
	}


	onGetMyCompanies = () => {
		let self = this;
		this.interAxios.get('/api/user/getMyCompanies', function(response) {
			self.setState({myCompanies: response.data});
		});
	}

	onfindCompanyByName = (name) => {
		let self = this;
		this.interAxios.get('/api/findCompanyByName', {params: {name: name}}, function(response) {
			self.setState({myCurrentCompanyId: response.data.id});

		});
	}

	onSetCompany = value => {
     this.setState({currentCompanyName: value});s
		 this.onfindCompanyByName(value);
  };

	onFileChange = (e) => {
		let list = this.state.selectedPhotos;
		for (let i = 0; i < e.target.files.length; i++) {
			let file = e.target.files[i];
			list.push({file: file, url: URL.createObjectURL(file), name: file.name, server: false});
		}

		this.setState({
				selectedFotos: list
			});

	}

	// KUKA gomb
	onImagesToDelete = (photo) => {
		let newSelectedPhotos = this.state.selectedPhotos.filter(function(value) {
				return value.url !== photo.url;
		})
		this.setState({
			selectedPhotos: newSelectedPhotos
		});

		if(photo.server){
			this.state.deleteList.push(photo.name);
		}
	}




// DONE gomb
	handleDone = () => {
		this.onFileDelete(this.state.deleteList);
		// TODO: backend kolcsonos kizaras
		//this.onFileUpload(this.state.selectedPhotos);
		this.setState({editPhotos: false, deleteList: []});
	}


	// DONE gomb: kepek torlese
	onFileDelete = (imageNames) => {
		let self = this;
		this.interAxios.post('/storage/companies/'+this.props.currentCompany.id+'/photos', imageNames, function(response) {
			// TODO: backend: kolcsonos kizaras

			self.onFileUpload(self.state.selectedPhotos);
		});
	}

	// DONE gomb: kepek feltoltese
	onFileUpload = (images) => {
		let self = this;
		let imagesToUpload = images
				.filter(function(value) {
						return !value.server;
				});
		if(imagesToUpload.length>0){
			const formData = new FormData
			for (let i = 0; i < imagesToUpload.length; i++) {
				formData.append("files", imagesToUpload[i].file, imagesToUpload[i].name);
			}
			this.interAxios.post('/upload_company_photos/' + this.props.currentCompany.id, formData, {
				'Content-Type': 'multipart/form-data'
			}, function(response) {
				self.props.userData.refresh();
			});
		}

	}



	handleEdit = () => {
		this.setState({editPhotos: true});
	}

	handleEditClose = () => {
		this.setState({editPhotos: false});
	}

	onIsOpenTrue = () => {
		this.setState({isOpen: true});
	}

	onIsOpenFalse = () => {
		this.setState({isOpen: false});
		if(this.props.public){
			this.props.onIsOpen();
		}
	}
	notFunktion = () => {

	}


  render() {
		const { t } = this.props;
		const client = this.props.client;
		var images = [];
		if (this.state.selectedPhotos !== undefined && this.state.selectedPhotos.length>0){
			for (const photo of this.state.selectedPhotos){
					images.push({	src: photo.url });
			}
		}

		let fotosListMap = this.state.selectedPhotos.map((photo) => {
			return <div key={photo.url} >
							<a onClick={this.onIsOpenTrue}>
								<img src={photo.url} className="image-manager-img"/>
							</a>
							{this.state.editPhotos ?
							<MDBBtn
									 tag="a"
									 className="image-manager-img-trash-btn"
									 floating
									 color="elegant"
									 onClick={() => this.onImagesToDelete(photo)}
							>
								<img src={trahsicon} />
							</MDBBtn>:false}
						 </div>
		})
    return (
<>
		<MDBRow className="m-0">
			<div size="12" className="profile-header-bg-b2b" style={{backgroundImage: `url(${this.props.currentCompany.backgroundUrl+ '?' + this.state.timeStamp})`}}>
			 {this.props.userData.isCurrentCompanyModerator() ?
				<MDBBtn flat className="profile-header-edit-btn-b2b m-0 p-0"
						onClick={this.props.handleShowBackground}><img src={editicon} />
				</MDBBtn>:false}
				{this.props.currentCompany.id!== undefined  ?
					<img className="rounded-circle profile-image"
						src={this.props.currentCompany.profileUrl +'?' + this.state.timeStamp}
						onClick={this.props.userData.isCurrentCompanyModerator() ? this.props.handleShowProfile : this.notFunktion}
					/>:false}
			</div>
		</MDBRow>
	<MDBContainer className="profile-content">
		<MDBRow className="m-0">
			<MDBCol size="12" md="12">
					<MDBRow className="m-0 pr-3">
						<MDBCol middle md="4" size="6" className="text-left p-0">
							<img  className="profile-ib2bicon" src={ib2bicon}/>
						</MDBCol>
						<MDBCol middle md="1" size="2" className="text-right p-0">
						{this.props.userData.isCurrentCompanyModerator() ?
							<MDBBtn tag="a" flat className="profile-edit-btn m-0 p-0"
											onClick={this.props.handleShowCompany}><img src={editicon} />
							</MDBBtn>: false}
						</MDBCol>
					</MDBRow>
					<MDBRow center className="m-0 pt-1 pb-4">
						<MDBCol md="12" middle className="text-left pl-0">
							<MDBRow start className="m-0 pb-2">
							<MDBCol md="auto" size="auto" middle className="text-left pl-0">
								<span aria-hidden="true" className="myProfile-symbol-text1">{t('firma')}</span>
							</MDBCol>
							<MDBCol middle className="text-left pl-0">
							<span aria-hidden="true" className="myProfile-symbol-text2">{t('daten')}</span>
							</MDBCol>
							</MDBRow>
						</MDBCol>
					</MDBRow>
			</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="12" md="12">
					<MDBRow>
						<MDBCol size="12" md="6" lg="6" xl="6">
							<span className="adresse-title-text">{this.props.currentCompany.name}</span><br/><br/>
							<span className="adresse-text">{this.props.currentCompany.street}</span><br/>
							<span className="adresse-text">{this.props.currentCompany.postcode} {this.props.currentCompany.town}</span><br/>
							<span className="adresse-text">{this.props.currentCompany.country}</span><br/>
							<br/>
							{this.props.currentCompany.phonenumber !=="" && this.props.currentCompany.phonenumber !== null ?
							<img className="contact-icons" src={call}/>:false} <span className="contact-text">{this.props.currentCompany.phonenumber}</span><br/>
							{this.props.currentCompany.email !=="" && this.props.currentCompany.email !== null ?
							<img className="contact-icons" src={sms}/>:false} <span className="contact-text"><a className="contact-text" href={"mailto:" + this.props.currentCompany.email}>{this.props.currentCompany.email}</a></span><br/>
							{this.props.currentCompany.website !=="" && this.props.currentCompany.website !== null ?
							<img className="contact-icons" src={global_icon}/>:false} <span className="contact-text"><a href={"https://" + this.props.currentCompany.website} target="_blank" rel="noopener noreferrer" className="contact-text">{this.props.currentCompany.website}</a></span><br/><br/>
						</MDBCol>
						<MDBCol size="12" md="6">
							{this.props.currentCompany.lat !== "" ?
							<div className="profile-mapholder">
							<GoogleMaps5 latitude={this.props.currentCompany.lat}
													 longitude={this.props.currentCompany.lng}
													 currentCompany={this.props.currentCompany}
													 isCompaniesMap= {true}
													 withMap = {"100%"}
													 heightMap = {"270px"}
													/>
							</div>:false}
						</MDBCol>
					</MDBRow>
			</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="12" md="12">
					<MDBRow className="m-0 pr-3 pt-3">
						<MDBCol middle md="4" size="6" className="text-left p-0">
							<img  className="profile-ib2bicon" src={ib2bicon}/>
						</MDBCol>
						<MDBCol middle md="1" size="2" className="text-right p-0">
						{this.props.userData.isCurrentCompanyModerator() ?
							<MDBBtn tag="a" flat className="profile-edit-btn m-0 p-0"
											onClick={this.state.editPhotos ? this.handleEditClose : this.handleEdit}><img src={this.state.editPhotos ? closeicon : editicon} />
							</MDBBtn>:false}
						</MDBCol>
						{this.state.editPhotos ? <>
						<MDBCol middle md="1" size="2" className="text-right p-0">
							<div className="file-field md-form m-0">
								<span className="btn-bild-hinzu plusimage-icon text-center">
									<img src={plusimgicon} style={{width: "93%", paddingTop: "8px"}} />
									<input multiple type="file" accept=".jpg,.jpeg,.png" onChange={this.onFileChange} />
								</span>
							</div>
						</MDBCol>
						<MDBCol middle md="3" size="2" className="text-right p-0">
							<MDBBtn flat className="fotos-done"
											onClick={this.handleDone}>Done
							</MDBBtn>
						</MDBCol> </> :false}
					</MDBRow>
					<MDBRow center className="m-0 pt-1 pb-4">
						<MDBCol md="12" middle className="text-left pl-0">
						<MDBRow start className="m-0 pb-2">
						<MDBCol md="auto" size="auto" middle className="text-left pl-0">
							<span aria-hidden="true" className="myProfile-symbol-text1">{t('firma')}</span>
						</MDBCol>
						<MDBCol middle className="text-left pl-0">
						<span aria-hidden="true" className="myProfile-symbol-text2">{t('fotos')}</span>
						</MDBCol>
						</MDBRow>
						</MDBCol>
					</MDBRow>
			</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBRow>

			</MDBRow>
			<MDBRow className="pl-1 m-0">
					{fotosListMap}
					<LightboxPageToMitteilungen
						images={images}
						isOpen={this.state.isOpen}
						onIsOpen={this.onIsOpenFalse}/>
			</MDBRow>
		</MDBRow>
		<MDBRow>
			<MDBCol size="12" md="12">
					<MDBRow className="m-0 pr-3 pt-3">
						<MDBCol middle md="4" size="6" className="text-left p-0">
							<img  className="profile-ib2bicon" src={ib2bicon}/>
						</MDBCol>
						<MDBCol middle md="1" size="2" className="text-right p-0">
						{this.props.userData.isCurrentCompanyModerator() ?
							<MDBBtn tag="a" flat className="profile-edit-btn m-0 p-0"
											onClick={this.props.handleCloseArticle}><img src={editicon} />
							</MDBBtn>:false}
						</MDBCol>
					</MDBRow>
					<MDBRow center className="m-0 pt-1 pb-4">
						<MDBCol md="12" middle className="text-left pl-0">
						<MDBRow start className="m-0 pb-2">
						<MDBCol md="auto" size="auto" middle className="text-left pl-0">
							<span aria-hidden="true" className="myProfile-symbol-text1">{t('uber')}</span>
						</MDBCol>
						<MDBCol middle className="text-left pl-0">
						<span aria-hidden="true" className="myProfile-symbol-text2">{t('uns')}</span>
						</MDBCol>
						</MDBRow>
						</MDBCol>
					</MDBRow>
			</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="12" md="12">
							{this.props.currentCompany.aboutUs !== null ?
							<MDBCard className="interb2b-card px-4 pt-2">
							<MDBRow>
								<MDBCol>
									<div className=' py-2'
									dangerouslySetInnerHTML={{__html: this.props.currentCompany.aboutUs }}/>
								</MDBCol>
							</MDBRow>
							</MDBCard>:false}
			</MDBCol>
		</MDBRow>
		{this.props.userData.isCurrentCompanyModerator() ?
		<MDBRow center className="pt-4">
		{this.props.currentCompany.enabled ?
			<><p className="company_enable">{t('ihre_unternehmensinformationen_sind_derzeit')}<a  className="link-text" onClick={this.props.onDisabledCompany}>{t('hier')}</a>!</p></>:
      <><p className="company_enable">{t('ihre_unternehmensinformationen_sind_derzeit_nicht')}<a  className="link-text" onClick={this.props.onEnabledCompany}>{t('hier')}</a>!</p>
			</>}
		</MDBRow>:false}
	</MDBContainer>
</>
    );
  }
}

export default withTranslation()(MyProfileB2B);
