import React, { Component } from "react";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInputGroup, MDBInput, MDBCard } from 'mdbreact';
import Cookies from 'universal-cookie';


const cookies = new Cookies();


class CreateOrderDTO extends Component {

  constructor(props) {
    super(props);
    this.state = {token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,
                  show: this.props.show,
                  payPage: false
                };
  };


   handleSave = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      event.target.className += " was-validated"
      if((form.checkValidity() !== false)){
        this.onCreateOrderDTO();
      }
 }


 onCreateOrderDTO = () => {
   this.setState({payPage: true});
   this.props.handlePayPage(this.state.name, this.state.lname, this.state.fname, this.state.street, this.state.postcode, this.state.town, this.state.country, this.state.ustidnr);
   this.handleClose();
 }

 changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

 handleClose = () => {
   this.props.handleClose();
   this.setState({show: false});
 }

 handleShow = () => {
   this.setState({show: true});
 }


  render() {
    return (
<MDBContainer>
  <MDBModal size="lg"
         isOpen={this.state.show}
         toggle={this.handleClose}>
    <MDBModalHeader toggle={this.handleClose}>
      Create Company
    </MDBModalHeader>
    <MDBModalBody>


      <form className="row g-3 needs-validation" onSubmit={this.handleSave} noValidate>
        <div className="col-md-12">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder="Enter company name" name="name" onChange={this.changeHandler} value={this.state.name} id="validationCustom01" required />
            <label htmlFor="validationCustom01" className="form-label">Company name</label>
            <div className="invalid-feedback">Please provide a valid company name.</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-outline">
            <input
              type="text"
              className="form-control text-muted"
              placeholder="Enter first name" name="fname" onChange={this.changeHandler} value={this.state.fname}
            />
            <label className="form-label">First name</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-outline">
            <input
              type="text"
              className="form-control text-muted"
              placeholder="Enter last name" name="lname" onChange={this.changeHandler} value={this.state.lname}
            />
            <label className="form-label">Last name</label>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder="Enter street" name="street" onChange={this.changeHandler} value={this.state.street} id="validationCustom04" required />
            <label htmlFor="validationCustom04" className="form-label">Street</label>
            <div className="invalid-feedback">Please provide a valid street.</div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder="Enter town" name="town" onChange={this.changeHandler} value={this.state.town} id="validationCustom05" required />
            <label htmlFor="validationCustom05" className="form-label">Town</label>
            <div className="invalid-feedback">Please provide a valid town.</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder="Enter postcode" name="postcode" onChange={this.changeHandler} value={this.state.postcode} id="validationCustom06" required />
            <label htmlFor="validationCustom06" className="form-label">Zip</label>
            <div className="invalid-feedback">Please provide a valid zip.</div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder="Enter country" name="country" onChange={this.changeHandler} value={this.state.country} id="validationCustom07" required />
            <label htmlFor="validationCustom07" className="form-label">country</label>
            <div className="invalid-feedback">Please provide a valid country.</div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder="Enter USt-IdNr" name="ustidnr" onChange={this.changeHandler} value={this.state.ustidnr} id="validationCustom08" required />
            <label htmlFor="validationCustom07" className="form-label">USt-IdNr</label>
            <div className="invalid-feedback">Please provide a valid USt-IdNr.</div>
          </div>
        </div>


        <div className="col-md-12">
          <div className="form-outline">
            <br/><small>InterB2b GmbH verpflichtet sich, Ihre Privatsphäre zu schützen und zu respektieren. Wir verwenden Ihre persönlichen Daten nur zur Verwaltung Ihres Kontos und zur Bereitstellung der von Ihnen angeforderten Produkte und Dienstleistungen. Von Zeit zu Zeit möchten wir Sie über unsere Produkte und Dienstleistungen sowie andere Inhalte, die für Sie von Interesse sein könnten, informieren. Wenn Sie damit einverstanden sind, dass wir Sie zu diesem Zweck kontaktieren, geben Sie bitte unten an, wie Sie von uns kontaktiert werden möchten:</small>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="defaultUnchecked" required />
              <label className="custom-control-label" htmlFor="defaultUnchecked">
              Ich stimme zu, Benachrichtigungen von InterB2bGmbH zu erhalten und habe die <a id="1" href="/AGB" target="_blank">AGB</a>, <a id="1" href="/UserAgreement" target="_blank">Nutzungsbedingungen</a> und die <a id="1" href="/PrivacyPolicy" target="_blank">Datenschutzrichtlinien</a> gelesen und bin damit einverstanden. Außerdem erkläre ich mich damit einverstanden, dass die „InterB2b GmbH” mich unter Nutzung meiner Bestands- und Nutzerdaten per Telefon oder E-Mail im Rahmen meines vorhandenen Firmenprofils und zur Information über eigene Produkte (Features, Angebote, Neuigkeiten) kontaktiert.</label>
            </div>

          </div>
        </div>

        <div className="col-12">
        <br/>
        <MDBBtn color="purple" size="sm" type="submit">Kostenlos testen</MDBBtn>
        </div>
      </form>


    </MDBModalBody>

  </MDBModal>
</MDBContainer>
    );
  }
}
export default CreateOrderDTO;
