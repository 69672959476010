import React, { Component } from "react";
import{
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBContainer,
	MDBModalFooter,
	MDBBtn,
	MDBRow,
	MDBCol
} from 'mdbreact';
import peoplewhite from "../../assets/media/newicons/peoplewhite.png";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

class GenericModal extends Component {
  state = {}


  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
      <MDBContainer>
        <MDBModal isOpen={this.props.show}>
          <MDBModalHeader toggle={this.props.handleClose}>
							<MDBRow center>
							<MDBCol>
					    	<span className="jitsi-modal-title">{this.props.config?.modalTitle ??'Modal'}</span>
							</MDBCol>
							</MDBRow>
          </MDBModalHeader>
          <MDBModalBody>
            {this.props.children}

            {/*<MDBBtn color="secondary" onClick={this.props.handleClose}>Schließen</MDBBtn>*/}
            {(this.props.config?.okButton?.show ?? true) ?
							<MDBRow center className="pt-3">
							<MDBBtn	type="button"
											color={this.props.config?.okButton?.color ?? 'primary'}
											rounded className="btn-sm"
											onClick={this.props.internalHandleSave}><span><img src={peoplewhite} style={{width: "40%"}} className="pr-2"/> {this.props.config?.okButton?.text ?? t('speichern')}</span>
							</MDBBtn>
							</MDBRow>
						: false }

						{(this.props.config?.thirdButton?.show ?? false) ?
							<MDBRow center>
							<MDBBtn	type="button"
											color={this.props.config?.thirdButton?.color ?? 'red'}
											rounded className="btn-sm"
											onClick={(event) => this.props.config?.thirdButton?.handleClick(this.props.data)}><span><img src={peoplewhite} style={{width: "40%"}} className="pr-2"/> {this.props.config?.thirdButton?.text}</span>
							</MDBBtn>

							</MDBRow>
						: false }

          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default withTranslation()(GenericModal);
