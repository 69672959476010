import React, { Component } from "react";
import { MDBListGroupItem, MDBCardBody, MDBCard, MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import Moment from 'moment';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import readmore from "../../assets/media/images/markt/readmore.png";

class Interb2bNewsItems extends Component {
  constructor(props) {
    super(props);
  };


      render() {
          let prevDate = Moment(Date.now());
          let prevDate1 = Moment(Date.now()).subtract(1, 'days');
          let prevDate2 = Moment(Date.now()).subtract(2, 'days');
          let tsmoment = Moment(this.props.msg.pubDate);
          return (
            <>
              <MDBListGroupItem className="pb-0 pt-0" href={this.props.msg.link} target="_blank">
                  <div>
                      <span className="interb2b-text-title">{this.props.msg.title}</span><br/>
                      {tsmoment.isBefore(prevDate2, 'day') ?
                      <small className="font-weight-normal text-muted">{tsmoment.locale(this.props.i18n.language).format('DD MMMM YYYY')}</small>:false}
                      {tsmoment.isSame(prevDate2, 'day') ?
                      <small className="font-weight-normal text-muted">vor 2 Tagen</small>:false}
                      {tsmoment.isSame(prevDate1, 'day') ?
                      <small className="font-weight-normal text-muted">gestern</small>:false}
                      {tsmoment.isSame(prevDate, 'day') ?
                      <small className="font-weight-normal text-muted">{tsmoment.format('h:mm a')}</small>:false}

                      {/*}<p className="mb-1 text-justify font-weight-lighter">{this.props.msg.summary}</p>*/}
                  </div>
              </MDBListGroupItem>
            </>
          );
        }
      }


export default withTranslation()(Interb2bNewsItems);
