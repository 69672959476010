import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_first from "../../../assets/media/images/markt/profil/Group 7.png";
import logo from "../../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import addBike from "../../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import anmelden from "../../../assets/media/images/markt/anmelden.png";
import searchnormalicon from "../../../assets/media/images/markt/searchnormalicon.png";
import logo2 from "../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../../assets/media/images/markt/Group 22.png";
import logo4 from "../../../assets/media/images/markt/Group 19.png";
import logo5 from "../../../assets/media/images/markt/Group 20.png";
import profile_Boda from "../../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../../assets/media/images/b2b/Group 8.png";
import group_21 from "../../../assets/media/images/markt/Group 21.png";
import rectangle_1 from "../../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../../assets/media/images/b2b/Interb2b.png";

import InterB2BAxios from '../../../Service/InterB2BAxios';
import FooterB2BMainPage from '../../../components/Footer/FooterB2BMainPage';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../components/LazyLoad/LazyLoadItem';
import SonstigeNavB2B from '../../../components/NavbarComponentB2B/SonstigeNavB2B';
import MenuSideNavB2BikeMain from './MainPageHandler/MenuSideNavB2BikeMain';
import LandingPageB2BikeNavRespo2 from '../../../components/NavbarComponentB2B/LandingPageB2BikeNavRespo2';

import styles from '../../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';
import { Scrollbar } from "react-scrollbars-custom";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBSideNav
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
class AddBikeB2BHersteller extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefInterB2B = React.createRef();
		this.listInnerRefNews = React.createRef();
		this.listInnerRefInfo = React.createRef();
		this.listInnerRefContact = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'AddBike');
    this.state = {
								  activeItem: "1",
									activeVideoItem: "1",
									navbarPaddingTop: true,
									navbarPaddingMiddle: false,
									navbarPadding: false,
									loggedIn: false,
									gyikList: [],
									collapseID: -1,
									mitteilungenlist: [],
									kategorie: "",
									eintargstyp: false,
									};
  };





	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
			var params = window.location.href.split(".");
	    language = params[params.length - 1];
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	onEintragsTyp = (kategorie) => {
		this.setState({kategorie: kategorie, eintargstyp: true});
	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<div className="d-none d-lg-block">
			<SonstigeNavB2B  userData={this.props.userData}/>
				<div className={styles.bodywrapper}>
						<div className="b2b-top-addbike">
							<MDBRow center className="m-0 addbike-row-bottom">
							<MDBCol>
							<MDBRow center className="m-0 pt-5">
									<img alt="" className="e-bikeborse-logo-first" src={logo_first}></img>
									<span className="landingpage-text">{t('kategorie')}</span>
							</MDBRow>
							<MDBRow center className="pt-4 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie-b2b"
									onClick={() => this.onEintragsTyp("E-Bike Pedelec")}
								>

									<span className="text-capitalize">E-Bike Pedelec</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie-b2b"
									onClick={() => this.onEintragsTyp("E-Bike 45 km/h")}
								>
									<span className="text-capitalize">E-Bike 45 km/h</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie-b2b"
									onClick={() => this.onEintragsTyp("E-Bike City")}
								>
									<span className="text-capitalize">E-Bike City</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie-b2b"
									onClick={() => this.onEintragsTyp("E-Bike Cross")}
								>
									<span className="text-capitalize">E-Bike Cross</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie-b2b"
									onClick={() => this.onEintragsTyp("E-Bike Klapprad-Faltrad")}
								>
									<span className="text-capitalize">E-Bike Klapprad-Faltrad</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie-b2b"
									onClick={() => this.onEintragsTyp("E-Bike Kinder-Jugendliche")}
								>
									<span className="text-capitalize">E-Bike Kinder-Jugendliche</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie-b2b"
									onClick={() => this.onEintragsTyp("E-Bike MTB")}
								>
									<span className="text-capitalize">E-Bike MTB</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie-b2b"
									onClick={() => this.onEintragsTyp("E-Bike Rennräder")}
								>
									<span className="text-capitalize">E-Bike Rennräder</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie-b2b"
									onClick={() => this.onEintragsTyp("E-Bike Trekking")}
								>
									<span className="text-capitalize">E-Bike Trekking</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie-b2b"
									onClick={() => this.onEintragsTyp("E-scooter")}
								>
									<span className="text-capitalize">E-scooter</span>
								</MDBBtn>
							</MDBRow>
							</MDBCol>
							</MDBRow>

						</div>

				</div>
				<div className="rectangle-footer-addBike">
					<MDBContainer style={{maxWidth: "1250px"}}>
						<FooterB2BMainPage/>
					</MDBContainer>
				</div>
			</div>
			<div className="d-block d-lg-none">
			<MDBContainer fluid className="navbarcomponentRespo-nav-container-b2b">
				<div className={styles.bodywrapper}>
					<div className="side-nav-menu-b2b">
						<MDBSideNav
								right
								triggerOpening={this.props.isSideNavOpen}
								onOverlayClick={this.props.handleSideNavToggle}
								breakWidth={1300}
								className="deep-purple darken-4"
						>
							<MenuSideNavB2BikeMain
									userData={this.props.userData}
									toggle = {this.toggle}
									onMenuClose = {this.props.onEnableScroll}
									onItemClose = {this.props.onEnableScrollMain}
									activeItem = {1}
							/>
						</MDBSideNav>
					</div>
					<LandingPageB2BikeNavRespo2  userData={this.props.userData} onMenuOpen = {this.props.handleSideNavToggle} onRefInterB2B={this.onRefInterB2B}/>
					<div className="b2b-top-addbike">
						<MDBRow center className="m-0 addbike-row-bottom">
						<MDBCol>
						<MDBRow center className="m-0 p-0">
								<img alt="" className="e-bikeborse-logo-first" src={logo_first}></img>
								<span className="landingpage-text">{t('kategorie')}</span>
						</MDBRow>
						<MDBRow center className="pt-4 m-0">
							<MDBBtn
								type="button"
								flat
								className="filter-btn-addKategorie-b2b"
								onClick={() => this.onEintragsTyp("E-Bike Pedelec")}
							>

								<span className="text-capitalize">E-Bike Pedelec</span>
							</MDBBtn>
						</MDBRow>
						<MDBRow center className="p-0 m-0">
							<MDBBtn
								type="button"
								flat
								className="filter-btn-addKategorie-b2b"
								onClick={() => this.onEintragsTyp("E-Bike 45 km/h")}
							>
								<span className="text-capitalize">E-Bike 45 km/h</span>
							</MDBBtn>
						</MDBRow>
						<MDBRow center className="p-0 m-0">
							<MDBBtn
								type="button"
								flat
								className="filter-btn-addKategorie-b2b"
								onClick={() => this.onEintragsTyp("E-Bike City")}
							>
								<span className="text-capitalize">E-Bike City</span>
							</MDBBtn>
						</MDBRow>
						<MDBRow center className="p-0 m-0">
							<MDBBtn
								type="button"
								flat
								className="filter-btn-addKategorie-b2b"
								onClick={() => this.onEintragsTyp("E-Bike Cross")}
							>
								<span className="text-capitalize">E-Bike Cross</span>
							</MDBBtn>
						</MDBRow>
						<MDBRow center className="p-0 m-0">
							<MDBBtn
								type="button"
								flat
								className="filter-btn-addKategorie-b2b"
								onClick={() => this.onEintragsTyp("E-Bike Klapprad-Faltrad")}
							>
								<span className="text-capitalize">E-Bike Klapprad-Faltrad</span>
							</MDBBtn>
						</MDBRow>
						<MDBRow center className="p-0 m-0">
							<MDBBtn
								type="button"
								flat
								className="filter-btn-addKategorie-b2b"
								onClick={() => this.onEintragsTyp("E-Bike Kinder-Jugendliche")}
							>
								<span className="text-capitalize">E-Bike Kinder-Jugendliche</span>
							</MDBBtn>
						</MDBRow>
						<MDBRow center className="p-0 m-0">
							<MDBBtn
								type="button"
								flat
								className="filter-btn-addKategorie-b2b"
								onClick={() => this.onEintragsTyp("E-Bike MTB")}
							>
								<span className="text-capitalize">E-Bike MTB</span>
							</MDBBtn>
						</MDBRow>
						<MDBRow center className="p-0 m-0">
							<MDBBtn
								type="button"
								flat
								className="filter-btn-addKategorie-b2b"
								onClick={() => this.onEintragsTyp("E-Bike Rennräder")}
							>
								<span className="text-capitalize">E-Bike Rennräder</span>
							</MDBBtn>
						</MDBRow>
						<MDBRow center className="p-0 m-0">
							<MDBBtn
								type="button"
								flat
								className="filter-btn-addKategorie-b2b"
								onClick={() => this.onEintragsTyp("E-Bike Trekking")}
							>
								<span className="text-capitalize">E-Bike Trekking</span>
							</MDBBtn>
						</MDBRow>
						<MDBRow center className="p-0 m-0">
							<MDBBtn
								type="button"
								flat
								className="filter-btn-addKategorie-b2b"
								onClick={() => this.onEintragsTyp("E-scooter")}
							>
								<span className="text-capitalize">E-scooter</span>
							</MDBBtn>
						</MDBRow>
						</MDBCol>
						</MDBRow>

					</div>
				</div>
			</MDBContainer>
			</div>
				{ this.state.eintargstyp && this.state.kategorie !== "" ?
						<Redirect to={{
											pathname: "/EintragsTypB2B",
											state: { kategorie: this.state.kategorie }
						}}
					/>:
 				false}


		</>
    );
  }
}

export default  withTranslation()(AddBikeB2BHersteller);
