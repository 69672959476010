import React from "react";
import { Route } from "react-router-dom";
import UserChatBike from "./UserChatBike";

function ChatHistoryBike(props) {
  return (
    <>
      <Route
        path= "/ChatsBike/user/:chatroomId" exact>
        <UserChatBike {...props}/>
      </Route>
    </>
  );
}
export default ChatHistoryBike;
