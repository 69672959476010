import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo from "../../assets/media/logo/1.png";
import{
	MDBRow,
	MDBCol,
	MDBCard,
	MDBNav,
	MDBNavItem,
	MDBNavLink
} from 'mdbreact';

class FooterB2BSingUp extends Component {

  constructor(props) {
    super(props);
  };

	/*aboutUs
	impressum
	privacyPolicy
	termsOfUse
	cookiePolicy*/
  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
      <div className="footer-nav padding-top-footer">
			<MDBNav className="justify-content-center">
				<MDBNavItem>
					<MDBNavLink to="/AboutB2B"><span className="footertext">{t('aboutUs')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/ImpressumB2B"><span className="footertext">{t('impressum')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/PrivacyPolicyB2B"><span className="footertext">{t('privacyPolicy')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/UserAgreementB2B"><span className="footertext">{t('termsOfUse')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/CookiePolicyB2B"><span className="footertext">{t('cookiePolicy')}</span></MDBNavLink>
				</MDBNavItem>
			</MDBNav>
			<div className="text-center pt-3">
	    		<img alt="" className="copyright-logo" src={logo}  style={{width: "35px", height: "35px"}}></img>
				<span className="px-3 logotext-footer">IT-Boda &copy; 2021</span>
            </div>
	    </div>


    );
  }
}

export default withTranslation()(FooterB2BSingUp);
