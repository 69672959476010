import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import 'moment/locale/de';
import Ckeditor from './Ckeditor';
import logo from "../../assets/media/logo/1.png";
import likeicon from "../../assets/media/newicons/like.png";
import like2icon from "../../assets/media/newicons/like2.png";
import commenticon from "../../assets/media/newicons/clipboard-text.png";
import deleted from "../../assets/media/images/markt/profil/deleted.png";
import reserved from "../../assets/media/images/markt/profil/reserved.png";
import revoked from "../../assets/media/images/markt/profil/revoked.png";
import bookmark_w from "../../assets/media/images/markt/bookmark-w.png";
import favorite_green from "../../assets/media/images/markt/bookmark.png";
import noImage from "../../assets/media/images/noImage.png";
import { UserStatus } from "../../Config/Config";
import InterB2BAxios from '../../Service/InterB2BAxios';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardTitle,
	MDBCardBody,
	MDBBtn,
	MDBCollapse,
	MDBView,
	MDBMask,
	MDBContainer,
	MDBIcon,
	MDBBtnGroup,
	MDBInput,
	MDBBadge,
	MDBPopper,
	MDBTooltip

} from 'mdbreact';

const cookies = new Cookies();

class HerstellerAnzegeKarte extends React.Component {


  constructor(props) {

    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {
		    };
  };

	componentDidMount() {
			this.onFindCompanyById(this.props.msg.id);
	};

	componentWillUnmount() {
		this.interAxios.cancel();
	}


	onFindCompanyById = (companyId) => {
		var self = this;
    this.interAxios.get('/api/findCompanyById/' + companyId, function(response) {
          self.setState({currentCompany: response.data});
    })

	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
    const {open} = this.state

    return (
		<MDBCol md="auto" size="12" className="mb-3 py-0 pl-2 pr-2">
		<Link
					className="contacts-link"
					onClick={(event) => this.props.onFindCompanyById(this.state.currentCompany.id)}
		>
		<MDBCard className="companyanzeige-card">
			<MDBCardBody>
				<MDBRow className="m-0">
					<div size="12" className="profile-header-bg-b2b" style={{backgroundImage: `url(${this.state.currentCompany?.backgroundUrl+ '?' +  Date.now()})`}}>

						{this.state.currentCompany?.id!== undefined  ?
							<img className="rounded-circle profile-image"
								src={this.state.currentCompany?.profileUrl +'?' + Date.now()}
							/>:false}
					</div>
				</MDBRow>



				<MDBRow className="pl-2 py-2">

					<MDBCol middle className="text-center">
						<span className="username">{this.state.currentCompany?.name}</span><br/>
						<small className="adresse-text">{this.state.currentCompany?.street} {this.state.currentCompany?.postcode} {this.state.currentCompany?.town} {this.state.currentCompany?.country}</small><br/>

					</MDBCol>
				</MDBRow>
			</MDBCardBody>
		</MDBCard>
		</Link>
		</MDBCol>
	);
  }
}

export default withTranslation()(HerstellerAnzegeKarte);
