import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_first from "../../../assets/media/images/markt/profil/Group 7.png";
import logo2 from "../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../../assets/media/images/markt/profil/Group 3.png";
import InterB2BAxios from '../../../Service/InterB2BAxios';
import FooterMarktLanding from '../../Footer/FooterMarktLanding';
import SonstigeNav from '../../NavbarComponent/SonstigeNav';
import ProfilpageNavRespo from '../../NavbarComponent/ProfilpageNavRespo';
import BoersePageNavRespo from '../../NavbarComponent/BoersePageNavRespo';
import ProfilpageNav from '../../NavbarComponent/ProfilpageNav';
import BikeAnzeigeKarteFavorite from '../../Companys/BikeAnzeigeKarteFavorite';
import styles from '../../../assets/scss/modules/profilpage.module.scss';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
  MDBBtn,
} from 'mdbreact';

let language = '';
let urlName = '';
class FavoriteDesktop extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'Eintraege');
    this.state = {bikeslist: {}, deleteList: []
									};
  };

	componentDidMount() {
		this.props.addFunctionFromChild(this.onMessage);
		var params = window.location.href.split(".");
	  language = params[params.length - 1];
		var params2 = window.location.href.split("/");
		urlName = params2[params2.length - 1];
		this.onGetBikesWithUserId();

	}

	componentWillUnmount() {
		this.props.userData.onDeleteFavoriteDone(this.state.deleteList);
		this.props.removeFunctionFromChild(this.onMessage);
		this.interAxios.cancel();
	}

	onMessage = (message) => {
    let type = message.type;
		if (type === 'NEWEBIKEPRODUCT'){
	    this.onGetBikesWithUserId();
	  }
  };

	onGetBikesWithUserId = () => {
		let self = this;
		this.interAxios.get('/api/allFavoriteBikeWithUserId/', function(response) {
			self.setState({bikeslist: response.data});
		})
	};

	onDeleteFavorite = () => {
		this.setState({isDeleteActive: true});
	}

	onDeleteFavoriteDone = () => {
		this.setState({isDeleteActive: false});

		if(this.state.deleteList.length > 0){
			let self = this;
			this.interAxios.post('/api/deleteFavoriteBikes/', this.state.deleteList, function(response) {
				self.setState({deleteList: []});
				console.log(this.state.deleteList);
			})
		}
	}

	onAddToDeleteList = (id) => {
		const newList = this.state.deleteList.concat(id);
		this.setState({deleteList: newList});
	}

	onRemoveFromDeleteList = (id) => {
		const newList = this.state.deleteList.filter((item) => item !== id);
		this.setState({deleteList: newList});
	}

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>

				<SonstigeNav userData={this.props.userData}/>
				<div className={styles.bodywrapper}>
				<div className="profil-background">
					<MDBContainer style={{maxWidth: "1250px"}}>
						<MDBRow className="pt-4">
							<MDBCol md="auto" className="pr-4">
								<ProfilpageNav activeItem = {"2"} userData={this.props.userData}/>
							</MDBCol>
							<MDBCol className="px-5">
							<MDBRow start className="m-0 pt-0 pb-3">
									<img alt="" className="e-bikeborse-logo-first-eigene" src={logo_first}></img>
									<span className="landingpage-text-eigene">{t('favoriten')}</span>
							</MDBRow>
								<MDBRow className="m-0 eigene-padding-bottom">
									{this.state.bikeslist?.length > 0 ?
											<>
											{this.state.bikeslist.map(
											(o, index) =>
											<MDBCol md="3" size="12" lg="4" xl="3" className="mb-3 px-2 pt-0" key={o.id}>
															<BikeAnzeigeKarteFavorite
															 isDeleteActive = {this.state.isDeleteActive}
															 onAddToDeleteList = {this.onAddToDeleteList}
															 onRemoveFromDeleteList = {this.onRemoveFromDeleteList}
															 userData={this.props.userData}
															 msg={o}/>
												</MDBCol>

										)}
										 </>:false}
								</MDBRow>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</div>
				</div>
		</>

    );
  }
}

export default  withTranslation()(FavoriteDesktop);
