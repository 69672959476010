import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_first from "../../../assets/media/images/markt/profil/Group 7.png";
import logo from "../../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import addBike from "../../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import anmelden from "../../../assets/media/images/markt/anmelden.png";
import signinmarkt from "../../../assets/media/images/markt/signinmarkt.png";
import signupmarkt from "../../../assets/media/images/markt/signupmarkt.png";
import searchnormalicon from "../../../assets/media/images/markt/searchnormalicon.png";
import logo2 from "../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../../assets/media/images/markt/Group 18.png";
import logo4 from "../../../assets/media/images/markt/Group 19.png";
import logo5 from "../../../assets/media/images/markt/Group 20.png";
import profile_Boda from "../../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../../assets/media/images/b2b/Group 8.png";
import group_21 from "../../../assets/media/images/markt/Group 21.png";
import rectangle_1 from "../../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../../assets/media/images/b2b/Interb2b.png";

import InterB2BAxios from '../../../Service/InterB2BAxios';
import FooterMarktLanding from '../../../components/Footer/FooterMarktLanding';
import GyikModul from "../../../components/Gyik/GyikModul";
import Interb2bNewsItems from '../../../components/News/Interb2bNewsItems';
import MitteilungenItem from '../../../components/Companys/MitteilungenItem';
import MitteilungenItemWithoutLogin from '../../../components/Companys/MitteilungenItemWithoutLogin';
import ChatsMainBike from '../../../components/ChatBike/ChatsMainBike';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../components/LazyLoad/LazyLoadItem';


import styles from '../../../assets/scss/modules/landingmarkt.module.scss';
import BikePartsLinkNavItem from '../../../components/NavbarComponent/BikePartsLinkNavItem';
import Cookies from 'universal-cookie';
import { Scrollbar } from "react-scrollbars-custom";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle
} from 'mdbreact';
const Parser = require('rss-parser');
const parser = new Parser();
const cookies = new Cookies();
let language = '';
let urlName = '';
class LandingPageMarktDesktop extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefInterB2B = React.createRef();
		this.listInnerRefNews = React.createRef();
		this.listInnerRefInfo = React.createRef();
		this.listInnerRefContact = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'LandingPageMarkt');
    this.state = {
								  activeItem: "1",
									activeVideoItem: "1",
									navbarPaddingTop: true,
									navbarPaddingMiddle: false,
									navbarPadding: false,
									gyikList: [],
									collapseID: -1,
									mitteilungenlist: [],
									news:{},
									timeStamp: Date.now(),
									redirect: false,
									activeItemKategorie: "1",
									user: {},
									textItem:"",
									categories: [],
									};
  };





	componentDidMount() {
		this.getAllEBikeCategories();
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.onTextBikeClear();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
		var params2 = window.location.href.split("/");
    urlName = params2[params2.length - 1];
		document.addEventListener("scroll", this.scrollEventListener);
		//this.props.userData.refresh();
		this.handlegetAllGyik();
		if(this.props.userData.isLoggedInMarkt()){
			this.onGetMitteilungen();
		}else{
			this.onGetMitteilungenWithoutLogin();
		}
		this.onNews();
	}

	getAllEBikeCategories = () => {
		let self = this;
		this.interAxios.get('/api/eBikeKategorie', function(response) {
			//console.log(response.data);
			self.setState({categories: response.data});
		})
	}

	componentWillUnmount() {
		this.interAxios.cancel();
		document.removeEventListener("scroll", this.scrollEventListener);
	}


	toggle = tab => e => {
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
						if (tab == 4) {
							fullpage_api.moveTo(4);
						}
						if (tab == 3) {
							fullpage_api.moveTo(3);
						}
						if (tab == 2) {
							fullpage_api.moveTo(2);
						}
						if (tab == 1) {
							fullpage_api.moveTo(1);
						}
				}

	}

	onEmailChange = (event) =>{
    this.setState({email: event.target.value});
  };

  onSubmit = (event) =>{
    event.preventDefault();
    let self = this;
    this.interAxios.post('/api/email/informations', {email: this.state.email}, function(response) {
     })
  };

	onRefNews = () => {
		this.listInnerRefNews.current.scrollIntoView({
 	 });
	}

	onRefInterB2B = () => {
		this.listInnerRefInterB2B.current.scrollIntoView({
	 });
	}

	onRefInfo = () => {
		this.listInnerRefInfo.current.scrollIntoView({
	 });
	}

	onRefContact = () => {
		this.listInnerRefContact.current.scrollIntoView({
	 });
	}


	scrollEventListener = (event) => {
		const navPadding = window.scrollY < 500 ? false : true;
		const tabscroll = window.scrollY < 500 ? "1" : window.scrollY < 1650 ? "2" : window.scrollY < 2630 ? "3" : "4";
		this.setState({ activeItem: tabscroll, navbarPadding: navPadding});
	}

	handlegetAllGyik = () => {
	 let self = this;
	 this.interAxios.get('/getallgyik',
		function(response) {
			self.setState({gyikList: response.data})
		});
	}

	handleNewGyik = () => {
	 let self = this;
	 event.preventDefault();
	 this.interAxios.post('/newgyik',
			 {kerdes: "The best fitness tracker on tot even better?",
				valasz: "Deutschlands Marktplatz Nr. 1 für neue und gebrauchte E-BikesDeutschlands Marktplatz Nr. 1 für neue und gebrauchte E-BikesDeutschlands Marktplatz Nr. 1 für neue und gebrauchte."},
		function(response) {

		});
	}

	toggleCollapse = collapseID => () =>
  this.setState(prevState => ({
    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));

	onGetMitteilungen = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungen', function(response) {
			self.setState({mitteilungenlist: response.data});
			forceVisible();
		})
	};

	onGetMitteilungenWithoutLogin = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungenWithoutLogin', function(response) {
			self.setState({mitteilungenlist: response.data});
			forceVisible();
		})
	};

	onMoreGetMitteilungen = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungen', {params: {before: this.state.mitteilungenlist[this.state.mitteilungenlist.length-1].time}}, function(response) {
			let mitteilungenlist = self.state.mitteilungenlist;
			mitteilungenlist.push(...response.data);
			self.setState({mitteilungenlist: mitteilungenlist});
		});
	}

	onMoreGetMitteilungenWithoutLogin = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungenWithoutLogin', {params: {before: this.state.mitteilungenlist[this.state.mitteilungenlist.length-1].time}}, function(response) {
			let mitteilungenlist = self.state.mitteilungenlist;
			mitteilungenlist.push(...response.data);
			self.setState({mitteilungenlist: mitteilungenlist});
		});
	}

	onNews = () => {
    let self = this;
    this.interAxios.get('/api/rss', function(response)
    {
      let promise = parser.parseString(response.data)

      promise.then((value) => {
        self.setState({news: value.items});
      });
    });
  }

	onOfferProductFalse = (event) => {

	}

	onKategorie = (value, bikeKategorie) => {
		this.setState({activeItemKategorie: value, textItem: this.props.userData.textBike, bikeKategorie: bikeKategorie});
		this.props.userData.onBoersepageTrue();
	}



	onSearchProductB2B = event => {
		this.setState({redirect: true});
	}

	hotLinks = (rowIndex) => {
		const { t } = this.props;
		let increment = 6;
		let startIncl = rowIndex * increment;
		let endExcl = startIncl + increment;
		let categoryCount = this.state.categories.length;
		if (categoryCount === 0) {
			return [];
		}
		let hotLinksInRow = [];
		for (let i = startIncl; i < endExcl && i < categoryCount; i++) {
			//console.log(i);
			let name = this.state.categories[i];
			let localizedName = t(name);
			if (localizedName.slice(0, 6) === "E-Bike") {
				localizedName = localizedName.slice(7);
			}
			hotLinksInRow.push(
				<Link key={i} to={{
					pathname: "/BoersePage",
					state: { activeItem: i + 2 + "", bikeKategorie: name }}}>
					<MDBBtn
						type="button"
						className="filter-btn-landingmarkt"
					>
						<span className="text-capitalize">{localizedName}</span>
					</MDBBtn>
				</Link>);
		}
		return hotLinksInRow;
	}

  render() {
//		console.log(this.state.redirect);
		const { t } = this.props;
    const client = this.props.client;
		const { collapseID } = this.state;
		const anchors = ["Borse", "News", "Info", "Contact"];
		let gyiklist = [];
		/*if (this.state.gyikList.length > 0){
		gyiklist = this.state.gyikList.map(
			(o) => {
					return (<GyikModul key={o.id} msg={o} toggleCollapse={this.toggleCollapse} collapseID={collapseID}/>);
		});}*/
    return (
			<>
			<div className="landingmarkt-nav-container fixed-top-landingmarkt">
			<MDBContainer style={{maxWidth: "1250px"}}>
					<MDBNav className="landingmarkt-nav">
						<MDBRow className="m-0" style={{width: "100%"}}>
							<MDBCol middle md="auto" className="text-left first-col px-0">
								<MDBRow className="m-0" start>
									<MDBNavItem>
										<MDBNavLink link to="#" onClick={this.toggle("1")} role="tab">
											<img alt="" className="ebike-logo" src={logo}></img>
										</MDBNavLink>
									</MDBNavItem>
									{this.state.navbarPadding ?
									<MDBNavItem>
										<MDBRow center className="p-0 m-0">
											<div className="product-search-markt-header">
												<form className= ""
															onSubmit={async (event) => {await this.onSearchProductB2B(event);}}>
													<MDBRow center className="m-0">
														<MDBCol top md="10" className="pl-0" middle>
															<input
																type="text"
																className="form-control"
																id="formGroupExampleInput2"
																placeholder={t('produkt_eingeben_3')}
																name="textBike"
																onChange={this.props.userData.onTextSearchChange}
																value={this.props.userData.textBike}
															/>
														</MDBCol>
														<MDBCol top md="2" className="px-0" >
															<MDBBtn type="submit"
																			className="borse-btn-header"
																			><img src={searchnormalicon} className="vector-1"/>
															</MDBBtn>
														</MDBCol>
													</MDBRow>
												</form>
											</div>
										</MDBRow>
									</MDBNavItem>
										:false}
									<MDBNavItem>
										<MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
										<div className="navlink-News">
												<span>News</span>
										</div>
										</MDBNavLink>
									</MDBNavItem>
									<MDBNavItem>
										<MDBNavLink link to="#" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" >
										<div className="navlink-info">
												<span>Info</span>
										</div>
										</MDBNavLink>
									</MDBNavItem>
									<MDBNavItem>
										<MDBNavLink link to="#" active={this.state.activeItem === "4"} onClick={this.toggle("4")} role="tab" >
										<div className="navlink-contact">
												<span>{t('kontakt')}</span>
										</div>
										</MDBNavLink>
									</MDBNavItem>
								</MDBRow>
							</MDBCol>
							<MDBCol middle style={{width: "100%"}} className="text-right second-col px-0">
								<MDBRow className="m-0" end style={{width: "100%"}}>
									<Link className="link-center" to={this.props.userData.isLoggedInMarkt() ? "/AddBike" : "/SignInMarkt"} >
										<MDBBtn
											className="addBike-btn-landingmarkt"
											color="primary"
											type="submit"
										>
											<img alt="" className="addBike-logo" src={addBike}></img>
										</MDBBtn>
									</Link>
									<BikePartsLinkNavItem userData={this.props.userData} />
									<MDBNavItem>
										<MDBRow className="m-0" style={{height: "100%"}}>
										<MDBCol middle className="pr-0">
										{!this.props.userData.isLoggedInMarkt() ?
											<MDBDropdown>
											<MDBDropdownToggle nav id="dorpdown" className="signin-btn-landingmarkt">
												<img alt="" className="anmelden-logo" src={anmelden}></img>
											</MDBDropdownToggle>
											<MDBDropdownMenu  className="text-center">
												<div id="userdiv">
												<Link to="/SignInMarkt"><MDBDropdownItem id="userprofile" >
												<MDBBtn
													flat
													className="p-0 m-0"
												>
													<img alt="" className="signinmarkt-logo" src={signinmarkt}></img><span className="anmelden-text text-capitalize pl-2">{t('anmelden')}</span>
												</MDBBtn>
												</MDBDropdownItem></Link>
												</div>
												<div id="userdiv">
												<Link to="/SignUpMarkt"><MDBDropdownItem id="userprofile">
												<MDBBtn
													flat
													className="p-0 m-0"
												>
													<img alt="" className="signinmarkt-logo" src={signupmarkt}></img><span className="signup-text text-capitalize pl-2">{t('registrieren')}</span>
												</MDBBtn>
												</MDBDropdownItem></Link>
												</div>
											</MDBDropdownMenu>
											</MDBDropdown>:

											<Link to="/Eintraege" className="signin-btn-landingmarkt">
											{this.props.userData.getCurrentCompany()?.profileUrl !== undefined ?
												<img className="userProfileName-icon"
													src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + this.state.timeStamp}
													/>:<>
													{this.props.userData.getMyUser()?.profileUrl !== undefined ?
														<img alt="" className="user-photo-navbar" src={this.props.userData.getMyUser()?.profileUrl +'?' + Date.now()}></img>:
														<div className="userProfileName-icon">{this.props.userData.userProfilName()}</div>}</>}
											</Link>}
										</MDBCol>
										</MDBRow>
									</MDBNavItem>
								</MDBRow>
							</MDBCol>
						</MDBRow>
					</MDBNav>
				</MDBContainer>
				</div>
				<ReactFullpage
					licenseKey = {'2C01A6F9-2F35483E-81C14CF4-C7FF7A52'}
					scrollingSpeed = {500}
					anchors={anchors}
					menu='#myMenu'
					navigation={true}
					navigationPosition={'right'}
					showActiveTooltip= {true}
					responsiveWidth={1350}
					responsiveHeight={680}
					onLeave={(origin,destination,direction) => {
							if(destination.index === 1){
								this.setState({ navbarPadding: true, activeItem: "2"});
							}else{
									if(destination.index === 2){
										this.setState({ navbarPadding: true, activeItem: "3"});
									}else{
										if(destination.index === 3){
											this.setState({ navbarPadding: true, activeItem: "4"});
										}else{
											this.setState({ navbarPadding: false, activeItem: "1"});
										}
								}
							}

						}}
					render={({ state, fullpageApi }) => {
					return (
						<>
							<ReactFullpage.Wrapper>
				<div className={styles.bodywrapper}>
						<div ref={this.listInnerRefInterB2B}/>
						<section className="section landingmarkt-top">
							<MDBRow center className="padding-row-logo3 m-0">
								<img alt="" className="e-bikeborse-logo2" src={logo2}></img>
							</MDBRow>
							<MDBRow center className="pt-3 m-0">
								<div className="product-search-markt">
									<form className= ""
												onSubmit={async (event) => {await this.onSearchProductB2B(event);}}>
										<MDBRow center className="pt-3 m-0">
											<MDBCol top md="10" className="main-col" middle>
									      <input
									        type="text"
									        className="form-control"
									        id="formGroupExampleInput2"
													placeholder={t('produkt_eingeben_3')}
													name="textBike"
													onChange={this.props.userData.onTextSearchChange}
													value={this.props.userData.textBike}
									      />
											</MDBCol>
											<MDBCol top md="2" className="pr-0 main-col" >
												<MDBBtn type="submit"
																className="borse-btn"
																><img src={searchnormalicon} className="vector-1"/>
												</MDBBtn>
											</MDBCol>
										</MDBRow>
									</form>
								</div>
							</MDBRow>
							<MDBRow center className="pt-4 m-0">
								{this.hotLinks(0)}
							</MDBRow>
							<MDBRow center className="py-2 m-0">
								{this.hotLinks(1)}
							</MDBRow>
							<MDBRow center className="py-2 m-0">
								{this.hotLinks(2)}
							</MDBRow>
							<MDBRow center className="py-2 m-0">
								{this.hotLinks(3)}
							</MDBRow>
						</section>
						<div ref={this.listInnerRefNews}/>
						<section className="section landingmarkt-midle1">
						<MDBRow center className="m-0 pt-5">
							<MDBCol middle className="text-right pr-1">
								<img alt="" className="e-bikeborse-logo-first" src={logo_first}></img>
							</MDBCol>
							<MDBCol middle className="text-left pl-1">
								<span className="landingpage-text">{t('news')}</span>
							</MDBCol>
						</MDBRow>
							<MDBContainer>
								<MDBRow center className="padding-row-logo4 m-0">
									<MDBCol md="5" className="mb-3 text-center">
									<Link to="/NewsPage" className="icon-newspage">
									<MDBCard className="blog-card2 py-5">
									<div className="allnews-btn">
									<span className="allnews">All News</span>
									</div>
										<Scrollbar
															id="scrollbar"
															style={{ width: "100%", maxHeight: "100%"}}
															maximalThumbYSize = {100}
															noScrollX = {true}
															noScrollY = {true}
										>
										{this.state.news.length > 0 ?
												<>
												{this.state.news.filter((item, index) => index < 2).map(
												(o, index) =>
																<Interb2bNewsItems
																 key={index}
																 msg={o}
																 useLink={false}/>

											)}
										</>:false}
										</Scrollbar>
										<div className="gradient2"/>
									</MDBCard>
									</Link>
									</MDBCol>
									<MDBCol md="7" className="mb-3">
									<Link to="/BlogPage" className="icon-newspage">
										<MDBCard className="blog-card p-5">
										<div className="allblog-btn">
										<span className="allblog">e-Bike Blog</span>
										</div>
											<Scrollbar
																id="scrollbar"
																style={{ width: "100%", maxHeight: "100%"}}
																maximalThumbYSize = {100}
																noScrollX = {true}
																noScrollY = {true}
											>
												{this.state.mitteilungenlist.filter((item, index) => index < 2).map(
													(o, index) =>
																<div key={o.id}>
																{this.props.userData.isLoggedInMarkt() ?
																	<MitteilungenItem
																		userData ={this.props.userData}
																		key={o.id}
																		msg={o}
																	/>:
																	<MitteilungenItemWithoutLogin
																		userData ={this.props.userData}
																		key={o.id}
																		msg={o}
																	/>}</div>
												)}
											</Scrollbar>
											<div className="gradient1"/>
										</MDBCard>
								</Link>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
						</section>
						<div ref={this.listInnerRefInfo}/>
						<section className="section landingmarkt-midle2">
						<MDBRow center className="m-0 pt-3">
							<MDBCol middle className="text-right pr-1">
								<img alt="" className="e-bikeborse-logo-first" src={logo_first}></img>
							</MDBCol>
							<MDBCol middle className="text-left pl-1">
								<span className="landingpage-text">{t('faq')}</span>
							</MDBCol>
						</MDBRow>
							<div className="py-5 m-0">
								{gyiklist}
							</div>

						</section>
						<div ref={this.listInnerRefContact}/>
						<section className="section landingmarkt-bottom">
							<MDBRow center className="m-0 pt-3">
								<MDBCol middle className="text-right pr-1">
									<img alt="" className="e-bikeborse-logo-first" src={logo_first}></img>
								</MDBCol>
								<MDBCol middle className="text-left pl-1">
									<span className="landingpage-text">{t('kontakt_1')}</span>
								</MDBCol>
							</MDBRow>
							<MDBRow center className="m-0">
								<MDBContainer style={{maxWidth: "1250px"}}>
								<MDBRow className="landingmarkt-bottom-padding pb-5">
									<MDBCol middle md="7">
										<MDBRow start className="m-0 pb-2">
											<MDBCol md="auto" middle>
												<img className="profile" src={profile_Boda}></img>
											</MDBCol>
											<MDBCol middle>
											<span className="balazs-boda-interb-2-b">balazs.boda@interb2b.com</span><br/>
											<span className="balazs-boda-interb-2-b">{t('projektleitung_der_produktentwicklung')}</span><br/>
											</MDBCol>
										</MDBRow>
										<MDBRow start className="m-0 py-1">
											<MDBCol middle md="auto" className="m-0 pr-2">
												<img className="rectangle-1" src={rectangle_2}></img>
											</MDBCol>
											<MDBCol middle className="m-0 pl-2">
												<span className="liebe-interessenten">{t('deutschlands_marktplatz')}</span>
											</MDBCol>
										</MDBRow>
										<MDBRow className="row-landing-markt pl-4">
										<span className="liebe-interessenten-title">{t('brauche_mehr_infos')}</span><br/>
										<span className="liebe-interessenten-mark pt-3 pb-3">{t('bitte_geben_sie_uns_ihre_E_Mail_Adresse_an')}</span><br/>
										</MDBRow>
										<MDBRow className="row-markt-input pl-4">
										<MDBFormInline className="landingmarkt-form">
												<MDBInput
													className="landingmarkt-input"
													label= "Email"
													type="text"
													success="right"
													value={this.state.email}
													onChange={this.onEmailChange}
													/>
												<MDBBtn
															className="contact-btn-landingmarkt"
															type="submit"
															onClick={async (event) => {await this.onSubmit(event);}}>{t('senden')}</MDBBtn>
											</MDBFormInline>
										</MDBRow>
									</MDBCol>
									<MDBCol middle md="5" className="pr-5">
										<MDBRow end className="pb-4 m-0">
											<img className="firmen" src={interb2b}></img>
										</MDBRow>
										<MDBRow end className="py-4 m-0">
												<a 	style={{textAlign:"right"}}
														href="https://www.studicore.hu/about"
														target="_blank" rel="noopener noreferrer">
														<img className="firmen" src={studiCore}></img>
												</a>
										</MDBRow>
										<MDBRow end className="pt-4 m-0">
											<a 	style={{textAlign:"right"}}
													href="https://appentum.de"
													target="_blank" rel="noopener noreferrer">
													<img className="firmen" src={appentum}></img>
											</a>
										</MDBRow>
									</MDBCol>
								</MDBRow>
								</MDBContainer>
							</MDBRow>
							<div className="rectangle-footer-markt">
								<MDBContainer style={{maxWidth: "1250px"}}>
									<FooterMarktLanding urlName = {urlName}/>
								</MDBContainer>
							</div>
						</section>
				</div>
											</ReactFullpage.Wrapper>
								 </>
								 );
							 }}
						 />
						{this.props.userData.isLoggedInMarkt() ?
						 <div className="chatwrapper-markt d-none d-xl-block">
				 				<ChatsMainBike
				 									className="text-center"
				 									userData = {this.props.userData}
				 									addFunctionFromChild= {this.props.addFunctionFromChild}
				 									removeFunctionFromChild= {this.props.removeFunctionFromChild}
				 				/>
		 				 </div>:false}
					 {this.state.redirect ?
			 			<Redirect
						to={{		pathname: "/BoersePage",
									  state: { activeItem: this.state.activeItemKategorie, bikeKategorie: this.state.bikeKategorie }}}/>:false}
	 </>
 	);
 }
 }

 export default  withTranslation()(LandingPageMarktDesktop);
