import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import Flags from 'country-flag-icons/react/3x2'
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import konto from "../../assets/media/images/icons/konto-2.png";
import konto_gelb from "../../assets/media/images/icons/konto.png";
import chat_gelb from "../../assets/media/images/icons/Vector.png";
import chat from "../../assets/media/images/markt/profil/chat.png";
import invitation from "../../assets/media/images/icons/Vector-4.png";
import invitation_gelb from "../../assets/media/images/icons/Vector-14.png";
import management from "../../assets/media/images/icons/Vector-3.png";
import management_gelb from "../../assets/media/images/icons/Vector-13.png";
import signOut from "../../assets/media/images/icons/Vector-6.png";

import wheel from "../../assets/media/images/icons/wheel-2.png";
import wheel_gelb from "../../assets/media/images/icons/wheel.png";
import unternehmer_gelb from "../../assets/media/images/icons/profilInfoOrange.png";
import unternehmer from "../../assets/media/images/icons/profilInfoGrey.png";
import moment from 'moment';
import InterB2BAxios from '../../Service/InterB2BAxios';
import Footer from '../Footer/Footer';
import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle,
	MDBBadge
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
class ProfilpageNavB2B extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'LandingPage');
    this.state = {
									};
  };





	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		//this.props.userData.refresh();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	setBackendLanguage = (language) => {
    this.interAxios.get('/api/user/setLanguage', { params: { language: language}}, function(response) {
      console.log('Language set to ' + language)
    })
  }

  toGerman = () => {
    i18next.changeLanguage('de', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      this.setBackendLanguage('de');
      //this.setState({language: 'de'});
    });
    moment.locale('de');
    //this.onLanguageDropFalse();
  }

  toEnglish = () => {
    i18next.changeLanguage('en', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      this.setBackendLanguage('en');
      //this.setState({language: 'en'});
    });
    moment.locale('en');
    //this.onLanguageDropFalse();
  }


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<div className="sticky-card-b2b">
			<MDBCard className="profilepage-card-b2b">
			<div className="profilpage-hauptmenu">
				<MDBNav className="flex-column landingmarkt-nav">
					{!this.props.userData.isCurrentCompanyAnonim() ?
					<MDBNavItem>
						<MDBNavLink link to="/NewCompanyB2B" active={this.props.activeItem === "1"} role="tab" >
							<MDBRow className="m-0">
								<MDBCol middle md="auto" className="px-0 col-height">
									<img alt="" className="profil-logo" src={this.props.activeItem === "1" ? unternehmer_gelb : unternehmer}></img>
								</MDBCol>
								<MDBCol middle className="pl-2 pr-2 col-height">
									<span className="profilpage-menu">{t('neue_firma')}</span>
								</MDBCol>
							</MDBRow>
						</MDBNavLink>
					</MDBNavItem>:false}
					{!this.props.userData.isCurrentCompanyAnonim() ?
					<MDBNavItem>
						<MDBNavLink link to="/MyProfilePageB2B" active={this.props.activeItem === "2"} role="tab" disabled={this.props.userData.isCurrentCompanyAnonim() ? true :false}>
							<MDBRow className="m-0">
								<MDBCol middle md="auto" className="px-0 col-height">
									<img alt="" className="profil-logo" src={this.props.activeItem === "2" ? unternehmer_gelb : unternehmer}></img>
								</MDBCol>
								<MDBCol middle className="pl-2 pr-2 col-height">
									<span className="profilpage-menu">{t('unternehmensprofil')}</span>
								</MDBCol>
							</MDBRow>
						</MDBNavLink>
					</MDBNavItem>:false}
					{!this.props.userData.isCurrentCompanyAnonim() && this.props.userData.hasMoreCompanies() ?
					<MDBNavItem>
						<MDBNavLink link to="/MyCurrentCompany" active={this.props.activeItem === "3"} role="tab" >
							<MDBRow className="m-0">
								<MDBCol middle md="auto" className="px-0 col-height">
									<img alt="" className="profil-logo" src={this.props.activeItem === "3" ? wheel_green : wheel}></img>
								</MDBCol>
								<MDBCol middle className="pl-2 pr-2 col-height">
									<span className="profilpage-menu">{t('aktuelles_unternehmen')}</span>
								</MDBCol>
							</MDBRow>
						</MDBNavLink>
					</MDBNavItem>:false}
					<MDBNavItem>
						<MDBNavLink link to="/EintraegeB2B" active={this.props.activeItem === "4"} role="tab" disabled={this.props.userData.isCurrentCompanyAnonim() ? true :false}>
							<MDBRow className="m-0">
								<MDBCol middle md="auto" className="px-0 col-height">
									<img alt="" className="profil-logo" src={this.props.activeItem === "4" ? wheel_gelb : wheel}></img>
								</MDBCol>
								<MDBCol middle className="pl-2 pr-2 col-height">
									<span className="profilpage-menu">{t('eigene_anzeigen')}</span>
								</MDBCol>
							</MDBRow>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/ChatsBikeB2B" active={this.props.activeItem === "5"} role="tab" disabled={this.props.userData.isCurrentCompanyAnonim() ? true :false}>
							<MDBRow className="m-0">
								<MDBCol middle md="auto" className="px-0 col-height">
									<img alt="" className="profil-logo" src={this.props.activeItem === "5" ? chat_gelb : chat}></img>
								</MDBCol>
								<MDBCol middle className="pl-2 pr-2 col-height">
									<span className="profilpage-menu">Chat</span>
								</MDBCol>
							</MDBRow>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/KontoB2B" active={this.props.activeItem === "6"} role="tab" >
						<MDBRow className="m-0">
							<MDBCol middle md="auto" className="px-0 col-height">
								<img alt="" className="profil-logo" src={this.props.activeItem === "6" ? konto_gelb : konto}></img>
							</MDBCol>
							<MDBCol middle className="pl-2 pr-2 col-height">
								<span className="profilpage-menu">Konto</span>
							</MDBCol>
						</MDBRow>
						</MDBNavLink>
					</MDBNavItem>
					{!this.props.userData.isCurrentCompanyAnonim() && this.props.userData.isCurrentCompanyModerator()  ? <>
						<MDBNavItem>
							<MDBNavLink link to="/VerwaltungB2B" active={this.props.activeItem === "7"} role="tab" disabled={this.props.userData.isCurrentCompanyAnonim() ? true :false}>
								<MDBRow className="m-0">
									<MDBCol middle md="auto" className="px-0 col-height">
										<img alt="" className="profil-logo" src={this.props.activeItem === "7" ? management_gelb : management}></img>
									</MDBCol>
									<MDBCol middle className="pl-2 pr-2 col-height">
										<span className="profilpage-menu">{t('abo_verwaltung')}</span>
									</MDBCol>
								</MDBRow>
							</MDBNavLink>
						</MDBNavItem>
						</>:false}
					{!this.props.userData.isCurrentCompanyAnonim() && this.props.userData.isCurrentCompanyModerator()  ? <>
						<MDBNavItem>
							<MDBNavLink link to="/InvitationB2B" active={this.props.activeItem === "8"} role="tab" >
								<MDBRow className="m-0">
									<MDBCol middle md="auto" className="px-0 col-height">
										<img alt="" className="profil-logo" src={this.props.activeItem === "8" ? invitation_gelb : invitation}></img>
									</MDBCol>
									<MDBCol middle className="pl-2 pr-2 col-height">
										<span className="profilpage-menu">{t('einladung_senden')}</span>
									</MDBCol>
								</MDBRow>
							</MDBNavLink>
						</MDBNavItem>
					</>:false}
					{this.props.userData.getMyInvitationslist() !== undefined && this.props.userData.getMyInvitationslist().length>0 ?<>
						<MDBNavItem>
							<MDBNavLink link to="/InvitationB2BIn" active={this.props.activeItem === "9"} role="tab" >
								<MDBRow className="m-0">
									<MDBCol middle md="auto" className="px-0 col-height">
										<img alt="" className="profil-logo" src={this.props.activeItem === "9" ? invitation_gelb : invitation}></img>
									</MDBCol>
									<MDBCol middle className="pl-2 pr-2 col-height">
										<span className="profilpage-menu">{t('einladungen')}</span>
									</MDBCol>
								</MDBRow>
							</MDBNavLink>
						</MDBNavItem>
						<MDBBadge className="invitationBadge" pill color="danger" style={{position: 'absolute', right: '40px', bottom: '133px', zIndex: 10, border: '2px solid #fff'}}>{this.props.userData.getMyInvitationslist().length}</MDBBadge>
						</>:false}
						<MDBNavItem>
						<MDBNavLink link to="/Logout" active={this.props.activeItem === "11"} role="tab" >
							<MDBBtn
								flat
								className="p-0 m-0"
							>
								<MDBRow className="m-0">
									<MDBCol middle md="auto" className="px-0 col-height">
										<img alt="" className="profil-logo" src={signOut}></img>
									</MDBCol>
									<MDBCol middle className="pl-2 pr-2 col-height">
										<span className="profilpage-menu text-capitalize">{t('abmelden')}</span>
									</MDBCol>
								</MDBRow>
							</MDBBtn>
						</MDBNavLink>
					</MDBNavItem>
				</MDBNav>
				<MDBNav className="currentcompany-nav">
					<MDBNavItem>
						<MDBNavLink  id="flag" link className="pt-3" to="#" active={this.props.i18n.language === 'en'} onClick={this.toEnglish} role="tab">
							<Flags.GB style={{borderRadius: "2px", width: "30px"}}/>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink  id="flag" link className="pt-3" to="#" active={this.props.i18n.language === 'de'} onClick={this.toGerman} role="tab">
							<Flags.DE style={{borderRadius: "2px", width: "30px"}}/>
						</MDBNavLink>
					</MDBNavItem>
				</MDBNav>
			</div>
			</MDBCard>
			<div style={{width: "264px", marginLeft: "-5px"}}>
			<Footer/>
			</div>
			</div>
		</>
    );
  }
}

export default  withTranslation()(ProfilpageNavB2B);
