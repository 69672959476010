import React from 'react';
import {BrowserRouter as Router, Route } from 'react-router-dom';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

import InterB2BAxios, { setGlobalUserData } from './Service/InterB2BAxios';
import App from './App';
import moment from 'moment';

class UserDataComponent extends React.Component {

  state = {
    loggedIn: false,
    loggedInMarkt: false,
    seiteMarkt: false,
    spinner: false,
    productCount: -1
    //currentCompany: {},
    //myUser: {},
  }

  constructor(props) {
    super(props);
    //console.log('lefut a constructor, localStorage.state=' + window.localStorage.getItem('state'));
    this.state = JSON.parse(window.localStorage.getItem('state')) || {
      loggedIn: false,
      loggedInMarkt: false,
      seiteMarkt: false,
      spinner: false,
      productCount: -1,
      originalLatitude: -200.0,
      originalLongitude: -200.0
      //currentCompany: {},
      //myUser: {}
    }
    this.interAxios = new InterB2BAxios(this, 'UserDataComponent');
    let self = this;
    navigator.geolocation.getCurrentPosition(function(position) {
        self.setState({
          originalLatitude: position.coords.latitude,
          originalLongitude: position.coords.longitude
        });
    });
  }

  componentDidMount() {
    //console.log('lefut a componentDidMount');
    //this.reloadUserDataOnReload();
    //this.interAxios.refreshUserData(this.userData());

  }

  setState(state) {
    //console.log('setState: ' + JSON.stringify(state));
    //console.trace();
    /*let newState = {...this.state, ...state};
    window.localStorage.setItem('state', JSON.stringify(newState));
    super.setState(newState);*/
    super.setState(function(prevState, props) {
      let newState = {...prevState, ...state};
      window.localStorage.setItem('state', JSON.stringify(newState));
      return {
        ...newState
      };
    })
  }

  /*getUsername = () =>  {
    return cookies.get('authtoken').username;
  }*/

  isLoggedIn = () => {
    //return cookies.get('authtoken') !== undefined && cookies.get('authtoken') !== null;
    return this.state.loggedIn && (cookies.get('authtoken') !== undefined && (cookies.get('authtoken')?.expiry === undefined || moment().isBefore(moment(cookies.get('authtoken')?.expiry))));
  }

  isLoggedInMarkt = () => {
    return this.state.loggedInMarkt;
  }

  isSeiteMarkt = () => {
    return this.state.seiteMarkt;
  }

  setSeiteMarktTrue = () => {
    this.setState({seiteMarkt: true});
    //this.onLoginFormSubmitAsGast();
  }

  setSeiteMarktFalse = () => {

    this.setState({seiteMarkt: false});
  }

  token = () => {
    return cookies.get('authtoken')?.token;
  }

  shortJwt() {
    if (cookies.get('authtoken')?.token === undefined)
      return 'no_token';
    let parts = cookies.get('authtoken')?.token.split('.');
    return parts[2].substring(0,10);
  }

  userId = () => {
    return cookies.get('authtoken')?.userId;
  }

  name = () => {
    return cookies.get('authtoken')?.name;
  }

  userProfilName = () => {
    return cookies.get('authtoken')?.userProfilName;
  }

  anmeldenFromMarkt = () => {
    return cookies.get('authtoken')?.anmeldenFromMarkt;
  }

  partner = () => {
    return cookies.get('authtoken')?.partner;
  }

  sysAdmin = () => {
    return cookies.get('authtoken')?.sysAdmin;
  }

  isGastAdmin = () => {
    return cookies.get('authtoken')?.gastAdmin;
  }

  getTokenExpiry = () => {
    let exp = cookies.get('authtoken')?.expiry;
    if (exp === undefined) {
      return Date.now();
    } else {
      return moment(exp).valueOf();
    }
  }

  getCurrentCompany = () => {
    return this.state.currentCompany;
  }

  getMyUser = () => {
    return this.state.myUser;
  }

  getLatLng = () => {
    return {
      originalLatitude: this.state.originalLatitude,
      originalLongitude: this.state.originalLongitude
    };
  }

  isCurrentCompanyAnonim = () => {
    return this.getCurrentCompany()?.name === '';
  }

  isCurrentCompanyModerator = () => {
    return this.getCurrentCompany() !== undefined && !this.getCurrentCompany().companyIsWorksFor;
  }

  hasMoreCompanies = () => {
    return this.state.isMoreCompanies;
  }

  getUrlState = () => {
    return this.state.urlState;
  }

  getUseremail = () => {
    return this.state.userEmail;
  }

  onIsMoreCompanies = () => {
    let self = this;
    this.interAxios.get('/api/isMoreCompanies/'+ cookies.get('authtoken')?.userId, function(response)
    {
          self.setState({isMoreCompanies: response.data});
    });
	}

  onCurrentCompany = (id, afterFunction) =>{
    var self = this;
    this.interAxios.get('/api/myCompany/'+id, function(response)
    {
          self.setState({currentCompany: response.data});
          if (afterFunction !== undefined) {
            afterFunction(response.data);
          }
    });
  };

  onGetUser = (id) => {
    let self = this;
    this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
      self.setState({myUser: response.data});
    })
  };

  onIncomingInvitations = () => {
    this.setState({myInvitationslist: {}});
    let self = this;
    this.interAxios.get('/api/invite/getincoming', function(response)
    {
          self.setState({myInvitationslist: response.data});
    });
  }

  onOutgoingInvitations = () => {
    this.setState({outInvitationsList: {}});
		let self = this;
		this.interAxios.get('/api/invite/getoutgoing', function(response) {
			self.setState({outInvitationsList: response.data});
		});
	}

  onMySubscription = () => {
    var self = this;
		this.interAxios.get("/ecommerce/mySubscription", function(response) {
			self.setState({mySubscription: response.data});
		})
  };

  getMyInvitationslist = () => {
    return this.state.myInvitationslist;
  }

  getOutgoingInvitationslist = () => {
    return this.state.outInvitationsList;
  }

  getAcquireCompanyWithSameEmail = () => {
    return this.state.acquireCompanyWithSameEmail;
  }

  getTimeStamp = () => {
    return Date.now();
  }

  getMySubscription = () => {
    return this.state.mySubscription;
  }

  refresh = () => {

    //this.interAxios.refreshUserData(this.userData());
    if(this.state.myUser !== undefined){
      this.onCurrentCompany(cookies.get('authtoken')?.userId);

    }
    // FIXME itt m�g lehet, hogy nem lesz meg a currentCompany �rt�ke!!!
    if(this.state.currentCompany !== undefined){
      this.onIsMoreCompanies();
      this.onIncomingInvitations();
      if(this.isCurrentCompanyModerator()){
        this.onOutgoingInvitations();
      }
    }
    if(cookies.get('authtoken') !== undefined){
      this.onGetUser(cookies.get('authtoken').userId);
    }

  }

  refreshNavbar = (urlState) => {
    if (urlState === undefined) {
      let urlParts = window.location.href.split('/');
      urlState = urlParts[urlParts.length-1];
    }
    this.setState({urlState: urlState})
  }

  /*reloadUserDataOnReload = () => {
    let self = this;
    this.interAxios.get('/api/auth/reload', function(response)
    {
      let responseData = response.data;
      if (responseData.loggedIn == true) {
          self.setState(
            {
              loggedIn: true,
              loggedInMarkt: true,
              acquireCompanyWithSameEmail: responseData.acquireCompanyWithSameEmail,
              userEmail: responseData.email,
            }
          );
      }
    });
  }*/

  onSignIn = (responseData) => {
    console.log('UserDataComponent.onSignIn');
    cookies.set('authtoken', {
        token: responseData.accessToken,
        userId: responseData.id,
        name: responseData.name,
        partner: responseData.partner,
        anmeldenFromMarkt: responseData.anmeldenFromMarkt,
        sysAdmin: responseData.sysAdmin,
        gastAdmin: responseData.gastAdmin,
        userProfilName: responseData.profilName,
        expiry: responseData.expiry
      }, { path: '/'} /*, {
         sameSite: 'None',
         secure: true
      }*/
    );
    this.setState({ loggedIn: true,
                    acquireCompanyWithSameEmail: responseData.acquireCompanyWithSameEmail,
                    userEmail: responseData.email});
    this.interAxios.refreshUserData(this.userData());
    //console.log("interAxios token refreshed to " + this.userData().token());
    if(responseData.anmeldenFromMarkt){
      this.setState({ loggedInMarkt: true});
        this.onCurrentCompany(responseData.id, function(responseData) {
          //console.log('afterFunction is being run')
          if(responseData !== undefined){
            this.onIsMoreCompanies();
            this.onIncomingInvitations();
            if(this.isCurrentCompanyModerator()){
              this.onOutgoingInvitations();
            }
          }
        });
        this.onGetUser(responseData.id);
    } else {
      this.onGetUser(responseData.id);
      let self = this;
      this.onCurrentCompany(responseData.id, function(responseData) {
        //console.log('afterFunction is being run')
        if(responseData !== undefined){
          self.onIsMoreCompanies();
          self.onIncomingInvitations();
          if(self.isCurrentCompanyModerator()){
            self.onOutgoingInvitations();
          }
        }
      });
    }

  }

  onLogout = () => {
    cookies.remove('authtoken', { path: '/'});
    //window.localStorage.removeItem('state');
    this.setState({loggedIn: false,
                   loggedInMarkt: false,
                   currentCompany: undefined,
                   myUser: undefined,
                   myInvitationslist: undefined,
                   acquireCompanyWithSameEmail: undefined,
                   userEmail: undefined

                    });
  }

  onLogoutfromMarkt = () => {
    cookies.remove('authtoken', { path: '/'});
    this.setState({loggedIn: false,
                   loggedInMarkt: false,
                   currentCompany: undefined,
                   myUser: undefined,
                   myInvitationslist: undefined});
    this.onLoginFormSubmitAsGast();
  }

  onLoginFormSubmitAsGast  = () => {
		var self = this;
		this.interAxios.post('/api/auth/signin', {username: "UserGast", password: "000000000"}, function(response) {
				self.onSignIn(response.data);
			}, function(error) {


			});
	};

  // InterAxios hívja, ha a hívás 401 Unauthorized miatt meghiúsul.
  onUnauthorizedError = () => {
    window.localStorage.removeItem('state')
    if (this.state.loggedInMarkt) {
      this.onLogoutfromMarkt();
    } else {
      this.onLogout();
    }
  }

  searchProductQuick = (product, thenfunction) => {
		this.interAxios.get('/api/veloconnect/quickSearch', {
        params: { q: product }
								}, thenfunction);
	}

  searchProductCount = (product, thenfunction) => {
		this.interAxios.get('/api/veloconnect/count', {
        params: { q: product }
								}, thenfunction);
	}

  searchProductFullWithCompanyName = (product, name, thenfunction) => {
		this.interAxios.get('/api/veloconnect/fullSearch', {
        params: { q: product,  supplier: name}
								}, thenfunction);
	}

  searchProductFull = (product, thenfunction) => {
		this.interAxios.get('/api/veloconnect/fullSearch', {
        params: { q: product}
								}, thenfunction);
	}
  onSearchProductFull =(productName) =>{
    let self = this;
    this.setState({	offerlist: {}, spinner: true});
		this.searchProductFull(productName, function(response) {
      self.setState({searchStatus: true, productname: productName});
			self.setState({	offerlist: response.data, spinner: false, productCount: response.data.totalCount,});

		});
  };
  onSearchProductWithCompanyFilter = (productName, name) => {
    let self = this;
    this.setState({	offerlist: {}, spinner: true});
    this.searchProductFullWithCompanyName(productName, name, function(response) {
      self.setState({searchStatus: true, productname: productName});
      self.setState({	offerlist: response.data, productCount: response.data.totalCount, spinner: false});

    });
  }

  onSearchProductQuick =(productName) =>{
    let self = this;
    this.setState({	offerlist: {}, spinner: true});
		this.searchProductQuick(productName, function(response) {
      self.setState({searchStatus: true, productname: productName});
			self.setState({	offerlist: response.data, spinner: false});

		});
  };

  onSearchProductCount =(productName) =>{
    let self = this;
    this.setState({	offerlist: {}, productCount: -1, availableCompanies: {}, productname: productName, spinner: true});
		this.searchProductCount(productName, function(response) {
      if(response.data.totalCount < 50){
        self.onSearchProductFull(productName);
      }else{
        self.setState({searchStatus: true, });
        self.setState({	productCount: response.data.totalCount,
                        availableCompanies: response.data.suppliers.filter((e) => e.count > 0)});
        self.searchProductQuick(productName, function(response) {
          self.setState({searchStatus: true, productname: productName});
    			self.setState({	offerlist: response.data, spinner: false});

    		});
      }


		});
  };

  getOfferlist = () => {
    return this.state.offerlist;
  }

  onOfferClear = () => {
    this.setState({productname: "", offerlist: {suppliers: [], items: []}});
  }

  onSearchInactive = () => {
    this.setState({ searchStatus: false, productCount: -1, productname: "", availableCompanies: {}, offerlist: {suppliers: [], items: []}});
  }

  onTextBikeChange = value => {
		 	this.setState({textBike: value});
	 };

   onTextBikeClear = () => {
     this.setState({textBike: ""});
   }

   onTextSearchChange = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

   onDeleteFavoriteDone = (list) => {
 		if(list.length > 0){
 			let self = this;
 			this.interAxios.post('/api/deleteFavoriteBikes/', list, function(response) {
 			})
 		}
 	}

  setInterB2BikeTrue = () => {
    this.setState({interb2bike: true});
  }

  getInterB2Bike = () => {
    return this.state.interb2bike;
  }

  isRemoteBoersePage = () => {
    return location.hostname.includes('e-bikeboerse') || location.hostname.includes('xn--e-bikebrse-kcb.de')
  }

  isRemoteB2BPage = () => {
    return location.hostname.includes('interb2bike');
    //return true;
  }

  getBaseName = () => {
    if (this.isRemoteBoersePage() || this.isRemoteB2BPage()) {
      return '/';
    } else {
      return '/';
    }
  }



  userData = () => {
    return {
      getLatLng: this.getLatLng,
      spinner: this.state.spinner,
      onDeleteFavoriteDone: this.onDeleteFavoriteDone,
      getMyUser: this.getMyUser,
      onGetUser: this.onGetUser,
      onTextBikeChange: this.onTextBikeChange,
      onTextBikeClear: this.onTextBikeClear,
      textBike: this.state.textBike,
      onTextSearchChange: this.onTextSearchChange,
      userId: this.userId,
      userProfilName: this.userProfilName,
      anmeldenFromMarkt: this.anmeldenFromMarkt,
      onSearchProductFull: this.onSearchProductFull,
      onSearchProductQuick: this.onSearchProductQuick,
      onSearchProductCount: this.onSearchProductCount,
      onSearchProductWithCompanyFilter: this.onSearchProductWithCompanyFilter,
      offerlist: this.state.offerlist,
      productCount: this.state.productCount,
      availableCompanies: this.state.availableCompanies,
      searchStatus: this.state.searchStatus,
      productname: this.state.productname,
      onOfferClear: this.onOfferClear,
      onSearchInactive: this.onSearchInactive,
      isLoggedIn: this.isLoggedIn,
      isLoggedInMarkt: this.isLoggedInMarkt,
      setSeiteMarktTrue: this.setSeiteMarktTrue,
      setSeiteMarktFalse: this.setSeiteMarktFalse,
      isSeiteMarkt: this.isSeiteMarkt,
      name: this.name,
      partner: this.partner,
      token: this.token,
      sysAdmin: this.sysAdmin,
      //getUsername: this.getUsername,
      isGastAdmin: this.isGastAdmin,
      getTokenExpiry: this.getTokenExpiry,
      getCurrentCompany: this.getCurrentCompany,
      isCurrentCompanyAnonim: this.isCurrentCompanyAnonim,
      isCurrentCompanyModerator: this.isCurrentCompanyModerator,
      hasMoreCompanies: this.hasMoreCompanies,
      getMyInvitationslist: this.getMyInvitationslist,
      getOutgoingInvitationslist: this.getOutgoingInvitationslist,
      getAcquireCompanyWithSameEmail: this.getAcquireCompanyWithSameEmail,
      getUrlState: this.getUrlState,
      onSignIn: this.onSignIn,
      onLogout: this.onLogout,
      onLogoutfromMarkt: this.onLogoutfromMarkt,
      getTimeStamp: this.getTimeStamp,
      getUseremail: this.getUseremail,
      getMySubscription: this.getMySubscription,
      onMySubscription: this.onMySubscription,
      refresh: this.refresh,
      refreshNavbar: this.refreshNavbar,
      setInterB2BikeTrue: this.setInterB2BikeTrue,
      getInterB2Bike: this.getInterB2Bike,
      isRemoteBoersePage: this.isRemoteBoersePage,
      isRemoteB2BPage: this.isRemoteB2BPage,
      getBaseName: this.getBaseName,
      onUnauthorizedError: this.onUnauthorizedError
    };
  }

  render() {
    setGlobalUserData(this.userData());
    return (
      <App userData={this.userData()}/>
    );
  }
}

export default UserDataComponent;
