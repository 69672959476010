import React, { Component } from "react";

class LazyLoadItem extends Component {

  componentDidMount() {
    if (this.props.index == this.props.size-4 && this.props.loadFunction !== undefined) {
      this.props.loadFunction();
    }
  }

  render() {
    //console.log(this.props.index)
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

export default LazyLoadItem;
