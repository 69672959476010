import React, { Component } from "react";
import PersonSectionBike from "./PersonSectionBike";


class SectionBike extends Component {

  constructor(props) {
    super(props);
  };

  render() {
    return (
  		<PersonSectionBike
  				selecetdChatroom	= {this.props.selecetdChatroom}
  				statusText = {this.props.statusText}
          countInt = {this.props.countInt}
  		/>
    );
  }
}
export default SectionBike;
