import React, { Component } from "react";
import ReactDOM from 'react-dom';
import BikePageResponziv from './BikePage/BikePageResponziv';
import BikePageDesktop from './BikePage/BikePageDesktop';

class BikePage extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };

  render() {

    return (
			<>
				{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<BikePageDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<BikePageResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
			</>
    );
  }
}

export default  BikePage;
