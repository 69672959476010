import React, { Component } from "react";
import ReactDOM from 'react-dom';
import FavoriteDesktop from './Favorite/FavoriteDesktop';
import FavoriteResponziv from './Favorite/FavoriteResponziv';

class Favorite extends Component {
	constructor(props) {
		super(props);
		this.state = {
									};
	};

  render() {

    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<FavoriteDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<FavoriteResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
			</>

    );
  }
}

export default  Favorite;
