import React, { Component } from "react";
import { MDBListGroupItem, MDBCardBody, MDBCard, MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import Moment from 'moment';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import readmore from "../../assets/media/images/markt/readmore.png";

class Interb2bNewsItemsFirst extends Component {
  constructor(props) {
    super(props);
  };

render() {
    let prevDate = Moment(Date.now());
    let prevDate1 = Moment(Date.now()).subtract(1, 'days');
    let prevDate2 = Moment(Date.now()).subtract(2, 'days');
    let tsmoment = Moment(this.props.msg.pubDate);
    let html = this.props.msg['content:encoded'];
    let parser = new DOMParser();
    let wrapper = parser.parseFromString(html, 'text/html');
    let imgs = wrapper.getElementsByTagName('img');
    return (
      <MDBCol md="12" size="12" className="mb-3 px-1 first-card-col">
        <MDBCard className="news-card-borse-first">
        <MDBRow className="m-0">
          <MDBCol middle md="6" size="6" className="pl-2">
          <img alt="" className="news-image-borse-first" src={imgs[0].getAttribute('src')}></img>
          </MDBCol>
          <MDBCol md="6" size="6">
            <div className="d-none d-lg-block">
              <MDBRow className="m-0 px-2 pb-3 pt-5">
                <span className="news-title-borse-first">{this.props.msg.title}</span>
              </MDBRow>
              <MDBRow className="m-0 px-2 pt-4">
                <MDBCol middle md="6" className="m-0 px-0 text-left">
                  <span className="news-date-text-borse">UPDATE {tsmoment.locale(this.props.i18n.language).format('DD MMMM YYYY')}</span>
                </MDBCol>
                <MDBCol middle md="6" className="m-0 px-0 text-center">
                <div className="news-url-borse text-center">
                    <span className="news-url-text-borse">{this.props.msg.creator}</span>
                </div>
                </MDBCol>
                <MDBCol middle md="3" className="m-0 px-0 text-right">
                  <a
                    className={this.props.b2bplattform ? "news-btn-b2b" :"news-btn-borse"}
                    href={this.props.msg.link}
                    target="_blank" rel="noopener noreferrer"
                  >
                    <span className="news-btn-text-borse">Read</span><img alt="" className="news-btn-icon-borse" src={readmore}></img>
                  </a>
                </MDBCol>
              </MDBRow>
            </div>
            <div className="d-lg-none d-block">
              <MDBRow className="m-0 px-0 pb-2 pt-2">
                <span className="news-title-borse-first">{this.props.msg.title}</span>
              </MDBRow>
              <MDBRow className="m-0 px-0 pt-0">
                <MDBCol middle md="6" size="12" className="m-0 px-0 pb-1 text-center">
                  <span className="news-date-text-borse">UPDATE {tsmoment.locale(this.props.i18n.language).format('DD MMMM YYYY')}</span>
                </MDBCol>
                <MDBCol middle md="6" size="12" className="m-0 px-0 py-1 text-center">
                <div className="news-url-borse text-center">
                    <span className="news-url-text-borse">{this.props.msg.creator}</span>
                </div>
                </MDBCol>
                <MDBCol middle md="12" size="12" className="m-0 px-0 pt-1 text-center">
                  <a
                    className={this.props.b2bplattform ? "news-btn-b2b" :"news-btn-borse"}
                    href={this.props.msg.link}
                    target="_blank" rel="noopener noreferrer"
                  >
                    <span className="news-btn-text-borse">Read</span><img alt="" className="news-btn-icon-borse" src={readmore}></img>
                  </a>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
        </MDBCard>
      </MDBCol>
    );
  }
}

export default withTranslation()(Interb2bNewsItemsFirst);
