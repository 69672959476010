import React, { Component, Fragment } from "react";
import { Redirect } from 'react-router';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import IhrNetzwerk from '../../../../components/IhrNetzwerk/IhrNetzwerk';
import HerstellerAnzegeKarte from '../../../../components/Companys/HerstellerAnzegeKarte';
import HerstellerProfileB2BPublic from '../../../../components/MyProfile/HerstellerProfileB2BPublic';
import PaginationPage from '../../../../components/PaginationPage/PaginationPage';
import SonstigeNavB2B from '../../../../components/NavbarComponentB2B/SonstigeNavB2B';
import FooterB2BMainPage from '../../../../components/Footer/FooterB2BMainPage';
import styles from '../../../../assets/scss/modules/pages.module.scss';
import InterB2BAxios from '../../../../Service/InterB2BAxios';
import logo from "../../../../assets/media/logo/1.png";
import greaticon from "../../../../assets/media/images/b2b/hersteller.png";
import ib2bicon from "../../../../assets/media/images/ib2b.png";
import searchnormalicon from "../../../../assets/media/newicons/search-normal.png";


import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBCollapse,
	MDBCollapseHeader,
	MDBIcon,
	MDBBtn,
	MDBScrollbar,
	MDBAutocomplete,
	MDBAnimation,
	MDBSideNav
} from 'mdbreact';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
let urlName = '';
let companyName = '';
class CompaniesPageHandlerDesktop extends Component {

  constructor(props) {
    super(props);
		this.messagesEndRef = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'CompaniesPageHandler');
    this.state = {allCompanies: [],
									site: 0,
									currentCompany: {},
									pagingstatus: true,
									closeHeaderAnimationRunning: false,
									closeCompanyAnimationRunning: false,
									loading: false,
									headerCollapseOpen: false,
									companyCollapseOpen: false,
									nextCurrentCompany: undefined,
									activeItem: "3"
									};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		var params = window.location.href.split("/");
		urlName = params[params.length - 1];
		companyName = urlName.replace(/-/g, ' ');

		//this.onPageLoad();
		//this.props.userData.refreshNavbar();
		this.onAllCompanyByPageable(this.state.site);
		if (this.props.match?.params?.id !== undefined){
			this.onFindCompanyById(this.props.match?.params?.id);
		} else {
			this.openHeaderCollapse();
		}
		//this.props.onIsMoreCompanies();
		this.onAllCompanyName();
		this.messagesEndRef.current.scrollIntoView({

 	 }); //  behavior: "smooth",
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match?.params?.id !== this.props.match?.params?.id) {
			this.onAllCompanyByPageable(this.state.site);
			this.onFindCompanyById(this.props.match?.params?.id);
		}
		this.messagesEndRef.current.scrollIntoView({

    }); //behavior: "smooth",
	};

	componentWillUnmount() {
		this.interAxios.cancel();
	}


	onFindCompanyById = (companyId) => {
		this.closeHeaderCollapse();
		this.closeCompanyCollapse();
		this.setState({
			loading: true,
			currentCompany: {},
		})
		var self = this;
    this.interAxios.get('/api/findCompanyById/' + companyId, function(response) {
          self.setState({
						nextCurrentCompany: response.data,
						loading: false
					});
					self.checkToUpdateCompany();
					self.openCompanyCollapse();

    })

	}

	onCompanyNameSearchChange = value => {
		 this.setState({companyName: value});
	};

	onFindCompanyByName = event => {
	 this.setState({allCompanies: []});
	 let self = this;
	 this.interAxios.get('/api/companiesHandler/', {
			 params: { namePattern: this.state.companyName},
		 }, function(response){
		 self.setState({allCompanies: response.data,
			 							companyName: '',
		 								totalElements: response.data.length,
										pagingstatus: false});
	 })
	};

	onAllCompanyName = () => {
		let self = this;
		this.interAxios.get('/api/allCompanyNameFullHandler', function(response) {
  		self.setState({	nameList: response.data});
  	});
 };

	onAllCompanyByPageable = (site) =>{
    var self = this;
    this.interAxios.get('/api/companiesByPageHandler/' + site, function(response) {
          self.setState({allCompanies: response.data.content,
												 totalPages: response.data.totalPages,
												 totalElements: response.data.totalElements,
											 	 pagingstatus: response.data.totalPages > 1 ? true : false});
    })
  };

toggleCollapse = collapseID => () => {
	this.setState(prevState => ({
		collapseID: prevState.collapseID !== collapseID ? collapseID : ""
	}));
}

handleChatClick = (companyId) => {
};

onWiederRufen = (page) => {
	this.setState({site: page});
	this.onAllCompanyByPageable(page);
}

handleResetCurrentCompany = () => {
	this.closeCompanyCollapse();
	this.openHeaderCollapse();
}

onPageLoad = () => {
	this.openHeaderCollapse();
}

openHeaderCollapse = () => {
	setTimeout(function(){
		this.setState({headerCollapseOpen: true});
	}.bind(this), 300);
}

openCompanyCollapse = () => {
		this.setState({companyCollapseOpen: true});
}

closeHeaderCollapse = () => {
	this.setState({
		headerCollapseOpen: false,
		closeHeaderAnimationRunning: this.state.headerCollapseOpen
	});
}

closeCompanyCollapse = () => {
	this.setState({
		companyCollapseOpen: false,
		closeCompanyAnimationRunning: this.state.companyCollapseOpen
	});
}

checkToUpdateCompany = () => {
	if (this.state.nextCurrentCompany !== undefined && !this.state.loading && !this.state.closeCompanyAnimationRunning) {
		this.setState({
			currentCompany: this.state.nextCurrentCompany,
			companyPhotosUrl: this.state.nextCurrentCompany.urls,
			nextCurrentCompany: undefined
		});
	}
}



  render() {

		const { t } = this.props;
		const client = this.props.client;
		let companiesLis =[];
    if (this.state.allCompanies.length>0){
      companiesLis = this.state.allCompanies.map(

          (o) =>
							<HerstellerAnzegeKarte
									key={o.id}
									msg={o}
									handleChatClick={this.handleChatClick}
									followed = {true}
									allCompany = {true}
									onFindCompanyById= {this.onFindCompanyById}
												/>

        );
    }

    return (
<>
<SonstigeNavB2B userData={this.props.userData} activeItem={this.state.activeItem}/>
<div ref={this.messagesEndRef}></div>
<div className={styles.bodywrapper}>
	<MDBCollapse id="basicCollapse-profile" className="fullPageCollapse"
			isOpen={this.state.companyCollapseOpen && !this.state.loading && !this.state.closeHeaderAnimationRunning && !this.state.closeCompanyAnimationRunning}
			delay={{show: 2000, hide: 2000}}
			onOpened={()=>{ console.log('company opened');}}
			onClosed={()=>{
				this.setState({closeCompanyAnimationRunning: false});
				this.checkToUpdateCompany();}}
	>
			<HerstellerProfileB2BPublic
						status={false}
						public={false}
						{...this.props}
						userData= {this.props.userData}
						currentCompany={this.state.currentCompany}
						companyPhotosUrl = {this.state.companyPhotosUrl}
						handleResetCurrentCompany = {this.handleResetCurrentCompany}
						companySubscription = {this.state.companySubscription}
						contactStatus = {true}
			/>
		<MDBContainer>
			<hr style={{marginTop: ".7rem", marginBottom: "1rem", borderTop: "2px solid rgb(255, 255, 255)"}}/>
			<MDBRow className="align-items-center pb-3">
				<MDBCol className= "text-center">
					<p className="font-weight-bolder text-muted" style={{fontSize: "18px"}}>{t('andere_firmen')}</p>
				</MDBCol>
			</MDBRow>
		</MDBContainer>
	</MDBCollapse>

	<MDBContainer>
		<MDBCollapse id="headerCollapse" className="pageTopCollapse"
				isOpen={this.state.headerCollapseOpen && !this.state.closeHeaderAnimationRunning && !this.state.closeCompanyAnimationRunning}
				delay={{show: 900, hide: 900}}
				onClosed={()=>{
					this.setState({closeHeaderAnimationRunning: false});
					this.checkToUpdateCompany();}}
				>
			<MDBRow>
				<MDBCol size="4" md="2" className="text-center">
					<img className="w-100" src={greaticon} />
				</MDBCol>
				<MDBCol size="8" md="5" className="pl-4">
					<MDBRow className="pt-4">
					<small className="find-supplier-grow">Find supplier, grow your business!</small>
					</MDBRow>
					<MDBRow className="py-3">
					<small className="join-meetings-easily">Join meetings easily with this integrated feature. Create videoconference by choosing a day!</small>
					</MDBRow>
				</MDBCol>
				<MDBCol middle size="12" md="5">
				<div className="hersteller-such-form">
					<MDBRow className="text-center py-3" style={{borderLeft: "2px solid rgb(255, 255, 255)"}}>
						<MDBCol middle size="9" md="auto" className="search-col pl-2">
							<MDBAutocomplete data={this.state.nameList}
																label={t('kontakt_suchen')}
																scrollbar
																id="input2"
																className="search-input"
																getValue={this.onCompanyNameSearchChange}
																value={this.state.companyName}
							/>
						</MDBCol>
						<MDBCol middle size="3" md="2" className= "text-right pr-4">
							<MDBBtn	type="button"
											className="hersteller-search-btn"
											onClick={(event) => this.onFindCompanyByName(event)}><img src={searchnormalicon} />
							</MDBBtn>
						</MDBCol>
					</MDBRow>
				</div>
				</MDBCol>
			</MDBRow>
			</MDBCollapse>
				{companiesLis.length>0 ?
					<>
					<MDBRow center className="pt-5">
						{companiesLis}
					</MDBRow>
					<MDBCol className="py-3 px-0">
					{this.state.pagingstatus ?
					<PaginationPage
									{...this}
									{...this.props}
									{...this.state}
					/>:false}
					</MDBCol>
					</>:false}
				<div style={{height: "100px"}}/>
				</MDBContainer>
				<div className="rectangle-footer-markt">
					<MDBContainer style={{maxWidth: "1250px"}}>
						<FooterB2BMainPage/>
					</MDBContainer>
				</div>
</div>

</>
    );
  }
}

export default withTranslation()(CompaniesPageHandlerDesktop);
