import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import{
	MDBCard,
	MDBCardBody,
	MDBRow,
	MDBCol
} from 'mdbreact';

class Partners extends Component {

	render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<MDBCard className="transparent-card px-3 text-center">
				<MDBRow center>
					<p className="font-weight-bold">{t('partnerunternehmen')}</p>
				</MDBRow>
				<MDBRow center>
					<a 	className="partner-logo"
							href="https://www.studicore.hu/about"
							target="_blank" rel="noopener noreferrer">
							<img src="https://www.studicore.hu/img/StudiCore_logo-300x80.png" className="img-fluid pb-2" />
					</a>
				</MDBRow>
				<MDBRow center>
					<a 	className="partner-logo"
							href="https://appentum.de"
							target="_blank" rel="noopener noreferrer">
							<img src="https://appentum.de/img/logo.png" className="img-fluid py-2" />
					</a>
				</MDBRow>
			</MDBCard>
    );
  }
}
export default withTranslation()(Partners);
