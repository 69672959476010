import React, { Component } from "react";
import ReactDOM from 'react-dom';
import BlogPageHandlerDesktop from './BlogPageHandler/BlogPageHandlerDesktop';
import BlogPageHandlerResponziv from './BlogPageHandler/BlogPageHandlerResponziv';

class BlogPageHandler extends Component {

	constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
	return (
		<>{!window.matchMedia('(max-device-width: 992px)').matches ?
			<div className="d-none d-lg-block">
				<BlogPageHandlerDesktop userData={this.props.userData}
																 addFunctionFromChild = {this.props.addFunctionFromChild}
																 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																 {...this.props}
																 />
			</div>:
			<div className="d-lg-none d-block">
				<BlogPageHandlerResponziv userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
			</div>}
		</>
    );
  }
}

export default  BlogPageHandler;
