import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_first from "../../../assets/media/images/markt/profil/Group 7.png";
import logo from "../../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import addBike from "../../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import anmelden from "../../../assets/media/images/markt/anmelden.png";
import searchnormalicon from "../../../assets/media/images/markt/searchnormalicon.png";
import logo2 from "../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../../assets/media/images/markt/Group 24.png";
import logo4 from "../../../assets/media/images/markt/Group 19.png";
import logo5 from "../../../assets/media/images/markt/Group 20.png";
import profile_Boda from "../../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../../assets/media/images/b2b/Group 8.png";
import group_21 from "../../../assets/media/images/markt/Group 21.png";
import rectangle_1 from "../../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../../assets/media/images/b2b/Interb2b.png";
import BoersePageNavRespo from '../../../components/NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from '../BoersePage/MenuSideNavBoerse';
import InterB2BAxios from '../../../Service/InterB2BAxios';
import FooterMarktLanding from '../../../components/Footer/FooterMarktLanding';
import GyikModul from "../../../components/Gyik/GyikModul";
import Interb2bNews from '../../../components/News/Interb2bNews';
import MitteilungenItem from '../../../components/Companys/MitteilungenItem';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../components/LazyLoad/LazyLoadItem';
import SonstigeNav from '../../../components/NavbarComponent/SonstigeNav';
import GoogleMaps5 from '../../../components/GoogleMaps/GoogleMaps5';
import ProfilpageNav from '../../../components/NavbarComponent/ProfilpageNav';
import ProfilpageNavRespo from '../../../components/NavbarComponent/ProfilpageNavRespo';
import styles from '../../../assets/scss/modules/profilpage.module.scss';
import Cookies from 'universal-cookie';
import { Scrollbar } from "react-scrollbars-custom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
//import searchnormalicon from "../../assets/media/newicons/edit.png";
import trahsicon from "../../../assets/media/newicons/trash.png";
import plusimgicon from "../../../assets/media/images/markt/profil/gallery.png";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
const myregexp = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.*([^\s]{11})/gm;
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBIcon,
	MDBSideNav
} from 'mdbreact';

const cookies = new Cookies();
const containerStyle = {
  width: '100%',
  height: '280px'
};
let language = '';
let urlName = '';
let auswahlenText = '';
class BikeInserierenUpdateResponziv extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefInterB2B = React.createRef();
		this.listInnerRefNews = React.createRef();
		this.listInnerRefInfo = React.createRef();
		this.listInnerRefContact = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'BikeInserieren');
    this.state = {redirect: false,
									deleteList: [],
								  latitude: "",
									longitude: "",
									collapseID: -1,
									kategorie: this.props.location?.state?.msg.kategorie !== undefined ? this.props.location.state.msg.kategorie : '',
									eintragstyp: this.props.location?.state?.msg.eintragstyp !== undefined ? this.props.location.state.msg.eintragstyp : '',
									addBike: false,
									inserieren: false,
									backEintragsTyp: false,
				          selectedFiles: [],
									mitteilungenlist: [],
									rahmengroseList: [],
									laufradgroseList: [],
									rahmenformenList: [],
									motorpositionList: [],
									akkupositionList: [],
									schaltartList: [],
									verfugbarkeitList: [],

									photosList: this.props.msg.ebikeProductPhotosUrl !== undefined ? this.props.msg.ebikeProductPhotosUrl : [],
								  videoLink: this.props.msg.videoLink !== undefined ? this.props.msg.videoLink : '',
							    title: this.props.msg.title !== undefined ? this.props.msg.title : '',
							    preis: this.props.msg.preis !== undefined ? this.props.msg.preis : '',
							    article: this.props.msg.article !== undefined ? this.props.msg.article : '',
								  kilometerstand: this.props.msg.kilometerstand !== undefined ? this.props.msg.kilometerstand : '',
								  akkukapazitaet: this.props.msg.akkukapazitaet !== undefined ? this.props.msg.akkukapazitaet : '',
								  hersteller: this.props.msg.hersteller !== undefined ? this.props.msg.hersteller : '',
								  modell: this.props.msg.modell !== undefined ? this.props.msg.modell : '',
								  baujahr: this.props.msg.baujahr !== undefined ? this.props.msg.baujahr : '',
								  rahmengrose: this.props.msg.rahmengrose !== undefined ? this.props.msg.rahmengrose : '',
								  laufradgrose: this.props.msg.laufradgrose !== undefined ? this.props.msg.laufradgrose : '',
								  rahmenformen: this.props.msg.rahmenformen !== undefined ? this.props.msg.rahmenformen : '',
								  motorposition: this.props.msg.motorposition !== undefined ? this.props.msg.motorposition : '',
								  akkuposition: this.props.msg.akkuposition !== undefined ? this.props.msg.akkuposition : '',
								  schaltart: this.props.msg.schaltart !== undefined ? this.props.msg.schaltart : '',
								  verfugbarkeit: this.props.msg.verfugbarkeit !== undefined ? this.props.msg.verfugbarkeit : '',
								  standort: this.props.msg.standort !== undefined ? this.props.msg.standort : '',
									anzeigeId: this.props.msg.id !== undefined ? this.props.msg.id : 0,
									activeItem: this.props.msg.aktuelleStatus !== undefined ? this.props.msg.aktuelleStatus +"" : "1",
									lat: this.props.msg.lat !== undefined ? this.props.msg.lat : -200,
									lng: this.props.msg.lng !== undefined ? this.props.msg.lng : -200,
									};
		const { t } = this.props;
    const client = this.props.client;
		auswahlenText = t('auswahlentext');
  };

	componentDidMount() {
		this.onRefInterB2B();
		//this.interAxios.refreshUserData(this.props.userData);
		var params = window.location.href.split(".");
	  language = params[params.length - 1];
		var params2 = window.location.href.split("/");
		urlName = params2[params2.length - 1];
		this.onRahmengrosse();
		this.onLaufradgrosse();
		this.onRahmenformen();
		this.onMotorposition();
		this.onAkkuposition();
		this.onSchaltart();
		this.onVerfugbarkeit();
		let self = this;
		navigator.geolocation.getCurrentPosition(function(position) {
				self.setState({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				});
		});
		document.addEventListener("scroll", this.scrollEventListener);
		let newSelectedPhotos = [];
		if (this.state.photosList !== undefined && this.state.photosList.length>0){
			for (const companyPhoto of this.state.photosList){
				let urlArray = companyPhoto.split("\/");
				let name = urlArray[urlArray.length-1];
				newSelectedPhotos.push({file: {}, url: companyPhoto, name: name, server: true});
			}
		}
		this.setState({selectedFiles: newSelectedPhotos})
	}

	componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {

    }
  }

	componentWillUnmount() {
		document.removeEventListener("scroll", this.scrollEventListener);
		this.interAxios.cancel();
	}

	onRefInterB2B = () => {
		this.listInnerRefInterB2B.current.scrollIntoView({
	 });

	}


	onAddBike = () => {
		this.setState({addBike: true});
	}

	onBackEintragsTyp = () => {
		this.setState({backEintragsTyp: true});
	}

	onInserieren = (eintargstyp) => {
		this.setState({eintargstyp: eintargstyp, inserieren: true});
	}

	onImagesSelected = (e) => {
		this.setState({ images: e.target.files });
	}

	renderPhotos = (source) => {
		return source.map((photo) => {
			return <div key={photo.url} >
						 <img style={{ height: '100px'}} src={photo.url} className="photos-content py-2"/>
						 <MDBBtn
						 			tag="a"
									className="m-0"
									floating
									color="elegant"
									onClick={() => this.onResetPhotos(photo)}
									style={{height: "auto", width: "auto", position: 'relative', top: '-27px', right: '14px', transform: 'translate(-50%)'}}>
			         <img src={trahsicon} style={{width: "60%"}} className="py-1"/>
			       </MDBBtn>
						 </div>
		})
	}


	onArticleChange = (editorState) => {
		let videoLink = '';
		let text = '';
		if (editorState.match(myregexp)){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(editorState.match(myregexp), 'text/html');
			text =parsedHtml.documentElement.textContent;

	    if (text.startsWith("https://www.youtube.com/watch")) {
				let newVideoLink = text.replace("watch?v=", "embed/");
				let andIndex = newVideoLink.indexOf("&");
				if (andIndex != -1) {
					videoLink = newVideoLink.substring(0, andIndex);
				} else {
					videoLink = newVideoLink;
				}
			} else {
				videoLink = text;
			}
			this.setState({videoLink: videoLink});
		}
		this.setState({article: editorState.replace(editorState.match(myregexp), '')});

	}

	onVideoLinkChange = (editorState) => {
	  this.setState({videoLink: editorState});
	}

	onTitleChange = (editorState) => {
	  this.setState({title: editorState});
	}

	onResetPhotos = (photo) => {
    let newSelectedPhotos = this.state.selectedFiles.filter(function(value) {
        return value.url !== photo.url;
    })

    this.setState({
      selectedFiles: newSelectedPhotos
    });
      this.state.deleteList.push(photo.name);
	}


	onResetVideoLink =() => {
	  this.setState({ videoLink: '', title: ''});
	}

	onResetArticle =() => {
	  this.setState({ article: '', title: ''});
	}

	onSelectedFilesDelete = () => {
		this.setState({selectedFiles: []});
	}

	onRahmengrosse = () => {
    let self = this;
    self.setState({rahmengroseList: []});
    this.interAxios.get('/api/rahmengroese', function(response) {
      self.setState({rahmengroseList: response.data});
    });

  }

	onSetRahmengrosse = value => {
		if (value === auswahlenText) {
			this.setState({rahmengrose: "---"});
		}else{
			this.setState({rahmengrose: value});
		}
	}

	onLaufradgrosse = () => {
    let self = this;
    self.setState({laufradgroseList: []});
    this.interAxios.get('/api/laufradgroese', function(response) {
      self.setState({laufradgroseList: response.data});
    });

  }

	onSetLaufradgrosse = value => {
		if (value === auswahlenText) {
			this.setState({laufradgrose: "---"});
		}else{
			this.setState({laufradgrose: value});
		}
	}

	onRahmenformen = () => {
    let self = this;
    self.setState({rahmenformenList: []});
    this.interAxios.get('/api/rahmenformen', function(response) {
      self.setState({rahmenformenList: response.data});
    });

  }

	onSetRahmenformen = value => {
		if (value === auswahlenText) {
			this.setState({rahmenformen: "---"});
		}else{
			this.setState({rahmenformen: value});
		}
	}

	onMotorposition = () => {
    let self = this;
    self.setState({motorpositionList: []});
    this.interAxios.get('/api/motorposition', function(response) {
      self.setState({motorpositionList: response.data});
    });

  }

	onSetMotorposition = value => {
		if (value === auswahlenText) {
			this.setState({motorposition: "---"});
		}else{
			this.setState({motorposition: value});
		}
	}

	onAkkuposition = () => {
    let self = this;
    self.setState({akkupositionList: []});
    this.interAxios.get('/api/akkuposition', function(response) {
      self.setState({akkupositionList: response.data});
    });

  }

	onSetAkkuposition = value => {
		if (value === auswahlenText) {
			this.setState({akkuposition: "---"});
		}else{
			this.setState({akkuposition: value});
		}
	}

	onSchaltart = () => {
    let self = this;
    self.setState({schaltartList: []});
    this.interAxios.get('/api/schaltart', function(response) {
      self.setState({schaltartList: response.data});
    });

  }

	onSetSchaltart = value => {
		if (value === auswahlenText) {
			this.setState({schaltart: "---"});
		}else{
			this.setState({schaltart: value});
		}
	}

	onVerfugbarkeit = () => {
    let self = this;
    self.setState({verfugbarkeitList: []});
    this.interAxios.get('/api/verfuegbarkeit', function(response) {
      self.setState({verfugbarkeitList: response.data});
    });

  }

	onSetVerfugbarkeit = value => {
		if (value === auswahlenText) {
			this.setState({verfugbarkeit: "---"});
		}else{
			this.setState({verfugbarkeit: value});
		}
	}

	changeHandler = event => {
     this.setState({ [event.target.name]: event.target.value });
   };

	 onNewBikeProductUpdate = (event) => {
		 const form = event.currentTarget;
		 event.preventDefault();
		 event.target.className += " was-validated"
		 if((form.checkValidity() !== false)){
			 this.handleDone();
			 let self = this;
			 const formData = new FormData
			 formData.append("anzeigeId", this.state.anzeigeId);
			 formData.append("videoLink", this.state.videoLink);
			 formData.append("title", this.state.title);
			 formData.append("preis", this.state.preis);
			 formData.append("article", this.state.article);
			 formData.append("kilometerstand", this.state.kilometerstand);
			 formData.append("akkukapazitaet", this.state.akkukapazitaet);
			 formData.append("hersteller", this.state.hersteller);
			 formData.append("modell", this.state.modell);
			 formData.append("baujahr", this.state.baujahr);
			 formData.append("rahmengrose", this.state.rahmengrose);
			 formData.append("laufradgrose", this.state.laufradgrose);
			 formData.append("rahmenformen", this.state.rahmenformen);
			 formData.append("motorposition", this.state.motorposition);
			 formData.append("akkuposition", this.state.akkuposition);
			 formData.append("schaltart", this.state.schaltart);
			 formData.append("verfugbarkeit", this.state.verfugbarkeit);
			 formData.append("standort", this.state.standort);
			 formData.append("kategorie", this.state.kategorie);
			 formData.append("eintragstyp", this.state.eintragstyp);

			 this.interAxios.put('/api/newBikeProductUpdate', formData, {
				 headers: {
					 'Content-Type': 'multipart/form-data'
				 }
			 }, function(response) {
				 	self.setState({redirect: true});
			 })
		 }
	 };

	 onUpdateLatLng = () => {
 		this.interAxios.get('/api/updatelatlong/' + 50, function(response) {
 		})
 	};

	onFileChange = (e) => {
		let list = this.state.selectedFiles;
    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];

      list.push({file: file, url: URL.createObjectURL(file), name: file.name, server: false});
    }
		this.setState({
  			selectedFiles: list,
				uploadFiles: e.target.files
  		});

  }

// DONE gomb
	handleDone = () => {
		this.onFileDelete(this.state.deleteList);
    // TODO: backend kolcsonos kizaras
    //this.onFileUpload(this.state.selectedPhotos);
		this.setState({editPhotos: false, deleteList: []});
  }


  // DONE gomb: kepek torlese
	onFileDelete = (imageNames) => {
    let self = this;
    this.interAxios.post('/storage/newEbike/'+ this.state.anzeigeId +'/photos', imageNames, function(response) {
      // TODO: backend: kolcsonos kizaras
      self.onFileUpload(self.state.uploadFiles);
    });
  }

  // DONE gomb: kepek feltoltese
  onFileUpload = (images) => {
    if(images.length>0){
      const formData = new FormData
      for (let i = 0; i < images.length; i++) {
        formData.append("files", images[i], images[i].name);
      }
      this.interAxios.post('/upload_ebike_photos/' + this.state.anzeigeId, formData, {
        'Content-Type': 'multipart/form-data'
      }, function(response) {});
    }
  }

	toggle = tab => e => {
						if (this.state.activeItem !== tab) {
							this.setState({
								activeItem: tab});	}
						if (tab == 1) {
							this.onSetStatus(1);
						}
						if (tab == 2) {
							this.onSetStatus(2);
						}
						if (tab == 3) {
							this.onSetStatus(3);
						}
						if (tab == 4) {
							this.onSetStatus(4);
						}

	}

	onSetStatus = (status) => {
    let self = this;
    this.interAxios.post('/api/status/' + this.state.anzeigeId + "/" + status , function(response) {

    });

  }

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		let rahmengroseLis = {};
    rahmengroseLis = this.state.rahmengroseList.map(
      (o) =>
              <MDBSelectOption key={o} value={o}>{o}</MDBSelectOption>
    );
		if(this.state.rahmengrose === `---`){
      rahmengroseLis.unshift(<MDBSelectOption key="---" value="---" disabled>{auswahlenText}</MDBSelectOption>);
    }else{
      rahmengroseLis.unshift(<MDBSelectOption key="---" value="---" >{auswahlenText}</MDBSelectOption>);
    }
		let laufradgroseLis = {};
    laufradgroseLis = this.state.laufradgroseList.map(
      (o) =>
              <MDBSelectOption key={o} value={o}>{o}</MDBSelectOption>
    );
		if(this.state.laufradgrose === `---`){
      laufradgroseLis.unshift(<MDBSelectOption key="---" value="---" disabled>{auswahlenText}</MDBSelectOption>);
    }else{
      laufradgroseLis.unshift(<MDBSelectOption key="---" value="---" >{auswahlenText}</MDBSelectOption>);
    }
		let rahmenformenLis = {};
    rahmenformenLis = this.state.rahmenformenList.map(
      (o) =>
              <MDBSelectOption key={o} value={o}>{o}</MDBSelectOption>
    );
		if(this.state.rahmenformen === `---`){
      rahmenformenLis.unshift(<MDBSelectOption key="---" value="---" disabled>{auswahlenText}</MDBSelectOption>);
    }else{
      rahmenformenLis.unshift(<MDBSelectOption key="---" value="---" >{auswahlenText}</MDBSelectOption>);
    }
		let motorpositionLis = {};
    motorpositionLis = this.state.motorpositionList.map(
      (o) =>
              <MDBSelectOption key={o} value={o}>{o}</MDBSelectOption>
    );
		if(this.state.motorposition === `---`){
      motorpositionLis.unshift(<MDBSelectOption key="---" value="---" disabled>{auswahlenText}</MDBSelectOption>);
    }else{
      motorpositionLis.unshift(<MDBSelectOption key="---" value="---" >{auswahlenText}</MDBSelectOption>);
    }
		let akkupositionLis = {};
    akkupositionLis = this.state.akkupositionList.map(
      (o) =>
              <MDBSelectOption key={o} value={o}>{o}</MDBSelectOption>
    );
		if(this.state.akkuposition === `---`){
      akkupositionLis.unshift(<MDBSelectOption key="---" value="---" disabled>{auswahlenText}</MDBSelectOption>);
    }else{
      akkupositionLis.unshift(<MDBSelectOption key="---" value="---" >{auswahlenText}</MDBSelectOption>);
    }
		let schaltartLis = {};
    schaltartLis = this.state.schaltartList.map(
      (o) =>
              <MDBSelectOption key={o} value={o}>{o}</MDBSelectOption>
    );
		if(this.state.schaltart === `---`){
      schaltartLis.unshift(<MDBSelectOption key="---" value="---" disabled>{auswahlenText}</MDBSelectOption>);
    }else{
      schaltartLis.unshift(<MDBSelectOption key="---" value="---" >{auswahlenText}</MDBSelectOption>);
    }
		let verfuegbarkeitLis = {};
    verfuegbarkeitLis = this.state.verfugbarkeitList.map(
      (o) =>
              <MDBSelectOption key={o} value={o}>{o}</MDBSelectOption>
    );
		if(this.state.verfugbarkeit === `---`){
      verfuegbarkeitLis.unshift(<MDBSelectOption key="---" value="---" disabled>{auswahlenText}</MDBSelectOption>);
    }else{
      verfuegbarkeitLis.unshift(<MDBSelectOption key="---" value="---" >{auswahlenText}</MDBSelectOption>);
    }

    return (
			<>
			<div ref={this.listInnerRefInterB2B}></div>
			<BoersePageNavRespo userData={this.props.userData}
													onMenuOpen = {this.props.handleSideNavToggle}
													isUserMenu={true}
													activeItem = {"1"}/>
			<div className={styles.bodywrapper}>

				<div className="side-nav-menu">
					<MDBSideNav
						right
						triggerOpening={this.props.isSideNavOpen}
						onOverlayClick={this.props.handleSideNavToggle}
						breakWidth={1300}
						className="deep-purple darken-4"

					>
						<MenuSideNavBoerse
												userData={this.props.userData}
												toggle = {this.toggle}
												onMenuClose = {this.props.onEnableScroll}
												activeItem = {this.state.activeItem}
						/>
					</MDBSideNav>
				</div>
				<div className="profil-background">
						<MDBRow className="pt-0 m-0">
							<MDBCol size="12" className="px-1">
							<MDBRow center className="m-0 pt-0 pb-3">
									<img alt="" className="e-bikeborse-logo-first-eigene" src={logo_first}></img>
									<span className="landingpage-text-eigene">{t('inserieren')}</span>
							</MDBRow>
								<div className="bike-status-filter">
									<MDBNav className="justify-content-center bike-status-nav">
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab" >
												<span className="profilpage-menu">{t('Active')}</span>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
												<span className="profilpage-menu">{t('Reserviert')}</span>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" >
												<span className="profilpage-menu">{t('Widerrufen')}</span>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "4"} onClick={this.toggle("4")} role="tab" >
												<span className="profilpage-menu">{t('Geloscht')}</span>
											</MDBNavLink>
										</MDBNavItem>
									</MDBNav>
								</div>
								<form className="row g-3 needs-validation m-0" onSubmit={async (event) => {await this.onNewBikeProductUpdate(event);}} noValidate>
									<div className="col-md-12">
										<div className="form-outline-markt">
												<label htmlFor="validationCustom01" className="form-label pt-2">{t('Titel_des_Eintrags') + "*"}</label>
												<input type="text" className="form-control text-muted" name="title" onChange={this.changeHandler} value={this.state.title} id="validationCustom01" required />
												<div className="invalid-feedback">{t('Bitte_geben_Sie_den_Titel_an')}</div>
										</div>
									</div>

									<div className="col-md-4">
										<div className="form-outline-markt">
											<label htmlFor="validationCustom02" className="form-label pt-2">{t('preis')}</label>
											<MDBRow className="m-0">
												<MDBCol md="10" size="10" className="p-0">
													<input type="text" className="form-control text-muted" placeholder={0} name="preis" onChange={this.changeHandler} value={this.state.preis} id="validationCustom02"/>
												</MDBCol>
												<MDBCol middle md="2" size="2" className="p-0">
													<span className="price-currency">€</span>
												</MDBCol>
											</MDBRow>
										</div>
									</div>


									<div className="col-md-12">
										<div className="form-outline-markt pt-2">
											<label htmlFor="validationCustom03" className="form-label pt-2">{t('Ausfuhrliche_Beschreibung')}</label><br/>
											<MDBRow className="m-0 p-0">
												<MDBCol md="auto" className="pl-0 pr-1">
													<MDBIcon icon="info-circle" />
												</MDBCol>
												<MDBCol className="pl-1 pr-0">
													<p className="form-anmerkung">{t('Wenn_deine_Beschreibung_YouTube_Links')}</p>
												</MDBCol>
											</MDBRow>
											<div className="ckeditor-padding">
											<CKEditor
					            	editor={ ClassicEditor }
												data={this.state.article}
												config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
					            	onChange={
													( event, editor ) => {
					            			const data = editor.getData();
					            			this.onArticleChange(data);
					            		}
												}
					            />
											</div>
										</div>
									</div>
									{this.state.videoLink !== "" ?
									 <div className="col-md-12">
										 <div className="form-outline-markt pt-2">
											 <br/><iframe src={this.state.videoLink} allowFullScreen autoPlay className="photos-content"></iframe>
											 <MDBBtn
													 tag="a"
													 className="m-0"
													 floating
													 color="elegant"
													 onClick={this.onResetVideoLink}
													 style={{height: "auto", width: "auto", position: 'relative', top: '-130px', right: '14px', transform: 'translate(-50%)'}}>
												<img src={trahsicon} style={{width: "60%"}} className="py-1"/>
											</MDBBtn>
										</div>
									 </div>:false}

								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
										<label htmlFor="validationCustom04" className="form-label">{t('Kilometerstand') + "*"}</label>
										<input type="text" className="form-control text-muted" name="kilometerstand" onChange={this.changeHandler} value={this.state.kilometerstand} id="validationCustom04" required />
										<div className="invalid-feedback">{t('Bitte_geben_Sie_den_Kilometerstand_an')}</div>
									</div>
								</div>

								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
										<label htmlFor="validationCustom05" className="form-label">{t('Akku_Kapazitat_in_Wh') + "*"}</label>
										<input type="text" className="form-control text-muted" name="akkukapazitaet" onChange={this.changeHandler} value={this.state.akkukapazitaet} id="validationCustom05" required />
										<div className="invalid-feedback">{t('Bitte_geben_Sie_die_Akku_Kapazitat_an')}</div>
									</div>
								</div>

								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
										<label htmlFor="validationCustom06" className="form-label">{t('Hersteller') + "*"}</label>
										<input type="text" className="form-control text-muted" name="hersteller" onChange={this.changeHandler} value={this.state.hersteller} id="validationCustom06" required />
										<div className="invalid-feedback">{t('Bitte_geben_Sie_den_Hersteller_an')}</div>
									</div>
								</div>

								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
										<label htmlFor="validationCustom07" className="form-label">{t('Modell') + "*"}</label>
										<input type="text" className="form-control text-muted" name="modell" onChange={this.changeHandler} value={this.state.modell} id="validationCustom07" required />
										<div className="invalid-feedback">{t('Bitte_geben_Sie_den_Modell_an')}</div>
									</div>
								</div>

								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
										<label htmlFor="validationCustom08" className="form-label">{t('Baujahr') + "*"}</label>
										<input type="text" className="form-control text-muted" name="baujahr" onChange={this.changeHandler} value={this.state.baujahr} id="validationCustom08" required />
										<div className="invalid-feedback">{t('Bitte_geben_Sie_den_Baujahr_an')}</div>
									</div>
								</div>

								<div className="col-md-6">
									<div className="form-outline-markt pt-2">
									<label htmlFor="validationCustom09" className="form-label">{t('Rahmengrossee') + "*"}</label>
										<MDBSelect getTextContent={this.onSetRahmengrosse} className="form-control text-muted">
											<MDBSelectInput selected={this.state.rahmengrose}/>
											<MDBSelectOptions>
											{rahmengroseLis}
											</MDBSelectOptions>
										</MDBSelect>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-outline-markt pt-2">
									<label htmlFor="validationCustom10" className="form-label">{t('Laufradgrosse') + "*"}</label>
										<MDBSelect getTextContent={this.onSetLaufradgrosse} className="form-control text-muted">
											<MDBSelectInput selected={this.state.laufradgrose}/>
											<MDBSelectOptions>
											{laufradgroseLis}
											</MDBSelectOptions>
										</MDBSelect>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
									<label htmlFor="validationCustom11" className="form-label">{t('Rahmenformen') + "*"}</label>
										<MDBSelect getTextContent={this.onSetRahmenformen} className="form-control text-muted">
											<MDBSelectInput selected={this.state.rahmenformen}/>
											<MDBSelectOptions>
											{rahmenformenLis}
											</MDBSelectOptions>
										</MDBSelect>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
									<label htmlFor="validationCustom12" className="form-label">{t('Motorposition') + "*"}</label>
										<MDBSelect getTextContent={this.onSetMotorposition} className="form-control text-muted">
											<MDBSelectInput selected={this.state.motorposition}/>
											<MDBSelectOptions>
											{motorpositionLis}
											</MDBSelectOptions>
										</MDBSelect>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
									<label htmlFor="validationCustom13" className="form-label">{t('Akkuposition') + "*"}</label>
										<MDBSelect getTextContent={this.onSetAkkuposition} className="form-control text-muted">
											<MDBSelectInput selected={this.state.akkuposition}/>
											<MDBSelectOptions>
											{akkupositionLis}
											</MDBSelectOptions>
										</MDBSelect>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
									<label htmlFor="validationCustom14" className="form-label">{t('Schaltart') + "*"}</label>
										<MDBSelect getTextContent={this.onSetSchaltart} className="form-control text-muted">
											<MDBSelectInput selected={this.state.schaltart}/>
											<MDBSelectOptions>
											{schaltartLis}
											</MDBSelectOptions>
										</MDBSelect>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
									<label htmlFor="validationCustom15" className="form-label">{t('Verfugbarkeit') + "*"}</label>
										<MDBSelect getTextContent={this.onSetVerfugbarkeit} className="form-control text-muted">
											<MDBSelectInput selected={this.state.verfugbarkeit}/>
											<MDBSelectOptions>
											{verfuegbarkeitLis}
											</MDBSelectOptions>
										</MDBSelect>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
										<label htmlFor="validationCustom16" className="form-label">{t('Standort')}</label><br/>
										<MDBRow className="m-0 p-0">
											<MDBCol md="auto" className="pl-0 pr-1">
												<MDBIcon icon="info-circle" />
											</MDBCol>
											<MDBCol className="pl-1 pr-0">
												<p className="form-anmerkung">{t('Der_genaue_Standort_wird')}</p>
											</MDBCol>
										</MDBRow>
										<input type="text" className="form-control text-muted " name="standort" onChange={this.changeHandler} value={this.state.standort} id="validationCustom16"/>
									</div>
								</div>
								<div className="col-md-12 pt-4">
										{this.state.lat !== -200 || this.state.latitude !== "" ?
											<div className="profile-mapholder">
											<GoogleMaps5  latitude={this.state.lat !== -200 ? this.state.lat : this.state.latitude}
																		longitude={this.state.lng !== -200 ? this.state.lng : this.state.longitude}
																		isBikeMap={true}
																		userData = {this.props.userData}
																		withMap = {"100%"}
																		heightMap = {"250px"}
																		/>
											</div>:false}
								</div>
								<div className="col-md-12">
									<div className="form-outline-markt pt-2">
										<br/><p className="form-label">{t('anhange_zu_ihrem_beitrag')}</p>
										<div className="form-row align-items-center">
											{this.renderPhotos(this.state.selectedFiles)}
											<div className="file-field md-form">

												<span className="btn-bild-hinzu plusimage-icon text-center">
													<img src={plusimgicon} style={{width: "80%", paddingTop: "0.8rem"}} />
													<input multiple type="file" accept=".jpg,.jpeg,.png" onChange={this.onFileChange}/>
												</span>
											</div>
										</div>
									</div>
								</div>

									<div className="col-12">
										<MDBRow center className="profilpage-bottom">
											<button className="btn btn-done-profil" type="submit">{t('formular_senden')}</button>
										</MDBRow>
									</div>
									{ this.state.redirect === true ?
										<Redirect to="/Eintraege"/>:
									false
									}
								</form>
							</MDBCol>
						</MDBRow>
				</div>
			</div>

				{ this.props.userData.boersepage ?
					 <Redirect to={{
										 pathname: "/Boersepage",
										 state: { activeItem: this.state.activeItemKategorie, bikeKategorie: this.state.bikeKategorie }
					 }}
				 />:
				 false}

		</>
    );
  }
}

export default  withTranslation()(BikeInserierenUpdateResponziv);
