import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import InterB2BAxios from '../../Service/InterB2BAxios';
import logo from "../../assets/media/logo/1.png";
import { UserStatus } from "../../Config/Config";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import ratingicon from "../../assets/media/newicons/rating.png";
import emptyicon from "../../assets/media/newicons/star.png";
import commenticon from "../../assets/media/newicons/clipboard-text.png";
import CommentItem from '../Companys/CommentItem';
import {
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
	MDBBtn,
	MDBCollapse,
	MDBView,
	MDBMask,
	MDBContainer,
	MDBIcon,
	MDBListGroup,
	MDBListGroupItem,
	MDBTooltip,
  MDBRating,
	MDBInput,
	MDBCardTitle
} from 'mdbreact';

const cookies = new Cookies();

class FeedbackCardCompany extends React.Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {readState: 'read-less',
									iconName: "angle-double-right",
									allFeedback: [],
									average: 0.0,
									allRating: 0,
									lastFeedback: {},
                  };
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.addFunctionFromChild(this.onMessage);
		this.onLastFeedback();
		this.onAllFeedback();
  };

	componentWillUnmount() {
		this.props.removeFunctionFromChild(this.onMessage);
		this.interAxios.cancel();
	}

	onMessage = (message) => {
    let type = message.type;
    if (type === 'FEEDBACK') {
			this.onLastFeedback();
			this.onAllFeedback();
    }
  };


	truncateString = (str, num) => {
		if (str.length <= num) {
			return str
  		}
  			return str.slice(0, num) + '...'
	}

	toggleCollapse = collapseID => () => {
		this.setState(prevState => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : ""
		}));
	}


	handleReadmore = (id) =>{
		const newClassName = this.state.readState === 'read-less' ? 'read' : 'read-less';
		const { t } = this.props;
    const client = this.props.client;
		this.setState({readState: newClassName});
		if(newClassName === 'read'){
			this.setState({iconName: "angle-double-left",
									 	 textstatus: true});
		}else{
			this.setState({iconName: "angle-double-right",
									   textstatus: false});
		}
	}

	onNewFeedbackComment = (feddbackId) => {
		let self = this;
		const formData = new FormData
		formData.append("content", this.state.kommentar);
		formData.append("companyId", this.props.userData.getCurrentCompany()?.id);
    this.interAxios.post('/api/comment/newFeedbackComment/' + feddbackId, formData, function(response) {
    })
  };

	handleShowKommentar = () => {
		this.setState({showKommentar: !this.state.showKommentar});
	}

	handleSaveKommentar = () => {
		this.onNewFeedbackComment(this.props.lastFeedback.id);
		this.setState({kommentar: ''});
	}

	handleCloseKommentar = () => {
		this.setState({showKommentar: !this.state.showKommentar});
	}

	onKommentarChange = (editorState) => {
		this.setState({kommentar: editorState});
	}

	onAllFeedback = () =>{
		this.setState({average: 0, allRating: 0});
		var self = this;
		this.interAxios.get('/api/allFeedbackToCompany/' + this.props.currentCompany.id, function(response) {
					self.setState({allFeedback: response.data});
					let count = 0;
					let ratingValue = 0;
					if (response.data.length>0){
						for (let i = 0; i < response.data.length; i++){
							count ++;
							ratingValue += response.data[i].rating;
						}
						self.setState({average: ratingValue/count,
													 allRating: count});
					};


		})
	};

	onLastFeedback = () =>{
		var self = this;
		this.setState({lastFeedback: {}});
		this.interAxios.get('/api/lastFeedbackToCompany/' + this.props.currentCompany.id, function(response) {
				if(response.data !== null){
					self.setState({lastFeedback: response.data});
				}else{
					self.setState({lastFeedback: {}});
				}

		})
	};

  render() {

		const { t } = this.props;
    const client = this.props.client;
		let prevDate = Moment(Date.now());
		let prevDate1 = Moment(Date.now()).subtract(1, 'days');
		let prevDate2 = Moment(Date.now()).subtract(2, 'days');
		let tsmoment = Moment(this.state.lastFeedback.time);
		var text ="";
		if (this.state.lastFeedback.text !== ''){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.state.lastFeedback.text, 'text/html');
			text =parsedHtml.documentElement.textContent;
		}
		const secondExample = {
		  size: 13,
		  count: 5,
			color: "black",
		  value: this.state.lastFeedback.rating,
		  a11y: true,
			edit: false,
		  emptyIcon: <img src={emptyicon} style={{width: "85%"}}/>,
		  filledIcon: <img src={ratingicon} style={{width: "85%"}}/>,
		};
    return (
      <>
			{this.state.allRating > 0 &&  this.state.lastFeedback !== {} && this.state.lastFeedback.commentlist !== undefined ?
			<MDBCard className="interb2b-card">
				<MDBCardTitle>
					{this.props.myCompany ?
					<MDBRow>
						<MDBCol>
							{!this.props.userData.isGastAdmin() ?
								<Link className="contacts-link" to={"/AllFeedbackPageCompany/" + this.props.currentCompany.id}><span style={{color: "#0360ec"}}>{t('meine_bewertungen')} ({this.state.allRating})</span>
								</Link>:
							<span className="contacts-link" style={{color: "#0360ec"}}>{t('meine_bewertungen')} ({this.state.allRating})</span>}
						</MDBCol>
					</MDBRow>:
					<MDBRow>
						<MDBCol>
							{!this.props.userData.isGastAdmin() ?
								<Link className="contacts-link" to={"/AllFeedbackPageCompany/" + this.props.currentCompany.id}><span style={{color: "#0360ec"}}>{t('bewertungen_von')}  {this.props.currentCompany.name} ({this.state.allRating})</span>
								</Link>:
							<span className="contacts-link" style={{color: "#0360ec"}}>{t('bewertungen_von')}  {this.props.currentCompany.name} ({this.state.allRating})</span>}
						</MDBCol>
					</MDBRow>}



				<MDBRow className="align-items-center pt-2">
				<MDBCol>
				<img src={ratingicon} style={{width: "8%"}} className="pr-2"/><a style={{color: "#000"}}>{this.state.average.toFixed(1)}/5</a>
				</MDBCol>
				</MDBRow>
				</MDBCardTitle>
				<MDBCardBody>
				<MDBRow>
					<MDBCol size="auto" sm="auto" md="auto" lg="auto" xl="auto" className="text-center pb-2">
						<MDBView hover zoom>
						<img
						src={this.state.lastFeedback.companyUrl +'?' + Date.now()}
						className={this.state.allFeedbackStatus ? "photo rounded d-flex z-depth-1" : "photo-feedback rounded d-flex z-depth-1"}
						alt=""
						/>
						</MDBView>
					</MDBCol>
					<MDBCol>
						<MDBRow className="align-items-center">
							<MDBCol size="6" sm="6" md="6" lg="6" xl="6" className="text-left px-0">
							{this.state.lastFeedback.companyName !== undefined && this.state.lastFeedback.companyName !== null?
								<>
								<p className="font-weight-bold mb-0">

								{this.state.lastFeedback.companyName.length>25 ?
								<MDBTooltip
									domElement
									tag="span"
									material
									placement="top"
								>
									<>{this.truncateString(this.state.lastFeedback.companyName, 25)}</>
									<>{this.state.lastFeedback.companyName}</>
								</MDBTooltip>:
								<>{this.state.lastFeedback.companyName}</>}

								</p></>:false}
							</MDBCol>
							<MDBCol size="6" sm="6" md="6" lg="6" xl="6" className="text-right">
								{tsmoment.isBefore(prevDate2, 'day') ?
								<small className="font-weight-normal text-muted">{tsmoment.locale(this.props.i18n.language).format('DD-MM-YYYY')}</small>:false}
								{tsmoment.isSame(prevDate2, 'day') ?
								<small className="font-weight-normal text-muted">vor 2 Tagen</small>:false}
								{tsmoment.isSame(prevDate1, 'day') ?
								<small className="font-weight-normal text-muted">gestern</small>:false}
								{tsmoment.isSame(prevDate, 'day') ?
								<small className="font-weight-normal text-muted">{tsmoment.format('h:mm a')}</small>:false}
							</MDBCol>
						</MDBRow>
						<MDBRow className={this.props.allFeedbackStatus ? "pb-0" : "pb-0 rating-row"}>
									{this.state.lastFeedback.text !== '' ?
									<>
									{this.state.textstatus === true ?
										<small className={this.state.readState + ' pt-2 pr-3'} id={'read-' + this.state.lastFeedback.id}>{text}</small>:
										<small className="pt-2 pr-3">{this.truncateString(text, 60)}</small>}

									{text.length > 60 ?
									<MDBBtn flat
										className="btn-block btn-sm mb-0 pb-1 px-0 rating-btn"
										onClick={() => {
											this.handleReadmore(this.state.lastFeedback.id);
										}}
										style={{ marginBottom: "1rem",	textAlign:"left",	paddingLeft:"9px" }}
									>
										<span style={{textTransform: "capitalize"}}>{this.state.readState === 'read-less' ? t('weiterlesen') : t('weniger')} </span>
										<MDBIcon icon={this.state.iconName} />
									</MDBBtn>:false}
									</>:false}
						</MDBRow>
						<MDBRow className="pt-0">
						{this.state.lastFeedback.rating !== undefined ?
							<ReactStars
									{...secondExample}
							/>:false}

						</MDBRow>
					</MDBCol>
				</MDBRow>
				{!this.props.userData.isCurrentCompanyAnonim() &&  !this.props.userData.isGastAdmin() ?
					<>
				<MDBRow>
				<MDBBtn flat
								className="px-4"
								 onClick={this.toggleCollapse("basicCollapse")}
								style={{color:"#000", minWidth: "170px"}}>
								<img src={commenticon} style={{width: "13%"}}/><span className="text-capitalize"> {t('kommentare')} ({this.state.lastFeedback.commentlist !== undefined && this.state.lastFeedback.commentlist !== null ? this.state.lastFeedback.commentlist.length : 0})</span>
				</MDBBtn>
				</MDBRow>
				<MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
					{this.state.collapseID ?
						<>
						<MDBRow>
						{this.state.lastFeedback.commentlist !== null ?
						<MDBCol>
							{this.state.lastFeedback.commentlist.map(
								(o) =>
								<MDBListGroup className="mitteilungen-listgroup">
									 <CommentItem
										key={o.id} msg={o} />
								</MDBListGroup>
							)}

						</MDBCol>:false}
						</MDBRow>
						<MDBInput type="textarea" rows="1" getValue={this.onKommentarChange} value={this.state.kommentar} placeholder={t('bitte_fügen_sie_ihren_kommentar_hinzu')}/>
						</>:false}
						<div className="text-center p-0">
						{this.state.kommentar !== undefined ?
						<MDBBtn flat onClick={this.handleSaveKommentar}>{t('kommentar_senden')}</MDBBtn>:
						<MDBBtn flat >{t('kommentar_senden')}</MDBBtn>}
						</div>
			</MDBCollapse>
			</>:<MDBRow style={{height: "15px"}}/>}
				{this.props.allFeedbackStatus ?
				<hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>:false}
				</MDBCardBody>
			</MDBCard>:false}
	</>
    );
  }
}

export default withTranslation()(FeedbackCardCompany);
