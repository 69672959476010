import React, { Component } from "react";
import Jitsi from 'react-jitsi';
import logo from "../../assets/media/logo/1.png";
import partners from "../../assets/media/images/b2b/events.png";
import ib2bicon from "../../assets/media/images/b2b/ib2b_b2b.png";
import styles from '../../assets/scss/modules/pages.module.scss';
import calendarStyles from '../../assets/scss/modules/calendar.module.scss';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import InterB2BAxios from '../../Service/InterB2BAxios';
import FooterB2BMainPage from '../Footer/FooterB2BMainPage';
import GenericModal from './GenericModal.js';
import SalonEventModal from './SalonEventModal.js';
import SonstigeNavB2BHersteller from '../NavbarComponentB2B/SonstigeNavB2BHersteller';

import{
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBMedia,
	MDBListGroup,
	MDBRow,
	MDBCol,
  MDBContainer,
	MDBScrollbar
} from 'mdbreact';

import Cookies from 'universal-cookie';

const cookies = new Cookies();


class SalonCalendarPageHersteller extends Component {
	state={
		jitsiApi: undefined,
		currentCompany: undefined,
		activeItem: "4"
	};
  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
		this.getMyEvents();
		this.getMyCompanySubscription();
		this.localizer = momentLocalizer(moment);
	}

	componentDidMount(){
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.refreshNavbar();
	}

	// Mikorra lehet eseményt készíteni?
	canCreateNewSalonEventBasedOnDateTime = (slot) => {
		let start = moment(slot.start);
		let now = moment();
		return moment(start).isSame(now, 'days') && start.hours() == 0 && start.minutes() == 0 ||
					 start.subtract(30, 'minutes').isAfter(now);
	}

	// Mikor lapolódnak át az események?
	intersectWithOtherEvent = (slot) => {
		let startMoment = moment(slot.start);
		let endMoment = moment(slot.end);

		// Ha csak a napot adtuk meg (start: nap, 0:00, end: nap, 0:00), akkor nem kell ezzel foglalkozni
		if (startMoment.hours() == 0 && startMoment.minutes() == 0 && endMoment.hours() == 0 && endMoment.minutes() == 0) {
			return false;
		}

		// Ha van átlapolódó elem:
		const existsIntersectingEvent = this.state.events.some((element) => {
			const elementStartMoment = moment(element.start);
			const elementEndMoment = moment(element.end);
			return startMoment.isBefore(elementEndMoment) && elementStartMoment.isBefore(endMoment);
		})
		return existsIntersectingEvent;
	}

	freeUsable = (start) => {
		const startMonth = moment(start).startOf('month')
		const usable = !this.props.userData.getCurrentCompany()?.freeUsed?.includes(startMonth.format("YYYY-MM-DD"));
		return usable;
	}

	// Elegendő-e a jogosultság arra, hogy azt az eseményt felvegyük
	//  - PREMIUM szint kell
	//  - legyen érvényes az esemény kezdetekor
	checkIfNewEventAllowed = (slot) => {
		//return true; --> ha ezt beteszed, tesztelhető a backend előfizetésellenőrzése
		if (this.state.mySubscription === undefined) {
			return false;
		}
		if (this.state.mySubscription.currentLevel !== 'PREMIUM') {
			return false;
		}
		let startMoment = moment(slot.start);
		let subscriptionEnds = moment(this.state.mySubscription.gultigBis);
		if (startMoment.isAfter(subscriptionEnds)) {
			return false;
		}
		return true;
	}

	onSelectSlot = (slot) => {
		const { t } = this.props;
		const client = this.props.client;
		slot.end = moment(slot.start).isSame(slot.end, 'days') ? slot.end : slot.start;
		if (!this.checkIfNewEventAllowed(slot)) {
			//this.openWarningModal(t('sie_haben_kein_recht_eine_veranstaltung_hinzufügen'))
			// ne csináljunk semmit?
		} else if (this.intersectWithOtherEvent(slot)) {
			this.openWarningModal(t('die_zeit_der_veranstaltung_kann_nicht_mit_einer_anderen_überlappen'))
		} else if (this.canCreateNewSalonEventBasedOnDateTime(slot)) {
			//meghívunk egy axios-t, amivel beállítjuk a modalConfig.freeUsable-t igazra, ha van neki az adott hónapra ingyenese. Param: start, end, Return: true vagy false
			this.setState(
				{
					modalConfig: {
						modalTitle: t('veranstaltung_hinzufügen'),
						error: undefined,
						titleEditable: true,
						startTimeEditable: true,
						endTimeEditable: true,
						summaryEditable: true,
						maxVisitorEditable: true,
						freeUsable: this.freeUsable(slot.start), // false, beállítjuk egy axios-hívással
						showPrice: true,
						showVisitors: false,
						showLink: false,
						okButton: {
							show: true,
							text: t('hinzufügen'),
							color: 'green'
						}
					},
					modalData: {
						...slot,
						hostCompanyName: this.props.userData.getCurrentCompany()?.name,
						useFree: true,
					},
					modalSave: this.handleNewSalonEvent
				});
			this.openModal();
		}
	}

	onSelectEvent = (event) => {
		const { t } = this.props;
		const client = this.props.client;
		if (!event.weHost) { // not our event
			if (!event.registered) {
				if (event.canRegister) {
					this.setState(
						{
							modalConfig: {
								modalTitle: t('anmelden_veranstaltung'),
								error: undefined,
								titleEditable: false,
								startTimeEditable: false,
								endTimeEditable: false,
								summaryEditable: false,
								showLink: event.showLink,
								okButton: {
									show: true,
									text: t('anmelden'),
									color: 'green'
								}
							},
							modalData: event,
							modalSave: this.handleRegisterSalonEvent
						});
					} else { // not registered, cannot register
						this.setState(
							{
								modalConfig: {
									modalTitle: t('anzeigen_veranstaltung'),
									error: undefined,
									titleEditable: false,
									startTimeEditable: false,
									endTimeEditable: false,
									summaryEditable: false,
									showLink: event.showLink,
									okButton: {
										show: false
									}
								},
								modalData: event
							});
					}
			} else { // registered
				if (event.canUnregister){ // registered, can unregister
					this.setState(
						{
							modalConfig: {
								modalTitle: t('veranstaltungregistrazion_andern'),
								error: undefined,
								titleEditable: false,
								startTimeEditable: false,
								endTimeEditable: false,
								summaryEditable: false,
								showLink: event.showLink,
								okButton: {
									show: false
								},
								thirdButton: {
									show: true,
									text: t('unregister'),
									color: 'red',
									handleClick: this.handleUnregisterSalonEvent
								}
							},
							modalData: event
						});
				} else { // registered, cannot unregister
					this.setState(
						{
							modalConfig: {
								modalTitle: t('veranstaltung_anzeigen'),
								error: undefined,
								titleEditable: false,
								startTimeEditable: false,
								endTimeEditable: false,
								summaryEditable: false,
								showLink: event.showLink && event.registered,
								okButton: {
									show: false
								}
							},
							modalData: event
						});
				}
			}
		} else {

		// weHost the event

			if (event.canCancel) {
				this.setState(
					{
						modalConfig: {
							modalTitle: t('veranstaltung_andern'),
							error: undefined,
							titleEditable: event.canModify,
							startTimeEditable: false,
							endTimeEditable: false,
							summaryEditable: event.canModify,
							maxVisitorEditable: true,
							freeUsable: this.freeUsable(event.start) || event.canModify && event.useFree,
							showLink: event.showLink,
							showPrice: true,
							okButton: {
								show: true,
							},
							thirdButton: {
								show: true,
								text: t('entfernen'),
								color: 'red',
								handleClick: this.handleCancelSalonEvent
							}
						},
						modalData: event,
						modalSave: this.handleModifySalonEvent
					});
			} else {
				this.setState(
					{
						modalConfig: {
							modalTitle: t('andern_veranstaltung'),
							error: undefined,
							titleEditable: event.canModify,
							startTimeEditable: false,
							endTimeEditable: false,
							summaryEditable: event.canModify,
							showLink: event.showLink,
							showPrice: true,
							okButton: {
								show: true,
							},
						},
						modalData: event,
						modalSave: this.handleModifySalonEvent
					});
			}
		}
		this.openModal();
	}

	eventPropGetter = (event) => {
		let weHost = event?.weHost;
		let registered = event?.registered;
		let past = moment(event?.start).isBefore(moment());
		let hidden = event?.hidden;
		if (hidden) {
			return {
				className: calendarStyles.hidden
			}
		}	else if (past) {
			return {
				className: calendarStyles.past
			}
		} else if (weHost) {
			return {
				className: calendarStyles.weHost
			}
		} else if (registered) {
			return {
				className: calendarStyles.registered
			}
		} else {
			return {
				className: calendarStyles.other
			}
		}
	}

	// SalonModal letöltő és event handlerek
	getMyEvents = () => {
		const { t } = this.props;
		let self = this;
		this.interAxios.get('/api/salon/getMyEvents/' + cookies.get('authtoken').userId, function(response) {
			let events = response.data.map((event) => {
				event.start = moment(event.start).toDate();
				event.end = moment(event.end).toDate();
				event.displayTitle = event.hidden ? t('reserviert') : event.displayTitle;
				return event;
			});
			self.setState({events: events});
		});
	}

	getMyCompanySubscription = () => {
		let self = this;
		this.interAxios.get('/ecommerce/mySubscription', function(response) {
			console.log(response.data);
			self.setState({mySubscription: response.data});
		});
	}

	handleNewSalonEvent = (data) => {
		console.log("Creating new event " + JSON.stringify(data));
		let self = this;
		this.interAxios.post('/api/salon/newEvent/' + cookies.get('authtoken').userId, data, function(response) {
			self.getMyEvents();
			self.getMyCompanySubscription();
			self.closeModal();
		}, function(error) {
			console.log("errorFunction is running: " + error.response?.data)
			self.handleBackendError(error.response?.data);
		});
		//this.closeModal();
	}

	handleModifySalonEvent = (data) => {
		console.log("Modify event " + JSON.stringify(data));
		let self = this;
		this.interAxios.post('/api/salon/modifyEvent/' + data.id + '/' + cookies.get('authtoken').userId, data, function(response) {
			self.getMyEvents();
			self.getMyCompanySubscription();
			self.closeModal();
		}, function(error) {
			console.log("errorFunction is running: " + error.response?.data)
			self.handleBackendError(error.response?.data);
		});
	}

	handleCancelSalonEvent = (data) => {
		console.log("Cancel event: " + JSON.stringify(data));
		let self = this;
		this.interAxios.post('/api/salon/cancelEvent/' + data.id + '/' + cookies.get('authtoken').userId, {}, function(response) {
			self.getMyEvents();
			self.getMyCompanySubscription();
			self.closeModal();
		}, function(error) {
			console.log("errorFunction is running: " + error.response?.data)
			self.handleBackendError(error.response?.data);
		});
	}

	handleRegisterSalonEvent = (data) => {
		console.log("Registering to " + JSON.stringify(data));
		let self = this;
		this.interAxios.post('/api/salon/registerEvent/' + data.id + '/' + cookies.get('authtoken').userId, {}, function(response) {
			self.getMyEvents();
			self.closeModal();
		}, function(error) {
			console.log("errorFunction is running: " + error.response?.data)
			self.handleBackendError(error.response?.data);
		});
	}

	handleUnregisterSalonEvent = (data) => {
		console.log("Unregistering from " + JSON.stringify(data));
		let self = this;
		this.interAxios.post('/api/salon/unregisterEvent/' + data.id + '/' + cookies.get('authtoken').userId, {}, function(response) {
			self.getMyEvents();
			self.closeModal();
		}, function(error) {
			console.log("errorFunction is running: " + error.response?.data)
			self.handleBackendError(error.response?.data);
		});
	}

	handleBackendError = (error) => {
		let modalConfig = this.state.modalConfig;
		modalConfig.error = error;
		this.setState({modalConfig: modalConfig});
	}


	// SalonEventModal
	openModal = () => {
		this.setState({modalShow: true})
	}

	closeModal = (reactEvent) => {
		this.setState({modalShow: false})
	}

	// Warning modal
	openWarningModal = (text) => {
		this.setState(
			{
				warningModalText: text,
				warningModalShow: true
			}
		);
	}

	closeWarningModal = () => {
		this.setState({warningModalShow: false})
	}


  render() {
		console.log(this.state.events);
		const { t } = this.props;
		const client = this.props.client;
    return (
			<>
			<SonstigeNavB2BHersteller userData={this.props.userData} activeItem={this.state.activeItem}/>
			<div className={styles.bodywrapper}>
			    <MDBContainer>
						<MDBRow>
							<MDBCol md="2" className="text-center">
								<img style={{width: "115%"}} src={partners}/>
							</MDBCol>
							<MDBCol md="5">
								<h5 className="netzwerk-title">{t('bleiben_sie_mit_ihren_kunden_und_partnern_in_kontakt')}</h5>
								<img className="netzwerk-ib2b w-50" src={ib2bicon}/>
								<p className="dark-grey-text">
								 	{t('nehmen_sie_mit_dieser_integrierten_funktion')}
								</p>
							</MDBCol>
							<MDBCol md="5" >

							</MDBCol>
						</MDBRow>
					<MDBRow center>
					{this.state.events !== undefined ?
					<MDBCard className="interb2b-card p-3" style={{ width: '80%' }}>
					<Calendar
				       localizer={this.localizer}
				       events={this.state.events}
							 eventPropGetter={this.eventPropGetter}
							 selectable={true}
							 onSelectSlot={this.onSelectSlot}
							 onSelectEvent={this.onSelectEvent}
							 onDoubleClickEvent={this.onDoubleClick}
				       startAccessor="start"
				       endAccessor="end"
							 titleAccessor="displayTitle"
							 views={['month', 'week', 'day']}
				       style={{ height: 400, width: '100%' }}
							 messages={this.props.i18n.language === 'de' ? {
		  					date: 'Datum',
 						    time: 'Zeit',
 						    event: 'Veranstaltung',
						    allDay: 'Ganzer Tag',
						    week: 'Woche',
						    work_week: 'Arbeitswoche',
						    day: 'Tag',
						    month: 'Monat',
						    previous: 'Zurück',
						    next: 'Weiter',
						    yesterday: 'Gestern',
						    tomorrow: 'Morgen',
						    today: 'Heute',
						    agenda: 'Agenda',
						    noEventsInRange: 'Es gibt keine Veranstaltungen in diesem Bereich.',
						    showMore: function showMore(total) {
						      return "+" + total + " mehr";
						    }
							} : undefined}
				     />
						 </MDBCard>:false}
						 </MDBRow>
						 <SalonEventModal
						 	  data={this.state.modalData}
								config={this.state.modalConfig}
								show={this.state.modalShow}
								handleSave={this.state.modalSave}
								handleClose={this.closeModal}
						 />

						 <GenericModal
						 		show={this.state.warningModalShow}
								config={{
									modalTitle: t('achtung'),
									okButton: {
										show: false
									}
								}}
								handleClose={this.closeWarningModal}
						 >
						 		<div>{this.state.warningModalText}</div>
						 </GenericModal>
      	</MDBContainer >
				<div className="rectangle-footer-markt">
					<MDBContainer style={{maxWidth: "1250px"}}>
						<FooterB2BMainPage/>
					</MDBContainer>
				</div>
				</div>
				</>
    )

  }
}

export default withTranslation()(SalonCalendarPageHersteller);
