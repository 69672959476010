import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_first from "../../assets/media/images/markt/profil/Group 7.png";
import logo3 from "../../assets/media/images/markt/profil/Group 6.png";
import CreateImageProfile from '../Modals/CreateImageProfile'
import InterB2BAxios from '../../Service/InterB2BAxios';
import FooterMarktLanding from '../Footer/FooterMarktLanding';
import SonstigeNav from '../NavbarComponent/SonstigeNav';
import ProfilpageNavRespo from '../NavbarComponent/ProfilpageNavRespo';
import BoersePageNavRespo from '../NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from '../../pages/markt/BoersePage/MenuSideNavBoerse';
import ProfilpageNav from '../NavbarComponent/ProfilpageNav';
import styles from '../../assets/scss/modules/profilpage.module.scss';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
const myregexp = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.*([^\s]{11})/gm;
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBIcon,
	MDBDatePicker,
	MDBSideNav
} from 'mdbreact';

let language = '';
let urlName = '';
class Benachrichtigungen extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefStart = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'ProfilInfo');
    this.state = {redirect: false,
									myUser: {},
									neueigkeiten: true,
									neuerNachricht: true,
									neuesKomment: true,
									antwortKomment: true,
									radio: 1,
									};
  };

	componentDidMount() {
		document.addEventListener("scroll", this.scrollEventListener);
		var params = window.location.href.split(".");
	  language = params[params.length - 1];
		var params2 = window.location.href.split("/");
		urlName = params2[params2.length - 1];
		this.onMyUser();
		this.onRefStart();
	}

	componentWillUnmount() {
		document.removeEventListener("scroll", this.scrollEventListener);
		this.interAxios.cancel();
	}

	scrollEventListener = (event) => {
		const navPadding = window.scrollY < 50 ? true : false;
		this.setState({ navbarPaddingTop: navPadding});
	}

	onRefStart = () => {
		this.listInnerRefStart.current.scrollIntoView({
	 });
	}

	onMyUser = () => {
		var self = this;
		this.interAxios.get('/api/user/me', function(response) {
      self.setState({myUser: response.data});
    });
	};


	 onUserUpdate = (event) => {
		 const form = event.currentTarget;
		 event.preventDefault();
		 event.target.className += " was-validated"
		 if((form.checkValidity() !== false)){
			 let self = this;
			 const formData = new FormData
			 formData.append("neueigkeiten", this.state.neueigkeiten);
			 formData.append("neuerNachricht", this.state.neuerNachricht);
			 formData.append("neuesKomment", this.state.neuesKomment);
			 formData.append("antwortKomment", this.state.antwortKomment);
			 this.interAxios.put('/api/auth/userupdateMarkt', formData, {
				 headers: {
					 'Content-Type': 'multipart/form-data'
				 }
			 }, function(response) {
				 	self.setState({redirect: true});
			 })
		 }
	 };



		handleCheckbox1 = () => {
	    this.setState({neueigkeiten: !this.state.neueigkeiten});
	  }
		handleCheckbox2 = () => {
			this.setState({neuerNachricht: !this.state.neuerNachricht});
		}
		handleCheckbox3 = () => {
			this.setState({neuesKomment: !this.state.neuesKomment});
		}
		handleCheckbox4 = () => {
			this.setState({antwortKomment: !this.state.antwortKomment});
		}

	 onClick = (nr) => () => {
	   this.setState({
	     radio: nr,
	   });
	 };

	 onMenuOpen = () => {
 		this.setState({
 			isMenuOpen: true
 		});
 	}

 	onMenuClose = () => {
 		this.setState({
 			isMenuOpen: false
 		});
 	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<div ref={this.listInnerRefStart}></div>
			<div className="d-none d-lg-block">
				<SonstigeNav  userData={this.props.userData}/>
			</div>
			<div className="d-lg-none d-block">
			<BoersePageNavRespo userData={this.props.userData}
													onMenuOpen = {this.props.handleSideNavToggle}
													isUserMenu={true}
													activeItem = {"7"}
													isOpen = {this.state.isButton}/>
			</div>

				<div className={styles.bodywrapper}>
					<div className="d-lg-none d-block">
						<div className="side-nav-menu">
							<MDBSideNav
								right
								triggerOpening={this.props.isSideNavOpen}
								onOverlayClick={this.props.handleSideNavToggle}
								breakWidth={1300}
								className="deep-purple darken-4"

							>
								<MenuSideNavBoerse
														userData={this.props.userData}
														toggle = {this.toggle}
														onMenuClose = {this.props.onEnableScroll}
														activeItem = {this.state.activeItem}
								/>
							</MDBSideNav>
						</div>
					</div>
					<div className="profil-background">
						<MDBContainer style={{maxWidth: "1250px"}}>
							<MDBRow className="pt-4 addbike-row-bottom-2">
							<div className="d-none d-lg-block">
							<MDBCol md="auto" className="pr-4">
								<ProfilpageNav activeItem = {"7"} userData={this.props.userData}/>
							</MDBCol>
							</div>
								<MDBCol className="px-3">
								<div className="d-none d-lg-block">
								<MDBRow start className="m-0 pt-0 pb-3">
										<img alt="" className="e-bikeborse-logo-first-eigene" src={logo_first}></img>
										<span className="landingpage-text-eigene">{t('benachrichtigungen')}</span>
								</MDBRow>
								</div>
								<div className="d-block d-lg-none">
								<MDBRow center className="m-0 pt-0 pb-3">
										<img alt="" className="e-bikeborse-logo-first-eigene" src={logo_first}></img>
										<span className="landingpage-text-eigene">{t('benachrichtigungen')}</span>
								</MDBRow>
								</div>
									<div className="benach-form px-3">
										<form className="row g-3 needs-validation" onSubmit={async (event) => {await this.onUserUpdate(event);}} noValidate>

											<div>
											<MDBRow className="m-0 py-3">
												<span className="form-title ">Newsletter</span>
											</MDBRow>
												<MDBInput
								          gap
													className="row-height"
								          onClick={this.onClick(1)}
								          checked={this.state.radio === 1 ? true : false}
								          label={<span className="radio-text">Schickt mir <span className="font-weight-bold">täglich</span> Newsletter, sofern es neue Einträge gibt</span>}
								          type="radio"
								          id="radio1"
								        />
								        <MDBInput
								          gap
								          onClick={this.onClick(2)}
								          checked={this.state.radio === 2 ? true : false}
								          label={<span className="radio-text">Schickt mir <span className="font-weight-bold">wöchentlich</span> Newsletter, sofern es neue Einträge gibt</span>}
								          type="radio"
								          id="radio2"
								        />
												<MDBInput
								          gap
								          onClick={this.onClick(3)}
								          checked={this.state.radio === 3 ? true : false}
								          label={<span className="radio-text">Schickt mir keine Newsletter</span>}
								          type="radio"
								          id="radio3"
								        />
												<MDBRow className="m-0 py-3">
													<span className="form-title">E-mails von Administratoren</span>
												</MDBRow>

									      <div className="custom-control custom-checkbox">
									        <input type="checkbox" className="custom-control-input" id="defaultChecked1" name="neueigkeiten" value={this.state.neueigkeiten} checked={this.state.neueigkeiten} onChange={this.handleCheckbox1}/>
									        <label className="custom-control-label" htmlFor="defaultChecked1">Ich möchte gelegentlich Neuigkeiten von den
																Administratoren von E-Bikebörse empfangen</label>
									      </div>

												<MDBRow className="m-0 py-3">
													<span className="form-title">Ich möchte eine E-Mail-Benachrichtigung erhalten, wenn...</span>
												</MDBRow>
									      <div className="custom-control custom-checkbox">
									        <input type="checkbox" className="custom-control-input" id="defaultChecked2" name="neuerNachricht" value={this.state.neuerNachricht} checked={this.state.neuerNachricht} onChange={this.handleCheckbox2}/>
									        <label className="custom-control-label" htmlFor="defaultChecked2">...mir jemand eine Nachricht sendet</label>
									      </div>

												<div className="custom-control custom-checkbox">
									        <input type="checkbox" className="custom-control-input" id="defaultChecked3" name="neuesKomment" value={this.state.neuesKomment} checked={this.state.neuesKomment} onChange={this.handleCheckbox3}/>
									        <label className="custom-control-label" htmlFor="defaultChecked3">...jemand meinen Eintrag kommentiert</label>
									      </div>

												<div className="custom-control custom-checkbox">
									        <input type="checkbox" className="custom-control-input" id="defaultChecked4" name="antwortKomment" value={this.state.antwortKomment} checked={this.state.antwortKomment} onChange={this.handleCheckbox4}/>
									        <label className="custom-control-label" htmlFor="defaultChecked4">...ein Eintrag, den ich kommentiert habe, aktualisiert wurde</label>
									      </div>
											</div>
											<div className="col-12">
											<div className="d-none d-lg-block">
												<MDBRow start className="profilpage-bottom pt-4">
													<button className="btn btn-done-profil" type="submit" disabled>{t('formular_senden')}</button>
												</MDBRow>
											</div>
											<div className="d-lg-none d-block" style={{width: "100%"}}>
												<MDBRow center className="profilpage-bottom pt-4 m-0" style={{width: "100%"}}>
													<button className="btn btn-done-profil" type="submit" disabled>{t('formular_senden')}</button>
												</MDBRow>
											</div>
											</div>
										</form>
									</div>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</div>
				</div>


				{ this.props.userData.boersepage ?
					 <Redirect to="/Eintraege"/>:
				 false}
		</>

    );
  }
}

export default  withTranslation()(Benachrichtigungen);
