import React, { Component } from "react";
import { ChatFilterOptions, ChatFilterOptionsMarkt } from "../../../Config/Config";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBBtn,
  MDBScrollbar,
  MDBRow,
  MDBCol,
  MDBBadge
} from "mdbreact";

import ChatMessageMainBike from "./Message/ChatMessageMainBike";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class ChatListMainBike extends Component {
  state = {
    ChatUserId: "",
    collapseID: "",
    search: "",
    filterOption: ChatFilterOptionsMarkt.ActiveChats,
    switch1: false,
    eredmeny: true,
  };


  componentDidMount() {
    var params = window.location.href.split("/");
    this.setState({
      ChatUserId: params[params.length - 1],
    });
  }

  handleChatClick = (id) => {
    this.props.handleChatClick(id);
  };

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  handleFilterOptionChanged = (option) => {
    this.setState({
      filterOption: option,
      search: "",
    });

  };

  handleSearchChat = value => {
    this.setState({search: value});
  };

  filterChats = (AllChatList) => {
    let chatList = [...AllChatList];
    if (this.state.filterOption !== ChatFilterOptionsMarkt.AllChats)
      chatList = chatList.filter((x) => x.aktuelleStatus === this.state.filterOption);
    if (this.state.search) {
      chatList = chatList.filter(
        (x) =>
          x.name.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0
      );
    }
    return chatList;
  }

  handleSwitchChange = nr => {
    let switchNumber = `switch${nr}`;
    this.setState({
      [switchNumber]: !this.state[switchNumber],
      eredmeny: !this.state.eredmeny
    });

    if(this.state.eredmeny){
      eventKey = 3;
    }else{
      eventKey =  2;
    }
    this.handleChange(eventKey);
  };

  handleChange = async (eventKey) => {;
    await this.handleFilterOptionChanged(parseInt(eventKey));
  };

  render() {
    var i=0;
    for (const chatRoom of this.props.rooms){
        i+= chatRoom.badge;
    }
    let AllChatList = this.props.rooms;
    let roomNameList = AllChatList.map(o => o.name);
    let FilteredChatList = this.filterChats(AllChatList);
		switch (this.state.filterOption) {
			case ChatFilterOptionsMarkt.ActiveChats:
				break;
			case ChatFilterOptionsMarkt.Deleted:
				break;
			default:
				break;
		}
    const { collapseID } = this.state;
    return (
      <>
      <MDBRow className="align-items-center" style={{paddingLeft: "1rem", paddingRight: 0, paddingTop: "0.6rem"}}>
        <MDBCol xs="12" sm="2" className="text-center">
          <div className={"avatar avatar-online"} style={{height: '2.2rem', width: '2.2rem', minWidth: '2.2rem'}}>
          {this.props.userData.getCurrentCompany()?.profileUrl !== undefined ?
            <img className="userProfileName-icon"
              src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + this.state.timeStamp}
              />:<>
              {this.props.userData.getMyUser()?.profileUrl !== undefined ?
                <img alt="" className="user-photo-navbar" src={this.props.userData.getMyUser()?.profileUrl +'?' + Date.now()}></img>:
                <div className="userProfileName-icon">{this.props.userData.userProfilName()}</div>}</>}
          </div>
        </MDBCol>
        <MDBCol xs="12" sm="3" className="text-left">
          <span aria-hidden="true"> Chat</span>
        </MDBCol>
        <MDBCol xs="12" sm="7" className="text-right">
          <MDBBtn flat
                  className='btn-block'
                  onClick={this.toggleCollapse("basicCollapse")}
          >
            {this.state.collapseID ?
              <MDBIcon icon="chevron-down" />:
              <MDBIcon icon="chevron-up" />}
          </MDBBtn>
          {i>0 ?
          <MDBBadge pill color="danger" style={{position: 'absolute', right: '99px', top: '14px', zIndex: 10, border: '2px solid #fff'}}>{i}</MDBBadge>:false}
        </MDBCol>
      </MDBRow>
      <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
        <MDBScrollbar>
         <div id="chatContactsList" className="px-2">
           <ul
             className="list-group"
             id="chatContactTab"
             data-chat-list=""
           >
             {FilteredChatList.map((chat, index) => {
               return (
                 <ChatMessageMainBike
                   key={"chat" + index}
                   {...chat}
                   handleChatClick={() => {
                     this.handleChatClick(chat.id);
                   }}

                 />
               );
             })}
           </ul>
         </div>
         </MDBScrollbar>
       </MDBCollapse>
       </>


    );
  }
}
export default ChatListMainBike;
