import React, { Component } from "react";
import ReactDOM from 'react-dom';

import ProfilPageDesktop from './ProfilPage/ProfilPageDesktop';
import ProfilPageResponziv from './ProfilPage/ProfilPageResponziv';

class ProfilPage extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};

  };

  render() {

		return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<ProfilPageDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<ProfilPageResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
	 		</>
    );
  }
}

export default  ProfilPage;
