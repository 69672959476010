import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo from "../../assets/media/logo/1.png";
import{
	MDBRow,
	MDBCol,
	MDBCard,
	MDBNav,
	MDBNavItem,
	MDBNavLink
} from 'mdbreact';

class FooterPublic extends Component {

  constructor(props) {
    super(props);
  };

	aboutUs
	impressum
	privacyPolicy
	termsOfUse
	cookiePolicy
  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
      <div className="footer-nav">
			<MDBNav className="justify-content-center">
				<MDBNavItem>
					<MDBNavLink to="/PrivacyPolicy">{t('privacyPolicy')}</MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/UserAgreement">{t('termsOfUse')}</MDBNavLink>
				</MDBNavItem>
			</MDBNav>
			<div className="text-center py-2">
	    		<img alt="" className="copyright-logo" src={logo}  style={{width: "35px", height: "35px"}}></img>
				<span style={{fontSize: "10px"}} className="px-3">IT-Boda &copy; 2021</span>
            </div>
	    </div>


    );
  }
}

export default withTranslation()(FooterPublic);
