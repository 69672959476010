import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import Flags from 'country-flag-icons/react/3x2'
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import arrowDown from '../../assets/media/images/markt/arrowDown.png';
import arrowUp from '../../assets/media/images/markt/arrowUp.png';
import credit from "../../assets/media/images/markt/profil/credit.png";
import credit_green from "../../assets/media/images/markt/profil/credit-3.png";
import favorit from "../../assets/media/images/markt/profil/favorit.png";
import favorite_green from "../../assets/media/images/markt/profil/favorit-3.png";
import konto from "../../assets/media/images/markt/profil/konto.png";
import konto_green from "../../assets/media/images/markt/profil/konto-3.png";
import notification from "../../assets/media/images/markt/profil/notification.png";
import notification_green from "../../assets/media/images/markt/profil/notification-3.png";
import profilinfo from "../../assets/media/images/markt/profil/profilinfo.png";
import profilinfo_green from "../../assets/media/images/markt/profil/profilInfo-3.png";
import chat_green from "../../assets/media/images/markt/profil/chat-3.png";
import chat from "../../assets/media/images/markt/profil/chat.png";
import signOut from "../../assets/media/images/icons/Vector-6.png";
import wheel from "../../assets/media/images/markt/profil/wheel.png";
import wheel_green from "../../assets/media/images/markt/profil/wheel-3.png";
import Footer from '../Footer/Footer2';
import InterB2BAxios from '../../Service/InterB2BAxios';
import moment from 'moment';
import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
class ProfilpageNavRespo extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'LandingPage');
    this.state = {collapseID: ""
									};
  };





	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		//this.props.userData.refresh();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}





	toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<MDBCard className="profilepage-card-respo">
			<div className="profilpage-hauptmenu text-center">
				<MDBNav className="landingmarkt-nav">
					<MDBNavItem>
						<MDBNavLink link to="/Eintraege" active={this.props.activeItem === "1"} role="tab" >
							<img alt="" className="profil-logo" src={this.props.activeItem === "1" ? wheel_green : wheel}></img>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/Favorite" active={this.props.activeItem === "2"} role="tab" >
							<img alt="" className="profil-logo" src={this.props.activeItem === "2" ? favorite_green : favorit}></img>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/ChatsBike" active={this.props.activeItem === "3"} role="tab" >
							<img alt="" className="profil-logo" src={this.props.activeItem === "3" ? chat_green : chat}></img>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/ProfilInfo" active={this.props.activeItem === "4"} role="tab" >
							<img alt="" className="profil-logo" src={this.props.activeItem === "4" ? profilinfo_green : profilinfo}></img>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/Konto" active={this.props.activeItem === "6"} role="tab" >
							<img alt="" className="profil-logo" src={this.props.activeItem === "6" ? konto_green : konto}></img>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/Benachrichtigungen" active={this.props.activeItem === "7"} role="tab" >
							<img alt="" className="profil-logo" src={this.props.activeItem === "7" ? notification_green : notification}></img>
						</MDBNavLink>
					</MDBNavItem>
				</MDBNav>
				{/**/}
			</div>
			</MDBCard>
		</>
    );
  }
}

export default  withTranslation()(ProfilpageNavRespo);
