import React, { Component } from "react";
import ChatsBikeB2BResponziv from "./ChatsBikeB2B/ChatsBikeB2BResponziv";
import ChatsBikeB2BDesktop from "./ChatsBikeB2B/ChatsBikeB2BDesktop";

class ChatsBikeB2B extends Component {

  constructor(props) {
    super(props);
    this.state = {
		};
  };

  render() {

    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<ChatsBikeB2BDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 sendMessage = {this.props.sendMessage}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<ChatsBikeB2BResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 sendMessage = {this.props.sendMessage}
																		 {...this.props}
																		 />
				</div>}
			</>
    );
  }
}
export default ChatsBikeB2B;
