import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo from "../../assets/media/images/b2b/Footer-logo.png";
import{
	MDBRow,
	MDBCol,
	MDBCard,
	MDBNav,
	MDBNavItem,
	MDBNavLink
} from 'mdbreact';

class Footer extends Component {

  constructor(props) {
    super(props);
  };

	/*aboutUs
	impressum
	privacyPolicy
	termsOfUse
	cookiePolicy*/
  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
      <div className="footer-nav">
			<MDBNav className="justify-content-center">
				<MDBNavItem>
					<MDBNavLink to="/About"><span className="footer-text-b2b">{t('aboutUs')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/Impressum"><span className="footer-text-b2b">{t('impressum')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/PrivacyPolicy"><span className="footer-text-b2b">{t('privacyPolicy')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/UserAgreement"><span className="footer-text-b2b">{t('termsOfUse')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/CookiePolicy"><span className="footer-text-b2b">{t('cookiePolicy')}</span></MDBNavLink>
				</MDBNavItem>
			</MDBNav>
			<div className="text-center pb-0 pt-2">
	    		<img alt="" className="copyright-logo-b2b" src={logo}></img>
            </div>
	    </div>


    );
  }
}

export default withTranslation()(Footer);
