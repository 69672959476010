import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../../components/LazyLoad/LazyLoadItem';
import { Scrollbar } from "react-scrollbars-custom";
import MenuSideNavB2BikeMain from '../MainPageHandler/MenuSideNavB2BikeMain';
import LandingPageB2BikeNavRespo2 from '../../../../components/NavbarComponentB2B/LandingPageB2BikeNavRespo2';
import InterB2BAxios from '../../../../Service/InterB2BAxios';
import CompanysItemWithDesign from '../../../../components/Companys/CompanysItemWithDesign';
import FooterHomePage from '../../../../components/Footer/FooterHomePage';
import BikeAnzeigeKarte from '../../../../components/Companys/BikeAnzeigeKarte';
import SonstigeNavB2B from '../../../../components/NavbarComponentB2B/SonstigeNavB2B';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from '../../../../assets/scss/modules/pages.module.scss';
import arrowDown from '../../../../assets/media/images/markt/arrowDown.png';
import arrowUp from '../../../../assets/media/images/markt/arrowUp.png';
import arrowUp2 from "../../../../assets/media/images/markt/arrowUp2.png";
import logo from "../../../../assets/media/logo/1.png";
import ratingicon from "../../../../assets/media/newicons/rating.png";
import searchnormalicon from "../../../../assets/media/images/markt/searchnormalicon.png";
import group_21 from "../../../../assets/media/images/markt/Group 21.png";
import addBike from "../../../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import logo2 from "../../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import anmelden from "../../../../assets/media/images/markt/anmelden.png";
import signinmarkt from "../../../../assets/media/images/markt/signinmarkt.png";
import signupmarkt from "../../../../assets/media/images/markt/signupmarkt.png";
import logo3 from "../../../../assets/media/images/markt/Group 18.png";
import meinprofilicon from '../../../../assets/media/newicons/meinprofil.png';
import clearOffericon from "../../../../assets/media/newicons/clearOffer.png";
import arrowLeft from "../../../../assets/media/images/arrowLeft.png";
import companyicon from "../../../../assets/media/images/b2b/Group 28.png";
import companyicon_gelb from "../../../../assets/media/images/b2b/Group 29.png";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBAutocomplete,
	MDBSpinner,
	MDBSideNav,
	MDBCollapse
} from 'mdbreact';

let language = '';
class ProductPageResponziv extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'ProductPage');
    this.state = {textFeld: this.props.textFeld,
									textSearch2: this.props.userData.productname,
									activeItem: this.props.activeItem,
									};
  };


	componentDidMount() {
			var params = window.location.href.split(".");
	    language = params[params.length - 1];
	}

	componentWillUnmount() {
		this.setState({textSearch2: ""});
		this.interAxios.cancel();
	}

	onKey = (ev) => {
		ev.preventDefault();
		if(this.state.textSearch2 !== "" ){
			this.onSearchProduct()
		}else{
			 this.onTextTrue();
		}
	}

	toggle = (tab) => {
		this.toggleCollapse();
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
				}
	}

	toggleCollapse = () => {
		this.setState(prevState => ({
			collapseID: prevState.collapseID !== "basicCollapse" ? "basicCollapse" : ""
		}));
	}

	onSearchFullProduct = () => {
		this.setState({activeItem: ""});
		this.props.userData.onSearchProductFull(this.props.userData.productname);
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		let searchLis = this.props.userData.offerlist !== undefined ? this.props.userData.offerlist : {};

		let companiesList = this.props.userData.availableCompanies.length > 0 ? this.props.userData.availableCompanies.map(
								(o) => {
										return (
														 <div key={o.id} className="p-0">
										 					<MDBNavItem>
										 						<MDBNavLink link to="#" active={this.state.activeItem === o.id} onClick={() => this.toggle(o.id)} role="tab" >
																	<MDBBtn
																			className="product-filtercompany-button"
																			flat
																			onClick={() => this.props.userData.onSearchProductWithCompanyFilter(this.props.userData.productname, o.name)}>
																	 <MDBRow className="m-0">
																		 <MDBCol middle md="1" size="1" className="px-0 col-height text-left">
																			<img alt="" className="profil-logo" src={this.state.activeItem === o.id ? companyicon_gelb : companyicon}></img>
																		</MDBCol>
																		<MDBCol middle  md="9" size="9" className="pl-2 pr-2 col-height text-left text-capitalize">
																			<span className="profilpage-menu">{o.name}:</span>
																		</MDBCol>
																		 <MDBCol middle  md="2" size="2" className="p-0 text-right">
																		 <span className="profilpage-menu">{o.count}</span>
																		 </MDBCol>
																	  </MDBRow>
																  </MDBBtn>
										 						</MDBNavLink>
										 					</MDBNavItem>
														 </div>);
									}
							) : {};
    return (
			<>
				<div ref={this.listInnerRefInterB2B}/>
				<MDBContainer fluid className="navbarcomponentRespo-nav-container-b2b">
					<div className={styles.bodywrapper}>
						<div className="side-nav-menu-b2b">
						<MDBSideNav
						right
						triggerOpening={this.props.isSideNavOpen}
						onOverlayClick={this.props.handleSideNavToggle}
						breakWidth={1300}
						className="deep-purple darken-4"
						>
						<MenuSideNavB2BikeMain
							userData={this.props.userData}
							onMenuClose = {this.props.onEnableScroll}
							onItemClose = {this.props.onEnableScrollMain}
							activeItem = {1}
						/>
						</MDBSideNav>
						</div>
						<LandingPageB2BikeNavRespo2  activeItem = {"4"}
																					  userData={this.props.userData}
																					  onMenuOpen = {this.props.handleSideNavToggle}
																					  onRefInterB2B={this.onRefInterB2B}/>
					<MDBCard className="filter-card-anzeige">
						<MDBCardBody className="py-2">
							{this.props.userData.searchStatus ? <>
							<div className="anzeigepage-filter">
								<MDBRow center className="m-0 pt-3 pb-0">
									<span className="product-filter-text">Schnellsuchergebnis mit den beliebtesten Produkten.</span>
								</MDBRow>
								<MDBRow center className="m-0 pt-2">
									<MDBBtn className="product-filter-btn"
													onClick={this.onSearchFullProduct}>
													<span>Full list</span>
									</MDBBtn>
								</MDBRow>
								<MDBRow center className="m-0 pb-0">
									<span className="product-filter-text">{this.props.userData.productCount} products</span>
								</MDBRow>
								<MDBRow center className="m-0">
									<MDBBtn
										flat
										className="collapse-button-respo"
										onClick={this.toggleCollapse}
									>
										<span className="collapse-btn-text-productpage text-capitalize">Manufacturers </span>
										<img className="collapse-btn-icon" src={!this.state.collapseID ? arrowDown : arrowUp}/>
									</MDBBtn>
								</MDBRow>
							</div>
							<MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
								<div className="anzeigepage-filter">

									{companiesList.length > 0 ?
									<>
										<MDBRow className="m-0">
										<MDBCol md="12" className="p-0">
											  <MDBNav className="flex-column b2b-product-nav">
												{companiesList}
											</MDBNav>
										</MDBCol>
										</MDBRow></>:false}
								</div>
							</MDBCollapse>
							</>:false}
						</MDBCardBody>
					</MDBCard>
					<MDBRow className="m-0">
						<MDBCol md="8" size="12" className="text-center">
						<div id="productlist">
						{ searchLis !== {}  && searchLis.items !== undefined && searchLis.items.length > 0 ?
						 <>

						{searchLis.items.map(
						 (o, index) => {
							 if (index < 20) {
								 return (
									 <CompanysItemWithDesign
																	key={o.standardItemIdentification.id.value}
																	msg={o}
																	activeItem = {this.state.activeItem}
																	userData = {this.props.userData}
																	suppliers={searchLis.suppliers}
																	textSearch = {this.state.textSearch2}
																	{...this}
																	{...this.props}
																 />);
							 } else {
								 return (
									 <LazyLoad key={o.standardItemIdentification.id.value} height={200} offset={[-100, 0]}>
										<LazyLoadItem index={index} size={searchLis.items.length - 20}>
										<CompanysItemWithDesign
																 key={o.standardItemIdentification.id.value}
																 msg={o}
																 userData = {this.props.userData}
																 suppliers={searchLis.suppliers}
																 activeItem = {this.state.activeItem}
																 textSearch = {this.state.textSearch2}
																 {...this}
																 {...this.props}
																/>
										</LazyLoadItem>
								 </LazyLoad>
							 );
							 }
						 }
					 )} </>: this.props.userData.spinner ?
											<div className="spinner-position">
											<MDBSpinner /><br/>
							        <span className="">Loading...</span>
							      </div>:false}
						</div>
						</MDBCol>
					</MDBRow>
					</div>
				</MDBContainer>
		  </>
    );
  }
}

export default  withTranslation()(ProductPageResponziv);
