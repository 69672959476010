import React, { Component, Fragment } from "react";

import Cookies from 'universal-cookie';
import foto2 from "../assets/media/fotos/2.jpg";
import styles from '../assets/scss/modules/companypublicprofile.module.scss';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBLightbox } from
"mdbreact";
import LightboxPageToMitteilungen from '../components/LightboxPage/LightboxPageToMitteilungen';
import GoogleMaps5 from '../components/GoogleMaps/GoogleMaps5';
import ImageManagerPublic from '../components/MyProfile/ImageManagerPublic';
import MapBox from '../components/MapBox/MapBox';
import Moment from 'moment';
import { Redirect } from 'react-router';
import 'moment/locale/de';
import logo from "../assets/media/logo/1.png";
import Flags from 'country-flag-icons/react/3x2'
import trahsicon from "../assets/media/newicons/trash.png";
import checkdone from "../assets/media/newicons/check_done.png";
import arrowcirclelefticon from "../assets/media/newicons/arrow-circle-left2.png";
import companiesPublicObject from "../assets/media/images/companiesPublicObject.png";
import galleryicon from "../assets/media/newicons/gallery.png";
import plusimgicon from "../assets/media/newicons/plusImg.png";
import editicon from "../assets/media/newicons/editProfil.png";
import logouticon from "../assets/media/newicons/logout.png";
import ib2bicon from "../assets/media/images/ib2b.png";
import FooterPublic from '../components/Footer/FooterPublic';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';


import{
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBScrollbar,
	MDBFormInline,
	MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBInput
} from 'mdbreact';

import InterB2BAxios from '../Service/InterB2BAxios';

const cookies = new Cookies();
let companyName = '';
class CompaniesPageProfile extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {currentCompany: {}, isOpenFalse: false, redirect: false};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.refreshNavbar();
		var params = window.location.href.split("/");
		companyName = params[params.length - 1];
		this.onFindCompanyByName();
		//this.onAllCompanyName();
		//this.onUpdateAllCompanies();
	};



	componentWillUnmount() {
		this.interAxios.cancel();
	}

	onIsOpen = () => {
		this.setState({isOpenFalse: true});
	}


	onFindCompanyByName = event => {
	 let self = this;
	 this.interAxios.get('/api/publiccompany/', {
			 params: { namePattern: companyName },
		 }, function(response){
		 self.setState({currentCompany: response.data[0], companyPhotosUrl: response.data[0].urls, timeStamp: Date.now()});
	 })
	};

	onEmailChange = (event) =>{
    this.setState({email: event.target.value});
  };

	onSubmit = (event) =>{
    event.preventDefault();
    let self = this;
    this.interAxios.post('/api/email/informationspublic', {email: this.state.email}, function(response) {
     })
  };

	onRedirect = () => {
		this.setState({redirect: true});
	}

	toGerman = () => {
    i18next.changeLanguage('de', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
    });
  }

  toEnglish = () => {
    i18next.changeLanguage('en', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
    });
  }

	/*onAllCompanyName = () => {
		let self = this;
		this.interAxios.get('/api/publicAllCompanyNameFull', function(response) {
			let nameList = response.data.map(n => ({name: n, nameUrl: n.replace(/-/g, ' ').replace(/\./g, '')}));
			console.log(nameList);
			//self.setState({	nameList: response.data});
		});
 	};*/

	/*onUpdateAllCompanies = () => {
		this.interAxios.get('/api/allCompaniesUpdate', function(response) {
		});
 	};*/

  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
<>
<div className={styles.bodywrapper}>
	<section className="profile-header">
		<MDBRow>
			<MDBCol size="12" className="profile-header-bg" style={{backgroundImage: `url(${this.state.currentCompany.backgroundUrl+ '?' + this.state.timeStamp})`}}>
			</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="12" className="profile-header-content">
				<MDBContainer>
					<MDBRow>
						<MDBCol size="6">
							{this.state.currentCompany.id!== undefined ?
								<img className="rounded-circle profile-image"
									src={this.state.currentCompany.profileUrl +'?' + this.state.timeStamp}
									onClick={this.state.handleShowProfile}
								/>:false}
						</MDBCol>
						<MDBCol size="6" className="profile-header-btns">
							<MDBNav className="justify-content-end">
								<MDBNavItem>
									<MDBNavLink to={companyName} onClick={this.toEnglish}><Flags.GB style={{borderRadius: "2px", width: "35px"}}/></MDBNavLink>
								</MDBNavItem>
								<MDBNavItem>
									<MDBNavLink to={companyName} onClick={this.toGerman}><Flags.DE style={{borderRadius: "2px", width: "35px"}}/></MDBNavLink>
								</MDBNavItem>
							</MDBNav>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBCol>
		</MDBRow>
	</section>

	<MDBContainer className="profile-content">
		<MDBRow>
			<MDBCol size="12" md="6">
				<MDBCard className="transparent-card">

					<MDBRow>
						<img  className="profile-ib2bicon" src={ib2bicon}/>
					</MDBRow>
					<MDBRow>
						<MDBCol size="9" md="9" lg="10" xl="10">
							<h3>{this.state.currentCompany.name}</h3>
							<small style={{fontSize: "18px"}}>{this.state.currentCompany.street}</small><br/>
							<small style={{fontSize: "18px"}}>{this.state.currentCompany.postcode} {this.state.currentCompany.town}</small><br/>
							<small style={{fontSize: "18px"}}>{this.state.currentCompany.country}</small><br/>
							<br/>
							{this.state.currentCompany.phonenumber !=="" && this.state.currentCompany.phonenumber !== null ?
							<MDBIcon icon="phone fa-fw mr-2"/>:false} <small style={{fontSize: "18px"}}>{this.state.currentCompany.phonenumber}</small><br/>
							{this.state.currentCompany.email !=="" && this.state.currentCompany.email !== null ?
							<MDBIcon icon="at fa-fw mr-2"/>:false} <a style={{fontSize: "18px"}} href={"mailto:" + this.state.currentCompany.email}>{this.state.currentCompany.email}</a><br/>
							{this.state.currentCompany.website !=="" && this.state.currentCompany.website !== null ?
							<MDBIcon icon="link fa-fw mr-2"/>:false} <a style={{fontSize: "18px"}} href={"https://" + this.state.currentCompany.website} target="_blank" rel="noopener noreferrer" className="underlined">{this.state.currentCompany.website}</a><br/><br/>
						</MDBCol>
						<MDBCol size="3" md="3" lg="2" xl="2">
						</MDBCol>
					</MDBRow>
				</MDBCard>
			</MDBCol>
			<MDBCol size="12" md="6">
				{this.state.currentCompany.lat !== "" ?
					<div className="profile-mapholder">
					<GoogleMaps5 currentCompany={this.props.currentCompany}
											 isCompaniesMap= {true}
											 withMap = {"100%"}
											 heightMap = {"270px"}
											/>
					</div>:false}
				</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="12" md="6">
				<ImageManagerPublic
					editable={false}
					public= {true}
					photosList = {this.state.companyPhotosUrl}
					onIsOpen = {this.onIsOpen}/>
			</MDBCol>
			<MDBCol size="12" md="6">
				{this.state.currentCompany.aboutUs !== null ?
				<MDBCard className="interb2b-card px-4 pt-2">
					<MDBRow className="form-row align-items-center">
						<MDBCol>
							<h3>{t('uber_uns')}</h3>
						</MDBCol>
					</MDBRow>
					<MDBRow>
				<MDBCol>
					<div className=' py-2'
					dangerouslySetInnerHTML={{__html: this.state.currentCompany.aboutUs }}/>
				</MDBCol>
				</MDBRow>
				</MDBCard>:false}
			</MDBCol>
		</MDBRow>
	</MDBContainer>

	<section className="companiesprofile-bottom">
		<MDBContainer>
			<MDBRow>
				<MDBCol md="6" className="order-2 order-md-1">
					<div className="companiesprofile-bottom-left">
						<img className="companiesprofile-logo" src={logo}/>
						<p className="companiespublic-standardtitle">{t('brauche_mehr_infos')}</p>
						<small className="companiespublic-standardtext">{t('bitte_geben_sie_uns_ihre_E_Mail_Adresse_an_public1')}{this.state.currentCompany.name}{t('bitte_geben_sie_uns_ihre_E_Mail_Adresse_an_public2')}</small>

						<MDBFormInline className="landing-form companiesprofile-bottom-form">
							<MDBInput
								className="landing-input"
								label= "Email"
								type="text"
								success="right"
								value={this.state.email}
								onChange={this.onEmailChange}
								/>
							<MDBBtn
								className="contact-btn-landing"
								color="primary"
								type="submit"
								onClick={async (event) => {await this.onSubmit(event);}}>{t('senden')}</MDBBtn>
						</MDBFormInline>
					</div>
				</MDBCol>
				<MDBCol md="6" className="order-1 order-md-1 ">
					<div className="companiesprofile-bottom-right">
						<img className="img-fluid" src={companiesPublicObject}/>

						<p className="companiespublic-standardtitle">{t('tauchen_sie_ein_in_ihren_markt_erreichen')}</p>
						<p className="companiespublic-standardtext">{t('recherchieren_sie_schnell_den_markt_und_liefern_sie')}</p>

						<MDBBtn
							className="signin-btn-public"
							color="primary"
							type="button"
							onClick={this.onRedirect}
							>{t('weiter_zu_InterB2B')}</MDBBtn>
					</div>
				</MDBCol>
			</MDBRow>
			<FooterPublic homeStatus={false} public={true}/>
		</MDBContainer>
	</section>

{ this.state.redirect === true ?
		<Redirect to='/LandingPage'
	/>:
false
}
</div>
</>
    );
  }
}

export default withTranslation()(CompaniesPageProfile);
