import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Redirect, Link } from 'react-router-dom';
import styles from '../../assets/scss/modules/pages.module.scss';
import group_22 from "../../assets/media/images/b2b/E-Bikebörse Logo Neu.png";
import{
  MDBNavItem,
  MDBNavLink,
  MDBBtn
} from 'mdbreact';

class EBikeBoerseLinkNavItem extends React.Component {

  render() {
    if (this.props.userData.partner() === 'Dealer') {
      let button = <div className="navlink-borse">
      <MDBBtn
        flat
        onClick={() => this.props.userData.onLogout()}
        className="p-0 m-0"
      >
        <img alt="" className="group-22-logo" src={group_22}></img>
      </MDBBtn>
      </div>;

      let to = "/LandingPageMarkt";
      let link;
      if (this.props.userData.isRemoteB2BPage()) {
        to = process.env.REACT_APP_EBIKEBOERSE_FRONTEND_URL + this.props.userData.getBaseName() + "?interb2bike=true";
        //console.log(to);
        link = <a href={to} className="nav-link Ripple-parent">{button}</a>;
      } else {
        link = <MDBNavLink link to={to} role="tab">{button}</MDBNavLink>;
      }
      return (<MDBNavItem>
          {link}
        </MDBNavItem>);
    } else {
      return <></>;
    }
  }

}

export default EBikeBoerseLinkNavItem;
