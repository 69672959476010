import React, { Component } from "react";
import ReactDOM from 'react-dom';


import BikeInserierenUpdateDesktop from './BikeInserierenUpdate/BikeInserierenUpdateDesktop';
import BikeInserierenUpdateResponziv from './BikeInserierenUpdate/BikeInserierenUpdateResponziv';

class BikeInserierenUpdate extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };


  render() {
    return (
			<>
				<div className="d-none d-lg-block">
					<BikeInserierenUpdateDesktop userData={this.props.userData}
																			 addFunctionFromChild = {this.props.addFunctionFromChild}
																			 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																			 msg = {this.props.location?.state?.msg.preis !== undefined ? this.props.location.state.msg : {}}
																			 {...this.props}
																	 	 />
				</div>
				<div className="d-lg-none d-block">
					<BikeInserierenUpdateResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 msg = {this.props.location?.state?.msg.preis !== undefined ? this.props.location.state.msg : {}}
																		 {...this.props}
																		 />
				</div>
			</>
    );
  }
}

export default  BikeInserierenUpdate;
