import React, { Component } from "react";
import { MDBContainer,
         MDBBtn,
         MDBModal,
         MDBModalBody,
         MDBModalHeader,
         MDBModalFooter,
         MDBInputGroup,
         MDBInput,
         MDBSelect ,
         MDBSelectInput,
         MDBSelectOptions,
         MDBSelectOption,
         MDBRow,

      } from 'mdbreact';
import Cookies from 'universal-cookie';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import InterB2BAxios from '../../Service/InterB2BAxios';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import GoogleMaps5 from '../../components/GoogleMaps/GoogleMaps5';
import { Scrollbar } from "react-scrollbars-custom";
const cookies = new Cookies();


class FilterByStandort extends Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    const { t } = this.props;
		const client = this.props.client;
    this.state = {valueUmkreis: this.props.valueUmkreis,
                  latitude: this.props.latitude,
                  longitude: this.props.longitude,
                  umkreisNot: false,
                  standort: t('Aktuelle_Standort'),
                  options: [
                            { checked: this.props.aktuelleStandort !== this.props.adresse,
                              text: t('Aktuelle_Standort'),
                              value: "1"
                            },
                            { checked: this.props.aktuelleStandort === this.props.adresse,
                              text: this.props.adresse,
                              value: "2"
                            }
                          ]
                };
  };

  componentDidMount() {

  };

  componentWillUnmount() {
    this.interAxios.cancel();
  }


  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = () => {
    this.props.handleClose();
    this.setState({show: false});
  }

  handleShow = () => {
    this.setState({show: true});
  }

  handleChangeUmkreis = (event, newValue) => {
    this.setState({valueUmkreis: newValue});
    this.props.onGetUmkreis(newValue < 650 ? newValue : 20000);
  };

  onSetStandort = value => {
    if(value === "Aktuelle Standort"){
      this.setState({latitude: this.props.userData.getLatLng().originalLatitude, longitude: this.props.userData.getLatLng().originalLongitude, standort: value});
      this.props.onGetStandort(this.state.originalAdresse);
      this.props.onGetLatLng(this.props.userData.getLatLng().originalLatitude, this.props.userData.getLatLng().originalLongitude);
    }else {
      this.setState({latitude: this.props.latitude, longitude: this.props.longitude, standort: value});
      this.props.onGetStandort(this.props.adresse);
      this.props.onGetLatLng(this.props.latitude, this.props.longitude);
    }
	}

  onGetOriginalAdresse = (adresse) => {
		this.setState({originalAdresse: adresse});
	}

  onValueLabelFormat = () => {
    return this.state.valueUmkreis < 650 ? this.state.valueUmkreis : "Unlimited"
  }


  render() {

    const { t } = this.props;
		const client = this.props.client;
    const marksUmkreis = [
      {
        value: 30,
        label: '30km',
      },
      {
        value: 50,
      },
      {
        value: 100,
      },
      {
        value: 200,
      },
    	{
        value: 600,
      },
      {
        value: 700,
        label: 'Unlimited',
      },
    ];
    return (
<MDBContainer>
  <div className="standort-filter-modal">
  <MDBModal
         isOpen={this.props.show}
         toggle={this.handleClose}>
    <MDBModalHeader toggle={this.handleClose}>
      <span className="filtercard-title-umkreis">{t('Nach_Standort_filtern')}</span>
    </MDBModalHeader>
    <MDBModalBody className="pt-0 pb-3">
    <Scrollbar
      id="scrollbar"
      style={{ width: "98%", height: "58vh"}}
      maximalThumbYSize = {150}
      noScrollX = {true}
      >
      	{this.props.userData.isLoggedInMarkt() && this.props.adresse !== "N/A" ?
      <MDBRow center className="m-0 py-0 form-outline-umkreis">
        <MDBSelect
          getTextContent={this.onSetStandort}
          selected={this.state.standort}
          options={this.state.options}
        />
      </MDBRow>:false}
      <MDBRow center className="m-0 py-3">
      </MDBRow>
    {this.state.latitude !== "" ?
      <MDBRow center className="m-0 py-3">
          <GoogleMaps5 latitude={this.state.latitude}
                       longitude={this.state.longitude}
                       userData = {this.props.userData}
                       valueUmkreis={this.state.valueUmkreis < 650 ? this.state.valueUmkreis : 20000}
                       isUmkreis = {true}
                       withMap = {"100%"}
                       heightMap = {"35%"}
                       onGetOriginalAdresse = {this.onGetOriginalAdresse}/>
      </MDBRow>:false}
        <MDBRow center className="m-0">
        <Box sx={{ width: "80%" }} className="slider-filter-umkreis">
          <Typography id="track-false-slider" gutterBottom>
            <span className="radius-text">Radius ({this.state.valueUmkreis < 700 ? this.state.valueUmkreis + " km" : "Unlimited"})</span>
          </Typography>
          <Slider
            getAriaLabel={() => 'Umkreis range'}
            defaultValue={100}
            value={this.state.valueUmkreis}
            onChange={this.handleChangeUmkreis}
            valueLabelFormat={this.onValueLabelFormat}
            step={null}
            min={30}
            max={700}
            valueLabelDisplay="auto"
            marks={marksUmkreis}
          />
        </Box>
        </MDBRow>
      <MDBRow center className="m-0 py-3">
        <MDBBtn flat className="filtercard-umkreis-btn" onClick={this.handleClose}>
        <span className="text-capitalize">Filter ({this.props.bikeAnzahl !== undefined ? this.props.bikeAnzahl : 0})</span></MDBBtn>
      </MDBRow>
      </Scrollbar>
    </MDBModalBody>

  </MDBModal>
  </div>
</MDBContainer>
    );
  }
}
export default withTranslation()(FilterByStandort);
