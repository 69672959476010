import React, { Component } from "react";

import { ReactComponent as SearchSvg } from "../../assets/media/icons/search.svg";
import Cookies from 'universal-cookie';
import InterB2BAxios from '../../Service/InterB2BAxios';
import{
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBContainer,
	MDBModalFooter,
	MDBBtn,
  MDBFileInput,
  MDBSelect
} from 'mdbreact';

const cookies = new Cookies();

class FileManager extends Component {
  state = { selectedPhotos: [] ,
            photoslist: [],
            selectedFiles: [],
            images : [],
            selectedFilesToDelete: [],
            token : cookies.get('authtoken').token,
            myUserId: cookies.get('authtoken').userId};

	constructor(props) {
		super(props);
		this.interAxios = new InterB2BAxios(this);
	}

  componentDidMount() {
		this.onImagesToDelete();
  };

	componentWillUnmount(props) {
		this.interAxios.cancel(this);
	}

  onFileChange = files => {
    let list = this.state.selectedFiles;
    for (let i = 0; i < files.length; i++) {
      list.push(files[i]);
    }
    this.setState({selectedFiles: list});
  };

  selectFilesToDelete = files => {
    let list = [];
    for (let i = 0; i < files.length; i++) {
      list.push(files[i]);
    }
    this.setState({selectedFilesToDelete: list});
		console.log(list);

  }

  onFileUpload = () => {
    let self = this;
    if(this.state.selectedFiles.length>0){
	    const formData = new FormData
	    for (let i = 0; i < this.state.selectedFiles.length; i++) {
	      formData.append("files", this.state.selectedFiles[i], this.state.selectedFiles[i].name);
	    }
			this.interAxios.post('/upload_company_photos/' + this.props.currentCompany.id, formData, {
	      headers: {
	        'Content-Type': 'multipart/form-data'
				}}, function(response) {
					self.props.handleClose();
				}
			);
	    /*axios.post(process.env.REACT_APP_BACKEND_URL+'/upload_company_photos/' + this.props.currentCompany.id, formData, {
	      headers: {
	        'Content-Type': 'multipart/form-data',
	        'Authorization': 'Bearer ' + this.state.token
	      }
	    }).then(function(response) {

	      self.props.handleClose();

	    }).catch(function(error) {
	      console.log(error)
	    });*/
    }else{
      self.props.handleClose();

    }
  }

  onFileDelete = () => {
    let self = this;
		if (this.state.selectedFilesToDelete.length>0){
			this.interAxios.post('/storage/companies/'+this.props.currentCompany.id+'/photos', this.state.selectedFilesToDelete, function(response) {
	      self.onFileUpload();
	    });
/*	    axios.post(process.env.REACT_APP_BACKEND_URL+'/storage/companies/'+this.props.currentCompany.id+'/photos',
	        this.state.selectedFilesToDelete,
	         {headers: {
	            'Authorization': 'Bearer ' + this.state.token
	          }
	        }
	    ).then(function(response) {

	      self.onFileUpload();

	    }).catch(function(error) {
	      console.log(error)
	    });*/
		}else{
			self.onFileUpload();
		}
  }

  setModalShow = () => {
    this.props.show();
  };

  setModalClose = () => {
    this.props.handleClose();
  };


  onImagesToDelete = () => {
    var i=1;
    for (const companyPhoto of this.props.currentCompany.urls){
      let titleArray = companyPhoto.split("\/");
      let title = titleArray[titleArray.length-1];
			this.state.images.push({icon: companyPhoto + '?' + Date.now(),
                   value: title,
                   text: title,
                  });
			i++;
    }

  }

  render() {
    let fileList = this.state.selectedFiles.map((file, index) =>
      <p key={index}>Dateinamen: {file.name}</p>
    );

    return (
      <MDBContainer>
      <MDBModal isOpen={this.props.show} toggle={this.props.handleClose}>
        <MDBModalHeader toggle={this.props.handleClose}>
                  Bilder bearbeiten, hinzufügen, löschen
        </MDBModalHeader>
          <MDBModalBody>
            <div>
              <MDBSelect
                getValue ={this.selectFilesToDelete}
                multiple
                options={this.state.images}
                selected="Wähle deine Option"
                label="Fotos auswählen"
              />
            </div>
            {fileList}
            <MDBFileInput getValue={this.onFileChange} multiple btnColor="info" />

            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={this.props.handleClose}>
                Schließen
              </MDBBtn>
              <MDBBtn color="primary" onClick={this.onFileDelete}>Speichern</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
    );
  }
}
export default FileManager;
