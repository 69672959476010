import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import Flags from 'country-flag-icons/react/3x2'
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import credit from "../../assets/media/images/markt/profil/credit.png";
import credit_green from "../../assets/media/images/markt/profil/credit_green.png";
import favorit from "../../assets/media/images/markt/profil/favorit.png";
import favorite_green from "../../assets/media/images/markt/profil/favorite_green.png";
import konto from "../../assets/media/images/markt/profil/konto.png";
import konto_green from "../../assets/media/images/markt/profil/konto_green.png";
import notification from "../../assets/media/images/markt/profil/notification.png";
import notification_green from "../../assets/media/images/markt/profil/notification_green.png";
import profilinfo from "../../assets/media/images/markt/profil/profilinfo.png";
import profilinfo_green from "../../assets/media/images/markt/profil/profilinfo_green.png";
import chat_green from "../../assets/media/images/markt/profil/chat_green.png";
import chat from "../../assets/media/images/markt/profil/chat.png";
import signOut from "../../assets/media/images/icons/Vector-6.png";
import wheel from "../../assets/media/images/markt/profil/wheel.png";
import wheel_green from "../../assets/media/images/markt/profil/wheel_green.png";
import Footer from '../Footer/Footer2';
import InterB2BAxios from '../../Service/InterB2BAxios';
import moment from 'moment';
import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
class ProfilpageNav extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'LandingPage');
    this.state = {
									};
  };





	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		//this.props.userData.refresh();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	setBackendLanguage = (language) => {
		this.interAxios.get('/api/user/setLanguage', { params: { language: language}}, function(response) {
			console.log('Language set to ' + language)
		})
	}

	toGerman = () => {
		i18next.changeLanguage('de', (err, t) => {
			if (err) return console.log('something went wrong loading', err);
			this.setBackendLanguage('de');
			//this.setState({language: 'de'});
		});
		moment.locale('de');
		//this.onLanguageDropFalse();
	}

	toEnglish = () => {
		i18next.changeLanguage('en', (err, t) => {
			if (err) return console.log('something went wrong loading', err);
			this.setBackendLanguage('en');
			//this.setState({language: 'en'});
		});
		moment.locale('en');
		//this.onLanguageDropFalse();
	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<div className="sticky-card-b2b">
			<MDBCard className="profilepage-card">
			<div className="profilpage-hauptmenu">
				<MDBNav className="flex-column landingmarkt-nav">
					<MDBNavItem>
						<MDBNavLink link to="/Eintraege" active={this.props.activeItem === "1"} role="tab" >
							<MDBRow className="m-0">
								<MDBCol middle md="auto" className="px-0 col-height">
									<img alt="" className="profil-logo" src={this.props.activeItem === "1" ? wheel_green : wheel}></img>
								</MDBCol>
								<MDBCol middle className="pl-2 pr-2 col-height">
									<span className="profilpage-menu">{t('eigene_anzeigen')}</span>
								</MDBCol>
							</MDBRow>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/Favorite" active={this.props.activeItem === "2"} role="tab" >
							<MDBRow className="m-0">
								<MDBCol middle md="auto" className="px-0 col-height">
									<img alt="" className="profil-logo" src={this.props.activeItem === "2" ? favorite_green : favorit}></img>
								</MDBCol>
								<MDBCol middle className="pl-2 pr-2 col-height">
									<span className="profilpage-menu">{t('favoriten')}</span>
								</MDBCol>
							</MDBRow>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/ChatsBike" active={this.props.activeItem === "3"} role="tab" >
							<MDBRow className="m-0">
								<MDBCol middle md="auto" className="px-0 col-height">
									<img alt="" className="profil-logo" src={this.props.activeItem === "3" ? chat_green : chat}></img>
								</MDBCol>
								<MDBCol middle className="pl-2 pr-2 col-height">
									<span className="profilpage-menu">Chat</span>
								</MDBCol>
							</MDBRow>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/ProfilInfo" active={this.props.activeItem === "4"} role="tab" >
							<MDBRow className="m-0">
								<MDBCol middle md="auto" className="px-0 col-height">
									<img alt="" className="profil-logo" src={this.props.activeItem === "4" ? profilinfo_green : profilinfo}></img>
								</MDBCol>
								<MDBCol middle className="pl-2 pr-2 col-height">
									<span className="profilpage-menu">{t('profil_info')}</span>
								</MDBCol>
							</MDBRow>
						</MDBNavLink>
					</MDBNavItem>
					{/*<MDBNavItem>
						<MDBNavLink link to="/Transaktionen" active={this.props.activeItem === "5"} role="tab" >
						<MDBRow className="m-0">
							<MDBCol middle md="auto" className="px-0 col-height">
								<img alt="" className="profil-logo" src={this.props.activeItem === "5" ? credit_green : credit}></img>
							</MDBCol>
							<MDBCol middle className="pl-2 pr-2 col-height">
								<span className="profilpage-menu">Transaktionen</span>
							</MDBCol>
						</MDBRow>
						</MDBNavLink>
					</MDBNavItem>*/}
					<MDBNavItem>
						<MDBNavLink link to="/Konto" active={this.props.activeItem === "6"} role="tab" >
						<MDBRow className="m-0">
							<MDBCol middle md="auto" className="px-0 col-height">
								<img alt="" className="profil-logo" src={this.props.activeItem === "6" ? konto_green : konto}></img>
							</MDBCol>
							<MDBCol middle className="pl-2 pr-2 col-height">
								<span className="profilpage-menu">{t('konto')}</span>
							</MDBCol>
						</MDBRow>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/Benachrichtigungen" active={this.props.activeItem === "7"} role="tab" >
							<MDBRow className="m-0">
								<MDBCol middle md="auto" className="px-0 col-height">
									<img alt="" className="profil-logo" src={this.props.activeItem === "7" ? notification_green : notification}></img>
								</MDBCol>
								<MDBCol middle className="pl-2 pr-2 col-height">
									<span className="profilpage-menu">{t('benachrichtigungen')}</span>
								</MDBCol>
							</MDBRow>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink link to="/LandingPageMarkt" active={this.props.activeItem === "8"} role="tab" >
							<MDBBtn
								flat
								onClick={() => this.props.userData.onLogout()}
								className="p-0 m-0"
							>
								<MDBRow className="m-0">
									<MDBCol middle md="auto" className="px-0 col-height">
										<img alt="" className="profil-logo" src={signOut}></img>
									</MDBCol>
									<MDBCol middle className="pl-2 pr-2 col-height">
										<span className="profilpage-menu text-capitalize">{t('abmelden')}</span>
									</MDBCol>
								</MDBRow>
							</MDBBtn>
						</MDBNavLink>
					</MDBNavItem>
				</MDBNav>
				<MDBNav className="currentcompany-nav">
					<MDBNavItem>
						<MDBNavLink  id="flag" link className="pt-3" to="#" active={this.props.i18n.language === 'en'} onClick={this.toEnglish} role="tab">
							<Flags.GB style={{borderRadius: "2px", width: "30px"}}/>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink  id="flag" link className="pt-3" to="#" active={this.props.i18n.language === 'de'} onClick={this.toGerman} role="tab">
							<Flags.DE style={{borderRadius: "2px", width: "30px"}}/>
						</MDBNavLink>
					</MDBNavItem>
				</MDBNav>
			</div>
			</MDBCard>
			<div style={{width: "264px", marginLeft: "-5px"}}>
			<Footer/>
			</div>
			</div>
		</>
    );
  }
}

export default  withTranslation()(ProfilpageNav);
