import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Moment from 'moment';
import Flags from 'country-flag-icons/react/3x2'
import Interb2bNewsItems from '../../../../components/News/Interb2bNewsItems';
import MenuSideNavB2BikeMain from './MenuSideNavB2BikeMain';
import ChatsMainBikeB2B from '../../../../components/ChatBikeB2B/ChatsMainBikeB2B';
import AdvertBoxMain from '../../../../components/Adverts/AdvertBoxMain';
import AdvertBoxMobile from '../../../../components/Adverts/AdvertBoxMobile';
import Partners from '../../../../components/Adverts/Partners';
import FeedbackCardCompany from '../../../../components/RatingPage/FeedbackCardCompany';
import FooterB2BMainPage from '../../../../components/Footer/FooterB2BMainPage';
import SonstigeNavB2B from '../../../../components/NavbarComponentB2B/SonstigeNavB2B';
import InterB2BAxios from '../../../../Service/InterB2BAxios';
import ReactFullpage from '@fullpage/react-fullpage';
import { Scrollbar } from "react-scrollbars-custom";
import { Redirect, Link } from 'react-router-dom';
import LandingPageB2BikeNavRespo2 from '../../../../components/NavbarComponentB2B/LandingPageB2BikeNavRespo2';
import logo from "../../../../assets/media/logo/1.png";
import ratingicon from "../../../../assets/media/newicons/rating.png";
import styles from '../../../../assets/scss/modules/landingB2b.module.scss';
import searchnormalicon from "../../../../assets/media/images/markt/searchnormalicon.png";
import group_21 from "../../../../assets/media/images/markt/Group 21.png";
import addBike from "../../../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import logo2 from "../../../../assets/media/images/b2b/Group 19.png";
import anmelden from "../../../../assets/media/images/markt/anmelden.png";
import signinmarkt from "../../../../assets/media/images/markt/signinmarkt.png";
import signupmarkt from "../../../../assets/media/images/markt/signupmarkt.png";
import logo3 from "../../../../assets/media/images/b2b/news.png";
import logo5 from "../../../../assets/media/images/b2b/b2b-contact.png";
import meinprofilicon from '../../../../assets/media/newicons/meinprofil.png';
import clearOffericon from "../../../../assets/media/newicons/clearOffer.png";
import arrowLeft from "../../../../assets/media/images/arrowLeft.png";
import appentum from "../../../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../../../assets/media/images/b2b/Interb2b.png";
import e_Bikeborse from "../../../../assets/media/images/b2b/E-Bikebörse.png";
import profile_Boda from "../../../../assets/media/images/b2b/profile_Boda.jpg";
import rectangle_2 from "../../../../assets/media/images/b2b/Rectangle-1.png";
import hamburgericon from "../../../../assets/media/images/b2b/landingrespo/hamburger.png";
import animation from "../../../../assets/media/videos/animation.mp4";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardTitle,
	MDBCardBody,
	MDBCardFooter,
	MDBScrollbar,
	MDBIcon,
	MDBTabPane,
	MDBTabContent,
	MDBNav,
	MDBNavItem,
	MDBNavLink,
	MDBBtn,
	MDBMedia,
	MDBView,
	MDBCollapse,
	MDBInput,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle,
	MDBAutocomplete,
	MDBSideNav
} from 'mdbreact';
const Parser = require('rss-parser');
const parser = new Parser();
let urlName = '';
class MainPageHandlerResponziv extends Component {

  constructor(props) {
    super(props);
		this.listInnerRefInterB2B = React.createRef();
		this.listInnerRefNews = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'MainPageHandler');
    this.state = {werbungStatus: false,
									timeStamp: Date.now(),
									news:{},
									timeStamp: Date.now(),
									user: {},
									textSearch: '',
									textFeld: false,
									redirect: false,
									navbarPaddingTop: true,
									};
  };

  componentDidMount() {
		this.onRefInterB2B();
		this.props.userData.onSearchInactive();
		var params2 = window.location.href.split("/");
    urlName = params2[params2.length - 1];
		document.addEventListener("scroll", this.scrollEventListener);
		this.onNews();
  };

	componentWillUnmount() {
		this.interAxios.cancel();
		document.removeEventListener("scroll", this.scrollEventListener);
  }

	toggle = tab => e => {
				this.props.onEnableScrollMain();
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
					if (tab == 2) {
						this.onRefNews();
					}
					if (tab == 1) {
						this.onRefInterB2B();
					}
				}

	}
	onRefNews = () => {
		this.listInnerRefNews.current.scrollIntoView({

 	 });
	}
	onRefInterB2B = () => {
		this.listInnerRefInterB2B.current.scrollIntoView({
	 });

	}

	onNews = () => {
    let self = this;
    this.interAxios.get('/api/rss', function(response)
    {
      let promise = parser.parseString(response.data)

      promise.then((value) => {
        self.setState({news: value.items});
      });
    });
  }

	onTextSearchChange = event => {
     this.setState({ [event.target.name]: event.target.value });
   };

	onSearchProduct = () => {
		this.props.userData.onSearchProductCount(this.state.textSearch);
		this.onTextFalse();
		this.setState({redirect: true});
	}

	onTextTrue = () => {
		this.setState({textFeld: !this.state.textFeld});
	}

	onTextFalse = () => {
		this.setState({textFeld: false, textSearch: ""});
	}

	onKey = (ev) => {
		ev.preventDefault();
		if(this.state.textSearch !== "" ){
			this.onSearchProduct()
		}else{
			 this.onTextTrue();
		}
	}

	scrollEventListener = (event) => {
		const navPadding = window.scrollY < 500 ? true : false;
		const tabscroll = window.scrollY < 670 ? "9" : window.scrollY < 1400 ? "1" : window.scrollY < 2100 ? "2" : window.scrollY < 2770 ? "3" : "4";
		this.setState({ navbarPaddingTop: navPadding,  activeItem: tabscroll});
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
				<div ref={this.listInnerRefInterB2B}/>
				<MDBContainer fluid className="navbarcomponentRespo-nav-container-b2b">
					<div className={styles.bodywrapper5}>
					<div className="side-nav-menu-b2b">
						<MDBSideNav
								right
								triggerOpening={this.props.isSideNavOpen}
								onOverlayClick={this.props.handleSideNavToggle}
								breakWidth={1300}
								className="deep-purple darken-4"
						>
							<MenuSideNavB2BikeMain
									userData={this.props.userData}
									toggle = {this.toggle}
									onMenuClose = {this.props.onEnableScroll}
									onItemClose = {this.props.onEnableScrollMain}
									activeItem = {1}
							/>
						</MDBSideNav>
					</div>
					{this.state.navbarPaddingTop ?
						<MDBRow end className="m-0">
							<MDBBtn flat
											className="hambureger-menu-mainpage"
											type="button"
											onClick= {this.props.handleSideNavToggle}>
											<img className="hambureger-icon" src={hamburgericon}/>
							</MDBBtn>
						</MDBRow>:
						<LandingPageB2BikeNavRespo2  userData={this.props.userData} onMenuOpen = {this.props.handleSideNavToggle} onRefInterB2B={this.onRefInterB2B}/>}
								<div style={{ zIndex: 100}}>
									<video width="100%" controls loop autoPlay muted>
										<source src={animation} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								</div>
								<MDBRow center className="padding-row-logo3 m-0">
									<img alt="" className="bike-parts-logo-main-respo" src={logo2}></img>
								</MDBRow>
								<MDBRow center className="pt-3 m-0">
									<div className="product-search-b2b-respo">
										<form className= ""
													>
											<MDBRow center className="pt-3 m-0">
												<MDBCol top className="pl-3 pr-2">
										      <input
														onKeyPress={(ev) => {
																if (ev.key === "Enter") {
																	this.onKey(ev);
																}
														}}
										        type="text"
										        className="form-control"
										        id="formGroupExampleInput"
														placeholder={t('produkt_eingeben_3')}
														name="textSearch"
														onChange={this.onTextSearchChange}
														value={this.state.textSearch}
										      />
												</MDBCol>
												<MDBCol top size="auto" className="pl-0 pr-3">
													<MDBBtn type="button"
																	className="b2b-btn-respo-main"
																	onClick={this.state.textSearch !== "" ? this.onSearchProduct : this.onTextTrue}
																	><img src={searchnormalicon} className="vector-1"/>
													</MDBBtn>
												</MDBCol>
											</MDBRow>
										</form>
									</div>
								</MDBRow>
								<MDBRow center className="m-0 pt-3">
									{!this.state.textFeld ?
									<span className="bike-respo-main-text">Find the bike parts you need on the biggest
										e-bike supplier network available online.</span>:false}
								</MDBRow>
								{this.props.userData.productname === "" && this.state.textFeld ?
								<MDBContainer className="text-center">
									<MDBRow center className="m-0 pt-3">
										<MDBCol bottom className="text-right pr-0" size="4">
										<img className="arrow-icon-respo"
												 src={arrowLeft}/>
										</MDBCol>
										<MDBCol bottom size="8" className="text-left pl-2" >
										<span className="offer-text" >{t('geben_sie_einen_suchbegriff_in_das_feld_ein')}</span>
										</MDBCol>
									</MDBRow>
								</MDBContainer>:false}
					</div>
				<div ref={this.listInnerRefNews}/>
					<div className={styles.bodywrapper6}>
							<MDBRow center className="m-0">
								<img alt="" className="e-bikeborse-logo3-b2b" src={logo3}></img>
							</MDBRow>
							<Link to="/NewsPageB2B" className="icon-newspage-2">
							{this.state.news.length > 0 ?
							<MDBBtn flat className="allnews-btn">
								<span className="allnews-respo">All news</span>
							</MDBBtn>:false}
							<MDBRow center className="padding-row-logo4 mx-0 p-0">
							<MDBCard className="blog-card-b2b-respo">
							<Scrollbar

									style={{ width: "100%", height: "67vh"}}
									maximalThumbYSize = {100}
									noScrollX = {true}
									noScrollY = {true}
							>
								<MDBRow className="mx-0 p-0">
											{this.state.news.length > 0 ?
											<>
												{this.state.news.filter((item, index) => index < 4).map(
												(o, index) =>
												<MDBCol md="6" size="6" lg="12" className="mb-3 px-2">
																			<Interb2bNewsItems
																			key={index}
																			msg={o}
																			useLink={false}/>
												</MDBCol>
												)}
											</>:false}
								</MDBRow>
							</Scrollbar>
							</MDBCard>
							</MDBRow>
							<div className="gradient3"/>
							</Link>
					</div>
				</MDBContainer>
			{this.state.redirect ? <Redirect to={{pathname: "/ProductPage", state: { textFeld: this.state.textFeld}}}/>:false}

	 </>
    );
  }
}

export default withTranslation()(MainPageHandlerResponziv);
