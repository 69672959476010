import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_first from "../../../assets/media/images/markt/profil/Group 7.png";
import logo2 from "../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../../assets/media/images/markt/profil/Group 2.png";
import InterB2BAxios from '../../../Service/InterB2BAxios';
import FooterMarktLanding from '../../Footer/FooterMarktLanding';
import SonstigeNav from '../../NavbarComponent/SonstigeNav';
import ProfilpageNavRespo from '../../NavbarComponent/ProfilpageNavRespo';
import BoersePageNavRespo from '../../NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from '../../../pages/markt/BoersePage/MenuSideNavBoerse';
import BikeAnzeigeKarteEintraege from '../../Companys/BikeAnzeigeKarteEintraege';
import styles from '../../../assets/scss/modules/profilpage.module.scss';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
  MDBBtn,
	MDBSideNav
} from 'mdbreact';

let language = '';
let urlName = '';
class EintraegeResponziv extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefStart = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'Eintraege');
    this.state = {bikeslist: {}
									};
  };

	componentDidMount() {
		document.addEventListener("scroll", this.scrollEventListener);
		this.props.addFunctionFromChild(this.onMessage);
		var params = window.location.href.split(".");
	  language = params[params.length - 1];
		var params2 = window.location.href.split("/");
		urlName = params2[params2.length - 1];
		this.onGetBikesWithUserId();
		this.onRefStart();
	}

	componentWillUnmount() {
		document.removeEventListener("scroll", this.scrollEventListener);
		this.props.removeFunctionFromChild(this.onMessage);
		this.interAxios.cancel();
	}

	scrollEventListener = (event) => {
		const navPadding = window.scrollY < 50 ? true : false;
		this.setState({ navbarPaddingTop: navPadding});
	}

	onRefStart = () => {
		this.listInnerRefStart.current.scrollIntoView({
	 });
	}

	onMessage = (message) => {
    let type = message.type;
		if (type === 'NEWEBIKEPRODUCT'){
	    this.onGetBikesWithUserId();
	  }
  };

	onGetBikesWithUserId = () => {
		let self = this;
		this.interAxios.get('/api/allBikeProductWithUserId/', function(response) {
			self.setState({bikeslist: response.data});
		})
	};

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<div ref={this.listInnerRefStart}></div>
			<BoersePageNavRespo userData={this.props.userData}
													onMenuOpen = {this.props.handleSideNavToggle}
													isUserMenu={true}
													activeItem = {"1"}/>
				<div className={styles.bodywrapper}>
					<div className="d-lg-none d-block">
						<div className="side-nav-menu">
							<MDBSideNav
								right
								triggerOpening={this.props.isSideNavOpen}
								onOverlayClick={this.props.handleSideNavToggle}
								breakWidth={1300}
								className="deep-purple darken-4"

							>
								<MenuSideNavBoerse
														userData={this.props.userData}
														toggle = {this.toggle}
														onMenuClose = {this.props.onEnableScroll}
														activeItem = {this.state.activeItem}
								/>
							</MDBSideNav>
						</div>
					</div>
				<div className="profil-background">
					<MDBContainer fluid className="px-0">
						<MDBRow className="pt-4 m-0 addbike-row-bottom-2">
							<MDBCol size="12" className="px-1">
							<MDBRow center className="m-0 pt-0 pb-3">
									<img alt="" className="e-bikeborse-logo-first-eigene" src={logo_first}></img>
									<span className="landingpage-text-eigene">{t('eigene_anzeigen')}</span>
							</MDBRow>
								<MDBRow className="m-0 eigene-padding-bottom">
									{this.state.bikeslist?.length > 0 ?
											<>
											{this.state.bikeslist.map(
											(o, index) =>
											<MDBCol md="3" size="6" lg="4" className="mb-0 px-2 py-1" key={index}>
															<BikeAnzeigeKarteEintraege
															 userData={this.props.userData}
															 msg={o}/>
											</MDBCol>
										)}
										 </>:false}
								</MDBRow>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</div>
				</div>
		</>

    );
  }
}

export default  withTranslation()(EintraegeResponziv);
