import React, { Component } from "react";
import MessageDropdownBike from "./Dropdowns/MessageDropdownBike";
import Moment from 'moment';
import { UserStatus } from "../../../Config/Config";
import { Link } from "react-router-dom";
import LightboxPageToMitteilungen from '../../LightboxPage/LightboxPageToMitteilungen';
import InterB2BAxios from '../../../Service/InterB2BAxios';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBInputGroup,
	MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
	MDBListGroup,
	MDBCardFooter,
	MDBScrollbar,
	MDBFileInput,
	MDBIcon,
	MDBBadge
} from 'mdbreact';


// User  chat detail component - chat with users
class MessageItemBike extends Component {
  state = {
      isOpen: false
    };
  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'MessageItemBike');
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.onGetUser(this.props.msg.senderUserId);
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}


	onGetUser = (id) => {
    let self = this;
    this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
      self.setState({senderUser: response.data});
    })
  };

  onIsOpen = () => {
	  this.setState({isOpen: !this.state.isOpen});
	}

  render() {

    var date = Moment(this.props.msg.timestamp);
    let fileName = '';
    if (this.props.msg.documentUrl !== '') {
      let parts = this.props.msg.documentUrl.split('/');
      fileName = parts[parts.length-1];
    }
    var images = [];
		var imagesplus = 0;
		var i=1;
		if (this.props.msg.messagePhotosUrl.length>0){
			for (const messagePhoto of this.props.msg.messagePhotosUrl){
					images.push({	src: messagePhoto });

				i++;
			}
			imagesplus = (i - 2);
		}

	var isMyMessage = this.props.myUserId === this.props.msg.senderUserId;

    return (
<div className={isMyMessage ? "chat-msg chat-msg-self" : "chat-msg"}>
	<MDBRow className={isMyMessage ? "d-flex flex-row-reverse m-0" : "d-flex flex-row m-0"}>
		<MDBCol size="2" md="2" lg="2" xl="1" className="px-0">
			{this.props.msg.senderProfile !== undefined ?
				<div>
				{this.state.senderUser?.companyUser ?
					<img alt="" className="user-photo-navbar" src={this.state.senderUser?.companyProfileUrl +'?' + Date.now()}></img>:<>
						{this.state.senderUser?.profileUrl !== undefined ?
							<img alt="" className="user-photo-navbar" src={this.state.senderUser?.profileUrl +'?' + Date.now()}></img>:
							<div className="userProfileName-icon">{this.state.senderUser?.profilName}</div>}</>}
				</div>:false}
		</MDBCol>
		<MDBCol size="auto" md="auto" lg="auto" xl="auto" className="contentWidth">

			<div className="chat-msg-content">
			{isMyMessage ? '': <MDBRow className="align-items-center px-3"><div className="oval"/>
												 <p className="chat-sender-name pl-1">{this.props.msg.senderName}</p></MDBRow>}
			<div>{this.props.msg.content}</div>
			{this.props.msg.documentUrl !== '' ?
					<><a href={this.props.msg.documentUrl} target="_blank" rel="noopener noreferrer" className="underlined">
						<MDBIcon far icon="file-archive" />
						<span> {fileName}</span>
						</a>
					</>:false}

			{images.length>0 ?
					<div style={{width: "6rem"}} className="p-0 center-left">
										<a href="#!"  onClick={this.onIsOpen}>
										<img
											className="img-fluid photos-content"
											src={images[0].src}
											alt=""

											/>
										</a>



											<LightboxPageToMitteilungen
															images={images}
															isOpen={this.state.isOpen}
															onIsOpen={this.onIsOpen}/>

					{imagesplus > 0 ?
						<h6 style={{position: 'relative', left: '15px', transform: 'translateY(-100%)', maxWidth: "2.5rem", marginBottom: "-10px", backgroundColor:"#a6a6ae"}}>
							+ {imagesplus} <i className="far fa-image"></i>
						</h6>:false}
						<a className="text-right" href={this.props.msg.messagePhotosDownloadUrl} target="_blank" rel="noopener noreferrer">
						<MDBIcon icon="download" />
						</a>
					</div>:false}
			</div>
		</MDBCol>
		<MDBCol size="auto" md="auto" className="text-center">
		{isMyMessage ?
		<MDBRow end>
			<span className="chat-time">{date.format('h:mma')}</span>
		</MDBRow>:
		<MDBRow start>
			<span className="chat-time">{date.format('h:mma')}</span>
		</MDBRow>}
		<MDBRow className="pt-1">
			<MessageDropdownBike
					drop = {this.props.drop}
					messageId = {this.props.msg.id}
					revoke = {this.props.revoke}
					{...this.props} />
		</MDBRow>
		</MDBCol>
	</MDBRow>
    </div>
	);

    }
  }


export default MessageItemBike;
