import React from 'react';
import Cookies from 'universal-cookie';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import chaticon from "../../assets/media/newicons/messages-2.png";
import emailicon from "../../assets/media/newicons/sms-edit.png";
import arrowicon from "../../assets/media/newicons/arrow-right.png";

const cookies = new Cookies();

import{
	MDBContainer,
	MDBScrollbar,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
  MDBAvatar,
  MDBIcon,
  MDBBtn,
  MDBCardUp,
  MDBTooltip
} from 'mdbreact';
import { Link } from "react-router-dom";


class IhrNetzwerkPublic extends React.Component {
		constructor(props) {
    super(props);
  };

	componentDidMount() {

  };

  truncateString = (str, num) => {
		if (str.length <= num) {
			return str
  		}
  			return str.slice(0, num) + '...'
	}

  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
<>
<MDBCol size="12" sm="12" md="12" lg="6">
	<MDBCard  className="interb2b-card">
		<MDBCardBody>
			<MDBRow className="pt-5 pb-4">
				<MDBCol className="text-center">
					<MDBAvatar
						style={{width: "80px", height: "80px"}}
						tag="img"
						src={this.props.msg.profileUrl}
						alt="Sample avatar"
					/>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol className="text-center">
					{this.props.msg.name.length > 15 ?

						<MDBTooltip
							domElement
							style={{ display: "inline-block" }}
							tag="span"
							material
							placement="top"
						>
							<p className="companiespublic-cardtitle">{this.truncateString(this.props.msg.name, 15)}</p>
							<small className="font-weight-bold text-capitalize">{this.props.msg.name}</small>
						</MDBTooltip>:
						<p className="companiespublic-cardtitle">{this.props.msg.name}</p>}

				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol className="text-center">
					<p className="companiespublic-cardtext">
						{this.props.msg.postcode} {this.props.msg.town}<br/>
						{this.props.msg.street}
					</p>
				</MDBCol>
			</MDBRow>
			<MDBRow className="pb-4 pt-2">
				<MDBCol className="text-center">
					<Link
								className="netzwerk-contacts-link gradient"
								to={"/CompaniesPageProfile/" + this.props.msg.urlName}
					>
						<img src={arrowicon} />
					</Link>
				</MDBCol>
			</MDBRow>
		</MDBCardBody>
	</MDBCard>
</MDBCol>
</>
    );
  }
}

export default withTranslation()(IhrNetzwerkPublic);
