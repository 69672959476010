import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import Moment from 'moment';
import 'moment/locale/de';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_first from "../../assets/media/images/markt/profil/Group 7.png";
import logo3 from "../../assets/media/images/markt/profil/Group 1.png";
import CreateImageProfile from '../Modals/CreateImageProfile'
import InterB2BAxios from '../../Service/InterB2BAxios';
import FooterMarktLanding from '../Footer/FooterMarktLanding';
import SonstigeNav from '../NavbarComponent/SonstigeNav';
import ProfilpageNavRespo from '../NavbarComponent/ProfilpageNavRespo';
import BoersePageNavRespo from '../NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from '../../pages/markt/BoersePage/MenuSideNavBoerse';
import ProfilpageNav from '../NavbarComponent/ProfilpageNav';
import GoogleMaps5 from '../GoogleMaps/GoogleMaps5';
import styles from '../../assets/scss/modules/profilpage.module.scss';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
const myregexp = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.*([^\s]{11})/gm;
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBIcon,
	MDBDatePicker,
	MDBSideNav
} from 'mdbreact';

const containerStyle = {
  width: '100%',
  height: '280px'
};
let language = '';
let urlName = '';
class ProfilInfo extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefStart = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'ProfilInfo');
    this.state = {redirect: false,
									vorname: "",
									nachname: "",
									anzeigename: "",
									benutzername: "",
									standort: "",
									privatetelefon: "",
									publictelefon: "",
									aboutus: "",
									birthday: Date.now(),
									myUser: {},
								  latitude: -200.0,
									longitude: -200.0,
								  standort: this.props.standort ?? '',
									isGoogleMap: false
									};
  };

	componentDidMount() {
		document.addEventListener("scroll", this.scrollEventListener);
		var params = window.location.href.split(".");
	  language = params[params.length - 1];
		var params2 = window.location.href.split("/");
		urlName = params2[params2.length - 1];
		this.onRefStart();
		this.onMyUser();
	}

	componentWillUnmount() {
		this.interAxios.cancel();
		document.removeEventListener("scroll", this.scrollEventListener);
	}

	scrollEventListener = (event) => {
		const navPadding = window.scrollY < 50 ? true : false;
		this.setState({ navbarPaddingTop: navPadding});
	}

	onRefStart = () => {
		this.listInnerRefStart.current.scrollIntoView({
	 });
	}

	onMyUser = () => {
		var self = this;
		this.interAxios.get('/api/user/me', function(response) {
      self.setState({myUser: response.data,
										 vorname: response.data.vorname ?? "",
										 nachname: response.data.nachname ?? "",
										 anzeigename: response.data.anzeigename ?? "",
										 benutzername: response.data.username ?? "",
										 standort: response.data.standort ?? "",
										 latitude: response.data.lat ?? self.state.latitude,
										 longitude: response.data.lng ?? self.state.longitude,
										 privatetelefon: response.data.privatetelefon ?? "",
										 publictelefon: response.data.publictelefon ?? "",
										 aboutus: response.data.aboutUs ?? "",
										 birthday: response.data.birthday ?? Date.now(),
									 });
    });
	};



	changeHandler = event => {
     this.setState({ [event.target.name]: event.target.value });
   };

	 onUserUpdate = (event) => {
		 const form = event.currentTarget;
		 event.preventDefault();
		 event.target.className += " was-validated"
		 if((form.checkValidity() !== false)){
			 let self = this;
			 const formData = new FormData
			 formData.append("name", this.state.vorname + " " + this.state.nachname);
			 formData.append("anzeigename", this.state.anzeigename);
			 formData.append("benutzername", this.state.benutzername);
			 formData.append("standort", this.state.standort);
			 formData.append("latitude", this.state.latitude);
			 formData.append("longitude", this.state.longitude);
			 formData.append("privatetelefon", this.state.privatetelefon);
			 formData.append("publictelefon", this.state.publictelefon);
			 formData.append("aboutus", this.state.aboutus);
			 formData.append("birthday", Moment(this.state.birthday).format('YYYY-MM-DD'));
			 this.interAxios.put('/api/auth/userupdateMarkt', formData, {
				 headers: {
					 'Content-Type': 'multipart/form-data'
				 }
			 }, function(response) {
				 	self.setState({redirect: true});
			 })
		 }
	 };

	 onUpdateLatLng = () => {
 		this.interAxios.get('/api/updatelatlong/' + 50, function(response) {
 		})
 	};

	handleShowProfile = () => {
    this.setState({showProfile: true,
                   minWidth: 200,
                   width: 200,
                   maxWidth: 200,
                   aspect: 1,
									 aboutus: "",
                   bildName: 'Profile'});
  };
	handleCloseProfile = () => {
    this.setState({showProfile: false});
		//self.props.userData.refresh();
  };

	getPickerValue = (value) => {
    this.setState({birthday: value});
  };

	onArticleChange = (editorState) => {
		let text = '';
		if (editorState.match(myregexp)){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(editorState.match(myregexp), 'text/html');
			text =parsedHtml.documentElement.textContent;

		}
		this.setState({aboutus: editorState.replace(editorState.match(myregexp), '')});

	}

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<div ref={this.listInnerRefStart}></div>
			<div className="d-none d-lg-block">
				<SonstigeNav  userData={this.props.userData}/>
			</div>
			<div className="d-lg-none d-block">
			<BoersePageNavRespo userData={this.props.userData}
													onMenuOpen = {this.props.handleSideNavToggle}
													isUserMenu={true}
													activeItem = {"4"}
													isOpen = {this.state.isButton}/>
			</div>
				<div className={styles.bodywrapper}>
					<div className="d-lg-none d-block">
						<div className="side-nav-menu">
							<MDBSideNav
								right
								triggerOpening={this.props.isSideNavOpen}
								onOverlayClick={this.props.handleSideNavToggle}
								breakWidth={1300}
								className="deep-purple darken-4"

							>
								<MenuSideNavBoerse
														userData={this.props.userData}
														toggle = {this.toggle}
														onMenuClose = {this.props.onEnableScroll}
														activeItem = {this.state.activeItem}
								/>
							</MDBSideNav>
						</div>
					</div>
					<div className="profil-background">
						<MDBContainer style={{maxWidth: "1250px"}}>
							<MDBRow className="pt-4 addbike-row-bottom-2">
							<div className="d-none d-lg-block">
							<MDBCol md="auto" className="pr-4">
								<ProfilpageNav activeItem = {"4"} userData={this.props.userData}/>
							</MDBCol>
							</div>
								<MDBCol className="px-2">
								<div className="d-none d-lg-block">
								<MDBRow start className="m-0 pt-0 pb-3">
										<img alt="" className="e-bikeborse-logo-first-eigene" src={logo_first}></img>
										<span className="landingpage-text-eigene">{t('profil_info')}</span>
								</MDBRow>
								</div>
								<div className="d-block d-lg-none">
								<MDBRow center className="m-0 pt-0 pb-3">
										<img alt="" className="e-bikeborse-logo-first-eigene" src={logo_first}></img>
										<span className="landingpage-text-eigene">{t('profil_info')}</span>
								</MDBRow>
								</div>
									<p className="px-2">{t('Ein_Teil_dieser_Informationen')}<Link to={"/ProfilPage/" + this.state.myUser.id}>{t('auf_deiner_Profilseite')}</Link></p>
									<form className="row g-3 needs-validation m-0 px-0" onSubmit={async (event) => {await this.onUserUpdate(event);}} noValidate>
										<div className="col-md-12">
											<div className="form-outline-markt">
													<label htmlFor="validationCustom01" className="form-label pt-2">{t('vorname')}</label>
													<input type="text" className="form-control text-muted" name="vorname" onChange={this.changeHandler} value={this.state.vorname} id="validationCustom01"/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-outline-markt">
													<label htmlFor="validationCustom02" className="form-label pt-2">{t('nachname')}</label>
													<MDBRow className="m-0 p-0">
														<MDBCol md="auto" size="auto" className="pl-0 pr-1">
															<MDBIcon icon="info-circle" />
														</MDBCol>
														<MDBCol className="pl-1 pr-0">
															<p className="form-anmerkung">{t('anderen_Benutzern_wird')}</p>
														</MDBCol>
													</MDBRow>
													<input type="text" className="form-control text-muted" name="nachname" onChange={this.changeHandler} value={this.state.nachname} id="validationCustom02"/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-outline-markt">
											<label htmlFor="validationCustom03" className="form-label pt-2">{t('Anzeigename')}</label><br/>
											<MDBRow className="m-0 p-0">
												<MDBCol md="auto" size="auto" className="pl-0 pr-1">
													<MDBIcon icon="info-circle" />
												</MDBCol>
												<MDBCol className="pl-1 pr-0">
													<p className="form-anmerkung">{t('Wenn_du_eine_Organisation_vertrittst')}</p>
												</MDBCol>
											</MDBRow>
													<input type="text" className="form-control text-muted" name="anzeigename" onChange={this.changeHandler} value={this.state.anzeigename} id="validationCustom03"/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-outline-markt">
											<label htmlFor="validationCustom04" className="form-label pt-2">{t('benutzername')}</label><br/>
											<MDBRow className="m-0 p-0">
												<MDBCol md="auto" size="auto" className="pl-0 pr-1">
													<MDBIcon icon="info-circle" />
												</MDBCol>
												<MDBCol className="pl-1 pr-0">
													<p className="form-anmerkung">{t('Dein_Benutzername_wird_als_Teil')}</p>
												</MDBCol>
											</MDBRow>
													<input type="text" className="form-control text-muted" name="benutzername" onChange={this.changeHandler} value={this.state.benutzername} id="validationCustom04"/>
											</div>
										</div>
										<div ref={this.listInnerRefGoogle}/>
										<div className="col-md-12">
											<div className="form-outline-markt">
											<label htmlFor="validationCustom05" className="form-label pt-2">{t('Standort')}</label><br/>
											<MDBRow className="m-0 p-0">
												<MDBCol md="auto" size="auto" className="pl-0 pr-1">
													<MDBIcon icon="info-circle" />
												</MDBCol>
												<MDBCol className="pl-1 pr-0">
													<p className="form-anmerkung">{t('Wird_nur_beim_Erstellen_eines_neuen')} </p>
												</MDBCol>
											</MDBRow>
													<input type="text" className="form-control text-muted" name="standort" onChange={this.changeHandler} value={this.state.standort} id="validationCustom05"/>
											</div>
										</div>
										{/*<div className="col-md-6">
											<div className="form-outline-markt">
													<input type="text" className="form-control text-muted" name="latitude" onChange={this.changeHandler} value={this.state.latitude} id="validationCustom06"/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-outline-markt">
													<input type="text" className="form-control text-muted" name="longitude" onChange={this.changeHandler} value={this.state.longitude} id="validationCustom07"/>
											</div>
										</div>*/}
										<div className="col-md-12 pt-4 pb-3">
												<div className="profile-mapholder">
												<GoogleMaps5  latitude={this.state.latitude !== -200.0 ? this.state.latitude : this.props.userData.getLatLng().originalLatitude}
																			longitude={this.state.longitude !== -200.0 ? this.state.longitude : this.props.userData.getLatLng().originalLongitude}
																			isBikeMap={true}
																			userData = {this.props.userData}
																			withMap = {"100%"}
																			heightMap = {"250px"}
																			/>
												</div>
										</div>
										<div className="col-md-12">
											<div className="form-outline-markt">
											<label htmlFor="validationCustom08" className="form-label pt-2">{('telefonnummer')}</label><br/>
											<MDBRow className="m-0 p-0">
												<MDBCol md="auto" size="auto" className="pl-0 pr-1">
													<MDBIcon icon="info-circle" />
												</MDBCol>
												<MDBCol className="pl-1 pr-0">
													<p className="form-anmerkung">Wird anderen Benutzern nicht angezeigt.</p>
												</MDBCol>
											</MDBRow>
													<input type="text" className="form-control text-muted" name="privatetelefon" onChange={this.changeHandler} value={this.state.privatetelefon} id="validationCustom08"/>
											</div>
										</div>
										<div className="col-md-12">
										<label htmlFor="validationCustom09" className="form-label pt-2">Profilbild</label><br/>
										<MDBRow className="m-0 p-0">
											<MDBCol md="auto" size="auto" className="pl-0 pr-1">
												<MDBIcon icon="info-circle" />
											</MDBCol>
											<MDBCol className="pl-1 pr-0">
												<p className="form-anmerkung">Das Bild sollte ein quadratisches Seitenverhältnis haben (1:1),
												zum Beispiel 800x800 Pixel. Ansonsten wird es beschnitten. </p>
											</MDBCol>
										</MDBRow>
										{this.state.myUser.id!== undefined ?
											<img className="profilpage-image"
												src={this.state.myUser.profileUrl +'?' + Date.now()}
												onClick={this.handleShowProfile}
											/>:false}
										</div>


										<div className="col-md-12">
											<div className="form-outline-markt pt-2">
												<label htmlFor="validationCustom10" className="form-label pt-2">Über dich</label><br/>
												<div className="ckeditor-padding">
												<CKEditor
						            	editor={ ClassicEditor }
													data={this.state.aboutus}
													config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
						            	onChange={
														( event, editor ) => {
						            			const data = editor.getData();
						            			this.onArticleChange(data);
						            		}
													}
						            />
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-outline-markt pt-2">
											<label htmlFor="validationCustom11" className="form-label pt-2">Geburtsdatum*</label><br/>
												<MDBDatePicker animateYearScrolling={true}
																			 cancelLabel="Cancel"
																			 locale={Moment.locale('de')}
																			 getValue={this.getPickerValue}
																			 className="picker-format"
																			 theme={{
																				 borderRadius: "35px",
																			  palette: {
																			    primary: {
																			      main: '#00A441',

																			    },

																			    secondary: {
																			      main: '#FF8800',
																			      contrastText: '#ffcc00',
																			    },
																			  },
																			  typography: {
																			    useNextVariants: true,
  																			}
																			}} />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-outline-markt">
											<label htmlFor="validationCustom12" className="form-label pt-2">Telefonnummer veröffentlicht:</label><br/>
											<MDBRow className="m-0 p-0">
												<MDBCol md="auto" size="auto" className="pl-0 pr-1">
													<MDBIcon icon="info-circle" />
												</MDBCol>
												<MDBCol className="pl-1 pr-0">
													<p className="form-anmerkung">Wird im öffentlichen Profil angezeigt.</p>
												</MDBCol>
											</MDBRow>
													<input type="text" className="form-control text-muted" name="publictelefon" onChange={this.changeHandler} value={this.state.publictelefon} id="validationCustom11"/>
											</div>
										</div>

										<div className="col-12">
											<MDBRow center className="profilpage-bottom">
												<button className="btn btn-done-profil" type="submit">{t('formular_senden')}</button>
											</MDBRow>
										</div>
										{ this.state.redirect === true ?
											<Redirect to="/LandingPageMarkt"/>:
										false
										}
									</form>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</div>
				</div>

				{this.state.showProfile ?
				<CreateImageProfile
				show= {this.state.showProfile}
				handleClose = {this.handleCloseProfile}
				currentCompany={this.state.myUser}
				minWidth = {this.state.minWidth}
				width = {this.state.width}
				maxWidth = {this.state.maxWidth}
				aspect = {this.state.aspect}
				bildName = {this.state.bildName}
				/>: false}
				{ this.props.userData.boersepage ?
					 <Redirect to={{
										 pathname: "/BoersePage",
										 state: { activeItem: this.state.activeItemKategorie, bikeKategorie: this.state.bikeKategorie }
					 }}
				 />:
				 false}
		</>

    );
  }
}

export default  withTranslation()(ProfilInfo);
