import React, { Component } from "react";

import { Link } from "react-router-dom";

import { ReactComponent as HorizontalOptionDotsSvg } from "../../../../assets/media/icons/horizontaloptiondots.svg";
import { ReactComponent as CopySvg } from "../../../../assets/media/icons/copy.svg";
import { ReactComponent as ReplySvg } from "../../../../assets/media/icons/reply.svg";
import { ReactComponent as ForwardSvg } from "../../../../assets/media/icons/forward.svg";
import { ReactComponent as FavouriteSvg } from "../../../../assets/media/icons/favourite.svg";
import { ReactComponent as DeleteSvg } from "../../../../assets/media/icons/delete.svg";
import groupVerticalGray from "../../../../assets/media/newicons/groupVerticalGray.png";
import groupHorizontalBlue from "../../../../assets/media/newicons/groupHorizontalBlue.png";
import trashRed from "../../../../assets/media/newicons/trashRed.png";
import close from "../../../../assets/media/newicons/close.png";
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBInputGroup,
	MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
	MDBListGroup
} from 'mdbreact';
import Cookies from 'universal-cookie';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
const cookies = new Cookies();

class MessageDropdownBikeB2B  extends Component {
  state = {
    token : cookies.get('authtoken').token,
    myUserId: cookies.get('authtoken').userId,

  };

  constructor(props) {
    super(props);
  };


	render() {
		const { t } = this.props;
    const client = this.props.client;
  return (
		<>
		<div className="dropdown-menu-chat-b2b">
		{this.props.drop === "left" ?
		<MDBDropdown>
		<MDBDropdownToggle className="btn-flat px-0 py-0 my-0 text-left" color="transparent"><i style={{width: "45%"}} className="icon-default"></i></MDBDropdownToggle>

			<MDBDropdownMenu className="dropdown-menu dropdown-menu-chat-left">
				<Link className="align-items-center" to="#" onClick={(event) => this.props.deleteMySide(this.props.messageId)}>
					<MDBRow className="m-0">
					<MDBCol middle className="text-center px-0" size="3" md="3">
					<img style={{width: "1.2rem"}} src={trashRed}/>
					</MDBCol>
					<MDBCol middle className="px-0" size="9"  md="9">
					<span>{t('für_mich_löschen')}</span>
					</MDBCol>
					</MDBRow>
				</Link>
			</MDBDropdownMenu>
		</MDBDropdown>:
		<MDBDropdown>
		<MDBDropdownToggle className="btn-flat px-0 py-0 my-0 text-right" color="transparent"><i style={{width: "45%"}} className="icon-default"></i></MDBDropdownToggle>

			<MDBDropdownMenu className="dropdown-menu-chat-right">
			<MDBRow className="m-0">
				<Link className="align-items-center" to="#" onClick={(event) => this.props.deleteMySide(this.props.messageId)}>
					<MDBRow className="m-0">
					<MDBCol middle className="text-center px-0" size="3" md="3">
					<img style={{width: "1.2rem"}} src={trashRed}/>
					</MDBCol>
					<MDBCol middle className="px-0" size="9"  md="9">
					<span>{t('für_mich_löschen')}</span>
					</MDBCol>
					</MDBRow>
				</Link>
			</MDBRow>
			<MDBRow className="m-0">
				<Link className="align-items-center" to="#" onClick={(event) => this.props.revoke(this.props.messageId)}>
					<MDBRow className="m-0">
					<MDBCol middle className="text-center px-0" size="3" md="3">
					<img style={{width: ".9rem"}} src={close}/>
					</MDBCol>
					<MDBCol middle className="px-0" size="9"  md="9">
					<span>{t('für_alle_löschen')}</span>
					</MDBCol>
					</MDBRow>
				</Link>
			</MDBRow>
			</MDBDropdownMenu>

		</MDBDropdown>}
		</div>
		</>
  );
}
}
export default withTranslation()(MessageDropdownBikeB2B);
