import React, { Component } from "react";

import logo from "../../assets/media/logo/1.png";
import{
	MDBRow,
	MDBCol,
	MDBCard,
} from 'mdbreact';

class FooterHomePage extends Component {

  constructor(props) {
    super(props);
  };


  render() {

    return (
			<MDBCard className="transparent-card pt-4">
				<MDBRow className="footer-menu d-flex justify-content-between">
					<MDBCol className="text-center py-1">
							<a href="/About">Über Uns</a>
					</MDBCol>
					<MDBCol className="text-center py-1">
							<a href="/Impressum">Impressum</a>
					</MDBCol>
					<MDBCol className="text-center py-1">
							<a href="/PrivacyPolicy">Datenschutz</a>
					</MDBCol>
					<MDBCol className="text-center py-1">
							<a href="/UserAgreement">Nutzungsbedingungen</a>
					</MDBCol>
					<MDBCol className="text-center py-1">
							<a href="/CookiePolicy">Cookie-Richtlinie</a>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="text-center py-2">
						<img alt="" className="copyright-logo" src={logo}  style={{width: "35px", height: "35px"}}></img>
						<span style={{fontSize: "10px"}} className="px-3">IT-Boda&nbsp;&copy;&nbsp;2021</span>
					</MDBCol>
				</MDBRow>
			</MDBCard>
    );
  }
}

export default FooterHomePage;
