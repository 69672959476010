import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import styles from '../../../assets/scss/modules/profilpage.module.scss';
import SonstigeNavB2B from '../../../components/NavbarComponentB2B/SonstigeNavB2B';
import ProfilpageNavB2B from '../../../components/NavbarComponentB2B/ProfilpageNavB2B';
import MenuSideNavB2BikeMain from './MainPageHandler/MenuSideNavB2BikeMain';
import LandingPageB2BikeNavRespoUser from '../../../components/NavbarComponentB2B/LandingPageB2BikeNavRespoUser';
import SonstigeNavB2BHersteller from '../../../components/NavbarComponentB2B/SonstigeNavB2BHersteller';
import ProfilpageNavB2BHersteller from '../../../components/NavbarComponentB2B/ProfilpageNavB2BHersteller';
import TablePage from '../../../components/NewCompany/TablePage';
import CreateOrderDTO from '../../../components/NewCompany/CreateOrderDTO';
import Cookies from 'universal-cookie';
import ib2bicon from "../../../assets/media/images/b2b/ib2b_b2b.png";
import Moment from 'moment';
import 'moment/locale/de';
const cookies = new Cookies();


import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
	MDBInput,
	MDBBtn,
	MDBCardTitle,
	MDBNav,
	MDBNavItem,
	MDBNavLink,
	MDBTabContent,
	MDBTabPane,
	MDBSideNav,
	MDBSwitch
} from 'mdbreact';

import InterB2BAxios from '../../../Service/InterB2BAxios';

class NewCompanyB2B extends Component {

  constructor(props) {
    super(props);
		this.formRef = React.createRef();
		this.interAxios = new InterB2BAxios(this);
		let self = this;
		this.interAxios.get('/api/preis/paketpreis', function(response) {
			self.setState({
				preisList: response.data
			});
			for (const packet of response.data){
				if(packet.packet === "premiumMonatlich"){
					self.setState({
						premiumMonatlich: packet.preis
					});
				}
				if(packet.packet === "premiumJahrlich"){
					self.setState({
						premiumJahrlich: packet.preis
					});
				}
			}

		});
		this.interAxios.get('/api/user/me', function(response) {
			self.setState({
				systemAdmin: response.data.systemAdmin,
				testMode: response.data.systemAdmin
			});
		});

    this.state = {acquireCompanyWithSameEmail:false,
									companyWithSameEmail: {},
                  activeItem: "1",
                  orderStatus: false,
									payPage: false,
									status: false,
									alert: false,
									cardStatusLeft: true,
									cardStatusRight: false,
								  package: ""};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		//this.props.userData.refreshNavbar();
		if(this.props.userData.getAcquireCompanyWithSameEmail()){
			this.onCompanyWithSameEmail();
		}
	};

	componentWillUnmount() {
		this.interAxios.cancel();
  }

	handleChange = () => {
		this.setState({status: true})
	}

	handleClose = () => {
		this.setState({orderStatus: false})
	}

	handlePayPage = (name, lname, fname, street, postcode, town, country, ustidnr) => {
		this.setState({name: name,
									 lname: lname,
									 fname: fname,
									 street: street,
									 postcode: postcode,
									 town: town,
									 country: country,
									 ustidnr: ustidnr,
									 payPage: true});

	}


	toggle = tab => e => {
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab,
			});
				}
	}

	onActiveCardLeft = event => {
	 if(!this.state.cardStatusLeft){
		 this.setState({cardStatusLeft: true, cardStatusRight: false})
	 }
	}

	onActiveCardRight = event => {
	 if(!this.state.cardStatusRight){
		 this.setState({cardStatusRight: true, cardStatusLeft: false})
	 }
	}

	handleTestModeChange = () => {
	 this.setState({testMode: !this.state.testMode});
	}

	onCompanyWithSameEmail = () => {
	 var self = this;
	 this.interAxios.get('/api/companyWithSameEmail/' + this.props.userData.getUseremail(), function(response)
	 {
				 self.setState({companyWithSameEmail: response.data});
	 });
	};

	onCompanyConnection = () => {
	 this.setState({acquireCompanyWithSameEmail: !this.state.acquireCompanyWithSameEmail});
	}

	submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

	datenBlatt = event => {
		event.preventDefault();
    event.target.className += " was-validated";
		if(event.target.checkValidity()){
			this.setState({ package: "pm",
												calculatedPrice: this.state.premiumMonatlich,
												orderStatus: true	})
		}
		/*this.setState({ package: "pm",
											calculatedPrice: this.state.premiumMonatlich,
											orderStatus: true	})*/
	}


  render() {
		const { t } = this.props;
		const client = this.props.client;
		var date = new Date();
		date.setMonth(date.getMonth() + 3);
		let labelText = <div className="price-page-haufig-text" >
											{t('ich_stimme_zu')}
											<a className="link-text" id="1" href="/UserAgreement" target="_blank">{t('termsOfUse')}</a>
											{t('und_die')}<a className="link-text" id="1" href="/PrivacyPolicy" target="_blank">
											{t('privacyPolicy')}</a>{t('gelesen_und_bin_damit_einverstanden')}</div>
    return (
			<>
			<div className="d-none d-lg-block">
			{this.props.userData.partner() === "Dealer" ?
			<SonstigeNavB2B userData={this.props.userData}/>:false}
			{this.props.userData.partner() === "Supplier" ?
			<SonstigeNavB2BHersteller userData={this.props.userData}/>:false}
			<div className={styles.bodywrapper}>
				<div className="profil-background">
					<MDBContainer style={{maxWidth: "1250px"}}>
						<MDBRow className="pt-4 m-0">
							<MDBCol md="auto" className="pr-4">
								{this.props.userData.partner() === "Dealer" ?
								<ProfilpageNavB2B activeItem = {"1"} userData={this.props.userData}/>:false}
								{this.props.userData.partner() === "Supplier" ?
								<ProfilpageNavB2BHersteller activeItem = {"1"} userData={this.props.userData}/>:false}
							</MDBCol>
							<MDBCol className="px-5">
								<MDBRow between>
									<MDBCol md="6">
										<h1 className="price-page-title">{t('preis')}</h1>
										{this.props.userData.getAcquireCompanyWithSameEmail() ?<>
											<p className="company_assign">{t('die_emailadresse_des_unternehmens_namens')}<Link className="contacts-link" to={"/CompaniesPage/" + this.state.companyWithSameEmail.id}>{this.state.companyWithSameEmail.name}</Link>{t('in_der_datenbank_entspricht_ihrer_emailadresse')}</p>
											{!this.state.acquireCompanyWithSameEmail ?
											<p className="company_assign">{t('um_dieses_unternehmen_ihrem_konto_zuzuordnen_klicken_sie')}<a className="company_enable_hier" onClick={this.onCompanyConnection}>{t('hier')}</a>!</p>:
											<p className="company_assign">{t('sie_verknüpfen_dieses_unternehmen_mit_ihrem_konto')}<a className="company_enable_hier" onClick={this.onCompanyConnection}>{t('hier')}</a>!</p>}
										</>:false}



											<p className="price-page-text">{t('starten_sie_ihre_1_monatige_kostenlose_probeversion')}</p>
											<MDBNav className="price-tabs">
												<MDBNavItem>
													<MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab">
													    {t('monatlich')}
													</MDBNavLink>
												</MDBNavItem>
												<MDBNavItem>
													<MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
													    {t('jahrlich')}
													</MDBNavLink>
												</MDBNavItem>
											</MDBNav>

											<MDBRow center className="align-items-center">
											<MDBTabContent activeItem={this.state.activeItem} style={{width: "100%", paddin: 0}}>
												<MDBTabPane tabId="1" role="tabpanel">
													<MDBRow center>
															<MDBCard className="price-card right active">
																<MDBCardBody className="text-center">
																	<h1>{t('premium_monatlich')}</h1>
																	<div className="price">
																		<span className="currency">€</span>
																		<span className="value">{this.state.premiumMonatlich}</span>
																		<span className="period">/mo</span>
																	</div>
																	<ul className="func-list">
																		<li>{t('kooperation_interaktion')}</li>
																		<li>{t('chat_funktionen')}</li>
																		<li>{t('visibility')}</li>
																		<li>{t('mehrere_accounts')}</li>
																		<li>{t('persönliche_assistance')}</li>
																		<li>{t('business_lounge_funktionen')}</li>
																	</ul>
																</MDBCardBody>
															</MDBCard>
													</MDBRow>
												</MDBTabPane>
												<MDBTabPane tabId="2" role="tabpanel">
													<MDBRow center>
															<MDBCard className="price-card right active">
																<MDBCardBody className="text-center">
																	<h1>{t('premium_jahrlich')}</h1>
																	<div className="price">
																		<span className="currency">€</span>
																		<span className="value">{this.state.premiumJahrlich}</span>
																		<span className="period">/mo</span>
																	</div>
																	<ul className="func-list">
																		<li>{t('kooperation_interaktion')}</li>
																		<li>{t('chat_funktionen')}</li>
																		<li>{t('visibility')}</li>
																		<li>{t('mehrere_accounts')}</li>
																		<li>{t('persönliche_assistance')}</li>
																		<li>{t('business_lounge_funktionen')}</li>
																	</ul>
																</MDBCardBody>
															</MDBCard>
													</MDBRow>
												</MDBTabPane>
											</MDBTabContent>
											</MDBRow>
										</MDBCol>
										<MDBCol md="5">
											<MDBRow>
												<MDBCol md="12">
													<img className="companies-ib2b-newcompany" src={ib2bicon}/>
												  <h5 className="price-page-haufig-text-title-first text-muted">{t('haufig_gestellte_fragen')}</h5>
													<h5 className="price-page-haufig-text-title text-muted">{t('was_passiert_nach_ablauf_der_kostenlosen_Probezeit')}</h5>
													<p className="price-page-haufig-text text-muted">{t('ihre_mitgliedschaft_wird_am_ende')}</p>

													<h5 className="price-page-haufig-text-title text-muted">{t('kann_ich_mein_bonnement')}</h5>
													<p className="price-page-haufig-text text-muted">{t('sie_können_ihre_mitgliedschaft_unter')}</p>


													<h5 className="price-page-haufig-text-title text-muted">{t('wie_erfolgt_die_abrechnung')}</h5>
													<p className="price-page-haufig-text text-muted">{t('die_abrechnung_kann_monatlich_oder_jahrlich_erfolgen')}</p>

													<h5 className="price-page-haufig-text-title text-muted">{t('was_sind_ihre_rückerstattungsrichtlinien')}</h5>
													<p className="price-page-haufig-text text-muted">{t('rückerstattungen_sind_nur_unter')}</p>
												</MDBCol>
											</MDBRow>
										</MDBCol>
										<form
						          className="needs-validation"
						          onSubmit={this.datenBlatt}
						          noValidate
						        >
											<MDBRow>
											<div className="custom-control custom-checkbox pl-3">
												<input
													className="custom-control-input"
													type="checkbox"
													value=""
													id="invalidCheck"
													required
												/>
												<label className="custom-control-label" htmlFor="invalidCheck">
													{labelText}
												</label>
												<div className="invalid-feedback">
													You must agree before submitting.
												</div>
											</div>
											</MDBRow>
											<MDBRow center style={{width: "100%"}} className="pt-3">
												<button className="btn price-card-signup-btn" type="submit">{t('registrieren_2')}</button>
											</MDBRow>
										</form>
										{this.state.systemAdmin ?
											<MDBSwitch checked={this.state.testMode} onChange={this.handleTestModeChange} labelLeft="Live" labelRight="Test"/>
										:
											false
										}
							</MDBRow>
							<div>
      </div>
									{/*<TablePage
									  datenBlatt = {this.datenBlatt}
										onParams = {this.onParams}/>*/}

								<p className="text-center font-weight-bolder my-3 pb-4">{t('wir_werden_sie_7_tage_vor') +  Moment(date).format('DD. MMMM YYYY')}</p>

							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</div>
			</div>
			</div>


			<div className="d-block d-lg-none">
				<MDBContainer fluid className="navbarcomponentRespo-nav-container-b2b">
					<div className={styles.bodywrapper4}>
						<div className="side-nav-menu-b2b">
						<MDBSideNav
						right
						triggerOpening={this.props.isSideNavOpen}
						onOverlayClick={this.props.handleSideNavToggle}
						breakWidth={1300}
						className="deep-purple darken-4"
						>
						<MenuSideNavB2BikeMain
							userData={this.props.userData}
							onMenuClose = {this.props.onEnableScroll}
							onItemClose = {this.props.onEnableScrollMain}
							activeItem = {1}
						/>
						</MDBSideNav>
						</div>
						<LandingPageB2BikeNavRespoUser  activeItem = {"9"}
																						userData={this.props.userData}
																						onMenuOpen = {this.props.handleSideNavToggle}
																						onRefInterB2B={this.onRefInterB2B}
																						textSearch = {this.state.textSearch2}
																						onGetSearchText = {this.onGetSearchText}/>
						<MDBRow between className="pt-4 m-0 px-3">
									 <MDBCol md="6" size="12">
										 <h1 className="price-page-title">{t('preis')}</h1>
										 {this.props.userData.getAcquireCompanyWithSameEmail() ?<>
											 <p className="company_assign">{t('die_emailadresse_des_unternehmens_namens')}<Link className="contacts-link" to={"/CompaniesPage/" + this.state.companyWithSameEmail.id}>{this.state.companyWithSameEmail.name}</Link>{t('in_der_datenbank_entspricht_ihrer_emailadresse')}</p>
											 {!this.state.acquireCompanyWithSameEmail ?
											 <p className="company_assign">{t('um_dieses_unternehmen_ihrem_konto_zuzuordnen_klicken_sie')}<a className="company_enable_hier" onClick={this.onCompanyConnection}>{t('hier')}</a>!</p>:
											 <p className="company_assign">{t('sie_verknüpfen_dieses_unternehmen_mit_ihrem_konto')}<a className="company_enable_hier" onClick={this.onCompanyConnection}>{t('hier')}</a>!</p>}
										 </>:false}



											 <p className="price-page-text">{t('starten_sie_ihre_1_monatige_kostenlose_probeversion')}</p>
											 <MDBNav className="price-tabs">
												 <MDBNavItem>
													 <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab">
															 {t('monatlich')}
													 </MDBNavLink>
												 </MDBNavItem>
												 <MDBNavItem>
													 <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
															 {t('jahrlich')}
													 </MDBNavLink>
												 </MDBNavItem>
											 </MDBNav>
											 <MDBRow center className="align-items-center">
											 <MDBTabContent activeItem={this.state.activeItem} style={{width: "100%", padding: 0}}>
												 <MDBTabPane tabId="1" role="tabpanel">
													 <MDBRow center className="m-0">
															 <MDBCard className="price-card right active p-0">
																 <MDBCardBody className="text-center">
																	 <h1>{t('premium_monatlich')}</h1>
																	 <div className="price">
																		 <span className="currency">€</span>
																		 <span className="value">{this.state.premiumMonatlich}</span>
																		 <span className="period">/mo</span>
																	 </div>
																	 <ul className="func-list">
																		 <li>{t('kooperation_interaktion')}</li>
																		 <li>{t('chat_funktionen')}</li>
																		 <li>{t('visibility')}</li>
																		 <li>{t('mehrere_accounts')}</li>
																		 <li>{t('persönliche_assistance')}</li>
																		 <li>{t('business_lounge_funktionen')}</li>
																	 </ul>
																	</MDBCardBody>
															 </MDBCard>
													 </MDBRow>
												 </MDBTabPane>
												 <MDBTabPane tabId="2" role="tabpanel">
													 <MDBRow center>
															 <MDBCard className="price-card right active">
																 <MDBCardBody className="text-center">
																	 <h1>{t('premium_jahrlich')}</h1>
																	 <div className="price">
																		 <span className="currency">€</span>
																		 <span className="value">{this.state.premiumJahrlich}</span>
																		 <span className="period">/mo</span>
																	 </div>
																	 <ul className="func-list">
																		 <li>{t('kooperation_interaktion')}</li>
																		 <li>{t('chat_funktionen')}</li>
																		 <li>{t('visibility')}</li>
																		 <li>{t('mehrere_accounts')}</li>
																		 <li>{t('persönliche_assistance')}</li>
																		 <li>{t('business_lounge_funktionen')}</li>
																	 </ul>
																	</MDBCardBody>
															 </MDBCard>
													 </MDBRow>
												 </MDBTabPane>
											 </MDBTabContent>
											 </MDBRow>
										 </MDBCol>

										 <MDBCol md="5" size="12">
											 <MDBRow className="m-0 px-0">
												 <MDBCol md="12" size="12" className="m-0 px-0">
													 <img className="companies-ib2b-newcompany" src={ib2bicon}/>
													 <h5 className="price-page-haufig-text-title-first text-muted">{t('haufig_gestellte_fragen')}</h5>
													 <h5 className="price-page-haufig-text-title text-muted">{t('was_passiert_nach_ablauf_der_kostenlosen_Probezeit')}</h5>
													 <p className="price-page-haufig-text text-muted">{t('ihre_mitgliedschaft_wird_am_ende')}</p>

													 <h5 className="price-page-haufig-text-title text-muted">{t('kann_ich_mein_bonnement')}</h5>
													 <p className="price-page-haufig-text text-muted">{t('sie_können_ihre_mitgliedschaft_unter')}</p>


													 <h5 className="price-page-haufig-text-title text-muted">{t('wie_erfolgt_die_abrechnung')}</h5>
													 <p className="price-page-haufig-text text-muted">{t('die_abrechnung_kann_monatlich_oder_jahrlich_erfolgen')}</p>

													 <h5 className="price-page-haufig-text-title text-muted">{t('was_sind_ihre_rückerstattungsrichtlinien')}</h5>
													 <p className="price-page-haufig-text text-muted">{t('rückerstattungen_sind_nur_unter')}</p>
												 </MDBCol>
											 </MDBRow>
										 </MDBCol>
										 <form
 						          className="needs-validation"
 						          onSubmit={this.datenBlatt}
 						          noValidate
 						        >
 											<MDBRow className="mx-4">
 											<div className="custom-control custom-checkbox">
 												<input
 													className="custom-control-input"
 													type="checkbox"
 													value=""
 													id="invalidCheck2"
 													required
 												/>
 												<label className="custom-control-label" htmlFor="invalidCheck2">
 													{labelText}
 												</label>
 												<div className="invalid-feedback">
 													You must agree before submitting.
 												</div>
 											</div>
 											</MDBRow>
 											<MDBRow center style={{width: "100%"}} className="pt-3">
 												<button className="btn price-card-signup-btn" type="submit">{t('registrieren_2')}</button>
 											</MDBRow>
 										</form>
										 {this.state.systemAdmin ?
											 <MDBSwitch checked={this.state.testMode} onChange={this.handleTestModeChange} labelLeft="Live" labelRight="Test"/>
										 :
											 false
										 }
									 {/*<TablePage
										 datenBlatt = {this.datenBlatt}
										 onParams = {this.onParams}/>*/}
								 <p className="text-center font-weight-bolder my-3 pb-4">{t('wir_werden_sie_7_tage_vor') +  Moment(date).format('DD. MMMM YYYY')}</p>
						</MDBRow>
				 </div>
				</MDBContainer>
			</div>
			{this.state.orderStatus?
				<Redirect to={{
					pathname: '/PayPage',
					state: { package: this.state.package,
							 	   preis: this.state.calculatedPrice,
								 	 testMode: this.state.testMode,
									 acquireCompanyWithSameEmail: this.state.acquireCompanyWithSameEmail,
									 companyWithSameEmail: this.state.companyWithSameEmail,
								    }
				}}/>
			: false}
		</>

    );
  }
}

export default withTranslation()(NewCompanyB2B);
