import React, { Component, Fragment } from "react";

import Cookies from 'universal-cookie';
import foto2 from "../../assets/media/fotos/2.jpg";

import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBLightbox } from
"mdbreact";
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import MapBox from '../MapBox/MapBox';
import logo from "../../assets/media/logo/1.png";

import trahsicon from "../../assets/media/newicons/trash.png";
import checkdone from "../../assets/media/newicons/check_done.png";
import arrowcirclelefticon from "../../assets/media/newicons/arrow-circle-left2.png";
import galleryicon from "../../assets/media/newicons/gallery.png";
import plusimgicon from "../../assets/media/newicons/plusImg.png";
import editicon from "../../assets/media/newicons/editProfil.png";
import logouticon from "../../assets/media/newicons/logout.png";
import ib2bicon from "../../assets/media/images/ib2b.png";
import Footer from '../Footer/Footer';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';


import{
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBScrollbar,
  MDBFileInput
} from 'mdbreact';

import InterB2BAxios from '../../Service/InterB2BAxios';


const cookies = new Cookies();
const containerStyle = {
  width: '100%',
  height: '280px'
};


class ImageManager extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,
                  currentCompanyId: cookies.get('authtoken').currentCompanyId,
									selectedPhotos: [],
									editPhotos: false,
									isOpen: false,
									deleteList: [],
              	};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let newSelectedPhotos = [];
      if (this.props.photosList !== undefined && this.props.photosList.length>0){
        for (const companyPhoto of this.props.photosList){
          let urlArray = companyPhoto.split("\/");
          let name = urlArray[urlArray.length-1];
          newSelectedPhotos.push({url: companyPhoto, name: name, server: true});
        }
      }
      this.setState({selectedPhotos: newSelectedPhotos})
    }
  }

	onIsOpenTrue = () => {
		this.setState({isOpen: true});
	}

	onIsOpenFalse = () => {
		this.setState({isOpen: false});
		if(this.props.public){
			this.props.onIsOpen();
		}
	}

	onFileChange = (e) => {
		let list = this.state.selectedPhotos;
    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];
      list.push({file: file, url: URL.createObjectURL(file), name: file.name, server: false});
    }

		this.setState({
  			selectedFotos: list
  		});

  }

  // KUKA gomb
  onImagesToDelete = (photo) => {
    let newSelectedPhotos = this.state.selectedPhotos.filter(function(value) {
        return value.url !== photo.url;
    })
    this.setState({
      selectedPhotos: newSelectedPhotos
    });

		if(photo.server){
      this.state.deleteList.push(photo.name);
		}
	}




// DONE gomb
	handleDone = () => {
		this.onFileDelete(this.state.deleteList);
    // TODO: backend kolcsonos kizaras
    //this.onFileUpload(this.state.selectedPhotos);
		this.setState({editPhotos: false, deleteList: []});
  }


  // DONE gomb: kepek torlese
	onFileDelete = (imageNames) => {
    let self = this;
    this.interAxios.post('/storage/companies/'+this.state.currentCompanyId+'/photos', imageNames, function(response) {
      // TODO: backend: kolcsonos kizaras
			console.log(self.state.selectedPhotos);
      self.onFileUpload(self.state.selectedPhotos);
    });
  }

  // DONE gomb: kepek feltoltese
  onFileUpload = (images) => {
    let imagesToUpload = images
        .filter(function(value) {
            return !value.server;
        });
    if(imagesToUpload.length>0){
      const formData = new FormData
      for (let i = 0; i < imagesToUpload.length; i++) {
        formData.append("files", imagesToUpload[i].file, imagesToUpload[i].name);
      }
      this.interAxios.post('/upload_company_photos/' + this.state.currentCompanyId, formData, {
        'Content-Type': 'multipart/form-data'
      }, function(response) {});
    }

  }

	handleEdit = () => {
		this.setState({editPhotos: true});
	}



  render() {

		var images = [];
		if (this.state.selectedPhotos !== undefined && this.state.selectedPhotos.length>0){
			for (const photo of this.state.selectedPhotos){
					images.push({	src: photo.url });
			}
		}

		let fotosListMap = this.state.selectedPhotos.map((photo) => {
			return <div key={photo.url} >
							<a href="#"  onClick={this.onIsOpenTrue}>
								<img src={photo.url} className="image-manager-img"/>
							</a>
							{this.state.editPhotos ?
							<MDBBtn
									 tag="a"
									 className="image-manager-img-trash-btn"
									 floating
									 color="elegant"
									 onClick={() => this.onImagesToDelete(photo)}
							>
								<img src={trahsicon} />
							</MDBBtn>:false}
						 </div>
		})
    return (


							<MDBCard className="transparent-card pt-2">
								{this.props.editable ?
									<MDBRow>
										<MDBCol>
											<div className="text-right">
											{!this.state.editPhotos ?
											<MDBBtn tag="a" floating className="profile-edit-btn"
															onClick={this.handleEdit}><img src={editicon} />
											</MDBBtn>:
											<MDBRow end className="pr-3">
												<MDBBtn	className="btn btn-done" onClick={this.handleDone}><img src={checkdone} /> Done</MDBBtn>
											</MDBRow>}
											</div>
										</MDBCol>
									</MDBRow>
								: false}
								{this.state.editPhotos ?
                  <div className="file-field md-form" style={{marginTop: "-4rem", marginBottom: 0, width: "85px"}}>
    								<span className="btn-floating plusimage-icon text-center">
    									<img src={plusimgicon} style={{width: "80%", paddingTop: "0.8rem"}} />
    									<input multiple type="file" accept=".jpg,.jpeg,.png" onChange={this.onFileChange}/>
    								</span>
    							</div>:false}
								<MDBRow className="form-row align-items-center">
										{fotosListMap}
										<LightboxPageToMitteilungen
											images={images}
											isOpen={this.state.isOpen}
											onIsOpen={this.onIsOpenFalse}/>
								</MDBRow>
							</MDBCard>

    );
  }
}

export default ImageManager;
