import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

//import './assets/css/chat.min.css'; //chat

import './assets/scss/style.scss';

import App from "./App";
import UserDataComponent from './UserDataComponent';

//import registerServiceWorker from './registerServiceWorker';
//import reportWebVitals from './reportWebVitals';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

ReactDOM.render(

   <Suspense fallback={<div>Laden...</div>}>
    <UserDataComponent/>
  </Suspense>

  ,
  document.getElementById('root'));

//registerServiceWorker();
//reportWebVitals();
