import React, { Component, Fragment } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
import foto2 from "../../assets/media/fotos/2.jpg";
import ratingicon from "../../assets/media/newicons/rating.png";
import { Link } from "react-router-dom";

import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBLightbox } from
"mdbreact";
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import GoogleMaps5 from '../GoogleMaps/GoogleMaps5';
import FeedbackCompany from '../RatingPage/FeedbackCompany';
import FeedbackCardCompany from '../RatingPage/FeedbackCardCompany';
import ImageManager from './ImageManager';
import MapBox from '../MapBox/MapBox';
import Moment from 'moment';
import 'moment/locale/de';
import logo from "../../assets/media/logo/1.png";

import trahsicon from "../../assets/media/newicons/trash.png";
import staricon from '../../assets/media/newicons/star1.png';
import checkdone from "../../assets/media/newicons/check_done.png";
import arrowcirclelefticon from "../../assets/media/newicons/arrow-circle-left2.png";
import galleryicon from "../../assets/media/newicons/gallery.png";
import plusimgicon from "../../assets/media/newicons/plusImg.png";
import editicon from "../../assets/media/newicons/editProfil.png";
import logouticon from "../../assets/media/newicons/logout.png";
import ib2bicon from "../../assets/media/images/ib2b.png";
import Footer from '../Footer/Footer';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';

import{
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBScrollbar,
	MDBCardTitle
} from 'mdbreact';

import InterB2BAxios from '../../Service/InterB2BAxios';


const cookies = new Cookies();
const containerStyle = {
  width: '100%',
  height: '280px'
};


class MyProfile extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,
									q: [0,0,0,0],
									isOpen: false,
									myCompanies: [],
									images: [],
									selectedFiles: [],
									selectedFotos: [],
									myCurrentCompanyId: '',
									showFotos: false,
									deletePhotosList: [],
									lastFeedbackCompany: {},
              	};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		/*this.interAxios.get("/ecommerce/proba_admin", function(response) {
			console.log(response.data);
		})*/

		this.onGetMyCompanies();
  };


	onIsOpen = () => {
		this.setState({isOpen: !this.state.isOpen});
	}


	onUpdateLatLng = () => {
		this.interAxios.get('/api/updatelatlong/' + 50, function(response) {
		})
	};

	onSelectCurrentCompany = () => {
		let self = this;
		this.interAxios.get('/api/user/selectCurrentCompany/' + this.state.myCurrentCompanyId, function(response) {
			self.props.onCurrentCompany();
			cookies.set('authtoken', {
				token: self.state.token,
				userId: self.state.myUserId,
				currentCompanyId: self.state.myCurrentCompanyId
			}, {
				sameSite: 'None',
				secure: true,

			}
			);
		})
	}

	onCustomerPortal = () => {
		let self = this;
		this.interAxios.get('/ecommerce/stripe-customer-portal', function(response) {
			//window.location.href = response.data;
			window.open(response.data);
		});
	}


	onGetMyCompanies = () => {
		let self = this;
		this.interAxios.get('/api/user/getMyCompanies', function(response) {
			self.setState({myCompanies: response.data});
		});
	}

	onfindCompanyByName = (name) => {
		let self = this;
		this.interAxios.get('/api/findCompanyByName', {params: {name: name}}, function(response) {
			self.setState({myCurrentCompanyId: response.data.id});

		});
	}

	onSetCompany = value => {
     this.setState({currentCompanyName: value});
		 this.onfindCompanyByName(value);
  };

  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
<>
	{this.props.status ?
	<section className="profile-header">
		<MDBRow className="m-0">
			<MDBCol size="12" className="profile-header-bg" style={{backgroundImage: `url(${this.props.currentCompany.backgroundUrl+ '?' + this.props.timeStamp})`}}>
			</MDBCol>
		</MDBRow>
		<MDBRow className="m-0">
			<MDBCol size="12" className="profile-header-content">
				<MDBContainer>
					<MDBRow>
						<MDBCol size="6">
							{this.props.currentCompany.id!== undefined && this.props.status ?
								<img className="rounded-circle profile-image"
									src={this.props.currentCompany.profileUrl +'?' + this.props.timeStamp}
									onClick={this.props.handleShowProfile}
								/>:false}
						</MDBCol>
						<MDBCol size="6" className="profile-header-btns text-right">
							{this.props.status ?
								<>
								<MDBBtn tag="a" floating className="profile-header-edit-btn"
										onClick={this.props.handleShowBackground}><img src={editicon} />
								</MDBBtn>
								</>:false}
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBCol>
		</MDBRow>
	</section>:

	<section className="profile-header">
		<MDBRow>
			<MDBCol size="12" className="profile-header-bg" style={{backgroundImage: `url(${this.props.currentCompany.backgroundUrl+ '?' + this.props.timeStamp})`}}>
			</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="12" className="profile-header-content">
				<MDBContainer>
					<MDBRow>
						<MDBCol size="6">
							{this.props.currentCompany.id!== undefined ?
								<img className="rounded-circle profile-image"
									src={this.props.currentCompany.profileUrl +'?' + this.props.timeStamp}
								/>:false}
						</MDBCol>
						<MDBCol size="6" className="profile-header-btns text-right">
						<MDBRow end>
							<MDBBtn flat className="resetCompany" onClick={this.props.handleResetCurrentCompany}>
									<img src={arrowcirclelefticon}/>
							</MDBBtn>
						</MDBRow>
							<FeedbackCompany currentCompany = {this.props.currentCompany}/>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBCol>
		</MDBRow>
	</section>}

	<MDBContainer className="profile-content">
		<MDBRow>
			<MDBCol size="12" md="6">
				<MDBCard className="transparent-card">
					<MDBRow>
						<img  className="profile-ib2bicon" src={ib2bicon}/>
					</MDBRow>
					<MDBRow>
						<MDBCol size="9" md="9" lg="10" xl="10">
							<h3>{this.props.currentCompany.name}</h3>
							<small style={{fontSize: "18px"}}>{this.props.currentCompany.street}</small><br/>
							<small style={{fontSize: "18px"}}>{this.props.currentCompany.postcode} {this.props.currentCompany.town}</small><br/>
							<small style={{fontSize: "18px"}}>{this.props.currentCompany.country}</small><br/>
							<br/>
							{this.props.currentCompany.phonenumber !=="" && this.props.currentCompany.phonenumber !== null ?
							<MDBIcon icon="phone fa-fw mr-2"/>:false} <small style={{fontSize: "18px"}}>{this.props.currentCompany.phonenumber}</small><br/>
							{this.props.currentCompany.email !=="" && this.props.currentCompany.email !== null ?
							<MDBIcon icon="at fa-fw mr-2"/>:false} <a style={{fontSize: "18px"}} href={"mailto:" + this.props.currentCompany.email}>{this.props.currentCompany.email}</a><br/>
							{this.props.currentCompany.website !=="" && this.props.currentCompany.website !== null ?
							<MDBIcon icon="link fa-fw mr-2"/>:false} <a style={{fontSize: "18px"}} href={"https://" + this.props.currentCompany.website} target="_blank" rel="noopener noreferrer" className="underlined">{this.props.currentCompany.website}</a><br/><br/>
						</MDBCol>
						<MDBCol size="3" md="3" lg="2" xl="2">
							{this.props.status ?
							<div className="text-right">
								<MDBBtn tag="a" floating className="profile-edit-btn"
												onClick={this.props.handleShowCompany}><img src={editicon} />
								</MDBBtn>
							</div>:false}
						</MDBCol>
					</MDBRow>
				</MDBCard>
			</MDBCol>
			<MDBCol size="12" md="6">
				{this.props.currentCompany.lat !== "" ?
					<div className="profile-mapholder">
					<GoogleMaps5 latitude={this.props.currentCompany.lat}
											 longitude={this.props.currentCompany.lng}
											 currentCompany={this.props.currentCompany}
											 isCompaniesMap= {true}
											 withMap = {"100%"}
											 heightMap = {"270px"}
											/>
					</div>:false}
				</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="12" md="6">
				<ImageManager
					editable={this.props.status}
					public= {false}
					photosList = {this.props.companyPhotosUrl}/>
				{this.props.companySubscription !== undefined && !this.props.status ?
				<div className="pt-3 pr-4">
					<MDBCard className="interb2b-card p-2">
							<MDBRow>
								<MDBCol className="text-center p-3">
									<h4>Aktuelles Unternehmen</h4>
								</MDBCol>
							</MDBRow>
							<MDBRow className="p-3">
								<MDBCol md="auto">
									<span>Firmen Name:</span><br/>
									<span>Gültig bis:</span><br/>
									<span>Aktuelles Paket:</span><br/>
									<span>Aktuelles Level:</span><br/>
								</MDBCol>
								<MDBCol>
									 <span>{this.props.currentCompany.name}</span><br/>
									 <span>{Moment(this.props.companySubscription?.gultigBis).locale('de').format('DD MMMM YYYY')}</span><br/>
									 <span>{this.props.companySubscription?.currentPackage}</span><br/>
									 <span>{this.props.companySubscription?.currentLevel}</span><br/>
								</MDBCol>
							</MDBRow>
					</MDBCard>
				</div>:false}
			</MDBCol>
			<MDBCol size="12" md="6">
							{this.props.currentCompany.aboutUs !== null || this.props.status ?
							<MDBCard className="interb2b-card px-4 pt-2">
							<MDBRow className="form-row align-items-center">
							<MDBCol>
								<h3>{t('uber_uns')}</h3>
							</MDBCol>
							<MDBCol>
								{this.props.status ?
									<div className="text-right">
										<MDBBtn tag="a" floating className="profile-edit-btn profile-uberuns-edit-btn"
														onClick={this.props.handleCloseArticle}><img src={editicon} />
										</MDBBtn>
									</div>
								:false}
							</MDBCol>
							</MDBRow>
							<MDBRow>
							<MDBCol>
								<div className=' py-2'
								dangerouslySetInnerHTML={{__html: this.props.currentCompany.aboutUs }}/>
							</MDBCol>
							</MDBRow>
							</MDBCard>:false}
							{!this.props.status  ?
								<>
									{this.props.currentCompany.id !== undefined ?
									<FeedbackCardCompany 	currentCompany= {this.props.currentCompany.id}
																				companyName = {this.props.currentCompany.name}
																				allFeedbackStatus = {true}
																				myCompany = {false}
																				{...this}
																				{...this.props}
									/>:false}
								</>:false}
			</MDBCol>
		</MDBRow>
		{this.props.status ?
			<>
		<MDBRow center className="pt-4">
		{this.props.currentCompany.enabled ?
			<><p className="company_enable">{t('ihre_unternehmensinformationen_sind_derzeit')}<a className="company_enable_hier" onClick={this.props.onDisabledCompany}>{t('hier')}</a>!</p></>:
      <><p className="company_enable">{t('ihre_unternehmensinformationen_sind_derzeit_nicht')}<a className="company_enable_hier" onClick={this.props.onEnabledCompany}>{t('hier')}</a>!</p>
			</>}
		</MDBRow>
		<Footer homeStatus={false}/></>:false}
	</MDBContainer>
</>
    );
  }
}

export default withTranslation()(MyProfile);
