import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import 'moment/locale/de';
import Ckeditor from './Ckeditor';
import logo from "../../assets/media/logo/1.png";
import likeicon from "../../assets/media/newicons/like.png";
import like2icon from "../../assets/media/newicons/like2.png";
import commenticon from "../../assets/media/newicons/clipboard-text.png";
import deleted from "../../assets/media/images/markt/profil/deleted.png";
import reserved from "../../assets/media/images/markt/profil/reserved.png";
import revoked from "../../assets/media/images/markt/profil/revoked.png";
import bookmark_w from "../../assets/media/images/markt/bookmark-w.png";
import favorite_green from "../../assets/media/images/markt/bookmark.png";
import noImage from "../../assets/media/images/noImage.png";
import { UserStatus } from "../../Config/Config";
import InterB2BAxios from '../../Service/InterB2BAxios';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardTitle,
	MDBCardBody,
	MDBBtn,
	MDBCollapse,
	MDBView,
	MDBMask,
	MDBContainer,
	MDBIcon,
	MDBBtnGroup,
	MDBInput,
	MDBBadge,
	MDBPopper,
	MDBTooltip

} from 'mdbreact';

const cookies = new Cookies();

class CompanyAnzegeKarte extends React.Component {


  constructor(props) {

    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {timeStamp: Date.now(),
					maxheight: 142.3,
					textstatus: false,
					isHovering: false,
					alreadyBookmark: false,
					isFavorite: false,
					user: {}
		    };
  };

	componentDidMount() {
		if(this.props.companys !== undefined){
			this.onfindCompanyByName(this.props.companys.filter(o => o.id === this.props.supplierItem.supplierId)[0].name);
		}else{
			this.onFindCompanyById(this.props.msg.id);
		}

	};

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	onfindCompanyByName = (name) => {
		let self = this;
		this.interAxios.get('/api/findCompanyByName', {params: {name: name}}, function(response) {
			self.setState({aktuelleCompanyProfileUrl: response.data.profileUrl, currentCompany: response.data, companyId: response.data.id });

		});
	}

	onFindCompanyById = (companyId) => {
		var self = this;
    this.interAxios.get('/api/findCompanyById/' + companyId, function(response) {
          self.setState({currentCompany: response.data});
    })

	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
    const {open} = this.state

    return (
		<MDBCol md="4" size="auto" className="mb-3 py-0 pl-0 pr-2 text-center" style={{width: "100%"}}>

		<MDBCard className="companyanzeige-card">
			<MDBCardBody>
			<Link className="contacts-link" to={{pathname: "/CompanyPageWithProduct/" + this.state.companyId, state: { msg: this.props.msg, activeItem: this.props.activeItem}}}>
				<MDBRow className="m-0">
					<div size="12" className="profile-header-bg-b2b" style={{backgroundImage: `url(${this.state.currentCompany?.backgroundUrl+ '?' +  this.state.timeStamp})`}}>

						{this.state.currentCompany?.id!== undefined  ?
							<img className="rounded-circle profile-image"
								src={this.state.currentCompany?.profileUrl +'?' + this.state.timeStamp}
							/>:false}
					</div>
				</MDBRow>
				<MDBRow className="pl-2 py-2">

					<MDBCol middle className="text-center">
						<span className="username">{this.state.currentCompany?.name}</span><br/>
						<small className="adresse-text">{this.state.currentCompany?.street} {this.state.currentCompany?.postcode} {this.state.currentCompany?.town} {this.state.currentCompany?.country}</small><br/>

					</MDBCol>
				</MDBRow>
				</Link>
			</MDBCardBody>
		</MDBCard>
		</MDBCol>
	);
  }
}

export default withTranslation()(CompanyAnzegeKarte);
