import React, { Component } from "react";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
//import Cookies from 'universal-cookie';
import Imagecrop from '../Imagecrop/Imagecrop'
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import InterB2BAxios from '../../Service/InterB2BAxios';
import { Scrollbar } from "react-scrollbars-custom";

//const cookies = new Cookies();


class CreateImageProfile extends Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {/*token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,*/
                  show: this.props.show,};
  };

  componentDidMount() {
    //this.interAxios.refreshUserData(this.props.userData);
  };

  componentWillUnmount() {
    this.interAxios.cancel();
  }

 onCropComplete = (image) => {
   this.setState({url: image.url, blob: image.blob});
 }

 handleSaveProfile = (event) => {
   event.preventDefault();
   let self = this;
   const formData = new FormData
   formData.append(0, this.state.blob, "new" + this.props.bildName + "_" + this.props.currentCompany.id + ".jpg");

   this.interAxios.post('/upload_profile/' + this.props.currentCompany.id, formData, {
     headers: {
       'Content-Type': 'multipart/form-data'
     }
   }, function(response) {
     self.props.userData.refresh();
     self.handleClose();
   })
 }

 handleClose = () => {
   this.props.handleClose();
   this.setState({show: false});
 }

 handleShow = () => {
   this.setState({show: true});
 }
 /*onCLientWidth = () => {
   this.setState({widthModalBody: (document.getElementById('background-modal-body').clientWidth-32)});
 }*/
  render() {
    const { t } = this.props;
    const client = this.props.client;
    return (
      <MDBContainer>
        <MDBModal
            isOpen={this.state.show}
            toggle={this.handleClose}
            overflowScroll = {true}
            >
         <MDBModalHeader toggle={this.props.handleClose}>
          {t('bildassistent')}
         </MDBModalHeader>
         <MDBModalBody>
         <Scrollbar
           id="scrollbar-b2b"
           style={{ width: "98%", height: "calc(100vh - 17rem)"}}
           maximalThumbYSize = {150}
           noScrollX = {true}
           >
           <Imagecrop
              onCropComplete={this.onCropComplete}
              width = {this.state.widthModalBody}
              minWidth = {this.props.minWidth}
              maxWidth = {this.props.maxWidth}
              aspect = {this.props.aspect}

              />
         </Scrollbar>
         </MDBModalBody>
         <MDBModalFooter>
           <MDBBtn color="btn btn-done-feedback" onClick={this.handleClose}>
             {t('schließen')}
           </MDBBtn>
           {this.state.blob !== undefined ?
             <MDBBtn color="btn btn-done-speichern" onClick={this.handleSaveProfile}>{t('speichern')}</MDBBtn>
           : false}
         </MDBModalFooter>
       </MDBModal>
      </MDBContainer>
    );
  }
}

export default withTranslation()(CreateImageProfile);
