import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import Lightbox from "react-image-lightbox";
import "../../assets/css/Lightbox.css";

class LightboxPageToMitteilungen extends React.Component {
  state = {
    photoIndex: 0
  }
  render() {
    var images = [];
    for (let i = 0; i < this.props.images.length; i++){
        images.push(this.props.images[i].src)}
    const { photoIndex} = this.state;
    return (
      <>
        {this.props.isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            imageTitle={photoIndex + 1 + "/" + images.length}
            onCloseRequest={this.props.onIsOpen}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </>
    );
  }
}
export default LightboxPageToMitteilungen;
