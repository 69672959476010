import React, { Component } from "react";

import { ReactComponent as SearchSvg } from "../../assets/media/icons/search.svg";
import Cookies from 'universal-cookie';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import{
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBContainer,
	MDBModalFooter,
	MDBBtn,
  MDBFileInput,
  MDBSelect
} from 'mdbreact';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Scrollbar } from "react-scrollbars-custom";
const cookies = new Cookies();

class Article extends Component {
  state = {
            token : cookies.get('authtoken').token,
            myUserId: cookies.get('authtoken').userId
						};



  render() {
		const { t } = this.props;
		const client = this.props.client;
		let initialContent = 'Hello';
    return (
      <MDBContainer>
      <MDBModal isOpen={this.props.show} toggle={this.props.handleClose}>
        <MDBModalHeader toggle={this.props.handleClose}>
                  {t('artikel_hinzufügen')}
        </MDBModalHeader>
          <MDBModalBody>
					<Scrollbar
			      id="scrollbar-b2b"
			      style={{ width: "98%", height: "calc(100vh - 20rem)" }}
			      maximalThumbYSize = {150}
			      noScrollX = {true}
			      >
					<div style={{height: "calc(100vh - 23rem)"}}>
					<CKEditor	 editor={ ClassicEditor }
										 data={this.props.article}
										 config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
										 onChange={ ( event, editor ) => {
                         const data = editor.getData();
												 this.props.onArticleChange(data);
                     } }
                 />
						</div>
						</Scrollbar>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="btn btn-done-feedback" onClick={this.props.handleClose}>
                {t('schließen')}
              </MDBBtn>
              <MDBBtn color="btn btn-done-speichern" onClick={this.props.handleShow}>{t('speichern')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
    );
  }
}
export default withTranslation()(Article);
