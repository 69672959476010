import React, { Component } from "react";
import Cookies from 'universal-cookie';
import InterB2BAxios from '../Service/InterB2BAxios';
import FeedbackItemWhitoutComment from '../components/RatingPage/FeedbackItemWhitoutComment';
import Feedback from '../components/RatingPage/Feedback';
import FooterB2BMainPage from '../components/Footer/FooterB2BMainPage';
import FooterMarktLanding from '../components/Footer/FooterMarktLanding';
import ProfilpageNavRespo from '../components/NavbarComponent/ProfilpageNavRespo';
import LandingPageB2BikeNavRespo2 from '../components/NavbarComponentB2B/LandingPageB2BikeNavRespo2';
import MenuSideNavB2Bike from '../pages/b2b/LandingPageB2Bike/MenuSideNavB2Bike';
import MenuSideNavB2BikeMain from '../pages/b2b/Handler/MainPageHandler/MenuSideNavB2BikeMain';
import BoersePageNavRespo from '../components/NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from '../pages/markt/BoersePage/MenuSideNavBoerse';
import BikePartsLinkNavItem from '../components/NavbarComponent/BikePartsLinkNavItem';
import EBikeBoerseLinkNavItem from '../components/NavbarComponentB2B/EBikeBoerseLinkNavItem';
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Link } from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';
import logo from "../assets/media/logo/1.png";
import ReactStars from "react-rating-stars-component";
import styles from '../assets/scss/modules/about.module.scss';
import profile_Boda from "../assets/media/images/profile_Boda.jpg";
import ratingicon from "../assets/media/newicons/rating.png";
import emptyicon from "../assets/media/newicons/star.png";
import ratingLogo from "../assets/media/images/RatingLogo.png";
import ib2bicon from "../assets/media/images/ib2b.png";
import i18next from 'i18next';
import nutzungsbedingungeninterb2bike from "../assets/media/pdf/nutzungsbedingungeninterb2bike.pdf";
//import nutzungsbedingungeninterb2bike_html from "../assets/media/html/nutzungsbedingungeninterb2bike.html";
const cookies = new Cookies();


import{
	MDBContainer,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
	MDBCardTitle,
	MDBRow,
	MDBCol,
	MDBBtn,
	MDBIcon,
	MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBSideNav
} from 'mdbreact';
let urlName = '';
class UserAgreementB2B extends Component {
	constructor(props) {
    super(props);
		this.listInnerRefStart = React.createRef();
		this.interAxios = new InterB2BAxios(this);
    this.state = {newslist: [],
									pageName: "User Agreement",
									urlName: this.props.location?.state?.urlName !== undefined ? this.props.location.state.urlName : '',
									};

  };

  componentDidMount() {
		document.addEventListener("scroll", this.scrollEventListener);
		this.onRefStart();
		this.onGetAgb();
		var params = window.location.href.split("/");
    urlName = params[params.length - 1];
  };


	componentWillUnmount() {
		this.interAxios.cancel();
		document.removeEventListener("scroll", this.scrollEventListener);
  }

	onRefStart = () => {
		this.listInnerRefStart.current.scrollIntoView({
	 });
	}

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}
	toggle = tab => e => {
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
				}

	}

	onGetAgb= () => {
		let self = this;
		this.interAxios.get('/storage/legal/nutzungsbedingungeninterb2bike.html', function(response) {
			self.setState({agb: response.data});
			//this.onAnzeigeCompany(response.data.userFormProduct.id);
		})
	};

  render() {
		const { t } = this.props;
    const client = this.props.client;
		let urltext = "LandingPageMarkt";
    return(
			<>
			<div ref={this.listInnerRefStart}></div>
<div className="d-none d-lg-block">
	<BikePartsLinkNavItem userData={this.props.userData}/>
</div>
<div className="d-lg-none d-block">
<div className="navbarcomponentRespo-nav-container-b2b"><LandingPageB2BikeNavRespo2  activeItem = {"4"}
																userData={this.props.userData}
																onMenuOpen = {this.props.handleSideNavToggle}
																/></div>
</div>
<div className={styles.bodywrapper}>
<div className="d-lg-none d-block">
	<div className="side-nav-menu">
		<MDBSideNav
			right
			triggerOpening={this.props.isSideNavOpen}
			onOverlayClick={this.props.handleSideNavToggle}
			breakWidth={1300}
			className="deep-purple darken-4"

		>
		{this.props.userData.isLoggedIn() ?
			<MenuSideNavB2BikeMain
				userData={this.props.userData}
				onMenuClose = {this.props.onEnableScroll}
				onItemClose = {this.props.onEnableScrollMain}
				activeItem = {this.state.activeItem}
				toggle = {this.toggle}
			/>:
			<MenuSideNavB2Bike
				userData={this.props.userData}
				onMenuClose = {this.props.onEnableScroll}
				onItemClose = {this.props.onEnableScrollMain}
				activeItem = {this.state.activeItem}
				toggle = {this.toggle}
			/>}
		</MDBSideNav>
	</div>
</div>
		<MDBContainer>
		<MDBRow className="m-0 py-4">
		<MDBCol md="12" size="12" className="m-0 px-4">

<div dangerouslySetInnerHTML={{__html: this.state.agb}}/>
	</MDBCol>
	</MDBRow>
	</MDBContainer>
</div>
</>
    );
  }
}
export default withTranslation()(UserAgreementB2B);
