import React, { Component } from "react";
import Cookies from 'universal-cookie';
import logo from "../../assets/media/images/signinbg.png";
import styles from '../../assets/scss/modules/adverts.module.scss';
const cookies = new Cookies();

import{
	MDBCard,
	MDBCardBody,
	MDBContainer,
	MDBRow
} from 'mdbreact';

class AdvertBoxMain extends Component {
	constructor(props) {
    super(props);
    this.state = {myUserId: cookies.get('authtoken').userId,
									sysAdmin: cookies.get('authtoken').sysAdmin,
									gastAdmin: cookies.get('authtoken').gastAdmin,
									};

  };

	render() {
    return (
			<>{!this.state.gastAdmin ?
	      <MDBCard className="interb2b-card">
					<MDBCardBody>
	          <p className="font-weight-bolder">ADVERTBOX MAIN</p>
	          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
					</MDBCardBody>
	      </MDBCard>:
				<div className={styles.bodywrapper}>
				<MDBContainer className="adverts-container">
				<MDBCard className="transparent-card interb2b-card card-adverts py-4"  >
					<MDBCardBody className="px-1">
						<MDBRow center>
	          	<span className="font-weight-bolder text-white adverts-text ">interB2B</span><br/>
						</MDBRow>
						<MDBRow center className="pb-3">
							<span className="font-weight-bolder text-white adverts-text">Business und Social Media an einem Ort</span><br/><br/>
						</MDBRow>
	          <p className="text-white adverts-text px-2" >Um auf weitere Inhalte zuzugreifen, registrieren Sie sich auf unserer Website und melden Sie sich an.
								Wenn Sie alle Funtionen der Seite ausprobieren möchten, registrieren
								Sie Ihre Firma und Sie können dies für den ersten Monat kostenlos tun.</p>
					</MDBCardBody>
		    </MDBCard>
				</MDBContainer></div>}
			</>
    );
  }
}
export default AdvertBoxMain;
