import React, { Component } from "react";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import Cookies from 'universal-cookie';
import Imagecrop from '../Imagecrop/Imagecrop'
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import InterB2BAxios from '../../Service/InterB2BAxios';
import { Scrollbar } from "react-scrollbars-custom";

const cookies = new Cookies();


class CreateImageBackground extends Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,
                  show: this.props.show,
                  };
  };

  componentDidMount() {
  };

  componentWillUnmount() {
    this.interAxios.cancel();
  }

 onCropComplete = (image) => {
   this.setState({url: image.url, blob: image.blob});
 };

 handleSaveBackground = (event) => {
   event.preventDefault();
   let self = this;
   const formData = new FormData
   formData.append(0, this.state.blob, "new" + this.props.bildName + "_" + this.props.currentCompany.id + ".jpg");

   this.interAxios.post('/upload_background/' + this.props.currentCompany.id, formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
     self.props.userData.refresh();
     self.handleClose();
   });
/*   axios.post(process.env.REACT_APP_BACKEND_URL+'/upload_background/' + this.props.currentCompany.id, formData, {
     headers: {
       'Content-Type': 'multipart/form-data',
       'Authorization': 'Bearer ' + this.state.token
     }
   })
   .then(function(response) {
     self.handleClose();
   })
   .catch(function(error) {
     console.log(error)
   });*/

 };

 handleClose = () => {
   this.props.handleClose();
   this.setState({show: false});
 };

 handleShow = () => {
   this.setState({show: true});
 };

  render() {
    const { t } = this.props;
    const client = this.props.client;
    return (
      <MDBContainer>
        <MDBModal className="my-modal"
            isOpen={this.state.show}
            toggle={this.handleClose}>
         <MDBModalHeader toggle={this.handleClose}>
          {t('bildassistent')}
         </MDBModalHeader>
         <MDBModalBody>
         <Scrollbar
           id="scrollbar-b2b"
           style={{ width: "98%", height: "calc(100vh - 17rem)"}}
           maximalThumbYSize = {150}
           noScrollX = {true}
           >
           <Imagecrop
              onCropComplete={this.onCropComplete}
              width = {this.state.widthModalBody}
              minWidth = {this.props.minWidth}
              maxWidth = {this.props.maxWidth}
              aspect = {this.props.aspect}

              />
         </Scrollbar>
         </MDBModalBody>
         <MDBModalFooter>
           <MDBBtn color="btn btn-done-feedback" onClick={this.handleClose}>
             {t('schließen')}
           </MDBBtn>
           {this.state.blob !== undefined ?
             <MDBBtn color="btn btn-done-speichern" onClick={this.handleSaveBackground}>
               {t('speichern')}
             </MDBBtn>
           : false}
         </MDBModalFooter>
       </MDBModal>
      </MDBContainer>
    );
  }
}

export default withTranslation()(CreateImageBackground);
