import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import InterB2BAxios from '../../Service/InterB2BAxios';
import Cookies from 'universal-cookie';
import styles from '../../assets/scss/modules/signinB2B.module.scss';
import arrowcircleleft from "../../assets/media/images/b2b/arrow-circle-left.png";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn
} from 'mdbreact';

const cookies = new Cookies();

class ForgottenPasswordB2B extends Component {
  state = {email: ''};

  constructor(props) {
      super(props);
			this.interAxios = new InterB2BAxios(this);
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}

  onEmailChange  = (event) => {
    this.setState({email: event.target.value});
  };


	sendEmail = (event) => {
	    event.preventDefault();
	    var self = this;
			this.interAxios.get('/api/auth/password/reset', {params: {email: this.state.email}}, function(response) {
				self.setState({redirect: true});
			})
	    /*axios.get(process.env.REACT_APP_BACKEND_URL+'/api/auth/password/reset',
	          {params: {email: this.state.email}})
	    .then(function(response) {
	      self.setState({redirect: true});
			})
	    .catch(function(error) {
	      console.log(error);
	    })*/

	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
	<div className={styles.bodywrapper1}>
	<MDBContainer className="signin-container">
	<MDBRow center>
		<MDBCol sm="6" md="6" lg="4">
			<MDBRow start className="pb-5 m-0">
			<Link to="/">
				<img className="vuesax-linear-arrow-circle-lef" src={arrowcircleleft}></img>
			</Link>
			</MDBRow>
			<MDBRow start className="pb-4 m-0">
			<span className="sign-up-to-inter-b-2-bi">{t('email_senden')}</span>
			</MDBRow>
			<form className="signinb2b-form">

			<MDBInput
				className="signinb2b-input"
				label="Email"
				placeholder={t('geben_sie_ihre_email_ein')}
				type="email"
				validate error="wrong"
				success="right"
				value={this.state.email}
				onChange={this.onEmailChange}
								/>

			<MDBBtn
				className="signup-btn"
				to="/SignInB2B"
				type="submit"
				onClick={this.sendEmail}
				>{t('absenden')}
			</MDBBtn>

			{ this.state.redirect === true ?
				<Redirect to='/SignInB2B'
				/>:
				false
								}
		</form>


			</MDBCol>
			<MDBCol sm="6" md="6" lg="8" className=".d-none .d-sm-block">
			</MDBCol>
		</MDBRow>
	</MDBContainer>

	</div>
    );
  }
}

export default withTranslation()(ForgottenPasswordB2B);
