import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import logo2 from "../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../../assets/media/images/markt/profil/Group 7.png";
import InterB2BAxios from '../../../Service/InterB2BAxios';
import BoersePageNavRespo from '../../NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from '../../../pages/markt/BoersePage/MenuSideNavBoerse';
import BikeAnzeigeKarte from '../../Companys/BikeAnzeigeKarte';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import GoogleMaps5 from '../../GoogleMaps/GoogleMaps5';
import styles from '../../../assets/scss/modules/profilpage.module.scss';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
  MDBBtn,
	MDBCollapse,
	MDBCard,
	MDBSideNav
} from 'mdbreact';

let language = '';
let userId = '';
let urlName = '';
class ProfilPageResponziv extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefInterB2B = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'Eintraege');
    this.state = {bikeslist: {},
									user: {},
									};

  };

	componentDidMount() {
		this.onRefInterB2B();
		this.onAnimationStart();
		this.props.addFunctionFromChild(this.onMessage);
		document.addEventListener("scroll", this.scrollEventListener);
		var params = window.location.href.split("/");
    userId = params[params.length - 1];
		var params2 = window.location.href.split(".");
	  language = params2[params2.length - 1];
		var params3 = window.location.href.split("/");
		urlName = params3[params3.length - 1];
		this.onGetUser(userId);
		this.onGetBikesWithUserId(userId);

	}

	componentWillUnmount() {
		this.props.removeFunctionFromChild(this.onMessage);
		document.removeEventListener("scroll", this.scrollEventListener);
		this.interAxios.cancel();
	}

	onRefInterB2B = () => {
		this.listInnerRefInterB2B.current.scrollIntoView({
	 });

	}

	onMessage = (message) => {
    let type = message.type;
		if (type === 'NEWEBIKEPRODUCT'){
	    this.onGetBikesWithUserId();
	  }
  };

	onGetUser = (id) => {
		let self = this;
		this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
			self.setState({user: response.data});
		})
	}

	onGetBikesWithUserId = (id) => {
		let self = this;
		this.interAxios.get('/api/allBikeProductWithUserId/' + id, function(response) {
			self.setState({bikeslist: response.data});
		})
	};

	onChatContact = () => {
		let self = this;
		this.interAxios.get('/api/chat/bike/room/getOrCreateWithUser/' + this.state.bikeProduct.userFormProduct.id + "/" + 0,
			function(response) {
				self.setState({chatroomid: response.data, redirect: true});
		});
	}

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}
	onAnimationStart = () => {
		setTimeout(function(){
			this.setState({animationTrue: true});
		}.bind(this), 500);
	}
  render() {
		const { t } = this.props;
    const client = this.props.client;
		var text ="";


		if (this.state.user?.aboutUs !== ''){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.state.user?.aboutUs, 'text/html');
			text =parsedHtml.documentElement.textContent;
		}
		var profilUrlEnde = "";
		if(this.state.user.profileUrl !== undefined){
			var profilUrlEnde = this.state.user.profileUrl.split("_")[this.state.user.profileUrl.split("_").length - 1];
		}

		return (
			<>
			<div ref={this.listInnerRefInterB2B}></div>
			<BoersePageNavRespo userData={this.props.userData}
													onMenuOpen = {this.props.handleSideNavToggle}
													isUserMenu={false}
													activeItem = {"3"}
													isOpen = {this.state.isButton}/>
<MDBCollapse id="basicCollapse2" className="fullPageCollapse" isOpen={this.state.animationTrue} delay={{show: 2000}} onOpened={() => {this.setState({wrapperTrue: true});}}>
				<div className={styles.bodywrapper3}>
					<div className="d-lg-none d-block">
						<div className="side-nav-menu">
							<MDBSideNav
								right
								triggerOpening={this.props.isSideNavOpen}
								onOverlayClick={this.props.handleSideNavToggle}
								breakWidth={1300}
								className="deep-purple darken-4"

							>
								<MenuSideNavBoerse
														userData={this.props.userData}
														toggle = {this.toggle}
														onMenuClose = {this.props.onEnableScroll}
														activeItem = {this.state.activeItem}
								/>
							</MDBSideNav>
						</div>
					</div>
				<div className="profil-background">
					<MDBContainer fluid className="px-1">
						<div className="person-card-respo">
								<MDBRow center className="m-0 pb-4">
								{this.state.user?.companyUser ?
									<img alt="" className="user-foto" src={this.state.user?.companyProfileUrl +'?' + Date.now()}></img>:<>
										{this.state.user?.profileUrl !== undefined && profilUrlEnde!== "default.jpg" ?
									<img alt="" className="user-foto" src={this.state.user?.profileUrl +'?' + Date.now()}></img>:
									<div className="userProfileName-icon">{this.state.user?.profilName}</div>}</>}
								</MDBRow>
								<MDBRow center className="m-0 pb-2">
								{this.state.user?.companyUser ?
									<span className="user-name">{this.state.user?.companyName}</span>:
									<span className="user-name">{this.state.user?.name}</span>}
								</MDBRow>
								<MDBRow center className="m-0 pb-3">
								{this.state.user?.publictelefon ?
									<span className="user-telefon">{this.state.user?.publictelefon}</span>:false}
								</MDBRow>
								<MDBRow start className="m-0 pb-4">
								{this.state.user?.aboutUs ?
									<span className="user-aboutUs">{text}</span>:false}
								</MDBRow>
								<MDBRow center className="m-0 pb-4 px-3">
								<GoogleMaps5  latitude={this.state.user.lat !== -200 && this.state.user.lat !== null ? this.state.user.lat: this.props.userData.getLatLng().originalLatitude}
															longitude={this.state.user.lng !== -200 && this.state.user.lng !== null ? this.state.user.lng: this.props.userData.getLatLng().originalLongitude}
															isProfileMap={true}
															userData = {this.props.userData}
															withMap = {"100%"}
															heightMap = {"150px"}/>
								</MDBRow>
								<MDBRow center className="m-0">
								<a href={"mailto:" + this.state.user.email}>
								 <MDBBtn className="contact-btn-person"
								 >
									 <span className="contact-btn-text text-capitalize">E-mail</span>
								 </MDBBtn>
								</a>
								</MDBRow>

							<MDBRow center className="pt-3 pb-3 m-0">
								<MDBCol middle size="5" className="pl-0 pr-1 text-right">
									<img alt="" className="profil-title-logo-profilpage" src={logo3}></img>
								</MDBCol>
								<MDBCol middle size="7" className="pl-1 pr-0 text-left">
								{this.state.user?.companyUser ?
									<span className="logo-text-name">{this.state.user?.companyName}</span>:
									<span className="logo-text-name">{this.state.user?.name}</span>}
								</MDBCol>
							</MDBRow>
							<MDBRow className="m-0 eigene-padding-bottom">
								{this.state.bikeslist?.length > 0 ?
										<>
										{this.state.bikeslist.filter((e) => (e.aktuelleStatus !== 3 && e.aktuelleStatus !== 4)).map(
										(o, index) =>
										<MDBCol size="6" md="4" className="mb-3 px-1 pt-0">
														<BikeAnzeigeKarte
														 key={index}
														 userData={this.props.userData}
														 msg={o}/>
										</MDBCol>
									)}
									 </>:false}
							</MDBRow>
							</div>
					</MDBContainer>
				</div>
			</div>
</MDBCollapse>
		</>

    );
  }
}

export default  withTranslation()(ProfilPageResponziv);
