import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import styles from '../../assets/scss/modules/chat.module.scss';

import ChatListMainBikeB2B from "./ChatList/ChatListMainBikeB2B";
import InterB2BAxios from '../../Service/InterB2BAxios';

import logo from "../../assets/media/logo/1.png";
import mailicon from "../../assets/media/newicons/mail.png";
import phoneicon from "../../assets/media/newicons/phone.png";
import sendmsgicon from "../../assets/media/newicons/sendmsg.png";
import galleryicon from "../../assets/media/newicons/gallery.png";
import attachicon from "../../assets/media/newicons/attach.png";
import folderopenicon from "../../assets/media/newicons/folder-open.png";
import smileysicon from "../../assets/media/newicons/smileys.png";
import trahsicon from "../../assets/media/newicons/trash.png";
import plusimgicon from "../../assets/media/newicons/plusImg.png";
import peoplewhiteicon from "../../assets/media/newicons/peoplewhite.png";

import{
	MDBContainer,
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
	MDBListGroup,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBBadge,
	MDBFileInput,
	MDBTooltip
} from 'mdbreact';

import Cookies from 'universal-cookie';


const cookies = new Cookies();
let chatroomid = '';
class ChatsMainBikeB2B extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
  	this.state = {rooms: [], messages: [], selectedChatroom: ''};
  };

  componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);z
    var params = window.location.href.split("/");
    let chatroomid = params[params.length - 1];
    this.props.addFunctionFromChild(this.onMessage);
    if (isFinite(chatroomid)){
      this.selectChatroom(undefined, chatroomid);
    }
    this.loadRooms();
  };

  componentWillUnmount() {
		this.interAxios.cancel();
    this.props.removeFunctionFromChild(this.onMessage);
  }

	handleChatClick = (chatroomid) => {
    this.selectChatroom(undefined, chatroomid);
  };

  loadRooms = () => {
    var self = this;
		this.interAxios.get('/api/chat/bike/roomsb2b', function(response)
		{
			self.setState({rooms: response.data});
		});
  };

	refreshAll = (chatroomid) => {
    this.loadRooms();
  };

	selectChatroom = (event, chatroomid) => {
    this.setState({selectedChatroom: chatroomid})
    this.refreshAll(chatroomid);
  };

	onMessage = (message) => {
		var params = window.location.href.split("/");
		let chatroomid = params[params.length - 1];
		let type = message.type;
		if (type === 'OTHER') {
			this.refreshAll();
		} else {
			let messageId = message.id;
			let messageChatRoomId = message.chatRoomId;
			if (this.state.selectedChatroom == messageChatRoomId) {
				var self = this;
				this.interAxios.get('/api/chat/messages/' + messageId, function(response)
				{
					let message = response.data;
					let messages = self.state.messages;
					messages.push(message);
					self.setState({messages: messages});
				});

			} else {
				this.loadRooms();
			}
		}
	};

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<MDBCard className="char-card-markt">
				<MDBCardBody className="pb-2 px-0 pt-0">
          <ChatListMainBikeB2B
              handleChatClick={this.handleChatClick}
              rooms = {this.state.rooms}
							userData = {this.props.userData}
							addFunctionFromChild= {this.props.addFunctionFromChild}
							removeFunctionFromChild= {this.props.removeFunctionFromChild}
							/>
				</MDBCardBody>
      </MDBCard>

    );
  }
}
export default withTranslation()(ChatsMainBikeB2B);
