import React, { Component, Fragment } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import TablePage from './TablePage';
import CreateOrderDTO from './CreateOrderDTO';
import Moment from 'moment';
import Footer from '../../components/Footer/Footer';
import logo from "../../assets/media/logo/1.png";
import ib2bicon from "../../assets/media/images/ib2b.png";
import styles from '../../assets/scss/modules/pages.module.scss';
import arrowcircleleft from "../../assets/media/newicons/arrow-circle-left.png";
import spinner from "../../assets/media/icons/Spinner-1s-200px.gif";
import {
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBContainer
} from 'mdbreact';

import InterB2BAxios from '../../Service/InterB2BAxios';

import Cookies from 'universal-cookie';

const cookies = new Cookies();


class PayPageOnGoing extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,
									paymentOngoing: true,
								};

  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
	  this.interval = setInterval(this.checkPaymentOngoing, 1000);
	}
	componentWillUnmount() {
	  clearInterval(this.interval);
	}

	checkPaymentOngoing = () => {
		let self = this;
		this.interAxios.get('/ecommerce/checkPaymentOngoing', function(response) {
			self.setState({ paymentOngoing: response.data });
		});
	}


  render() {
		const { t } = this.props;
		const client = this.props.client;
		if (this.state.paymentOngoing) {
	    return (
				<div className={styles.bodywrapper}>
					<MDBContainer>
						<MDBRow center>
							<h2 className="font-weight-bold">{t('laufende_zahlung')}</h2>
						</MDBRow>
						<MDBRow center>
							<MDBCard className="price-card final">
								<MDBCardBody className="text-center">
									<h1>{t('warten_sie_bitte_bis_zum_ende_der_zahlung')}</h1>
									<img src={spinner}/>
								</MDBCardBody>
							</MDBCard>
						</MDBRow>
						<Footer homeStatus={false}/>
					</MDBContainer>
				</div>
	    )
		} else {
			return (
				<Redirect to="/MyProfilePageB2B"/>
			);
		}
  }
}

export default withTranslation()(PayPageOnGoing);
