import React, { Component } from "react";
import EintraegeB2BDesktop from './EintraegeB2B/EintraegeB2BDesktop';
import EintraegeB2BResponziv from './EintraegeB2B/EintraegeB2BResponziv';


class EintraegeB2B extends Component {
  render() {
    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<EintraegeB2BDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<EintraegeB2BResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
	 		</>
    );
  }
}

export default EintraegeB2B;
