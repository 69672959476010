import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import logo from "../../assets/media/logo/1.png";
import loginicon from "../../assets/media/newicons/login.png";
import arrowcircleleft from "../../assets/media/images/b2b/arrow-circle-left.png";
import arrowcircleright from "../../assets/media/images/b2b/arrow-circle-right.png";
import group_13 from "../../assets/media/images/b2b/Group 13.png";
import FooterB2BSingUp from '../../components/Footer/FooterB2BSingUp';
import InterB2BAxios from '../../Service/InterB2BAxios';
import styles from '../../assets/scss/modules/signinB2B.module.scss';
import Cookies from 'universal-cookie';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn
} from 'mdbreact';

const cookies = new Cookies();

class SignInB2B extends Component {
  state = {

    isRemember: false,
		falseLogin: '',
    isRTL: Boolean(localStorage.getItem("rtl")),
		password: '',
    username: this.props.location?.state?.username !== undefined ? this.props.location?.state?.username : '',

  };
  constructor(props) {
  	super(props);
		this.interAxios = new InterB2BAxios(this, 'SignInB2B');
	}

  componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
    if (this.state.isRTL) document.body.setAttribute("dir", "rtl");
    else document.body.removeAttribute("dir");
  }

	componentWillUnmount() {
		//console.log("interAxios.cancel");
    this.interAxios.cancel();
  }

  toggleIsremember = (event) => {
    this.setState({ isRemember: event.target.checked });
  };

  onUsernameChange  = (event) => {
    this.setState({username: event.target.value});
  };

  onPasswordChange  = (event) => {
    this.setState({password: event.target.value});
  };

	setFrontendLanguage = (lang) => {
		i18next.changeLanguage(lang, (err, t) => {
			if (err) return console.log('something went wrong loading', err);
		});
	}

  onLoginFormSubmit  = (event) => {
    event.preventDefault();
    var self = this;
		this.interAxios.post('/api/auth/signin', {username: this.state.username, password: this.state.password, anmeldenfromMarkt: false}, function(response) {
				//console.log("onLoginFormSubmit: post result: " + response.data);
				if (response.data.language !== 'N/A') {
					self.setFrontendLanguage(response.data.language);
				}
				self.setState({falseLogin: ''});
				//this.props.userData.refresh();
				// Ezt a végére tettem, hogy ne legyen unmounted component frissítése (le tudjanak futni a hívások, mielőtt a redirect értelmezésre kerül). De még így is lehet, hogy időzítéstől függ a helyessége?
				self.props.userData.onSignIn(response.data);
				//console.log("onLoginFormSubmit ends.")
			}, function(error) {
				//console.log(error.response.data);
				if (error.response?.data?.message !== undefined && error.response?.data?.message !== '') {
					self.setState({falseLogin: error.response.data.message});	
				} else {
					self.setState({falseLogin: 'Wrong username or password.'});
				}
				self.setState({username: '', password: ''});
			});
  };


  onNewUserCreated = (username) => {
    this.setState({username: username});
  };

  render() {
		//console.log("render (loggedIn: " + this.props.userData.isLoggedIn() + ")");
		const { t } = this.props;
		const client = this.props.client;
    return (
	<div className={styles.bodywrapper1}>
	<div className="rectangle-16"/>
      <MDBContainer className="signin-container">
			<MDBRow center className="signin-row-padding">
				<MDBCol sm="6" md="6" lg="4">
					<MDBRow start className="pb-5 m-0">
					<Link to="/">
						<img className="vuesax-linear-arrow-circle-lef" src={arrowcircleleft}></img>
					</Link>
					</MDBRow>
					<MDBRow start className="pb-4 m-0">
					<img className="group_11" src={group_13}></img>
					</MDBRow>
					<MDBRow start className="pb-4 m-0">
					<span className="sign-up-to-inter-b-2-bi">{t('Melden_Sie_sich_bei_Ihrem_InterB2Bike_Konto_an')}</span>
					</MDBRow>
          <form className="signinb2b-form">
					<div className="error-signin-b2b">{t(this.state.falseLogin)}</div>
					<MDBInput
						className="signinb2b-input"
						label= {t('benutzername')}
						placeholder={t('geben_sie_ihren_benutzernamen ein')}
						type="text"
						validate error="wrong"
						success="right"
						value={this.state.username}
						onChange={this.onUsernameChange}
                    />
					<MDBInput
						className="signinb2b-input"
						label= {t('passwort')}
						type="password"
						validate
						placeholder={t('geben_sie_ihr_passwort_ein')}
						value={this.state.password}
						onChange={this.onPasswordChange}
					/>

					<MDBBtn
							className="signup-btn"
							to={this.props.userData.partner() === "Dealer" ? '/MainPageHandler' : this.props.userData.partner() === "Supplier" ? '/MainPageHersteller' : '/'}
							type="submit"
							onClick={this.onLoginFormSubmit}
					>{t('einloggen')}</MDBBtn><br/>

					<Link to="/ForgottenPasswordB2B">
						<span className="cant-sign-in pl-0 py-3 text-uppercase">{t('passwort_vergessen')}</span>
					</Link><br/>
					<Link to="/SignUpHandler">
						<span className="cant-sign-in pl-0 py-3 text-uppercase">{t('registrieren')}</span>
					</Link>
					{ this.props.userData.isLoggedIn() ?
							<Redirect to={this.props.userData.partner() === "Dealer" ? '/MainPageHandler' : this.props.userData.partner() === "Supplier" ? '/MainPageHersteller' : '/'}
						/>:
					false
					}
				</form>


          </MDBCol>
          <MDBCol sm="6" md="6" lg="8" className=".d-none .d-sm-block">
          </MDBCol>
        </MDBRow>
      </MDBContainer>
			<div className="rectangle-15">
				<MDBContainer style={{maxWidth: "1250px"}}>
					<FooterB2BSingUp/>
				</MDBContainer>
			</div>
	</div>
    );
  }
}

export default withTranslation()(SignInB2B);
