import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import salonicon from '../../assets/media/newicons/salon.png';
import meinprofilicon from '../../assets/media/newicons/meinprofil.png';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import{
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBContainer,
	MDBRow,
	MDBCol,
} from 'mdbreact';




// Top  Re-usable Action Component
class ChatAction extends Component {
	constructor(props) {
		super(props);
		this.state = {currentCompanyIsEmpty: cookies.get('authtoken').currentCompanyIsEmpty};
	};

  toggleNewChat = () => {
		this.props.onPcHeaderActive();
		//this.props.onZindex();
  };

  toggleCreateGroup = () => {
		this.props.onGcHeaderActive();
		//this.props.onZindex();
  };

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
<>
	<MDBRow>
		<MDBCol size="6" sm="6" md="6" lg="6" xl="6" className="text-center">
		{!this.state.currentCompanyIsEmpty ?
			<button className="btn chat-add-btn groupchat-add-btn d-inline-flex flex-row flex-nowrap justify-content-center" onClick={this.toggleCreateGroup}>+ {t('gruppe')}<span><img src={salonicon} /></span></button>:
			<button className="btn chat-add-btn groupchat-add-btn d-inline-flex flex-row flex-nowrap justify-content-center" >+ {t('gruppe')}<span><img src={salonicon} /></span></button>}
		</MDBCol>
		<MDBCol size="6" sm="6" md="6" lg="6" xl="6" className="text-center">
		{!this.state.currentCompanyIsEmpty ?
			<button className="btn chat-add-btn d-inline-flex flex-row flex-nowrap justify-content-center" onClick={this.toggleNewChat}>+ Chat<span><img src={meinprofilicon} /></span></button>:
			<button className="btn chat-add-btn d-inline-flex flex-row flex-nowrap justify-content-center" >+ Chat<span><img src={meinprofilicon} /></span></button>}
		</MDBCol>
	</MDBRow>

</>
    );
  }
}
export default withTranslation()(ChatAction);
