import React, { Component } from 'react'

import { Jutsu } from 'react-jutsu'
import partners from "../../assets/media/images/Partners.png";
import ib2bicon from "../../assets/media/images/ib2b.png";
import styles from '../../assets/scss/modules/jitsi.module.scss';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import FooterPublic from '../Footer/FooterPublic';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar
} from 'mdbreact';

import InterB2BAxios from '../../Service/InterB2BAxios';

class SalonMeetingPage extends React.Component {

  state = {
    jitsiLoaded: false,
    userNameLoaded: false,
    companyNameLoaded: false,
		meetingInfoLoaded: false,
		meetingOnline: true,
  };

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
  }

  componentDidMount() {
    console.log(this.props.match?.params?.id);
		//this.interAxios.refreshUserData(this.props.userData);
    let self = this;
    this.interAxios.get('/api/myCompany', function(response) {
          self.setState({companyName: response.data.name, companyNameLoaded: true});
    });
    this.interAxios.get('/api/user/me', function(response) {
          self.setState({userName: response.data.name, userNameLoaded: true});
    });
		this.interAxios.get('/api/salon/jitsimeetinginfo?link=' + this.props.match?.params?.id, function(response) {
//					console.log(response.data);
					self.setState({jitsiToken: response.data.token, duration: response.data.duration, meetingInfoLoaded: true});
		})

    let scriptTag = document.getElementById("jitsiJsTag");
    if (scriptTag !== undefined) {
      const script = document.createElement("script");
      script.src = "https://meet.jit.si/external_api.js";
      script.async = false;
      script.id = "jitsiJsTag"
      script.onload = () => { self.setState({jitsiLoaded: true}); }
      document.body.appendChild(script);
    }
		this.props.onShowNavbar();

  }

  componentWillUnmount() {
    let scriptTag = document.getElementById("jitsiJsTag");
//    console.log(scriptTag);
    if (scriptTag !== null) {
      document.body.removeChild(scriptTag);
    }
		this.props.onHideNavbar();
  }

	onMeetingEnded = () => {
		this.setState({meetingOnline: false});
	}

  render() {
		const { t } = this.props;
		const client = this.props.client;
		let self = this;
		let jitsiheight = 100;
		if(document.getElementById("container") !== null){
			jitsiheight = document.getElementById("container").clientWidth * 5/8;
		}
		console.log(this.state.jitsiLoaded);
		console.log(this.state.userNameLoaded);
		console.log(this.state.companyNameLoaded);
		console.log(this.state.tokenLoaded);
    return (
      <>
        <div className={styles.bodywrapper}>
          <MDBContainer>
					<MDBRow center className="pb-4">
					<img className="netzwerk-ib2b w-25" src={ib2bicon}/>
					</MDBRow>
					<MDBRow>
					<MDBCol md="3" middle>
						<img style={{width: "115%"}} src={partners}/>
					</MDBCol>
					<MDBCol md="9">
					<MDBCard id="container" className="p-2" style={{borderRadius: "10px"}}>
            {this.state.jitsiLoaded && this.state.userNameLoaded && this.state.companyNameLoaded && this.state.meetingInfoLoaded && this.state.meetingOnline ?

              <Jutsu
                roomName={'vpaas-magic-cookie-e83f94493f934b5281f283c195d3196a/' + this.props.match?.params?.id}
                onMeetingEnd={() => console.log('Meeting has ended')}
                loadingComponent={<p>loading ...</p>}
								configOverwrite={{prejoinPageEnabled: false}}
								containerStyles={{height: jitsiheight, width: '100%'}}
								domain='8x8.vc'
								jwt={this.state.jitsiToken}
								onJitsi={(api) => { setTimeout(() => {
									self.setState({meetingOnline: false});
								}, self.state.duration)} }
                 />
            : (!this.state.meetingOnline) ?
							<div>{t('die_sitzung_ist_beendet')}</div>
						: false}
						</MDBCard>
						</MDBCol>
						</MDBRow>

						<div className="pt-4">
						<FooterPublic homeStatus={false} public={true}/>
						</div>
          </MDBContainer>
        </div>
      </>
    );
  }
}

export default withTranslation()(SalonMeetingPage);
