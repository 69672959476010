import React, { Component, Fragment } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
import foto2 from "../../assets/media/fotos/2.jpg";
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import GenericModal from '../Jitsi/GenericModal.js';
import ImageManager from './ImageManager';
import MapBox from '../MapBox/MapBox';
import Moment from 'moment';
import 'moment/locale/de';
import logo from "../../assets/media/logo/1.png";
import loginicon from "../../assets/media/newicons/login.png";
import user_profile_icon from "../../assets/media/newicons/user_profile_icon.png";
import trahsicon from "../../assets/media/newicons/trash.png";
import checkdone from "../../assets/media/newicons/check_done.png";
import arrowcirclelefticon from "../../assets/media/newicons/arrow-circle-left2.png";
import galleryicon from "../../assets/media/newicons/gallery.png";
import plusimgicon from "../../assets/media/newicons/plusImg.png";
import editicon from "../../assets/media/newicons/editProfil.png";
import logouticon from "../../assets/media/newicons/logout.png";
import ib2bicon from "../../assets/media/images/ib2b.png";
import mintakep from "../../assets/media/images/group2.png";
import styles from '../../assets/scss/modules/pages.module.scss';
import arrowLeft from "../../assets/media/images/arrowLeft.png";
import Footer from '../Footer/Footer';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';


import{
	MDBBtn,
	MDBRow,
	MDBCol,
	MDBCard,
  MDBContainer,
	MDBInput
} from 'mdbreact';

import InterB2BAxios from '../../Service/InterB2BAxios';

const cookies = new Cookies();

class MyUserPage extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {falseLogin: '',
									updateControl: false
              	};
  };

	componentDidMount() {
    this.props.userData.refreshNavbar();
		//this.interAxios.refreshUserData(this.props.userData);
		this.onMe();
  };

  onMe = () => {
    var self = this;
    this.interAxios.get('/api/user/me', function(response) {
        self.setState({name: response.data.name, email: response.data.email});
      })
  };

	onLoginFormSubmit  = () => {
    var self = this;
		this.interAxios.post('/api/auth/signin', {username: this.state.username, password: this.state.password}, function(response) {
		    cookies.set('authtoken', {
						token: response.data.accessToken,
						userId: response.data.id,
						name: response.data.name,
						currentCompanyId: response.data.companyId,
						sysAdmin: response.data.sysAdmin,
						gastAdmin: response.data.gastAdmin,
					}/*, {
						 sameSite: 'None',
						 secure: true
					}*/
				);
			}, function(error) {
			});
  };

	onControlUser  = () => {
		event.preventDefault();
		var self = this;
		this.interAxios.post('/api/auth/validUser', {username: this.state.username, password: this.state.password}, function(response) {

					self.setState({falseLogin: ''});
					self.setState({updateControl: response.data});
			}, function(error) {
				self.setState({falseLogin: t('falscher_benutzername_oder_falsches_passwort')});

			});
	};

	onUpdateMyUser = (event) => {
		event.preventDefault();
    let self = this;
		if (this.state.password !== this.state.firstPassword || this.state.password === undefined || this.state.firstPassword === undefined) {
			console.log(this.state.password + ", " + this.state.firstPassword);
			this.openWarningModal(t('die_passwörter_die_sie_eingegeben'))
		} else {
    this.interAxios.post('/api/auth/userupdate',
		   {
				 name: this.state.name,
				 username: this.state.username,
				 email: this.state.email,
				 password: this.state.password
			 }, function(response) {
			 self.onLoginFormSubmit();
		 })
	 }
  }

	onNameChange = (event) =>{
		this.setState({name: event.target.value});
	};

	onUsernameChange = (event) =>{
		this.setState({username: event.target.value});
	};

	onEmailChange = (event) =>{
		this.setState({email: event.target.value});
	};

	onPasswordChange= (event) =>{
		this.setState({password: event.target.value});
	};

	onFirstPasswordChange  = (event) => {
		this.setState({firstPassword: event.target.value});
	};

  onSecondPasswordChange  = (event) => {
    this.setState({secondPassword: event.target.value});
  };

	openWarningModal = (text) => {
		this.setState(
			{
				warningModalText: text,
				warningModalShow: true
			}
		);
	}

	closeWarningModal = () => {
		this.setState({warningModalShow: false})
	}



  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
      	<div className={styles.bodywrapper}>
				<MDBContainer>
				{!this.state.updateControl ?
				<>
					<MDBRow center>
							<img alt="" className="userpage-logo" src={logo}></img>
					</MDBRow>
					<MDBRow center>
							<img alt="" className="userpage-b2bicon py-4" src={ib2bicon}></img>
					</MDBRow>
				<MDBCard className="interb2b-card p-4">
				<MDBRow center>
					<h3 className="userpage-title font-weight-bolder py-4">{t('geben_sie_ihren_aktuellen_benutzernamen')}</h3>
				</MDBRow>
				<MDBRow center>
				{this.state.falseLogin !== '' ?
				<h5 className="alert alert-danger">{this.state.falseLogin}</h5>:false}
								<form className="signin-form">

					<MDBInput
						className="signin-input"
						id="userprofil"
						label= {t('benutzername')}
						placeholder={t('geben_sie_ihren_benutzernamen ein')}
						type="text"
						validate error="wrong"
						success="right"
						value={this.state.username}
						onChange={this.onUsernameChange}
										/>
					<MDBInput
						className="signin-input"
						id="userprofil"
						label= {t('passwort')}
						type="password"
						validate
						placeholder={t('geben_sie_ihr_passwort_ein')}
						value={this.state.password}
						onChange={this.onPasswordChange}
					/>


					<div className="pt-4 text-center">
					<MDBBtn
							className="userpage-btn"
							color="primary"
							type="submit"
							onClick={this.onControlUser}
							>Done <img src={loginicon} /></MDBBtn>
					</div>

				</form>
				</MDBRow>

        </MDBCard></>:<>
				<form className="signin-form">
				<MDBCard className="interb2b-card px-4 pt-4 pb-0" style={{backgroundColor: "transparent"}}>
				<MDBRow>
					<MDBCol md="2" size="6">
						<MDBRow start>
								<MDBCard className="userprofile-card">
								<MDBRow className="row-width">
								<MDBCol middle md="5" size="4" className="pr-2 pl-0">
									<img alt="" className="userpage-userprofileicon py-0" src={user_profile_icon}></img>
								</MDBCol>
								<MDBCol middle md="7" size="7" className="pl-2 pr-0">
										<span className="card-text text-capitalize">{this.state.username}</span>
								</MDBCol>
								</MDBRow>
								</MDBCard>
						</MDBRow>
					</MDBCol>
					<MDBCol md="7" size="6" className="pl-3">
					<MDBRow start>
						<h3 className="userpage-title-andern pt-2">{t('change_user_data')}</h3>
					</MDBRow>
						<MDBRow start>
								<img alt="" className="userpage-b2bicon py-0" src={ib2bicon}></img>
						</MDBRow>
					</MDBCol>
					<MDBCol md="3" className="userpage-btn-col">
						<MDBRow end>
							<MDBBtn
							 className="userpage-btn"
							 color="primary"
							 type="submit"
							 onClick={(event) => this.onUpdateMyUser(event)}
							 >{t('update')}</MDBBtn>
				 	 	</MDBRow>
					</MDBCol>
				</MDBRow>
				<MDBRow start>
						<h3 className="userpage-text-andern py-4">{t('change_your_profile_settings_here')}</h3>
				</MDBRow>
			</MDBCard>

			<MDBCard className="interb2b-card p-4">

				<MDBRow center>
					<MDBCol md="2">
					</MDBCol>
					<MDBCol md="4">
					<MDBRow center>
					<MDBInput
						className="signin-input"
						id="userprofil"
						placeholder={t('geben_sie_ihren_namen_ein')}
						label= {t('name')}
						type="text"
						validate error="wrong"
						success="right"
						value={this.state.name}
						onChange={this.onNameChange}
						/>
					<MDBInput
						className="signin-input"
						id="userprofil"
						placeholder={t('geben_sie_ihren_benutzernamen ein')}
						label= {t('benutzername')}
						type="text"
						validate error="wrong"
						success="right"
						value={this.state.username}
						onChange={this.onUsernameChange}
						/>
					<MDBInput
						className="signin-input"
						id="userprofil"
						placeholder={t('geben_sie_ihre_e_mail_adresse_ein')}
						label= {t('email')}
						type="text"
						validate error="wrong"
						success="right"
						value={this.state.email}
						onChange={this.onEmailChange}
						/>
				</MDBRow>
				</MDBCol>
				<MDBCol md="4" bottom>
				<MDBRow center>
						<h3 className="userpage-text">{t('geben_sie_ihr_aktuelles_passwort_ein_oder_geben')}</h3>
					<MDBInput
						className="signin-input"
						id="userprofil"
						label= {t('passwort')}
						type="password"
						validate
						placeholder={t('geben_sie_ihr_passwort_ein')}
						value={this.state.password}
						onChange={this.onPasswordChange}
					/>
					<MDBInput
						className="signin-input"
						placeholder={t('passwort_bestatigen')}
						label={t('passwort')}
						type="password"
						validate error="wrong"
						success="right"
						value={this.state.firstPassword}
						onChange={this.onFirstPasswordChange}
					/>
				</MDBRow>
				</MDBCol>
				<MDBCol md="2" bottom>
				</MDBCol>
			</MDBRow>

			</MDBCard></form></>}
					<Footer homeStatus={false}/>
			</MDBContainer>
			<GenericModal
				 show={this.state.warningModalShow}
				 config={{
					 modalTitle: t('achtung'),
					 okButton: {
						 show: false
					 }
				 }}
				 handleClose={this.closeWarningModal}
			>
				 <div>{this.state.warningModalText}</div>
			</GenericModal>
			</div>
    );
  }
}

export default withTranslation()(MyUserPage);
