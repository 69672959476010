import React, { Component } from "react";

import { ReactComponent as SearchSvg } from "../../assets/media/icons/search.svg";
import Cookies from 'universal-cookie';
import{
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBContainer,
	MDBModalFooter,
	MDBBtn,
  MDBFileInput,
  MDBSelect
} from 'mdbreact';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const cookies = new Cookies();

class Ckeditor extends Component {
  state = {
            token : cookies.get('authtoken').token,
            myUserId: cookies.get('authtoken').userId
						};


  render() {

		let initialContent = 'Hello';
    return (
      <>
					<CKEditor
                     editor={ ClassicEditor }
                     data="<p></p>"
                     onReady={ editor => {
                     } }
                     onChange={ ( event, editor ) => {
                         const data = editor.getData();
												 this.props.onKommentarChange(data);
                     } }
                     onBlur={ ( event, editor ) => {
                     } }
                     onFocus={ ( event, editor ) => {
                     } }
                 />
    </>
    );
  }
}
export default Ckeditor;
