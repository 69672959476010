import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next';
import InterB2BAxios from '../Service/InterB2BAxios';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
import styles from '../assets/scss/modules/signin.module.scss';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn
} from 'mdbreact';

const cookies = new Cookies();

class ResetPassword extends Component {
  state = {
		state: 0,
    firstPassword: '',
		secondPassword: '',
		token: ''
  };

	constructor(props) {
      super(props);
			this.interAxios = new InterB2BAxios(this);
			// TODO URLSearchParams 2017-2018-as tamogatott!!!
			let url = new URL(window.location.href);
			let params = new URLSearchParams(url.search);
			var self = this;
			this.interAxios.get('/api/auth/password/validate', { params: {token: params.get("token")}}, function(response) {
				self.setState({token: params.get("token")});
				let valid = response.data;
				//console.log('valid: ' + valid);
				if (valid) {
					self.setState({state: 1});
				} else {
					self.setState({state: 2});
				}
		}, function(error) {
			//console.log(error);
			self.setState({state: 2});
		});
		/*	axios.get(process.env.REACT_APP_BACKEND_URL+'/api/auth/password/validate',
						{ params: {token: params.get("token")}})
			.then(function(response) {
				self.setState({token: params.get("token")});
				let valid = response.data;
				console.log('valid: ' + valid);
				if (valid) {
					self.setState({state: 1});
				} else {
					self.setState({state: 2});
				}
		})
			.catch(function(error) {
				console.log(error);
				self.setState({state: 2});
			})*/
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}


	onFirstPasswordChange  = (event) => {
		this.setState({firstPassword: event.target.value});
	};

  onSecondPasswordChange  = (event) => {
    this.setState({secondPassword: event.target.value});
  };

	updatePassword = (event) => {
		event.preventDefault();
		if (this.state.firstPassword !== this.state.secondPassword) {
			// validation
		} else {
				var self = this;
				this.interAxios.get('/api/auth/password/update', { params: {token: this.state.token, password: this.state.firstPassword}}, function(response) {
					self.setState({redirect: true});
				});
				/*axios.get(process.env.REACT_APP_BACKEND_URL+'/api/auth/password/update',
							{ params: {token: this.state.token, password: this.state.firstPassword}})
				.then(function(response) {
					self.setState({redirect: true});
			})
			.catch(function(error) {
				console.log(error);
			})*/
		}
	}

	backToSignIn = () => {
		this.setState({redirect: true});
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<div className={styles.bodywrapper}>
					<MDBContainer className="signin-container">
					<MDBRow>
								<MDBCol md="4">
								<h3 className={styles.pagetitle}>{t('neues_passwort_erstellen')}</h3>
								{this.state.state === 1 ?
							<form className="signin-form">

								<MDBInput
									className="signin-input"
									placeholder={t('neues_passwort_eingeben')}
									label={t('passwort')}
									type="password"
									validate error="wrong"
									success="right"
									value={this.state.firstPassword}
									onChange={this.onFirstPasswordChange}
								/>
								<MDBInput
									className="signin-input"
									placeholder={t('neues_passwort_bestätigen')}
									label={t('passwort')}
									type="password"
									validate error="wrong"
									success="right"
									value={this.state.secondPassword}
									onChange={this.onSecondPasswordChange}
								/>

								<MDBBtn
									className="signin-btn"
									to="/SignIn"
									color="primary"
									type="submit"
									onClick={this.updatePassword}
									>{t('absenden')}</MDBBtn>

								{ this.state.redirect === true ?
									<Redirect to='/SignIn'
									/>:
									false
													}
								</form>
									:
									this.state.state === 2 ?
									<>
									<div>{t('ungültiges_token')}</div>
									<div className="text-center">
										<MDBBtn
											to="/SignIn"
											color="primary"
											type="submit"
											onClick={this.backToSignIn}
										>{t('zurück')}</MDBBtn>
									</div>
									{ this.state.redirect === true ?
											<Redirect to='/SignIn'
										/>:
									false
									}
									</>
									: false}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
			</div>
    );
  }
}

export default withTranslation()(ResetPassword);
