import React, { Component } from "react";
import ReactDOM from 'react-dom';
import styles from '../../assets/scss/modules/boersepage.module.scss';
import logo from "../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import bike_info from "../../assets/media/images/markt/bike-info.png";
import rotate from "../../assets/media/images/markt/rotate.png";

import{
	MDBContainer,
	MDBRow,
	MDBCol
} from 'mdbreact';

class LandingResponsivPage extends Component {

	constructor(props) {
    super(props);
    this.state = {};
  };

	componentDidMount() {
	}

	componentWillUnmount() {
	}

  render() {
    return (
			<>
			<div className={styles.bodywrapper3}>
				<MDBContainer fluid className="mx-0 px-0">
					<MDBRow center className="m-0 pb-3">
						<img className="bike-info" src={bike_info}/>
					</MDBRow>
					<MDBRow center className="m-0 pb-3">
						<img className="bike-info-rotate" src={rotate}/>
					</MDBRow>
					<MDBRow center className="m-0 pb-1">
						<span className="landscape-title">Landscape mode not available!</span>
					</MDBRow>
					<MDBRow center className="m-0">
						<span className="landscape-text">Please use your device in portrait mode.</span>
					</MDBRow>
				</MDBContainer>
			</div>
	 		</>
 	);
 }
 }

 export default  LandingResponsivPage;
