import React from 'react';
import logo from "../../assets/media/logo/1.png";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { UserStatus } from "../../Config/Config";
import { Link } from "react-router-dom";
import InterB2BAxios from '../../Service/InterB2BAxios';

import{
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
	MDBCardHeader,
	MDBListGroupItem,
  MDBTooltip
} from 'mdbreact';


import Cookies from 'universal-cookie';

const cookies = new Cookies();
class CompaniesList extends React.Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {
      open: false,
      token : cookies.get('authtoken').token,
      myUserId: cookies.get('authtoken').userId,
			sysAdmin: cookies.get('authtoken').sysAdmin,
			gastAdmin: cookies.get('authtoken').gastAdmin,
			profileImageHash: Date.now()
    };
  };
  componentDidMount() {
  };
	componentWillUnmount() {
		this.interAxios.cancel();
	}

	onFollowed = (id) => {
		let self = this;
		this.interAxios.get('/api/addFollowedCompany/'+id, function(response) {});
/*		axios.get(process.env.REACT_APP_BACKEND_URL+'/api/addFollowedCompany/'+id,

				{
				headers:
						{'Authorization': 'Bearer ' + this.state.token}
				}
		)
		.then(function(response) {

		})
		.catch(function(error) {
				console.log(error);
		})*/
	};

	onSelectCompany = (id) => {
			this.setState({aktuelleCompanyId: id});
	}

	truncateString = (str, num) => {
		if(str !== null){
			if (str.length <= num) {
				return str
	  	}
	  			return str.slice(0, num) + '...'
		}
	}



/*  handleClick = () =>{
    this.setState( (prevState: { open: any; }) => ({
      open: !prevState.open
    }))
  }*/
  render() {
		const { t } = this.props;
    const client = this.props.client;
		let status = '';

		switch (this.props.msg.status) {
			case UserStatus.Online:
				status = "avatar avatar-sm avatar-online";
				break;
			case UserStatus.Offline:
				status = "avatar avatar-sm avatar-offline";
				break;
			default:
				break;
		}
    return (
<MDBListGroupItem>
	<div className="d-flex flex-row flex-md-column flex-lg-row w-100 px-1 align-items-center">
		<div className={status}>
			<img alt="" className="photo" src={this.props.msg.profileUrl +'?' + Date.now()}></img>
		</div>
		<div className="pl-2">
		{this.props.msg.name.length>14 ?
		<MDBTooltip
		 domElement
		 tag="small"
		 material
		 placement="top"
	 >
        	<small  className="font-weight-bold text-capitalize ">{this.truncateString(this.props.msg.name, 14)}</small>
					<small  className="font-weight-bold text-capitalize ">{this.props.msg.name}</small>
	 </MDBTooltip>:
	 <small  className="font-weight-bold text-capitalize ">{this.props.msg.name}</small>}
	 {/*

	*/}
		</div>
    </div>
	<div className="text-center">
			{!this.state.gastAdmin ?
			<a onClick = {(event) => this.onFollowed(this.props.msg.id)} className="text-capitalize font-weight-lighter" style={{color: "#0360EC"}}>{t('folgen')}</a>:
			<span className="text-capitalize font-weight-lighter text-muted">{t('folgen')}</span>}
	</div>
</MDBListGroupItem>
    );
  }
}

export default withTranslation()(CompaniesList);
