import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import logo3 from "../../assets/media/images/markt/profil/Group 1.png";
import CreateImageProfile from '../Modals/CreateImageProfile'
import InterB2BAxios from '../../Service/InterB2BAxios';
import FooterMarktLanding from '../Footer/FooterMarktLanding';
import SonstigeNav from '../NavbarComponent/SonstigeNav';
import ProfilpageNav from '../NavbarComponent/ProfilpageNav';
import styles from '../../assets/scss/modules/profilpage.module.scss';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
const myregexp = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.*([^\s]{11})/gm;
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBIcon,
	MDBDatePicker
} from 'mdbreact';

const containerStyle = {
  width: '100%',
  height: '280px'
};
let language = '';
class Transaktionen extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefInterB2B = React.createRef();
		this.listInnerRefNews = React.createRef();
		this.listInnerRefInfo = React.createRef();
		this.listInnerRefContact = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'ProfilInfo');
    this.state = {redirect: false,
									myUser: {},
								  latitude: "",
									longitude: "",
								  standort: this.props.standort ?? '',
									};
  };

	componentDidMount() {

		//this.interAxios.refreshUserData(this.props.userData);
		var params = window.location.href.split(".");
	  language = params[params.length - 1];
		this.onMyUser();
		let self = this;
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	onMyUser = () => {
		var self = this;
		this.interAxios.get('/api/user/me', function(response) {
      self.setState({myUser: response.data,
										 vorname: response.data.vorname ?? "",
										 nachname: response.data.nachname ?? "",
										 anzeigename: response.data.anzeigename ?? "",
										 benutzername: response.data.username ?? "",
										 standort: response.data.standort ?? "",
										 privatetelefon: response.data.privatetelefon ?? "",
										 publictelefon: response.data.publictelefon ?? "",
										 aboutus: response.data.aboutUs ?? "",
										 birthday: response.data.birthday ?? Date.now(),
									 });
    });
	};



	changeHandler = event => {
     this.setState({ [event.target.name]: event.target.value });
   };

	 onUserUpdate = (event) => {
		 const form = event.currentTarget;
		 event.preventDefault();
		 event.target.className += " was-validated"
		 if((form.checkValidity() !== false)){
			 let self = this;
			 const formData = new FormData
			 formData.append("name", this.state.vorname + " " + this.state.nachname);
			 formData.append("anzeigename", this.state.anzeigename);
			 formData.append("benutzername", this.state.benutzername);
			 formData.append("standort", this.state.standort);
			 formData.append("privatetelefon", this.state.privatetelefon);
			 formData.append("publictelefon", this.state.publictelefon);
			 formData.append("aboutus", this.state.aboutus);
			 //formData.append("birthday", this.state.birthday);
			 this.interAxios.put('/api/auth/userupdateMarkt', formData, {
				 headers: {
					 'Content-Type': 'multipart/form-data'
				 }
			 }, function(response) {
				 	self.setState({redirect: true});
			 })
		 }
	 };

	 onUpdateLatLng = () => {
 		this.interAxios.get('/api/updatelatlong/' + 50, function(response) {
 		})
 	};

	handleShowProfile = () => {
    this.setState({showProfile: true,
                   minWidth: 200,
                   width: 200,
                   maxWidth: 200,
                   aspect: 1,
									 aboutus: "",
                   bildName: 'Profile'});
  };
	handleCloseProfile = () => {
    this.setState({showProfile: false});
		//self.props.userData.refresh();
  };

	getPickerValue = (value) => {
    this.setState({birthday: value});
  };

	onArticleChange = (editorState) => {
		let text = '';
		if (editorState.match(myregexp)){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(editorState.match(myregexp), 'text/html');
			text =parsedHtml.documentElement.textContent;

		}
		this.setState({aboutus: editorState.replace(editorState.match(myregexp), '')});

	}

  render() {
		console.log(this.state.birthday);
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
				<SonstigeNav userData={this.props.userData}/>

				<div className={styles.bodywrapper}>
					<div className="profil-background">
						<MDBContainer style={{maxWidth: "1250px"}}>
							<MDBRow className="pt-4">
								<MDBCol md="auto" className="pr-4">
									<ProfilpageNav activeItem = {"5"} userData={this.props.userData}/>
								</MDBCol>
								<MDBCol className="px-5">
								<MDBRow start className="pt-0 pb-3 m-0">
									<img alt="" className="profil-title-logo" src={logo3}></img>
								</MDBRow>
									<p className="">Ein Teil dieser Informationen sind für alle E-Bikebörse Mitglieder sichtbar <a href="#">auf deiner Profilseite.</a></p>
									<form className="row g-3 needs-validation" onSubmit={async (event) => {await this.onUserUpdate(event);}} noValidate>
										<div className="col-md-12">
											<div className="form-outline-markt">
													<label htmlFor="validationCustom01" className="form-label pt-2">Vorname</label>
													<input type="text" className="form-control text-muted" name="vorname" onChange={this.changeHandler} value={this.state.vorname} id="validationCustom01"/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-outline-markt">
													<label htmlFor="validationCustom02" className="form-label pt-2">Nachname   <span className=""> (anderen Benutzern wird nur der erste Buchstabe angezeigt)</span></label>
													<input type="text" className="form-control text-muted" name="nachname" onChange={this.changeHandler} value={this.state.nachname} id="validationCustom02"/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-outline-markt">
											<label htmlFor="validationCustom03" className="form-label pt-2">Anzeigename</label><br/>
											<MDBRow className="m-0 p-0">
												<MDBCol md="auto" className="pl-0 pr-1">
													<MDBIcon icon="info-circle" />
												</MDBCol>
												<MDBCol className="pl-1 pr-0">
													<p className="form-anmerkung">Wenn du eine Organisation vertrittst, kannst du den Namen deiner Organisation als Anzeigename verwenden.
													Der Anzeigename wird den anderen Nutzern statt deines Vor- und Nachnamens angezeigt.</p>
												</MDBCol>
											</MDBRow>
													<input type="text" className="form-control text-muted" name="anzeigename" onChange={this.changeHandler} value={this.state.anzeigename} id="validationCustom03"/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-outline-markt">
											<label htmlFor="validationCustom04" className="form-label pt-2">Benutzername</label><br/>
											<MDBRow className="m-0 p-0">
												<MDBCol md="auto" className="pl-0 pr-1">
													<MDBIcon icon="info-circle" />
												</MDBCol>
												<MDBCol className="pl-1 pr-0">
													<p className="form-anmerkung">Dein Benutzername wird als Teil der einzigartigen URL-Adresse deines Profils verwendet.
													Du kannst nur Buchstaben und Zahlen für deinen Benutzernamen verwenden, ohne Leerzeichen.
													Wenn du deinen Benutzernamen änderst, wird deine URL-Adresse automatisch geändert und deine vorherige
													URL-Adresse wird nicht automatisch auf dein neues Profil weitergeleitet.
													Der alte Benutzername, den du verwendet hast, kann danach von anderen Benutzern genutzt werden.</p>
												</MDBCol>
											</MDBRow>
													<input type="text" className="form-control text-muted" name="benutzername" onChange={this.changeHandler} value={this.state.benutzername} id="validationCustom04"/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-outline-markt">
											<label htmlFor="validationCustom05" className="form-label pt-2">Standort  <span className=""> (wird nur beim Erstellen eines neuen Eintrags als Standardeinstellung benutzt)</span></label><br/>
											<MDBRow className="m-0 p-0">
												<MDBCol md="auto" className="pl-0 pr-1">
													<MDBIcon icon="info-circle" />
												</MDBCol>
												<MDBCol className="pl-1 pr-0">
													<p className="form-anmerkung">Du kannst entweder deinen exakten Standort oder nur deine Postleitzahl angeben.
													Es ist eine gute Idee, das Land auch einzugeben. Beispiele: "10117 Berlin, Deutschland" oder "2000 Sand Hill Road, CA, USA". </p>
												</MDBCol>
											</MDBRow>
													<input type="text" className="form-control text-muted" name="standort" onChange={this.changeHandler} value={this.state.standort} id="validationCustom05"/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-outline-markt">
											<label htmlFor="validationCustom06" className="form-label pt-2">Telefonnummer  <span className=""> (wird anderen Benutzern nicht angezeigt)</span></label><br/>
													<input type="text" className="form-control text-muted" name="privatetelefon" onChange={this.changeHandler} value={this.state.privatetelefon} id="validationCustom06"/>
											</div>
										</div>
										<div className="col-md-12">
										<label htmlFor="validationCustom06" className="form-label pt-2">Profilbild</label><br/>
										<MDBRow className="m-0 p-0">
											<MDBCol md="auto" className="pl-0 pr-1">
												<MDBIcon icon="info-circle" />
											</MDBCol>
											<MDBCol className="pl-1 pr-0">
												<p className="form-anmerkung">Das Bild sollte ein quadratisches Seitenverhältnis haben (1:1),
												zum Beispiel 800x800 Pixel. Ansonsten wird es beschnitten. </p>
											</MDBCol>
										</MDBRow>
										{this.state.myUser.id!== undefined ?
											<img className="profilpage-image"
												src={this.state.myUser.profileUrl +'?' + Date.now()}
												onClick={this.handleShowProfile}
											/>:false}
										</div>


										<div className="col-md-12">
											<div className="form-outline-markt pt-2">
												<label htmlFor="validationCustom03" className="form-label pt-2">Über dich</label><br/>
												<div className="ckeditor-padding">
												<CKEditor
						            	editor={ ClassicEditor }
													data={this.state.aboutus}
													config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
						            	onChange={
														( event, editor ) => {
						            			const data = editor.getData();
						            			this.onArticleChange(data);
						            		}
													}
						            />
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-outline-markt pt-2">
											<label htmlFor="validationCustom03" className="form-label pt-2">Geburtsdatum*</label><br/>
												<MDBDatePicker animateYearScrolling={true}
																			 cancelLabel="Cancel"
																			 locale={moment.locale('de')}
																			 getValue={this.getPickerValue}
																			 className="picker-format"
																			 theme={{
																				 borderRadius: "35px",
																			  palette: {
																			    primary: {
																			      main: '#00A441',

																			    },

																			    secondary: {
																			      main: '#FF8800',
																			      contrastText: '#ffcc00',
																			    },
																			  },
																			  typography: {
																			    useNextVariants: true,
  																			}
																			}} />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-outline-markt">
											<label htmlFor="validationCustom06" className="form-label pt-2">Telefonnummer veröffentlicht:  <span className=""></span> (wird im öffentlichen Profil angezeigt)</label><br/>
													<input type="text" className="form-control text-muted" name="publictelefon" onChange={this.changeHandler} value={this.state.publictelefon} id="validationCustom06"/>
											</div>
										</div>

										<div className="col-12">
											<MDBRow center className="profilpage-bottom">
												<button className="btn btn-done-profil" type="submit">{t('formular_senden')}</button>
											</MDBRow>
										</div>
										{ this.state.redirect === true ?
											<Redirect to="/LandingPageMarkt"/>:
										false
										}
									</form>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
						<div className="rectangle-footer-addBike">
							<MDBContainer style={{maxWidth: "1250px"}}>
								<FooterMarktLanding/>
							</MDBContainer>
						</div>
					</div>
				</div>

				{this.state.showProfile ?
				<CreateImageProfile
				show= {this.state.showProfile}
				handleClose = {this.handleCloseProfile}
				currentCompany={this.state.myUser}
				minWidth = {this.state.minWidth}
				width = {this.state.width}
				maxWidth = {this.state.maxWidth}
				aspect = {this.state.aspect}
				bildName = {this.state.bildName}
				/>: false}
				{ this.props.userData.boersepage ?
					 <Redirect to={{
										 pathname: "/BoersePage",
										 state: { activeItem: this.state.activeItemKategorie, bikeKategorie: this.state.bikeKategorie }
					 }}
				 />:
				 false}
		</>

    );
  }
}

export default  withTranslation()(Transaktionen);
