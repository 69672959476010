import React, { Component } from "react";
import CompanysItemToNewFeed from '../components/Companys/CompanysItemToNewFeed';
import FooterB2BMainPage from '../components/Footer/FooterB2BMainPage';
import FooterMarktLanding from '../components/Footer/FooterMarktLanding';
import Cookies from 'universal-cookie';
import ChatsMain from '../components/Chat/ChatsMain';
import Companies from '../components/Companys/Companies';
import FeedbackItem from '../components/RatingPage/FeedbackItem';
import MenuSideNavBoerse from './markt/BoersePage/MenuSideNavBoerse';
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import greaticon from "../assets/media/images/great.png";
import ib2bicon from "../assets/media/images/ib2b.png";
import ratingimage from "../assets/media/images/ratingimage.png";
import emptyicon from "../assets/media/newicons/star.png";
import ratingicon from "../assets/media/newicons/rating.png";
import ratingstar from "../assets/media/newicons/ratingstar.png";
import logo from "../assets/media/logo/1.png";
import AdvertBoxMain from '../components/Adverts/AdvertBoxMain';
import styles from '../assets/scss/modules/pages.module.scss';
import InterB2BAxios from '../Service/InterB2BAxios';
import PaginationPage from '../components/PaginationPage/PaginationPage';
import SonstigeNav from '../components/NavbarComponent/SonstigeNav';
import SonstigeNavB2B from '../components/NavbarComponentB2B/SonstigeNavB2B';
import ProfilpageNavRespo from '../components/NavbarComponent/ProfilpageNavRespo';
import BoersePageNavRespo from '../components/NavbarComponent/BoersePageNavRespo';
import SonstigeNavB2BHersteller from '../components/NavbarComponentB2B/SonstigeNavB2BHersteller';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
const cookies = new Cookies();

import{
	MDBContainer,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
	MDBRow,
	MDBCol,
	MDBCardTitle,
	MDBCollapse,
	MDBSideNav
} from 'mdbreact';

class AllFeedbackPage extends Component {
  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
		this.state = {allFeedback: [],
									site: 0,
									average: 0.0,
									allRating: 0,
									lastFeedback: {},
									pagingstatus: true,
									lastFeedbackUser: {}
									};
	};

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.addFunctionFromChild(this.onMessage);
		this.onAnimationStart();
		this.props.userData.refreshNavbar();
		this.onAllFeedbackPage(this.state.site);
		this.onAllFeedback();
		this.onLastFeedback();
	};

	componentWillUnmount() {
		this.props.removeFunctionFromChild(this.onMessage);
    this.interAxios.cancel();
  }

	onMessage = (message) => {
    let type = message.type;
    if (type === 'FEEDBACK') {
			this.onLastFeedback();
			this.onAllFeedback();
			this.onAllFeedbackPage(this.state.site);
    }
  };

	onAllFeedbackPage = (page) =>{
    var self = this;
		self.setState({allFeedback: []});

    this.interAxios.get('/api/feedbackByPage/' + page, function(response) {
					self.setState({allFeedback: response.data.content,
												 totalPages: response.data.totalPages,
											   totalElements: response.data.totalElements});
    })
  };

	onWiederRufen = (page) => {
		this.setState({site: page});
		this.onAllFeedbackPage(page);
	}

	onAllFeedback = () =>{
    var self = this;
    this.interAxios.get('/api/allFeedback', function(response) {
					let count = 0;
					let ratingValue = 0;
					if (response.data.length>0){
						for (let i = 0; i < response.data.length; i++){
							count ++;
							ratingValue += response.data[i].rating;
						}
			      self.setState({average: ratingValue/count,
                           allRating: count});
			    };


    })
  };

  onLastFeedback = () =>{
    var self = this;
		this.setState({lastFeedback: {}});
    this.interAxios.get('/api/lastFeedback', function(response) {
				if(response.data !== null){
					self.setState({lastFeedback: response.data});
					self.onLastFeedbackUser(response.data.userId)
				}else{
					self.setState({lastFeedback: {}});
				}

    })
  };

	onLastFeedbackUser = (id) => {
		let self = this;
		this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
			self.setState({lastFeedbackUser: response.data});
		})
	}

	onAnimationStart = () => {
		setTimeout(function(){
			this.setState({animationTrue: true});
		}.bind(this), 300);
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;

		const secondExample = {
			size: 13,
			count: this.state.lastFeedback.rating,
			color: "black",
			value: this.state.lastFeedback.rating,
			a11y: true,
			edit: false,
			emptyIcon: <img src={emptyicon} style={{width: "85%"}}/>,
			filledIcon: <img src={ratingicon} style={{width: "85%"}}/>,
		};
    return(
			<>
			<div className="d-none d-lg-block">
			{this.props.userData.partner() === "Dealer" ?
			<SonstigeNavB2B userData={this.props.userData}/>:false}
			{this.props.userData.partner() === "Supplier" ?
			<SonstigeNavB2BHersteller userData={this.props.userData}/>:false}
			{this.props.userData.partner() === "Markt" ?
			<SonstigeNav userData={this.props.userData}/>:false}
			</div>
			<div className="d-lg-none d-block">
			<BoersePageNavRespo  userData={this.props.userData} onMenuOpen = {this.props.handleSideNavToggle} isUserMenu={false} activeItem = {"4"}/>
			</div>
			<div className={styles.bodywrapper2}>
			<div className="d-lg-none d-block">
				<div className="side-nav-menu">
					<MDBSideNav
						right
						triggerOpening={this.props.isSideNavOpen}
						onOverlayClick={this.props.handleSideNavToggle}
						breakWidth={1300}
						className="deep-purple darken-4"

					>
						<MenuSideNavBoerse
												userData={this.props.userData}
												toggle = {this.toggle}
												onMenuClose = {this.props.onEnableScroll}
												activeItem = {this.state.activeItem}
						/>
					</MDBSideNav>
				</div>
			</div>
			    <MDBContainer style={{maxWidth: "1250px"}}>
					<MDBCollapse id="basicCollapse" className="pageTopCollapse" isOpen={this.state.animationTrue} delay={{show: 900}}>
						<MDBRow>
							<MDBCol size="4" md="2" className="text-center">
								<img className="w-100" src={ratingimage}/>
							</MDBCol>
							<MDBCol size="8" md="6">
								<h5 className="netzwerk-title">{t('danke_schon_wir_haben_bereits')} {this.state.totalElements} {t('feedbacks')}</h5>
								<img className="netzwerk-ib2b w-50" src={ib2bicon}/>
								<p className="dark-grey-text d-none d-sm-block">
								 	{t('es_ist_uns_wichtigs')}
								</p>
							</MDBCol>
							<MDBCol size="12" className="d-block d-sm-none">
								<p className="dark-grey-text pt-3">
								  {t('es_ist_uns_wichtigs')}
								</p>
							</MDBCol>
							<MDBCol className="rating-line" size="12" md="4" style={{paddingTop: "50px"}}>
								<MDBRow style={{paddingLeft: "20px"}}>
  							<span className="ratingnummer-text">{this.state.average.toFixed(1) + " "}</span> <img className="rating-image-solo" src={ratingstar}/><span className="rating-text">{t('basieren_auf')} {this.state.allRating} {t('bewertungen')}</span>
								</MDBRow>
								<MDBRow style={{paddingLeft: "5px"}}>
								<span className="rating-text-2">{t('letztes_feedback_von')} {this.state.lastFeedback.companyName !== "" ? this.state.lastFeedback.companyName : this.state.lastFeedbackUser.name}</span>
								<div style={{paddingTop: "9px", paddingLeft: "10px"}}>
								{this.state.lastFeedback.rating !== undefined ?
									<ReactStars {...secondExample}/>:false}</div>
								</MDBRow>
							</MDBCol>
						</MDBRow>
					</MDBCollapse>
					{this.state.allFeedback !== undefined ?
						<>
					<MDBRow style={{paddingTop: "30px"}}>
					{this.state.allFeedback.map(
						(o) =>	<MDBCol sm="12" md="6" lg="6" xl="4" className="py-0 px-2">
										<MDBCard  className="interb2b-card" style={{minHeight: "145px", paddingTop: "15px"}}>
										<MDBCardBody>
										 <FeedbackItem
											key={o.id} lastFeedback={o} allFeedbackStatus = {false} userData={this.props.userData}/>
										</MDBCardBody>
										</MDBCard>
										</MDBCol>

					)}
					</MDBRow>
					{this.state.totalPages > 1 ?
					<PaginationPage
									{...this}
									{...this.props}
									{...this.state}
					/>:false}
					</>:false}

			      </MDBContainer>

</div>
<div className="rectangle-footer-addBike">
	<MDBContainer style={{maxWidth: "1250px"}}>
	{this.props.userData.isLoggedInMarkt() ? 
		<FooterMarktLanding/>:
		<FooterB2BMainPage/>}
	</MDBContainer>
</div>
</>



    );
  }
}
export default withTranslation()(AllFeedbackPage);
