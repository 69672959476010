import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import BoersePageNavRespo from '../../../components/NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from '../BoersePage/MenuSideNavBoerse';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import backbtn from "../../../assets/media/images/markt/profil/backGreen.png";
import logo from "../../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import addBike from "../../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import kommentare from "../../../assets/media/images/markt/Kommentare.png";
import favorite_green from "../../../assets/media/images/markt/bookmark.png";
import favorite from "../../../assets/media/images/markt/bookmark-leer.png";
import kommentar from "../../../assets/media/images/markt/Kommentar.png";
import anmelden from "../../../assets/media/images/markt/anmelden.png";
import searchnormalicon from "../../../assets/media/images/markt/searchnormalicon.png";
import logo2 from "../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../../assets/media/images/markt/Group 22.png";
import logo4 from "../../../assets/media/images/markt/Group 19.png";
import logo5 from "../../../assets/media/images/markt/Group 20.png";
import profile_Boda from "../../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../../assets/media/images/b2b/Group 8.png";
import group_21 from "../../../assets/media/images/markt/Group 21.png";
import rectangle_1 from "../../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../../assets/media/images/b2b/Interb2b.png";

import InterB2BAxios from '../../../Service/InterB2BAxios';
import FooterMarktBoerse from '../../../components/Footer/FooterMarktBoerse';
import BikeAnzeigeKarte from '../../../components/Companys/BikeAnzeigeKarte';
import CommentItemBikes from '../../../components/Companys/CommentItemBikes';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../components/LazyLoad/LazyLoadItem';
import SonstigeNav from '../../../components/NavbarComponent/SonstigeNav';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from '../../../assets/scss/modules/boersepage.module.scss';
import Cookies from 'universal-cookie';
import { Scrollbar } from "react-scrollbars-custom";
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import GoogleMaps5 from '../../../components/GoogleMaps/GoogleMaps5';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBRangeInput,
	MDBListGroup,
	MDBSideNav,
	MDBIcon
} from 'mdbreact';
const cookies = new Cookies();
import Moment from 'moment';
let bikeAnzeigeId = '';
let urlName = '';
let bikePageUrl = '';
class BikePageResponziv extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefInterB2B = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'BikePage');
    this.state = {//bikeProduct: {},
									//currentCompany: {},
									activeItem: 0,
									kommentar:undefined,
									myUserId: cookies.get('authtoken')?.userId,
									latitude: "",
									longitude: "",
									redirect: false,
									};
  };


	componentDidMount() {
		this.onRefInterB2B();
		this.onAnimationStart();
		this.props.addFunctionFromChild(this.onMessage);
		var params = window.location.href.split("/");
    bikeAnzeigeId = params[params.length - 1];
		var params2 = window.location.href.split("/");
		urlName = params2[params2.length - 1];
		bikePageUrl = window.location.href;
		this.onGetBikes(bikeAnzeigeId);
		if (this.props.userData.isLoggedInMarkt()) {
			this.onisFavorite(bikeAnzeigeId);
		}
		document.addEventListener("scroll", this.scrollEventListener);
		let self = this;
		navigator.geolocation.getCurrentPosition(function(position) {
				self.setState({
					originalLatitude: position.coords.latitude,
					originalLongitude: position.coords.longitude
				});
		});
	}

	componentWillUnmount() {
		document.removeEventListener("scroll", this.scrollEventListener);
		this.props.removeFunctionFromChild(this.onMessage);
		this.interAxios.cancel();
	}
	onRefInterB2B = () => {
		this.listInnerRefInterB2B.current.scrollIntoView({
	 });

	}

	onMessage = (message) => {
    let type = message.type;
		if (type === 'NEWEBIKEPRODUCT'){
	    this.onGetBikes(bikeAnzeigeId);
	  }
  };

	onGetBikes= (id) => {
		let self = this;
		this.interAxios.get('/api/bikeProductById/' + id, function(response) {
			self.setState({
				bikeProduct: response.data,
				latitude: response.data.lat,
				longitude: response.data.lng
			});
			if(response.data.userFormProduct.companyUser){
				self.onGetCompany(response.data.userFormProduct.id);
			}else{
				self.onGetUser(response.data.userFormProduct.id);
			}
		})
	};

	onGetUser = (id) => {
		let self = this;
		this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
			self.setState({user: response.data,
//										 latitude: response.data.lat !== null && response.data.lat !== -200.0 ?
//										 	response.data.lat : self.state.bikeProduct.lat !== null && self.state.bikeProduct.lat !== -200.0  ? self.state.bikeProduct.lat : self.state.originalLatitude,
//										 longitude: response.data.lng !== null && response.data.lng !== -200.0 ?
//										  response.data.lng : self.state.bikeProduct.lng !== null && self.state.bikeProduct.lng !== -200.0  ? self.state.bikeProduct.lng : self.state.originalLongitude,
										});

		})
	}

	onGetCompany = (id) => {
		let self = this;
		this.interAxios.get('/api/myCompany/' + id, function(response) {

			self.setState({company: response.data,
//										 latitude: response.data.lat !== null ? response.data.lat : self.state.originalLatitude,
//										 longitude: response.data.lng !== null ? response.data.lng : self.state.originalLongitude,
										});

		})
	}

	onisFavorite= (id) => {
		let self = this;
		this.interAxios.get('/api/isMyFavoriteBike/' + id, function(response) {
			self.setState({favorite: response.data});
		})
	};

	onAddFavorite= () => {
		this.setState({favorite: true});
		let self = this;
		this.interAxios.get('/api/favoriteBike/' + this.state.bikeProduct.id, function(response) {

		})
	};

	onDeleteFavoriteDone = () => {
			this.setState({favorite: false});
			const newList = [];
			const newList2 = newList.concat(bikeAnzeigeId);
			let self = this;
			this.interAxios.post('/api/deleteFavoriteBikes/', newList2, function(response) {
			})
	}

	onChatContact = () => {
		let self = this;
		this.interAxios.get('/api/chat/bike/room/getOrCreateWithUser/' + this.state.bikeProduct.userFormProduct.id + "/" + this.state.bikeProduct.id,
			function(response) {
				self.setState({chatroomid: response.data, redirect: true});
		});
	}

	toggle = tab => e => {

				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
				}
	}

	onKommentarChange = (editorState) => {
		this.setState({kommentar: editorState});
	}

	onNewBikeProductComment = (bikeproductId) => {
		let self = this;
		if(this.props.userData.getCurrentCompany()?.id !== undefined){
			const formData = new FormData
			formData.append("content", this.state.kommentar);
			formData.append("companyId", this.props.userData.getCurrentCompany()?.id);
	    this.interAxios.post('/api/comment/newBikeProductComment/' + bikeproductId, formData, function(response) {
	    })
		}else{
			const formData = new FormData
			formData.append("content", this.state.kommentar);
			formData.append("userId", this.state.myUserId);
	    this.interAxios.post('/api/comment/newBikeProductComment/' + bikeproductId, formData, function(response) {
	    })
		}
  };

	handleSaveKommentar = () => {
		this.onNewBikeProductComment(this.state.bikeProduct.id);
		this.setState({kommentar: ''});
	}

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}
	onAnimationStart = () => {
		setTimeout(function(){
			this.setState({animationTrue: true});
		}.bind(this), 500);
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		let text1 = t('E_Bike_zur_Selbstabholung_anbieten');
		let text2= t('E_Bike_Online_Verkaufen_und_Versenden');
		let liefer =  this.state.bikeProduct?.eintragstyp === text1 ? t('Im_Laden') :
									this.state.bikeProduct?.eintragstyp === text2 ? "Online": false;
		let prevDate = Moment(Date.now());
		let prevDate1 = Moment(Date.now()).subtract(1, 'days');
		let prevDate2 = Moment(Date.now()).subtract(2, 'days');
		let tsmoment = Moment(this.state.bikeProduct?.time);
    return (
			<>
<div ref={this.listInnerRefInterB2B}></div>
			<BoersePageNavRespo  userData={this.props.userData} onMenuOpen = {this.props.handleSideNavToggle}/>
			<MDBCollapse id="basicCollapse2" className="fullPageCollapse" isOpen={this.state.animationTrue} delay={{show: 2000}} onOpened={() => {this.setState({wrapperTrue: true});}}>
				<div className={styles.bodywrapper}>
					<div className="side-nav-menu">
					<MDBSideNav
						right
						triggerOpening={this.props.isSideNavOpen}
						onOverlayClick={this.props.handleSideNavToggle}
						breakWidth={1300}
						className="deep-purple darken-4"

					>
						<MenuSideNavBoerse
												userData={this.props.userData}
												toggle = {this.toggle}
												onMenuClose = {this.props.onEnableScroll}
												activeItem = {this.state.activeItem}
						/>
					</MDBSideNav>
					</div>
							{this.state.bikeProduct !== undefined ?
							<MDBContainer fluid>

								<div className="bike-card-respo">
								 	<MDBRow start className="m-0 pb-2">
											<Link to="/BoersePage"><img className="bikepage-back-btn" src={backbtn}/></Link>
									</MDBRow>
									<a href={"mailto:?subject=e-bikeboerse&body=" + bikePageUrl}><MDBIcon className="share-icon" size="lg" icon="share-alt" /></a>
									<MDBRow className="m-0 pb-4">
										<span className="bike-title-bikepage">{this.state.bikeProduct.title}</span>
									</MDBRow>
									<MDBRow className="m-0">
									{this.state.bikeProduct.ebikeProductPhotosUrl !== undefined ?
										<img alt="" className="bike-img-big" src={this.state.bikeProduct.ebikeProductPhotosUrl[this.state.activeItem]}/>:false}
									</MDBRow>
									<MDBRow className="m-0">
									{this.state.bikeProduct.ebikeProductPhotosUrl !== undefined ?
										<div className="ebikeProductPhotos-small">
										<MDBNav className="flex-row ebikeproduct-nav">
											{this.state.bikeProduct.ebikeProductPhotosUrl.map(	(o, index) =>
														<MDBNavItem>
															<MDBNavLink link to="#" active={this.state.activeItem === index} onClick={this.toggle(index)} role="tab" >
																<img key={index} alt="" className="bike-img-small" src={o}/>
															</MDBNavLink>
														</MDBNavItem>
											)}
										</MDBNav></div>:false}
									</MDBRow>
									{this.state.bikeProduct.videoLink !== undefined && this.state.bikeProduct.videoLink !== "" ?
									<MDBRow className="m-0 py-4">
											<iframe src={this.state.bikeProduct.videoLink} width="100%" height="auto" style={{borderRadius: "10px"}} allowFullScreen autoPlay></iframe>
									</MDBRow>:false}
									<MDBRow className="m-0 pt-3 pb-2">
									 <MDBCol size="auto" className="p-0">
										 <div className="kategorie-text">
											 <span className="text">{this.state.bikeProduct.rahmengrose}</span>
										 </div>
									 </MDBCol>
									 <MDBCol size="auto">
										 <div className="kategorie-text">
											 <span className="text">{liefer}</span>
										 </div>
									 </MDBCol>
									 <MDBCol size="auto">
										 <span className="kategorie-text">{t(this.state.bikeProduct.kategorie)}</span>
									 </MDBCol>
									</MDBRow>
									<MDBRow className="m-0 py-2">
										<MDBCol size="auto" className="p-0">
										 <span className="preis-text">Preis:</span>
										</MDBCol>
										<MDBCol >
										 <span className="preis-text">{this.state.bikeProduct.preis} €</span>
										</MDBCol>
									</MDBRow>
									<MDBRow className="m-0 py-2">
									 <div className="sonstige-text" dangerouslySetInnerHTML={{__html: this.state.bikeProduct.article}}/>
									</MDBRow>
									<MDBRow className="m-0 pb-2">
										<MDBCol size="auto" className="p-0">
										 <span className="sonstige-text">{t('Kilometerstand')}:</span>
										</MDBCol>
										<MDBCol >
										 <span className="sonstige-text-bold">{this.state.bikeProduct.kilometerstand}</span>
										</MDBCol>
									</MDBRow>
									<MDBRow className="m-0 pb-2">
										<MDBCol size="auto" className="p-0">
										 <span className="sonstige-text">{t('Hersteller')}:</span>
										</MDBCol>
										<MDBCol >
										 <span className="sonstige-text-bold">{this.state.bikeProduct.hersteller}</span>
										</MDBCol>
									</MDBRow>
									<MDBRow className="m-0 pb-2">
										<MDBCol size="auto" className="p-0">
										 <span className="sonstige-text">{t('Modell')}:</span>
										</MDBCol>
										<MDBCol >
										 <span className="sonstige-text-bold">{this.state.bikeProduct.modell}</span>
										</MDBCol>
									</MDBRow>
									<MDBRow className="m-0">
										<MDBCol size="auto" className="p-0">
										 <span className="sonstige-text">{t('Baujahr')}:</span>
										</MDBCol>
										<MDBCol >
										 <span className="sonstige-text-bold">{this.state.bikeProduct.baujahr}</span>
										</MDBCol>
									</MDBRow>
									{this.state.bikeProduct.commentlist !== undefined && this.props.userData.isLoggedInMarkt()  ?
									<>
									<MDBRow className="m-0 pt-3 pb-3">
									 <MDBCol size="auto" middle className="px-0">
										<img alt="" className="kommentare-img" src={kommentare}/>
									 </MDBCol>
									 <MDBCol size="auto" middle className="pl-2">
										<span className="kommentare-title"> {t('Offentliche_Diskussion')} ({this.state.bikeProduct.commentlist.length})</span>
									 </MDBCol>
									</MDBRow>
									<MDBRow className="m-0">
										{this.state.bikeProduct.commentlist.length>0 ?
										<MDBCol className="px-0 pb-3">
											{this.state.bikeProduct.commentlist.map(
												(o) =>
												<MDBListGroup className="mitteilungen-listgroup">
													 <CommentItemBikes
														key={o.id} msg={o} userData={this.props.userData}/>
												</MDBListGroup>
											)}

										</MDBCol>:false}
									</MDBRow>
									<div className="line"/>
									{this.props.userData.isLoggedInMarkt() ? <>
									<MDBRow className="m-0">
										<MDBInput className="textarea-bikepage" type="textarea" rows="1" getValue={this.onKommentarChange} value={this.state.kommentar}
										placeholder={t('Kommentiere_den_Eintrag')}/>
									</MDBRow>
									<MDBRow start className="m-0">
										{this.state.kommentar !== undefined ?
										<MDBBtn className="comment-btn" onClick={this.handleSaveKommentar}>
											<MDBRow className="m-0" center>
												<MDBCol size="2" className="p-0" middle>
													<img alt="" className="comment-icon" src={kommentar}/>
												</MDBCol>
												<MDBCol size="8" className="p-0" middle>
													<span className="btn-text text-capitalize">{t('Kommentar')}</span>
												</MDBCol>
											</MDBRow>
										</MDBBtn>:
										<MDBBtn className="comment-btn">
											<MDBRow className="m-0" center>
												<MDBCol size="2" className="p-0" middle>
													<img alt="" className="comment-icon" src={kommentar}/>
												</MDBCol>
												<MDBCol size="8" className="p-0" middle>
													<span className="btn-text text-capitalize">{t('Kommentar')}</span>
												</MDBCol>
											</MDBRow>
										</MDBBtn>}
									</MDBRow>
									</>:false}
									 <MDBBtn className="favorite-btn m-0 p-0" flat onClick={!this.state.favorite ? this.onAddFavorite : this.onDeleteFavoriteDone}>
										 <MDBRow start className="m-0">
											<MDBCol size="auto" middle className="text-right">
												<span className="add-favorite text-capitalize">Bookmark</span>
											</MDBCol>
											<MDBCol size="auto" middle className="px-0">
												<img alt="" className="favorite-icon" src={!this.state.favorite ? favorite : favorite_green}/>
											</MDBCol>
										 </MDBRow>
										</MDBBtn>

										</>:false}
									 {this.state.bikeProduct.userFormProduct.id !== this.state.myUserId && this.props.userData.isLoggedInMarkt() ?<>
										 <div className="line"/>
									 <Link to={"/ProfilPage/" + this.state.bikeProduct.userFormProduct.id}>
									 <MDBRow className="m-0 pt-4 pb-2">
										 <MDBCol size="auto" middle className="pl-0">
												 {this.state.bikeProduct.userFormProduct.companyUser ?
													 <img alt="" className="user-foto" src={this.state.bikeProduct.userFormProduct.companyProfileUrl +'?' + Date.now()}></img>:<>
														 {this.state.bikeProduct.userFormProduct.profileUrl !== undefined ?
															 <img alt="" className="user-foto" src={this.state.bikeProduct.userFormProduct.profileUrl +'?' + Date.now()}></img>:
															 <div className="userProfileName-icon">{this.state.bikeProduct.userFormProduct.profilName}</div>}</>}
										 </MDBCol>
										 <MDBCol size="auto" className="p-0" middle>
												 <MDBRow start className="pl-0 pb-2 m-0">
														 {this.state.bikeProduct.userFormProduct.companyUser ?
														 <span className="user-name">{this.state.bikeProduct.userFormProduct.companyName}</span>:
														 <span className="user-name">{this.state.bikeProduct.userFormProduct.name}</span>}
												 </MDBRow>
												 <MDBRow start className="pl-0 m-0">
													 {tsmoment.isBefore(prevDate2, 'day') ?
													 <span className="user-datum">{tsmoment.locale(this.props.i18n.language).format('DD-MM-YYYY')}</span>:false}
													 {tsmoment.isSame(prevDate2, 'day') ?
													 <span className="user-datum">{t('vor_2_Tagen')}</span>:false}
													 {tsmoment.isSame(prevDate1, 'day') ?
													 <span className="user-datum">{t('gestern')}</span>:false}
													 {tsmoment.isSame(prevDate, 'day') ?
													 <span className="user-datum">{tsmoment.format('h:mm a')}</span>:false}
												 </MDBRow>
										 </MDBCol>
									 </MDBRow>
									 </Link></>:false}
									 <MDBRow start className="m-0 pb-4">
										 {this.state.bikeProduct.userFormProduct.id !== this.state.myUserId && this.props.userData.isLoggedInMarkt() ?
											<MDBBtn className="contact-btn-bikepage"
															onClick = {this.onChatContact}
											>
												<span className="contact-btn-text text-capitalize">{t('kontakt')}</span>
											</MDBBtn>
										 :false}
									 </MDBRow>

									 <MDBRow className="m-0 pt-2 py-4">
										 <MDBCol size="12" className="p-0">
											 {this.state.latitude > -190 ?
											 	<div className="profile-mapholder">
											 		<GoogleMaps5  latitude={this.state.latitude}
																				longitude={this.state.longitude}
																				isBikeMap={true}
																				userData = {this.props.userData}
																				withMap = {"100%"}
		 																	 	heightMap = {"250px"}
																				/>
											 	</div>:false}
										 </MDBCol>
									 </MDBRow>
								</div>
							</MDBContainer>:false}
				</div>
				</MDBCollapse>
				{ this.state.redirect === true ?
					<Redirect to={"/ChatsBike/user/" + this.state.chatroomid}/>:false}
		</>
    );
  }
}

export default  withTranslation()(BikePageResponziv);
