import React, { Component } from 'react';


import staricon from '../../assets/media/newicons/star1.png';
import staricon_mobile from '../../assets/media/newicons/star.png';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import FeedbackPage from "../Modals/FeedbackPage";
import InterB2BAxios from '../../Service/InterB2BAxios';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Feedback extends Component {

	constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {
                  showFeedback: false
                  };
  }

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
	}

	componentWillUnmount() {
		this.interAxios.cancel();
  }

  onFeedbackChange = (editorState) => {
    this.setState({feedback: editorState});
  }

  handleShowFeedback = () => {
    this.setState({showFeedback: !this.state.showFeedback});
  }

  handleSaveFeedback = (ertek) => {
    this.setState({showFeedback: !this.state.showFeedback});
  	this.onFeedbackSenden(ertek);
  }

  handleCloseFeedback = () => {
    this.setState({showFeedback: !this.state.showFeedback});
  }

  onFeedbackSenden = (ertek) => {
    let self = this;
    this.interAxios.get('/api/newFeedback',{
        params: { rating: ertek,
									text: this.state.feedback,
                  myUserId: cookies.get('authtoken').userId},
      }, function(response)
    {
    });
	}

	onFeedbackCompanySenden = (ertek) => {
		console.log(this.state.feedback);
		let self = this;
		this.interAxios.get('/api/newFeedbackToCompany',{
				params: { rating: ertek,
									text: this.state.feedback,
									myUserId: cookies.get('authtoken').userId,
								  companyId: cookies.get('authtoken').currentCompanyId},
			}, function(response)
    {
    });
	}

	render() {
    const { t } = this.props;
    const client = this.props.client;
		return (
			<>
    		<div className="feedbackwrapperInterB2B">
    			<div className="feedback" onClick={this.handleShowFeedback}><img src={staricon} /> <span>FEEDBACK</span></div>
    		</div>
        {this.state.showFeedback ?
          <FeedbackPage
            interb2b={true}
            feedBackState={this.state.feedback}
            onFeedbackChange={this.onFeedbackChange}
            show={this.state.showFeedback}
            handleShow={this.handleShowFeedback}
            handleSave={this.handleSaveFeedback}
            handleClose={this.handleCloseFeedback}
            {...this.props}
          />:
        false}
      </>
		);
	}
}

export default withTranslation()(Feedback);
