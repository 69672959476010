import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import logo2 from "../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../../assets/media/images/markt/profil/Group 7.png";
import InterB2BAxios from '../../../Service/InterB2BAxios';
import FooterMarktLanding from '../../Footer/FooterMarktLanding';
import SonstigeNav from '../../NavbarComponent/SonstigeNav';
import ProfilpageNav from '../../NavbarComponent/ProfilpageNav';
import BikeAnzeigeKarte from '../../Companys/BikeAnzeigeKarte';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import GoogleMaps5 from '../../GoogleMaps/GoogleMaps5';
import styles from '../../../assets/scss/modules/profilpage.module.scss';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
  MDBBtn,
	MDBCollapse,
	MDBCard
} from 'mdbreact';

let language = '';
let userId = '';
class ProfilPageDesktop extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'Eintraege');
    this.state = {bikeslist: {},
									user: {},
									};

  };

	componentDidMount() {
		this.onAnimationStart();
		this.props.addFunctionFromChild(this.onMessage);
		var params = window.location.href.split("/");
    userId = params[params.length - 1];
		var params2 = window.location.href.split(".");
	  language = params2[params2.length - 1];
		this.onGetUser(userId);
		this.onGetBikesWithUserId(userId);

	}

	componentWillUnmount() {
		this.props.removeFunctionFromChild(this.onMessage);
		this.interAxios.cancel();
	}

	onMessage = (message) => {
    let type = message.type;
		if (type === 'NEWEBIKEPRODUCT'){
	    this.onGetBikesWithUserId();
	  }
  };

	onGetUser = (id) => {
		let self = this;
		this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
			self.setState({user: response.data});
		})
	}

	onGetBikesWithUserId = (id) => {
		let self = this;
		this.interAxios.get('/api/allBikeProductWithUserId/' + id, function(response) {
			self.setState({bikeslist: response.data});
		})
	};

	onChatContact = () => {
		let self = this;
		this.interAxios.get('/api/chat/bike/room/getOrCreateWithUser/' + this.state.bikeProduct.userFormProduct.id + "/" + 0,
			function(response) {
				self.setState({chatroomid: response.data, redirect: true});
		});
	}
	onAnimationStart = () => {
		setTimeout(function(){
			this.setState({animationTrue: true});
		}.bind(this), 500);
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		var text ="";


		if (this.state.user?.aboutUs !== ''){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.state.user?.aboutUs, 'text/html');
			text =parsedHtml.documentElement.textContent;
		}

		var profilUrlEnde = "";
		if(this.state.user.profileUrl !== undefined){
			var profilUrlEnde = this.state.user.profileUrl.split("_")[this.state.user.profileUrl.split("_").length - 1];
		}

    return (
			<>
				<SonstigeNav userData={this.props.userData}/>
				<MDBCollapse id="basicCollapse2" className="fullPageCollapse" isOpen={this.state.animationTrue} delay={{show: 2000}} onOpened={() => {this.setState({wrapperTrue: true});}}>
				<div className={styles.bodywrapper}>
				<div className="profil-background">
					<MDBContainer style={{maxWidth: "1350px"}}>
						<MDBRow className="pt-4">
							<MDBCol md="auto" lg="3" className="pr-4">
								<MDBCard className="person-card">
									<MDBRow center className="m-0 pb-4">
									{this.state.user?.companyUser ?
										<img alt="" className="user-foto" src={this.state.user?.companyProfileUrl +'?' + Date.now()}></img>:<>
											{this.state.user?.profileUrl !== undefined && profilUrlEnde!== "default.jpg" ?
										<img alt="" className="user-foto" src={this.state.user?.profileUrl +'?' + Date.now()}></img>:
										<div className="userProfileName-icon">{this.state.user?.profilName}</div>}</>}
									</MDBRow>
									<MDBRow center className="m-0 pb-2">
									{this.state.user?.companyUser ?
										<span className="user-name">{this.state.user?.companyName}</span>:
										<span className="user-name">{this.state.user?.name}</span>}
									</MDBRow>
									<MDBRow center className="m-0 pb-3">
									{this.state.user?.publictelefon ?
										<span className="user-telefon">{this.state.user?.publictelefon}</span>:false}
									</MDBRow>
									<MDBRow start className="m-0 pb-4">
									{this.state.user?.aboutUs ?
										<span className="user-aboutUs">{text}</span>:false}
									</MDBRow>
									<MDBRow center className="m-0 pb-4">
									 <GoogleMaps5  latitude={this.state.user.lat !== -200 && this.state.user.lat !== null ? this.state.user.lat: this.props.userData.getLatLng().originalLatitude}
																 longitude={this.state.user.lng !== -200 && this.state.user.lng !== null ? this.state.user.lng: this.props.userData.getLatLng().originalLongitude}
																 isProfileMap={true}
																 userData = {this.props.userData}
																 withMap = {"100%"}
																 heightMap = {"150px"}/>
									</MDBRow>
									<MDBRow center className="m-0">
									<a href={"mailto:" + this.state.user.email}>
									 <MDBBtn className="contact-btn-person"
									 >
										 <span className="contact-btn-text text-capitalize">E-mail</span>
									 </MDBBtn>
									</a>
									</MDBRow>
								</MDBCard>
							</MDBCol>
							<MDBCol lg="9" className="px-5">
								<MDBRow start className="pt-0 pb-3 m-0">
								<MDBCol middle md="auto" className="pl-0 pr-1">
									<img alt="" className="profil-title-logo" src={logo3}></img>
								</MDBCol>
								<MDBCol middle className="pl-1 pr-0">
								{this.state.user?.companyUser ?
									<span className="logo-text-name">{this.state.user?.companyName}</span>:
									<span className="logo-text-name">{this.state.user?.name}</span>}
								</MDBCol>
								</MDBRow>
								<MDBRow className="m-0 eigene-padding-bottom">
									{this.state.bikeslist?.length > 0 ?
											<>
											{this.state.bikeslist.filter((e) => (e.aktuelleStatus !== 3 && e.aktuelleStatus !== 4)).map(
											(o, index) =>
											<MDBCol md="3" lg="4" xl="3" size="12" className="mb-3 px-2 pt-0">
															<BikeAnzeigeKarte
															 key={index}
															 userData={this.props.userData}
															 msg={o}/>
											</MDBCol>
										)}
										 </>:false}
								</MDBRow>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</div>
				</div>
</MDBCollapse>
		</>

    );
  }
}

export default  withTranslation()(ProfilPageDesktop);
