import React, { Component } from "react";
import ReactDOM from 'react-dom';
import NewsPageHandlerDesktop from './NewsPageHandler/NewsPageHandlerDesktop';
import NewsPageHandlerResponziv from './NewsPageHandler/NewsPageHandlerResponziv';

class NewsPageHandler extends Component {

	constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
	return (
		<>{!window.matchMedia('(max-device-width: 992px)').matches ?
			<div className="d-none d-lg-block">
				<NewsPageHandlerDesktop userData={this.props.userData}
																 addFunctionFromChild = {this.props.addFunctionFromChild}
																 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																 {...this.props}
																 />
			</div>:
			<div className="d-lg-none d-block">
				<NewsPageHandlerResponziv userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
			</div>}
		</>
    );
  }
}

export default  NewsPageHandler;
