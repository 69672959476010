import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import logo from "../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import addBike from "../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import anmelden from "../../assets/media/images/markt/anmelden.png";
import signinmarkt from "../../assets/media/images/markt/signinmarkt.png";
import signupmarkt from "../../assets/media/images/markt/signupmarkt.png";
import searchnormalicon from "../../assets/media/images/markt/searchnormalicon.png";
import logo2 from "../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../assets/media/images/markt/Group 18.png";
import logo4 from "../../assets/media/images/markt/Group 19.png";
import logo5 from "../../assets/media/images/markt/Group 20.png";
import profile_Boda from "../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../assets/media/images/b2b/Group 8.png";
import group_21 from "../../assets/media/images/markt/Group 21.png";
import rectangle_1 from "../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../assets/media/images/b2b/Interb2b.png";

import InterB2BAxios from '../../Service/InterB2BAxios';
import FooterMarktLanding from '../../components/Footer/FooterMarktLanding';
import GyikModul from "../../components/Gyik/GyikModul";
import Interb2bNewsItems from '../../components/News/Interb2bNewsItems';
import LandingPageMarktDesktop from './LandingPage/LandingPageMarktDesktop';
import LandingPageMarktResponziv from './LandingPage/LandingPageMarktResponziv';
import ChatsMainBike from '../../components/ChatBike/ChatsMainBike';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../components/LazyLoad/LazyLoadItem';

class LandingPageMarkt extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };

	componentDidMount() {
	}

	componentWillUnmount() {
	}

  render() {
    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<LandingPageMarktDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<LandingPageMarktResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
	 		</>
 	);
 }
 }

 export default  LandingPageMarkt;
