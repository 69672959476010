import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import 'moment/locale/de';
import Ckeditor from './Ckeditor';
import CommentItem from './CommentItem';
import LikeItem from './LikeItem';
import logo from "../../assets/media/logo/1.png";
import { UserStatus } from "../../Config/Config";
import InterB2BAxios from '../../Service/InterB2BAxios';
import Cookies from 'universal-cookie';
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import { Link } from "react-router-dom";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardTitle,
	MDBCardBody,
	MDBBtn,
	MDBCollapse,
	MDBView,
	MDBMask,
	MDBContainer,
	MDBIcon,
	MDBBtnGroup,
	MDBInput,
	MDBBadge,
	MDBPopper

} from 'mdbreact';

const cookies = new Cookies();

class CompanysItemToNewFeed extends React.Component {


  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {
		      open: false,
					isOpen: false,
					profileImageHash: Date.now(),
					buttonName: "Weiterlesen",
					readState: 'read-less',
					textstatus: false,
					token : cookies.get('authtoken').token,
			    myUserId: cookies.get('authtoken').userId,
					companyId: cookies.get('authtoken').currentCompanyId,
					companyPhotos: [],
					iconName: "angle-double-right",
					q: -1,
					buysLikeCompanies: [],
					sellsLikeCompanies: [],
					kommentar:undefined,
					showKommentar: false,
					collapseID: ""
		    };
  };

	componentDidMount() {
		if(this.props.msg.offerPhotosUrl !== undefined && this.props.msg.offerPhotosUrl.length > 0){
			this.loadImage(this.props.msg.offerPhotosUrl[0]);
		}else{
			if(this.props.msg.videoLink !== undefined && this.props.msg.videoLink !== ''){
				this.setState({q: 16/9});
			}
		}
	};

	componentWillUnmount() {
		this.interAxios.cancel();
	}
	handleClick = () =>{
    this.setState( (prevState: { open: any; }) => ({
      open: !prevState.open
    }))
  }

	handleReadmore = (id) =>{
		const newClassName = this.state.readState === 'read-less' ? 'read' : 'read-less';
		this.setState({readState: newClassName});
		if(newClassName === 'read'){
			this.setState({buttonName: "Weniger",
										 iconName: "angle-double-left",
									 	 textstatus: true});
		}else{
			this.setState({buttonName: "Weiterlesen",
										 iconName: "angle-double-right",
									   textstatus: false});
		}
	}

	onIsOpen = () => {
	  this.setState({isOpen: !this.state.isOpen});
	}

	loadImage = imagesrc => {
		const img = new Image();
		let self = this;
		img.onload = function() {
			self.setState({q: this.width / this.height})
		}
		img.src = imagesrc;
	}

	onNewSellsComment = (sellsId) => {
		let self = this;
		const formData = new FormData
		formData.append("content", this.state.kommentar);
		formData.append("companyId", this.state.companyId);
		this.interAxios.post('/api/comment/newSellsComment/' + sellsId, formData, function(response) {});
/*    axios.post(process.env.REACT_APP_BACKEND_URL+'/api/comment/newSellsComment/' + sellsId, formData, {
        headers:
						{'Authorization': 'Bearer ' + this.state.token}
      }
    )
    .then(function(response) {
    })
    .catch(function(error) {
        console.log(error);
    })*/
  };

	onNewBuysComment = (buysId) => {
		let self = this;
		const formData = new FormData
		formData.append("content", this.state.kommentar);
		formData.append("companyId", this.state.companyId);
		this.interAxios.post('/api/comment/newBuysComment/' + buysId, formData, function(response) {});
/*		axios.post(process.env.REACT_APP_BACKEND_URL+'/api/comment/newBuysComment/' + buysId, formData, {
				headers:
				{'Authorization': 'Bearer ' + this.state.token}
			}
		)
		.then(function(response) {
		})
		.catch(function(error) {
				console.log(error);
		})*/
	};

	onNewSellsLike = (sellsId) => {
		var self = this;
		this.interAxios.get('/api/addLikeToSellsProduct/' + sellsId, function(response) {});
/*		axios.get(process.env.REACT_APP_BACKEND_URL+'/api/addLikeToSellsProduct/' + sellsId, {
			headers: {
				'Authorization': 'Bearer ' + this.state.token
			}
		})
		.then(function(response) {
		})
		.catch(function(error) {
			console.log(error);
		})*/
	};

	onNewBuysLike = (buysId) => {
		var self = this;
		this.interAxios.get('/api/addLikeToBuysProduct/' + buysId, function(response) {});
/*		axios.get(process.env.REACT_APP_BACKEND_URL+'/api/addLikeToBuysProduct/' + buysId, {
			headers: {
				'Authorization': 'Bearer ' + this.state.token
			}
		})
		.then(function(response) {
		})
		.catch(function(error) {
			console.log(error);
		})*/
	};

	onRevokeSellsLike = (sellsId) => {
		var self = this;
		this.interAxios.get('/api/revokeLikeToSellsProduct/' + sellsId, function(response) {});
/*		axios.get(process.env.REACT_APP_BACKEND_URL+'/api/revokeLikeToSellsProduct/' + sellsId, {
			headers: {
				'Authorization': 'Bearer ' + this.state.token
			}
		})
		.then(function(response) {
		})
		.catch(function(error) {
			console.log(error);
		})*/
	};

	onRevokeBuysLike = (buysId) => {
		var self = this;
		this.interAxios.get('/api/revokeLikeToBuysProduct/' + buysId, function(response) {});
/*		axios.get(process.env.REACT_APP_BACKEND_URL+'/api/revokeLikeToBuysProduct/' + buysId, {
			headers: {
				'Authorization': 'Bearer ' + this.state.token
			}
		})
		.then(function(response) {
		})
		.catch(function(error) {
			console.log(error);
		})*/
	};

	handleShowKommentar = () => {
		this.setState({showKommentar: !this.state.showKommentar});
	}

	handleSaveKommentar = () => {
		if(this.props.msg.relationshipType ==="SELLS"){
			this.onNewSellsComment(this.props.msg.id);
		}else{
			this.onNewBuysComment(this.props.msg.id);
		}
		this.setState({kommentar: ''});
	}

	handleCloseKommentar = () => {
		this.setState({showKommentar: !this.state.showKommentar});
	}

	onKommentarChange = (editorState) => {
		this.setState({kommentar: editorState});
	}

	handleShowLike = () => {
		if(this.props.msg.relationshipType ==="SELLS"){
			this.onNewSellsLike(this.props.msg.id);
		}else{
			this.onNewBuysLike(this.props.msg.id);
		}
	}

	handleShowRevokeLike = () => {
		if(this.props.msg.relationshipType ==="SELLS"){
			this.onRevokeSellsLike(this.props.msg.id);
		}else{
			this.onRevokeBuysLike(this.props.msg.id);
		}
	}

	toggleCollapse = collapseID => () => {
	  this.setState(prevState => ({
	    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
	  }));
	}

	truncateString = (str, sum) => {
		if(str !== null){
			if (str.length <= sum) {
				return str
	  	}
					return str.slice(0, sum) + '...'

		}
	}


  render() {
    const {open} = this.state
		var images = [];
		var imagesplus = 0;
		var i=1;
		if (this.props.msg.offerPhotosUrl.length>0){
			for (const companyPhoto of this.props.msg.offerPhotosUrl){
					images.push({	src: companyPhoto });

				i++;
			}
			imagesplus = (i - 2);
		}
		let imagewidth = 900*this.state.q/(9*this.state.q+16);
		let videowidth = (100-imagewidth);
		let imagepercent = imagewidth+'%';
		let videopercent = videowidth+'%';
		let text ="";
		if (this.props.msg.article !== ''){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.props.msg.article, 'text/html');
			text =parsedHtml.documentElement.textContent;
		}
		if (this.props.msg.aboutUs !== '' && this.props.msg.relationshipType ==="Über Uns Text"){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.props.msg.aboutUs, 'text/html');
			text ="Über uns: " + parsedHtml.documentElement.textContent;
		}
    return (
			<>
{this.props.msg.relationshipType ==="SELLS" || this.props.msg.relationshipType ==="BUYS"?

<MDBCard className="interb2b-card">
	<MDBCardTitle>
	{this.props.msg.relationshipType ==="SELLS" ?
	<>
		{this.props.msg.product === undefined ?
                <>BIETET: {this.props.productName}</> : <>

                  BIETET: {this.props.msg.product[0]}

                </>}
	</>:
	<>
		{this.props.msg.product === undefined ?
                <>SUCHT: {this.props.productName}</> : <>

                  SUCHT: {this.props.msg.product[0]}

                </>}
	</>}

		<p className="float-right" style={{fontSize: "10px", color: "#30445B"}}>
			<i className="far fa-calendar-alt"></i> {Moment(this.props.msg.time).locale('de').format('DD MMMM YYYY')} <i className="far fa-clock"></i> {Moment(this.props.msg.time).format('h:mm a')}

		</p>
	</MDBCardTitle>
	<MDBCardBody>
        <MDBRow>
            <MDBCol md="auto" className="text-center">
				{this.props.msg.companyId !==undefined?
				<MDBView hover zoom>
		            <img
		                src={this.props.msg.profileUrl +'?' + Date.now()}
		                className="photo rounded"
		                alt=""
		            />
		        </MDBView>:false}
            </MDBCol>
            <MDBCol>
            	<Link className="contacts-link" to={"/CompaniesPage/" + this.props.msg.companyId}>{this.props.msg.companyName}</Link>
				<address>
                	{this.props.msg.postcode} {this.props.msg.town}<br/>
					{this.props.msg.street}
				</address>
            </MDBCol>

		</MDBRow>
<hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>
		{this.props.msg.relationshipType ==="SELLS" ?
		<>
			{this.state.q !== -1 ?
			<>
			<MDBRow>
				<MDBCol>
					{images.length>0 ?

					<div style={{float: "left", width: imagepercent}} className="px-1">
						<a href="#!"  onClick={this.onIsOpen}>
							<img
								className="img-fluid"
								src={images[0].src}
								alt=""
								/>
						</a>
						{imagesplus > 0 ?
						<h6 style={{position: 'absolute', top: '97%', left: '20px', transform: 'translateY(-50%)', backgroundColor: "#fff"}}>
							+ {imagesplus} <i className="far fa-image"></i>
						</h6>:false}
						<LightboxPageToMitteilungen
							images={images}
							isOpen={this.state.isOpen}
							onIsOpen={this.onIsOpen}/>
					</div>:false}

					<div style={{float: "left", width:videopercent}} className="embed-responsive embed-responsive-16by9 px-1">
						<iframe className="embed-responsive-item" src={this.props.msg.videoLink} allowFullScreen autoPlay></iframe>
					</div>
				</MDBCol>

			</MDBRow>
			<hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>
			</>:false}

			{this.props.msg.article !== '' ?
			<>
			<MDBRow>
				<MDBCol>
				{this.state.textstatus === true ?
					<div className={this.state.readState + ' px-2 pt-2'} id={'read-' + this.props.msg.id}>{text}</div>:
			    <div className="px-2 pt-2">{this.truncateString(text, 155)}</div>}

					{text.length > 155 ?
					<MDBBtn flat
						className='btn-block btn-sm mb-0 pb-0'
						onClick={() => {
							this.handleReadmore(this.props.msg.id);
						}}
						style={{ marginBottom: "1rem",	textAlign:"left",	paddingLeft:"9px" }}
					>
						<span style={{textTransform: "capitalize"}}>{this.state.buttonName} </span>
						<MDBIcon icon={this.state.iconName} />
					</MDBBtn>:false}
				</MDBCol>

			</MDBRow>
			<hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>
			</>: false}

		</>:false}

		{this.props.msg.companiesWithLike.length>0 || this.props.msg.commentlist.length>0 ?
		<>
		<MDBRow className="px-3">
			{this.props.msg.companiesWithLike.length>0 ?
			<MDBPopper placement="bottom" material>
				<span style={{color:"#33b5e5"}}>
					{this.props.msg.companiesWithLike.length} <MDBIcon icon="thumbs-up" style={{paddingRight: "0.5rem"}}/>
				</span>
				<span>
					{this.props.msg.companiesWithLike.length>0 ?

								this.props.msg.companiesWithLike.map(
									(o) =>
										 <LikeItem
											key={o.id} msg={o} />
								)
							:false}
				</span>
			</MDBPopper>:false}

								{this.props.msg.commentlist.length === 1 ?
									<MDBBtn flat
													className="p-0 m-0"
													style={{color:"#33b5e5", textTransform: "capitalize"}}
													onClick={this.toggleCollapse("basicCollapse")}
													><span>{this.props.msg.commentlist.length} Kommentar</span>
									</MDBBtn>:false}
								{this.props.msg.commentlist.length > 1 ?
									<MDBBtn flat
													className="p-0 m-0"
													style={{color:"#33b5e5", textTransform: "capitalize"}}
													onClick={this.toggleCollapse("basicCollapse")}
													><span>{this.props.msg.commentlist.length} Kommentare</span>
									</MDBBtn>:false}

	    </MDBRow>
	    <hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>
	    </>:false}


		<MDBRow center>
			<MDBCol className="text-center">
				<MDBBtnGroup>
					{!this.props.msg.likeFromMe ?
					<MDBBtn flat
									className="btn-group-left"
									onClick={this.handleShowLike}
									style={{color:"#33b5e5"}}><MDBIcon far icon="thumbs-up" />
					</MDBBtn>:

					<MDBBtn flat
									className="btn-group-left"
									onClick={this.handleShowRevokeLike}
									style={{color:"#33b5e5"}}><MDBIcon icon="thumbs-up"/>
					</MDBBtn>}
					<MDBBtn flat
									className="btn-group-right"
									 onClick={this.toggleCollapse("basicCollapse")}
									style={{color:"#33b5e5"}}><MDBIcon far icon="comments"/>
					</MDBBtn>
				</MDBBtnGroup>
			</MDBCol>
		</MDBRow>
					<MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
						{this.state.collapseID ?
							<>

							<MDBRow>


							{this.props.msg.commentlist.length>0 ?
							<MDBCol>
								{this.props.msg.commentlist.map(
									(o) =>
										 <CommentItem
											key={o.id} msg={o} />
								)}

							</MDBCol>:false}
							</MDBRow>
							<MDBInput type="textarea" rows="1" getValue={this.onKommentarChange} value={this.state.kommentar} placeholder="Bitte fügen Sie Ihren Kommentar hinzu..."/>
							</>:false}
							<div className="text-center p-0">
				 			<MDBBtn flat onClick={this.handleSaveKommentar}>Kommentar senden</MDBBtn>
							</div>
        </MDBCollapse>

						</MDBCardBody>
</MDBCard>:false}

{this.props.msg.relationshipType ==="Name der Firma" ?
<MDBCard className="interb2b-card">
	<MDBCardTitle>
		{this.props.msg.product === undefined ?
                <>{this.props.msg.relationshipType}: {this.props.productName}</> : <>

                  {this.props.msg.relationshipType}: {this.props.msg.product[0]}

                </>}
								<MDBBadge pill color="light" className="float-right">
									<i className="far fa-calendar-alt"></i> {Moment(this.props.msg.time).locale("de").format('DD MMMM YYYY')} <i className="far fa-clock"></i> {Moment(this.props.msg.time).format('h:mm a')}

								</MDBBadge>
	</MDBCardTitle>
	<MDBCardBody>
        <MDBRow>
            <MDBCol md="auto" className="text-center">
				{this.props.msg.companyId !==undefined?
				<MDBView hover zoom>
		            <img
		                src={this.props.msg.profileUrl +'?' + Date.now()}
		                className="photo img-thumbnail"
		                alt=""
		            />
		        </MDBView>:false}
            </MDBCol>
            <MDBCol>
            	<Link className="contacts-link" to={"/CompaniesPage/" + this.props.msg.companyId}>{this.props.msg.companyName}</Link>
				<address>
                	{this.props.msg.postcode} {this.props.msg.town}<br/>
					{this.props.msg.street}
				</address>
            </MDBCol>


		</MDBRow>
	<hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>

						</MDBCardBody>
</MDBCard>: false}

{this.props.msg.relationshipType ==="Unternehmensektor" ?
<MDBCard className="interb2b-card">
	<MDBCardTitle>
		{this.props.msg.product === undefined ?
                <>{this.props.msg.relationshipType}: {this.props.productName}</> : <>

                  {this.props.msg.relationshipType}: {this.props.msg.product[0]}

                </>}
								<MDBBadge pill color="light" className="float-right">
									<i className="far fa-calendar-alt"></i> {Moment(this.props.msg.time).locale("de").format('DD MMMM YYYY')} <i className="far fa-clock"></i> {Moment(this.props.msg.time).format('h:mm a')}

								</MDBBadge>
	</MDBCardTitle>
	<MDBCardBody>
        <MDBRow>
            <MDBCol md="auto" className="text-center">
				{this.props.msg.companyId !==undefined?
				<MDBView hover zoom>
		            <img
		                src={this.props.msg.profileUrl +'?' + Date.now()}
		                className="photo img-thumbnail"
		                alt=""
		            />
		        </MDBView>:false}
            </MDBCol>
            <MDBCol>
            	<Link className="contacts-link" to={"/CompaniesPage/" + this.props.msg.companyId}>{this.props.msg.companyName}</Link>
				<address>
                	{this.props.msg.postcode} {this.props.msg.town}<br/>
					{this.props.msg.street}
				</address>
            </MDBCol>


		</MDBRow>
	<hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>

						</MDBCardBody>
</MDBCard>: false}

{this.props.msg.relationshipType ==="Unternehmenoberkategorie" ?
<MDBCard className="interb2b-card">
	<MDBCardTitle>
		{this.props.msg.product === undefined ?
                <>{this.props.msg.relationshipType}: {this.props.productName}</> : <>

                  {this.props.msg.relationshipType}: {this.props.msg.product[0]}

                </>}
								<MDBBadge pill color="light" className="float-right">
									<i className="far fa-calendar-alt"></i> {Moment(this.props.msg.time).locale("de").format('DD MMMM YYYY')} <i className="far fa-clock"></i> {Moment(this.props.msg.time).format('h:mm a')}

								</MDBBadge>
	</MDBCardTitle>
	<MDBCardBody>
        <MDBRow>
            <MDBCol md="auto" className="text-center">
				{this.props.msg.companyId !==undefined?
				<MDBView hover zoom>
		            <img
		                src={this.props.msg.profileUrl +'?' + Date.now()}
		                className="photo img-thumbnail"
		                alt=""
		            />
		        </MDBView>:false}
            </MDBCol>
            <MDBCol>
            	<Link className="contacts-link" to={"/CompaniesPage/" + this.props.msg.companyId}>{this.props.msg.companyName}</Link>
				<address>
                	{this.props.msg.postcode} {this.props.msg.town}<br/>
					{this.props.msg.street}
				</address>
            </MDBCol>


		</MDBRow>
	<hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>

						</MDBCardBody>
</MDBCard>: false}

{this.props.msg.relationshipType ==="Unternehmenkategorie" ?
<MDBCard className="interb2b-card">
	<MDBCardTitle>
		{this.props.msg.product === undefined ?
                <>{this.props.msg.relationshipType}: {this.props.productName}</> : <>

                  {this.props.msg.relationshipType}: {this.props.msg.product[0]}

                </>}
								<MDBBadge pill color="light" className="float-right">
									<i className="far fa-calendar-alt"></i> {Moment(this.props.msg.time).locale("de").format('DD MMMM YYYY')} <i className="far fa-clock"></i> {Moment(this.props.msg.time).format('h:mm a')}

								</MDBBadge>
	</MDBCardTitle>
	<MDBCardBody>
        <MDBRow>
            <MDBCol md="auto" className="text-center">
				{this.props.msg.companyId !==undefined?
				<MDBView hover zoom>
		            <img
		                src={this.props.msg.profileUrl +'?' + Date.now()}
		                className="photo img-thumbnail"
		                alt=""
		            />
		        </MDBView>:false}
            </MDBCol>
            <MDBCol>
            	<Link className="contacts-link" to={"/CompaniesPage/" + this.props.msg.companyId}>{this.props.msg.companyName}</Link>
				<address>
                	{this.props.msg.postcode} {this.props.msg.town}<br/>
					{this.props.msg.street}
				</address>
            </MDBCol>


		</MDBRow>
	<hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>

						</MDBCardBody>
</MDBCard>: false}

{this.props.msg.relationshipType ==="Über Uns Text" ?
<MDBCard className="interb2b-card">
	<MDBCardTitle>
		{this.props.msg.product === undefined ?
		<>{this.props.msg.relationshipType}: {this.props.productName}</> : <>

		{this.props.msg.relationshipType}: {this.props.msg.product[0]}

		</>}
		<MDBBadge pill color="light" className="float-right">
			<i className="far fa-calendar-alt"></i> {Moment(this.props.msg.time).locale("de").format('DD MMMM YYYY')} <i className="far fa-clock"></i> {Moment(this.props.msg.time).format('h:mm a')}

		</MDBBadge>
	</MDBCardTitle>
	<MDBCardBody>
		<MDBRow>
		<MDBCol md="auto" className="text-center">
		{this.props.msg.companyId !==undefined?
		<MDBView hover zoom>
		<img
		src={this.props.msg.profileUrl +'?' + Date.now()}
		className="photo img-thumbnail"
		alt=""
		/>
		</MDBView>:false}
		</MDBCol>
		<MDBCol>
		<Link className="contacts-link" to={"/CompaniesPage/" + this.props.msg.companyId}>{this.props.msg.companyName}</Link>
		<address>
		{this.props.msg.postcode} {this.props.msg.town}<br/>
		{this.props.msg.street}
		</address>
		</MDBCol>
		</MDBRow>
		<hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>
		<MDBRow>
		<MDBCol>
		{this.props.msg.aboutUs !== '' ?
		<>
		{this.state.textstatus === true ?
			<div className={this.state.readState + ' px-2 pt-2'} id={'read-' + this.props.msg.id}>{text}</div>:
			<div className="px-2 pt-2">{this.truncateString(text, 105)}</div>}
		{text.length > 105 ?
		<MDBBtn flat
			className='btn-block btn-sm mb-0 pb-1'
			onClick={() => {
				this.handleReadmore(this.props.msg.id);
			}}
			style={{ marginBottom: "1rem",	textAlign:"left",	paddingLeft:"9px" }}
		>
			<span style={{textTransform: "capitalize"}}>{this.state.buttonName} </span>
			<MDBIcon icon={this.state.iconName} />
		</MDBBtn>:false}
		</>:false}
		</MDBCol>
		</MDBRow>
		<hr style={{marginTop: ".5rem", marginBottom: ".5rem"}}/>
	</MDBCardBody>
</MDBCard>:false}

</>

	);
  }
}

export default CompanysItemToNewFeed;
