import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import logo from "../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import addBike from "../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import signinmarkt from "../../assets/media/images/markt/signinmarkt.png";
import signupmarkt from "../../assets/media/images/markt/signupmarkt.png";
import anmelden from "../../assets/media/images/markt/anmelden.png";
import searchnormalicon from "../../assets/media/images/markt/searchnormalicon.png";
import logo2 from "../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../assets/media/images/markt/Group 22.png";
import logo4 from "../../assets/media/images/markt/Group 19.png";
import logo5 from "../../assets/media/images/markt/Group 20.png";
import profile_Boda from "../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../assets/media/images/b2b/Group 8.png";
import group_21 from "../../assets/media/images/markt/Group 21.png";
import rectangle_1 from "../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../assets/media/images/b2b/Interb2b.png";
import InterB2BAxios from '../../Service/InterB2BAxios';

import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import BikePartsLinkNavItem from './BikePartsLinkNavItem';
import Cookies from 'universal-cookie';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
class InserierenNav extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'LandingPage');
    this.state = {
									collapseID: -1, user: {}, redirect: false
									};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.refresh();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
		this.onGetUser();
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}
	onSearchProductB2B = event => {
		this.setState({redirect: true});
	}
	onGetUser = () => {
		let self = this;
		this.interAxios.get('/api/user/anzeigeUserById/' + this.props.userData.userId(), function(response) {
			self.setState({user: response.data});
		})
	};

	onSearchProductB2B = event => {
		this.setState({redirect: true});
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>

			<div className="landingmarkt-nav-container fixed-top-landingmarkt">
			<MDBContainer style={{maxWidth: "1250px"}}>
					<MDBNav className="landingmarkt-nav">
						<MDBRow className="m-0" style={{width: "100%"}}>
							<MDBCol middle md="auto" className="text-left first-col px-0">
								<MDBRow className="m-0" start>
									<MDBNavItem>
										<MDBNavLink link to="/LandingPageMarkt" role="tab">
											<img alt="" className="ebike-logo" src={logo}></img>
										</MDBNavLink>
									</MDBNavItem>
									<MDBNavItem>
										<MDBRow center className="p-0 m-0">
											<div className="product-search-markt-header">
												<form className= ""
															onSubmit={async (event) => {await this.onSearchProductB2B(event);}}>
													<MDBRow center className="m-0">
														<MDBCol top md="10" className="" middle>
															<input
																type="text"
																className="form-control"
																id="formGroupExampleInput2"
																placeholder={t('produkt_eingeben_3')}
																name="textBike"
																onChange={this.props.userData.onTextSearchChange}
																value={this.props.userData.textBike}
															/>
														</MDBCol>
														<MDBCol top md="2" className="px-0" >
															<MDBBtn type="submit"
																			className="borse-btn-header"
																			><img src={searchnormalicon} className="vector-1"/>
															</MDBBtn>
														</MDBCol>
													</MDBRow>
												</form>
											</div>
										</MDBRow>
									</MDBNavItem>
								</MDBRow>
							</MDBCol>
							<MDBCol middle style={{width: "100%"}} className="text-right second-col px-0">
								<MDBRow className="m-0" end style={{width: "100%"}}>
								<BikePartsLinkNavItem userData={this.props.userData} />
									<MDBNavItem>
										<MDBRow className="m-0" style={{height: "100%"}}>
										<MDBCol middle>
										{!this.props.userData.isLoggedInMarkt() ?
											<MDBDropdown>
											<MDBDropdownToggle nav id="dorpdown" className="signin-btn-landingmarkt">
												<img alt="" className="anmelden-logo" src={anmelden}></img>
											</MDBDropdownToggle>
											<MDBDropdownMenu  className="text-center">
												<div id="userdiv">
												<Link to="/SignInMarkt"><MDBDropdownItem id="userprofile" >
												<MDBBtn
													flat
													onClick={() => this.props.userData.onLogout()}
													className="p-0 m-0"
												>
													<img alt="" className="signinmarkt-logo" src={signinmarkt}></img><span className="anmelden-text text-capitalize pl-2">{t('anmelden')}</span>
												</MDBBtn>
												</MDBDropdownItem></Link>
												</div>
												<div id="userdiv">
												<Link to="/SignUpMarkt"><MDBDropdownItem id="userprofile">
												<MDBBtn
													flat
													onClick={() => this.props.userData.onLogout()}
													className="p-0 m-0"
												>
													<img alt="" className="signinmarkt-logo" src={signupmarkt}></img><span className="signup-text text-capitalize pl-2">{t('registrieren')}</span>
												</MDBBtn>
												</MDBDropdownItem></Link>
												</div>
											</MDBDropdownMenu>
											</MDBDropdown>:

											<Link to="/Eintraege" className="signin-btn-landingmarkt">
											{this.props.userData.getCurrentCompany()?.profileUrl !== undefined ?
												<img className="userProfileName-icon"
													src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + this.state.timeStamp}
													/>:<>
													{this.props.userData.getMyUser()?.profileUrl !== undefined ?
														<img alt="" className="user-photo-navbar" src={this.props.userData.getMyUser()?.profileUrl +'?' + Date.now()}></img>:
														<div className="userProfileName-icon">{this.props.userData.userProfilName()}</div>}</>}
											</Link>}
										</MDBCol>
										</MDBRow>
									</MDBNavItem>
								</MDBRow>
							</MDBCol>
						</MDBRow>
					</MDBNav>
				</MDBContainer>
				</div>
				{this.state.redirect ?
				<Redirect
				to={{		pathname: "/BoersePage",
								state: { activeItem: this.state.activeItemKategorie, bikeKategorie: this.state.bikeKategorie }}}/>:false}
		</>
    );
  }
}

export default  withTranslation()(InserierenNav);
