import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import 'moment/locale/de';
import Ckeditor from './Ckeditor';
import CommentItem from './CommentItem';
import LikeItem from './LikeItem';
import logo from "../../assets/media/logo/1.png";
import likeicon from "../../assets/media/newicons/like.png";
import like2icon from "../../assets/media/newicons/like2.png";
import commenticon from "../../assets/media/newicons/clipboard-text.png";
import { UserStatus } from "../../Config/Config";
import InterB2BAxios from '../../Service/InterB2BAxios';
import Cookies from 'universal-cookie';
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import { Link } from "react-router-dom";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardTitle,
	MDBCardBody,
	MDBBtn,
	MDBCollapse,
	MDBView,
	MDBMask,
	MDBContainer,
	MDBIcon,
	MDBBtnGroup,
	MDBInput,
	MDBBadge,
	MDBPopper,
	MDBListGroup

} from 'mdbreact';

const cookies = new Cookies();

class MitteilungenItemWithoutLogin extends React.Component {


  constructor(props) {

    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {
		      open: false,
					isOpen: false,
					profileImageHash: Date.now(),
					readState: 'read-less',
					companyPhotos: [],
					iconName: "angle-double-right",
					q: -1,
					maxheight: 142.3,
					textstatus: false,
		    };
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.onReadmore();
		if(this.props.msg.mitteilungenPhotosUrl !== undefined && this.props.msg.mitteilungenPhotosUrl.length > 0){
			this.loadImage(this.props.msg.mitteilungenPhotosUrl[0]);
		}else{
			if(this.props.msg.videoLink !== undefined && this.props.msg.videoLink !== ''){
				this.setState({q: 16/9});
			}
		}
		this.onMitteilungenCompany(this.props.msg.companyId);

	};

	componentDidUpdate(prevProps, prevState) {
			//this.onReadmore();
	 };

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	handleClick = () =>{
    this.setState( (prevState: { open: any; }) => ({
      open: !prevState.open
    }))
  }

	onReadmore = () =>{
		const { t } = this.props;
    const client = this.props.client;
		this.setState({buttonName: t('weniger')});
	}

	handleReadmore = (id) =>{
		const { t } = this.props;
    const client = this.props.client;
		const newClassName = this.state.readState === 'read-less' ? 'read' : 'read-less';
		this.setState({readState: newClassName});
		if(newClassName === 'read'){
			this.setState({iconName: "angle-double-left",
									 	 textstatus: true});
		}else{
			this.setState({iconName: "angle-double-right",
									   textstatus: false});
		}
	}

	onIsOpen = () => {
	  this.setState({isOpen: !this.state.isOpen});
	}

	loadImage = imagesrc => {
		const img = new Image();
		let self = this;
		img.onload = function() {
			self.setState({q: this.width / this.height})
		}
		img.src = imagesrc;
	}

	onMitteilungenCompany = (id) =>{
    var self = this;
    this.interAxios.get('/api/findCompanyById/' + id, function(response) {
          self.setState({mitteilungenCompany: response.data});
    });
	};


	toggleCollapse = collapseID => () => {
	  this.setState(prevState => ({
	    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
	  }));
	}

	truncateString = (str, sum) => {
		if(str !== null){
			if (str.length <= sum) {
				//this.setState({buttonstatus: false});
				return str
	  	}
					//this.setState({buttonstatus: true});
					return str.slice(0, sum) + '...'

		}
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    const {open} = this.state
		var images = [];
		var imagesplus = 0;
		var i=1;
		if (this.props.msg.mitteilungenPhotosUrl.length>0){
			for (const mitteilungenPhoto of this.props.msg.mitteilungenPhotosUrl){
					images.push({	src: mitteilungenPhoto });

				i++;
			}
			imagesplus = (i - 2);
		}
		let imagewidth = 900*this.state.q/(9*this.state.q+16);
		let videowidth = (100-imagewidth);
		let imagepercent = imagewidth +'%';
		let videopercent = videowidth +'%';
		var text ="";


		if (this.props.msg.article !== ''){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.props.msg.article, 'text/html');
			text =parsedHtml.documentElement.textContent;
		}
		let prevDate = Moment(Date.now());
    let prevDate1 = Moment(Date.now()).subtract(1, 'days');
    let prevDate2 = Moment(Date.now()).subtract(2, 'days');
    let tsmoment = Moment(this.props.msg.time);
    return (

		<MDBCard className="interb2b-card">
		<MDBCardBody>
		<MDBRow className="py-3 align-items-center">
		<MDBCol size="auto" sm="auto" md="auto" lg="auto" xl="auto" className="pr-0">
			<div className="avatar avatar-card">
				<img alt="" className="photo" src={this.state.mitteilungenCompany?.profileUrl +'?' + Date.now()}></img>
			</div>
		</MDBCol>
		<MDBCol>
		<Link className="contacts-link" to="#" style={{color: "#000"}}><p className="font-weight-normal mb-0">{this.state.mitteilungenCompany?.name}</p></Link>
		{tsmoment.isBefore(prevDate2, 'day') ?
		<span className="font-weight-normal text-muted">{tsmoment.locale(this.props.i18n.language).format('DD MMMM YYYY')}</span>:false}
		{tsmoment.isSame(prevDate2, 'day') ?
		<span className="font-weight-normal text-muted">vor 2 Tagen</span>:false}
		{tsmoment.isSame(prevDate1, 'day') ?
		<span className="font-weight-normal text-muted">gestern</span>:false}
		{tsmoment.isSame(prevDate, 'day') ?
		<span className="font-weight-normal text-muted">{tsmoment.format('h:mm a')}</span>:false}
		</MDBCol>
		</MDBRow>
		{this.props.msg.title.length>0 ?
		<MDBRow>
		<MDBCol>
		<h5 className="font-weight-bold mb-3">
		<strong>{this.props.msg.title}</strong>
		</h5>
		</MDBCol>
		</MDBRow>:false}
		<MDBRow>
			<MDBCol>
				{images.length>0 ?

				<div style={{float: "left", width: imagepercent}} className="imagepercent-status">
					<a href="#!"  onClick={this.onIsOpen}>
						<img
							className="img-fluid"
							src={images[0].src}
							alt=""
							/>
					</a>
					{imagesplus > 0 ?
					<h6 style={{position: 'absolute', bottom: '-8px', left: '0px', backgroundColor: "#fff"}}>
						+ {imagesplus} <i className="far fa-image"></i>
					</h6>:false}
					<LightboxPageToMitteilungen
						images={images}
						isOpen={this.state.isOpen}
						onIsOpen={this.onIsOpen}/>
				</div>:false}
				{this.props.msg.videoLink.length>0 ?
				<div style={{float: "left", width:videopercent}} className="embed-responsive embed-responsive-16by9 videopercent-status">
					<iframe className="embed-responsive-item" src={this.props.msg.videoLink} allowFullScreen autoPlay></iframe>
				</div>:false}
			</MDBCol>

		</MDBRow>
		<MDBRow>

			<MDBCol>

				{this.props.msg.article !== '' ?
				<>
				{this.state.textstatus === true ?
					<div className={this.state.readState + ' pt-2'} id={'read-' + this.props.msg.id}>{text}</div>:
					<div className="pt-2">{this.truncateString(text, 105)}</div>}

				{text.length > 105 ?
				<MDBBtn flat
					className='btn-block btn-sm mb-0 pb-1 px-0'
					onClick={() => {
						this.handleReadmore(this.props.msg.id);
					}}
					style={{ marginBottom: "1rem",	textAlign:"left",	paddingLeft:"9px" }}
				>
					<span style={{textTransform: "capitalize"}}>{this.state.readState === 'read-less' ? t('weiterlesen') : t('weniger')} </span>
					<MDBIcon icon={this.state.iconName} />
				</MDBBtn>:false}
				</>:false}

			</MDBCol>
		</MDBRow>

					 </MDBCardBody>
		 </MDBCard>
	);
  }
}

export default withTranslation()(MitteilungenItemWithoutLogin);
