import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import { UserStatus } from "../../Config/Config";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import {
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
	MDBBtn,
	MDBCollapse,
	MDBView,
	MDBMask,
	MDBContainer,
	MDBIcon,
	MDBBtnGroup
} from 'mdbreact';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import InterB2BAxios from '../../Service/InterB2BAxios';
const cookies = new Cookies();

class CommentItem extends React.Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {currentCompany: {}, currentUser: {}
		    };
  };

	componentDidMount() {
		if(this.props.msg.companyId !== null){
			this.onCurrentCompany();
		}else{
			this.onCurrentUser(this.props.msg.userId)
		}

  };

	onCurrentUser = (id) => {
		let self = this;
		this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
			self.setState({currentUser: response.data});
		})
	}

	onCurrentCompany = () =>{
    var self = this;
		this.interAxios.get('/api/findCompanyById/' + this.props.msg.companyId, function(response) {
			self.setState({currentCompany: response.data});
		})
  };

  render() {

		let prevDate = Moment(Date.now());
		let prevDate1 = Moment(Date.now()).subtract(1, 'days');
		let prevDate2 = Moment(Date.now()).subtract(2, 'days');
		let tsmoment = Moment(this.props.msg.time);
    return (

				<MDBRow className={"d-flex flex-row"}>
					<MDBCol md="auto" size="auto" style={{paddingRight: "5px"}}>
							<div className="avatar" style={{height: '2.2rem', width: '2.2rem', minWidth: '2.2rem'}}>
								<img alt="" className="photo"
										 src={this.props.msg.companyId !== null ? this.state.currentCompany.profileUrl +'?' + Date.now() : this.state.currentUser.profileUrl +'?' + Date.now()}/>
							</div>
					</MDBCol>
					<MDBCol md="auto" size="auto" className="px-2">

						<div className="mitteilungen-msg-content">
							<MDBRow className="align-items-center pl-3"><div className="oval"/>
																 <p className="mitteilungen-name pl-1">{this.props.msg.companyId !== null ? this.state.currentCompany.name : this.state.currentUser.name}</p>
							</MDBRow>
							<div dangerouslySetInnerHTML={{__html: this.props.msg.content}}/>
						</div>
					</MDBCol>
					<MDBCol md="auto" size="auto" className="text-center">
					<MDBRow end>
						{tsmoment.isBefore(prevDate2, 'day') ?
						<small className="font-weight-normal text-muted">{tsmoment.locale(this.props.i18n.language).format('DD-MM-YYYY')}</small>:false}
						{tsmoment.isSame(prevDate2, 'day') ?
						<small className="font-weight-normal text-muted">vor 2 Tagen</small>:false}
						{tsmoment.isSame(prevDate1, 'day') ?
						<small className="font-weight-normal text-muted">gestern</small>:false}
						{tsmoment.isSame(prevDate, 'day') ?
						<small className="font-weight-normal text-muted">{tsmoment.format('h:mm a')}</small>:false}
					</MDBRow>
					</MDBCol>
				</MDBRow>



    );
  }
}

export default withTranslation()(CommentItem);
