import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import Flags from 'country-flag-icons/react/3x2'
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import arrowDown from '../../assets/media/images/markt/arrowDown.png';
import arrowUp from '../../assets/media/images/markt/arrowUp.png';
import konto from "../../assets/media/images/markt/profil/konto.png";
import konto_gelb from "../../assets/media/images/icons/konto.png";
import chat_gelb from "../../assets/media/images/icons/Vector.png";
import chat from "../../assets/media/images/markt/profil/chat.png";
import wheel from "../../assets/media/images/markt/profil/wheel.png";
import wheel_gelb from "../../assets/media/images/icons/wheel.png";
import invitation from "../../assets/media/images/icons/Vector-4.png";
import invitation_gelb from "../../assets/media/images/icons/Vector-14.png";
import management from "../../assets/media/images/icons/Vector-3.png";
import management_gelb from "../../assets/media/images/icons/Vector-13.png";
import unternehmer_gelb from "../../assets/media/images/icons/profilInfoOrange.png";
import unternehmer from "../../assets/media/images/icons/profilInfoGrey.png";





import Footer from '../Footer/Footer2';
import InterB2BAxios from '../../Service/InterB2BAxios';
import moment from 'moment';
import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
class ProfilpageNavRespob2b extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'LandingPage');
    this.state = {
									};
  };





	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		//this.props.userData.refresh();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}



  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<MDBCard className="profilepage-card-respo-b2b">
			<div className="profilpage-hauptmenu text-center">
				<MDBNav className="landingmarkt-nav">
					{this.props.userData.isCurrentCompanyAnonim() && this.props.userData.getMyInvitationslist() === undefined ?
					<>
						<MDBNavItem>
							<MDBNavLink link to="/NewCompanyB2B" active={this.props.activeItem === "1"} role="tab" >
								<img alt="" className="profil-logo" src={this.props.activeItem === "1" ? unternehmer_gelb : unternehmer}></img>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="/KontoB2B" active={this.props.activeItem === "6"} role="tab" >
								<img alt="" className="profil-logo" src={this.props.activeItem === "6" ? konto_gelb : konto}></img>
							</MDBNavLink>
						</MDBNavItem>
					</>:
					<>
						{this.props.userData.getMyInvitationslist() !== undefined && this.props.userData.getMyInvitationslist().length>0 ?
						<>
							<MDBNavItem>
								<MDBNavLink link to="/InvitationB2BIn" active={this.props.activeItem === "9"} role="tab" >
									<img alt="" className="profil-logo" src={this.props.activeItem === "9" ? invitation_gelb : invitation}></img>
								</MDBNavLink>
							</MDBNavItem>
							<MDBBadge className="invitationBadge" pill color="danger" style={{position: 'absolute', right: '40px', bottom: '133px', zIndex: 10, border: '2px solid #fff'}}>{this.props.userData.getMyInvitationslist().length}</MDBBadge>
							<MDBNavItem>
								<MDBNavLink link to="/KontoB2B" active={this.props.activeItem === "6"} role="tab" >
									<img alt="" className="profil-logo" src={this.props.activeItem === "6" ? konto_gelb : konto}></img>
								</MDBNavLink>
							</MDBNavItem>
						</>:
						<>
							{!this.props.userData.isCurrentCompanyAnonim() && (this.props.userData.getMyInvitationslist() === undefined || this.props.userData.getMyInvitationslist().length===0) ?
							<>
								<MDBNavItem>
									<MDBNavLink link to="/MyProfilePageB2B" active={this.props.activeItem === "2"} role="tab" >
										<img alt="" className="profil-logo" src={this.props.activeItem === "2" ? unternehmer_gelb : unternehmer}></img>
									</MDBNavLink>
								</MDBNavItem>
									{this.props.userData.hasMoreCompanies() ?
									<MDBNavItem>
										<MDBNavLink link to="/MyCurrentCompany" active={this.props.activeItem === "3"} role="tab" >
											<img alt="" className="profil-logo" src={this.props.activeItem === "3" ? wheel_gelb : wheel}></img>
										</MDBNavLink>
									</MDBNavItem>:false}
								<MDBNavItem>
									<MDBNavLink link to="/EintraegeB2B" active={this.props.activeItem === "4"} role="tab" >
										<img alt="" className="profil-logo" src={this.props.activeItem === "4" ? wheel_gelb : wheel}></img>
									</MDBNavLink>
								</MDBNavItem>
								<MDBNavItem>
									<MDBNavLink link to="/ChatsBikeB2B" active={this.props.activeItem === "5"} role="tab" >
										<img alt="" className="profil-logo" src={this.props.activeItem === "5" ? chat_gelb : chat}></img>
									</MDBNavLink>
								</MDBNavItem>
								<MDBNavItem>
									<MDBNavLink link to="/KontoB2B" active={this.props.activeItem === "6"} role="tab" >
										<img alt="" className="profil-logo" src={this.props.activeItem === "6" ? konto_gelb : konto}></img>
									</MDBNavLink>
								</MDBNavItem>
									{this.props.userData.isCurrentCompanyModerator()  ? <>
									<MDBNavItem>
										<MDBNavLink link to="/VerwaltungB2B" active={this.props.activeItem === "8"} role="tab" >
											<img alt="" className="profil-logo" src={this.props.activeItem === "8" ? management_gelb : management}></img>
										</MDBNavLink>
									</MDBNavItem>
									<MDBNavItem>
										<MDBNavLink link to="/InvitationB2B" active={this.props.activeItem === "8"} role="tab" >
											<img alt="" className="profil-logo" src={this.props.activeItem === "8" ? invitation_gelb : invitation}></img>
										</MDBNavLink>
									</MDBNavItem></>:false}
							</>:false}
						</>}
					 </>}
				</MDBNav>
			</div>
			</MDBCard>
		</>
    );
  }
}

export default  withTranslation()(ProfilpageNavRespob2b);
