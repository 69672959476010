import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import styles from '../../../assets/scss/modules/profilpage.module.scss';

import ChatListBikeB2B from "../ChatList/ChatListBikeB2B";
import { Picker } from "emoji-mart";
import { UserStatus } from "../../../Config/Config";
import { ThemeColor } from "../../../Config/Config";
import ChatHistoryBikeB2B from "../ChatHistory/ChatHistoryBikeB2B";
import InterB2BAxios from '../../../Service/InterB2BAxios';
import SonstigeNavB2B from '../../NavbarComponentB2B/SonstigeNavB2B';
import SonstigeNavB2BHersteller from '../../NavbarComponentB2B/SonstigeNavB2BHersteller';
import ProfilpageNavB2B from '../../NavbarComponentB2B/ProfilpageNavB2B';
import ProfilpageNavB2BHersteller from '../../NavbarComponentB2B/ProfilpageNavB2BHersteller';
import { Scrollbar } from "react-scrollbars-custom";

import logo from "../../../assets/media/logo/1.png";
import mailicon from "../../../assets/media/newicons/mail.png";
import phoneicon from "../../../assets/media/newicons/phone.png";
import sendmsgicon from "../../../assets/media/images/b2b/send_Orange.png";
import galleryicon from "../../../assets/media/newicons/gallery.png";
import attachicon from "../../../assets/media/newicons/attach.png";
import folderopenicon from "../../../assets/media/images/markt/profil/folder-open-green.png";
import smileysicon from "../../../assets/media/images/markt/profil/smileys.png";
import trahsicon from "../../../assets/media/newicons/trash.png";
import plusimgicon from "../../../assets/media/images/markt/profil/gallery.png";
import peoplewhiteicon from "../../../assets/media/newicons/peoplewhite.png";

import{
	MDBContainer,
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
	MDBListGroup,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBBadge,
	MDBFileInput,
	MDBTooltip
} from 'mdbreact';

import Cookies from 'universal-cookie';


const cookies = new Cookies();
let chatroomid = '';
class ChatsBikeB2BDesktop extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {rooms: [], messages: [],
                  token : cookies.get('authtoken').token,
                  userId: cookies.get('authtoken').userId,
									//selectedChatroom: '',
							    message: "",
									images: [],
									selectedFiles: [],
									file: '',
									notzip: false,
									userIsAdmin: false,
									companieslist: [],
									site: 0,
									emojiIsHidden: true,
									containerHeight: 0,
									imagescontainer: false,
									bikeProduct: {}
									};


  };

  componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.refreshNavbar();
    var params = window.location.href.split("/");
    chatroomid = params[params.length - 1];
    this.props.addFunctionFromChild(this.onMessage);
    if (isFinite(chatroomid)){
      this.selectChatroom(undefined, chatroomid);
      this.loadMessages(chatroomid);
    }
    this.loadRooms();
  };

	componentDidUpdate(prevProps, prevState) {
			var params = window.location.href.split("/");
			chatroomid = params[params.length - 1];
			if (isFinite(chatroomid) && this.state.selectedChatroomId !== chatroomid) {
				this.onCurrentChatroom(chatroomid);
			}
	 };

  componentWillUnmount() {
		this.interAxios.cancel();
    this.props.removeFunctionFromChild(this.onMessage);
  }

  handleChatClick = (chatroomid) => {
    this.selectChatroom(undefined, chatroomid);
    this.loadMessages(chatroomid);

  };

  loadRooms = () => {
    var self = this;
		this.interAxios.get('/api/chat/bike/roomsb2b', function(response)
		{
			self.setState({rooms: response.data});
		});
  };

  loadMessages = (chatroomid) => {
    if (chatroomid !== undefined) {
      var self = this;
			this.interAxios.get('/api/chat/bike/room/'+ chatroomid +'/messages', function(response)
			{
				self.setState({messages: response.data});
				self.loadRooms();
			});
    }
  };

  refreshAll = (chatroomid) => {
		if(chatroomid !== "ChatsBikeB2B"){
			this.loadMessages(chatroomid);
			this.onCurrentChatroom(chatroomid);
		}
		this.loadRooms();
  };

  selectChatroom = (event, chatroomid) => {
    this.setState({selectedChatroomId: chatroomid})
    this.refreshAll(chatroomid);
    this.isAdmin(chatroomid);
  };

  onMessage = (message) => {
    var params = window.location.href.split("/");
    chatroomid = params[params.length - 1];
    let type = message.type;
    if (type === 'OTHER') {
      this.refreshAll(chatroomid);
    } else {
			if(type === 'MESSAGE'){
      let messageId = message.id;
      let messageChatRoomId = message.chatRoomId;
      if (this.state.selectedChatroomId == messageChatRoomId) {
        var self = this;
				this.interAxios.get('/api/chat/bike/messages/'+messageId, function(response)
				{
					let message = response.data;
					let messages = self.state.messages;
					messages.push(message);
					self.setState({messages: messages});
					self.loadMessages(messageChatRoomId);
				});
      } else {
        this.loadRooms();
      }
		}
    }
  };

  isAdmin = (chatroomid) => {
    let self = this;
		this.interAxios.get('/api/chat/bike/room/' + chatroomid + '/isAdmin', function(response)
		{
					self.setState({userIsAdmin: response.data})
		});
  };

  sendMessage = async (event, msg, images, file) => {
    event.preventDefault();
		if (file !== '') {
			const reader = new FileReader()
			let binary = await new Response(file).arrayBuffer();
			const b64 = btoa(new Uint8Array(binary).reduce(function (data, byte) {
    		return data + String.fromCharCode(byte);
			}, ''));
			//const b64 = btoa(String.fromCharCode.apply(null, new Uint8Array(binary)));
			let chatMessage = {
							senderId: this.state.userId,
							chatRoomId: this.state.selectedChatroomId,
							content: msg,
							file: b64,
							fileName: file.name
					};
			this.props.sendMessage(chatMessage);
		} else if (images !== '') {
			const reader = new FileReader();
			let imagesList = [];
			let imageNames = [];
			for (let i = 0; i < images.length; i++){
	 			let binary = await new Response(images[i]).arrayBuffer();
				let b64 = btoa(new Uint8Array(binary).reduce(function (data, byte) {
    			return data + String.fromCharCode(byte);
				}, ''));
				//let b64 = btoa(String.fromCharCode.apply(null, new Uint8Array(binary)));
				imagesList.push(b64);
				imageNames.push(images[i].name);
			}
			let chatMessage = {
							senderId: this.state.userId,
							chatRoomId: this.state.selectedChatroomId,
							content: msg,
							imageNames: imageNames,
							images: imagesList
					};
			this.props.sendMessage(chatMessage);
		} else {
	    let chatMessage = {
	            senderId: this.state.userId,
	            chatRoomId: this.state.selectedChatroomId,
	            content: msg
	        };
	    this.props.sendMessage(chatMessage);
		}
  };

  revoke = (messageid) => {
    let self = this;
		this.interAxios.get('/api/chat/bike/messages/'+messageid+'/deleteeverybody', function(response)
		{
					self.loadMessages(self.state.selectedChatroomId)
		});
  };

  deleteMySide = (messageid) => {
    let self = this;
		this.interAxios.get('/api/chat/bike/messages/'+messageid+'/deletemyside', function(response)
		{
					self.loadMessages(self.state.selectedChatroomId)
		});
  };

	onCurrentChatroom = (chatroomid) =>{
    var self = this;
    this.setState({selectedChatroomId: chatroomid});
		this.isAdmin(chatroomid);
		this.interAxios.get('/api/chat/bike/currentRoom/'+ chatroomid, function(response)
		{
			self.setState({selectedChatroom: response.data});
			self.onGetUser(response.data.partnerId);
			self.onGetBike(response.data.anzeigeId);
		});
  };
	onGetUser = (id) => {
    let self = this;
    this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
      self.setState({senderUser: response.data});
    })
  };

	onGetBike= (id) => {
		let self = this;
		if(id !== 0){
			this.interAxios.get('/api/bikeProductById/' + id, function(response) {
				self.setState({bikeProduct: response.data});
			})
		}else{
			this.setState({bikeProduct: {}});
		}
	};

	/*onImagesSelected = (e) => {
		this.setState({ images: e.target.files });
	}*/

	onDocumentSelected = (e) => {
		if (e.target.files[0].name.endsWith('.zip')) {
			this.setState({ file: e.target.files[0] });
		}else
		{this.setState({ notzip: true });
		}
	}

	onReset =() => {
		this.setState({ file: '',
										images: []});
	}

	handleMessageChange = (event) => {
		this.setState({ message: event.target.value });
	};

	handleEmojiSelect = (param) => {
		this.setState({ message: this.state.message + param.native });
	};

	onChange = (event) => {
		let files = Array.from(event.target.files);
		this.setState({ uploading: true });
		const formData = new FormData();
		files.forEach((file,i) => {
			formData.append(i, file)
		});
		axios.post(process.env.REACT_APP_BACKEND_URL+'/upload_file', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': 'Bearer ' + this.state.token
			}
		}).then(function(response) {

		}).catch(function(error) {
			console.log(error)
		});
	};

	onFileChange = (e) => {
    let list = this.state.images;
		// itt volt egy olyan hiba, hogy a list.push nem function ergo a list valószínűleg null/undefined maradt (valamiért). Nem jött elő többször
		if (list instanceof Array) {
			//console.log(typeof list);
	    for (let i = 0; i < e.target.files.length; i++) {
	      list.push(e.target.files[i]);
	    }
	    this.setState({images: list});
		}
  };

	onResetPhotos = (photo) => {
		var photos = [];
		for(let j = 0; j < this.state.images.length; j++) {
			if(this.state.images[j].name !== photo){
				photos.push(this.state.images[j]);
			}
		}

		this.setState({images: photos});
	}

	onSelectedFilesDelete = () => {
		this.setState({images: []});
	}

	renderPhotos = (source) => {
		let ret = source.map((photo) => {
			return <div key={photo.url} className="py-2 pl-0 pr-2">
						 <img style={{ height: '65px'}} src={photo.url} className="photos-content"/>
						 <MDBBtn
									tag="a"
									className="m-0"
									floating
									color="elegant"
									onClick={() => this.onResetPhotos(photo.name)}
									style={{height: "auto", width: "auto", position: 'absolute', top: '12px', right: '14px'}}>
							 <img src={trahsicon} style={{width: "60%"}} className="py-1"/>
						 </MDBBtn>
						 </div>
		});
		return ret;

	}

	onPcHeaderActive = () => {
		this.setState({pcHeaderActive: true});
		//this.props.onZindexTrue();
	}

	onPcHeaderInactive = () => {
		this.setState({pcHeaderActive: false});
		//this.props.onZindexFalse();
	}

	onGcHeaderActive = () => {
		this.setState({gcHeaderActive: true});
		//this.props.onZindexTrue();
	}

	onGcHeaderInactive = () => {
		this.setState({gcHeaderActive: false});
		//this.props.onZindexFalse();
	}

	truncateString = (str, num) => {
		if(str !== null){
			if (str.length <= num) {
				return str
	  	}
	  			return str.slice(0, num) + '...'
		}
	};

	emojiToggle = () => {
		this.setState({emojiIsHidden: !this.state.emojiIsHidden});
	}

	handleToggle = () => {
  };

  render() {
		let count = (window.document.body.clientHeight-389)/58.5;
		let countInt = count.toFixed(0);
    let statusText = '';
    switch (this.state.selecetedChatroom?.status) {
      case UserStatus.Online:
        statusText =  "Online";
        break;
      case UserStatus.Offline:
        statusText = "Offline";
        break;
      default:
        break;
    }
		var fotos = [];
		if(this.state.images.length>0){
			fotos = this.state.images.map((file) => ({url: URL.createObjectURL(file), name: file.name}));
		}
		const { t } = this.props;
    const client = this.props.client;
    return (
			<><div className="chat-b2b-completed">
				{this.props.userData.partner() === "Dealer" ?
				<SonstigeNavB2B userData={this.props.userData}/>:false}
				{this.props.userData.partner() === "Supplier" ?
				<SonstigeNavB2BHersteller userData={this.props.userData}/>:false}
				<div className={styles.bodywrapper2}>
						<MDBContainer style={{maxWidth: "1250px"}}>
							<MDBRow className="pt-4 m-0">
								<MDBCol md="auto" className="pr-4">
									{this.props.userData.partner() === "Dealer" ?
									<ProfilpageNavB2B activeItem = {"5"} userData={this.props.userData}/>:false}
									{this.props.userData.partner() === "Supplier" ?
									<ProfilpageNavB2BHersteller activeItem = {"5"} userData={this.props.userData}/>:false}
								</MDBCol>
								<MDBCol className="px-0">
									<MDBRow className="m-0">
										<MDBCol md="8" className="p-1 m-0">
											<MDBCard className="chathistory-card-b2b">
												{chatroomid !== 'ChatsBikeB2B' ?
												<MDBCard className="chat-header-card">
													<MDBRow className="m-0" style={{height: "100%"}}>
													{this.state.selectedChatroom !== undefined ?
														<MDBCol md="auto" middle>
															{this.state.bikeProduct.ebikeProductPhotosUrl !== undefined  ?
															<div>
																<img alt="" className="user-photo-navbar" src={this.state.bikeProduct?.ebikeProductPhotosUrl[0] +'?' + Date.now()}></img>
															</div>:
															<div>
																		{this.state.senderUser?.companyUser ?
																			<img alt="" className="user-photo-navbar" src={this.state.senderUser?.companyProfileUrl +'?' + Date.now()}></img>:<>
																				{this.state.senderUser?.profileUrl !== undefined ?
																					<img alt="" className="user-photo-navbar" src={this.state.senderUser?.profileUrl +'?' + Date.now()}></img>:
																					<div className="userProfileName-icon">{this.state.senderUser?.profilName}</div>}</>}
															</div>}
														</MDBCol>:false}
														<MDBCol middle>
															<MDBRow className="m-0">
																<span className="chat-title-text">{this.state.selectedChatroom?.name}</span>
															</MDBRow>
															<MDBRow className="m-0">
																<span className="chat-name-text">{this.state.senderUser?.name}</span>
															</MDBRow>
														</MDBCol>
													</MDBRow>
												</MDBCard>:false}
												<MDBCard className="chat-messages-card-b2b">
												<MDBCardBody>
												<Scrollbar
													id="scrollbar-b2b"
													style={{ width: "98%", height: "calc(100vh - 17rem)"}}
													maximalThumbYSize = {150}
													noScrollX = {true}
													>
												{chatroomid === 'ChatsBikeB2B' ?
													<div className="d-none d-md-flex flex-column justify-content-center empty-chat ">
														<div className="avatar avatar-lg mb-2">
														{this.props.userData.getCurrentCompany()?.profileUrl !== undefined ?
															<img className="userProfileName-icon"
																src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + this.state.timeStamp}
																/>:<>
																{this.props.userData.getMyUser()?.profileUrl !== undefined ?
																	<img alt="" className="user-photo-navbar" src={this.props.userData.getMyUser()?.profileUrl +'?' + Date.now()}></img>:
																	<div className="userProfileName-icon">{this.props.userData.userProfilName()}</div>}</>}
														</div>
														<h5>{t('wilkommen')}{this.props.userData.getCurrentCompany()?.name}!</h5>
														<p className="text-muted">{t('bitte_wahlen_sie_einen_chat_aus')}</p>
													</div>:<div  className="chat-history-b2b">
																<ChatHistoryBikeB2B
																		userData={this.props.userData}
																		messages={this.state.messages}
																		onSendMessage={this.sendMessage}
																		isAdmin={this.state.userIsAdmin}
																		revoke={this.revoke}
																		deleteMySide={this.deleteMySide}
																		onCurrentChatroom= {this.onCurrentChatroom}
																/>
													</div>}
												</Scrollbar>
												</MDBCardBody>
												</MDBCard>
												{chatroomid !== 'ChatsBikeB2B' && this.state.selectedChatroom?.aktuelleStatus !== 3 ?
												<MDBCard className="chat-input-card">
													{!this.state.emojiIsHidden ?
													<div className="chat-emojiwrapper">
														<MDBContainer>
														<Picker
															className="emoji-picker"
															title={t('wahle_ihrer_emoji')}
															emoji="point_up"
															showPreview={true}
															set="facebook"
															theme={
																this.state.themeColor === ThemeColor.Light
																	? "light"
																	: "dark"
															}
															style={{
																width: "100%",
																maxWidth: "65rem",
															}}
															onSelect={this.handleEmojiSelect}
														/>
														</MDBContainer>
													</div>:false}

													<MDBRow className="m-0">
															<MDBCol bottom className="pr-3 pl-0">
															<div className="input-feld">
																		{this.state.images.length > 0 || this.state.file.name !== undefined ?
																			<>
																				{this.state.images.length > 0 ?
																				<>
																					<div className="form-row align-items-center pl-3">
																						{this.renderPhotos(fotos)}
																						<div className="image-upload">
																							<label htmlFor="file-input-b2b">
																								<img src={plusimgicon} className="plusimg-icon"/>
																							</label>
																							<input id="file-input-b2b" multiple type="file" accept=".jpg,.jpeg,.png" onChange={this.onFileChange}/>
																						</div>
																					</div>
																				</>:
																				<MDBRow className="align-items-center pl-4 pt-2 ">
																					<div className="zip-rectangle text-center pt-1">
																						<MDBRow center className="align-items-center  pt-3">
																							<img src={folderopenicon} style={{width: "25%"}} />
																						</MDBRow>
																						<MDBRow center className="align-items-center  pt-1">
																								{this.state.file.name.length > 10 ?
																								<MDBTooltip
																								 domElement
																								 tag="p"
																								 material
																								 placement="bottom"
																							 >
																											<small  className="file-12-zip">{this.truncateString(this.state.file.name, 10)}</small>
																											<small className="file-12-zip">{this.state.file.name}</small>
																							 </MDBTooltip>:
																							 <small className="file-12-zip">{this.state.file.name}</small>}
																						</MDBRow>
																						<MDBBtn
																								tag="a"
																								className="m-0"
																								floating
																								color="elegant"
																								onClick={this.onReset}
																								style={{height: "auto", width: "auto", position: 'absolute', top: '6px', right: '6px'}}>
																						 <img src={trahsicon} style={{width: "60%"}} className="py-1"/>
																					 </MDBBtn>
																					</div>
																				</MDBRow>}
																			</>:false}

																			<form className= {this.state.images.length > 0 || this.state.file.name !== undefined ? "chat-form no-top-borderradius" : "chat-form"}>

																				<MDBRow>
																					<MDBCol md="9" middle >
																						<input
																							type="text"
																							style={{borderRadius: "10px"}}
																							className="form-control"
																							placeholder={t('nachricht_hier_schreiben')}
																							rows="1"
																							value={this.state.message}
																							onChange={this.handleMessageChange}
																						></input>
																					</MDBCol>
																					<MDBCol md="3" middle>
																						<MDBRow end>
																							{this.state.images.length === 0 && this.state.file.name === undefined ?
																							<>
																							<MDBCol middle md="3" className="m-0 pr-0 pl-1">
																							<label className="btn-flat p-0 m-0" style={{backgroundColor: "transparent"}}>
																									<img className="chat-form-icon" src={attachicon} />
																									<input type="file" hidden accept=".zip" onChange={this.onDocumentSelected}/>
																							</label>
																							</MDBCol>
																							<MDBCol middle md="3" className="m-0 pr-0 pl-1">
																							<div className="image-upload2">
																								<label htmlFor="file-input-b2b">
																									<img className="chat-form-icon" src={galleryicon} />
																								</label>
																								<input id="file-input-b2b" multiple type="file" accept=".jpg,.jpeg,.png" onChange={this.onFileChange}/>
																							</div>
																							</MDBCol>
																							</>:false}
																							<MDBCol middle md="3" className="m-0 pr-0 pl-1">
																							<a
																								className="btn btn-flat btn-transparent p-0 m-0"
																								color="transparent"
																								onClick={() => this.emojiToggle()}
																							>
																								<img className="chat-form-icon" src={smileysicon} />
																							</a>
																							</MDBCol>
																							<MDBCol middle md="3" className="m-0 pl-1">
																							<MDBBtn
																								className="chat-form-btn m-0 p-0"
																								flat
																								type="submit"
																								onClick={(e) => {
																									this.sendMessage(e, this.state.message, this.state.images, this.state.file);
																									this.setState({message: '', images: '', file: ''});
																								}}
																							>
																									<img className="chat-form-icon" src={sendmsgicon} />
																							</MDBBtn>
																							</MDBCol>
																						</MDBRow>
																					</MDBCol>
																			</MDBRow>
																		</form>
																	</div>
																	</MDBCol>
																	<MDBCol bottom md="auto"className="input-img-padding text-right">
																	{this.props.userData.getCurrentCompany()?.profileUrl !== undefined ?
																		<img className="userProfileName-icon"
																			src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + this.state.timeStamp}
																			/>:<>
																			{this.props.userData.getMyUser()?.profileUrl !== undefined ?
																				<img alt="" className="user-photo-navbar" src={this.props.userData.getMyUser()?.profileUrl +'?' + Date.now()}></img>:
																				<div className="userProfileName-icon">{this.props.userData.userProfilName()}</div>}</>}
																	</MDBCol>
																</MDBRow>
												</MDBCard>:false}
											</MDBCard>
										</MDBCol>
										<MDBCol md="4" className="pr-0">
											<ChatListBikeB2B
													userData={this.props.userData}
													handleChatClick={this.handleChatClick}
													rooms = {this.state.rooms}
													handleToggle={this.handleToggle}
													selectedChatroomId = {this.state.selectedChatroomId}
													chatroomid = {chatroomid}
													{...this.props}
											/>
										</MDBCol>
									</MDBRow>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
				</div>
</div>
			</>
    );
  }
}
export default withTranslation()(ChatsBikeB2BDesktop);
