import React, { Component } from "react";
import { MDBContainer,
         MDBBtn,
         MDBModal,
         MDBModalBody,
         MDBModalHeader,
         MDBModalFooter,
         MDBInputGroup,
         MDBInput,
         MDBSelect ,
         MDBSelectInput,
         MDBSelectOptions,
         MDBSelectOption,
         MDBRow

      } from 'mdbreact';
import Cookies from 'universal-cookie';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import InterB2BAxios from '../../Service/InterB2BAxios';
import { Scrollbar } from "react-scrollbars-custom";

const cookies = new Cookies();


class CreateCompany extends Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,
                  show: this.props.show,
                  companyId: this.props.currentCompany.id,
                  name: this.props.currentCompany.name ?? '',
                  fname: this.props.currentCompany.fname ?? '',
                  lname: this.props.currentCompany.lname ?? '',
                  naceCode: this.props.currentCompany.naceCode ?? '',
                  street: this.props.currentCompany.street ?? '',
                  postcode: this.props.currentCompany.postcode ?? '',
                  town: this.props.currentCompany.town ?? '',
                  country: this.props.currentCompany.country ?? '',
                  email: this.props.currentCompany.email ?? '',
                  website: this.props.currentCompany.website ?? '',
                  phonenumber: this.props.currentCompany.phonenumber ?? '',
                  lat: this.props.currentCompany.lat ?? '',
                  lng: this.props.currentCompany.lng ?? '',
                  sektor:this.props.currentCompany.sektor ?? '',
                  oberkategorie:this.props.currentCompany.oberkategorie ?? '',
                  kategorie:this.props.currentCompany.kategorie ?? '',
                  bundesland:this.props.currentCompany.bundesland ?? '',
                  sektorList:[],
                  oberkategorieList:[],
                  kategorieList:[],
                  bundeslandList:[],

                };
  };

  componentDidMount() {
    this.onBundesland();
    this.onSektor();
  };

  componentWillUnmount() {
    this.interAxios.cancel();
  }

   handleSave = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      event.target.className += " was-validated"
      if((form.checkValidity() !== false)){
        this.onUpdateMyCompany();
      }
 }


 onUpdateMyCompany = () => {
   let self = this;
   let article = this.props.article;
   this.interAxios.put('/api/companies/' + this.props.currentCompany.id,
       {name: this.state.name,
        lname: this.state.lname,
        fname: this.state.fname,
        sektor: this.state.sektor,
        oberkategorie: this.state.oberkategorie,
        kategorie: this.state.kategorie,
        street: this.state.street,
        postcode: this.state.postcode,
        town: this.state.town,
        bundesland: this.state.bundesland,
        country: this.state.country,
        phonenumber: this.state.phonenumber,
        email: this.state.email,
        website: this.state.website,
        aboutUs: article,
        lat: this.state.lat,
        lng: this.state.lng},
    function(response) {
      self.props.userData.refresh();
      self.handleClose();
    })
 }


 onOberkategorie = (sektor) => {
   let self = this;
   self.setState({oberkategorieList: []});
   this.interAxios.get('/api/oberkategorie/' + sektor, function(response) {
     self.setState({oberkategorieList: response.data});
   });
 }

 onKategorie = (oberkategorie) => {
   let self = this;
   self.setState({kategorieList: []});
   this.interAxios.get('/api/kategorie/' + oberkategorie, function(response) {
     self.setState({kategorieList: response.data});
   })
 }

 onSektor = () => {
   let self = this;
   this.interAxios.get('/api/sektor', function(response) {
     self.setState({sektorList: response.data});
   });
 }

 onBundesland = () => {
   let self = this;
   self.setState({bundeslandList: []});
   this.interAxios.get('/api/bundesland', function(response) {
     self.setState({bundeslandList: response.data});
   });

 }

 changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

 handleClose = () => {
   this.props.handleClose();
   this.setState({show: false});
 }

 handleShow = () => {
   this.setState({show: true});
 }

 onSetCompanyName = (event) => {
   this.setState({name: event.target.value});

 }

 onSetFirstName = (event) => {
   this.setState({fname: event.target.value});

 }

  onSetLastName = (event) => {
    this.setState({lname: event.target.value});

  }


  onSetSektor = value => {
     this.setState({sektor: value});
     this.onOberkategorie(value);
  };

  onSetOberkategorie = value => {

     this.setState({oberkategorie: value});
     this.onKategorie(value);
  };

  onSetKategorie = value => {
     this.setState({kategorie: value});
  };

  onSetStreet = (event) => {
    this.setState({street: event.target.value});

  }

  onSetPostcode = (event) => {
    this.setState({postcode: event.target.value});

  }

  onSetTown = (event) => {
    this.setState({town: event.target.value});

  }

  onSetBundesland = value => {
     this.setState({bundesland: value});
  };


  onSetCountry = (event) => {
    this.setState({country: event.target.value});

  }

  onSetPhonenumber = (event) => {
    this.setState({phonenumber: event.target.value});

  }

  onSetEmail = (event) => {
    this.setState({email: event.target.value});

  }

  onSetWebsite = (event) => {
    this.setState({website: event.target.value});

  }

  onSetLat = (event) => {
    this.setState({lat: event.target.value});

  }

  onSetLng = (event) => {
    this.setState({lng: event.target.value});

  }

  render() {
    const { t } = this.props;
		const client = this.props.client;
    let sektorLis = {};
    sektorLis = this.state.sektorList.map(
      (o) =>
      <div key={o}>
          {this.state.sektor === o ?
            <MDBSelectOption checked value={o}>{o}</MDBSelectOption>:

            <MDBSelectOption  value={o}>{o}</MDBSelectOption>
          }
      </div>

    );
    let oberkategorieLis = {};
    oberkategorieLis = this.state.oberkategorieList.map(
      (o) =>
      <div key={o}>
            {this.state.oberkategorie === o ?
              <MDBSelectOption checked value={o}>{o}</MDBSelectOption>:

              <MDBSelectOption  value={o}>{o}</MDBSelectOption>
            }
      </div>
    );
    let kategorieLis = {};
    kategorieLis = this.state.kategorieList.map(
      (o) =>
      <div key={o}>
            {this.state.kategorie === o ?
              <MDBSelectOption checked value={o}>{o}</MDBSelectOption>:

              <MDBSelectOption  value={o}>{o}</MDBSelectOption>
            }
      </div>
    );
    let bundeslandLis = {};
    bundeslandLis = this.state.bundeslandList.map(
      (o) =>
      <div key={o}>
            {this.state.bundesland === o ?
              <MDBSelectOption checked value={o}>{o}</MDBSelectOption>:

              <MDBSelectOption  value={o}>{o}</MDBSelectOption>
            }
      </div>
    );
    // MDBModal-nak nincs onShow-ja
    return (
<MDBContainer>
  <MDBModal size="lg"
         isOpen={this.state.show}
         toggle={this.handleClose}>
    <MDBModalHeader toggle={this.handleClose}>
      {t('unternehmen_anlegen')}
    </MDBModalHeader>
    <MDBModalBody>
    <Scrollbar
      id="scrollbar-b2b"
      style={{ width: "98%", height: "calc(100vh - 10rem)"}}
      maximalThumbYSize = {150}
      noScrollX = {true}
      >

      <form className="row g-3 needs-validation" onSubmit={this.handleSave} noValidate>
        <div className="col-md-12">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder={t('firmennamen_eingeben')} name="name" onChange={this.changeHandler} value={this.state.name} id="validationCustom01" required />
            <label htmlFor="validationCustom01" className="form-label">{t('firmenname')}</label>
            <div className="invalid-feedback">{t('bitte_geben_sie_einen_gültigen_irmennamen_an')}</div>
          </div>
        </div>
      {/*  <div className="col-md-6">
          <div className="form-outline">
            <input
              type="text"
              className="form-control text-muted"
              placeholder={t('bitte_vornamen_eingeben')} name="fname" onChange={this.changeHandler} value={this.state.fname}
            />
            <label className="form-label">{t('vorname')}</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-outline">
            <input
              type="text"
              className="form-control text-muted"
              placeholder={t('bitte_nachnamen_eingeben')} name="lname" onChange={this.changeHandler} value={this.state.lname}
            />
            <label className="form-label">{t('nachname')}</label>
          </div>
        </div> */}

    {/*    <div className="col-md-6">
          <div className="form-outline">
        <MDBSelect label={t('sektor')} getTextContent={this.onSetSektor} selected={t('wählen_sie_ihren_sektor')}>
          <MDBSelectInput selected={t('wählen_sie_ihren_sektor')} />
          <MDBSelectOptions>

            {sektorLis}
          </MDBSelectOptions>
        </MDBSelect>
        </div>
      </div>


      <div className="col-md-6">
        <div className="form-outline">
      <MDBSelect label={t('oberkategorie')} getTextContent={this.onSetOberkategorie}>
        <MDBSelectInput selected={t('wählen_sie_ihre_oberkategorie')} />
        <MDBSelectOptions>
          {oberkategorieLis}
        </MDBSelectOptions>
      </MDBSelect>
      </div>
    </div>

    <div className="col-md-12">
      <div className="form-outline">
    <MDBSelect label={t('kategorie')} getTextContent={this.onSetKategorie}>
      <MDBSelectInput selected={t('wählen_sie_ihre_kategorie')} />
      <MDBSelectOptions>
        {kategorieLis}
      </MDBSelectOptions>
    </MDBSelect>
    </div>
  </div> */}

        <div className="col-md-12">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder={t('straße_eingeben')} name="street" onChange={this.changeHandler} value={this.state.street} id="validationCustom04" required />
            <label htmlFor="validationCustom04" className="form-label">{t('straße')}</label>
            <div className="invalid-feedback">{t('bitte_geben_sie_eine_gültige_straße_an')}</div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder={t('ort_eingeben')} name="town" onChange={this.changeHandler} value={this.state.town} id="validationCustom05" required />
            <label htmlFor="validationCustom05" className="form-label">{t('ort')}</label>
            <div className="invalid-feedback">{t('bitte_geben_sie_eine_gültige_ort_an')}</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder={t('postleitzahl_eingeben')} name="postcode" onChange={this.changeHandler} value={this.state.postcode} id="validationCustom06" required />
            <label htmlFor="validationCustom06" className="form-label">{t('PLZ')}</label>
            <div className="invalid-feedback">{t('bitte_geben_sie_eine_gültige_postleitzahl_an')}</div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-outline">
        <MDBSelect label={t('bundesland')} getTextContent={this.onSetBundesland}>
          <MDBSelectInput selected={t('wählen_sie_ihr_bundesland')} />
          <MDBSelectOptions>
            {bundeslandLis}
          </MDBSelectOptions>
        </MDBSelect>
        </div>
      </div>
        <div className="col-md-12">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder={t('land_eingeben')} name="country" onChange={this.changeHandler} value={this.state.country} id="validationCustom08" required />
            <label htmlFor="validationCustom07" className="form-label">{t('land')}</label>
            <div className="invalid-feedback">{t('bitte_geben_sie_eine_gültiges_land_an')}</div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder={t('telefonnummer_eingeben')} name="phonenumber" onChange={this.changeHandler} value={this.state.phonenumber}/>
            <label className="form-label">{t('telefonnummer')}</label>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-outline">
            <input type="email" className="form-control text-muted" placeholder={t('E-Mail_eingeben')} name="email" onChange={this.changeHandler} value={this.state.email}/>
            <label className="form-label">{t('email')}</label>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-outline">
            <input type="text" className="form-control text-muted" placeholder={t('webseite_eingeben')} name="website" onChange={this.changeHandler} value={this.state.website}/>
            <label className="form-label">{t('webseite')}</label>

          </div>
        </div>
        <div className="col-md-6">
          <div className="form-outline">
            <input
              type="text"
              className="form-control text-muted"
              placeholder={t('lat_eingeben')} name="lat" onChange={this.changeHandler} value={this.state.lat}
            />
            <label className="form-label">{t('LAT')}</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-outline">
            <input
              type="text"
              className="form-control text-muted"
              placeholder={t('long_eingeben')} name="lng" onChange={this.changeHandler} value={this.state.lng}
            />
            <label className="form-label">{t('LNG')}</label>
          </div>
        </div>
        <div className="col-12">
          <MDBRow center>
            <button className="btn btn-done-feedback" type="submit">{t('formular_senden')}</button>
          </MDBRow>
        </div>
      </form>
    </Scrollbar>
    </MDBModalBody>

  </MDBModal>
</MDBContainer>
    );
  }
}
export default withTranslation()(CreateCompany);
