//import logo from './logo.svg';
//import './App.css';
import React, { Component } from 'react';

import styles from '../../assets/scss/modules/pages.module.scss';

class ZoomMeetingEnded extends React.Component {
  render() {
    return (
      <div className={styles.bodywrapper}>
        <h1>The meeting has ended.</h1>
      </div>
    );
  }
}

export default ZoomMeetingEnded;
