import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import 'moment/locale/de';
import Ckeditor from './Ckeditor';
import logo from "../../assets/media/logo/1.png";
import likeicon from "../../assets/media/newicons/like.png";
import noImage from "../../assets/media/images/noImage_11.png";
import like2icon from "../../assets/media/newicons/like2.png";
import commenticon from "../../assets/media/newicons/clipboard-text.png";
import deleted from "../../assets/media/images/markt/profil/deleted.png";
import reserved from "../../assets/media/images/markt/profil/reserved.png";
import revoked from "../../assets/media/images/markt/profil/revoked.png";
import bookmark_w from "../../assets/media/images/markt/bookmark-w.png";
import favorite_green from "../../assets/media/images/markt/bookmark.png";
import { UserStatus } from "../../Config/Config";
import InterB2BAxios from '../../Service/InterB2BAxios';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardTitle,
	MDBCardBody,
	MDBBtn,
	MDBCollapse,
	MDBView,
	MDBMask,
	MDBContainer,
	MDBIcon,
	MDBBtnGroup,
	MDBInput,
	MDBBadge,
	MDBPopper,
	MDBListGroup,
	MDBTooltip

} from 'mdbreact';

const cookies = new Cookies();

class BikeAnzeigeKarte extends React.Component {


  constructor(props) {

    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {
					maxheight: 142.3,
					textstatus: false,
					isHovering: false,
					alreadyBookmark: false,
					isFavorite: false,
					user: {}
		    };
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		if(this.props.msg.ebikeProductPhotosUrl !== undefined && this.props.msg.ebikeProductPhotosUrl.length > 0){
			this.loadImage(this.props.msg.ebikeProductPhotosUrl[0]);
			this.onGetUser();
			this.onGetIsFavorite();
		}
	};

	componentDidUpdate(prevProps, prevState) {
	 };

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	loadImage = imagesrc => {
		const img = new Image();
		let self = this;
		img.onload = function() {
			self.setState({q: this.width / this.height})
		}
		img.src = imagesrc;
	}

	onGetUser = () => {
		let self = this;
		this.interAxios.get('/api/user/anzeigeUserById/' + this.props.msg.userFormProduct.id, function(response) {
			self.setState({user: response.data});
		})
	};

	onGetIsFavorite = () => {
		let self = this;
		this.interAxios.get('/api/isMyFavoriteBike/' + this.props.msg.id, function(response) {
			self.setState({isFavorite: response.data});
		})
	};

	handleClick = () =>{
    this.setState({
      open: !this.state.open
    })
  }

	truncateString = (str, sum) => {
		if(str !== null){
			if (str.length <= sum) {
				//this.setState({buttonstatus: false});
				return str
			}
					//this.setState({buttonstatus: true});
					return str.slice(0, sum) + '...'

		}
	}

	handleShowLike = () => {
		this.onNewMitteilungenLike(this.props.msg.id);
	}

	handleMouseHover = () => {
    const { isHovering } = this.state;
    this.setState({ isHovering: !isHovering });
}


  render() {
		const { t } = this.props;
    const client = this.props.client;
    const {open} = this.state
		var images = [];
		if (this.props.msg.ebikeProductPhotosUrl.length>0){
			for (const mitteilungenPhoto of this.props.msg.ebikeProductPhotosUrl){
					images.push({	src: mitteilungenPhoto  +'?' + Date.now()});
			}
		}
		let imagewidth = 900*this.state.q/(9*this.state.q+16);
		let imagepercent = imagewidth +'%';
		var text ="";


		if (this.props.msg.article !== ''){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.props.msg.article, 'text/html');
			text =parsedHtml.documentElement.textContent;
		}
		let prevDate = Moment(Date.now());
    let prevDate1 = Moment(Date.now()).subtract(1, 'days');
    let prevDate2 = Moment(Date.now()).subtract(2, 'days');
    let tsmoment = Moment(this.props.msg.time);

		var profilUrlEnde = "";
		if(this.props.msg.userFormProduct.profileUrl !== undefined){
			var profilUrlEnde = this.props.msg.userFormProduct.profileUrl.split("_")[this.props.msg.userFormProduct.profileUrl.split("_").length - 1];
		}

   	let anzeigename = "";

		if (this.props.msg.userFormProduct.companyUser){
			anzeigename = this.props.msg.userFormProduct.companyName;
		}else {
			let nameArray = this.props.msg.userFormProduct.name.split(" ");
			anzeigename = nameArray[0] + " " + nameArray[1].charAt(0);
		}
 //isVisible= {true}
 
    return (

		<Link className="contacts-link" to={"/BikePage/" + this.props.msg.id} >
		<MDBCard className="bikeanzeige-card">

		{this.state.isFavorite ?
			<img alt="" className="favorite-icon-anzeige-fix" src={favorite_green}/>:false}
			<MDBCardBody className="px-1">
				<MDBRow center className="p-1 m-0 bild-row">
				{this.props.msg.aktuelleStatus !== 1 ?
					<div className="anzeige-status">
						<img alt="" className="status" src={this.props.msg.aktuelleStatus === 2 ? reserved :
							this.props.msg.aktuelleStatus === 3 ? revoked :
							this.props.msg.aktuelleStatus === 4 ? deleted :
							false}/>
					</div>:false}
				{images[0] !== undefined ? <>
							<div className="bookmark-hover d-flex align-items-center justify-content-center">
								{this.state.alreadyBookmark ?
								<img alt="" className="bookmark-img" src={bookmark_w}/>:false}
								<img
								className={"bike-image"}
								src={images[0].src}
								alt=""
								/>
							</div>
							</>:
							<div className="bookmark-hover text-center">
							<img
							className={"bike-image-keinbild"}
							src={noImage}
							alt=""
							/></div>}
					<span className="bike-price">{this.props.msg.preis} €</span>
				</MDBRow>

				{this.props.msg.title.length>0 ?
				<MDBRow start className="px-2 pt-2 m-0">
						{this.props.msg.title.length>13 ?
							<MDBTooltip
							 domElement
							 tag="small"
							 placement="top"
						 >
									<small  className="bike-title text-capitalize ">{this.truncateString(this.props.msg.title, 13)}</small>
									<small  className="bike-title text-capitalize ">{this.props.msg.title}</small>
					 </MDBTooltip>:
					 <small  className="bike-title text-capitalize ">{this.props.msg.title}</small>}
				</MDBRow>:false}

				<MDBRow className="pl-2 pt-3 m-0">
					<MDBCol size="auto" sm="auto" md="auto" lg="auto" xl="auto" className="p-0">
					{this.props.msg.userFormProduct.companyUser ?
						<img alt="" className="user-foto" src={this.props.msg.userFormProduct.companyProfileUrl +'?' + Date.now()}></img>:<>
							{this.props.msg.userFormProduct.profileUrl !== undefined && profilUrlEnde!== "default.jpg" ?
								<img alt="" className="user-foto" src={this.props.msg.userFormProduct.profileUrl +'?' + Date.now()}></img>:
								<div className="userProfileName-icon-anzeige">{this.props.msg.userFormProduct.profilName}</div>}</>}
					</MDBCol>
					<MDBCol middle className="pr-0 pl-2">
					<MDBRow start className="pl-0 pb-0 m-0">
							<span className="username">{anzeigename}</span>
					</MDBRow>
					<MDBRow start className="pl-0 m-0">
						{tsmoment.isBefore(prevDate2, 'day') ?
						<span className="user-datum">{tsmoment.locale(this.props.i18n.language).format('DD-MM-YYYY')}</span>:false}
						{tsmoment.isSame(prevDate2, 'day') ?
						<span className="user-datum">vor 2 Tagen</span>:false}
						{tsmoment.isSame(prevDate1, 'day') ?
						<span className="user-datum">gestern</span>:false}
						{tsmoment.isSame(prevDate, 'day') ?
						<span className="user-datum">{tsmoment.format('h:mm a')}</span>:false}
					</MDBRow>
					</MDBCol>
				</MDBRow>

			{/*	<MDBRow center>
					<MDBCol className="text-center">
						<MDBBtn flat
										className="px-1"
										onClick={this.handleShowLike}
										style={{color:"#000"}}
						>
							<img src={likeicon} style={{width: "25%"}}/><span
								className="text-capitalize"> {t('likes')}</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>*/}
			</MDBCardBody>
		</MDBCard>
		</Link>

	);
  }
}

export default withTranslation()(BikeAnzeigeKarte);
