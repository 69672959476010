import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_first from "../../../assets/media/images/markt/profil/Group 7.png";
import logo from "../../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import addBike from "../../../assets/media/images/markt/E-Bikebörse Logo green.png";
import closebutton from "../../../assets/media/images/markt/closebutton.png";
import respo_ebike_logo from "../../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import hamburgericon from "../../../assets/media/images/markt/hamburgericon.png";
import anmelden from "../../../assets/media/images/markt/anmelden.png";
import signinmarkt from "../../../assets/media/images/markt/signinmarkt_2.png";
import signupmarkt from "../../../assets/media/images/markt/signupmarkt_2.png";
import searchnormalicon from "../../../assets/media/images/markt/searchnormalicon.png";
import logo2 from "../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../../assets/media/images/markt/Group 18.png";
import logo4 from "../../../assets/media/images/markt/Group 19.png";
import logo5 from "../../../assets/media/images/markt/Group 20.png";
import logo6 from "../../../assets/media/images/markt/Group 25.png";
import logo7 from "../../../assets/media/images/markt/E-Bikebörse Logo 3.png";
import profile_Boda from "../../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../../assets/media/images/b2b/Group 8.png";
import group_21 from "../../../assets/media/images/markt/Group 21_2.png";
import rectangle_1 from "../../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../../assets/media/images/b2b/Appentum_2.png";
import studiCore from "../../../assets/media/images/b2b/StudiCore_2.png";
import interb2b from "../../../assets/media/images/b2b/Interb2b_2.png";

import InterB2BAxios from '../../../Service/InterB2BAxios';
import MenuSideNavMarkt from './MenuSideNavMarkt';
import FooterMarktLanding from '../../../components/Footer/FooterMarktLanding';
import GyikModul from "../../../components/Gyik/GyikModul";
import Interb2bNewsItems from '../../../components/News/Interb2bNewsItems';
import MitteilungenItemRespo from '../../../components/Companys/MitteilungenItemRespo';
import MitteilungenItemWithoutLoginRespo from '../../../components/Companys/MitteilungenItemWithoutLoginRespo';
import ChatsMainBike from '../../../components/ChatBike/ChatsMainBike';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../components/LazyLoad/LazyLoadItem';


import styles from '../../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';
import { Scrollbar } from "react-scrollbars-custom";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle,
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBModalFooter,
	MDBSideNav
} from 'mdbreact';
const Parser = require('rss-parser');
const parser = new Parser();
const cookies = new Cookies();
let language = '';
let urlName = '';

class LandingPageMarktResponziv extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefBlog = React.createRef();
		this.listInnerRefNews = React.createRef();
		this.listInnerRefInfo = React.createRef();
		this.listInnerRefContact = React.createRef();
		this.listInnerRefInterB2B = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'LandingPageMarkt');
    this.state = {
								  activeItem: "9",
									navbarPaddingTop: true,
									navbarPaddingMiddle: false,
									navbarPadding: false,
									gyikList: [],
									collapseID: -1,
									mitteilungenlist: [],
									news:{},
									timeStamp: Date.now(),
									redirect: false,
									activeItemKategorie: "1",
									user: {},
									textItem:"",
									isMenuOpen: false,
									navbarPaddingTop: true,
									};
  };

	componentDidMount() {
		this.onRefInterB2B();
		this.props.userData.onTextBikeClear();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
		var params2 = window.location.href.split("/");
    urlName = params2[params2.length - 1];
		document.addEventListener("scroll", this.scrollEventListener);
		this.handlegetAllGyik();
		if(this.props.userData.isLoggedInMarkt()){
			this.onGetMitteilungen();
		}else{
			this.onGetMitteilungenWithoutLogin();
		}
		this.onNews();
	}

	componentWillUnmount() {
		this.interAxios.cancel();
		document.removeEventListener("scroll", this.scrollEventListener);
	}
	toggle = tab => e => {
				this.props.onEnableScrollMain();
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
					if (tab == 4) {
						this.onRefContact();
					}
					if (tab == 3) {
						this.onRefInfo();
					}
					if (tab == 2) {
						this.onRefBlog();
					}
					if (tab == 1) {
						this.onRefNews();
					}
					if (tab == 9) {
						this.onRefInterB2B();
					}
				}

	}

	onEmailChange = (event) =>{
    this.setState({email: event.target.value});
  };

  onSubmit = (event) =>{
    event.preventDefault();
    let self = this;
    this.interAxios.post('/api/email/informations', {email: this.state.email}, function(response) {
     })
  };

	onRefNews = () => {
		this.listInnerRefNews.current.scrollIntoView({

 	 });
	}

	onRefBlog = () => {

		this.listInnerRefBlog.current.scrollIntoView({

	 });
	}

	onRefInfo = () => {

		this.listInnerRefInfo.current.scrollIntoView({

	 });
	}

	onRefContact = () => {

		this.listInnerRefContact.current.scrollIntoView({

	 });
	}

	onRefInterB2B = () => {
		this.listInnerRefInterB2B.current.scrollIntoView({
	 });

	}


	handlegetAllGyik = () => {
	 let self = this;
	 this.interAxios.get('/getallgyik',
		function(response) {
			self.setState({gyikList: response.data})
		});
	}

	handleNewGyik = () => {
	 let self = this;
	 event.preventDefault();
	 this.interAxios.post('/newgyik',
			 {kerdes: "The best fitness tracker on tot even better?",
				valasz: "Deutschlands Marktplatz Nr. 1 für neue und gebrauchte E-BikesDeutschlands Marktplatz Nr. 1 für neue und gebrauchte E-BikesDeutschlands Marktplatz Nr. 1 für neue und gebrauchte."},
		function(response) {

		});
	}

	toggleCollapse = collapseID => () =>
  this.setState(prevState => ({
    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));

	onGetMitteilungen = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungen', function(response) {
			self.setState({mitteilungenlist: response.data});
			forceVisible();
		})
	};

	onGetMitteilungenWithoutLogin = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungenWithoutLogin', function(response) {
			self.setState({mitteilungenlist: response.data});
			forceVisible();
		})
	};

	onMoreGetMitteilungen = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungen', {params: {before: this.state.mitteilungenlist[this.state.mitteilungenlist.length-1].time}}, function(response) {
			let mitteilungenlist = self.state.mitteilungenlist;
			mitteilungenlist.push(...response.data);
			self.setState({mitteilungenlist: mitteilungenlist});
		});
	}

	onMoreGetMitteilungenWithoutLogin = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungenWithoutLogin', {params: {before: this.state.mitteilungenlist[this.state.mitteilungenlist.length-1].time}}, function(response) {
			let mitteilungenlist = self.state.mitteilungenlist;
			mitteilungenlist.push(...response.data);
			self.setState({mitteilungenlist: mitteilungenlist});
		});
	}

	onNews = () => {
    let self = this;
    this.interAxios.get('/api/rss', function(response)
    {
      let promise = parser.parseString(response.data)

      promise.then((value) => {
        self.setState({news: value.items});
      });
    });
  }

	onOfferProductFalse = (event) => {

	}

	onKategorie = (value, bikeKategorie) => {
		this.setState({activeItemKategorie: value, textItem: this.props.userData.textBike, bikeKategorie: bikeKategorie});
		this.props.userData.onBoersepageTrue();
	}

	onSearchProductB2B = event => {
		this.setState({redirect: true});
	}

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}

	scrollEventListener = (event) => {
		const navPadding = window.scrollY < 500 ? true : false;
		const tabscroll = window.scrollY < 670 ? "9" : window.scrollY < 1400 ? "1" : window.scrollY < 2100 ? "2" : window.scrollY < 2770 ? "3" : "4";
		this.setState({ navbarPaddingTop: navPadding,  activeItem: tabscroll});
	}





  render() {
		const { t } = this.props;
    const client = this.props.client;
		const { collapseID } = this.state;
		let gyiklist = [];
		/*if (this.state.gyikList.length > 0){
		gyiklist = this.state.gyikList.map(
			(o) => {
					return (<GyikModul key={o.id} msg={o} toggleCollapse={this.toggleCollapse} collapseID={collapseID}/>);
		});}*/
    return (
			<>
				<MDBContainer fluid className="navbarcomponentRespo-nav-container">
				<div ref={this.listInnerRefInterB2B}></div>
					<div className={this.state.navbarPaddingTop ? "landing-nav-container fixed-top" : "landing-nav-container-scroll fixed-top"}>
						<MDBRow end className="hamburegermenu-row">
							<MDBBtn flat
											className="hambureger-menu"
											type="button"
											onClick= {this.props.handleSideNavToggle}>
											<img className="hambureger-icon" src={hamburgericon}/>
							</MDBBtn>
						</MDBRow>
						{!this.state.navbarPaddingTop ? <>
						<MDBRow start className="hamburegermenu-row">
							<div className="product-search-markt-header">
								<form className= ""
											onSubmit={async (event) => {await this.onSearchProductB2B(event);}}>
									<MDBRow start className="m-0">
										<MDBCol top size="10" sm="6" md="6" className=" pl-0" middle>
											<input
														type="text"
														className="form-control"
														id="formGroupExampleInput2"
														placeholder={t('produkt_eingeben_3')}
														name="textBike"
														onChange={this.props.userData.onTextSearchChange}
														value={this.props.userData.textBike}
											/>
										</MDBCol>
										<MDBCol top size="2" sm="auto" md="auto" className="p-0 text-right" >
											<MDBBtn type="submit"
															className="borse-btn-header">
												<img src={searchnormalicon} className="vector-1"/>
											</MDBBtn>
										</MDBCol>
									</MDBRow>
								</form>
							</div>
						</MDBRow>
						<img className="ebike-logo-respo" src={respo_ebike_logo}/></>:false}
					</div>
					<div className={styles.bodywrapper2}>
						<div className="side-nav-menu">
						<MDBSideNav
							right
							triggerOpening={this.props.isSideNavOpen}
							onOverlayClick={this.props.handleSideNavToggle}
							breakWidth={1300}
							className="deep-purple darken-4"

						>
							<MenuSideNavMarkt
													userData={this.props.userData}
													toggle = {this.toggle}
													onMenuClose = {this.props.onEnableScroll}
													onItemClose = {this.props.onEnableScrollMain}
													activeItem = {this.state.activeItem}
							/>
						</MDBSideNav>
						</div>
						<MDBRow center className="m-0 pb-2">
							<img className="ebike-logo-respo-main" src={respo_ebike_logo}/>
						</MDBRow>
						<MDBRow center className="m-0 py-3">
							<div className="product-search-markt-main">
								<form className= ""
											onSubmit={async (event) => {await this.onSearchProductB2B(event);}}>
									<MDBRow center className="m-0">
										<MDBCol top className="pl-3 pr-2" middle>
											<input
														type="text"
														className="form-control"
														id="formGroupExampleInput2"
														placeholder={t('produkt_eingeben_3')}
														name="textBike"
														onChange={this.props.userData.onTextSearchChange}
														value={this.props.userData.textBike}
											/>
										</MDBCol>
										<MDBCol top size="auto" className="pl-0 pr-3" >
											<MDBBtn type="submit"
															className="borse-btn-header m-0 p-0">
												<img src={searchnormalicon} className="vector-1"/>
											</MDBBtn>
										</MDBCol>
									</MDBRow>
								</form>
							</div>
						</MDBRow>
						<MDBRow center className="m-0 pb-3">
							<span className="bike-respo-main-text">{t('deutschlands_marktplatz')}</span>
						</MDBRow>
					</div>
					<div className={styles.bodywrapper3}>
					<div ref={this.listInnerRefNews}></div>
					<div className="innerref-padding"/>
					<MDBRow center className="m-0 pt-5">
							<img alt="" className="e-bikeborse-logo-first" src={logo_first}></img>
							<span className="landingpage-text">{t('news')}</span>
					</MDBRow>
						<Link to="/NewsPage" className="icon-newspage-2">
						{this.state.news.length > 0 ?
						<MDBBtn flat className="allnews-btn">
							<span className="allnews-respo">{t('Alle_News')}</span>
						</MDBBtn>:false}
						<MDBRow center className="padding-row-logo4 mx-0 px-2">
										{this.state.news.length > 0 ?
										<>
											{this.state.news.filter((item, index) => index < 4).map(
											(o, index) =>
											<MDBCol md="6" size="6" lg="12" className="mb-3 px-2">
																		<Interb2bNewsItems
																		key={index}
																		msg={o}
																		useLink={false}/>
											</MDBCol>
											)}
										</>:false}
						</MDBRow>
						<div className="gradient3"/>
						</Link>
					</div>
					<div className={styles.bodywrapper3}>
					<div ref={this.listInnerRefBlog}></div>
					<div className="innerref-padding"/>
					<MDBRow center className="m-0 pt-5">
							<img alt="" className="e-bikeborse-logo-first" src={logo_first}></img>
							<span className="landingpage-text">{t('blog')}</span>
					</MDBRow>
						<MDBRow center className="padding-row-logo4 m-0">
							<MDBCol size="12" className="mb-3 text-center">
								<Link to="/BlogPage" className="icon-newspage-2">
										{this.state.mitteilungenlist.length > 0 ?
										<MDBBtn flat className="allblog-btn">
											<span className="allnews-respo text-capitalize">e-Bike blog</span>
										</MDBBtn>:false}
										{this.state.mitteilungenlist.filter((item, index) => index < 2).map(
											(o, index) =>
														<>
														{this.props.userData.isLoggedInMarkt() ?
															<MitteilungenItemRespo
																userData ={this.props.userData}
																key={o.id}
																msg={o}
															/>:
															<MitteilungenItemWithoutLoginRespo
																userData ={this.props.userData}
																key={o.id}
																msg={o}
															/>}</>
										)}
										<div className="gradient4"/>
								</Link>
							</MDBCol>
						</MDBRow>
					</div>
					<div className={styles.bodywrapper4}>
					<div ref={this.listInnerRefInfo}></div>
					<div className="innerref-padding"/>
					<MDBRow center className="m-0 pt-5">
							<img alt="" className="e-bikeborse-logo-first" src={logo_first}></img>
							<span className="landingpage-text">{t('faq')}</span>
					</MDBRow>
						<div className="py-5 m-0 px-4">
							{gyiklist}
						</div>
					</div>
					<div className={styles.bodywrapper5}>
					<div ref={this.listInnerRefContact}></div>
					<div className="innerref-padding"/>
					<MDBRow center className="m-0 pt-5">
							<img alt="" className="e-bikeborse-logo-first-kontakt" src={logo_first}></img>
							<span className="landingpage-text-kontakt">{t('kontakt_1')}</span>
					</MDBRow>
						<MDBRow center className="m-0 py-4">
							<MDBCol size="3" middle>
								<img className="profile" src={profile_Boda}></img>
							</MDBCol>
							<MDBCol size="auto" middle>
							<span className="balazs-boda-interb-2-b-respo font-weight-bolder">balazs.boda@interb2b.com</span><br/>
							<span className="balazs-boda-interb-2-b-respo">{t('projektleitung_der_produktentwicklung')}</span><br/>
							</MDBCol>
						</MDBRow>
						<MDBRow start className="m-0 py-3 px-2">
							<MDBCol middle size="auto" className="m-0 pr-2">
								<img className="rectangle-1" src={rectangle_2}></img>
							</MDBCol>
							<MDBCol middle className="m-0 pl-2">
								<span className="liebe-interessenten">{t('deutschlands_marktplatz')}</span>
							</MDBCol>
						</MDBRow>
						<MDBRow center className="py-3 m-0">
							<img className="firmen" src={interb2b}></img>
						</MDBRow>
						<MDBRow center className="py-3 m-0">
								<a 	style={{textAlign:"center"}}
								href="https://www.studicore.hu/about"
										target="_blank" rel="noopener noreferrer">
										<img className="firmen" src={studiCore}></img>
								</a>
						</MDBRow>
						<MDBRow center className="pt-3 m-0 footer-row-bottom">
							<a 	style={{textAlign:"center"}}
									href="https://appentum.de"
									target="_blank" rel="noopener noreferrer">
									<img className="firmen" src={appentum}></img>
							</a>
						</MDBRow>
						<div className="rectangle-footer-markt-respo">
							<MDBContainer fluid>
								<FooterMarktLanding urlName = {urlName}/>
							</MDBContainer>
						</div>
					</div>
				</MDBContainer>

					 {this.state.redirect ?
			 			<Redirect
						to={{		pathname: "/BoersePage",
									  state: { activeItem: this.state.activeItemKategorie, bikeKategorie: this.state.bikeKategorie }}}/>:false}
	 </>
 	);
 }
 }

 export default  withTranslation()(LandingPageMarktResponziv);
