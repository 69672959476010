import React, { Component } from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBBtn,
  MDBScrollbar,
  MDBRow,
  MDBCol,
  MDBBadge
} from "mdbreact";

import ChatMessageMain from "./Message/ChatMessageMain";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class ChatListMain extends Component {
  state = {
    ChatUserId: "",
    search: "",
    collapseID: ""
  };


  componentDidMount() {
    var params = window.location.href.split("/");
    this.setState({
      ChatUserId: params[params.length - 1],
    });
  }

  handleChatClick = (id) => {
    this.props.handleChatClick(id);
  };

  toggleCollapse = collapseID => () => {
  this.setState(prevState => ({
    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));
}

  render() {
    if (this.props.userData.getCurrentCompany() === undefined)
			return false;
    var i=0;
    for (const chatRoom of this.props.rooms){
        i+= chatRoom.badge;
    }
    let AllChatList = this.props.rooms;
    const { collapseID } = this.state;
    return (
      <>
      <MDBRow className="align-items-center" style={{paddingLeft: "1rem", paddingRight: 0, paddingTop: "0.6rem"}}>
        <MDBCol xs="12" sm="2" className="text-center">
          <div className={"avatar avatar-online"} style={{height: '2.2rem', width: '2.2rem', minWidth: '2.2rem'}}>
            <img alt="" className="photo" src={process.env.REACT_APP_BACKEND_URL+'/storage/profile/newProfile_' + this.props.userData.getCurrentCompany()?.id + '.jpg?' + Date.now()}></img>
          </div>
        </MDBCol>
        <MDBCol xs="12" sm="3" className="text-left">
          <span aria-hidden="true"> Chat</span>
        </MDBCol>
        <MDBCol xs="12" sm="7" className="text-right">
          <MDBBtn flat
                  className='btn-block'
                  onClick={this.toggleCollapse("basicCollapse")}
          >
            {this.state.collapseID ?
              <MDBIcon icon="chevron-down" />:
              <MDBIcon icon="chevron-up" />}
          </MDBBtn>
          {i>0 ?
          <MDBBadge pill color="danger" style={{position: 'absolute', right: '99px', top: '14px', zIndex: 10, border: '2px solid #fff'}}>{i}</MDBBadge>:false}
        </MDBCol>
      </MDBRow>
      <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
        <MDBScrollbar>
         <div id="chatContactsList" className="px-2">
           <ul
             className="list-group"
             id="chatContactTab"
             data-chat-list=""
           >
             {AllChatList.map((chat, index) => {
               return (
                 <ChatMessageMain
                   key={"chat" + index}
                   {...chat}
                   handleChatClick={() => {
                     this.handleChatClick(chat.id);
                   }}

                 />
               );
             })}
           </ul>
         </div>
         </MDBScrollbar>
       </MDBCollapse>
       </>


    );
  }
}
export default ChatListMain;
