import React, { Component, Fragment } from "react";
import styles from '../assets/scss/modules/pages.module.scss';
import Cookies from 'universal-cookie';

const cookies = new Cookies();


import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBCollapse,
	MDBCollapseHeader,
	MDBIcon,
	MDBBtn,
	MDBScrollbar
} from 'mdbreact';

class SubscriptionCancel extends Component {

  constructor(props) {
    super(props);
    this.state = {token : cookies.get('authtoken').token
									};
  };

  render() {

    return (
			<div className={styles.bodywrapper}>
			  <MDBScrollbar style={{maxHeight: "calc(100vh - 6.6rem)"}}>
					<MDBContainer >
						<MDBCard>
							<p>Forgot to add something to your cart? Shop around then come back to pay!</p>
						</MDBCard>
					</MDBContainer>
				</MDBScrollbar>
			</div>
    );
  }
}

export default SubscriptionCancel;
