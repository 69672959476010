import React, { Component } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import ChatAction from "../../ChatAction/ChatAction";
import salonicon from '../../../assets/media/newicons/peoplegray.png';
import meinprofilicon from '../../../assets/media/newicons/profilegray.png';
import peopleicon from '../../../assets/media/newicons/people.png';
import profileicon from '../../../assets/media/newicons/profile.png';
import active_green from '../../../assets/media/images/b2b/Group 29.png';
import active_grau from '../../../assets/media/images/markt/profil/active_grau.png';
import inactive_green from '../../../assets/media/images/b2b/inactive_orange.png';
import inactive_grau from '../../../assets/media/images/markt/profil/inactive_grau.png';
import ChatFilter from "../../FilterDropdowns/ChatFilter";
import { ChatFilterOptions, ChatFilterOptionsMarkt } from "../../../Config/Config";
import ChatMessageBikeB2B from "./Message/ChatMessageBikeB2B";
import{
	MDBContainer,
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBListGroup,
  MDBCardTitle,
  MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBSwitch,
	MDBCollapse,
	MDBSpinner
} from 'mdbreact';
let eventKey = 3;

class ChatListBikeB2B extends Component {
  state = {
    ChatUserId: "",
		search: "",
    filterOption: ChatFilterOptionsMarkt.ActiveChats,
		switch1: false,
		eredmeny: true,
  };

	constructor(props) {
    super(props);
  };
  componentDidMount() {
		this.onAnimationStart();
    var params = window.location.href.split("/");
    this.setState({
      ChatUserId: params[params.length - 1],
    });

  }

  handleChatClick = (id) => {
    this.props.handleChatClick(id);
		this.props.handleToggle();
  };

	handleFilterOptionChanged = (option) => {
    this.setState({
      filterOption: option,
      search: "",
    });

  };

  handleSearchChat = value => {
    this.setState({search: value});
  };

	filterChats = (AllChatList) => {
		let chatList = [...AllChatList];
		if (this.state.filterOption !== ChatFilterOptionsMarkt.AllChats)
			chatList = chatList.filter((x) => x.aktuelleStatus === this.state.filterOption);
		if (this.state.search) {
			chatList = chatList.filter(
				(x) =>
					x.name.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0
			);
		}
		return chatList;
	}

	handleSwitchChange = nr => {
		let switchNumber = `switch${nr}`;
		this.setState({
			[switchNumber]: !this.state[switchNumber],
			eredmeny: !this.state.eredmeny
		});

		if(this.state.eredmeny){
			eventKey = 3;
		}else{
			eventKey =  2;
		}
		this.handleChange(eventKey);
	};

	handleChange = async (eventKey) => {;
		await this.handleFilterOptionChanged(parseInt(eventKey));
	};

	onAnimationStart = () => {
		setTimeout(function(){
			this.setState({animationTrue: true});
		}.bind(this), 2000);
	}

  render() {

		const { t } = this.props;
		const client = this.props.client;
		let AllChatList = this.props.rooms;
    let roomNameList = AllChatList.map(o => o.name);
    let FilteredChatList = this.filterChats(AllChatList);
		switch (this.state.filterOption) {
			case ChatFilterOptionsMarkt.ActiveChats:
				break;
			case ChatFilterOptionsMarkt.Deleted:
				break;
			default:
				break;
		}
    return (
<MDBCard  className="chatlist-card-markt m-0">
	<MDBCardBody>
		<ChatFilter
    	    roomNameList= {roomNameList}
    	    selectedOption={this.state.filterOption}
    	    filterOptionChanged={this.handleFilterOptionChanged}
    	    search={this.state.search}
    	    handleSearch={this.handleSearchChat}
    	/>
			<MDBRow center className="py-3">
			<MDBSwitch id="chatfilter-b2b" className="form-row" checked={this.state.switch1} onChange={() => this.handleSwitchChange(1)}
			labelLeft={
						<>
							<span className={this.state.eredmeny ? "filter-text-green" : "filter-text-grau"}>Active</span>
							<img src={this.state.eredmeny ? active_green : active_grau} className="filter-icon"/>
						</>
			}
			labelRight={
					<>
						<img src={!this.state.eredmeny ? inactive_green : inactive_grau} className="filter-icon"/>
						<span className={!this.state.eredmeny ? "filter-text-green-inactive" : "filter-text-grau-inactive"}>Inactive</span>
					</>
			}/>

			</MDBRow>
			<Scrollbar
				id="scrollbar-b2b"
				style={{ width: "100%", height: "calc(100vh - 20.5rem)"}}
				maximalThumbYSize = {150}
				noScrollX = {true}
				>
				<div id="chatlistb2b">
				{!this.state.animationTrue ?
								 <div className="spinner-position">
									 <MDBSpinner /><br/>
									 <span className="">Loading...</span>
								 </div>:false}
				<MDBCollapse id="basicCollapse2" className="fullPageCollapse" isOpen={this.state.animationTrue} delay={{show: 1000}}>
				<MDBListGroup>
          {FilteredChatList.map((chat, index) => {
            return (
            <ChatMessageBikeB2B
            key={"chat" + index}
            {...chat}
						selectedChatroomId = {this.props.selectedChatroomId}
            handleChatClick={() => {
            this.handleChatClick(chat.id);
            }}
            />
            );
          })}
        </MDBListGroup>
				</MDBCollapse>
				</div>
		</Scrollbar>
  </MDBCardBody>
</MDBCard>
    );
  }
}
export default withTranslation()(ChatListBikeB2B);
