import React, { Component } from 'react';
import CookieConsent from "react-cookie-consent";
import {BrowserRouter as Router, Route } from 'react-router-dom';
import { Redirect, Switch } from 'react-router';

import logo from './assets/media/logo/1.png';
import homeicon from './assets/media/newicons/home.png';
import netzwerkicon from './assets/media/newicons/netzwerk.png';
import firmenicon from './assets/media/newicons/firmen.png';
import nachrichtenicon from './assets/media/newicons/nachrichten.png';
import mitteilungenicon from './assets/media/newicons/mitteilungen.png';
import pinnwandicon from './assets/media/newicons/pinnwand.png';
import salonicon from './assets/media/newicons/salon.png';
import statistikicon from './assets/media/newicons/statistik.png';
import meinprofilicon from './assets/media/newicons/meinprofil.png';
import mehricon from './assets/media/newicons/mehr.png';
import staricon from './assets/media/newicons/star1.png';
import staricon_mobile from './assets/media/newicons/star.png';
import newcompanyicon from './assets/media/newicons/newcompany.png';
import historyicon from './assets/media/newicons/history.png';
import cookiesicon from "./assets/media/newicons/cookiesicon.png";
import logouticon from "./assets/media/newicons/logout.png";

import netzwerkicon_gray from './assets/media/newicons/netzwerk-gray.png';
import nachrichtenicon_gray from './assets/media/newicons/nachrichten-gray.png';
import salonicon_gray from './assets/media/newicons/salon-gray.png';
import meinprofilicon_gray from './assets/media/newicons/profilegray.png';
import newcompanyicon_gray from './assets/media/newicons/newcompany-gray.png';
import historyicon_gray from './assets/media/newicons/history-gray.png';

import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import moment from 'moment';
import 'moment/locale/de';

import ResetPassword from "./pages/ResetPassword";
import Logout from "./components/Logout/Logout";

import MyProfile from "./components/MyProfile/MyProfile";
import MyCurrentCompany from "./components/MyProfile/MyCurrentCompany";
import InvitationPage from "./components/MyProfile/InvitationPage";
import MyManagement from "./components/MyProfile/MyManagement";
import MyUserPage from "./components/MyProfile/MyUserPage";
import NewCompanyB2B from "./pages/b2b/Handler/NewCompanyB2B";
import SalonCalendarPage from "./components/Jitsi/SalonCalendarPage";
import SalonMeetingPage from "./components/Jitsi/SalonMeetingPage";

//Ha direktben importáljuk be, nem csak akkor, amikor kell, akkor az összes oldalra integrálja a Zoomot, nemcsak az aloldalra, ettől fekete lesz az egész
//import ZoomMeetingPage from './components/Zoom/ZoomMeetingPage';
const ZoomMeetingPage = React.lazy(() => import("./components/Zoom/ZoomMeetingPage"));
import ZoomMeetingEnded from "./components/Zoom/ZoomMeetingEnded";
import PayPage from "./components/NewCompany/PayPage";
import PayPageOngoing from "./components/NewCompany/PayPageOngoing";
import CompaniesPagePublic from "./pages/CompaniesPagePublic";
import CompaniesPageProfile from "./pages/CompaniesPageProfile";
import AllFeedbackPage from "./pages/AllFeedbackPage";
import AllFeedbackPageB2B from "./pages/AllFeedbackPageB2B";
import About from "./About/About";
import AboutB2B from "./About/AboutB2B";
import UserAgreement from "./About/UserAgreement";
import UserAgreementB2B from "./About/UserAgreementB2B";
import CookiePolicy from "./About/CookiePolicy";
import CookiePolicyB2B from "./About/CookiePolicyB2B";
import PrivacyPolicy from "./About/PrivacyPolicy";
import PrivacyPolicyB2B from "./About/PrivacyPolicyB2B";
import InterB2BAxios from './Service/InterB2BAxios';
import InformationsEmail from './Template/InformationsEmail';

import LandingResponsivPage from "./pages/markt/LandingResponsivPage";

import ForgottenPassword from "./pages/ForgottenPassword";
import SignInMarkt from "./pages/markt/SignInMarkt";
import SignUpMarkt from "./pages/markt/SignUpMarkt";
import LandingPageMarkt from "./pages/markt/LandingPageMarkt";
import AddBike from "./pages/markt/AddBike";
import EintragsTyp from "./pages/markt/EintragsTyp";
import Favorite from "./components/User/Favorite";
import BikeInserieren from "./pages/markt/BikeInserieren";
import BikeInserierenUpdate from "./pages/markt/BikeInserierenUpdate";
import NewsPage from "./pages/markt/NewsPage";
import BlogPage from "./pages/markt/BlogPage";
import BoersePage from "./pages/markt/BoersePage";
import BikePage from "./pages/markt/BikePage";
import ProfilInfo from "./components/User/ProfilInfo";
import Benachrichtigungen from "./components/User/Benachrichtigungen";
import Eintraege from "./components/User/Eintraege";
import ProfilPage from "./components/User/ProfilPage";
import Konto from "./components/User/Konto";
import Transaktionen from "./components/User/Transaktionen";
import ChatsBike from "./components/ChatBike/ChatsBike";

import ForgottenPasswordB2B from "./pages/b2b/ForgottenPasswordB2B";
import SignInB2B from "./pages/b2b/SignInB2B";
import SignUpHandler from "./pages/b2b/Handler/SignUpHandler";
import MainPageHandler from "./pages/b2b/Handler/MainPageHandler";
import ProductPageWithCompany from "./pages/b2b/Handler/ProductPageWithCompany";
import ProductPage from "./pages/b2b/Handler/ProductPage";
import BlogPageHandler from "./pages/b2b/Handler/BlogPageHandler";
import NewsPageHandler from "./pages/b2b/Handler/NewsPageHandler";
import CompanyPageWithProduct from "./pages/b2b/Handler/CompanyPageWithProduct";
import LandingPageB2Bike from "./pages/b2b/LandingPageB2Bike";
import KontoB2B from "./pages/b2b/Handler/KontoB2B";
import AddBikeB2B from "./pages/b2b/Handler/AddBikeB2B";
import EintragsTypB2B from "./pages/b2b/Handler/EintragsTypB2B";
import EintraegeB2B from "./pages/b2b/Handler/EintraegeB2B";
import BikeInserierenB2B from "./pages/b2b/Handler/BikeInserierenB2B";
import BikeInserierenUpdateB2B from "./pages/b2b/Handler/BikeInserierenUpdateB2B";
import MyProfilePageB2B from "./pages/b2b/Handler/MyProfilePageB2B";
import CompaniesPageHandler from "./pages/b2b/Handler/CompaniesPageHandler";
import ChatsBikeB2B from "./components/ChatBikeB2B/ChatsBikeB2B";
import BikePageB2B from "./pages/b2b/BikePageB2B";
import NewsPageB2B from "./pages/b2b/NewsPageB2B";


import VerwaltungB2B from "./pages/b2b/Handler/VerwaltungB2B";
import InvitationB2B from "./pages/b2b/Handler/InvitationB2B";
import InvitationB2BIn from "./pages/b2b/Handler/InvitationB2BIn";
import SignUpHersteller from "./pages/b2b/Hersteller/SignUpHersteller";
import MainPageHersteller from "./pages/b2b/Hersteller/MainPageHersteller";
import KontoB2BHersteller from "./pages/b2b/Hersteller/KontoB2BHersteller";
import AddBikeB2BHersteller from "./pages/b2b/Hersteller/AddBikeB2BHersteller";
import EintragsTypB2BHersteller from "./pages/b2b/Hersteller/EintragsTypB2BHersteller";
import EintraegeB2BHersteller from "./pages/b2b/Hersteller/EintraegeB2BHersteller";
import BikeInserierenB2BHersteller from "./pages/b2b/Hersteller/BikeInserierenB2BHersteller";
import BikeInserierenUpdateB2BHersteller from "./pages/b2b/Hersteller/BikeInserierenUpdateB2BHersteller";
import MyProfilePageB2BHersteller from "./pages/b2b/Hersteller/MyProfilePageB2BHersteller";
import CompaniesPageHersteller from "./pages/b2b/Hersteller/CompaniesPageHersteller";
import NewsPageHersteller from "./pages/b2b/Hersteller/NewsPageHersteller";
import BlogPageHersteller from "./pages/b2b/Hersteller/BlogPageHersteller";
import SalonCalendarPageHersteller from "./components/Jitsi/SalonCalendarPageHersteller";

import Impressum from "./About/Impressum";
import ImpressumB2B from "./About/ImpressumB2B";

import SubscriptionSuccess from './pages/SubscriptionSuccess';
import SubscriptionCancel from './pages/SubscriptionCancel';

import SockJsClient from 'react-stomp';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class App extends Component {
	state = {navbarStatus: false,
					 isSideNavOpen: false,
					 windowWidth: window.innerWidth
	};


  functionsFromChildren = [];

	componentDidMount() {
		document.addEventListener("scroll", this.scrollEventListener)
		window.addEventListener('resize', this.updateDimensions);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.windowWidth !== window.innerWidth){
			this.setState({
				windowWidth : window.innerWidth
			});
			const body = document.body;
			body.style.position = '';
			body.style.width = '';
		}
	}


	componentWillUnmount() {
		document.removeEventListener("scroll", this.scrollEventListener);
		window.removeEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  onMessage = (message) => {
    if (this.functionsFromChildren !== undefined) {
      for (let i = 0; i < this.functionsFromChildren.length; i++) {
        let func = this.functionsFromChildren[i];
        func(message);
      }
    }
  }

  sendMessage = (webSocketMessage) => {
    this.clientRef.sendMessage("/app/sendMessage", JSON.stringify(webSocketMessage), {'X-Authorization': cookies.get('authtoken').token});
  };

  addFunctionFromChild = (func) => {
    this.functionsFromChildren.push(func);
  }

  removeFunctionFromChild = (func) => {
    var index = this.functionsFromChildren.indexOf(func);
    this.functionsFromChildren.splice(index, 1);
  }


  onCookieConsent = () => {
    cookies.set('CookieConsent', 'true', {
        path: '/'
    })
  }

	homepageLoggedInRedirection = () => {
		if (this.props.userData.isRemoteBoersePage()) {
			return <Redirect to="/LandingPageMarkt" />
		} else if (this.props.userData.isRemoteB2BPage()) {
			if (this.props.userData.partner() === "Dealer") {
				return <Redirect to="/MainPageHandler" />
			} else if (this.props.userData.partner() === "Supplier") {
				return <Redirect to="/MainPageHersteller" />
			} else {
				return <LandingPageB2Bike {...this} userData={this.props.userData}/>
			}
		} else {
			return <LandingPageB2Bike {...this} userData={this.props.userData}/>
		}
	}

	extractInterB2BikeTrue = () => {
		//console.log('href: ' + window.location.href);
		const queryString = window.location.search;
		//console.log('queryString: ' + queryString);
		const urlParams = new URLSearchParams(queryString);
		if (urlParams.get('interb2bike') === null) {
			return;
		}
		const interb2bike = urlParams.get('interb2bike') === 'true' ?? false;
		//console.log('interb2bike: ' + interb2bike);
		if (interb2bike) {
			this.props.userData.setInterB2BikeTrue();
		}
		urlParams.delete('interb2bike');
		let newlocation = window.location.pathname;
		if (urlParams.toString() !== '') {
			newlocation += '?' + urlParams.toString();
		}
		window.history.replaceState({}, '', newlocation);
	}

	handleSideNavToggle = () => {
		this.setState({
			isSideNavOpen: !this.state.isSideNavOpen
		});
		this.onDisableScroll();
	};
	onDisableScroll = () => {
		const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
		const body = document.body;
		body.style.position = 'fixed';
		body.style.top = `-${scrollY}`;
		body.style.width = '100%';
	};
	onEnableScroll = () => {
		this.setState({
			isSideNavOpen: !this.state.isSideNavOpen
		});
		const body = document.body;
		const scrollY = body.style.top;
		body.style.position = '';
		body.style.top = '';
		body.style.width = '';
		window.scrollTo(0, parseInt(scrollY || '0') * -1);
	}
	onEnableScrollMain = () => {
		this.setState({
			isSideNavOpen: !this.state.isSideNavOpen
		});
		const body = document.body;
		const scrollY = body.style.top;
		body.style.position = '';
		body.style.top = '';
		body.style.width = '';
		//window.scrollTo(0, parseInt(scrollY || '0') * -1);
	}
	scrollEventListener = (event) => {
		document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
	}


	render() {
		this.extractInterB2BikeTrue();
		//console.log(window.location.href);
		//console.log(window.location.hostname);
    const { t } = this.props;
    const client = this.props.client;
		var basename = this.props.userData.getBaseName();
		return (
			<>

			{window.matchMedia('(max-device-width: 992px)').matches  && window.matchMedia('(max-device-height: 576px)').matches ?
			<Router basename={basename}>
						<Route path="/" render={(props) => <LandingResponsivPage {...props} {...this} userData={this.props.userData}/>} />
			</Router>:

			<Router basename={basename}>

			{/*<NavbarComponent userData={this.props.userData}/>*/}
			{this.props.userData.isLoggedIn() ?
				<div>
				<Route path="/" exact>
				{
					this.homepageLoggedInRedirection()
				}
				</Route>

				<Route path="/AddBike" render={(props) => <AddBike {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/LandingPageMarkt" render={(props) => <LandingPageMarkt {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/EintragsTyp" render={(props) => <EintragsTyp {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BikeInserieren" render={(props) => <BikeInserieren {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BikeInserierenUpdate" render={(props) => <BikeInserierenUpdate {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BlogPage" render={(props) => <BlogPage {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/NewsPage" render={(props) => <NewsPage {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BoersePage" render={(props) => <BoersePage {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BikePage" render={(props) => <BikePage {...props} {...this} {...this.state} userData={this.props.userData}/>}/>
				<Route path="/ProfilInfo" render={(props) => <ProfilInfo {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/Benachrichtigungen" render={(props) => <Benachrichtigungen {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/Eintraege" render={(props) => <Eintraege {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />

				<Route path="/ProfilPage" render={(props) => <ProfilPage {...props} {...this} {...this.state} userData={this.props.userData}/>}/>
				<Route path="/Favorite" render={(props) => <Favorite {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/Konto" render={(props) => <Konto {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/Transaktionen" render={(props) => <Transaktionen {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/ChatsBike" render={(props) => <ChatsBike {...props} {...this} {...this.state} userData={this.props.userData}/>}/>
				<Route path="/ChatsBikeB2B" render={(props) => <ChatsBikeB2B {...props} {...this} {...this.state} userData={this.props.userData}/>}/>
				<Route path="/About" render={(props) => <About {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/AboutB2B" render={(props) => <AboutB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />

				<Route path="/KontoB2B" render={(props) => <KontoB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/EintraegeB2B" render={(props) => <EintraegeB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/EintragsTypB2B" render={(props) => <EintragsTypB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BikeInserierenB2B" render={(props) => <BikeInserierenB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BikeInserierenUpdateB2B" render={(props) => <BikeInserierenUpdateB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/AddBikeB2B" render={(props) => <AddBikeB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BikePageB2B" render={(props) => <BikePageB2B {...props} {...this} {...this.state} userData={this.props.userData}/>}/>
				<Route path="/CompanyPageWithProduct" render={(props) => <CompanyPageWithProduct {...props} {...this} {...this.state} userData={this.props.userData}/>}/>
				<Route path="/BlogPageHandler" render={(props) => <BlogPageHandler {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/NewsPageHandler" render={(props) => <NewsPageHandler {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/ProductPageWithCompany" render={(props) => <ProductPageWithCompany {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/ProductPage" render={(props) => <ProductPage {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/NewsPageB2B" render={(props) => <NewsPageB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />

				<Route path="/VerwaltungB2B" render={(props) => <VerwaltungB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/InvitationB2B" render={(props) => <InvitationB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/InvitationB2BIn" render={(props) => <InvitationB2BIn {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/KontoB2BHersteller" render={(props) => <KontoB2BHersteller {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/EintraegeB2BHersteller" render={(props) => <EintraegeB2BHersteller {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/EintragsTypB2BHersteller" render={(props) => <EintragsTypB2BHersteller {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BikeInserierenB2BHersteller" render={(props) => <BikeInserierenB2BHersteller {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BikeInserierenUpdateB2BHersteller" render={(props) => <BikeInserierenUpdateB2BHersteller {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/AddBikeB2BHersteller" render={(props) => <AddBikeB2BHersteller {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BlogPageHersteller" render={(props) => <BlogPageHersteller {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/NewsPageHersteller" render={(props) => <NewsPageHersteller {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/AllFeedbackPage" render={(props) => <AllFeedbackPage {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/AllFeedbackPageB2B" render={(props) => <AllFeedbackPageB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />



				<Route path="/SignInB2B" exact><SignInB2B {...this} {...this.props}/></Route>
				<Route path="/SignInMarkt" exact><SignInMarkt {...this} {...this.props}/></Route>
				<Route path="/SignUpHersteller" exact><SignUpHersteller {...this}/></Route>
				<Route path="/SignUpHandler" exact><SignUpHandler {...this}/></Route>
				<Route path="/SignUpMarkt" exact><SignUpMarkt {...this}/></Route>

        <Route path="/InformationsEmail" exact><InformationsEmail {...this}/></Route>
				{this.props.userData.partner() === "Dealer" ?
          <Route path="/MainPageHandler"><MainPageHandler {...this} {...this.state} userData={this.props.userData}/></Route>:false}
				{this.props.userData.partner() === "Supplier" ?
					<Route path="/MainPageHersteller"><MainPageHersteller {...this} {...this.state} userData={this.props.userData}/></Route>:false}


					<Route exact path="/CompaniesPageHandler"><CompaniesPageHandler {...this} {...this.state} userData={this.props.userData}/></Route>
					<Route exact path="/CompaniesPageHersteller"><CompaniesPageHersteller {...this} {...this.state} userData={this.props.userData}/></Route>

          <Route path="/CompaniesPagePublic"><CompaniesPagePublic {...this}/>  </Route>
          <Route path="/CompaniesPageProfile"><CompaniesPageProfile {...this}/></Route>
          <Route path="/MyManagement"><MyManagement {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/MyCurrentCompany"><MyCurrentCompany {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/InvitationPage"><InvitationPage {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/MyUserPage"><MyUserPage {...this} {...this.state} userData={this.props.userData}/></Route>

          <Route path="/PayPage" render={(props) => <PayPage {...props} {...this.state} userData={this.props.userData}/>} exact/>
          <Route path="/PayPageOngoing" render={(props) => <PayPageOngoing {...props} {...this.state} userData={this.props.userData}/>}/>


          <Route path="/SalonCalendarPage"><SalonCalendarPage {...this} {...this.state} userData={this.props.userData}/></Route>
					<Route path="/SalonCalendarPageHersteller"><SalonCalendarPageHersteller {...this} {...this.state} userData={this.props.userData}/></Route>
					<Route path="/SalonCalendarPageHersteller/:appid/:id" render={routerProps => <SalonMeetingPage {...this} {...routerProps} {...this.state} userData={this.props.userData}/>}></Route>
          <Route path="/SalonMeetingPage/:appid/:id" render={routerProps => <SalonMeetingPage {...this} {...routerProps} {...this.state} userData={this.props.userData}/>}></Route>
          <Route path="/SalonMeetingPage/:id" render={routerProps => <SalonMeetingPage {...this} {...routerProps} {...this.state} userData={this.props.userData}/>}></Route>
          <Route path="/ZoomMeetingPage/:id" render={routerProps => <ZoomMeetingPage {...this} {...routerProps} {...this.state} userData={this.props.userData}/>}></Route>
          <Route path="/ZoomMeetingEnded"><ZoomMeetingEnded {...this.state} userData={this.props.userData}/></Route>
					<Route path="/MyProfilePageB2B"><MyProfilePageB2B {...this} match={{params: {id: this.props.userData.getCurrentCompany()?.id}}} {...this.state} userData={this.props.userData}/></Route>
					<Route path="/MyProfilePageB2BHersteller"><MyProfilePageB2BHersteller {...this} match={{params: {id: this.props.userData.getCurrentCompany()?.id}}} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/NewCompanyB2B"><NewCompanyB2B {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/CookiePolicy"><CookiePolicy {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/PrivacyPolicy"><PrivacyPolicy {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/UserAgreement"><UserAgreement {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/Impressum"><Impressum {...this} {...this.state} userData={this.props.userData}/></Route>
					<Route path="/CookiePolicyB2B"><CookiePolicyB2B {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/PrivacyPolicyB2B"><PrivacyPolicyB2B {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/UserAgreementB2B"><UserAgreementB2B {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/ImpressumB2B"><ImpressumB2B {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/Logout"><Logout {...this} {...this.props} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/Subscription/success" exact><SubscriptionSuccess {...this} {...this.state} /></Route>
          <Route path="/Subscription/cancel" exact><SubscriptionCancel {...this}  {...this.state} userData={this.props.userData}/></Route>
          <SockJsClient url={process.env.REACT_APP_BACKEND_URL+'/ws'}
            headers={{'X-Authorization': cookies.get('authtoken')?.token}}
            topics={["/user/" + cookies.get('authtoken')?.userId + "/api/queue/messages"]}
            onMessage={this.onMessage}
            ref={ (client) => { this.clientRef = client }}
          />
        </div>:
        <div>
				<Switch>
				<Route path="/" exact>
				{
					this.props.userData.isRemoteBoersePage()
				?
					<Redirect to="/LandingPageMarkt" />
				:
					(this.props.userData.isRemoteB2BPage()
					?
					<Redirect to="/LandingPageB2Bike" />
					:
					<LandingPageB2Bike {...this} {...this.state} userData={this.props.userData}/>)
				}
				</Route>
				<Route path="/BlogPage" render={(props) => <BlogPage {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/NewsPage" render={(props) => <NewsPage {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BoersePage" render={(props) => <BoersePage {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/AllFeedbackPage" render={(props) => <AllFeedbackPage {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/AllFeedbackPageB2B" render={(props) => <AllFeedbackPageB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/About" render={(props) => <About {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/AboutB2B" render={(props) => <AboutB2B {...props} {...this} {...this.state} userData={this.props.userData}/>} exact />
				<Route path="/BikePage" render={(props) => <BikePage {...props} {...this} {...this.state} userData={this.props.userData}/>}/>
					<Route path="/CookiePolicy"><CookiePolicy {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/PrivacyPolicy"><PrivacyPolicy {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/UserAgreement"><UserAgreement {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/Impressum"><Impressum {...this} {...this.state} userData={this.props.userData}/></Route>
					<Route path="/CookiePolicyB2B"><CookiePolicyB2B {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/PrivacyPolicyB2B"><PrivacyPolicyB2B {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/UserAgreementB2B"><UserAgreementB2B {...this} {...this.state} userData={this.props.userData}/></Route>
          <Route path="/ImpressumB2B"><ImpressumB2B {...this} {...this.state} userData={this.props.userData}/></Route>
        	<Route path="/InformationsEmail" exact>
          <InformationsEmail {...this}/>
        </Route>
        <Route path="/CompaniesPagePublic">
          <CompaniesPagePublic {...this} {...this.state} userData={this.props.userData}/>
        </Route>
        <Route path="/CompaniesPageProfile">
          <CompaniesPageProfile {...this} {...this.state} userData={this.props.userData}/>
        </Route>
					<Route path="/SignUpHersteller" exact>
	          <SignUpHersteller {...this} {...this.state} userData={this.props.userData}/>
	        </Route>
					<Route path="/SignUpHandler" exact>
	          <SignUpHandler {...this} {...this.state} userData={this.props.userData}/>
	        </Route>
					<Route path="/SignUpMarkt" exact>
	          <SignUpMarkt {...this} {...this.state} userData={this.props.userData}/>
	        </Route>
					<Route path="/SignInMarkt" exact>
            <SignInMarkt {...this} {...this.state} userData={this.props.userData}/>
          </Route>
					<Route path="/SignInB2B" exact>
	          <SignInB2B {...this} {...this.state} userData={this.props.userData}/>
	        </Route>
					<Route path="/LandingPageMarkt" exact>
            <LandingPageMarkt {...this} {...this.state} userData={this.props.userData}/>
          </Route>
          <Route path="/ForgottenPasswordB2B" exact>
            <ForgottenPasswordB2B {...this} {...this.state} userData={this.props.userData}/>
          </Route>
          <Route path="/ResetPassword" exact>
            <ResetPassword {...this}/>
          </Route>

          <Route path="/LandingPage">
            <LandingPageB2Bike {...this} {...this.props} {...this.state} />
          </Route>
					<Route path="/LandingPageB2Bike" exact>
						<LandingPageB2Bike {...this} {...this.props} {...this.state} />
					</Route>
          <Route path="/InterB2b">
            <LandingPageB2Bike {...this} {...this.props} {...this.state} />
          </Route>
          <Route path="/Videos">
            <LandingPageB2Bike {...this} {...this.props} {...this.state} />
          </Route>
					<Route path="/">
					{
						this.props.userData.isRemoteBoersePage()
					?
						<Redirect to="/SignInMarkt" />
					:
						(this.props.userData.isRemoteB2BPage()
						?
						<Redirect to="/SignInB2B" />
						:
						<Redirect to="/" />)
					}
					</Route>
					</Switch>


        </div>}

			</Router>}

		<CookieConsent
			location="bottom"
			disableStyles={true}
			buttonText={t('akzeptieren')}
			cookieName="myAwesomeCookieName2"
			buttonClasses="btn btn-akzeptiren-cookies"
			contentClasses="cookies-content"
			buttonWrapperClasses="cookies-btn-wrapper"
			style={{ background: this.props.userData.isRemoteBoersePage() ? "#00A441" : "#FF9500" }}
			expires={150}
		>
			<h5 className="cookies-text-title"><img src={cookiesicon}/>{t('wir_verwenden_cookies')}</h5>
			<p>{t('um_ihnen_die_bestmögliche')}</p>
                <p>{t('weitere_informationen_finden')}<a id="1" href="/PrivacyPolicy" target="_blank">{t('datenschutzinformation')}</a>{t('bitte_beachten_sie')}</p>
		</CookieConsent>

</>
		);
	}
}

export default withTranslation()(App);
