import React, { Component } from "react";
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom'


const cookies = new Cookies();

class Logout extends Component {

componentDidMount() {
    this.props.userData.onLogout();
    //cookies.set('authtoken', undefined, { path: '/' } /*, { sameSite: 'None', secure: true}*/);
    //this.props.setLoggedOut();
  };
  render() {

    return (
      <Redirect to='/' />
    );
  }
}

export default Logout;
