import React, { Component } from "react";

import MyProfileB2B from '../../../components/MyProfile/MyProfileB2B';
import styles from '../../../assets/scss/modules/profilpage.module.scss';
import CreateImageBackground from '../../../components/Modals/CreateImageBackground'
import CreateImageProfile from '../../../components/Modals/CreateImageProfile'
import CreateCompany from '../../../components/Modals/CreateCompany'
import FileManager from '../../../components/Modals/FileManager'
import Article from '../../../components/Modals/Article'
import SonstigeNavB2BHersteller from '../../../components/NavbarComponentB2B/SonstigeNavB2BHersteller';
import ProfilpageNavB2BHersteller from '../../../components/NavbarComponentB2B/ProfilpageNavB2BHersteller';
import FooterMarktLanding from '../../../components/Footer/FooterMarktLanding';
import { Scrollbar } from "react-scrollbars-custom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar
} from 'mdbreact';

import InterB2BAxios from '../../../Service/InterB2BAxios';

class MyProfilePageB2BHersteller extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {myProfileStatus: true,
									showArticle: false};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.refreshNavbar();
	};

	componentWillUnmount() {
		this.interAxios.cancel();
  }

	onDisabledCompany = () => {
		var self = this;
		this.interAxios.get('/api/disabledCompany/' + this.props.userData.getCurrentCompany()?.id, function(response) {
				self.props.userData.refresh();
			})
	}

	onEnabledCompany = () => {
		var self = this;
		this.interAxios.get('/api/enabledCompany/' + this.props.userData.getCurrentCompany()?.id, function(response) {
				self.props.userData.refresh();
			})
	}

	handleCloseProfile = () => {
    this.setState({showProfile: false});
		this.props.userData.refresh();
  };

  handleCloseCompany = () => {
    this.setState({showCompany: false});
		this.props.userData.refresh();
  };

  handleCloseBackground = () => {
    this.setState({showBackground: false});
		console.log("miért nem csukod be?");
		this.props.userData.refresh();
  };

  handleShowBackground = () => {
    this.setState({showBackground: true,
                   width: 1080,
                   minWidth: 1080,
                   maxWidth: 1080,
                   aspect: 16.5/5,
                   bildName: 'Background'});
  };

  handleShowProfile = () => {
    this.setState({showProfile: true,
                   minWidth: 200,
                   width: 200,
                   maxWidth: 200,
                   aspect: 1,
                   bildName: 'Profile'});
  };

  handleShowCompany = () => {
    this.setState({showCompany: true});
  };

	handleShowFileUpload = () => {
		this.setState({showFileUpload: true})
	}

	handleCloseFileUpload = () => {
		this.setState({showFileUpload: false	})
		this.props.userData.refresh();
	}

	handleShowArticle = () => {
		this.onUpdateMyCompany(this.state.article);

	}

	handleCloseArticle = () => {
		this.setState({showArticle: !this.state.showArticle});
	}

	onResetArticle =() => {
		this.setState({ article: ''});
	}

	onArticleChange = (editorState) => {
		this.setState({article: editorState});
	}

	onUpdateMyCompany = (article) => {
    let self = this;
		this.interAxios.post('/api/companies/aboutUs/' + this.props.userData.getCurrentCompany()?.id, {aboutUs: article}, function(response) {
			self.handleCloseArticle();
			self.props.userData.refresh();
		})
  }

  render() {
    return (
			<>
			<SonstigeNavB2BHersteller userData={this.props.userData}/>
			<div className={styles.bodywrapper}>
				<div className="profil-background">
					<MDBContainer style={{maxWidth: "1250px"}}>
						<MDBRow className="pt-4 m-0">
							<MDBCol md="auto" className="pr-4">
								<ProfilpageNavB2BHersteller activeItem = {"2"} userData={this.props.userData}/>
							</MDBCol>
							<MDBCol className="px-5">
								<MyProfileB2B
									currentCompany= {this.props.userData.getCurrentCompany()}
									public={false}
									companyPhotosUrl = {this.props.userData.getCurrentCompany()?.urls}
									aboutUs = {this.props.userData.getCurrentCompany()?.aboutUs}
									mySubscription = {this.props.userData.getMySubscription()}
									timeStamp = {this.props.userData.getTimeStamp()}
									onDisabledCompany = {this.onDisabledCompany}
									onEnabledCompany = {this.onEnabledCompany}
									{...this.props}
									{...this}
									/>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</div>
				</div>


			{this.state.showBackground ?
			<CreateImageBackground
			show= {this.state.showBackground}
			handleClose = {this.handleCloseBackground}
			currentCompany={this.props.userData.getCurrentCompany()}
			minWidth = {this.state.minWidth}
			width = {this.state.width}
			maxWidth = {this.state.maxWidth}
			aspect = {this.state.aspect}
			bildName = {this.state.bildName}
			userData = {this.props.userData}
			/>: false}
			{this.state.showProfile ?
			<CreateImageProfile
			show= {this.state.showProfile}
			handleClose = {this.handleCloseProfile}
			currentCompany={this.props.userData.getCurrentCompany()}
			minWidth = {this.state.minWidth}
			width = {this.state.width}
			maxWidth = {this.state.maxWidth}
			aspect = {this.state.aspect}
			bildName = {this.state.bildName}
			userData = {this.props.userData}
			/>: false}
			{this.state.showCompany ?
			<CreateCompany
			show= {this.state.showCompany}
			handleClose = {this.handleCloseCompany}
			currentCompany={this.props.userData.getCurrentCompany()}
			article = {this.props.userData.getCurrentCompany()?.aboutUs}
			userData = {this.props.userData}
			/>:false}
			{this.state.showArticle ?
			<Article
			articleState={this.state.article}
			onArticleChange={this.onArticleChange}
			show={this.state.showArticle}
			handleShow={this.handleShowArticle}
			handleClose={this.handleCloseArticle}
			userData = {this.props.userData}
			{...this.props} />:
			false}
			</>

    );
  }
}

export default MyProfilePageB2BHersteller;
