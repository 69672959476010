import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import styles from '../../../assets/scss/modules/profilpage.module.scss';
import SonstigeNavB2B from '../../../components/NavbarComponentB2B/SonstigeNavB2B';
import MenuSideNavB2BikeMain from './MainPageHandler/MenuSideNavB2BikeMain';
import LandingPageB2BikeNavRespoUser from '../../../components/NavbarComponentB2B/LandingPageB2BikeNavRespoUser';
import ProfilpageNavB2B from '../../../components/NavbarComponentB2B/ProfilpageNavB2B';
import SonstigeNavB2BHersteller from '../../../components/NavbarComponentB2B/SonstigeNavB2BHersteller';
import ProfilpageNavB2BHersteller from '../../../components/NavbarComponentB2B/ProfilpageNavB2BHersteller';
import Cookies from 'universal-cookie';
import ib2bicon from "../../../assets/media/images/b2b/ib2b_b2b.png";
import Moment from 'moment';
import 'moment/locale/de';
const cookies = new Cookies();


import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
	MDBInput,
	MDBBtn,
	MDBCardTitle,
	MDBSideNav
} from 'mdbreact';

import InterB2BAxios from '../../../Service/InterB2BAxios';

class VerwaltungB2B extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
		this.state = { };
		let self = this;
		this.interAxios.get('/api/preis/paketpreis', function(response) {
			self.setState({
				preisList: response.data
			});
			for (const packet of response.data){
				if(packet.packet === "premiumMonatlich"){
					self.setState({
						premiumMonatlich: packet.preis
					});
				}
				if(packet.packet === "premiumJahrlich"){
					self.setState({
						premiumJahrlich: packet.preis
					});
				}
			}

		});
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		//this.props.userData.refreshNavbar();
		this.props.userData.onMySubscription();
	};

	componentWillUnmount() {
		this.interAxios.cancel();
  }

	onCustomerPortal = () => {
		let self = this;
		this.interAxios.get('/ecommerce/stripe-customer-portal', function(response) {
			window.open(response.data);
		});
	}


  render() {
		//console.log(this.props.userData.getMySubscription()?.currentPackage);
		const { t } = this.props;
		const client = this.props.client;
		const textpremium = t('sie_können_das_abonnement_hier_andern')
		const textpm = t('jeden_monat_werden_ihrem_konto') + this.state.premiumMonatlich + t('euro_abgezogen')
		const textpj = t('jedes_jahr_werden_ihrem_konto') + this.state.premiumJahrlich*12 + t('euro_abgezogen')
    return (
			<>
				<div className="d-none d-lg-block">
				{this.props.userData.partner() === "Dealer" ?
				<SonstigeNavB2B userData={this.props.userData}/>:false}
				{this.props.userData.partner() === "Supplier" ?
				<SonstigeNavB2BHersteller userData={this.props.userData}/>:false}
				<div className={styles.bodywrapper}>
					<div className="profil-background">
						<MDBContainer style={{maxWidth: "1250px"}}>
							<MDBRow className="pt-4 m-0">
								<MDBCol md="auto" className="pr-4">
								{this.props.userData.partner() === "Dealer" ?
								<ProfilpageNavB2B activeItem = {"7"} userData={this.props.userData}/>:false}
								{this.props.userData.partner() === "Supplier" ?
								<ProfilpageNavB2BHersteller activeItem = {"7"} userData={this.props.userData}/>:false}
								</MDBCol>
								<MDBCol className="px-5">
								<MDBCard className="interb2b-card p-2 m-0" style={{backgroundColor: "transparent"}}>
										<MDBRow center className="px-4">
											<MDBCol md="4">
												<MDBRow center>
													<h4>{t('abonnement_verwalten')}</h4>
												</MDBRow>
												<MDBRow center>
												<img className="netzwerk-ib2b-b2b pt-4" src={ib2bicon}/>
												</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow center>
											{this.props.userData.getMySubscription()?.currentPackage ==="pm" ?
											<MDBCard className="price-card final">
											<MDBCardTitle className="py-0">
											<MDBRow start className="p-3">
											<MDBCol md="6">
													<img
													src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
													className="photo rounded"
													alt=""
													/>
												</MDBCol>
												<MDBCol middle md="6" >
													 <h3 className="unternehmen-text text-capitalize">{this.props.userData.getCurrentCompany()?.name}</h3>
												</MDBCol>
											</MDBRow>
											</MDBCardTitle>
											<MDBCardBody className="text-center pb-3">
												<h1>{t('premium_monatlich')}</h1>
												<div className="price">
													<span className="currency">€</span>
													<span className="value">{this.state.premiumMonatlich}</span>
													<span className="period">/mo</span><br/>
													<span className="period">{t('gültig_bis')}</span>
													<span className="period">{Moment(this.props.userData.getMySubscription()?.gultigBis).locale('de').format('DD MMMM YYYY')}</span><br/>
												</div>
											</MDBCardBody>
											</MDBCard>:false}
											{this.props.userData.getMySubscription()?.currentPackage ==="pj" ?
											<MDBCard className="price-card final">
											<MDBCardTitle className="py-0">
											<MDBRow start className="p-3">
											<MDBCol md="6">
													<img
													src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
													className="photo rounded"
													alt=""
													/>
												</MDBCol>
												<MDBCol middle md="6" >
													 <h3 className="unternehmen-text text-capitalize">{this.props.userData.getCurrentCompany()?.name}</h3>
												</MDBCol>
											</MDBRow>
											</MDBCardTitle>
												<MDBCardBody className="text-center pb-3">
													<h1>{t('premium_jahrlich')}</h1>
													<div className="price">
														<span className="currency">€</span>
														<span className="value">{this.state.premiumJahrlich}</span>
														<span className="period">/mo</span><br/>
														<span className="period">{t('gültig_bis')}</span>
														<span className="period">{Moment(this.props.userData.getMySubscription()?.gultigBis).locale('de').format('DD MMMM YYYY')}</span><br/>
													</div>
												</MDBCardBody>
											</MDBCard>:false}
										</MDBRow>
										<MDBRow center className="pt-2">
											<MDBCol md="2">
											</MDBCol>
											<MDBCol md="8" className="px-4">
											{this.props.userData.getMySubscription()?.currentPackage ==="pm" ?
											<>
											<p className="text-center font-weight-bolder px-4">{textpm}</p>
											<p className="text-center font-weight-bolder px-4">{textpremium}</p></>:false}
											{this.props.userData.getMySubscription()?.currentPackage ==="pj" ?
											<>
											<p className="text-center font-weight-bolder px-4">{textpj}</p>
											<p className="text-center font-weight-bolder px-4">{textpremium}</p></>:false}

											</MDBCol>
											<MDBCol md="2">
											</MDBCol>
										</MDBRow>
										<MDBRow center className="p-4">
												<MDBBtn type="button"
																className="management-btn text-capitalize"
																onClick={this.onCustomerPortal}>Abonnement verwalten
												</MDBBtn>
										</MDBRow>
								</MDBCard>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</div>
				</div>
				</div>
				<div className="d-block d-lg-none">
				<MDBContainer fluid className="navbarcomponentRespo-nav-container-b2b">
					<div className={styles.bodywrapper4}>
						<div className="side-nav-menu-b2b">
						<MDBSideNav
						right
						triggerOpening={this.props.isSideNavOpen}
						onOverlayClick={this.props.handleSideNavToggle}
						breakWidth={1300}
						className="deep-purple darken-4"
						>
						<MenuSideNavB2BikeMain
							userData={this.props.userData}
							onMenuClose = {this.props.onEnableScroll}
							onItemClose = {this.props.onEnableScrollMain}
							activeItem = {1}
						/>
						</MDBSideNav>
						</div>
						<LandingPageB2BikeNavRespoUser  activeItem = {"7"}
																						userData={this.props.userData}
																						onMenuOpen = {this.props.handleSideNavToggle}
																						onRefInterB2B={this.onRefInterB2B}
																						textSearch = {this.state.textSearch2}
																						onGetSearchText = {this.onGetSearchText}/>
						<MDBRow className="pt-4 m-0 px-2">
							<MDBCard className="interb2b-card p-2 m-0" style={{backgroundColor: "transparent"}}>
								<MDBRow center className="px-4 m-0">
									<MDBCol md="4">
										<MDBRow center className="m-0">
											<h4>{t('abonnement_verwalten')}</h4>
										</MDBRow>
										<MDBRow center className="m-0">
										<img className="netzwerk-ib2b-b2b pt-4" src={ib2bicon}/>
										</MDBRow>
									</MDBCol>
								</MDBRow>
								<MDBRow center className="m-0">
									{this.props.userData.getMySubscription()?.currentPackage ==="pm" ?
									<MDBCard className="price-card final">
										<MDBCardTitle className="py-0">
										<MDBRow start className="p-3 m-0">
											<MDBCol md="6">
												<img
												src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
												className="photo rounded"
												alt=""
												/>
											</MDBCol>
											<MDBCol middle md="6" >
												 <h3 className="unternehmen-text text-capitalize">{this.props.userData.getCurrentCompany()?.name}</h3>
											</MDBCol>
										</MDBRow>
										</MDBCardTitle>
										<MDBCardBody className="text-center pb-3">
											<h1>{t('premium_monatlich')}</h1>
											<div className="price">
												<span className="currency">€</span>
												<span className="value">{this.state.premiumMonatlich}</span>
												<span className="period">/mo</span><br/>
												<span className="period">{t('gültig_bis')}</span>
												<span className="period">{Moment(this.props.userData.getMySubscription()?.gultigBis).locale('de').format('DD MMMM YYYY')}</span><br/>
											</div>
										</MDBCardBody>
									</MDBCard>:false}
									{this.props.userData.getMySubscription()?.currentPackage ==="pj" ?
									<MDBCard className="price-card final">
										<MDBCardTitle className="py-0">
											<MDBRow start className="p-3 m-0">
												<MDBCol md="6">
													<img
													src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
													className="photo rounded"
													alt=""
													/>
												</MDBCol>
												<MDBCol middle md="6" >
													 <h3 className="unternehmen-text text-capitalize">{this.props.userData.getCurrentCompany()?.name}</h3>
												</MDBCol>
											</MDBRow>
										</MDBCardTitle>
										<MDBCardBody className="text-center pb-3">
											<h1>{t('premium_jahrlich')}</h1>
											<div className="price">
												<span className="currency">€</span>
												<span className="value">{this.state.premiumJahrlich}</span>
												<span className="period">/mo</span><br/>
												<span className="period">{t('gültig_bis')}</span>
												<span className="period">{Moment(this.props.userData.getMySubscription()?.gultigBis).locale('de').format('DD MMMM YYYY')}</span><br/>
											</div>
										</MDBCardBody>
									</MDBCard>:false}
								</MDBRow>
								<MDBRow center className="pt-2 m-0 px-2">
									<MDBCol md="2">
									</MDBCol>
									<MDBCol md="8" className="px-4">
									{this.props.userData.getMySubscription()?.currentPackage ==="pm" ?
									<>
									<p className="text-center font-weight-bolder px-4">{textpm}</p>
									<p className="text-center font-weight-bolder px-4">{textpremium}</p></>:false}
									{this.props.userData.getMySubscription()?.currentPackage ==="pj" ?
									<>
									<p className="text-center font-weight-bolder px-4">{textpj}</p>
									<p className="text-center font-weight-bolder px-4">{textpremium}</p></>:false}

									</MDBCol>
									<MDBCol md="2">
									</MDBCol>
								</MDBRow>
								<MDBRow center className="p-4 m-0 px-2">
										<MDBBtn type="button"
														className="management-btn text-capitalize"
														onClick={this.onCustomerPortal}>Abonnement verwalten
										</MDBBtn>
								</MDBRow>
							</MDBCard>
						</MDBRow>
					</div>
				</MDBContainer>
				</div>
			</>

    );
  }
}

export default withTranslation()(VerwaltungB2B);
