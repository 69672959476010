import React from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { Link } from "react-router-dom";
import { ProfileType, UserStatus } from "../../../../Config/Config";
import Moment from 'moment';
import peoplewhiteicon from "../../../../assets/media/newicons/peoplewhite.png";

import {
  MDBListGroupItem,
  MDBRow,
  MDBCol,
} from 'mdbreact';

const ChatMessageMain = (props) => {
  let profile = "";
  let status = null;
  switch (props.status) {
    case UserStatus.Online:
      status = "avatar-online";
      break;
    case UserStatus.Offline:
      status = "avatar-offline";
      break;
    default:
      break;
  }
  switch (props.profileType) {
    case ProfileType.Image:
      profile = (

        <img alt="" className="photo" src={process.env.REACT_APP_BACKEND_URL+'/storage/profile/newProfile_' + props.partnerId + '.jpg?' + props.profileImageHash}></img>

      );
      break;
    case ProfileType.Svg:
      profile = (
        <div className="avatar avatar-sm">
          <img alt="" className="groupchat-icon" src={peoplewhiteicon}></img>
        </div>
      );
      break;
    case ProfileType.Text:
      profile = (

          <img alt="" className="photo" src={process.env.REACT_APP_BACKEND_URL+'/storage/profile/newProfile_' + props.companyId + '.jpg?' + props.profileImageHash}></img>

      );
      break;
    default:
      break;
  }
 let tsmoment = Moment(Date.now());
 const { t } = props;
 const client = props.client;
  return (
	<MDBListGroupItem
    	href={props.to}
    	onClick={props.handleChatClick}
    	className={
        	"list-group-item " +
			(props.ChatUserId && window.location.href.search(props.ChatUserId) > 0 ? "active " : " ") +
			(props.badge ? "unread" : "")
      	}
    >
    	<MDBRow>
    		<MDBCol md="2" className="text-left">
    			<div className={"avatar avatar-sm " + status}>
					{profile}
				</div>
    		</MDBCol>
    		<MDBCol  md="5" className="text-left pl-4">
    			<h6 className="chat-name text-truncate">{props.name}</h6>
				<p className="text-truncate">
        {props.message === "Kein Nachrichten" ?
          t('kein_nachrichten') :
        props.message}
        </p>
            </MDBCol>
    		<MDBCol md="3">
    			{props.time !== null ?
				<span className="chat-contacts-time">
				{Moment(props.time).date() === tsmoment.date()?
                <span>{Moment(props.time).format('h:mma')}</span>:
                <span>{Moment(props.time).format('MM.DD.YY')} </span>} </span>:false}
    		</MDBCol>
        {props.badge ?
         <MDBCol md="2">
              <div className="badge badge-rounded badge-primary ml-1">
                {props.badge}
              </div>
          </MDBCol> : false}
    	</MDBRow>
	</MDBListGroupItem>

  );
};
export default withTranslation()(ChatMessageMain);
