import React, { Component } from "react";

import styles from '../../../../assets/scss/modules/profilpage.module.scss';
import SonstigeNavB2B from '../../../../components/NavbarComponentB2B/SonstigeNavB2B';
import ProfilpageNavB2B from '../../../../components/NavbarComponentB2B/ProfilpageNavB2B';
import BikeAnzeigeKarteEintraegeB2B from '../../../../components/Companys/BikeAnzeigeKarteEintraegeB2B';
import { Scrollbar } from "react-scrollbars-custom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar
} from 'mdbreact';

import InterB2BAxios from '../../../../Service/InterB2BAxios';
let language = '';
class EintraegeB2BDesktop extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {myProfileStatus: true,
									showArticle: false};
  };

	componentDidMount() {
		this.props.addFunctionFromChild(this.onMessage);
		var params = window.location.href.split(".");
	  language = params[params.length - 1];
		this.onGetBikesWithUserId();
	};

	componentWillUnmount() {
		this.props.removeFunctionFromChild(this.onMessage);
		this.interAxios.cancel();
  }

	onMessage = (message) => {
    let type = message.type;
		if (type === 'NEWEBIKEPRODUCT'){
	    this.onGetBikesWithUserId();
	  }
  };

	onGetBikesWithUserId = () => {
		let self = this;
		this.interAxios.get('/api/allBikeProductWithUserId/', function(response) {
			self.setState({bikeslist: response.data});
		})
	};

  render() {
    return (
			<>
			<SonstigeNavB2B userData={this.props.userData}/>
			<div className={styles.bodywrapper}>
				<div className="profil-background">
					<MDBContainer style={{maxWidth: "1250px"}}>
						<MDBRow className="pt-4 m-0">
							<MDBCol md="auto" className="pr-4">
								<ProfilpageNavB2B activeItem = {"4"} userData={this.props.userData}/>
							</MDBCol>
							<MDBCol className="px-5">
							<MDBRow className="m-0 eigene-padding-bottom">
							{this.state.bikeslist?.length > 0 ?
									<>
									{this.state.bikeslist.map(
									(o, index) =>
									<MDBCol md="3" size="6" lg="3" className="mb-0 px-2 py-1">
													<BikeAnzeigeKarteEintraegeB2B
													 key={index}
													 userData={this.props.userData}
													 msg={o}/>
									</MDBCol>

								)}
								 </>:false}
								 </MDBRow>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</div>
				</div>
			</>

    );
  }
}

export default EintraegeB2BDesktop;
