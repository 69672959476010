import React, { Component } from "react";
import ReactDOM from 'react-dom';
import BikeInserierenUpdateB2BDesktop from './BikeInserierenUpdateB2B/BikeInserierenUpdateB2BDesktop';
import BikeInserierenUpdateB2BResponziv from './BikeInserierenUpdateB2B/BikeInserierenUpdateB2BResponziv';

class BikeInserierenUpdateB2B extends Component {

	constructor(props) {
    super(props);
    this.state = {};
  };

  render() {

    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<BikeInserierenUpdateB2BDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<BikeInserierenUpdateB2BResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
	 		</>
    );
  }
}

export default  BikeInserierenUpdateB2B;
