import React from 'react';
import InterB2BAxios from '../../Service/InterB2BAxios';
import Cookies from 'universal-cookie';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import chaticon from "../../assets/media/newicons/messages-2.png";
import emailicon from "../../assets/media/newicons/sms-edit.png";
import arrowicon from "../../assets/media/newicons/arrow-right.png";

const cookies = new Cookies();

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
  MDBAvatar,
  MDBIcon,
  MDBBtn,
  MDBCardUp,
  MDBTooltip
} from 'mdbreact';
import { Link } from "react-router-dom";


class IhrNetzwerk extends React.Component {
	state = { token : cookies.get('authtoken').token,
						myUserId: cookies.get('authtoken').userId};
  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
  };

	componentDidMount() {
		if(!this.props.followed){
			this.onChatroomId(this.props.msg.id);
		}
  };

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	onChatroomId = (companyId) => {
    let self = this;
		this.interAxios.get('/api/chat/room/searchChatroom/' + companyId, function(response) {
			self.setState({chatroomid: response.data});
    });
  };

  truncateString = (str, num) => {
		if (str.length <= num) {
			return str
  		}
  			return str.slice(0, num) + '...'
	}

  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
<>
<MDBCol sm="12" md="4" lg="3" className="py-0 px-2">
	<MDBCard  className="interb2b-card">
		<MDBCardBody>
			<MDBRow className="pt-5 pb-4">
				<MDBCol className="text-center">
					<MDBAvatar
						style={{width: "110px", height: "110px"}}
						tag="img"
						src={this.props.msg.profileUrl}
						alt="Sample avatar"
					/>
				</MDBCol>
			</MDBRow>
			<MDBRow className="px-2">
				<MDBCol className="text-center">
					<h5 className="font-weight-bold mb-4" style={{color: "#212529"}}>
						<MDBTooltip
							domElement
							tag="span"
							material
							placement="top"
						>
							<span>{this.truncateString(this.props.msg.name, 17)}</span>
							<span>{this.props.msg.name}</span>
						</MDBTooltip>
					</h5>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol className="text-center">
					<p className="dark-grey-text mt-4">
						{this.props.msg.postcode} {this.props.msg.town}<br/>
						{this.props.msg.street}
					</p>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol className="text-center">
					<MDBRow className="d-flex justify-content-center">
						{!this.props.followed ?
						<>
						<MDBCol size="3">
							<Link
								className="netzwerk-contacts-link"
								to={"/Chats/user/" + this.state.chatroomid}
							>
								<img src={chaticon} />
							</Link>
						</MDBCol>
						<MDBCol size="3">
							<Link
								className="netzwerk-contacts-link"
								to={"mailto:" + this.props.msg.email}
							>
								<img src={emailicon} />
							</Link>
						</MDBCol>
						<MDBCol size="3">
							<Link
										className="netzwerk-contacts-link gradient"
										to={"/CompaniesPage/" + this.props.msg.id}
							>
								<img src={arrowicon} />

							</Link>
						</MDBCol>
						</>:
						<MDBCol size="3">
						{this.props.allCompany ?
							<Link
										className="netzwerk-contacts-link gradient"
										onClick={(event) => this.props.onFindCompanyById(this.props.msg.id)}
							>
								<img src={arrowicon} />

							</Link>:
							<Link
										className="netzwerk-contacts-link gradient"
										to={"/CompaniesPage/" + this.props.msg.id}
							>
								<img src={arrowicon} />

							</Link>}
					</MDBCol>}
						</MDBRow>
						</MDBCol>
						</MDBRow>
						<MDBRow className="pb-2 pt-2">
						{!this.props.followed ?
						<MDBCol className="text-center">
							<MDBBtn flat onClick={() => this.props.onReset(this.props.msg.id)} style={{color: "red"}}><p className="text-capitalize font-weight-bold">{t('entfolgen')}</p></MDBBtn>
						</MDBCol>:
						<>
						{!this.props.allCompany ?
						<MDBCol className="text-center">
							<MDBBtn flat onClick={() => this.props.onFollowed(this.props.msg.id)} style={{color: "red"}}><p className="text-capitalize font-weight-bold">{t('folgen')}</p></MDBBtn>
						</MDBCol>:false}
					  </>}
						</MDBRow>
						</MDBCardBody>
	</MDBCard>
</MDBCol>
</>
    );
  }
}

export default withTranslation()(IhrNetzwerk);
