import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import CompanysItemToMainPage from '../Companys/CompanysItemToMainPage';
import InterB2BAxios from '../../Service/InterB2BAxios';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import {  MDBListGroup,
          MDBCard,
          MDBCardHeader,
          MDBCardTitle,
          MDBCardBody,
          MDBCardFooter,
          MDBIcon
        } from "mdbreact";

const cookies = new Cookies();


class Newsfeed extends Component {
  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {newslist: [],
                  token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,
								  pageName: "newsFeed"};

  };
  componentDidMount() {
    //this.interAxios.refreshUserData(this.props.userData);
    this.props.addFunctionFromChild(this.onMessage);
    this.onHistory();
  };

  componentWillUnmount() {
    this.props.removeFunctionFromChild(this.onMessage);
    this.interAxios.cancel();
  }

  onMessage = (message) => {
    if (message.type === 'NEWS' || message.type === 'BUSINESS'){
      this.onHistory();
    }
  };

  onHistory = () => {
    let self = this;
    this.interAxios.get('/api/history', {
      params: {limit: 2}}, function(response)
    {
        self.setState({newslist: response.data});
    });
  };

  render() {
    const { t } = this.props;
    const client = this.props.client;

    if (!this.props.userData.isGastAdmin() && !this.props.userData.isCurrentCompanyAnonim() && this.state.newslist.length>0) {
      return(
          <MDBCard className="interb2b-card">
            <MDBCardTitle>
              {t('suchverlauf')}
            </MDBCardTitle>
            <MDBCardBody>
            <MDBListGroup>
              {this.state.newslist.map(
                (o) =>
                         <CompanysItemToMainPage
                          key={o.id} msg={o}/>

              )}
            </MDBListGroup>
            </MDBCardBody>
            <MDBCardFooter style={{borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px"}}>
              <Link className="contacts-link" to={"/HistoryPage"}><span style={{color: "rgb(33, 37, 41)"}}>{t('noch_mehr')} </span><MDBIcon icon="angle-double-right" style={{color: "rgb(33, 37, 41)"}}/>
              </Link>
            </MDBCardFooter>
          </MDBCard>
      );
    } else {
      return false;
    }
  }
}
export default withTranslation()(Newsfeed);
