import React, { Component } from "react";
import ReactDOM from 'react-dom';
import BikePageB2BDesktop from './BikePageB2B/BikePageB2BDesktop';
import BikePageB2BResponziv from './BikePageB2B/BikePageB2BResponziv';


class BikePageB2B extends Component {

	constructor(props) {
    super(props);
    this.state = {};
  };

  render() {

    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<BikePageB2BDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<BikePageB2BResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
			</>
    );
  }
}

export default  BikePageB2B;
