import React, { Component } from "react";
import{
  MDBInput,
	MDBTimePicker,
  MDBRow,
  MDBCol
} from 'mdbreact';

import GenericModal from './GenericModal.js';

import moment from 'moment'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import InterB2BAxios from '../../Service/InterB2BAxios';

class SalonEventModal extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    //this.onPriceUpdate(this.props.data?.start, this.props.data?.end, (this.props.data?.maxVisitor ?? 50), this.props.data?.useFree, this.props.data?.id)
  }

  componentDidMount() {
    //this.interAxios.refreshUserData(this.props.userData);
  }

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.data !== this.props.data) {
			this.setState({
				id: this.props.data?.id,
				hostCompanyName: this.props.data?.hostCompanyName,
				title: this.props.data?.title,
				summary: this.props.data?.summary,
				start: this.props.data?.start,
				end: this.props.data?.end,
        maxVisitor: (this.props.data?.maxVisitor ?? 50),
        price: this.props.data?.price,
        useFree: this.props.data?.useFree,
			})
      this.onPriceUpdate(this.props.data?.start, this.props.data?.end, (this.props.data?.maxVisitor ?? 50), this.props.data?.useFree, this.props.data?.id)
		}
	}

  onSummaryChange = (summary) => {
    this.setState({summary: summary});
  }

  onTitleChange = (title) => {
    this.setState({title: title});
  }

	onStartTimeChange = (data) => {
		let arr = data.split(':');
		let startTime = moment(data);
		let start = moment(this.state.start).hours(arr[0]).minutes(arr[1]).toDate();
		this.setState({start: start});
    this.onPriceUpdate(start, this.state.end, this.state.maxVisitor, this.state.useFree, this.state.id);
  }

	onEndTimeChange = (data) => {
		let arr = data.split(':');
		let end = moment(this.state.end).hours(arr[0]).minutes(arr[1]).toDate();
		this.setState({end: end});
    this.onPriceUpdate(this.state.start, end, this.state.maxVisitor, this.state.useFree, this.state.id);
	}

  onMaxVisitorChange = (maxVisitor) => {
    this.setState({maxVisitor: maxVisitor})
    this.onPriceUpdate(this.state.start, this.state.end, maxVisitor, this.state.useFree, this.state.id);
  }

  onUseFreeChange = (useFree) => {
    console.log(useFree);
    this.setState({useFree: useFree});
    this.onPriceUpdate(this.state.start, this.state.end, this.state.maxVisitor, useFree, this.state.id);
  }

  onPriceUpdate = (start, end, maxVisitor, useFree, id) => {
    let self = this;
    this.interAxios.post("/api/salon/price", {start: start, end: end, maxVisitor: maxVisitor, useFree: useFree, id: id}, function(response) {
      self.setState({price: response.data});
    })
    // axios hívás: this.state.start, this.state.end, this.state.maxVisitor, this.state.useFree, return: this.state.price
  }

	internalHandleSave = (data) => {
    this.props.handleSave(this.state);
  }

  render() {
    const { t } = this.props;
		const client = this.props.client;
    return (
			<GenericModal
				{...this.props}
				internalHandleSave={this.internalHandleSave}
			>
            {
                (this.props.config?.error !== undefined)
            ?
                <>
                  <div style={{color: 'red'}}>{this.props.config.error.message}</div>
                  <div style={{color: 'red'}}>{this.props.config.error.detail}</div>
                </>
            :
                <></>
            }
            <p className="text-center">{t('firma')} {this.props.data?.hostCompanyName} </p>
			<span>{t('titel_der_veranstaltung')}</span><br/>
						{
							(this.props.config?.titleEditable ?? false)
						?
							<MDBInput id="jitsi1" getValue={this.onTitleChange} valueDefault={this.props.data?.title}/>
						:
							<div className="py-3 font-weight-bolder">{this.props.data?.title}</div>
						}
            <MDBRow className="pl-3">
            	<MDBCol>
					<p>{t('start')}</p>
					<MDBRow>
						<MDBCol className="px-0">
							<div className="datumformat">{moment(this.props.data?.start).locale('de').format('DD.MM.YYYY')}</div>
						</MDBCol>
						<MDBCol>
            {
              (this.props.config?.startTimeEditable ?? false)
            ?
              <MDBTimePicker doneText="Set" cancelable closeOnCancel id="startTime" hoursFormat={24} hours={moment(this.state.start).hours()} minutes={moment(this.state.start).minutes()} getValue={this.onStartTimeChange}/>
            :
              <div className="datumformat">{moment(this.props.data?.start).locale('de').format('LT')}</div>
            }
            			</MDBCol>
					</MDBRow>
				</MDBCol>
				<MDBCol>
					<p>{t('ende')}</p>
					<MDBRow>
						<MDBCol className="px-0">
							<div className="datumformat">{moment(this.props.data?.end).locale('de').format('DD.MM.YYYY')}</div>
						</MDBCol>
						<MDBCol>
            {
							(this.props.config?.endTimeEditable ?? false)
						?
							<MDBTimePicker doneText="Set" cancelable closeOnCancel id="startTime" hoursFormat={24} hours={moment(this.state.end).hours()} minutes={moment(this.state.end).minutes()} getValue={this.onEndTimeChange}/>
						:
							<div className="datumformat">{moment(this.props.data?.end).locale('de').format('LT')}</div>
						}
						</MDBCol>
					</MDBRow>
				</MDBCol>
            </MDBRow>

            <MDBRow>
            	<MDBCol>
					<span>{t('maximale_teilnehmeranzahl')}</span><br/>
            {
              (this.props.config?.maxVisitorEditable ?? false)
            ?
              <MDBInput id="jitsi1" getValue={this.onMaxVisitorChange} valueDefault={this.state.maxVisitor > 2000000000 ? "" : this.state.maxVisitor}/>
            :
              <div className="py-3 font-weight-bolder">{this.props.data?.maxVisitor > 2000000000 ? "unbegrenzt" : this.props.data?.maxVisitor}</div>
            }
            	</MDBCol>
            </MDBRow>
            <MDBRow center className="py-3">
						{
							(this.props.config?.showLink ?? false)
						?
							<><a href={'/ZoomMeetingPage/' + this.props.data?.link} target="_blank">{t('veranstaltung_beitreten')}</a><br/></>
						:
							<></>
						}
            </MDBRow>

            { (this.props.config?.showVisitors ?? true)
            ?
              <>
                <MDBRow center>
                      <label className="font-weight-bolder" htmlFor="summary">{t('unternehmen_die_teilnehmen_werden')}</label>
                </MDBRow>
                {
                  (this.props.data?.visitors?.length ?? 0) > 0
                ?
                  <>
                    <MDBRow className="pl-3">
                      {t('alles_zusammen_x_unternehmen_kunden_haben_registriert', {x: this.props.data.visitors.length})}
                    </MDBRow>
                    <MDBRow className="pl-3">
                      <ul>
                        {this.props.data?.visitors.map((data) => <li key={data.id}>{data.name}</li>)}
                      </ul>
                    </MDBRow>
                  </>
                :
                <MDBRow className="pl-3">
                  {t('keine_unternehmen_haben_registriert')}
                </MDBRow>
                }
              </>
            :
              false
            }

						{
							(this.props.config?.summaryEditable ?? false)
						? <>
              <MDBRow center>
  						      <label className="font-weight-bolder" htmlFor="summary">{t('beschreibung')}</label>
              </MDBRow>
							<CKEditor
	                 id="summary"
									 data={this.props.data?.summary}
	                 editor={ ClassicEditor }
	                 onChange={ ( event, editor ) => {
	                     const data = editor.getData();
	                     this.onSummaryChange(data);
	                 } }
	            />
              </>
						: <>
              <MDBRow center>
                <label className="font-weight-bolder">{t('zusammenfassung')}</label>
              </MDBRow>
              <MDBRow className="pl-3">
                <div dangerouslySetInnerHTML={{__html: this.props.data?.summary}}/>
              </MDBRow>
						</>}
            {
              (this.props.config?.freeUsable ?? false)
            ?
              <MDBRow>
                <MDBCol>
                  <MDBInput id="free" label={t('freie_videoveranstaltungmöglichkeit_benutzen')} type="checkbox" checked={this.state.useFree} getValue={this.onUseFreeChange}/>
                </MDBCol>
              </MDBRow>
            :
              false
            }
            {
              (this.props.config?.showPrice ?? false)
            ?
              <MDBRow>
                <MDBCol>
                  <span>{t('preis:')}</span><br/>
                  <div className="py-3 font-weight-bolder">{this.state.price !== undefined ? new Intl.NumberFormat(this.props.i18n.language, { style: 'currency', currency: 'EUR' }).format(this.state.price) : "---"}</div>
                </MDBCol>
              </MDBRow>
            :
              false
            }
				</GenericModal>
    );
  }
}
export default withTranslation()(SalonEventModal);
