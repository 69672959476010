import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import { UserStatus } from "../../Config/Config";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import {
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
	MDBBtn,
	MDBCollapse,
	MDBView,
	MDBMask,
	MDBContainer,
	MDBIcon,
	MDBBtnGroup
} from 'mdbreact';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import InterB2BAxios from '../../Service/InterB2BAxios';
const cookies = new Cookies();

class CommentItemBikes extends React.Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {currentCompany: {}, user: {}
		    };
  };

	componentDidMount() {
		if(this.props.msg.companyId !== null){
			this.onCurrentCompany(this.props.msg.companyId);
		}else{
			this.onGetUser(this.props.msg.userId)
		}
  };

	onCurrentCompany = (id) =>{
    var self = this;
		this.interAxios.get('/api/findCompanyById/' + id, function(response) {
			self.setState({currentCompany: response.data});
		})
  };

	onGetUser = (id) => {
		let self = this;
		this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
			self.setState({user: response.data});
		})
	};

  render() {
		let prevDate = Moment(Date.now());
		let prevDate1 = Moment(Date.now()).subtract(1, 'days');
		let prevDate2 = Moment(Date.now()).subtract(2, 'days');
		let tsmoment = Moment(this.props.msg.time);
    return (
				<MDBCard className="bike-comment-card">
					<MDBRow className="m-0 pb-3">
						<MDBCol md="auto" size="auto" middle className="pl-0">
								{this.props.msg.companyId !== null ?
									<img alt="" className="comment-foto" src={this.state.currentCompany.profileUrl +'?' + Date.now()}></img>:<>
										{this.state.user.profileUrl !== undefined ?
											<img alt="" className="comment-foto" src={this.state.user.profileUrl +'?' + Date.now()}></img>:
											<div className="userProfileName-icon">{this.props.userData.userProfilName()}</div>}</>}
						</MDBCol>
						<MDBCol md="auto" size="auto" className="p-0" middle>
								<MDBRow start className="pl-0 pb-2 m-0">
										{this.props.msg.companyId !== null ?
										<span className="comment-name">{this.state.currentCompany.name}</span>:
										<span className="comment-name">{this.state.user.name}</span>}
								</MDBRow>
								<MDBRow start className="pl-0 m-0">
									{tsmoment.isBefore(prevDate2, 'day') ?
									<span className="comment-datum">{tsmoment.locale(this.props.i18n.language).format('DD-MM-YYYY')}</span>:false}
									{tsmoment.isSame(prevDate2, 'day') ?
									<span className="comment-datum">vor 2 Tagen</span>:false}
									{tsmoment.isSame(prevDate1, 'day') ?
									<span className="comment-datum">gestern</span>:false}
									{tsmoment.isSame(prevDate, 'day') ?
									<span className="comment-datum">{tsmoment.format('h:mm a')}</span>:false}
								</MDBRow>
						</MDBCol>
					</MDBRow>
					<MDBRow start className="m-0">
						<MDBCol className="pl-0">
							<div className="comment-text" dangerouslySetInnerHTML={{__html: this.props.msg.content}}/>
						</MDBCol>
					</MDBRow>
				</MDBCard>

    );
  }
}

export default withTranslation()(CommentItemBikes);
