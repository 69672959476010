import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import InterB2BAxios from '../../Service/InterB2BAxios';
import logo from "../../assets/media/logo/1.png";
import { UserStatus } from "../../Config/Config";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import ratingicon from "../../assets/media/newicons/rating.png";
import emptyicon from "../../assets/media/newicons/star.png";
import commenticon from "../../assets/media/newicons/clipboard-text.png";
import CommentItem from '../Companys/CommentItem';
import {
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
	MDBBtn,
	MDBCollapse,
	MDBView,
	MDBMask,
	MDBContainer,
	MDBIcon,
	MDBListGroup,
	MDBListGroupItem,
	MDBTooltip,
  MDBRating,
	MDBInput
} from 'mdbreact';

const cookies = new Cookies();

class FeedbackItemWhitoutComment extends React.Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,
									currentCompanyId: cookies.get('authtoken').currentCompanyId,
									readState: 'read-less',
									iconName: "angle-double-right",
									lastFeedbackUser: {}

                  };
  };

	componentDidMount() {
		this.onLastFeedbackUser(this.props.lastFeedback.userId);
	}

	componentWillUnmount() {
		this.interAxios.cancel();
  }

	onLastFeedbackUser = (id) => {
		let self = this;
		this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
			self.setState({lastFeedbackUser: response.data});
		})
	}

	truncateString = (str, num) => {
		if (str.length <= num) {
			return str
  		}
  			return str.slice(0, num) + '...'
	}

	toggleCollapse = collapseID => () => {
		this.setState(prevState => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : ""
		}));
	}


	handleReadmore = (id) =>{
		const newClassName = this.state.readState === 'read-less' ? 'read' : 'read-less';
		const { t } = this.props;
    const client = this.props.client;
		this.setState({readState: newClassName});
		if(newClassName === 'read'){
			this.setState({iconName: "angle-double-left",
									 	 textstatus: true});
		}else{
			this.setState({iconName: "angle-double-right",
									   textstatus: false});
		}
	}



  render() {
		const { t } = this.props;
    const client = this.props.client;
		let prevDate = Moment(Date.now());
		let prevDate1 = Moment(Date.now()).subtract(1, 'days');
		let prevDate2 = Moment(Date.now()).subtract(2, 'days');
		let tsmoment = Moment(this.props.lastFeedback.time);
		var text ="";
		if (this.props.lastFeedback.text !== ''){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.props.lastFeedback.text, 'text/html');
			text =parsedHtml.documentElement.textContent;
		}
		const secondExample = {
		  size: 13,
		  count: 5,
			color: "black",
		  value: this.props.lastFeedback.rating,
		  a11y: true,
			edit: false,
		  emptyIcon: <img src={emptyicon} style={{width: "85%"}}/>,
		  filledIcon: <img src={ratingicon} style={{width: "85%"}}/>,
		};
		let name = this.props.lastFeedback.companyName !== "" ? this.props.lastFeedback.companyName : this.state.lastFeedbackUser.name;
    return (
      <>
      <MDBRow>
        <MDBCol size="auto" sm="auto" md="auto" lg="auto" xl="auto" className="text-center pb-2">
          <MDBView hover zoom>
          <img
          src={this.props.lastFeedback.companyUrl !== "" ? this.props.lastFeedback.companyUrl +'?' + Date.now() : this.state.lastFeedbackUser.profileUrl +'?' + Date.now()}
          className={this.props.allFeedbackStatus ? "photo rounded d-flex z-depth-1" : "photo-feedback rounded d-flex z-depth-1"}
          alt=""
          />
          </MDBView>
        </MDBCol>
        <MDBCol>
          <MDBRow className="align-items-center">
            <MDBCol size="8" sm="8" md="8" lg="8" xl="8" className="text-left px-0">
            {name !== undefined && name !== null?
							<>
              <p className="font-weight-bold mb-0">

							{name.length>18 ?
              <MDBTooltip
      					domElement
      					tag="span"
      					material
      					placement="top"
      				>
                <>{this.truncateString(name, 18)}</>
      					<>{name}</>
              </MDBTooltip>:
							<>{name}</>}

              </p></>:false}
            </MDBCol>
            <MDBCol size="4" sm="4" md="4" lg="4" xl="4" className="text-right pl-0 pr-1">
							{tsmoment.isBefore(prevDate2, 'day') ?
							<small className="font-weight-normal text-muted">{tsmoment.locale(this.props.i18n.language).format('DD-MM-YYYY')}</small>:false}
							{tsmoment.isSame(prevDate2, 'day') ?
							<small className="font-weight-normal text-muted">vor 2 Tagen</small>:false}
							{tsmoment.isSame(prevDate1, 'day') ?
							<small className="font-weight-normal text-muted">gestern</small>:false}
							{tsmoment.isSame(prevDate, 'day') ?
							<small className="font-weight-normal text-muted">{tsmoment.format('h:mm a')}</small>:false}
            </MDBCol>
          </MDBRow>
          <MDBRow className={this.props.allFeedbackStatus ? "pb-0" : "pb-0 rating-row"}>
								{this.props.lastFeedback.text !== '' ?
								<>
								{this.state.textstatus === true ?
									<small className={this.state.readState + ' pt-2 pr-3'} id={'read-' + this.props.lastFeedback.id}>{text}</small>:
									<small className="pt-2 pr-3">{this.truncateString(text, 60)}</small>}

								{text.length > 60 ?
								<MDBBtn flat
									className="btn-block btn-sm mb-0 pb-1 px-0 rating-btn"
									onClick={() => {
										this.handleReadmore(this.props.lastFeedback.id);
									}}
									style={{ marginBottom: "1rem",	textAlign:"left",	paddingLeft:"9px" }}
								>
									<span style={{textTransform: "capitalize"}}>{this.state.readState === 'read-less' ? t('weiterlesen') : t('weniger')} </span>
									<MDBIcon icon={this.state.iconName} />
								</MDBBtn>:false}
								</>:false}
          </MDBRow>
          <MDBRow className="pt-0">
          {this.props.lastFeedback.rating !== undefined ?
						<ReactStars
						    {...secondExample}
						/>:false}

          </MDBRow>
        </MDBCol>
      </MDBRow>
</>
    );
  }
}

export default withTranslation()(FeedbackItemWhitoutComment);
