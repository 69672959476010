import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Moment from 'moment';
import Flags from 'country-flag-icons/react/3x2'
import EBikeBoerseLinkNavItem from './EBikeBoerseLinkNavItem.js'
import InterB2BAxios from '../../Service/InterB2BAxios';
import ReactFullpage from '@fullpage/react-fullpage';
import { Scrollbar } from "react-scrollbars-custom";
import { Link } from "react-router-dom";
import logo from "../../assets/media/logo/1.png";
import ratingicon from "../../assets/media/newicons/rating.png";
import styles from '../../assets/scss/modules/pages.module.scss';
import searchnormalicon from "../../assets/media/images/markt/searchnormalicon.png";
import group_21 from "../../assets/media/images/b2b/bikeParts.png";
import group_22 from "../../assets/media/images/b2b/E-Bikebörse Logo Neu.png";
import addBike from "../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import logo2 from "../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import anmelden from "../../assets/media/images/markt/anmelden.png";
import signinmarkt from "../../assets/media/images/markt/signinmarkt.png";
import signupmarkt from "../../assets/media/images/markt/signupmarkt.png";
import logo3 from "../../assets/media/images/markt/Group 18.png";
import meinprofilicon from '../../assets/media/newicons/meinprofil.png';

import Cookies from 'universal-cookie';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
class SonstigeNavB2B extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'LandingPage');
    this.state = {
								  activeItem: this.props?.activeItem ? this.props.activeItem : "8",
									navbarPaddingTop: true,
									navbarPaddingMiddle: false,
									navbarPadding: false,
									collapseID: -1,
									};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.refresh();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<div className="navbar2b-nav-container fixed-top-landingb2b">
			<MDBContainer style={{maxWidth: "1250px"}}>
					<MDBNav className="landingb2b-nav">
						<MDBNavItem>
							<MDBNavLink link to={this.props.userData.isCurrentCompanyAnonim() ? "/NewCompanyB2B" : "/MainPageHandler"} role="tab">
							<div className="navlink-logo">
									<img alt="" className="group-21-logo" src={group_21}></img>
							</div>
							</MDBNavLink>
						</MDBNavItem>

						<MDBNavItem>
							<MDBNavLink link to={this.props.userData.isCurrentCompanyAnonim() ? "/NewCompanyB2B" : "/NewsPageHandler"} active={this.state.activeItem === "1"} role="tab" >
							<div className="navlink-News">
									<span>{t('aktuelles')}</span>
							</div>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to={this.props.userData.isCurrentCompanyAnonim() ? "/NewCompanyB2B" : "/BlogPageHandler"} active={this.state.activeItem === "2"} role="tab" >
							<div className="navlink-blog">
									<span>{t('bloggen')}</span>
							</div>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to={this.props.userData.isCurrentCompanyAnonim() ? "/NewCompanyB2B" : "/CompaniesPageHandler"} active={this.state.activeItem === "3"} role="tab" >
							<div className="navlink-hersteller">
									<span>{t('hersteller')}</span>
							</div>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to={this.props.userData.isCurrentCompanyAnonim() ? "/NewCompanyB2B" : "/SalonCalendarPage"} active={this.state.activeItem === "4"} role="tab" >
							<div className="navlink-events">
									<span>{t('veranstaltungen')}</span>
							</div>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="" role="tab" >
							<div className="navlink-null">

							</div>
							</MDBNavLink>
						</MDBNavItem>
						<EBikeBoerseLinkNavItem userData={this.props.userData} />
						<Link  to={this.props.userData.isCurrentCompanyAnonim() ? "/NewCompanyB2B" : "/AddBikeB2B"} >
							<MDBBtn
								className="addBike-btn-b2b"
								color="primary"
								type="submit"
							>
								<img alt="" className="addBike-logo" src={addBike}></img>
							</MDBBtn>
						</Link>
						<MDBNavItem>
							<MDBRow className="m-0" style={{height: "100%"}}>
							<MDBCol middle>
								<Link to={this.props.userData.isCurrentCompanyAnonim() ? "/NewCompanyB2B" : "/MyProfilePageB2B"} className="signin-btn-landingmarkt">
								{this.props.userData.getCurrentCompany()?.profileUrl !== undefined ?
									<img className="userProfileName-icon"
										src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + this.state.timeStamp}
										/>:<>
										{this.props.userData.getMyUser()?.profileUrl !== undefined ?
											<img alt="" className="user-photo-navbar" src={this.props.userData.getMyUser()?.profileUrl +'?' + Date.now()}></img>:
											<div className="userProfileName-icon">{this.props.userData.userProfilName()}</div>}</>}
								</Link>
							</MDBCol>
							</MDBRow>
						</MDBNavItem>
					</MDBNav>
				</MDBContainer>
				</div>
		</>
    );
  }
}

export default  withTranslation()(SonstigeNavB2B);
