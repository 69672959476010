import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../../components/LazyLoad/LazyLoadItem';
import { Scrollbar } from "react-scrollbars-custom";

import InterB2BAxios from '../../../../Service/InterB2BAxios';
import CompanysItemWithDesign from '../../../../components/Companys/CompanysItemWithDesign';
import FooterHomePage from '../../../../components/Footer/FooterHomePage';
import BikeAnzeigeKarte from '../../../../components/Companys/BikeAnzeigeKarte';
import SonstigeNavB2B from '../../../../components/NavbarComponentB2B/SonstigeNavB2B';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from '../../../../assets/scss/modules/pages.module.scss';

import logo from "../../../../assets/media/logo/1.png";
import ratingicon from "../../../../assets/media/newicons/rating.png";
import searchnormalicon from "../../../../assets/media/images/markt/searchnormalicon.png";
import group_21 from "../../../../assets/media/images/markt/Group 21.png";
import addBike from "../../../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import logo2 from "../../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import anmelden from "../../../../assets/media/images/markt/anmelden.png";
import signinmarkt from "../../../../assets/media/images/markt/signinmarkt.png";
import signupmarkt from "../../../../assets/media/images/markt/signupmarkt.png";
import logo3 from "../../../../assets/media/images/markt/Group 18.png";
import meinprofilicon from '../../../../assets/media/newicons/meinprofil.png';
import clearOffericon from "../../../../assets/media/newicons/clearOffer.png";
import arrowLeft from "../../../../assets/media/images/arrowLeft.png";
import companyicon from "../../../../assets/media/images/b2b/Group 28.png";
import companyicon_gelb from "../../../../assets/media/images/b2b/Group 29.png";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBAutocomplete,
	MDBSpinner
} from 'mdbreact';

let language = '';
class ProductPageDesktop extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'ProductPage');
    this.state = {textFeld: this.props.textFeld,
									extSearch2: this.props.userData.productname,
									textSearch: "",
									activeItem: this.props.activeItem,
									};
  };


	componentDidMount() {
			var params = window.location.href.split(".");
	    language = params[params.length - 1];
	}

	componentWillUnmount() {
		this.setState({textSearch2: ""});
		this.interAxios.cancel();
	}

	onTextSearchChange = event => {
     this.setState({ [event.target.name]: event.target.value });
   };

	onSearchProduct = () => {
		this.setState({activeItem: ""});
		this.props.userData.onSearchInactive();
		this.props.userData.onSearchProductCount(this.state.textSearch2);
		this.onTextFalse();
	}

	onSearchFullProduct = () => {
		this.setState({activeItem: ""});
		this.props.userData.onSearchProductFull(this.props.userData.productname);
	}

	onTextTrue = () => {
		this.setState({textFeld: !this.state.textFeld});
	}

	onTextFalse = () => {
		this.setState({textFeld: false});
	}


	onKey = (ev) => {
		ev.preventDefault();
		if(this.state.textSearch2 !== "" ){
			this.onSearchProduct()
		}else{
			 this.onTextTrue();
		}
	}

	toggle = tab => e => {
		console.log(tab);
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
				}
	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
		let searchLis = this.props.userData.offerlist !== undefined ? this.props.userData.offerlist : {};

		let companiesList = this.props.userData.availableCompanies.length > 0 ? this.props.userData.availableCompanies.map(
								(o) => {
										return (
														 <div key={o.id} className="p-0">
										 					<MDBNavItem>
										 						<MDBNavLink link to="#" active={this.state.activeItem === o.id} onClick={this.toggle(o.id)} role="tab" >
																	<MDBBtn
																			className="product-filtercompany-button"
																			flat
																			onClick={() => this.props.userData.onSearchProductWithCompanyFilter(this.props.userData.productname, o.name)}>
																	 <MDBRow className="m-0">
																		 <MDBCol middle md="1" className="px-0 col-height text-left">
																			<img alt="" className="profil-logo" src={this.state.activeItem === o.id ? companyicon_gelb : companyicon}></img>
																		</MDBCol>
																		<MDBCol middle  md="9" className="pl-2 pr-2 col-height text-left text-capitalize">
																			<span className="profilpage-menu">{o.name}:</span>
																		</MDBCol>
																		 <MDBCol middle  md="2" className="p-0 text-right">
																		 <span className="profilpage-menu">{o.count}</span>
																		 </MDBCol>
																	  </MDBRow>
																  </MDBBtn>
										 						</MDBNavLink>
										 					</MDBNavItem>
														 </div>);
									}
							) : {};
    return (
			<>

			<SonstigeNavB2B userData={this.props.userData}/>

				<div className={styles.bodywrapper}>
							<MDBContainer>
								<MDBRow className="m-0">
									<MDBCol md="4" size="12">
										<div className="sticky-card-b2b">
											<MDBCard className="filter-card-ab2b">
												<MDBCardBody>
													<div className="product-search-b2b-productpage">
														<form className= "form-inputs" >
															<MDBRow center className="p-0 m-0">
																<MDBCol top md="10" className="pl-0" middle>
																	<input
																		onKeyPress={(ev) => {
																				if (ev.key === "Enter") {
																					this.onKey(ev);
																				}
																		}}
																		type="text"
																		className="form-control"
																		id="formGroupExampleInput2"
																		placeholder={t('produkt_eingeben_3')}
																		name="textSearch2"
																		onChange={this.onTextSearchChange}
																		value={this.state.textSearch2}
																	/>
																</MDBCol>
																<MDBCol top md="2" className="pr-0" >
																	<MDBBtn type="button"
																					className="borse-btn-b2b-small"
																					onClick={this.state.textSearch2 !== "" ? this.onSearchProduct : this.onTextTrue}
																					><img src={searchnormalicon} className="vector-1"/>
																	</MDBBtn>
																</MDBCol>
															</MDBRow>
														</form>
													</div>
													{this.props.userData.searchStatus ? <>
													<MDBRow className="m-0 pb-3">
														<span className="product-filter-text">{t('anzahl der Produkte')}{this.props.userData.productname}: {this.props.userData.productCount}. Ihre
														Suche kann länger als 30 Sekunden dauern, daher bieten wir ein schnelles Suchergebnis mit einer Liste der beliebtesten Produkte.</span>
													</MDBRow>

														<MDBRow className="m-0 py-3">
														<span className="product-filter-text">Hier können Sie die vollständige Liste herunterladen:</span>
														</MDBRow>
														<MDBRow center className="m-0">
															<MDBBtn className="product-filter-btn"
																			onClick={this.onSearchFullProduct}>
															<span>Full list</span>
															</MDBBtn>
														</MDBRow>

														{companiesList.length > 0 ?
														<>
															<MDBRow className="m-0 py-3">
																<span className="product-filter-text">Oder filtern Sie Ihre Suche nach einem bestimmten Anbieter:</span>
															</MDBRow>
															<MDBRow className="m-0">
															<MDBCol md="12" className="p-0">
	 														  <MDBNav className="flex-column b2b-product-nav">
																	{companiesList}
																</MDBNav>
															</MDBCol>
															</MDBRow>

														</>:false}</>:false}
												</MDBCardBody>
											</MDBCard>
										</div>
									</MDBCol>
									<MDBCol md="8" size="12" className="text-center">
											<div id="productlist">
											 { searchLis !== {}  && searchLis.items !== undefined && searchLis.items.length > 0 ?
												 <>

											 {searchLis.items.map(
												 (o, index) => {
													 if (index < 20) {
														 return (
															 <CompanysItemWithDesign
																							key={o.standardItemIdentification.id.value}
																							msg={o}
																							activeItem = {this.state.activeItem}
																							suppliers={searchLis.suppliers}
																							textSearch = {this.state.textSearch2}
																							{...this}
																							{...this.props}
																						 />);
													 } else {
														 return (
															 <LazyLoad key={o.standardItemIdentification.id.value} height={200} offset={[-100, 0]}>
																<LazyLoadItem index={index} size={searchLis.items.length - 20}>
																<CompanysItemWithDesign
																						 key={o.standardItemIdentification.id.value}
																						 msg={o}
																						 suppliers={searchLis.suppliers}
																						 activeItem = {this.state.activeItem}
																						 textSearch = {this.state.textSearch2}
																						 {...this}
																						 {...this.props}
																						/>
																</LazyLoadItem>
														 </LazyLoad>
													 );
													 }
												 }
											 )} </>: this.props.userData.spinner ?
											 					<div className="spinner-position">
																	<MDBSpinner /><br/>
													        <span className="">Loading...</span>
													      </div>:false}
											 </div>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
				</div>
		</>
    );
  }
}

export default  withTranslation()(ProductPageDesktop);
