import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import mailicon from "../../../assets/media/newicons/mail.png";
import phoneicon from "../../../assets/media/newicons/phone.png";
import arrowcirclelefticon from "../../../assets/media/newicons/arrow-circle-left2.png";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBTooltip,
	MDBBtn,
	MDBIcon
} from 'mdbreact';

class PersonSection extends Component {

  constructor(props) {
    super(props);

  };

	truncateString = (str, num) => {
		if(str !== null){
			if (str.length <= num) {
				return str
	  	}
	  			return str.slice(0, num) + '...'
		}
	};

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<section className="chat-header">
				<MDBContainer>
					<MDBRow>
						<MDBCol size="8" lg="6" className="chat-header-left">
							<MDBRow>
								<MDBCol size="1" className="px-0  d-block d-md-none">
									<a href="/Chats" className="chat-back-btn"><MDBIcon icon="arrow-left" size="lg" style={{paddingLeft: "3px", paddingTop: "7px"}} /></a>
								</MDBCol>
								<MDBCol size="7" lg="8">
								{this.props.selecetdChatroom?.partnerId !== undefined ?
									<>
										<img alt=""
													className="avatar rounded-circle float-left"
													src={process.env.REACT_APP_BACKEND_URL+'/storage/profile/newProfile_'
														+ this.props.selecetdChatroom?.partnerId + '.jpg?' + Date.now()}></img>
									</>:false}


										{this.props.selectedChatroom?.name.length > 23 ?
										<MDBTooltip
										 domElement
										 tag="p"
										 material
										 placement="bottom"
									 >
													<p  className="font-weight-bolder text-left">{this.truncateString(this.props.selectedChatroom?.name, 23)}</p>
													<p  className="font-weight-bolder text-left">{this.props.selectedChatroom?.name}</p>
									 </MDBTooltip>:
									 <h5>{this.props.selectedChatroom?.name}</h5>}


										{this.props.statusText === "Online" ?
										<div className="chat-status status-online"/>:
										<div className="chat-status status-offline"/>}

										<small className="chat-status-text">{this.props.statusText}</small>


								</MDBCol>
								<MDBCol size="4" lg="3">
									<a href="#"><img src={mailicon} className="icon" /></a>
									<a href="#"><img src={phoneicon} className="icon" /></a>
								</MDBCol>
							</MDBRow>
						</MDBCol>
						<MDBCol size="4" lg="6" className="chat-header-right">
							<p className="chat-header-atitle">{t('adresse')}</p>
							<p className="chat-header-acontent">{this.props.selecetdChatroom?.adresse}</p>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</section>
			</>
    );
  }
}
export default withTranslation()(PersonSection);
