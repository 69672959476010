import React, { Component, Fragment } from "react";
import CompaniesPageHandlerDesktop from './CompaniesPageHandler/CompaniesPageHandlerDesktop';
import CompaniesPageHandlerResponziv from './CompaniesPageHandler/CompaniesPageHandlerResponziv';

class CompaniesPageHandler extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {

    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<CompaniesPageHandlerDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 activeItem = {this.state.activeItem}
																	 msg = {this.state.msg}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<CompaniesPageHandlerResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 activeItem = {this.state.activeItem}
																		 msg = {this.state.msg}
																		 {...this.props}
																		 />
				</div>}
			</>
    );
  }
}

export default CompaniesPageHandler;
