import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import { UserStatus } from "../../Config/Config";
import {
	MDBRow,
	MDBCol
} from 'mdbreact';


class LikeItem extends React.Component {

  render() {
    return (
			<MDBRow className="justify-content-between align-items-center ">
			<MDBCol className="text-left">
					<p className="mb-1 font-italic" style={{color: "#a5a8aa"}}>{this.props.msg.companyName !== null ? this.props.msg.companyName : this.props.msg.userName}</p>
			</MDBCol>

	 	</MDBRow>
    );
  }
}

export default LikeItem;
