import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import searchnormalicon from "../../assets/media/images/markt/searchnormalicon.png";
import respo_ebike_logo_fent from "../../assets/media/images/b2b/landingrespo/E-Bikebörse Logo fent.png";
import bikeParts from "../../assets/media/images/b2b/landingrespo/bikeParts.png";
import hamburgericon from "../../assets/media/images/b2b/landingrespo/hamburger.png";
import group_1 from "../../assets/media/images/b2b/landingrespo/Group 1.png";
import group_2 from "../../assets/media/images/b2b/landingrespo/Group 2.png";
import group_3 from "../../assets/media/images/b2b/landingrespo/Group 3.png";
import rectangle_1 from "../../assets/media/images/b2b/landingrespo/Rectangle-1.png";
import rectangle_2 from "../../assets/media/images/b2b/landingrespo/Rectangle-2.png";
import rectangle_3 from "../../assets/media/images/b2b/landingrespo/Rectangle-3.png";
import arrowLeft from "../../assets/media/images/arrowLeft.png";
import ProfilpageNavRespob2b from './ProfilpageNavRespob2b';
import InterB2BAxios from '../../Service/InterB2BAxios';
import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
class LandingPageB2BikeNavRespo2 extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'LandingPage');
    this.state = {
								  //activeItem: "1",
									activeVideoItem: "1",
									navbarPaddingTop: true,
									navbarPaddingMiddle: false,
									navbarPadding: false,
									collapseID: -1,
									textSearch: '',
									textFeld: false,
									redirect: false,
									textSearch2: this.props.userData.productname,
									};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.refresh();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
	}

	componentWillUnmount() {
		this.setState({textSearch2: ""});
		this.interAxios.cancel();
	}

	onTextSearchChange = event => {
     this.setState({ [event.target.name]: event.target.value });
   };

	onSearchProduct = () => {
		this.props.userData.onSearchProductCount(this.state.textSearch2);
		this.onTextFalse();
		this.setState({redirect: true});
	}

	onTextTrue = () => {
		this.setState({textFeld: !this.state.textFeld});
	}

	onTextFalse = () => {
		this.setState({textFeld: false, textSearch: ""});
	}

	onKey = (ev) => {
		ev.preventDefault();
		if(this.state.textSearch2 !== "" ){
			this.onSearchProduct()
		}else{
			 this.onTextTrue();
		}
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
				<div className="landing-nav-container-scroll2 fixed-top-landingb2b-respo">
					<MDBRow start className="hamburegermenu-row">
						<MDBCol middle size="4" sm="4" md="4" className="text-left m-0 p-0">
						<Link to="/MainPageHandler">
							<img className="landing-bikeparts-logo" src={bikeParts} onClick={this.props.onRefInterB2B}/>
						</Link>
						</MDBCol>
						<MDBCol middle size="8" sm="8" md="8" className="text-right m-0 p-0">
							<MDBBtn flat
											className="hambureger-menu-mainpage"
											type="button"
											onClick= {this.props.onMenuOpen}>
											<img className="hambureger-icon" src={hamburgericon}/>
							</MDBBtn>
						</MDBCol>
					</MDBRow>
					<MDBRow start className="m-0">
						<div className="product-search-markt-header">
							<form className= ""
										>
								<MDBRow start className="m-0">
									<MDBCol top size="10" sm="6" md="6" className="pl-0" middle>
										<input
													onKeyPress={(ev) => {
															if (ev.key === "Enter") {
																this.onKey(ev);
															}
													}}
													type="text"
													className="form-control"
													id="formGroupExampleInput"
													placeholder={t('produkt_eingeben_3')}
													name="textSearch2"
													onChange={this.onTextSearchChange}
													value={this.state.textSearch2}
										/>
									</MDBCol>
									<MDBCol top size="2" sm="auto" md="auto" className="p-0 text-right" >
										<MDBBtn type="button"
														className="borse-btn-header"
														onClick={this.state.textSearch2 !== "" ? this.onSearchProduct : this.onTextTrue}
														><img src={searchnormalicon} className="vector-1"/>
										</MDBBtn>
									</MDBCol>
								</MDBRow>
							</form>
						</div>
					</MDBRow>
					{this.props.userData.productname === "" && this.state.textFeld ?
					<MDBContainer className="text-center">
						<MDBRow center className="m-0 pt-3">
							<MDBCol bottom className="text-right pr-0" size="4">
							<img className="arrow-icon-respo"
									 src={arrowLeft}/>
							</MDBCol>
							<MDBCol bottom size="8" className="text-left pl-2" >
							<span className="offer-text" >{t('geben_sie_einen_suchbegriff_in_das_feld_ein')}</span>
							</MDBCol>
						</MDBRow>
					</MDBContainer>:false}
				</div>
				{this.state.redirect ?<Redirect to={{pathname: "/ProductPage", state: { textFeld: this.state.textFeld}}}/>:false}
		</>
    );
  }
}

export default  withTranslation()(LandingPageB2BikeNavRespo2);
