import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import respo_ebike_logo from "../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import hamburgericon from "../../assets/media/images/markt/hamburgericon.png";
import searchnormalicon from "../../assets/media/images/markt/searchnormalicon.png";
import ProfilpageNavRespo from './ProfilpageNavRespo';

import InterB2BAxios from '../../Service/InterB2BAxios';
import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
class BoersePageNavRespo extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'LandingPage');
    this.state = {
								  //activeItem: "1",
									activeVideoItem: "1",
									navbarPaddingTop: true,
									navbarPaddingMiddle: false,
									navbarPadding: false,
									collapseID: -1,
									};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.refresh();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	onSearchProductB2B = event => {
		this.setState({redirect: true});
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<MDBContainer fluid className="navbarcomponentRespo-nav-container">
				<div className="landing-nav-container-scroll fixed-top">
					<MDBRow end className="hamburegermenu-row">
						<MDBBtn flat
										className="hambureger-menu"
										type="button"
										onClick= {this.props.onMenuOpen}>
										<img className="hambureger-icon" src={hamburgericon}/>
						</MDBBtn>
					</MDBRow>
					<MDBRow start className="hamburegermenu-row">
						<div className="product-search-markt-header">
							<form className= ""
										onSubmit={async (event) => {await this.onSearchProductB2B(event);}}>
								<MDBRow start className="m-0">
									<MDBCol top size="10" sm="6" md="6" className="pl-0" middle>
										<input
													type="text"
													className="form-control"
													id="formGroupExampleInput2"
													placeholder={t('produkt_eingeben_3')}
													name="textBike"
													onChange={this.props.userData.onTextSearchChange}
													value={this.props.userData.textBike}
										/>
									</MDBCol>
									<MDBCol top size="2" sm="auto" md="auto" className="p-0 text-right" >
										<MDBBtn type="submit"
														className="borse-btn-header">
											<img src={searchnormalicon} className="vector-1"/>
										</MDBBtn>
									</MDBCol>
								</MDBRow>
							</form>
						</div>
					</MDBRow>
					<Link to="/LandingPageMarkt"><img className="ebike-logo-respo" src={respo_ebike_logo}/></Link>
					{this.props.isUserMenu ? <> {!this.props.isOpen ?
					<ProfilpageNavRespo activeItem = {this.props.activeItem} userData={this.props.userData}/>:
					<div className="grau-back"/>}</>:false}
				</div>
				</MDBContainer>
				{this.state.redirect ?
				 <Redirect
				 to="/BoersePage"/>:false}
		</>
    );
  }
}

export default  withTranslation()(BoersePageNavRespo);
