
import React, { Component } from "react";
import Cookies from 'universal-cookie';
import Interb2bNewsItemsB2B from '../News/Interb2bNewsItemsB2B';
import InterB2BAxios from '../../Service/InterB2BAxios';
import { MDBListGroup } from "mdbreact";

const Parser = require('rss-parser');
const parser = new Parser();
const cookies = new Cookies();


class Interb2bNews extends Component {
  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {news:{}};
  };

  componentDidMount() {
    //this.interAxios.refreshUserData(this.props.userData);
    this.onNews();
  };

  componentWillUnmount() {
		this.interAxios.cancel();
  }



  onNews = () => {
    let self = this;
    this.interAxios.get('/api/rss', function(response)
    {
      let promise = parser.parseString(response.data)

      promise.then((value) => {
        self.setState({news: value.items});
      });
    });
  }

  render() {
	return(<>
          {this.state.news.length > 0 ?
              <>
              {this.state.news.map(
              (o, index) =>
                      <Interb2bNewsItemsB2B
                       key={index}
                       msg={o}/>

            )}
          </>:false}
          </>
		)
	}
}
export default Interb2bNews
