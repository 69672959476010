import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo from "../../assets/media/images/markt/E-Bikebörse Logo 3.png";
import{
	MDBRow,
	MDBCol,
	MDBCard,
	MDBNav,
	MDBNavItem,
	MDBNavLink
} from 'mdbreact';

class FooterMarktLanding extends Component {

  constructor(props) {
    super(props);
  };

	/*aboutUs
	impressum
	privacyPolicy
	termsOfUse
	cookiePolicy*/
  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
      <div className="footer-nav">
			<div className="text-center py-2">
	    		<img alt="" className="copyright-logo-markt" src={logo}></img>
      </div>
			<MDBNav className="justify-content-center pb-2">
				<MDBNavItem>
					<MDBNavLink to="/About"><span className="footertext">{t('aboutUs')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/Impressum"><span className="footertext">{t('impressum')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/PrivacyPolicy"><span className="footertext">{t('privacyPolicy')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/UserAgreement"><span className="footertext">{t('termsOfUse')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/CookiePolicy"><span className="footertext">{t('cookiePolicy')}</span></MDBNavLink>
				</MDBNavItem>
			</MDBNav>
			 </div>


    );
  }
}

export default withTranslation()(FooterMarktLanding);
