import React, { Component } from "react";
import ChatsBikeResponziv from "./ChatsBike/ChatsBikeResponziv";
import ChatsBikeDesktop from "./ChatsBike/ChatsBikeDesktop";

class ChatsBike extends Component {

  constructor(props) {
    super(props);
    this.state = {
									};


  };

  render() {

    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<ChatsBikeDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
                                   sendMessage = {this.props.sendMessage}
                                   {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<ChatsBikeResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
                                     sendMessage = {this.props.sendMessage}
                                     {...this.props}
																		 />
				</div>}
			</>
    );
  }
}
export default ChatsBike;
