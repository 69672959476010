import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import Cookies from 'universal-cookie';
import axios from 'axios';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn
} from 'mdbreact';
import InterB2BAxios from '../../../Service/InterB2BAxios';
import styles from '../../../assets/scss/modules/signinB2B.module.scss';
import arrowcircleleft from "../../../assets/media/images/b2b/arrow-circle-left.png";
import arrowcircleright from "../../../assets/media/images/b2b/arrow-circle-right.png";
import group_12 from "../../../assets/media/images/b2b/Group 12.png";
import FooterB2BSingUp from '../../../components/Footer/FooterB2BSingUp';
const cookies = new Cookies();
// SignUp component
class SignUpHersteller extends Component {
  state = {
    isRTL: Boolean(localStorage.getItem("rtl")),
    name: '',
    username: '',
    email: '',
    password: '',
    redirect: false,
  };

	constructor(props) {
		super(props);
		this.interAxios = new InterB2BAxios(this);
	}

  // Load selected theme
  componentDidMount() {
		let url = new URL(window.location.href);
		let params = new URLSearchParams(url.search);
		if(params!=""){
			this.setState({email: params.get("email")});
		}
		this.props.userData.onLogout();
    if (this.state.isRTL) document.body.setAttribute("dir", "rtl");
    else document.body.removeAttribute("dir");
  }

	componentWillUnmount() {
		this.interAxios.cancel();
	}

  onNameChange = (event) =>{
    this.setState({name: event.target.value});
  };

  onUsernameChange = (event) =>{
    this.setState({username: event.target.value});
  };

  onEmailChange = (event) =>{
    this.setState({email: event.target.value});
  };

  onPasswordChange= (event) =>{
    this.setState({password: event.target.value});
  };

	onSubmit = (event) =>{
		const form = event.currentTarget;
    event.preventDefault();
		event.target.className += " was-validated"
		if((form.checkValidity() !== false)){
	    let self = this;
	    axios.post(process.env.REACT_APP_BACKEND_URL+'/api/auth/signup', {
	         name: this.state.name,
	         username: this.state.username,
	         email: this.state.email,
	         password: this.state.password,
					 partner: "Supplier"
	       }
	     ).then(function(response) {
	       self.setState({ redirect: true });
	     })
	     .catch(function(error) {
	       console.log(error.response.data.message);
	       console.log(error.response.data);
	 		 	 console.log(error.response.status);
	 	 		 console.log(error.response.headers);
			})
		}
  };

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
      <div className={styles.bodywrapper1}>
			<div className="rectangle-16"/>
      <MDBContainer className="signin-container">
      <MDBRow center className="signin-row-padding">
        <MDBCol sm="6" md="6" lg="4">
					<MDBRow start className="pb-3 m-0">
        	<Link to="/">
          	<img className="vuesax-linear-arrow-circle-lef" src={arrowcircleleft}></img>
          </Link>
					</MDBRow>
					<MDBRow start className="pb-3 m-0">
					<img className="group_11" src={group_12}></img>
					</MDBRow>
					<MDBRow start className="pb-2 m-0">
					<Link to="/SignUpHandler">
          	<span className="cant-sign-in2">{t('Ich_bin_Haendler')}</span><img className="vuesax-linear-arrow-circle-rig" src={arrowcircleright}></img>
          </Link>
					</MDBRow>
					<MDBRow start className="pb-2 m-0">
					<span className="sign-up-to-inter-b-2-bi">{t('Melden_Sie_sich_bei_InterB2Bike_an_und_erreichen')}</span>
					</MDBRow>
					<form className="signinb2b-form needs-validation" onSubmit={async (event) => {await this.onSubmit(event);}} noValidate>
							<MDBInput
								id="materialFormRegisterNameEx"
								className="signinb2b-input"
								placeholder={t('geben_sie_ihren_namen_ein')}
								label= {t('name')}
								type="text"
								required
								value={this.state.name}
								onChange={this.onNameChange}
							>
							 <div className="invalid-feedback">
								 Please provide a valid name.
							 </div>
							 <div className="valid-feedback">Looks good!</div>
						 </MDBInput>

							<MDBInput
								id="materialFormRegisterUsernameEx2"
								className="signinb2b-input"
								placeholder={t('geben_sie_ihren_benutzernamen ein')}
								label= {t('benutzername')}
								type="text"
								required
								value={this.state.username}
								onChange={this.onUsernameChange}
							>
							 <div className="invalid-feedback">
								 Please provide a valid username.
							 </div>
							 <div className="valid-feedback">Looks good!</div>
						 </MDBInput>
							<MDBInput
								id="materialFormRegisterEmailEx3"
								className="signinb2b-input"
								placeholder={t('geben_sie_ihre_e_mail_adresse_ein')}
								label= "Email"
								type="email"
								required
								value={this.state.email}
								onChange={this.onEmailChange}
							>
							 <div className="invalid-feedback">
								 Please provide a valid email.
							 </div>
							 <div className="valid-feedback">Looks good!</div>
						 </MDBInput>
							<MDBInput
								id="materialFormRegisterPasswordEx4"
								className="signinb2b-input"
								label= {t('passwort')}
								type="password"
								required
								placeholder={t('geben_sie_ihr_passwort_ein')}
								value={this.state.password}
								onChange={this.onPasswordChange}
							>
							 <div className="invalid-feedback">
								 Please provide a valid password.
							 </div>
							 <div className="valid-feedback">Looks good!</div>
						 </MDBInput>
						 <div className="custom-control custom-checkbox">
						  <input type="checkbox" className="custom-control-input" id="invalidCheck" required />
						  <label className="custom-control-label price-page-haufig-text" htmlFor="invalidCheck">
						  {t('ich_stimme_zu')}<a className="link-text-markt" id="1" href="/UserAgreementB2B"
						 												target="_blank">{t('termsOfUse')}</a>{t('und_die')}
						 												<a className="link-text-markt" id="1" href="/PrivacyPolicyB2B" target="_blank">{t('privacyPolicy')}</a>
						 												{t('gelesen_und_bin_damit_einverstanden_2')}</label>
						 </div>
							<MDBBtn
									className="signup-btn"
									type="submit"
							>{t('registrieren')}</MDBBtn>

								{ this.state.redirect === true ?
									<Redirect to={{
										pathname: '/SignInB2B',
										search: '?query=abc',
										state: { username: this.state.username}
									}}/>:
								false
								}
						</form>
					<span className="already-have-an-acco text-uppercase py-2">{t('sie_haben_bereits_ein_konto')}</span><br/>
			  	<Link to="/SignInB2B">
            <span className="cant-sign-in pl-0 pt-2 pb-4">{t('anmelden')}</span>
          </Link>


        </MDBCol>
        <MDBCol sm="6" md="6" lg="8" className=".d-none .d-sm-block">
        </MDBCol>

        </MDBRow>
      </MDBContainer>
			<div className="rectangle-15">
				<MDBContainer style={{maxWidth: "1250px"}}>
					<FooterB2BSingUp/>
				</MDBContainer>
			</div>
	</div>
    );
  }
}

export default withTranslation()(SignUpHersteller);
