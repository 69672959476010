import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import logo from "../../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import addBike from "../../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import kommentare from "../../../assets/media/images/markt/Kommentare.png";
import favorite_green from "../../../assets/media/images/markt/bookmark.png";
import favorite from "../../../assets/media/images/markt/bookmark-leer.png";
import kommentar from "../../../assets/media/images/markt/Kommentar.png";
import anmelden from "../../../assets/media/images/markt/anmelden.png";
import searchnormalicon from "../../../assets/media/images/markt/searchnormalicon.png";
import logo2 from "../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../../assets/media/images/markt/Group 22.png";
import logo4 from "../../../assets/media/images/markt/Group 19.png";
import logo5 from "../../../assets/media/images/markt/Group 20.png";
import profile_Boda from "../../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../../assets/media/images/b2b/Group 8.png";
import group_21 from "../../../assets/media/images/markt/Group 21.png";
import rectangle_1 from "../../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../../assets/media/images/b2b/Interb2b.png";

import InterB2BAxios from '../../../Service/InterB2BAxios';
import CommentItemBikes from '../../../components/Companys/CommentItemBikes';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../components/LazyLoad/LazyLoadItem';
import SonstigeNavB2B from '../../../components/NavbarComponentB2B/SonstigeNavB2B';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from '../../../assets/scss/modules/boersepage.module.scss';
import Cookies from 'universal-cookie';
import { Scrollbar } from "react-scrollbars-custom";
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import GoogleMaps5 from '../../../components/GoogleMaps/GoogleMaps5';
import ProfilpageNavB2B from '../../../components/NavbarComponentB2B/ProfilpageNavB2B';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBRangeInput,
	MDBListGroup
} from 'mdbreact';
const cookies = new Cookies();
import Moment from 'moment';
let bikeAnzeigeId = '';
class BikePageB2BDesktop extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'BikePage');
    this.state = {//bikeProduct: {},
									//currentCompany: {},
									activeItem: 0,
									kommentar:undefined,
									myUserId: cookies.get('authtoken').userId,
									latitude: -200.0,
									longitude: -200.0,
									redirect: false
									};
  };


	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		//this.props.userData.refresh();
		this.props.addFunctionFromChild(this.onMessage);
		var params = window.location.href.split("/");
    bikeAnzeigeId = params[params.length - 1];
		this.onGetBikes(bikeAnzeigeId);
	}

	componentWillUnmount() {
		this.props.removeFunctionFromChild(this.onMessage);
		this.interAxios.cancel();
	}

	/*onAnzeigeCompany = (id) =>{
    var self = this;
		this.interAxios.get('/api/findCompanyById/' + id, function(response) {
			self.setState({anzeigeCompany: response.data});
		})
  };*/

	onMessage = (message) => {
    let type = message.type;
		if (type === 'NEWEBIKEPRODUCT'){
	    this.onGetBikes(bikeAnzeigeId);
	  }
  };

	onGetBikes= (id) => {
		let self = this;
		this.interAxios.get('/api/bikeProductById/' + id, function(response) {
			self.setState({bikeProduct: response.data});
			//this.onAnzeigeCompany(response.data.userFormProduct.id);
		})
	};


	onChatContact = () => {
		let self = this;
		this.interAxios.get('/api/chat/bike/room/getOrCreateWithUser/' + this.state.bikeProduct.userFormProduct.id + "/" + this.state.bikeProduct.id,
			function(response) {
				self.setState({chatroomid: response.data, redirect: true});
		});
	}

	toggle = tab => e => {

				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
				}
	}

	onKommentarChange = (editorState) => {
		this.setState({kommentar: editorState});
	}

	onNewBikeProductComment = (bikeproductId) => {
		let self = this;
		if(this.props.userData.getCurrentCompany()?.id !== undefined){
			const formData = new FormData
			formData.append("content", this.state.kommentar);
			formData.append("companyId", this.props.userData.getCurrentCompany()?.id);
	    this.interAxios.post('/api/comment/newBikeProductComment/' + bikeproductId, formData, function(response) {
	    })
		}else{
			const formData = new FormData
			formData.append("content", this.state.kommentar);
			formData.append("userId", this.state.myUserId);
	    this.interAxios.post('/api/comment/newBikeProductComment/' + bikeproductId, formData, function(response) {
	    })
		}
  };

	handleSaveKommentar = () => {
		this.onNewBikeProductComment(this.state.bikeProduct.id);
		this.setState({kommentar: ''});
	}

  render() {

		const { t } = this.props;
    const client = this.props.client;

		let liefer =  this.state.bikeProduct?.eintragstyp === "E-Bike zur Selbstabholung anbieten" ? "Im Laden" :
									this.state.bikeProduct?.eintragstyp === "E-Bike Online Verkaufen und Versenden" ? "Online": false;
		let prevDate = Moment(Date.now());
		let prevDate1 = Moment(Date.now()).subtract(1, 'days');
		let prevDate2 = Moment(Date.now()).subtract(2, 'days');
		let tsmoment = Moment(this.state.bikeProduct?.time);
    return (
			<>

			<SonstigeNavB2B  userData={this.props.userData}/>

				<div className={styles.bodywrapper}>
							{this.state.bikeProduct !== undefined ?
							<MDBContainer style={{maxWidth: "1250px"}}>
							<MDBRow className="pt-4 m-0">
								<MDBCol md="auto" className="pr-4">
									<ProfilpageNavB2B activeItem = {"2"} userData={this.props.userData}/>
								</MDBCol>
								<MDBCol className="px-5">
								<MDBCard className="bikeb2b-card">
									<MDBCardBody>
										<MDBRow className="m-0">
										 <MDBCol md="6" className="pl-0 pr-2">
										 	<MDBRow className="m-0 pb-4">
												<span className="bike-title-bikepage">{this.state.bikeProduct.title}</span>
											</MDBRow>
											<MDBRow className="m-0">
											{this.state.bikeProduct.ebikeProductPhotosUrl !== undefined ?
												<img alt="" className="bike-img-big" src={this.state.bikeProduct.ebikeProductPhotosUrl[this.state.activeItem]}/>:false}
											</MDBRow>
											<MDBRow className="m-0">
											{this.state.bikeProduct.ebikeProductPhotosUrl !== undefined ?
												<div className="ebikeProductPhotos-small">
												<MDBNav className="flex-row ebikeproduct-nav">
													{this.state.bikeProduct.ebikeProductPhotosUrl.map(	(o, index) =>
																<MDBNavItem>
																	<MDBNavLink link to="#" active={this.state.activeItem === index} onClick={this.toggle(index)} role="tab" >
																		<img key={index} alt="" className="bike-img-small" src={o}/>
																	</MDBNavLink>
																</MDBNavItem>
													)}
												</MDBNav></div>:false}
											</MDBRow>
											{this.state.bikeProduct.videoLink !== undefined && this.state.bikeProduct.videoLink !== "" ?
											<MDBRow className="m-0 py-4">
													<iframe src={this.state.bikeProduct.videoLink} width="555" height="312" allowFullScreen autoPlay></iframe>
											</MDBRow>:false}
											{this.state.bikeProduct.commentlist !== undefined ?
											<>
											<MDBRow className="m-0 pt-5 pb-4">
											 <MDBCol md="auto" middle className="px-0">
												<img alt="" className="kommentare-img" src={kommentare}/>
											 </MDBCol>
											 <MDBCol md="auto" middle className="pl-2">
												<span className="kommentare-title"> Öffentliche Diskussion ({this.state.bikeProduct.commentlist.length})</span>
											 </MDBCol>
											</MDBRow>
											<MDBRow className="m-0">
												{this.state.bikeProduct.commentlist.length>0 ?
												<MDBCol className="pl-0 pb-3">
													{this.state.bikeProduct.commentlist.map(
														(o) =>
														<MDBListGroup className="mitteilungen-listgroup">
															 <CommentItemBikes
																key={o.id} msg={o} userData={this.props.userData}/>
														</MDBListGroup>
													)}

												</MDBCol>:false}
											</MDBRow>
											<div className="line"/>
											<MDBRow className="m-0">
												<MDBInput className="textarea-bikepage" type="textarea" rows="1" getValue={this.onKommentarChange} value={this.state.kommentar}
												placeholder="Kommentiere den Eintrag und frage nach mehr Details. Alle anderen Mitglieder werden deine Kommentare sehen."/>
											</MDBRow>
											<MDBRow className="m-0">
												{this.state.kommentar !== undefined ?
												<MDBBtn className="comment-btn" onClick={this.handleSaveKommentar}>
													<MDBRow>
														<MDBCol md="auto" className="p-0" middle>
															<img alt="" className="comment-icon" src={kommentar}/>
														</MDBCol>
														<MDBCol className="p-0" middle>
															<span className="btn-text text-capitalize">Kommentar</span>
														</MDBCol>
													</MDBRow>
												</MDBBtn>:
												<MDBBtn className="comment-btn">
													<MDBRow className="m-0" center>
														<MDBCol md="2" className="p-0" middle>
															<img alt="" className="comment-icon" src={kommentar}/>
														</MDBCol>
														<MDBCol md="8" className="p-0" middle>
															<span className="btn-text text-capitalize">Kommentar</span>
														</MDBCol>
													</MDBRow>
												</MDBBtn>}
											</MDBRow>
											</>:false}
										 </MDBCol>
										 <MDBCol md="6" className="pr-0 pl-2">
											 <MDBRow className="m-0 pb-2">
											 	<MDBCol md="auto" className="p-0">
													<div className="rectangle-rahmengroese text-center">
														<span className="text">{this.state.bikeProduct.rahmengrose}</span>
													</div>
												</MDBCol>
												<MDBCol md="auto">
													<div className="rectangle-rahmengroese text-center">
														<span className="text">{liefer}</span>
													</div>
												</MDBCol>
												<MDBCol md="auto">
													<span className="kategorie-text">{this.state.bikeProduct.kategorie}</span>
												</MDBCol>
											 </MDBRow>
											 <MDBRow className="m-0 py-2">
												 <MDBCol md="auto" className="p-0">
												 	<span className="preis-text">Preis:</span>
												 </MDBCol>
												 <MDBCol >
												 	<span className="preis-text">{this.state.bikeProduct.preis} €</span>
												 </MDBCol>
											 </MDBRow>
											 <MDBRow className="m-0 py-2">
											 	<div className="sonstige-text" dangerouslySetInnerHTML={{__html: this.state.bikeProduct.article}}/>
											 </MDBRow>
											 <MDBRow className="m-0 pb-2">
												 <MDBCol md="auto" className="p-0">
													<span className="sonstige-text">Kilometerstand:</span>
												 </MDBCol>
												 <MDBCol >
													<span className="sonstige-text-bold">{this.state.bikeProduct.kilometerstand}</span>
												 </MDBCol>
											 </MDBRow>
											 <MDBRow className="m-0 pb-2">
												 <MDBCol md="auto" className="p-0">
													<span className="sonstige-text">Hersteller:</span>
												 </MDBCol>
												 <MDBCol >
													<span className="sonstige-text-bold">{this.state.bikeProduct.hersteller}</span>
												 </MDBCol>
											 </MDBRow>
											 <MDBRow className="m-0 pb-2">
												 <MDBCol md="auto" className="p-0">
													<span className="sonstige-text">Modell:</span>
												 </MDBCol>
												 <MDBCol >
													<span className="sonstige-text-bold">{this.state.bikeProduct.modell}</span>
												 </MDBCol>
											 </MDBRow>
											 <MDBRow className="m-0">
												 <MDBCol md="auto" className="p-0">
													<span className="sonstige-text">Baujahr:</span>
												 </MDBCol>
												 <MDBCol >
													<span className="sonstige-text-bold">{this.state.bikeProduct.baujahr}</span>
												 </MDBCol>
											 </MDBRow>
											 <MDBRow className="m-0 pt-2">
												 <MDBCol md="12" className="p-0">
													 	<div className="profile-mapholder">
													 		<GoogleMaps5  latitude={this.state.bikeProduct.lat !== -200.0 && this.state.bikeProduct.lat !== 0 ? this.state.bikeProduct.lat: this.props.userData.getLatLng().originalLatitude}
																						longitude={this.state.bikeProduct.lng !== -200.0 && this.state.bikeProduct.lng !== 0 ? this.state.bikeProduct.lng: this.props.userData.getLatLng().originalLongitude}
																						isBikeMap={true}
																						userData = {this.props.userData}
																						withMap = {"100%"}
				 																	 	heightMap = {"250px"}
																						/>
													 	</div>
												 </MDBCol>
											 </MDBRow>
										 </MDBCol>
										</MDBRow>
									</MDBCardBody>
								</MDBCard>
								</MDBCol>
								</MDBRow>
							</MDBContainer>:false}
				</div>
				{ this.state.redirect === true ?
					<Redirect to={"/ChatsBikeB2B/user/" + this.state.chatroomid}/>:false}
		</>
    );
  }
}

export default  withTranslation()(BikePageB2BDesktop);
