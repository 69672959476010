import React, { Component } from "react";
import ChatListMain from "./ChatList/ChatListMain";
import InterB2BAxios from '../../Service/InterB2BAxios';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import{
	MDBContainer,
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody
} from 'mdbreact';

import Cookies from 'universal-cookie';


const cookies = new Cookies();

class ChatsMain extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {rooms: [], messages: [], selectedChatroom: ''};
  };

  componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
    var params = window.location.href.split("/");
    let chatroomid = params[params.length - 1];
    this.props.addFunctionFromChild(this.onMessage);
    if (isFinite(chatroomid)){
      this.selectChatroom(undefined, chatroomid);
    }
    this.loadRooms();

  };
  componentWillUnmount() {
		this.interAxios.cancel();
    this.props.removeFunctionFromChild(this.onMessage);
  }

  handleChatClick = (chatroomid) => {
    this.selectChatroom(undefined, chatroomid);
  };

  loadRooms = () => {
    var self = this;
		this.interAxios.get('/api/chat/rooms', function(response)
    {
          self.setState({rooms: response.data});
    });
  };

  refreshAll = (chatroomid) => {
    this.loadRooms();
  };

  selectChatroom = (event, chatroomid) => {
    this.setState({selectedChatroom: chatroomid})
    this.refreshAll(chatroomid);
  };

  onMessage = (message) => {
    var params = window.location.href.split("/");
    let chatroomid = params[params.length - 1];
    let type = message.type;
    if (type === 'OTHER') {
      this.refreshAll();
    } else {
      let messageId = message.id;
      let messageChatRoomId = message.chatRoomId;
      if (this.state.selectedChatroom == messageChatRoomId) {
        var self = this;
				this.interAxios.get('/api/chat/messages/' + messageId, function(response)
		    {
					let message = response.data;
					let messages = self.state.messages;
					messages.push(message);
					self.setState({messages: messages});
		    });

      } else {
        this.loadRooms();
      }
    }
  };

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
	      <MDBCard style={{	borderTopLeftRadius: "2rem",
													borderTopRightRadius: "2rem"}}>
					<MDBCardBody className="p-0">
	          <ChatListMain
	              handleChatClick={this.handleChatClick}
	              rooms = {this.state.rooms}
								userData = {this.props.userData}
								addFunctionFromChild= {this.props.addFunctionFromChild}
								removeFunctionFromChild= {this.props.removeFunctionFromChild}
								/>
					</MDBCardBody>
	      </MDBCard>
    );
  }
}
export default withTranslation()(ChatsMain);
