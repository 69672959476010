import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_first from "../../assets/media/images/markt/profil/Group 7.png";
import logo from "../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import addBike from "../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import anmelden from "../../assets/media/images/markt/anmelden.png";
import searchnormalicon from "../../assets/media/images/markt/searchnormalicon.png";
import logo2 from "../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../assets/media/images/markt/Group 22.png";
import logo4 from "../../assets/media/images/markt/Group 19.png";
import logo5 from "../../assets/media/images/markt/Group 20.png";
import profile_Boda from "../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../assets/media/images/b2b/Group 8.png";
import group_21 from "../../assets/media/images/markt/Group 21.png";
import rectangle_1 from "../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../assets/media/images/b2b/Interb2b.png";

import InterB2BAxios from '../../Service/InterB2BAxios';
import FooterMarktLanding from '../../components/Footer/FooterMarktLanding';
import GyikModul from "../../components/Gyik/GyikModul";
import Interb2bNews from '../../components/News/Interb2bNews';
import MitteilungenItem from '../../components/Companys/MitteilungenItem';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../components/LazyLoad/LazyLoadItem';
import InserierenNav from '../../components/NavbarComponent/InserierenNav';
import BoersePageNavRespo from '../../components/NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from './BoersePage/MenuSideNavBoerse';
import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';
import { Scrollbar } from "react-scrollbars-custom";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBSideNav
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
let urlName = '';
class AddBikeB2B extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefInterB2B = React.createRef();
		this.listInnerRefNews = React.createRef();
		this.listInnerRefInfo = React.createRef();
		this.listInnerRefContact = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'AddBike');
    this.state = {
								  activeItem: "1",
									activeVideoItem: "1",
									navbarPaddingTop: true,
									navbarPaddingMiddle: false,
									navbarPadding: false,
									loggedIn: false,
									gyikList: [],
									collapseID: -1,
									mitteilungenlist: [],
									kategorie: "",
									eintargstyp: false,
									categories: [],
									};
  };
	componentDidMount() {
		this.getAllEBikeCategories();
		this.onRefInterB2B();
		document.addEventListener("scroll", this.scrollEventListener);
		var params = window.location.href.split(".");
	    language = params[params.length - 1];
		var params2 = window.location.href.split("/");
		urlName = params2[params2.length - 1];
	}

	componentWillUnmount() {
		this.interAxios.cancel();
		document.removeEventListener("scroll", this.scrollEventListener);
	}

	getAllEBikeCategories = () => {
		let self = this;
		this.interAxios.get('/api/eBikeKategorie', function(response) {
			//console.log(response.data);
			self.setState({categories: response.data});
		})
	}

	onRefInterB2B = () => {
		this.listInnerRefInterB2B.current.scrollIntoView({
	 });

	}

	onEintragsTyp = (kategorie) => {
		this.setState({kategorie: kategorie, eintargstyp: true});
	}

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
	let categoryBoxes = this.state.categories.map((name,index) => 
		<MDBRow key={index} center className="pt-0 m-0">
			<MDBBtn
				type="button"
				flat
				className="filter-btn-addKategorie"
				onClick={() => this.onEintragsTyp(name)}
			>
				<span className="text-capitalize">{t(name)}</span>
			</MDBBtn>
		</MDBRow>	
	);
    return (
			<>
			<div ref={this.listInnerRefInterB2B}></div>
			<div className="d-none d-lg-block">
				<InserierenNav  userData={this.props.userData}/>
			</div>
			<div className="d-lg-none d-block">
				<BoersePageNavRespo  userData={this.props.userData} onMenuOpen = {this.props.handleSideNavToggle}/>
			</div>
				<div className={styles.bodywrapper}>
				<div className="d-lg-none d-block">
					<div className="side-nav-menu">
						<MDBSideNav
							right
							triggerOpening={this.props.isSideNavOpen}
							onOverlayClick={this.props.handleSideNavToggle}
							breakWidth={1300}
							className="deep-purple darken-4"

						>
							<MenuSideNavBoerse
													userData={this.props.userData}
													toggle = {this.toggle}
													onMenuClose = {this.props.onEnableScroll}
													activeItem = {this.state.activeItem}
							/>
						</MDBSideNav>
					</div>
				</div>
						<div className="landingmarkt-top-addbike">
							<MDBRow center className="m-0 addbike-row-bottom">
							<MDBCol>
							<MDBRow center className="m-0 pt-5">
									<img alt="" className="e-bikeborse-logo-first" src={logo_first}></img>
									<span className="landingpage-text">{t('kategorie')}</span>
							</MDBRow>
							{/*<MDBRow center className="pt-4 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie"
									onClick={() => this.onEintragsTyp("E-Bike Pedelec")}
								>

									<span className="text-capitalize">E-Bike Pedelec</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie"
									onClick={() => this.onEintragsTyp("E-Bike 45 km/h")}
								>
									<span className="text-capitalize">E-Bike 45 km/h</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie"
									onClick={() => this.onEintragsTyp("E-Bike City")}
								>
									<span className="text-capitalize">E-Bike City</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie"
									onClick={() => this.onEintragsTyp("E-Bike Cross")}
								>
									<span className="text-capitalize">E-Bike Cross</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie"
									onClick={() => this.onEintragsTyp("E-Bike Klapprad_Faltrad")}
								>
									<span className="text-capitalize">{"E-Bike " + t('Klapprad_Faltrad')}</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie"
									onClick={() => this.onEintragsTyp("E-Bike Kinder_Jugendliche")}
								>
									<span className="text-capitalize">{"E-Bike " + t('Kinder_Jugendliche')}</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie"
									onClick={() => this.onEintragsTyp("E-Bike MTB")}
								>
									<span className="text-capitalize">E-Bike MTB</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie"
									onClick={() => this.onEintragsTyp("E-Bike Rennrader")}
								>
									<span className="text-capitalize">{"E-Bike " + t('Rennrader')}</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie"
									onClick={() => this.onEintragsTyp("E-Bike Trekking")}
								>
									<span className="text-capitalize">E-Bike Trekking</span>
								</MDBBtn>
							</MDBRow>
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie"
									onClick={() => this.onEintragsTyp("E-Bike Lastenrad")}
								>
									<span className="text-capitalize">{"E-Bike " + t('Lastenrad')}</span>
								</MDBBtn>
							</MDBRow>							
							<MDBRow center className="p-0 m-0">
								<MDBBtn
									type="button"
									flat
									className="filter-btn-addKategorie"
									onClick={() => this.onEintragsTyp("E-Scooter")}
								>
									<span className="text-capitalize">E-Scooter</span>
								</MDBBtn>
							</MDBRow>*/}
							<MDBRow className="pt-4"></MDBRow>
							{categoryBoxes}
							</MDBCol>
							</MDBRow>

						</div>

				</div>
				<div className="rectangle-footer-addBike d-none d-lg-block">
					<MDBContainer style={{maxWidth: "1250px"}}>
						<FooterMarktLanding/>
					</MDBContainer>
				</div>
				{ this.state.eintargstyp && this.state.kategorie !== "" ?
						<Redirect to={{
											pathname: "/EintragsTyp",
											state: { kategorie: this.state.kategorie }
						}}
					/>:
 				false}
				{ this.props.userData.boersepage ?
					 <Redirect to={{
										 pathname: "/BoersePage",
										 state: { activeItem: this.state.activeItemKategorie, bikeKategorie: this.state.bikeKategorie }
					 }}
				 />:
				 false}

		</>
    );
  }
}

export default  withTranslation()(AddBikeB2B);
