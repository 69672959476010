import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import standorticon from '../../../assets/media/images/markt/map.png';
import arrowDown from '../../../assets/media/images/markt/arrowDown.png';
import arrowUp from '../../../assets/media/images/markt/arrowUp.png';
import arrowUp2 from "../../../assets/media/images/markt/arrowUp2.png";
import sortUp from '../../../assets/media/images/markt/sortUp.png';
import sortDown from '../../../assets/media/images/markt/sortDown.png';
import hamburgericon from "../../../assets/media/images/markt/hamburgericon.png";
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../components/LazyLoad/LazyLoadItem';
import InterB2BAxios from '../../../Service/InterB2BAxios';
import Footer from '../../../components/Footer/Footer2';
import BikeAnzeigeKarte from '../../../components/Companys/BikeAnzeigeKarte';
import BoersePageNavRespo from '../../../components/NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from './MenuSideNavBoerse';
import FilterByStandort from '../../../components/Modals/FilterByStandort';
import SortierungFilter from '../../../components/Modals/SortierungFilter';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import GoogleMaps5 from '../../../components/GoogleMaps/GoogleMaps5';
import styles from '../../../assets/scss/modules/boersepage.module.scss';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBCollapse,
	MDBSideNav
} from 'mdbreact';

let language = '';
let urlName = '';
class BoersePageResponziv extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefStart = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'BoersePage');
		const { t } = this.props;
		const client = this.props.client;
    this.state = {bikeslist: {},
									activeItem: this.props.location?.state?.activeItem !== undefined ? this.props.location.state.activeItem : "1",
									bikeKategorie: this.props.location?.state?.bikeKategorie !== undefined ? this.props.location.state.bikeKategorie : "",
									activeItemS: false,
									activeItemM: false,
									activeItemL: false,
									activeItemXL: false,
									activeItemXXL: false,
									activeItemOnline: false,
									activeItemSelbst: false,
									activeItemNeu: false,
									activeItemGebraucht: false,
									value: [0,20000],
									valueUmkreis: 20000,
									showStandortFilter: false,
									//aktuelleStandort: "Aktuelle Standort",
									latitude: "",
									longitude: "",
									adresse: "",
									collapseID: "",
									anzahlCompany: 0,
									anzahlUser: 0,
									sortedTyp: 1,
									showSoriter: false,
									filterText: t('Sortierung_nach_Datum'),
									navbarPaddingTop: true,
									categories: [],
									};
  };


	componentDidMount() {
		this.getAllEBikeCategories()
			this.onRefStart();
			this.props.addFunctionFromChild(this.onMessage);
			var params = window.location.href.split(".");
	    language = params[params.length - 1];
			var params2 = window.location.href.split("/");
	    urlName = params2[params2.length - 1];
			document.addEventListener("scroll", this.scrollEventListener);
			let latitude;
			let longitude;
			if(this.props.userData.isLoggedInMarkt()){
			if(this.props.userData.getCurrentCompany()?.profileUrl !== undefined){
				latitude = this.props.userData.getCurrentCompany().lat;
				longitude = this.props.userData.getCurrentCompany().lng;
				this.setState({latitude: latitude,
											 longitude: longitude,
											 adresse: this.props.userData.getCurrentCompany().street + ", " +
											 					this.props.userData.getCurrentCompany().postcode + ", " +
																this.props.userData.getCurrentCompany().town + ", " +
																this.props.userData.getCurrentCompany().country,
											 aktuelleStandort: this.props.userData.getCurrentCompany().street + ", " +
											 					this.props.userData.getCurrentCompany().postcode + ", " +
																this.props.userData.getCurrentCompany().town + ", " +
																this.props.userData.getCurrentCompany().country,
										 });
			}else{
				latitude = this.props.userData.getMyUser()?.lat ?? this.props.userData.getLatLng().originalLatitude;
				longitude = this.props.userData.getMyUser()?.lng ?? this.props.userData.getLatLng().originalLongitude;
				this.setState({latitude: latitude,
											 longitude: longitude,
											 adresse: this.props.userData.getMyUser()?.standort ?? "N/A",
											 aktuelleStandort: this.props.userData.getMyUser()?.standort ?? "N/A"
										 });
			}}else{
					latitude = this.props.userData.getLatLng().originalLatitude;
					longitude = this.props.userData.getLatLng().originalLongitude;
					this.setState({latitude: latitude,
												 longitude: longitude
											 });
			}
			this.onGetBikes(latitude, longitude, 1);

	}

	getAllEBikeCategories = () => {
		let self = this;
		this.interAxios.get('/api/eBikeKategorie', function(response) {
			console.log(response.data);
			self.setState({categories: response.data});
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.bikeKategorie !== prevState.bikeKategorie ||
				this.state.valueUmkreis !== prevState.valueUmkreis ||
				this.state.textBike !== prevState.textBike ||
				this.state.value[0] !== prevState.value[0] ||
				this.state.value[1] !== prevState.value[1] ||
				this.state.activeItemS !== prevState.activeItemS ||
				this.state.activeItemM !== prevState.activeItemM ||
				this.state.activeItemL !== prevState.activeItemL ||
				this.state.activeItemXL !== prevState.activeItemXL ||
				this.state.activeItemXXL !== prevState.activeItemXXL ||
				this.state.activeItemOnline !== prevState.activeItemOnline ||
				this.state.activeItemSelbst !== prevState.activeItemSelbst ||
				this.state.activeItemNeu !== prevState.activeItemNeu ||
				this.state.activeItemGebraucht !== prevState.activeItemGebraucht ||
					this.state.sortedTyp !== prevState.sortedTyp){
						this.onGetBikes(this.state.latitude, this.state.longitude, this.state.sortedTyp);
					}
	}

	componentWillUnmount() {
		this.props.removeFunctionFromChild(this.onMessage);
		document.documentElement.style.setProperty('--scroll-y', `0px`);
		document.removeEventListener("scroll", this.scrollEventListener);
		this.interAxios.cancel();
	}

	scrollEventListener = (event) => {
		const navPadding = window.scrollY < 50 ? true : false;
		this.setState({ navbarPaddingTop: navPadding});
	}

	onRefStart = () => {
		this.listInnerRefStart.current.scrollIntoView({
	 });
	}

	/*onMe = () => {
    var self = this;
    this.interAxios.get('/api/user/me', function(response) {
        self.setState({userMe: response.data});
      })
  };*/

	onMessage = (message) => {
    let type = message.type;
		if (type === 'NEWEBIKEPRODUCT'){
	    this.onGetBikes(this.state.latitude, this.state.longitude);
	  }
  };

  	toggleNew = (tab, kategorie) => {
		console.log("set: " + tab + ", " + kategorie);
		this.setState({activeItem: tab, bikeKategorie: kategorie});
	}

	/*toggle = tab => e => {
		const { t } = this.props;
    const client = this.props.client;
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
						if (tab == 1) {
							this.setState({
								bikeKategorie: ""});
						}
						if (tab == 2) {
							this.setState({
								bikeKategorie: "E-Bike Pedelec"});
						}
						if (tab == 3) {
							this.setState({
								bikeKategorie: "E-Bike 45 Km/h"});
						}
						if (tab == 4) {
							this.setState({
								bikeKategorie: "E-Bike City"});
						}
						if (tab == 5) {
							this.setState({
								bikeKategorie: "E-Bike Cross"});
						}
						if (tab == 6) {
							this.setState({
								bikeKategorie: "E-Bike " + t('Klapprad_Faltrad')});
						}
						if (tab == 7) {
							this.setState({
								bikeKategorie: "E-Bike " + t('Kinder_Jugendliche')});
						}
						if (tab == 8) {
							this.setState({
								bikeKategorie: "E-Bike MTB"});
						}
						if (tab == 9) {
							this.setState({
								bikeKategorie: "E-Bike " + t('Rennrader')});
						}
						if (tab == 10) {
							this.setState({
								bikeKategorie: "E-Bike Trekking"});
						}
						if (tab == 11) {
							this.setState({
								bikeKategorie: "E-Scooter"});
						}

				}
	}*/

	toggle2 = tab => e => {
						if (tab == 1) {
							this.setState({
								activeItemS: !this.state.activeItemS});
								//this.onGetBikes();
						}
						if (tab == 2) {
							this.setState({
								activeItemM: !this.state.activeItemM});
						}
						if (tab == 3) {
							this.setState({
								activeItemL: !this.state.activeItemL});
						}
						if (tab == 4) {
							this.setState({
								activeItemXL: !this.state.activeItemXL});
						}
						if (tab == 5) {
							this.setState({
								activeItemXXL: !this.state.activeItemXXL});
						}
	}

	toggle3 = tab => e => {
						if (tab == 1) {
							this.setState({
								activeItemOnline: !this.state.activeItemOnline});
						}
						if (tab == 2) {
							this.setState({
								activeItemSelbst: !this.state.activeItemSelbst});
						}
	}

	toggle4 = tab => e => {
						if (tab == 1) {
							this.setState({
								activeItemNeu: !this.state.activeItemNeu});
						}
						if (tab == 2) {
							this.setState({
								activeItemGebraucht: !this.state.activeItemGebraucht});
						}
	}

	toggle5 = tab => e => {
				this.onMenuClose();
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
					if (tab == 4) {

					}
					if (tab == 3) {

					}
					if (tab == 2) {

					}
					if (tab == 1) {

					}
					if (tab == 9) {

					}
				}

	}


	handleChange = (event, newValue) => {
    					this.setState({value: newValue});
   };
	 handleChangeUmkreis = (event, newValue) => {
							 this.setState({valueUmkreis: newValue});
		};

		onGetBikes = (lat, lng, sortedTyp) => {
			this.setState({anzahlCompany: 0, anzahlUser: 0});
			let self = this;
			this.interAxios.get('/api/allBikeProduct', {params: {sortedTyp: this.state.sortedTyp,
																													 anzahlCompany: 0,
																													 anzahlUser: 0,
																													 lat: lat,
																													 lng: lng,
																													 textBike: this.props.userData.textBike,
																													 bikeKategorie: this.state.bikeKategorie,
																													 lowerLimit: this.state.value[0],
																													 upperLimit: this.state.value[1],
																													 valueUmkreis: this.state.valueUmkreis,
																													 activeItemS: this.state.activeItemS,
																													 activeItemM: this.state.activeItemM,
																													 activeItemL: this.state.activeItemL,
																													 activeItemXL: this.state.activeItemXL,
																													 activeItemXXL: this.state.activeItemXXL,
																													 activeItemOnline: this.state.activeItemOnline,
																													 activeItemSelbst: this.state.activeItemSelbst,
																													 activeItemNeu: this.state.activeItemNeu,
																													 activeItemGebraucht: this.state.activeItemGebraucht
																								 }}, function(response) {
				 self.setState({	bikeslist: response.data.bikeList,
		 											anzahlCompany: response.data.anzahlCompany + self.state.anzahlCompany,
		 											anzahlUser: response.data.anzahlUser + self.state.anzahlUser});
			})
		};

		onMoreGetBikes = (lat, lng, sortedTyp) => {
			let self = this;
			this.interAxios.get('/api/allMoreBikeProduct', {params: {sortedTyp: this.state.sortedTyp,
																															 anzahlCompany: this.state.anzahlCompany,
																															 anzahlUser: this.state.anzahlUser,
																															 lat: lat,
																															 lng: lng,
																															 textBike: this.props.userData.textBike,
																															 bikeKategorie: this.state.bikeKategorie,
																															 lowerLimit: this.state.value[0],
																															 upperLimit: this.state.value[1],
																															 valueUmkreis: this.state.valueUmkreis,
																															 activeItemS: this.state.activeItemS,
																															 activeItemM: this.state.activeItemM,
																															 activeItemL: this.state.activeItemL,
																															 activeItemXL: this.state.activeItemXL,
																															 activeItemXXL: this.state.activeItemXXL,
																															 activeItemOnline: this.state.activeItemOnline,
																															 activeItemSelbst: this.state.activeItemSelbst,
																															 activeItemNeu: this.state.activeItemNeu,
																															 activeItemGebraucht: this.state.activeItemGebraucht,
																															 before: this.state.bikeslist[this.state.bikeslist.length-1].time
																								 }}, function(response) {
				 let bikeslist = self.state.bikeslist;
	 			bikeslist.push(...response.data.bikeList);
	 			self.setState({	bikeslist: bikeslist,
												anzahlCompany: response.data.anzahlCompany + self.state.anzahlCompany,
												anzahlUser: response.data.anzahlUser + self.state.anzahlUser});
			})
		};

	handleShowStandortFilter = () => {
    this.setState({showStandortFilter: true});
  };

	handleCloseStandortFilter = () => {
    this.setState({showStandortFilter: false});
		this.props.userData.refresh();
  };

	onGetStandort = (adresse) => {
		this.setState({aktuelleStandort: adresse});
	}

	onGetUmkreis = (newValue) => {
		this.setState({valueUmkreis: newValue});
	}

	onGetLatLng = (lat, lng) => {
		this.setState({latitude: lat, longitude: lng});
	}

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}

	toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

	handleShowSortier = () => {
    this.setState({showSoriter: true, });
  }

	handleCloseSortier = () => {
    this.setState({showSoriter: false, });
  }

	onGetsortedTyp = (nr) => {
		const { t } = this.props;
    const client = this.props.client;
		this.setState({sortedTyp: nr,  filterUp: nr === 1 ? false :
																						 nr === 2 ? false :
																						 nr === 3 ? true : false,
																	 filterText: nr === 1 ? t('Sortierung_nach_Datum') :
 																						 nr === 2 ? t('Preis_aufsteigend') :
 																						 nr === 3 ? t('Preis_absteigend') : t('Sortierung_nach_Datum'),});
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		const marks = [
		  {
		    value: 0,
		    label: this.state.value[0],
		  },
		  {
		    value: 20000,
		    label: this.state.value[1],
		  },
		];
		let bikeList = <></>;
		if(this.state.bikeslist.length > 0){
			bikeList = 	this.state.bikeslist.map(
				(o, index) =>
				<MDBCol size="6" md="3" sm="4" xs="4" className="mb-3 px-2 pt-0" key={index} >
						<LazyLoad height={200} width={"100%"} offset={[-100, 0]}>
							<LazyLoadItem index={index} size={this.state.bikeslist.length} loadFunction={() => this.onMoreGetBikes(this.state.latitude, this.state.longitude)}>
								<BikeAnzeigeKarte
								 key={index}
								 userData={this.props.userData}
								 msg={o}/>
						 </LazyLoadItem>
				 	</LazyLoad>
				</MDBCol>
			);

		}

		let categoryTabs = this.state.categories.map((name, index) => {
			let localizedName = t(name);
			if (localizedName.slice(0, 6) === "E-Bike") {
				localizedName = localizedName.slice(7);
			}
			let activeTab = index + 2 + "";
			return <MDBNavItem>
					<MDBNavLink link to="#" active={this.state.activeItem === activeTab} onClick={(event) => this.toggleNew(activeTab, name)} role="tab" >
						<MDBRow>
							<span className="profilpage-menu">{localizedName}</span>
						</MDBRow>
					</MDBNavLink>
				</MDBNavItem>
			}
		);

    return (
			<>
			<div ref={this.listInnerRefStart}></div>

			<BoersePageNavRespo  userData={this.props.userData} onMenuOpen = {this.props.handleSideNavToggle}/>

				<div className={styles.bodywrapper}>
				<div className="side-nav-menu">
				<MDBSideNav
					right
					triggerOpening={this.props.isSideNavOpen}
					onOverlayClick={this.props.handleSideNavToggle}
					breakWidth={1300}
					className="deep-purple darken-4"

				>
					<MenuSideNavBoerse
											userData={this.props.userData}
											toggle = {this.toggle}
											onMenuClose = {this.props.onEnableScroll}
											activeItem = {this.state.activeItem}
					/>
				</MDBSideNav>
				</div>
					<MDBContainer fluid className="mx-0 px-0">
					<div className={this.state.navbarPaddingTop ? "marktnews-nav-container fixed-top-marktnews" : "marktnews-nav-container-scroll fixed-top-marktnews"}>
						<MDBRow center className=" m-0 pb-2">
							{!this.state.navbarPaddingTop ?
								<MDBBtn flat className="arrow-up-btn" onClick={this.onRefStart}>
									<img alt="" className="arrow-up" src={arrowUp2}></img>
								</MDBBtn>:false}
						</MDBRow>
					</div>
						<MDBCard className="filter-card-anzeige">
							<MDBCardBody className="py-2">
								<div className="anzeigepage-filter">
									<MDBRow start className="rahmengroese-filter-row">
										<div className="lieferung-filter">
											<MDBNav className="flex-row lieferung-nav">
												<MDBNavItem>
													<MDBNavLink link to="#" active={this.state.activeItemNeu} onClick={this.toggle4("1")} role="tab" >
														<span className="profilpage-menu">{t('neu')}</span>
													</MDBNavLink>
												</MDBNavItem>
												<MDBNavItem>
													<MDBNavLink link to="#" active={this.state.activeItemGebraucht} onClick={this.toggle4("2")} role="tab" >
														<span className="profilpage-menu">{t('gebraucht')}</span>
													</MDBNavLink>
												</MDBNavItem>
											</MDBNav>
										</div>
									</MDBRow>
									<MDBRow center className="m-0">
										<MDBBtn
								      flat
											className="collapse-button-respo"
								      onClick={this.toggleCollapse("basicCollapse")}
								    >
								      <span className="collapse-btn-text text-capitalize">{!this.state.collapseID ? "More filter" :"Less filter"}</span>
											<img className="collapse-btn-icon" src={!this.state.collapseID ? arrowDown : arrowUp}/>
								    </MDBBtn>
									</MDBRow>
								</div>
								<MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
								<div className="anzeigepage-filter">
									<MDBNav className="flex-column landingmarkt-nav">
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={(event) => this.toggleNew("1", "")} role="tab" >
												<MDBRow>
														<span className="profilpage-menu">{t('Alle_Kategorien')}</span>
												</MDBRow>
											</MDBNavLink>
										</MDBNavItem>
										{categoryTabs}
										{/*<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
												<MDBRow>
														<span className="profilpage-menu">Pedelec</span>
												</MDBRow>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" >
											<MDBRow>
													<span className="profilpage-menu">45 km/h</span>
											</MDBRow>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "4"} onClick={this.toggle("4")} role="tab" >
											<MDBRow>
													<span className="profilpage-menu">City</span>
											</MDBRow>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "5"} onClick={this.toggle("5")} role="tab" >
												<MDBRow>
														<span className="profilpage-menu">Cross</span>
												</MDBRow>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "6"} onClick={this.toggle("6")} role="tab" >
												<MDBRow>
														<span className="profilpage-menu">{t('Klapprad_Faltrad')}</span>
												</MDBRow>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "7"} onClick={this.toggle("7")} role="tab" >
												<MDBRow>
														<span className="profilpage-menu">{t('Kinder_Jugendliche')}</span>
												</MDBRow>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "8"} onClick={this.toggle("8")} role="tab" >
												<MDBRow>
														<span className="profilpage-menu">MTB</span>
												</MDBRow>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "9"} onClick={this.toggle("9")} role="tab" >
												<MDBRow>
														<span className="profilpage-menu">{t('Rennrader')}</span>
												</MDBRow>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "10"} onClick={this.toggle("10")} role="tab" >
												<MDBRow>
														<span className="profilpage-menu">Trekking</span>
												</MDBRow>
											</MDBNavLink>
										</MDBNavItem>
										<MDBNavItem>
											<MDBNavLink link to="#" active={this.state.activeItem === "11"} onClick={this.toggle("11")} role="tab" >
												<MDBRow>
														<span className="profilpage-menu">E-Scooter</span>
												</MDBRow>
											</MDBNavLink>
									</MDBNavItem>*/}
									</MDBNav>
									<MDBRow start className="m-0" style={{paddingLeft: "1.9rem"}}>
										<Box sx={{ width: 180 }} className="slider-filter">
								      <Typography id="track-false-slider" gutterBottom>
								        {t('preis')}
								      </Typography>
											<Slider
								        getAriaLabel={() => 'Price range'}
								        value={this.state.value}
								        onChange={this.handleChange}
												defaultValue={[0, 20000]}
												min={0}
											  step={100}
											  max={20000}
								        marks={marks}
								      />
											<span className="min-euro">min €</span>
											<span className="max-euro">max €</span>
										</Box>
									</MDBRow>
									<MDBRow>
										<MDBCol>
											<span className="standort-text">{t('Standort')}</span>
										</MDBCol>
										<MDBCol className="text-right pr-4">
											<MDBBtn flat className="m-0 p-0" onClick={!this.state.showStandortFilter ? this.handleShowStandortFilter : this.handleCloseStandortFilter}>
												<img className="standorticon" src={standorticon}/>
											</MDBBtn>
										</MDBCol>
									</MDBRow>
									<MDBRow start className="rahmen-row">
											<span className="adresse-text-umkreis">{this.state.aktuelleStandort !== "N/A" ? this.state.aktuelleStandort : t('Aktuelle_Standort')}</span><br/>
											<span className="umkreis-text">{t('Umkreis')} {this.state.valueUmkreis === 20000 ? "Unlimited" : this.state.valueUmkreis + "km"}</span>
									</MDBRow>
									<MDBRow start className="rahmen-row">
										<span className="rahmengrosse">{t('Rahmengrosse')}</span>
									</MDBRow>
									<MDBRow center className="rahmengroese-filter-row">
									<div className="rahmengroese-filter">
										<MDBNav className="flex-row rahmengroese-nav">
											<MDBNavItem>
												<MDBNavLink link to="#" active={this.state.activeItemS} onClick={this.toggle2("1")} role="tab" >
													<span className="profilpage-menu">S</span>
												</MDBNavLink>
											</MDBNavItem>
											<MDBNavItem>
												<MDBNavLink link to="#" active={this.state.activeItemM} onClick={this.toggle2("2")} role="tab" >
													<span className="profilpage-menu">M</span>
												</MDBNavLink>
											</MDBNavItem>
											<MDBNavItem>
												<MDBNavLink link to="#" active={this.state.activeItemL} onClick={this.toggle2("3")} role="tab" >
													<span className="profilpage-menu">L</span>
												</MDBNavLink>
											</MDBNavItem>
											<MDBNavItem>
												<MDBNavLink link to="#" active={this.state.activeItemXL} onClick={this.toggle2("4")} role="tab" >
													<span className="profilpage-menu">XL</span>
												</MDBNavLink>
											</MDBNavItem>
											<MDBNavItem>
												<MDBNavLink link to="#" active={this.state.activeItemXXL} onClick={this.toggle2("5")} role="tab" >
													<span className="profilpage-menu">XXL</span>
												</MDBNavLink>
											</MDBNavItem>
										</MDBNav>
									</div>
									</MDBRow>
									<MDBRow start className="rahmen-row">
										<span className="rahmengrosse">{t('Lieferung')}</span>
									</MDBRow>
									<MDBRow center className="rahmengroese-filter-row">
									<div className="lieferung-filter">
										<MDBNav className="flex-row lieferung-nav">
											<MDBNavItem>
												<MDBNavLink link to="#" active={this.state.activeItemOnline} onClick={this.toggle3("1")} role="tab" >
													<span className="profilpage-menu">Online</span>
												</MDBNavLink>
											</MDBNavItem>
											<MDBNavItem>
												<MDBNavLink link to="#" active={this.state.activeItemSelbst} onClick={this.toggle3("2")} role="tab" >
													<span className="profilpage-menu">{t('Im_Laden')}</span>
												</MDBNavLink>
											</MDBNavItem>
										</MDBNav>
									</div>
									</MDBRow>
								</div>
								</MDBCollapse>
							</MDBCardBody>
						</MDBCard>
						<MDBRow className="m-0 pt-1 pb-3">
							<MDBCol middle size="10" className="text-right pr-0">
								<span className="sortierung-text">{this.state.filterText}</span>
							</MDBCol>
							<MDBCol middle size="2"  className="text-right  pl-0 pr-3 fold-padding-2">
								<MDBBtn flat className="sortierungbutton" onClick={this.handleShowSortier}>
									<img src={!this.state.filterUp ? sortUp : sortDown}/>
								</MDBBtn>
							</MDBCol>
						</MDBRow>
						<MDBRow className="m-0 fold-padding">
							{this.state.bikeslist.length > 0 ? <>
									{bikeList}</>:false}
						</MDBRow>
					</MDBContainer>
				</div>
				{ this.state.eintargstyp && this.state.kategorie !== "" ?
						<Redirect to={{
											pathname: "/EintragsTyp",
											state: { kategorie: this.state.kategorie }
						}}
					/>:
 				false}
				{this.state.showStandortFilter ?
				<FilterByStandort
				show= {this.state.showStandortFilter}
				handleClose = {this.handleCloseStandortFilter}
				valueUmkreis = {this.state.valueUmkreis}
				handleChangeUmkreis = {this.handleChangeUmkreis}
				onGetStandort = {this.onGetStandort}
				userData = {this.props.userData}
				latitude = {this.state.latitude}
				longitude = {this.state.longitude}
				adresse = {this.state.adresse}
				aktuelleStandort = {this.state.aktuelleStandort}
				onGetBikes = {this.onGetBikes}
				onGetUmkreis = {this.onGetUmkreis}
				bikeAnzahl = {bikeList.length}
				originalLatitude = {this.state.originalLatitude}
				originalLongitude = {this.state.originalLongitude}
				onGetLatLng = {this.onGetLatLng}
				/>:false}
				{this.state.showSoriter ?
				<SortierungFilter
				show= {this.state.showSoriter}
				handleClose = {this.handleCloseSortier}
				onGetsortedTyp = {this.onGetsortedTyp}
				radio= {this.state.sortedTyp}
				/>:false}
		</>
    );
  }
}

export default  withTranslation()(BoersePageResponziv);
