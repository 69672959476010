import React, { Component } from "react";

import styles from '../../../assets/scss/modules/profilpage.module.scss';
import SonstigeNavB2BHersteller from '../../../components/NavbarComponentB2B/SonstigeNavB2BHersteller';
import ProfilpageNavB2BHersteller from '../../../components/NavbarComponentB2B/ProfilpageNavB2BHersteller';
import Cookies from 'universal-cookie';
import { Redirect, Link } from 'react-router-dom';
import GenericModal from '../../../components/Jitsi/GenericModal.js';
import moment from 'moment';
import 'moment/locale/de';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
const myregexp = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.*([^\s]{11})/gm; new Cookies();
import user_profile_icon from "../../../assets/media/newicons/user_profile_icon.png";
import loginicon from "../../../assets/media/newicons/login.png";
import group_5 from "../../../assets/media/images/b2b/Group 17.png";
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar
} from 'mdbreact';
let language = '';
import InterB2BAxios from '../../../Service/InterB2BAxios';

class KontoB2BHersteller extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
		this.state = {falseLogin: '',
									updateControl: false,
									redirect: false
									};
  };

	componentDidMount() {
		var params = window.location.href.split(".");
		language = params[params.length - 1];
		this.onMe();
	};

	componentWillUnmount() {
		this.interAxios.cancel();
  }

	onMe = () => {
		var self = this;
		this.interAxios.get('/api/user/me', function(response) {
				self.setState({name: response.data.name, email: response.data.email});
			})
	};

	onLoginFormSubmit  = () => {
		var self = this;
		this.interAxios.post('/api/auth/signin', {username: this.state.username, password: this.state.password}, function(response) {
				cookies.set('authtoken', {
						token: response.data.accessToken,
						userId: response.data.id,
						name: response.data.name,
						currentCompanyId: response.data.companyId,
						sysAdmin: response.data.sysAdmin,
						gastAdmin: response.data.gastAdmin,
					}/*, {
						 sameSite: 'None',
						 secure: true
					}*/
				);
			}, function(error) {
			});
	};

	onControlUser  = () => {
		event.preventDefault();
		var self = this;
		const { t } = this.props;
		const client = this.props.client;
		this.interAxios.post('/api/auth/validUser', {username: this.state.username, password: this.state.password}, function(response) {

					self.setState({falseLogin: ''});
					self.setState({updateControl: response.data});
			}, function(error) {
				self.setState({falseLogin: t('falscher_benutzername_oder_falsches_passwort')});

			});
	};

	onUpdateMyUser = (event) => {
		event.preventDefault();
		let self = this;
		const { t } = this.props;
		const client = this.props.client;
		if (this.state.password !== this.state.firstPassword || this.state.password === undefined || this.state.firstPassword === undefined) {
			console.log(this.state.password + ", " + this.state.firstPassword);
			this.openWarningModal(t('die_passwörter_die_sie_eingegeben'))
		} else {
		this.interAxios.post('/api/auth/userupdate',
			 {
				 name: this.state.name,
				 username: this.state.username,
				 email: this.state.email,
				 password: this.state.password
			 }, function(response) {
			 self.onLoginFormSubmit();
			 self.setState({redirect: true});
		 })
	 }
	}

	onNameChange = (event) =>{
		this.setState({name: event.target.value});
	};

	onUsernameChange = (event) =>{
		this.setState({username: event.target.value});
	};

	onEmailChange = (event) =>{
		this.setState({email: event.target.value});
	};

	onPasswordChange= (event) =>{
		this.setState({password: event.target.value});
	};

	onFirstPasswordChange  = (event) => {
		this.setState({firstPassword: event.target.value});
	};

	onSecondPasswordChange  = (event) => {
		this.setState({secondPassword: event.target.value});
	};

	openWarningModal = (text) => {
		this.setState(
			{
				warningModalText: text,
				warningModalShow: true
			}
		);
	}

	closeWarningModal = () => {
		this.setState({warningModalShow: false})
	}

  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
			<>
			<SonstigeNavB2BHersteller userData={this.props.userData}/>
			<div className={styles.bodywrapper}>
				<div className="profil-background">
					<MDBContainer style={{maxWidth: "1250px"}}>
						<MDBRow className="pt-4 m-0">
							<MDBCol md="auto" className="pr-4">
								<ProfilpageNavB2BHersteller activeItem = {"6"} userData={this.props.userData}/>
							</MDBCol>
							<MDBCol className="px-5">
							<MDBRow start className="m-0 pb-5">
								<MDBCol md="auto" className="text-left px-0" middle>
									<img className="group_5_b2b" src={group_5}></img>
								</MDBCol>
								<MDBCol md="9" middle>
									<MDBRow start className="m-0 pb-1">
										<span aria-hidden="true" className="newpost-1-b2b">User</span>
									</MDBRow>
									<MDBRow start className="m-0 pt-0">
										<span aria-hidden="true" className="newpost-2-b2b">Kontodaten</span>
									</MDBRow>
								</MDBCol>
							</MDBRow>
							{!this.state.updateControl ?
								<>
							<p className="">{t('geben_sie_ihren_aktuellen_benutzernamen')}</p>
							<MDBRow center>
							{this.state.falseLogin !== '' ?
							<h5 className="alert alert-danger">{this.state.falseLogin}</h5>:false}
							<form className="row g-3 needs-validation" onSubmit={this.onControlUser} noValidate style={{width: "100%"}}>
								<div className="col-md-12">
									<div className="form-outline-bike-b2b">
											<label htmlFor="validationCustom01" className="form-label pt-2">{t('benutzername')}</label>
											<input type="text" className="form-control text-muted" name="username" onChange={this.onUsernameChange} value={this.state.username} id="validationCustom01"/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-outline-bike-b2b">
											<label htmlFor="validationCustom02" className="form-label pt-2">{t('passwort')}</label>
											<input type="password" className="form-control text-muted" name="password" onChange={this.onPasswordChange} value={this.state.password} id="validationCustom02"/>
									</div>
								</div>
								<div className="col-12">
									<MDBRow center className="profilpage-bottom">
										<button className="btn btn-done-profil-b2b" type="submit">Done</button>
									</MDBRow>
								</div>

							</form>
							</MDBRow></>:<>

							<p className="">{t('change_user_data')}</p>
							<MDBRow center>
							<form className="row g-3 needs-validation" onSubmit={(event) => this.onUpdateMyUser(event)} noValidate style={{width: "100%"}}>
								<div className="col-md-12">
									<div className="form-outline-bike-b2b">
											<label htmlFor="validationCustom03" className="form-label pt-2">{t('name')}</label>
											<input type="text" className="form-control text-muted" name="name" onChange={this.onNameChange} value={this.state.name} id="validationCustom03"/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-outline-bike-b2b">
											<label htmlFor="validationCustom04" className="form-label pt-2">{t('benutzername')}</label>
											<input type="text" className="form-control text-muted" name="name" onChange={this.onUsernameChange} value={this.state.username} id="validationCustom04"/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-outline-bike-b2b">
											<label htmlFor="validationCustom05" className="form-label pt-2">{t('email')}</label>
											<input type="email" className="form-control text-muted" name="name" onChange={this.onEmailChange} value={this.state.email} id="validationCustom05"/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-outline-bike-b2b">
											<label htmlFor="validationCustom06" className="form-label pt-2">{t('passwort')}</label>
											<input type="password" className="form-control text-muted" name="password" onChange={this.onPasswordChange} value={this.state.password} id="validationCustom06"/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-outline-bike-b2b">
											<label htmlFor="validationCustom07" className="form-label pt-2">{t('passwort_bestatigen')}</label>
											<input type="password" className="form-control text-muted" name="password" onChange={this.onFirstPasswordChange} value={this.state.firstPassword} id="validationCustom07"/>
									</div>
								</div>
								<div className="col-12">
									<MDBRow center className="profilpage-bottom">
										<button className="btn btn-done-profil-b2b" type="submit">{t('update')}</button>
									</MDBRow>
								</div>

							</form>
							</MDBRow></>}
								</MDBCol>
							</MDBRow>
							</MDBContainer>
							</div>
							</div>
							<GenericModal
							show={this.state.warningModalShow}
							config={{
							modalTitle: t('achtung'),
							okButton: {
							 show: false
							}
							}}
							handleClose={this.closeWarningModal}
							>
							<div>{this.state.warningModalText}</div>
							</GenericModal>
							{ this.state.redirect ?
							<Redirect to={this.props.userData.isCurrentCompanyAnonim() ? "/NewCompanyB2B" : "/MyProfilePageB2BHersteller"}/>: false}
							</>


    );
  }
}

export default withTranslation()(KontoB2BHersteller);
