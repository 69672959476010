import React, { Component } from "react";
import { Link } from "react-router-dom";
import MessageItemBike from './MessageItemBike';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBInputGroup,
	MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
	MDBListGroup,
	MDBCardFooter,
	MDBScrollbar,
	MDBFileInput,
	MDBIcon,
	MDBBadge,
	MDBAlert
} from 'mdbreact';
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { ThemeColor } from "../../../Config/Config";
import { UserStatus } from "../../../Config/Config";
import Moment from 'moment';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class UserChatBike extends Component {
  state = {
    showProfileDetail: false,
    token : cookies.get('authtoken').token,
    myUserId: cookies.get('authtoken').userId,
    chatSearchBox: false,
    showUserInformation: true,
    showLastMedia: true,
    showDocuments: true,
    emojiWidth: window.innerWidth / 16 + "rem",
    message: "",
		images: [],
		file: '',
		notzip: false
  };

  constructor(props) {
    super(props);
    this.messagesEndRef = React.createRef();
    window.onresize = () => {
      let width = window.innerWidth / 16;
      if (width >= 50) width = 50;
      this.setState({ emojiWidth: width + "rem" });
    };
  };

  componentDidMount() {
    switch (this.props.selecetedChatroomStatus) {
      case UserStatus.Online:
      this.setState({
        status : "avatar-online",
        statusText :  "Online"});
        break;
      case UserStatus.Offline:
      this.setState({
        status : "avatar-offline",
        statusText : "Offline"});
        break;
      default:
        break;
    }
   this.messagesEndRef.current.scrollIntoView({
	 });
  };

 componentDidUpdate(prevProps, prevState) {
    this.messagesEndRef.current.scrollIntoView({
    });
  };

  toggleShowProfileDetail = () => {
    this.setState({ showProfileDetail: !this.state.showProfileDetail });
    document.body.click();
  };

  toggleChatSearchBox = () => {
    this.setState({ chatSearchBox: !this.state.chatSearchBox });
    document.body.click();
  };

  toggleShowUserInformation = () => {
    this.setState({ showUserInformation: !this.state.showUserInformation });
  };



  render() {
    let msgs = [];
    var prevDate = Moment("1970-01-01");
    for (let i = 0; i < this.props.messages.length; i++) {
      let msg = this.props.messages[i];
      let tsmoment = Moment(msg.timestamp);
      if (prevDate.year() !== tsmoment.year() || prevDate.month() !== tsmoment.month() || prevDate.date() !== tsmoment.date()) {
        msgs.push(
          <MDBRow center key={msg.id+'a'}
            className="py-2 date-large m-0"
            ><p className="font-weight-bolder">{tsmoment.format('LL')}</p>
          </MDBRow>
        );
      }
      msgs.push(
        <div key={msg.id} >
          <MessageItemBike msg={msg}
					   drop = {(this.state.myUserId === msg.senderUserId ? "right" : "left")}
						 revoke= {(this.state.myUserId === msg.senderUserId ? true : false)}
             {...this.props}
             {...this.state}
          />
        </div>
      );
      prevDate = tsmoment;
    }
    return (
			<>
						<MDBListGroup >
						  {msgs}
						</MDBListGroup>
						<div ref={this.messagesEndRef}></div>
			</>
    );
  }
}
export default UserChatBike;
