import React, { Component, Fragment } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
import foto2 from "../../assets/media/fotos/2.jpg";
import ratingicon from "../../assets/media/newicons/rating.png";
import { Redirect, Link } from 'react-router-dom';

import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBLightbox } from
"mdbreact";
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import GoogleMaps5 from '../GoogleMaps/GoogleMaps5';
import FeedbackCompany from '../RatingPage/FeedbackCompany';
import FeedbackCardCompany from '../RatingPage/FeedbackCardCompany';
import ImageManager from './ImageManager';
import MapBox from '../MapBox/MapBox';
import Moment from 'moment';
import 'moment/locale/de';
import logo from "../../assets/media/logo/1.png";

import trahsicon from "../../assets/media/newicons/trash.png";
import staricon from '../../assets/media/newicons/star1.png';
import checkdone from "../../assets/media/newicons/check_done.png";
import arrowcirclelefticon from "../../assets/media/images/b2b/back-gelb.png";
import galleryicon from "../../assets/media/newicons/gallery.png";
import plusimgicon from "../../assets/media/newicons/plusImg.png";
import editicon from "../../assets/media/newicons/editProfil.png";
import logouticon from "../../assets/media/newicons/logout.png";
import ib2bicon from "../../assets/media/images/b2b/ib2b_b2b.png";
import sms from "../../assets/media/images/b2b/sms.png";
import call from "../../assets/media/images/b2b/call.png";
import global_icon from "../../assets/media/images/b2b/global.png";
import group_5 from "../../assets/media/images/b2b/Group 17.png";
import Footer from '../Footer/Footer';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';

import{
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBScrollbar,
	MDBCardTitle
} from 'mdbreact';

import InterB2BAxios from '../../Service/InterB2BAxios';


const cookies = new Cookies();
const containerStyle = {
  width: '100%',
  height: '280px'
};


class HerstellerProfileB2BPublic extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {myUserId: cookies.get('authtoken').userId, selectedPhotos: [],isOpen: false,
              	};
  };

	componentDidMount() {

  };

	componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let newSelectedPhotos = [];
      if (this.props.currentCompany.urls!== undefined && this.props.currentCompany.urls.length>0){
        for (const companyPhoto of this.props.currentCompany.urls){
          let urlArray = companyPhoto.split("\/");
          let name = urlArray[urlArray.length-1];
          newSelectedPhotos.push({url: companyPhoto, name: name, server: true});
        }
      }
      this.setState({selectedPhotos: newSelectedPhotos})
    }
  }

	onGetUserFromCompany = () => {
		let self = this;
		this.interAxios.get('/api/user/adminUser/' + this.props.currentCompany.id,
			function(response) {
				self.setState({adminUser: response.data});
				self.onChatContact(response.data.id)
		});
	}

	onChatContact = (id) => {
		let self = this;
		this.interAxios.get('/api/chat/bike/room/getOrCreateWithUser/' + id + "/" + 0,
			function(response) {
				self.setState({chatroomid: response.data, redirect: true});
		});
	}

	onIsOpen = () => {
		this.setState({isOpen: !this.state.isOpen});
	}

	onIsOpenTrue = () => {
		this.setState({isOpen: true});
	}

	onIsOpenFalse = () => {
		this.setState({isOpen: false});
		if(this.props.public){
			this.props.onIsOpen();
		}
	}

  render() {
		const { t } = this.props;
		const client = this.props.client;
		var images = [];
		if (this.state.selectedPhotos !== undefined && this.state.selectedPhotos.length>0){
			for (const photo of this.state.selectedPhotos){
					images.push({	src: photo.url });
			}
		}

		let fotosListMap = this.state.selectedPhotos.map((photo) => {
			return <div key={photo.url} >
							<a onClick={this.onIsOpenTrue}>
								<img src={photo.url} className="image-manager-img"/>
							</a>
							{this.state.editPhotos ?
							<MDBBtn
									 tag="a"
									 className="image-manager-img-trash-btn"
									 floating
									 color="elegant"
									 onClick={() => this.onImagesToDelete(photo)}
							>
								<img src={trahsicon} />
							</MDBBtn>:false}
						 </div>
		})
    return (
			<>

				<MDBContainer  className="profile-content" style={{maxWidth: "1250px"}}>
				<MDBRow className="m-0">
					<div size="12" className="hersteller-profile-header-bg-b2b" style={{backgroundImage: `url(${this.props.currentCompany.backgroundUrl+ '?' + this.props.timeStamp})`}}>
						{this.props.currentCompany.id!== undefined  ?
							<img className="rounded-circle hersteller-profile-image"
								src={this.props.currentCompany.profileUrl +'?' + this.props.timeStamp}
							/>:false}
					</div>
				</MDBRow>
					<MDBRow className="m-0">
						<MDBCol size="12" md="9">
								<MDBRow className="m-0 pr-3">
									<MDBCol middle md="4" className="text-left p-0">
										<MDBBtn flat className="resetHersteller" onClick={this.props.handleResetCurrentCompany}>
												<img src={arrowcirclelefticon}/>
										</MDBBtn>
										<img  className="profile-ib2bicon3" src={ib2bicon}/>
									</MDBCol>
								</MDBRow>
								<MDBRow center className="m-0 pt-1 pb-4">
									<MDBCol md="12" middle className="text-left pl-0">
										<MDBRow start className="m-0 pb-2">
										<MDBCol md="auto" middle className="text-left pl-0">
											<span aria-hidden="true" className="myProfile-symbol-text1">{t('firma')}</span>
										</MDBCol>
										<MDBCol middle className="text-left pl-0">
										<span aria-hidden="true" className="myProfile-symbol-text2">{t('daten')}</span>
										</MDBCol>
										</MDBRow>
									</MDBCol>
								</MDBRow>
						</MDBCol>
						{this.props.contactStatus ?
						<MDBCol size="12" md="3" className="text-right">
							<MDBBtn className="product-filter-btn"
											onClick = {this.onGetUserFromCompany}
							>
								<span className="contact-btn-text text-capitalize">{t('kontakt_1')}</span>
							</MDBBtn>
						</MDBCol>:false}
					</MDBRow>
					<MDBRow className="m-0">
						<MDBCol size="12" md="12">
								<MDBRow>
									<MDBCol size="12" md="6" lg="6" xl="6" className="text-left">
										<span className="adresse-title-text">{this.props.currentCompany.name}</span><br/><br/>
										<span className="adresse-text">{this.props.currentCompany.street}</span><br/>
										<span className="adresse-text">{this.props.currentCompany.postcode} {this.props.currentCompany.town}</span><br/>
										<span className="adresse-text">{this.props.currentCompany.country}</span><br/>
										<br/>
										{this.props.currentCompany.phonenumber !=="" && this.props.currentCompany.phonenumber !== null ?
										<img className="contact-icons" src={call}/>:false} <span className="contact-text">{this.props.currentCompany.phonenumber}</span><br/>
										{this.props.currentCompany.email !=="" && this.props.currentCompany.email !== null ?
										<img className="contact-icons" src={sms}/>:false} <span className="contact-text"><a className="contact-text" href={"mailto:" + this.props.currentCompany.email}>{this.props.currentCompany.email}</a></span><br/>
										{this.props.currentCompany.website !=="" && this.props.currentCompany.website !== null ?
										<img className="contact-icons" src={global_icon}/>:false} <span className="contact-text"><a href={"https://" + this.props.currentCompany.website} target="_blank" rel="noopener noreferrer" className="contact-text">{this.props.currentCompany.website}</a></span><br/><br/>
									</MDBCol>
									<MDBCol size="12" md="6">
										{this.props.currentCompany.lat !== "" ?
										<div className="profile-mapholder">
										<GoogleMaps5 latitude={this.props.currentCompany.lat}
																 longitude={this.props.currentCompany.lng}
																 currentCompany={this.props.currentCompany}
																	 isCompaniesMap= {true}
																	 withMap = {"100%"}
																	 heightMap = {"270px"}
																	/>
										</div>:false}
									</MDBCol>
								</MDBRow>
						</MDBCol>
					</MDBRow>
					{fotosListMap.length > 0 ? <>
					<MDBRow className="m-0">
						<MDBCol size="12" md="12">
								<MDBRow className="m-0 pr-3 pt-3">
									<MDBCol middle md="4" className="text-left p-0">
										<img  className="profile-ib2bicon" src={ib2bicon}/>
									</MDBCol>
								</MDBRow>
								<MDBRow center className="m-0 pt-1 pb-4">
									<MDBCol md="12" middle className="text-left pl-0">
									<MDBRow start className="m-0 pb-2">
									<MDBCol md="auto" middle className="text-left pl-0">
										<span aria-hidden="true" className="myProfile-symbol-text1">{t('firma')}</span>
									</MDBCol>
									<MDBCol middle className="text-left pl-0">
									<span aria-hidden="true" className="myProfile-symbol-text2">{t('fotos')}</span>
									</MDBCol>
									</MDBRow>
									</MDBCol>
								</MDBRow>
						</MDBCol>
					</MDBRow>
					<MDBRow className="m-0">
						<MDBRow className="pl-5">
								{fotosListMap}
								<LightboxPageToMitteilungen
									images={images}
									isOpen={this.state.isOpen}
									onIsOpen={this.onIsOpenFalse}/>
						</MDBRow>
						</MDBRow></>:false}
						{this.props.currentCompany.aboutUs !== null ? <>
						<MDBRow>
							<MDBCol size="12" md="12">
									<MDBRow className="m-0 pr-3 pt-3">
										<MDBCol middle md="4" className="text-left p-0">
											<img  className="profile-ib2bicon" src={ib2bicon}/>
										</MDBCol>
									</MDBRow>
									<MDBRow center className="m-0 pt-1 pb-4">
										<MDBCol md="12" middle className="text-left pl-0">
										<MDBRow start className="m-0 pb-2">
										<MDBCol md="auto" middle className="text-left pl-0">
											<span aria-hidden="true" className="myProfile-symbol-text1">{t('uber')}</span>
										</MDBCol>
										<MDBCol middle className="text-left pl-0">
										<span aria-hidden="true" className="myProfile-symbol-text2">{t('uns')}</span>
										</MDBCol>
										</MDBRow>
										</MDBCol>
									</MDBRow>
							</MDBCol>
						</MDBRow>
						<MDBRow className="m-0">
						<MDBCol size="12" md="12">

										<MDBCard className="interb2b-card px-4 pt-2">
										<MDBRow>
											<MDBCol>
												<div className=' py-2'
												dangerouslySetInnerHTML={{__html: this.props.currentCompany.aboutUs }}/>
											</MDBCol>
										</MDBRow>
										</MDBCard>
						</MDBCol>
					</MDBRow></>:false}
				</MDBContainer>
				{ this.state.redirect === true ?
					<Redirect to={"/ChatsBikeB2B/user/" + this.state.chatroomid}/>:false}
			</>
    );
  }
}

export default withTranslation()(HerstellerProfileB2BPublic);
