import React, { Component } from "react";
import Cookies from 'universal-cookie';
import InterB2BAxios from '../Service/InterB2BAxios';
import FeedbackItemWhitoutComment from '../components/RatingPage/FeedbackItemWhitoutComment';
import Feedback from '../components/RatingPage/Feedback';
import FooterB2BMainPage from '../components/Footer/FooterB2BMainPage';
import FooterMarktLanding from '../components/Footer/FooterMarktLanding';
import ProfilpageNavRespo from '../components/NavbarComponent/ProfilpageNavRespo';
import LandingPageB2BikeNavRespo2 from '../components/NavbarComponentB2B/LandingPageB2BikeNavRespo2';
import MenuSideNavB2BikeMain from '../pages/b2b/Handler/MainPageHandler/MenuSideNavB2BikeMain';
import BoersePageNavRespo from '../components/NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from '../pages/markt/BoersePage/MenuSideNavBoerse';
import BikePartsLinkNavItem from '../components/NavbarComponent/BikePartsLinkNavItem';
import EBikeBoerseLinkNavItem from '../components/NavbarComponentB2B/EBikeBoerseLinkNavItem';
import { Link } from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';
import logo from "../assets/media/logo/1.png";
import ReactStars from "react-rating-stars-component";
import styles from '../assets/scss/modules/about.module.scss';
import profile_Boda from "../assets/media/images/profile_Boda.jpg";
import ratingicon from "../assets/media/newicons/rating.png";
import emptyicon from "../assets/media/newicons/star.png";
import ratingLogo from "../assets/media/images/RatingLogo.png";
import ib2bicon from "../assets/media/images/ib2b.png";
import i18next from 'i18next';
const cookies = new Cookies();


import{
	MDBContainer,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
	MDBCardTitle,
	MDBRow,
	MDBCol,
	MDBBtn,
	MDBIcon,
	MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBSideNav
} from 'mdbreact';
let urlName = '';
class About extends Component {
	constructor(props) {
    super(props);
		this.listInnerRefStart = React.createRef();
		this.interAxios = new InterB2BAxios(this);
    this.state = {lastFeedbackCompany: {},
									lastFeedbackUser: {},
									allFeedback: [],
									allFeedbackList: false,
									average: 0.0,
									allRating: 0,
									lastFeedback: {},
									timeStamp: Date.now(),
									readState: 'read-less',
									iconName: "angle-double-right",
									urlName: this.props.location?.state?.urlName !== undefined ? this.props.location.state.urlName : '',
									};

  };

  componentDidMount() {
		document.addEventListener("scroll", this.scrollEventListener);
		this.onRefStart();
		var params = window.location.href.split("/");
    urlName = params[params.length - 1];
		this.onLastFeedback();
		this.onAllFeedback();
		this.props.addFunctionFromChild(this.onMessage);
		this.props.userData.refreshNavbar();
  };


	componentWillUnmount() {
		document.removeEventListener("scroll", this.scrollEventListener);
    this.props.removeFunctionFromChild(this.onMessage);
		this.interAxios.cancel();
  }

	onRefStart = () => {
		this.listInnerRefStart.current.scrollIntoView({
	 });
	}

	onMessage = (message) => {
    let type = message.type;
    if (type === 'FEEDBACK') {
			this.onLastFeedback();
			this.onAllFeedback();
    }
  };

	onLastFeedbackCompany = (id) =>{
    var self = this;
    this.interAxios.get('/api/findCompanyById/' + id, function(response) {
          self.setState({lastFeedbackCompany: response.data});
    });
	};

	onLastFeedbackUser = (id) => {
		let self = this;
		this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
			self.setState({lastFeedbackUser: response.data});
		})
	}

	onAllFeedback = () =>{
    var self = this;
    this.interAxios.get('/api/allFeedback', function(response) {
					let count = 0;
					let ratingValue = 0;
					let feedbacklist = [];
					if (response.data.length>0){
						for (let i = 0; i < response.data.length; i++){
							count ++;
							ratingValue += response.data[i].rating;
						}
						if (response.data.length>6){
							for (let i = 0; i < 6; i++){
								feedbacklist.push(response.data[i]);
							}
							self.setState({allFeedbackList: true});
					  } else {
							for (let i = 0; i < response.data.length; i++){
								feedbacklist.push(response.data[i]);
							}
						}
			      self.setState({average: ratingValue/count,
                           allRating: count,
												 	 allFeedback: feedbacklist});
			    };


    })
  };

  onLastFeedback = () =>{
    var self = this;
		this.setState({lastFeedback: {}});
    this.interAxios.get('/api/lastFeedback', function(response) {
				if(response.data !== null){
					self.setState({lastFeedback: response.data});
					self.onLastFeedbackUser(response.data.userId)
				}else{
					self.setState({lastFeedback: {}});
				}

    })
  };

	handleReadmore = (id) =>{
		const newClassName = this.state.readState === 'read-less' ? 'read' : 'read-less';
		const { t } = this.props;
    const client = this.props.client;
		this.setState({readState: newClassName});
		if(newClassName === 'read'){
			this.setState({iconName: "angle-double-left",
									 	 textstatus: true});
		}else{
			this.setState({iconName: "angle-double-right",
									   textstatus: false});
		}
	}

	truncateString = (str, num) => {
		if (str.length <= num) {
			return str
  		}
  			return str.slice(0, num) + '...'
	}

	toggleCollapse = collapseID => () => {
		this.setState(prevState => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : ""
		}));
	}

	toggle = tab => e => {
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
				}

	}
	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		var text ="";
		if (this.state.lastFeedback.text !== ''){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.state.lastFeedback.text, 'text/html');
			text =parsedHtml.documentElement.textContent;
		}
		const secondExample = {
				size: 13,
				count: 5,
				color: "black",
				value: this.state.lastFeedback.rating,
				a11y: true,
				edit: false,
				emptyIcon: <img src={emptyicon} style={{width: "100%"}}/>,
				filledIcon: <img src={ratingicon} style={{width: "100%"}}/>,
		};
		let urltext = "LandingPageMarkt";
    return(
			<>
			<div ref={this.listInnerRefStart}></div>
<div className="d-none d-lg-block">
{this.props.userData.isRemoteBoersePage() ?
	<EBikeBoerseLinkNavItem userData={this.props.userData}/>:
	<BikePartsLinkNavItem userData={this.props.userData}/>}
</div>
<div className="d-lg-none d-block">
	<BoersePageNavRespo  userData={this.props.userData} onMenuOpen = {this.props.handleSideNavToggle} isUserMenu={false} activeItem = {"4"}/>
</div>
<div className={styles.bodywrapper}>
<div className="d-lg-none d-block">
	<div className="side-nav-menu">
		<MDBSideNav
			right
			triggerOpening={this.props.isSideNavOpen}
			onOverlayClick={this.props.handleSideNavToggle}
			breakWidth={1300}
			className="deep-purple darken-4"

		>
		<MenuSideNavBoerse
									userData={this.props.userData}
									toggle = {this.toggle}
									onMenuClose = {this.props.onEnableScroll}
									activeItem = {this.state.activeItem}
			/>
		</MDBSideNav>
	</div>
</div>
{this.props.userData.partner() === "Markt" ?
	<section className="aboutUs-top">
		<MDBContainer style={{maxWidth: "1250px"}}>
			<MDBRow>
				<MDBCol md="7" size="12">
				<MDBRow>
					<MDBCol size="4" md="4" middle>
						<img className="logo-aboutus img-fluid" src={ratingLogo}/>
					</MDBCol>
					<MDBCol  size="8" md="8">
						<p className="aboutus-title">{t('uber_uns')}</p>
						<img className="aboutus-ib2bicon" src={ib2bicon}/>
						<p className="aboutus-text">{t('bitte_zögern_sie_nicht')}</p>
					</MDBCol>
				</MDBRow>
			</MDBCol>
			<MDBCol md="5" size="12">
	{this.state.lastFeedback !== {} ?
	<>
		<MDBRow className="m-0">
		{this.state.lastFeedback.text !== '' ?
		<>
		{this.state.textstatus === true ?
			<small className={this.state.readState + ' pt-2 aboutus-ratingtext'} id={'read-' + this.state.lastFeedback.id}>"{text}"</small>:
			<small className="pt-2 pr-3 aboutus-ratingtext">"{this.truncateString(text, 60)}"</small>}

		{text.length > 60 ?
		<MDBBtn flat
			className="btn-block btn-sm mb-0 pb-1 px-0 rating-btn"
			onClick={() => {
				this.handleReadmore(this.state.lastFeedback.id);
			}}
			style={{ textAlign:"left",	paddingLeft:"9px" }}
		>
			<span className="aboutus-text-read" style={{textTransform: "capitalize"}}>{this.state.readState === 'read-less' ? t('weiterlesen') : t('weniger')} </span>
			<MDBIcon style={{ color: "#828282" }} icon={this.state.iconName} />
		</MDBBtn>:false}
		</>:false}
		</MDBRow>
		<MDBRow className="pl-0 pt-2 m-0">
			<MDBCol md="6" size="6" className="about-ratingstars">
			{this.state.lastFeedback.rating !== undefined ?
				<ReactStars
						{...secondExample}
				/>:false}
			</MDBCol>
			<MDBCol md="6" size="6" className="pr-0">
			<p className="aboutus-ratingcompany">
				{this.state.lastFeedback.companyName !== "" ? this.state.lastFeedback.companyName : this.state.lastFeedbackUser.name}
			</p>
			</MDBCol>
		</MDBRow>
	</>:false}
	</MDBCol>
	</MDBRow>
	<MDBRow className="m-0 p-0">
	<Feedback/>
	</MDBRow>
</MDBContainer>
</section>:false}
<section className="aboutUs-middle">
<MDBContainer>
<p>Die Inter B2B GmbH sitzt in Hohenschäftlarn südlich von München.</p>

<p>Der Gründer und Geschäftsführer Balazs Boda hat aus Begeisterung zum E-Bike die Plattform
E-Bikebörse.de und Interb2b.de für Händler Hersteller und Privatleute entwickelt um eine überregionale einfache Möglichkeit zu
bekommen eine Verbindung zwischen Hersteller Produzent und Verkäufer und Privatleute zu bekommen.
</p>

<p>Mit der Website E-Bikebörse.de können Händler-Händler oder Händler-Privatkunden oder Privatkunden-Privatkunden den kauf von E-Bike`s abwickeln. In einem speziellen Bereich der E-Bikebörse.de (B2B Bereich) können Händler (Großhändler ) Hersteller-
Hersteller von Ersatzteilen einen einfachen Kontakt aufbauen um zu sehen wer welche Ersatzteile/Teile  E-Bike`s in welcher Menge zur Verfügung stellen kann.
</p>
<MDBRow className="pt-4">
<MDBCol md="6" size="12" className="pb-3">
<MDBRow className="align-items-center" center>
	<MDBCol md="auto" size="4">
	<img
			src={profile_Boda}
			className="photo-aboutus"
			alt=""
	/>
	</MDBCol>
	<MDBCol  size="8">
	<a href={"mailto:balazs.boda@interb2b.com"}>balazs.boda@interb2b.com</a><br/>
	<span className="profiletext-aboutus pt-2">{t('projektleitung_der_produktentwicklung')}</span>
	</MDBCol>
</MDBRow>
</MDBCol>
</MDBRow>
<MDBRow center className="pt-4">
<MDBCol middle className="text-center pb-4" md="5" size="6">
	<a 	className="partner-logo"
			href="https://www.studicore.hu/about"
			target="_blank" rel="noopener noreferrer">
			<img src="https://www.studicore.hu/img/StudiCore_logo-300x80.png" className="img-partner-rating-left" />
	</a>
	</MDBCol>
	<MDBCol middle className="text-center pb-4" md="2" size="6">
	<a 	className="partner-logo"
			href="https://appentum.de"
			target="_blank" rel="noopener noreferrer">
			<img src="https://appentum.de/img/logo.png" className="img-partner-rating-center" />
	</a>
	</MDBCol>
</MDBRow>
</MDBContainer>
</section>
{this.props.userData.partner() === "Markt" || this.props.userData.partner() === "Dealer" || this.props.userData.partner() === "Supplier" ?
<>
<section className="aboutUs-bottom">
<MDBContainer>
{this.state.allFeedback !== undefined ?
	<>
<MDBRow style={{paddingBottom: "30px"}}>
{this.state.allFeedback.map(
	(o) =>	<MDBCol size="12" md="6" lg="6" xl="4" className="py-0 px-2">
					<MDBCard  className="rating-card" style={{minHeight: "145px", paddingTop: "15px"}}>
					<MDBCardBody>
					 <FeedbackItemWhitoutComment
						key={o.id} lastFeedback={o} allFeedbackStatus = {false}/>
					</MDBCardBody>
					</MDBCard>
					</MDBCol>

)}
</MDBRow>
</>:false}
</MDBContainer>
</section>
<div className="aboutUs-All">
	<MDBRow center style={{paddingTop: "50px"}} className="m-0">
	<Link className="contacts-link" to={"/AllFeedbackPage"}>
	<MDBBtn
			className={this.props.userData.isLoggedInMarkt() ? "alle-feedback-btn-markt" : "alle-feedback-btn-b2b"}
			flat
			type="button"
			>{t('alle_ansehen')}</MDBBtn>
	</Link>
	</MDBRow>
</div>
</>:false}
</div>
<div className="rectangle-footer-addBike">
	<MDBContainer style={{maxWidth: "1250px"}}>
		<FooterMarktLanding/>
	</MDBContainer>
</div>
</>
    );
  }
}
export default withTranslation()(About);
