import React, { Component } from "react";
import { MDBListGroupItem, MDBCardBody, MDBCard, MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import Moment from 'moment';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import readmore from "../../assets/media/images/markt/readmore.png";

class Interb2bNewsItems extends Component {
  constructor(props) {
    super(props);
  };

  truncateString = (str, sum) => {
		if(str !== null){
			if (str.length <= sum) {
				//this.setState({buttonstatus: false});
				return str
			}
					//this.setState({buttonstatus: true});
					return str.slice(0, sum) + '...'

		}
	}

render() {
    let prevDate = Moment(Date.now());
    let prevDate1 = Moment(Date.now()).subtract(1, 'days');
    let prevDate2 = Moment(Date.now()).subtract(2, 'days');
    let tsmoment = Moment(this.props.msg.pubDate);
    let html = this.props.msg['content:encoded'];
    let parser = new DOMParser();
    let wrapper = parser.parseFromString(html, 'text/html');
    let imgs = wrapper.getElementsByTagName('img');
    let useLink = this.props.useLink ?? true;
    return (

            <MDBCard className="news-card-borse">
              <MDBRow center className="m-0 px-2 pb-3">
                <img alt="" className="news-image-borse" src={imgs[0].getAttribute('src')}></img>
              </MDBRow>
              <MDBRow className="m-0 px-2 pb-2" style={{minHeight: "65px"}}>
              {this.props.msg.title.length>38 ?
                <>
                    <span  className="news-title-borse">{this.truncateString(this.props.msg.title, 38)}</span>
             </>:
                <span className="news-title-borse">{this.props.msg.title}</span>}
              </MDBRow>
              <MDBRow className="m-0 px-2 pb-2">
                <div className="news-url-borse text-center">
                    <span className="news-url-text-borse">{this.props.msg.creator}</span>
                </div>
              </MDBRow>
              <MDBRow className="m-0 px-2">
                <MDBCol middle md="7" size="12" className="m-0 px-0 text-center">
                  <span className="news-date-text-borse">UPDATE {tsmoment.locale(this.props.i18n.language).format('DD MMMM YYYY')}</span>
                </MDBCol>
                <MDBCol middle md="5" size="12" className="m-0 px-0 text-center">
                  {useLink ?
                  <a
                    className="news-btn-borse"
                    href={this.props.msg.link}
                    target="_blank" rel="noopener noreferrer"
                  >
                    <span className="news-btn-text-borse">Read</span><img alt="" className="news-btn-icon-borse" src={readmore}></img>
                  </a>
                  :false}
                </MDBCol>
              </MDBRow>
            </MDBCard>

    );
  }
}

export default withTranslation()(Interb2bNewsItems);
