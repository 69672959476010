import React, { Component, Fragment } from "react";

import Cookies from 'universal-cookie';
import foto2 from "../../assets/media/fotos/2.jpg";

import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBLightbox } from
"mdbreact";
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import MapBox from '../MapBox/MapBox';
import logo from "../../assets/media/logo/1.png";

import trahsicon from "../../assets/media/newicons/trash.png";
import checkdone from "../../assets/media/newicons/check_done.png";
import arrowcirclelefticon from "../../assets/media/newicons/arrow-circle-left2.png";
import galleryicon from "../../assets/media/newicons/gallery.png";
import plusimgicon from "../../assets/media/newicons/plusImg.png";
import editicon from "../../assets/media/newicons/editProfil.png";
import logouticon from "../../assets/media/newicons/logout.png";
import ib2bicon from "../../assets/media/images/ib2b.png";
import Footer from '../Footer/Footer';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';


import{
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBScrollbar,
  MDBFileInput
} from 'mdbreact';



const cookies = new Cookies();
const containerStyle = {
  width: '100%',
  height: '280px'
};


class ImageManagerPublic extends Component {

  constructor(props) {
    super(props);
    this.state = {selectedPhotos: [],
									isOpen: false,
              	};
  };

	componentDidMount() {

  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let newSelectedPhotos = [];
      if (this.props.photosList !== undefined && this.props.photosList.length>0){
        for (const companyPhoto of this.props.photosList){
          let urlArray = companyPhoto.split("\/");
          let name = urlArray[urlArray.length-1];
          newSelectedPhotos.push({url: companyPhoto, name: name, server: true});
        }
      }
      this.setState({selectedPhotos: newSelectedPhotos})
    }
  }

	onIsOpenTrue = () => {
		this.setState({isOpen: true});
	}

	onIsOpenFalse = () => {
		this.setState({isOpen: false});
		if(this.props.public){
			this.props.onIsOpen();
		}
	}

  render() {

		var images = [];
		if (this.state.selectedPhotos !== undefined && this.state.selectedPhotos.length>0){
			for (const photo of this.state.selectedPhotos){
					images.push({	src: photo.url });
			}
		}

		let fotosListMap = this.state.selectedPhotos.map((photo) => {
			return <div key={photo.url} >
							<a href="#"  onClick={this.onIsOpenTrue}>
								<img src={photo.url} className="image-manager-img"/>
							</a>
						 </div>
		})
    return (
							<MDBCard className="transparent-card pt-2">
								<MDBRow className="form-row align-items-center">
										{fotosListMap}
										<LightboxPageToMitteilungen
											images={images}
											isOpen={this.state.isOpen}
											onIsOpen={this.onIsOpenFalse}/>
								</MDBRow>
							</MDBCard>

    );
  }
}

export default ImageManagerPublic;
