import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';


import styles from '../../assets/scss/modules/landingB2b.module.scss';
import Cookies from 'universal-cookie';


import LandingPageB2BikeDesktop from './LandingPageB2Bike/LandingPageB2BikeDesktop';
import LandingPageB2BikeResponziv from './LandingPageB2Bike/LandingPageB2BikeResponziv';

const cookies = new Cookies();
class LandingPageB2Bike extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };

  render() {
    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<LandingPageB2BikeDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<LandingPageB2BikeResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
	 		</>
    );
  }
}

export default  LandingPageB2Bike;
