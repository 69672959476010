import React, { Component, Fragment } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import InterB2BAxios from '../../Service/InterB2BAxios';
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import TablePage from './TablePage';
import CreateOrderDTO from './CreateOrderDTO';
import FooterB2BMainPage from '../Footer/FooterB2BMainPage';
import SonstigeNavB2B from '../NavbarComponentB2B/SonstigeNavB2B';
import MenuSideNavB2BikeMain from '../../pages/b2b/Handler/MainPageHandler/MenuSideNavB2BikeMain';
import LandingPageB2BikeNavRespoUser from '../NavbarComponentB2B/LandingPageB2BikeNavRespoUser';
import Moment from 'moment';
import Footer from '../../components/Footer/Footer';
import logo from "../../assets/media/logo/1.png";
import ib2bicon from "../../assets/media/images/b2b/ib2b_b2b.png";
import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import arrowcircleleft from "../../assets/media/images/b2b/back-gelb.png";
import {
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBContainer,
	MDBSideNav
} from 'mdbreact';

import Cookies from 'universal-cookie';

const cookies = new Cookies();


class PayPage extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,
									package: this.props.location?.state?.package!== undefined ? this.props.location.state.package : '',
									preis: this.props.location?.state?.preis!== undefined ? this.props.location.state.preis : '',
									testMode: this.props.location?.state?.testMode!== undefined ? this.props.location.state.testMode : false,
									acquireCompanyWithSameEmail: this.props.location?.state?.acquireCompanyWithSameEmail !== undefined ? this.props.location.state.acquireCompanyWithSameEmail : false,
									companyWithSameEmail: this.props.location?.state?.companyWithSameEmail!== undefined ? this.props.location.state.companyWithSameEmail : {}
								};

  };

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	/*onCreateCompany = () => {
    let self = this;

    axios.post(process.env.REACT_APP_BACKEND_URL+'/api/companies',
            {name: this.state.name,
             lname: this.state.lname,
             fname: this.state.fname,
             street: this.state.street,
             postcode: this.state.postcode,
             town: this.state.town,
             country: this.state.country,
             ustidnr: this.state.ustidnr},
             {headers: {
                'Authorization': 'Bearer ' + this.state.token
              }
            })
    .then(function(response) {
			cookies.set('authtoken', {token: self.state.token, userId: self.state.myUserId, currentCompanyId: response.data.id});
      self.setState({CompanyStatus: true});
    })
    .catch(function(error) {
      console.log(error);
    })
  }

	onCreateUserStatus = () => {
    let self = this;
		let date = new Date();
		date.setMonth(date.getMonth() + 1);
    axios.put(process.env.REACT_APP_BACKEND_URL+'/api/user/update/' + this.state.myUserId,
            {currentPackage: this.state.package,
						 gültigBis: date},
             {headers: {
                'Authorization': 'Bearer ' + this.state.token
              }
            })
    .then(function(response) {
			self.onCreateCompany();
    })
    .catch(function(error) {
      console.log(error);
    })
  }*/

	onCustomerPortal = () => {
		let self = this;
		this.interAxios.get('/ecommerce/stripe-customer-portal', function(response) {
			window.location.href = response.data;
		});
/*		axios.get(process.env.REACT_APP_BACKEND_URL+'/ecommerce/stripe-customer-portal',
			{ headers: {
				 'Authorization': 'Bearer ' + this.state.token
			}}
		).then(function(response) {
			console.log(response);
			window.location.href = response.data;
		}).catch(function(error) {
			console.log(error);
		});*/
	}

	onPayCompany = () => {
		console.log(this.state.testMode);
		let self = this;
		this.interAxios.post('/ecommerce/payNewCompany', { packageId: this.state.package,
			paymentGateway: 'stripe',
			testMode: this.state.testMode,
			language: this.props.i18n.language,
			acquireCompanyWithSameEmail: this.props.acquireCompanyWithSameEmail
		}, function(response) {
			window.location.href = response.data;
		});
/*		axios.post(process.env.REACT_APP_BACKEND_URL+'/ecommerce/payNewCompany',
			{ packageId: this.state.package,
				paymentGateway: 'stripe',
				testMode: this.state.testMode,
				language: this.props.i18n.language,
				acquireCompanyWithSameEmail: this.props.acquireCompanyWithSameEmail
			},
			{
				headers: {
				 'Authorization': 'Bearer ' + this.state.token
			  }
		 	}
		).then(function(response) {
			window.location.href = response.data;
		}).catch(function(error) {
			console.log(error);
		});*/

	}


  render() {
		const { t } = this.props;
		const client = this.props.client;
		let monatlichpreis = this.state.preis;
		let jahrlichpreis = this.state.preis*12;
		const textbasic = t('sie_können_das_abonnement_unter_ihrem_profilmenü_beenden_und_andern')
		const textbm = t('jeden_monat_werden_ihrem_konto') + monatlichpreis + t('euro_abgezogen')
		const textbj = t('jedes_jahr_werden_ihrem_konto') + jahrlichpreis + t('euro_abgezogen')
		const textpremium = t('sie_können_das_abonnement_unter_ihrem_profilmenü_beenden_und_andern')
		const textpm = t('jeden_monat_werden_ihrem_konto') + monatlichpreis + t('euro_abgezogen')
		const textpj = t('jedes_jahr_werden_ihrem_konto') + jahrlichpreis + t('euro_abgezogen')

    return (
			<>
			<div className="d-none d-lg-block">
				<SonstigeNavB2B userData={this.props.userData} activeItem="8"/>
			</div>
			<div className="d-block d-lg-none navbarcomponentRespo-nav-container-b2b">
						<div className="side-nav-menu-b2b">
						<MDBSideNav
						right
						triggerOpening={this.props.isSideNavOpen}
						onOverlayClick={this.props.handleSideNavToggle}
						breakWidth={1300}
						className="deep-purple darken-4"
						>
						<MenuSideNavB2BikeMain
							userData={this.props.userData}
							onMenuClose = {this.props.onEnableScroll}
							onItemClose = {this.props.onEnableScrollMain}
							activeItem = {1}
						/>
						</MDBSideNav>
						</div>
						<LandingPageB2BikeNavRespoUser  activeItem = {"9"}
																						userData={this.props.userData}
																						onMenuOpen = {this.props.handleSideNavToggle}
																						onRefInterB2B={this.onRefInterB2B}
																						textSearch = {this.state.textSearch2}
																						onGetSearchText = {this.onGetSearchText}/>
			</div>
			<div className={styles.bodywrapper}>
				<MDBContainer style={{maxWidth: "1250px"}}>
				<MDBRow center className="price-row-padding2">
				<Link to="/NewCompanyB2B">
							<img alt="" className="price-back-btn" src={arrowcircleleft}></img>
				</Link>
				<h2 className="font-weight-bold">{t('ausgewahlter_plan')}</h2>
				</MDBRow>
				<MDBRow center>
				{this.props.userData.getAcquireCompanyWithSameEmail() && this.state.acquireCompanyWithSameEmail ?
				<p className="company_assign">{t('die_emailadresse_des_unternehmens_namens')}<span className="font-weight-bold">{this.state.companyWithSameEmail.name}</span>{t('in_der_datenbank_entspricht_ihrer_emailadresse')}</p>:false}
				</MDBRow>
				<MDBRow center>
				<img className="netzwerk-paypage pt-4" src={ib2bicon}/>
				</MDBRow>

				<MDBRow center>
					{this.state.package ==="bm" ?
					<MDBCard className="price-card final">
						<MDBCardBody className="text-center">
							<h1>{t('basic_monatlich')}</h1>
							<div className="price">
								<span className="currency">€</span>
								<span className="value">{this.state.preis}</span>
								<span className="period">/mo</span>
							</div>
							<ul className="func-list">
								<li>{t('kooperation_interaktion')}</li>
								<li>{t('chat_funktionen')}</li>
								<li>{t('visibility')}</li>
								<li>{t('statistiken')}</li>
							</ul>
						</MDBCardBody>
					</MDBCard>:false}
					{this.state.package ==="pm" ?
					<MDBCard className="price-card final">
						<MDBCardBody className="text-center">
							<h1>{t('premium_monatlich')}</h1>
							<div className="price">
								<span className="currency">€</span>
								<span className="value">{this.state.preis}</span>
								<span className="period">/mo</span>
							</div>
							<ul className="func-list">
								<li>{t('kooperation_interaktion')}</li>
								<li>{t('chat_funktionen')}</li>
								<li>{t('visibility')}</li>
								<li>{t('statistiken')}</li>
								<li>{t('mehrere_accounts')}</li>
								<li>{t('persönliche_assistance')}</li>
								<li>{t('business_lounge_funktionen')}</li>
							</ul>

						</MDBCardBody>
					</MDBCard>:false}
					{this.state.package ==="bj" ?
					<MDBCard className="price-card final">
						<MDBCardBody className="text-center">
							<h1>{t('basic_jahrlich')}</h1>
							<div className="price">
								<span className="currency">€</span>
								<span className="value">{this.state.preis}</span>
								<span className="period">/mo</span>
							</div>
							<ul className="func-list">
								<li>{t('kooperation_interaktion')}</li>
								<li>{t('chat_funktionen')}</li>
								<li>{t('visibility')}</li>
								<li>{t('statistiken')}</li>
							</ul>
						</MDBCardBody>
					</MDBCard>:false}
					{this.state.package ==="pj" ?
					<MDBCard className="price-card final">
						<MDBCardBody className="text-center">
							<h1>{t('premium_jahrlich')}</h1>
							<div className="price">
								<span className="currency">€</span>
								<span className="value">{this.state.preis}</span>
								<span className="period">/mo</span>
							</div>
							<ul className="func-list">
								<li>{t('kooperation_interaktion')}</li>
								<li>{t('chat_funktionen')}</li>
								<li>{t('visibility')}</li>
								<li>{t('statistiken')}</li>
								<li>{t('mehrere_accounts')}</li>
								<li>{t('persönliche_assistance')}</li>
								<li>{t('business_lounge_funktionen')}</li>
							</ul>
						</MDBCardBody>
					</MDBCard>:false}
				</MDBRow>
				<MDBRow center className="pt-4">
				<MDBCol md="2">
				</MDBCol>
				<MDBCol md="8" className="px-4">
				{this.state.package ==="bm" ?
				<>
				<p className="text-center font-weight-bolder px-4">{textbm}</p>
				<p className="text-center font-weight-bolder px-4">{textbasic}</p></>:false}
				{this.state.package ==="bj" ?
				<>
				<p className="text-center font-weight-bolder px-4">{textbj}</p>
				<p className="text-center font-weight-bolder px-4">{textbasic}</p></>:false}
				{this.state.package ==="pm" ?
				<>
				<p className="text-center font-weight-bolder px-4">{textpm}</p>
				<p className="text-center font-weight-bolder px-4">{textpremium}</p></>:false}
				{this.state.package ==="pj" ?
				<>
				<p className="text-center font-weight-bolder px-4">{textpj}</p>
				<p className="text-center font-weight-bolder px-4">{textpremium}</p></>:false}
				<p className="text-center font-weight-bolder px-4" >Test mode schaltet: {this.state.testMode ? 'ein' : 'aus'}</p>
				</MDBCol>
				<MDBCol md="2">
				</MDBCol>
				</MDBRow>
				<MDBRow center>
				<MDBBtn	type="button"
								rounded className="management-btn btn-sm"
								onClick={this.onPayCompany}>{t('mit_karte_bezahlen')}
				</MDBBtn>

				{/*<MDBBtn variant="primary" onClick={this.onPayCompany}>
					Pay with Card
				</MDBBtn>*/}
				{/*<MDBBtn variant="primary" onClick={this.onCustomerPortal}>
					Customer Portal
				</MDBBtn>*/}
				{this.state.CompanyStatus ?
					<Redirect to='/MyProfilePage'/>
				: false}
				</MDBRow>

				</MDBContainer>
				<div className="rectangle-footer-price">
					<MDBContainer style={{maxWidth: "1250px"}}>
						<FooterB2BMainPage/>
					</MDBContainer>
				</div>
		</div>
		</>
    );
  }
}

export default withTranslation()(PayPage);
