import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import footer1 from "../../assets/media/images/b2b/Group 17.png";
import footer2 from "../../assets/media/images/b2b/Group 18.png";
import{
	MDBRow,
	MDBCol,
	MDBCard,
	MDBNav,
	MDBNavItem,
	MDBNavLink
} from 'mdbreact';

class FooterB2BMainPage extends Component {

  constructor(props) {
    super(props);
  };

	/*aboutUs
	impressum
	privacyPolicy
	termsOfUse
	cookiePolicy*/
  render() {
		const { t } = this.props;
		const client = this.props.client;
    return (
			<>
			<MDBRow center className="pt-3 pb-0">
				<MDBCol md="auto" size="auto" middle className="text-right pr-2">
		    		<img alt="" className="footer1-logo" src={footer1}></img>
				</MDBCol>
				<MDBCol md="auto" size="auto" middle className="text-left pl-2">
		    		<img alt="" className="footer2-logo" src={footer2}></img>
				</MDBCol>
      </MDBRow>
      <div className="footer-nav">
			<MDBNav className="justify-content-center pb-2">
				<MDBNavItem>
					<MDBNavLink to="/AboutB2B"><span className="footertext">{t('aboutUs')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/ImpressumB2B"><span className="footertext">{t('impressum')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/PrivacyPolicyB2B"><span className="footertext">{t('privacyPolicy')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/UserAgreementB2B"><span className="footertext">{t('termsOfUse')}</span></MDBNavLink>
				</MDBNavItem>
				<MDBNavItem>
					<MDBNavLink to="/CookiePolicyB2B"><span className="footertext">{t('cookiePolicy')}</span></MDBNavLink>
				</MDBNavItem>
			</MDBNav>
			 </div>
			 </>

    );
  }
}

export default withTranslation()(FooterB2BMainPage);
