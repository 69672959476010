import React from "react";
import { Route } from "react-router-dom";
import UserChatBikeB2B from "./UserChatBikeB2B";

function ChatHistoryBikeB2B(props) {
  return (
    <>
      <Route
        path= "/ChatsBikeB2B/user/:chatroomId" exact>
        <UserChatBikeB2B {...props}/>
      </Route>
    </>
  );
}
export default ChatHistoryBikeB2B;
