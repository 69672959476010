import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import InterB2BAxios from '../Service/InterB2BAxios';
import Cookies from 'universal-cookie';
import styles from '../assets/scss/modules/signin.module.scss';
import arrowcircleleft from "../assets/media/newicons/arrow-circle-left.png";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn
} from 'mdbreact';

const cookies = new Cookies();

class ForgottenPassword extends Component {
  state = {email: ''};

  constructor(props) {
      super(props);
			this.interAxios = new InterB2BAxios(this);
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}

  onEmailChange  = (event) => {
    this.setState({email: event.target.value});
  };


	sendEmail = (event) => {
	    event.preventDefault();
	    var self = this;
			this.interAxios.get('/api/auth/password/reset', {params: {email: this.state.email}}, function(response) {
				self.setState({redirect: true});
			})
	    /*axios.get(process.env.REACT_APP_BACKEND_URL+'/api/auth/password/reset',
	          {params: {email: this.state.email}})
	    .then(function(response) {
	      self.setState({redirect: true});
			})
	    .catch(function(error) {
	      console.log(error);
	    })*/

	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
	<div className={styles.bodywrapper}>
    	<MDBContainer className="signin-container">
			<MDBRow>
        		<MDBCol md="4">
        			<Link to="/SignIn">
						<img alt="" src={arrowcircleleft}></img>
					</Link>
					<h3 className={styles.pagetitle}>{t('email_senden')}</h3>
					<form className="signin-form">
						<MDBInput
							className="signin-input"
							placeholder={t('geben_sie_ihre_email_ein')}
							label="Email"
							type="email"
							validate error="wrong"
							success="right"
							value={this.state.email}
							onChange={this.onEmailChange}
						/>

						<MDBBtn
							className="signin-btn"
							to="/SignIn"
							color="primary"
							type="submit"
							onClick={this.sendEmail}
							>{t('absenden')}</MDBBtn>

						{ this.state.redirect === true ?
							<Redirect to='/SignIn'
							/>:
							false
                  		}
				  	</form>
				</MDBCol>
				<MDBCol md="8">
				</MDBCol>
				</MDBRow>
		</MDBContainer>
	</div>
    );
  }
}

export default withTranslation()(ForgottenPassword);
