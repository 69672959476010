import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_first from "../../assets/media/images/markt/profil/Group 7.png";
import logo3 from "../../assets/media/images/markt/profil/Group 5.png";
import CreateImageProfile from '../Modals/CreateImageProfile'
import InterB2BAxios from '../../Service/InterB2BAxios';
import FooterMarktLanding from '../Footer/FooterMarktLanding';
import SonstigeNav from '../NavbarComponent/SonstigeNav';
import ProfilpageNavRespo from '../NavbarComponent/ProfilpageNavRespo';
import BoersePageNavRespo from '../NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from '../../pages/markt/BoersePage/MenuSideNavBoerse';
import ProfilpageNav from '../NavbarComponent/ProfilpageNav';
import GenericModal from '../Jitsi/GenericModal.js';
import styles from '../../assets/scss/modules/profilpage.module.scss';
import user_profile_icon from "../../assets/media/newicons/user_profile_icon.png";
import loginicon from "../../assets/media/newicons/login.png";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
const myregexp = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.*([^\s]{11})/gm;
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBIcon,
	MDBDatePicker,
	MDBSideNav
} from 'mdbreact';

const containerStyle = {
  width: '100%',
  height: '280px'
};
let language = '';
let urlName = '';
class Konto extends Component {

	constructor(props) {
		super(props);
		this.listInnerRefStart = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'ProfilInfo');
    this.state = {falseLogin: '',
									updateControl: false,
									redirect: false,
									name: "",
				 				 	username: "",
				 				 	email: "",
				 				 	password: ""
									};
  };

	componentDidMount() {
		document.addEventListener("scroll", this.scrollEventListener);
		this.props.userData.refreshNavbar();
		var params = window.location.href.split(".");
	  language = params[params.length - 1];
		var params2 = window.location.href.split("/");
		urlName = params2[params2.length - 1];
		this.onMe();
		this.onRefStart();
	}

	componentWillUnmount() {
		document.removeEventListener("scroll", this.scrollEventListener);
		this.interAxios.cancel();
	}

	scrollEventListener = (event) => {
		const navPadding = window.scrollY < 50 ? true : false;
		this.setState({ navbarPaddingTop: navPadding});
	}

	onRefStart = () => {
		this.listInnerRefStart.current.scrollIntoView({
	 });
	}

	onMe = () => {
    var self = this;
    this.interAxios.get('/api/user/me', function(response) {
        self.setState({name: response.data.name, email: response.data.email});
      })
  };

	onLoginFormSubmit  = () => {
    var self = this;
		this.interAxios.post('/api/auth/signin', {username: this.state.username, password: this.state.password}, function(response) {
		    cookies.set('authtoken', {
						token: response.data.accessToken,
						userId: response.data.id,
						name: response.data.name,
						currentCompanyId: response.data.companyId,
						sysAdmin: response.data.sysAdmin,
						gastAdmin: response.data.gastAdmin,
					}/*, {
						 sameSite: 'None',
						 secure: true
					}*/
				);
			}, function(error) {
			});
  };

	onControlUser  = () => {
		event.preventDefault();
		var self = this;
		const { t } = this.props;
    const client = this.props.client;
		this.interAxios.post('/api/auth/validUser', {username: this.state.username, password: this.state.password}, function(response) {

					self.setState({falseLogin: ''});
					self.setState({updateControl: response.data});
			}, function(error) {
				self.setState({falseLogin: t('falscher_benutzername_oder_falsches_passwort')});

			});
	};

	onUpdateMyUser = (event) => {
		event.preventDefault();
    let self = this;
		const { t } = this.props;
    const client = this.props.client;
		if (this.state.password !== this.state.firstPassword || this.state.password === undefined || this.state.firstPassword === undefined) {
			console.log(this.state.password + ", " + this.state.firstPassword);
			this.openWarningModal(t('die_passwörter_die_sie_eingegeben'))
		} else {
    this.interAxios.post('/api/auth/userupdate',
		   {
				 name: this.state.name,
				 username: this.state.username,
				 email: this.state.email,
				 password: this.state.password
			 }, function(response) {
			 self.onLoginFormSubmit();
			 self.setState({redirect: true});
		 })
	 }
  }

	onNameChange = (event) =>{
		this.setState({name: event.target.value});
	};

	onUsernameChange = (event) =>{
		this.setState({username: event.target.value});
	};

	onEmailChange = (event) =>{
		this.setState({email: event.target.value});
	};

	onPasswordChange= (event) =>{
		this.setState({password: event.target.value});
	};

	onFirstPasswordChange  = (event) => {
		this.setState({firstPassword: event.target.value});
	};

  onSecondPasswordChange  = (event) => {
    this.setState({secondPassword: event.target.value});
  };

	openWarningModal = (text) => {
		this.setState(
			{
				warningModalText: text,
				warningModalShow: true
			}
		);
	}

	closeWarningModal = () => {
		this.setState({warningModalShow: false})
	}

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<div ref={this.listInnerRefStart}></div>
			<div className="d-none d-lg-block">
				<SonstigeNav  userData={this.props.userData}/>
			</div>
			<div className="d-lg-none d-block">
			<BoersePageNavRespo userData={this.props.userData}
													onMenuOpen = {this.props.handleSideNavToggle}
													isUserMenu={true}
													activeItem = {"6"}
													isOpen = {this.state.isButton}/>
			</div>
				<div className={styles.bodywrapper}>
					<div className="d-lg-none d-block">
						<div className="side-nav-menu">
							<MDBSideNav
								right
								triggerOpening={this.props.isSideNavOpen}
								onOverlayClick={this.props.handleSideNavToggle}
								breakWidth={1300}
								className="deep-purple darken-4"

							>
								<MenuSideNavBoerse
														userData={this.props.userData}
														toggle = {this.toggle}
														onMenuClose = {this.props.onEnableScroll}
														activeItem = {this.state.activeItem}
								/>
							</MDBSideNav>
						</div>
					</div>
					<div className="profil-background">
						<MDBContainer style={{maxWidth: "1250px"}}>
							<MDBRow className="pt-4 addbike-row-bottom-2">
							<div className="d-none d-lg-block">
							<MDBCol md="auto" className="pr-4">
								<ProfilpageNav activeItem = {"6"} userData={this.props.userData}/>
							</MDBCol>
							</div>
								<MDBCol className="px-3">
								<div className="d-none d-lg-block">
								<MDBRow start className="m-0 pt-0 pb-3">
										<img alt="" className="e-bikeborse-logo-first-eigene" src={logo_first}></img>
										<span className="landingpage-text-eigene">{t('konto')}</span>
								</MDBRow>
								</div>
								<div className="d-block d-lg-none">
								<MDBRow center className="m-0 pt-0 pb-3">
										<img alt="" className="e-bikeborse-logo-first-eigene" src={logo_first}></img>
										<span className="landingpage-text-eigene">{t('konto')}</span>
								</MDBRow>
								</div>
								{!this.state.updateControl ?
									<>
								<p className="">{t('geben_sie_ihren_aktuellen_benutzernamen')}</p>
								<MDBRow center>
								{this.state.falseLogin !== '' ?
								<h5 className="alert alert-danger">{this.state.falseLogin}</h5>:false}
								<form className="row g-3 needs-validation" onSubmit={this.onControlUser} noValidate style={{width: "100%"}}>
									<div className="col-md-12">
										<div className="form-outline-markt">
												<label htmlFor="validationCustom01" className="form-label pt-2">{t('benutzername')}</label>
												<input type="text" className="form-control text-muted" name="username" onChange={this.onUsernameChange} value={this.state.username} id="validationCustom01"/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-outline-markt">
												<label htmlFor="validationCustom02" className="form-label pt-2">{t('passwort')}</label>
												<input type="password" className="form-control text-muted" name="password" onChange={this.onPasswordChange} value={this.state.password} id="validationCustom02"/>
										</div>
									</div>
									<div className="col-12">
										<MDBRow center className="profilpage-bottom">
											<button className="btn btn-done-profil" type="submit">Done</button>
										</MDBRow>
									</div>

								</form>
								</MDBRow></>:<>

								<p className="">{t('change_user_data')}</p>
								<MDBRow center>
								<form className="row g-3 needs-validation" onSubmit={(event) => this.onUpdateMyUser(event)} noValidate style={{width: "100%"}}>
									<div className="col-md-12">
										<div className="form-outline-markt">
												<label htmlFor="validationCustom03" className="form-label pt-2">{t('name')}</label>
												<input type="text" className="form-control text-muted" name="name" onChange={this.onNameChange} value={this.state.name} id="validationCustom03"/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-outline-markt">
												<label htmlFor="validationCustom04" className="form-label pt-2">{t('benutzername')}</label>
												<input type="text" className="form-control text-muted" name="name" onChange={this.onUsernameChange} value={this.state.username} id="validationCustom04"/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-outline-markt">
												<label htmlFor="validationCustom05" className="form-label pt-2">{t('email')}</label>
												<input type="email" className="form-control text-muted" name="name" onChange={this.onEmailChange} value={this.state.email} id="validationCustom05"/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-outline-markt">
												<label htmlFor="validationCustom06" className="form-label pt-2">{t('passwort')}</label>
												<input type="password" className="form-control text-muted" name="password" onChange={this.onPasswordChange} value={this.state.password} id="validationCustom06"/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-outline-markt">
												<label htmlFor="validationCustom07" className="form-label pt-2">{t('passwort_bestatigen')}</label>
												<input type="password" className="form-control text-muted" name="password" onChange={this.onFirstPasswordChange} value={this.state.firstPassword} id="validationCustom07"/>
										</div>
									</div>
									<div className="col-12">
										<MDBRow center className="profilpage-bottom">
											<button className="btn btn-done-profil" type="submit">{t('update')}</button>
										</MDBRow>
									</div>

								</form>
								</MDBRow></>}
								</MDBCol>
							</MDBRow>
						</MDBContainer>

					</div>
				</div>
				<GenericModal
					 show={this.state.warningModalShow}
					 config={{
						 modalTitle: t('achtung'),
						 okButton: {
							 show: false
						 }
					 }}
					 handleClose={this.closeWarningModal}
				>
					 <div>{this.state.warningModalText}</div>
				</GenericModal>
				{ this.state.redirect ?
					 <Redirect to="/Eintraege"/>: false}
		</>

    );
  }
}

export default  withTranslation()(Konto);
