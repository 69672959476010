import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Flags from 'country-flag-icons/react/3x2'
import logo from "../assets/media/logo/1.png";
import loginicon from "../assets/media/newicons/login.png";
import play from "../assets/media/newicons/play.png";
import ib2bicon from "../assets/media/images/ib2b.png";
import profile_Boda from "../assets/media/images/profile_Boda.jpg";
import animation from "../assets/media/videos/bg_D.json";
import animation_en from "../assets/media/videos/bg.json";
import companiesPublicObject from "../assets/media/images/companiesPublicObject.png";
import forBiggerBlazes from "../assets/media/images/ForBiggerBlazes.jpg";
import forBiggerEscapes from "../assets/media/images/ForBiggerEscapes.jpg";
import elephantsDream from "../assets/media/images/ElephantsDream.jpg";

import InterB2BAxios from '../Service/InterB2BAxios';
import IhrNetzwerkPublic from '../components/IhrNetzwerk/IhrNetzwerkPublic';
import FooterPublic from '../components/Footer/FooterPublic';

import styles from '../assets/scss/modules/companypublic.module.scss';
import Cookies from 'universal-cookie';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
} from 'mdbreact';

const cookies = new Cookies();
let companyName = '';
let navlinkName = '';
class CompaniesPagePublic extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {currentCompany: {}, redirect: false, allCompanies: []};
  };

	componentDidMount() {
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.refreshNavbar();
		var params = window.location.href.split("/");
		companyName = decodeURI(params[params.length - 1]);
		navlinkName = params[params.length - 1];
		this.onFindCompanyByName();
		//this.onUpdateAllCompanies();
	}

	componentWillUnmount() {
		this.interAxios.cancel();
  }

	/*onUpdateAllCompanies = () => {
		this.interAxios.get('/api/allCompaniesUpdate', function(response) {
		});
	};*/

	onRedirect = () => {
		this.setState({redirect: true});
	}

	onFindCompanyByName = event => {
		let self = this;
		this.interAxios.get('/api/publiccompany/', {
		params: { namePattern: companyName },
		}, function(response){
			self.setState({allCompanies: response.data});
		})
	};

	toGerman = () => {
    i18next.changeLanguage('de', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
    });
  }

  toEnglish = () => {
    i18next.changeLanguage('en', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
    });
  }


  render() {
		const { t } = this.props;
    const client = this.props.client;
		let companiesLis =[];
    if (this.state.allCompanies.length>0){
      companiesLis = this.state.allCompanies.map(

          (o) =>
                  <IhrNetzwerkPublic
									 url={companyName}
								   key={o.id}
                   msg={o}
                   handleChatClick={this.handleChatClick}
									 followed = {true}
									 allCompany = {true}
									 onFindCompanyById= {this.onFindCompanyById}/>

        );
    }
    return (
<>
    <div className={styles.bodywrapper}>
    	<MDBNav className="historypublic-nav fixed-top justify-content-end">
			<MDBNavItem>
				<MDBNavLink to={navlinkName} onClick={this.toEnglish}><Flags.GB style={{borderRadius: "2px", width: "35px"}}/></MDBNavLink>
			</MDBNavItem>
			<MDBNavItem>
				<MDBNavLink to={navlinkName} onClick={this.toGerman}><Flags.DE style={{borderRadius: "2px", width: "35px"}}/></MDBNavLink>
			</MDBNavItem>
		</MDBNav>

		<MDBContainer>
			<MDBRow>
				<MDBCol size="12" md="6" className="order-2 order-md-1">
					<MDBRow className="companiespublic-row">
					<p className="companiespublic-title">{t('treffer_auf_der_InterB2B-Site')}</p>
						<img className="history-ib2b" src={ib2bicon}/>
					<small className="companiespublic-text">{t('schauen_sie_sich_die_unternehmensseiten_auf_InterB2B_an')}</small>
					</MDBRow>
					<MDBRow center>

								{companiesLis}

					</MDBRow>
				</MDBCol>
				<MDBCol size="12" md="6" className="order-1 order-md-1">
					<div className="historypublic-right">
						<img className="historypublic-partner img-fluid" src={companiesPublicObject}/>

						<div className="historypublic-signinbox">
							<img className="historypublic-logo" src={logo}/>

							<p className="companiespublic-standardtitle">{t('tauchen_sie_ein_in_ihren_markt_erreichen')}</p>

							<p className="companiespublic-standardtext">{t('recherchieren_sie_schnell_den_markt_und_liefern_sie')}</p>


							<MDBBtn
									className="signin-btn-public"
									color="primary"
									type="button"
									onClick={this.onRedirect}
									>{t('weiter_zu_InterB2B')}</MDBBtn>
						</div>
						<div className="d-none d-md-block">
							<FooterPublic homeStatus={false} public={true}/>
						</div>
					</div>
				</MDBCol>
			</MDBRow>
			<div className="d-block d-md-none">
			<FooterPublic homeStatus={false} public={true}/>
			</div>
		</MDBContainer>

		{ this.state.redirect === true ?
				<Redirect to='/LandingPage'
			/>:
		false
		}
	</div>
	</>
    );
  }
}

export default  withTranslation()(CompaniesPagePublic);
