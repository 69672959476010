import React, { Component } from "react";
import ProductPageWithCompanyDesktop from './ProductPageWithCompany/ProductPageWithCompanyDesktop';
import ProductPageWithCompanyResponziv from './ProductPageWithCompany/ProductPageWithCompanyResponziv';
class ProductPageWithCompany extends Component {

  constructor(props) {
    super(props);
    this.state = {textSearch2: this.props.userData.productname,
									msg: this.props.location?.state?.msg!== undefined ? this.props.location.state.msg : {},
									suppliers: this.props.location?.state?.suppliers!== undefined ? this.props.location.state.suppliers : {},
									activeItem: this.props.location?.state?.activeItem!== undefined ? this.props.location.state.activeItem : "",
									};
  };

  componentDidMount() {
  };

	componentWillUnmount() {
  }

  render() {

    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<ProductPageWithCompanyDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 textFeld = {this.state.textFeld}
																	 textSearch2 = {this.state.textSearch2}
																	 activeItem = {this.state.activeItem}
                 									 msg = {this.state.msg}
                 									 suppliers = {this.state.suppliers}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<ProductPageWithCompanyResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 textFeld = {this.state.textFeld}
																		 textSearch2 = {this.state.textSearch2}
																		 activeItem = {this.state.activeItem}
                                     msg = {this.state.msg}
                   									 suppliers = {this.state.suppliers}
																		 {...this.props}
																		 />
				</div>}
			</>
    );
  }
}

export default ProductPageWithCompany;
