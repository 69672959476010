import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import 'moment/locale/de';
import Ckeditor from './Ckeditor';
import CommentItem from './CommentItem';
import LikeItem from './LikeItem';
import logo from "../../assets/media/logo/1.png";
import likeicon from "../../assets/media/newicons/like.png";
import like2icon from "../../assets/media/newicons/like2.png";
import commenticon from "../../assets/media/newicons/clipboard-text.png";
import noImage from "../../assets/media/images/noImage_11.png";
import { UserStatus } from "../../Config/Config";
import InterB2BAxios from '../../Service/InterB2BAxios';
import Cookies from 'universal-cookie';
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import { Link } from "react-router-dom";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardTitle,
	MDBCardBody,
	MDBBtn,
	MDBCollapse,
	MDBView,
	MDBMask,
	MDBContainer,
	MDBIcon,
	MDBBtnGroup,
	MDBInput,
	MDBBadge,
	MDBPopper,
	MDBTooltip

} from 'mdbreact';

const cookies = new Cookies();

class CompanysItemWithDesign extends React.Component {


  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {
		      open: false,
					isOpen: false,
					profileImageHash: Date.now(),
					readState: 'read-less',
					token : cookies.get('authtoken').token,
			    myUserId: cookies.get('authtoken').userId,
					companyId: cookies.get('authtoken').currentCompanyId,
					gastAdmin: cookies.get('authtoken').gastAdmin,
					companyId: cookies.get('authtoken').currentCompanyId,
					currentCompanyIsEmpty: cookies.get('authtoken').currentCompanyIsEmpty,
					companyPhotos: [],
					iconName: "angle-double-right",
					q: -1,
					buysLikeCompanies: [],
					sellsLikeCompanies: [],
					kommentar:undefined,
					showKommentar: false,
					textstatus: false,
					collapseID: "",
		    };
  };

	componentDidMount() {

	};

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	highlightSearchText = (text, searchText) => {
		if (searchText === undefined || searchText === "")
			return text;
		let regexp = RegExp(searchText, 'gi')
		let foundIndex = text.search(regexp);
		let comp = [];
		while (foundIndex !== -1) {
			comp.push(text.substring(0, foundIndex));
			comp.push(<b>{text.substring(foundIndex, foundIndex + searchText.length)}</b>);
			text = text.substring(foundIndex + searchText.length);
			foundIndex = text.search(regexp);
		}
		comp.push(text);

		// mukodik case sensitive modon. a case insensitive mukodeshez kell egy kicsit meg reszelgetni
	/*		let array = text.split(searchText);
		let comp = [];
		for (let i = 0; i < array.length-1; i++) {
			comp.push(array[i]);
			comp.push(<b>{searchText}</b>);
		}
		comp.push(array[array.length-1]);*/
		return <>{comp}</>;
	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
    const {open} = this.state;
    return (
				<MDBCard className="interb2b-card">
					<MDBCardBody>
						<MDBRow className="py-3 align-items-center">
							<MDBCol size="auto" sm="auto" md="auto" lg="auto" xl="auto" className="pr-0">
								<div className="avatar1 avatar2-card">
									<img alt="" className="photo" src={this.props.msg.detailPictureUrlHttps !== "" ? this.props.msg.detailPictureUrlHttps +'?' + Date.now(): noImage}></img>
									{this.props.msg.detailPictureUrlHttps === "" ?
									<span className="kein-bild">Kein Bild</span>:false}
								</div>
							</MDBCol>
							<MDBCol className="text-left">
								<Link className="contacts-link"
											to={{pathname: "/ProductPageWithCompany", state: { msg: this.props.msg, suppliers: this.props.suppliers, activeItem: this.props.activeItem}}}
											style={{color: "#000"}}><p
											className="font-weight-normal mb-0">
											{this.highlightSearchText(this.props.msg.description.value, this.props.userData.productname)}</p>
								</Link>
							</MDBCol>
						</MDBRow>
					</MDBCardBody>
				</MDBCard>
	);
  }
}

export default withTranslation()(CompanysItemWithDesign);
