import React, { Component } from "react";
import { GoogleMap, LoadScriptNext, Marker, Circle } from '@react-google-maps/api';

// Source: https://www.npmjs.com/package/@react-google-maps/api

const containerStyle = {
  width: '65%',
  height: '200px',
  borderRadius: "10px",
  border: ".5px solid #ccc8c8"
};

class GoogleMaps5 extends Component {

	constructor(props) {
    super(props);
    this.state = {google_maps_geocoder: {},
                  width: this.props.withMap,
                  height: this.props.heightMap,
                  borderRadius: "10px",
                  border: ".5px solid #ccc8c8"
                  };

    };

    componentDidMount(){
    }

  componentDidUpdate(prevProps) {
    if (this.state.google_maps_geocoder?.geocode !== undefined && this.props.isUmkreis) {
      let self = this;
      this.state.google_maps_geocoder.geocode(
          { 'latLng': { lat: this.props.userData.getLatLng().originalLatitude, lng: this.props.userData.getLatLng().originalLongitude} },
          function( results, status ) {
            let addressArray = results.filter((e) => e.types.includes("postal_code"));
            if(addressArray.length > 0){
              self.props.onGetOriginalAdresse(addressArray[0].formatted_address);
            }

          }
      );
    }
  }


    onLoad = () => {
      this.setState({google_maps_geocoder :new window.google.maps.Geocoder()});
    }

    componentWillUnmount() {
    }


    render() {
      return this.props.latitude !== undefined && this.props.longitude !== undefined ? (
            <>
            <LoadScriptNext googleMapsApiKey="AIzaSyDV1hclNQdPA1iAbsgCKCokf8gBlfiuM0M">
            <GoogleMap
              mapContainerStyle={{width: this.state.width, height: "30vh", borderRadius: this.state.borderRadius, border: this.state.border,}}
              center={{ lat: this.props.latitude, lng: this.props.longitude}}
              zoom={this.props.isUmkreis ? this.props.valueUmkreis === 30 ? 9 : this.props.valueUmkreis === 50 ? 8 :
                    this.props.valueUmkreis === 100 ? 7 : this.props.valueUmkreis === 200 ? 6 :
                    this.props.valueUmkreis === 600 ? 4 : this.props.valueUmkreis > 600 ? 2 : 10 : 10}
              onLoad={this.onLoad}
              options={{
                streetViewControl: false,
                scaleControl: false,
                mapTypeControl: false,
                panControl: false,
                zoomControl: false,
                rotateControl: false,
                fullscreenControl: false
              }}


            >
            {this.props.isUmkreis ?
            <Circle center={{ lat: this.props.latitude, lng:  this.props.longitude }}
                    radius={this.props.valueUmkreis > 600 ? this.props.valueUmkreis * 200 : this.props.valueUmkreis * 1000}
                    options={{ fillColor: '#0c9845',
                               strokeColor: '#0c9845',
                               strokeWeight: 0.5,
                               fillOpacity: 0.35,
                               zIndex: 1
                            }}/>:false}
            {this.props.isProfileMap ?
            <Circle center={{ lat: this.props.latitude, lng:  this.props.longitude }}
                    radius={2000}
                    options={{ fillColor: '#0c9845',
                               strokeColor: '#0c9845',
                               strokeWeight: 0.5,
                               fillOpacity: 0.35,
                               zIndex: 1
                            }}/>:false}
            {this.props.isBikeMap ?
            <Circle center={{ lat: this.props.latitude, lng:  this.props.longitude }}
                    radius={3000}
                    options={{ fillColor: '#0c9845',
                               strokeColor: '#0c9845',
                               strokeWeight: 0.5,
                               fillOpacity: 0.35,
                               zIndex: 1
                            }}/>:false}
            {this.props.isCompaniesMap && this.state.google_maps_geocoder !== undefined ?
            <Marker position={{ lat: this.props.currentCompany.lat, lng:  this.props.currentCompany.lng }}
                    icon={{   url: this.props.currentCompany.profileUrl + '?' + Date.now(),
                              scaledSize: {width: 40, height: 40}
                            }}>

            </Marker>:false}

            </GoogleMap>
            </LoadScriptNext>
            {this.props.isCompaniesMap ?
            <style>{`
              .gm-style img[src^='` + this.props.currentCompany.profileUrl + `'] {
                  border-radius: 20px !important;
                  border: 2.8px solid rgb(27, 20, 234) !important;
              }
            `}</style>:false}

            </>
      ) : <></>
    }
  }
export default  GoogleMaps5;
