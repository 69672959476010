import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import Flags from 'country-flag-icons/react/3x2'
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import logo from "../../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import signOut from "../../../assets/media/images/markt/profil/signOut-w.png";
import addBike from "../../../assets/media/images/markt/E-Bikebörse Logo green.png";
import closebutton from "../../../assets/media/images/markt/hamburgericon-white.png";
import respo_ebike_logo from "../../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import hamburgericon from "../../../assets/media/images/markt/hamburgericon.png";
import anmelden from "../../../assets/media/images/markt/anmelden.png";
import signinmarkt from "../../../assets/media/images/markt/signinmarkt_2.png";
import signupmarkt from "../../../assets/media/images/markt/signupmarkt_2.png";
import searchnormalicon from "../../../assets/media/images/markt/searchnormalicon.png";
import logo2 from "../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../../assets/media/images/markt/Group 18.png";
import logo4 from "../../../assets/media/images/markt/Group 19.png";
import logo5 from "../../../assets/media/images/markt/Group 20.png";
import logo6 from "../../../assets/media/images/markt/Group 25.png";
import logo7 from "../../../assets/media/images/markt/E-Bikebörse Logo 3.png";
import profile_Boda from "../../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../../assets/media/images/b2b/Group 8.png";
import group_21 from "../../../assets/media/images/markt/Group 21_2.png";
import rectangle_1 from "../../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../../assets/media/images/b2b/Appentum_2.png";
import studiCore from "../../../assets/media/images/b2b/StudiCore_2.png";
import interb2b from "../../../assets/media/images/b2b/Interb2b_2.png";

import InterB2BAxios from '../../../Service/InterB2BAxios';
import FooterMarktLanding from '../../../components/Footer/FooterMarktLanding';
import GyikModul from "../../../components/Gyik/GyikModul";
import Interb2bNewsItems from '../../../components/News/Interb2bNewsItems';
import MitteilungenItemRespo from '../../../components/Companys/MitteilungenItemRespo';
import MitteilungenItemWithoutLoginRespo from '../../../components/Companys/MitteilungenItemWithoutLoginRespo';
import ChatsMainBike from '../../../components/ChatBike/ChatsMainBike';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../components/LazyLoad/LazyLoadItem';

import moment from 'moment';
import styles from '../../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';
import { Scrollbar } from "react-scrollbars-custom";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle,
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBModalFooter,
} from 'mdbreact';
const Parser = require('rss-parser');
const parser = new Parser();
const cookies = new Cookies();
let language = '';
let urlName = '';

class MenuSideNavMarkt extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'LandingPage');
    this.state = {
									};
  };





	componentDidMount() {
		var params = window.location.href.split(".");
		language = params[params.length - 1];
	}

	componentWillUnmount() {
	}

	setBackendLanguage = (language) => {
		this.interAxios.get('/api/user/setLanguage', { params: { language: language}}, function(response) {
			console.log('Language set to ' + language)
		})
	}

	toGerman = () => {
		i18next.changeLanguage('de', (err, t) => {
			if (err) return console.log('something went wrong loading', err);
			this.setBackendLanguage('de');
			//this.setState({language: 'de'});
		});
		moment.locale('de');
		//this.onLanguageDropFalse();
	}

	toEnglish = () => {
		i18next.changeLanguage('en', (err, t) => {
			if (err) return console.log('something went wrong loading', err);
			this.setBackendLanguage('en');
			//this.setState({language: 'en'});
		});
		moment.locale('en');
		//this.onLanguageDropFalse();
	}


  render() {
//		console.log(this.state.redirect);
		const { t } = this.props;
    const client = this.props.client;

    return (
			<div className="menu-modal">
			<MDBCard className="landingmarktResponziv-nav-container">
      <MDBCardBody>
					<MDBRow end className="hamburegermenu-row">
							<MDBBtn
								className="hambureger-menu"
								flat
								type="button"
								onClick= {this.props.onMenuClose}
							>
								<img alt="" className="hambureger-icon" src={closebutton}></img>
							</MDBBtn>
					</MDBRow>
					<MDBNav className="landingmarkt-nav flex-column">
						<MDBNavItem>
							<MDBNavLink link to="#" active={this.props.activeItem === "9"} onClick={this.props.toggle("9")} role="tab" >
									<img alt="" className="main-logo" src={logo7}></img>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="#" active={this.props.activeItem === "1"} onClick={this.props.toggle("1")} role="tab" >
									<span>News</span>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="#" active={this.props.activeItem === "2"} onClick={this.props.toggle("2")} role="tab" >
									<span>Blog</span>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="#" active={this.props.activeItem === "3"} onClick={this.props.toggle("3")} role="tab" >
									<span>Info</span>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="#" active={this.props.activeItem === "4"} onClick={this.props.toggle("4")} role="tab" >
									<span>{t('kontakt')}</span>
							</MDBNavLink>
						</MDBNavItem>
						<div className="linie"/>
						<Link  to={this.props.userData.isLoggedInMarkt() ? "/AddBike" : "/SignInMarkt"} >
							<MDBBtn
								className="addBike-btn-landingmarkt"
								flat
								type="button"
								onClick= {this.props.onMenuClose}
							>
								<img alt="" className="addBike-logo" src={addBike}></img><br/>
							</MDBBtn>
						</Link>
						<div className="linie"/>
						{!this.props.userData.isLoggedInMarkt() ? <>
						<MDBNavItem>
							<MDBNavLink link to="/SignUpMarkt" active={this.props.activeItem === "5"} onClick={this.props.toggle("5")} role="tab" >
								<MDBRow center className="m-0">
									<MDBCol middle md="auto" size="auto" className="pr-0 display-position">
										<img alt="" className="signinmarkt-logo" src={signupmarkt}></img>
									</MDBCol>
									<MDBCol middle md="auto" size="auto" className="pl-2">
										<span className="signup-text text-capitalize">{t('registrieren')}</span>
									</MDBCol>
								</MDBRow>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="/SignInMarkt" active={this.props.activeItem === "6"} onClick={this.props.toggle("6")} role="tab" >
								<MDBRow center className="m-0">
									<MDBCol middle md="auto" size="auto" className="pr-0 display-position">
										<img alt="" className="signinmarkt-logo" src={signinmarkt}></img>
									</MDBCol>
									<MDBCol middle md="auto" size="auto" className="pl-2">
										<span className="anmelden-text text-capitalize">{t('anmelden')}</span>
									</MDBCol>
								</MDBRow>
							</MDBNavLink>
						</MDBNavItem></>:
						<MDBNavItem>
							<MDBNavLink link to="/Eintraege" active={this.props.activeItem === "7"} onClick={this.props.toggle("7")} role="tab" >
							{this.props.userData.getCurrentCompany()?.profileUrl !== undefined ?
								<img className="userProfileName-icon"
									src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
									/>:<>
									{this.props.userData.getMyUser()?.profileUrl !== undefined ?
										<img alt="" className="user-photo-navbar" src={this.props.userData.getMyUser()?.profileUrl +'?' + Date.now()}></img>:
										<div className="userProfileName-icon">{this.props.userData.userProfilName()}</div>}</>}
							</MDBNavLink>
						</MDBNavItem>}
					</MDBNav>
					{this.props.userData.isLoggedInMarkt() ?
					<MDBNav  className="landingmarkt-nav flex-column">
					<MDBNavItem>
						<MDBNavLink link to="/LandingPageMarkt" active={this.props.activeItem === "8"} role="tab" onClick= {this.props.onMenuClose}>
							<MDBBtn
								flat
								onClick={() => this.props.userData.onLogout()}
								className="p-0 m-0"
							>
								<MDBRow className="m-0">
									<MDBCol middle md="auto" size="auto" className="px-0 col-height">
										<img alt="" className="profil-logo-modal" src={signOut}></img>
									</MDBCol>
									<MDBCol middle className="pl-2 pr-2 col-height">
										<span className="anmelden-text text-capitalize">{t('abmelden')}</span>
									</MDBCol>
								</MDBRow>
							</MDBBtn>
						</MDBNavLink>
					</MDBNavItem>
					</MDBNav>:false}
					{this.props.userData.getInterB2Bike() || this.props.userData.isLoggedInMarkt() && (this.props.userData.getMyUser()?.partner == "Dealer" || this.props.userData.getMyUser()?.partner == "Supplier") ?
					<div className="landingmarkt-nav">
					<MDBNav>
					<MDBNavItem>
						<a href="https://www.interb2bike.com" role="tab" className="nav-link Ripple-parent">
								<MDBBtn
									flat
									onClick={() => this.props.userData.onLogout()}
									className="p-0 m-0"
								>
									<img alt="" className="group-21-logo" src={group_21}></img>
								</MDBBtn>
						</a>
					</MDBNavItem></MDBNav></div>:false}
					<div className="currentcompany-nav-container">
					<MDBRow center>
					<MDBNav className="justify-content-center currentcompany-nav">
					<MDBNavItem>
						<MDBNavLink  id="flag" link className="pt-3" to="#" active={this.props.i18n.language === 'en'} onClick={this.toEnglish} role="tab">
							<Flags.GB style={{borderRadius: "2px", width: "30px"}}/>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink  id="flag" link className="pt-3" to="#" active={this.props.i18n.language === 'de'} onClick={this.toGerman} role="tab">
							<Flags.DE style={{borderRadius: "2px", width: "30px"}}/>
						</MDBNavLink>
					</MDBNavItem>
					</MDBNav>
					</MDBRow>
					</div>
				</MDBCardBody>
				</MDBCard>
				</div>
 	);
 }
 }

 export default  withTranslation()(MenuSideNavMarkt);
