import React, { Component } from "react";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"

const containerStyle = {
  width: '100%',
  height: '280px'
};

class MapBox extends Component {

  constructor(props) {
    super(props);
    mapboxgl.accessToken = 'pk.eyJ1IjoiYm9kYTcxIiwiYSI6ImNrcGN2ejBvNzAyeWIyd252ZWZyajhwbDUifQ.vyHyhXUTW812k_mqw6MwkQ';
    this.state = {
      lng: -70.9,
      lat: 42.35,
      zoom: 9
    };
    this.mapContainer = React.createRef();
  };

  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer.current, // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [this.props.currentCompany.lng, this.props.currentCompany.lat], // starting position [lng, lat]
      zoom: 9 // starting zoom
    });
    const marker = new mapboxgl.Marker()
      .setLngLat([this.props.currentCompany.lng, this.props.currentCompany.lat])
      .addTo(map);
  }
  render() {
    return (
      <div>
        <div ref={this.mapContainer} className="map-container" />
      </div>
    )
  }
}
export default MapBox;
