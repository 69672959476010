import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import standorticon from '../../../assets/media/images/markt/map.png';
import arrowUp2 from "../../../assets/media/images/markt/arrowUp2.png";
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../components/LazyLoad/LazyLoadItem';
import InterB2BAxios from '../../../Service/InterB2BAxios';
import Footer from '../../../components/Footer/Footer2';
import BikeAnzeigeKarte from '../../../components/Companys/BikeAnzeigeKarte';
import BoersePageNav from '../../../components/NavbarComponent/BoersePageNav';
import FilterByStandort from '../../../components/Modals/FilterByStandort';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import GoogleMaps5 from '../../../components/GoogleMaps/GoogleMaps5';
import { Scrollbar } from "react-scrollbars-custom";
import styles from '../../../assets/scss/modules/boersepage.module.scss';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBSelect,
	MDBIcon,
	MDBTooltip,
	MDBPopper
} from 'mdbreact';

let language = '';
class BoersePageDesktop extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefStart = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'BoersePage');
    this.state = {bikeslist: {},
									activeItem: this.props.location?.state?.activeItem !== undefined ? this.props.location.state.activeItem : "1",
									bikeKategorie: this.props.location?.state?.bikeKategorie !== undefined ? this.props.location.state.bikeKategorie : "",
									activeItemS: false,
									activeItemM: false,
									activeItemL: false,
									activeItemXL: false,
									activeItemXXL: false,
									activeItemOnline: false,
									activeItemSelbst: false,
									activeItemNeu: false,
									activeItemGebraucht: false,
									value: [0,20000],
									valueUmkreis: 20000,
									showStandortFilter: false,
									//aktuelleStandort: "Aktuelle Standort",
									latitude: "",
									longitude: "",
									adresse: "",
									anzahlCompany: 0,
									anzahlUser: 0,
									sortedTyp: 1,
									navbarPaddingTop: true,
									categories: [],
									};
  };


	componentDidMount() {
		this.getAllEBikeCategories()
		  document.addEventListener("scroll", this.scrollEventListener);
			const { t } = this.props;
	    const client = this.props.client;
		  this.setState({
				options: [
									{ checked: this.state.sortedTyp === 1,
										text: t('Sortierung_nach_Datum'),
										value: "1"
									},
									{ checked: this.state.sortedTyp === 2,
										text: t('Preis_aufsteigend'),
										value: "2"
									},
									{ checked: this.state.sortedTyp === 3,
										text: t('Preis_absteigend'),
										value: "3"
									}
								]
			});
			this.props.addFunctionFromChild(this.onMessage);
			var params = window.location.href.split(".");
	    language = params[params.length - 1];
			let latitude;
			let longitude;
			if(this.props.userData.isLoggedInMarkt()){
			if(this.props.userData.getCurrentCompany()?.profileUrl !== undefined){
				latitude = this.props.userData.getCurrentCompany().lat;
				longitude = this.props.userData.getCurrentCompany().lng;
				this.setState({latitude: latitude,
											 longitude: longitude,
											 adresse: this.props.userData.getCurrentCompany().street + ", " +
											 					this.props.userData.getCurrentCompany().postcode + ", " +
																this.props.userData.getCurrentCompany().town + ", " +
																this.props.userData.getCurrentCompany().country,
											 aktuelleStandort: this.props.userData.getCurrentCompany().street + ", " +
											 					this.props.userData.getCurrentCompany().postcode + ", " +
																this.props.userData.getCurrentCompany().town + ", " +
																this.props.userData.getCurrentCompany().country,
										 });
			}else{
				latitude = this.props.userData.getMyUser()?.lat ?? this.props.userData.getLatLng().originalLatitude;
				longitude = this.props.userData.getMyUser()?.lng ?? this.props.userData.getLatLng().originalLongitude;
				this.setState({latitude: latitude,
											 longitude: longitude,
											 adresse: this.props.userData.getMyUser()?.standort ?? "N/A",
											 aktuelleStandort: this.props.userData.getMyUser()?.standort ?? "N/A"
										 });
			}}else{
					latitude = this.props.userData.getLatLng().originalLatitude;
					longitude = this.props.userData.getLatLng().originalLongitude;
					this.setState({latitude: latitude,
												 longitude: longitude
											 });
			}
			this.onGetBikes(latitude, longitude, 1);

	}

	getAllEBikeCategories = () => {
		let self = this;
		this.interAxios.get('/api/eBikeKategorie', function(response) {
			//console.log(response.data);
			self.setState({categories: response.data});
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.bikeKategorie !== prevState.bikeKategorie ||
				this.state.valueUmkreis !== prevState.valueUmkreis ||
				this.state.textBike !== prevState.textBike ||
				this.state.value[0] !== prevState.value[0] ||
				this.state.value[1] !== prevState.value[1] ||
				this.state.activeItemS !== prevState.activeItemS ||
				this.state.activeItemM !== prevState.activeItemM ||
				this.state.activeItemL !== prevState.activeItemL ||
				this.state.activeItemXL !== prevState.activeItemXL ||
				this.state.activeItemXXL !== prevState.activeItemXXL ||
				this.state.activeItemOnline !== prevState.activeItemOnline ||
				this.state.activeItemSelbst !== prevState.activeItemSelbst ||
				this.state.activeItemNeu !== prevState.activeItemNeu ||
				this.state.activeItemGebraucht !== prevState.activeItemGebraucht ||
				this.state.sortedTyp !== prevState.sortedTyp){
					this.onGetBikes(this.state.latitude, this.state.longitude, this.state.sortedTyp);
				}
	}

	componentWillUnmount() {
		this.props.removeFunctionFromChild(this.onMessage);
		document.removeEventListener("scroll", this.scrollEventListener);
		this.interAxios.cancel();
	}

	scrollEventListener = (event) => {
		const navPadding = window.scrollY < 100 ? true : false;
		this.setState({ navbarPaddingTop: navPadding});
	}

	onRefStart = () => {
		this.listInnerRefStart.current.scrollIntoView({
	 });
	}

	/*onMe = () => {
    var self = this;
    this.interAxios.get('/api/user/me', function(response) {
        self.setState({userMe: response.data});
      })
  };*/

	onMessage = (message) => {
    let type = message.type;
		if (type === 'NEWEBIKEPRODUCT'){
	    this.onGetBikes(this.state.latitude, this.state.longitude, this.state.sortedTyp);
	  }
  };

  	toggleNew = (tab, kategorie) => {
		//console.log("set: " + tab + ", " + kategorie);
		this.setState({activeItem: tab, bikeKategorie: kategorie});
	}

	/*toggle = tab => e => {
		const { t } = this.props;
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
						if (tab == 1) {
							this.setState({
								bikeKategorie: ""});
						}
						if (tab == 2) {
							this.setState({
								bikeKategorie: "E-Bike Pedelec"});
						}
						if (tab == 3) {
							this.setState({
								bikeKategorie: "E-Bike 45 Km/h"});
						}
						if (tab == 4) {
							this.setState({
								bikeKategorie: "E-Bike City"});
						}
						if (tab == 5) {
							this.setState({
								bikeKategorie: "E-Bike Cross"});
						}
						if (tab == 6) {
							this.setState({
								bikeKategorie: "E-Bike " + t('Klapprad_Faltrad')});
						}
						if (tab == 7) {
							this.setState({
								bikeKategorie: "E-Bike " + t('Kinder_Jugendliche')});
						}
						if (tab == 8) {
							this.setState({
								bikeKategorie: "E-Bike MTB"});
						}
						if (tab == 9) {
							this.setState({
								bikeKategorie: "E-Bike " + t('Rennrader')});
						}
						if (tab == 10) {
							this.setState({
								bikeKategorie: "E-Bike Trekking"});
						}
						if (tab == 11) {
							this.setState({
								bikeKategorie: "E-Scooter"});
						}

				}
	}*/

	toggle2 = tab => e => {
						if (tab == 1) {
							this.setState({
								activeItemS: !this.state.activeItemS});
								//this.onGetBikes();
						}
						if (tab == 2) {
							this.setState({
								activeItemM: !this.state.activeItemM});
						}
						if (tab == 3) {
							this.setState({
								activeItemL: !this.state.activeItemL});
						}
						if (tab == 4) {
							this.setState({
								activeItemXL: !this.state.activeItemXL});
						}
						if (tab == 5) {
							this.setState({
								activeItemXXL: !this.state.activeItemXXL});
						}
	}

	toggle3 = tab => e => {
						if (tab == 1) {
							this.setState({
								activeItemOnline: !this.state.activeItemOnline});
						}
						if (tab == 2) {
							this.setState({
								activeItemSelbst: !this.state.activeItemSelbst});
						}
	}

	toggle4 = tab => e => {
						if (tab == 1) {
							this.setState({
								activeItemNeu: !this.state.activeItemNeu});
						}
						if (tab == 2) {
							this.setState({
								activeItemGebraucht: !this.state.activeItemGebraucht});
						}
	}


	handleChange = (event, newValue) => {
    					this.setState({value: newValue});
   };
	 handleChangeUmkreis = (event, newValue) => {
							 this.setState({valueUmkreis: newValue});
		};

	onGetBikes = (lat, lng, sortedTyp) => {
		this.setState({anzahlCompany: 0, anzahlUser: 0});
		let self = this;
		this.interAxios.get('/api/allBikeProduct', {params: {sortedTyp: this.state.sortedTyp,
																												 anzahlCompany: 0,
																												 anzahlUser: 0,
																												 lat: lat,
																												 lng: lng,
																												 textBike: this.props.userData.textBike,
																												 bikeKategorie: this.state.bikeKategorie,
																												 lowerLimit: this.state.value[0],
																												 upperLimit: this.state.value[1],
																												 valueUmkreis: this.state.valueUmkreis,
																												 activeItemS: this.state.activeItemS,
																												 activeItemM: this.state.activeItemM,
																												 activeItemL: this.state.activeItemL,
																												 activeItemXL: this.state.activeItemXL,
																												 activeItemXXL: this.state.activeItemXXL,
																												 activeItemOnline: this.state.activeItemOnline,
																												 activeItemSelbst: this.state.activeItemSelbst,
																												 activeItemNeu: this.state.activeItemNeu,
																												 activeItemGebraucht: this.state.activeItemGebraucht
																							 }}, function(response) {
			 self.setState({	bikeslist: response.data.bikeList,
	 											anzahlCompany: response.data.anzahlCompany + self.state.anzahlCompany,
	 											anzahlUser: response.data.anzahlUser + self.state.anzahlUser});
		})
	};

	onMoreGetBikes = (lat, lng, sortedTyp) => {
		let self = this;
		this.interAxios.get('/api/allMoreBikeProduct', {params: {sortedTyp: this.state.sortedTyp,
																														 anzahlCompany: this.state.anzahlCompany,
																														 anzahlUser: this.state.anzahlUser,
																														 lat: lat,
																														 lng: lng,
																														 textBike: this.props.userData.textBike,
																														 bikeKategorie: this.state.bikeKategorie,
																														 lowerLimit: this.state.value[0],
																														 upperLimit: this.state.value[1],
																														 valueUmkreis: this.state.valueUmkreis,
																														 activeItemS: this.state.activeItemS,
																														 activeItemM: this.state.activeItemM,
																														 activeItemL: this.state.activeItemL,
																														 activeItemXL: this.state.activeItemXL,
																														 activeItemXXL: this.state.activeItemXXL,
																														 activeItemOnline: this.state.activeItemOnline,
																														 activeItemSelbst: this.state.activeItemSelbst,
																														 activeItemNeu: this.state.activeItemNeu,
																														 activeItemGebraucht: this.state.activeItemGebraucht,
																														 before: this.state.bikeslist[this.state.bikeslist.length-1].time
																							 }}, function(response) {
			 let bikeslist = self.state.bikeslist;
 			bikeslist.push(...response.data.bikeList);
 			self.setState({	bikeslist: bikeslist,
											anzahlCompany: response.data.anzahlCompany + self.state.anzahlCompany,
											anzahlUser: response.data.anzahlUser + self.state.anzahlUser});
		})
	};

	handleShowStandortFilter = () => {
    this.setState({showStandortFilter: true});
  };

	handleCloseStandortFilter = () => {
    this.setState({showStandortFilter: false});
		this.props.userData.refresh();
  };

	onGetStandort = (adresse) => {
		this.setState({aktuelleStandort: adresse});
	}

	onGetUmkreis = (newValue) => {
		this.setState({valueUmkreis: newValue});
	}

	onGetLatLng = (lat, lng) => {
		this.setState({latitude: lat, longitude: lng});
	}

	onSetSortedTyp = value => {
		const { t } = this.props;
    const client = this.props.client;
      this.setState({sortedTyp: value === t('Sortierung_nach_Datum') ? 1 :
																value === t('Preis_aufsteigend') ? 2 :
																value === t('Preis_absteigend') ? 3 : 1});
	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
		const marks = [
		  {
		    value: 0,
		    label: this.state.value[0],
		  },
		  {
		    value: 20000,
		    label: this.state.value[1],
		  },
		];
		let bikeList = {};
		if(this.state.bikeslist.length > 0){
			bikeList = 	this.state.bikeslist.map(
				(o, index) =>
				<MDBCol md="3" size="12" lg="4" xl="3" className="mb-3 px-2 pt-0"key={index}>
						<LazyLoad height={200} width={"100%"} offset={[-100, 0]}>
							<LazyLoadItem index={index} size={this.state.bikeslist.length} loadFunction={() => this.onMoreGetBikes(this.state.latitude, this.state.longitude)}>
								<BikeAnzeigeKarte
								 key={index}
								 userData={this.props.userData}
								 msg={o}/>
						 </LazyLoadItem>
				 	</LazyLoad>
				</MDBCol>
			);

		}
		//console.log("activeItem: " + this.state.activeItem + " bikeKategorie: " + this.state.bikeKategorie);
		let categoryTabs = this.state.categories.map((name, index) => {
			let localizedName = t(name);
			if (localizedName.slice(0, 6) === "E-Bike") {
				localizedName = localizedName.slice(7);
			}
			let activeTab = index + 2 + "";
			return <MDBNavItem>
					<MDBNavLink link to="#" active={this.state.activeItem === activeTab} onClick={(event) => this.toggleNew(activeTab, name)} role="tab" >
						<MDBRow>
							<span className="profilpage-menu">{localizedName}</span>
						</MDBRow>
					</MDBNavLink>
				</MDBNavItem>
			}
		);

    return (
			<>
			<div ref={this.listInnerRefStart}></div>
			<BoersePageNav  userData={this.props.userData}/>
				<div className={styles.bodywrapper}>
							<MDBContainer style={{maxWidth: "1350px"}}>
								<MDBRow className="m-0">
									<MDBCol md="3" size="12" lg="4">
										<div className="sticky-card-anzeige">
											<MDBCard className="filter-card-anzeige">
												<MDBCardBody className="py-2">
												<div className="anzeigepage-filter">
													<MDBRow center className="rahmengroese-filter-row">
													<div className="lieferung-filter">
														<MDBNav className="flex-row lieferung-nav">
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItemNeu} onClick={this.toggle4("1")} role="tab" >
																	<span className="profilpage-menu">{t('neu')}</span>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItemGebraucht} onClick={this.toggle4("2")} role="tab" >
																	<span className="profilpage-menu">{t('gebraucht')}</span>
																</MDBNavLink>
															</MDBNavItem>
														</MDBNav>
													</div>
													</MDBRow>
												</div>
												<Scrollbar
													id="scrollbar"
													style={{ width: "98%", height: "66vh"}}
													maximalThumbYSize = {150}
													noScrollX = {true}
													>
												<div className="anzeigepage-filter">
													<MDBRow center className="rahmengroese-filter-row">
														<MDBNav className="flex-column landingmarkt-nav">
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={(event) => this.toggleNew("1", "")} role="tab" >
																	<MDBRow>
																			<span className="profilpage-menu">{t('Alle_Kategorien')}</span>
																	</MDBRow>
																</MDBNavLink>
															</MDBNavItem>
															{/*<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
																	<MDBRow>
																			<span className="profilpage-menu">Pedelec</span>
																	</MDBRow>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" >
																<MDBRow>
																		<span className="profilpage-menu">45 km/h</span>
																</MDBRow>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItem === "4"} onClick={this.toggle("4")} role="tab" >
																<MDBRow>
																		<span className="profilpage-menu">City</span>
																</MDBRow>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItem === "5"} onClick={this.toggle("5")} role="tab" >
																	<MDBRow>
																			<span className="profilpage-menu">Cross</span>
																	</MDBRow>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItem === "6"} onClick={this.toggle("6")} role="tab" >
																	<MDBRow>
																			<span className="profilpage-menu">{t('Klapprad_Faltrad')}</span>
																	</MDBRow>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItem === "7"} onClick={this.toggle("7")} role="tab" >
																	<MDBRow>
																			<span className="profilpage-menu">{t('Kinder_Jugendliche')}</span>
																	</MDBRow>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItem === "8"} onClick={this.toggle("8")} role="tab" >
																	<MDBRow>
																			<span className="profilpage-menu">MTB</span>
																	</MDBRow>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItem === "9"} onClick={this.toggle("9")} role="tab" >
																	<MDBRow>
																			<span className="profilpage-menu">{t('Rennrader')}</span>
																	</MDBRow>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItem === "10"} onClick={this.toggle("10")} role="tab" >
																	<MDBRow>
																			<span className="profilpage-menu">Trekking</span>
																	</MDBRow>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItem === "11"} onClick={this.toggle("11")} role="tab" >
																	<MDBRow>
																			<span className="profilpage-menu">E-Scooter</span>
																	</MDBRow>
																</MDBNavLink>
															</MDBNavItem>*/}
															{categoryTabs}
														</MDBNav>
													</MDBRow>
												</div>
													<MDBRow center >
														<Box sx={{ width: 180 }} className="slider-filter">
												      <Typography id="track-false-slider" gutterBottom>
												        {t('preis')}
												      </Typography>
															<Slider
												        getAriaLabel={() => 'Price range'}
												        value={this.state.value}
												        onChange={this.handleChange}
																defaultValue={[0, 20000]}
																min={0}
															  step={100}
															  max={20000}
												        marks={marks}
												      />
															<span className="min-euro">min €</span>
															<span className="max-euro">max €</span>
														</Box>
													</MDBRow>
													<MDBRow>
														<MDBCol>
															<span className="standort-text">{t('Standort')}</span>
														</MDBCol>
														<MDBCol className="text-right pr-4">
															<MDBBtn flat className="m-0 p-0" onClick={!this.state.showStandortFilter ? this.handleShowStandortFilter : this.handleCloseStandortFilter}>
																<img className="standorticon" src={standorticon}/>
															</MDBBtn>
														</MDBCol>
													</MDBRow>
													<MDBRow start className="rahmen-row">
															<span className="adresse-text-umkreis">{this.state.aktuelleStandort !== "N/A" ? this.state.aktuelleStandort : t('Aktuelle_Standort')}</span><br/>
															<span className="umkreis-text">{t('Umkreis')} {this.state.valueUmkreis === 20000 ? "Unlimited" : this.state.valueUmkreis + "km"}</span>
													</MDBRow>
													<MDBRow start className="rahmen-row">
														<span className="rahmengrosse">{t('Rahmengrosse')}</span>
													</MDBRow>
													<MDBRow center className="rahmengroese-filter-row">
													<div className="rahmengroese-filter">
														<MDBNav className="flex-row rahmengroese-nav">
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItemS} onClick={this.toggle2("1")} role="tab" >
																	<span className="profilpage-menu">S</span>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItemM} onClick={this.toggle2("2")} role="tab" >
																	<span className="profilpage-menu">M</span>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItemL} onClick={this.toggle2("3")} role="tab" >
																	<span className="profilpage-menu">L</span>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItemXL} onClick={this.toggle2("4")} role="tab" >
																	<span className="profilpage-menu">XL</span>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItemXXL} onClick={this.toggle2("5")} role="tab" >
																	<span className="profilpage-menu">XXL</span>
																</MDBNavLink>
															</MDBNavItem>
														</MDBNav>
													</div>
													</MDBRow>
													<MDBRow start className="rahmen-row">
														<span className="rahmengrosse">{t('Lieferung')}</span>
													</MDBRow>
													<MDBRow center className="rahmengroese-filter-row">
													<div className="lieferung-filter">
														<MDBNav className="flex-row lieferung-nav">
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItemOnline} onClick={this.toggle3("1")} role="tab" >
																	<span className="profilpage-menu">Online</span>
																</MDBNavLink>
															</MDBNavItem>
															<MDBNavItem>
																<MDBNavLink link to="#" active={this.state.activeItemSelbst} onClick={this.toggle3("2")} role="tab" >
																	<span className="profilpage-menu">{t('Im_Laden')}</span>
																</MDBNavLink>
															</MDBNavItem>
														</MDBNav>
													</div>
													</MDBRow>
												</Scrollbar>
												</MDBCardBody>
											</MDBCard>
											<div style={{width: "100%", marginLeft: "-5px"}}>
											<Footer/>
											</div>
										</div>
									</MDBCol>
									<MDBCol md="9" size="12" lg="8">
									<div className="sticky-card-borse">
									<div className={this.state.navbarPaddingTop ? "marktnews-nav-container-borse fixed-top-borse" : "marktnews-nav-container-scroll-borse fixed-top-borse"}>
											{!this.state.navbarPaddingTop ?
												<MDBBtn flat className="arrow-up-btn-borse" onClick={this.onRefStart}>
													<img alt="" className="arrow-up" src={arrowUp2}></img>
												</MDBBtn>:false}
									</div>
									</div>
										<MDBRow className="m-0">
											<MDBCol middle md="4" className="pl-0 pr-1">
												<div className="form-outline-markt pb-2 pl-2">
												<label className="form-label">{t('Sortieren_nach')}</label>
												<MDBSelect
													className="form-control"
													getTextContent={this.onSetSortedTyp}
								          selected={t('Sortierung_nach_Datum')}
								          options={this.state.options}
								        />
												</div>
											</MDBCol>
											<MDBCol middle md="2" className="pl-1 pr-1">
											<div className="info-tooltip">
												<MDBTooltip placement="right" active>
													<MDBBtn flat className="m-0 pl-0 pr-4 info-tooltip-btn"><MDBIcon icon="info-circle" /></MDBBtn>
													<div>
															<span className="tooltip-title">{t('Unsere_Standard_Sortierung')}</span><br/>
															<span className="tooltip-text">{t('Mit_der_Standard_Sortierung')}</span>
													</div>
												</MDBTooltip>
											</div>
											</MDBCol>
										</MDBRow>
										<MDBRow className="m-0">
											{this.state.bikeslist.length > 0 ? <>
													{bikeList}</>:false}
										</MDBRow>
									</MDBCol>
								</MDBRow>
							</MDBContainer>

				</div>
				{ this.state.eintargstyp && this.state.kategorie !== "" ?
						<Redirect to={{
											pathname: "/EintragsTyp",
											state: { kategorie: this.state.kategorie }
						}}
					/>:
 				false}
				{this.state.showStandortFilter ?
				<FilterByStandort
				show= {this.state.showStandortFilter}
				handleClose = {this.handleCloseStandortFilter}
				valueUmkreis = {this.state.valueUmkreis}
				handleChangeUmkreis = {this.handleChangeUmkreis}
				onGetStandort = {this.onGetStandort}
				userData = {this.props.userData}
				latitude = {this.state.latitude}
				longitude = {this.state.longitude}
				adresse = {this.state.adresse}
				sortedTyp = {this.state.sortedTyp}
				aktuelleStandort = {this.state.aktuelleStandort}
				onGetBikes = {this.onGetBikes}
				onGetUmkreis = {this.onGetUmkreis}
				bikeAnzahl = {bikeList.length}
				onGetLatLng = {this.onGetLatLng}
				/>:false}
		</>
    );
  }
}

export default  withTranslation()(BoersePageDesktop);
