import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import 'moment/locale/de';
import logo from "../../assets/media/logo/1.png";
import { UserStatus } from "../../Config/Config";
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import clock from "../../assets/media/newicons/clock.png";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import {
	MDBRow,
	MDBCol,
	MDBBtn,
	MDBCollapse,
	MDBView,
	MDBMask,
	MDBContainer,
	MDBIcon,
	MDBListGroupItem,
	MDBTooltip,
	MDBCard,
	MDBCardTitle,
	MDBCardBody,
	MDBBadge
} from 'mdbreact';

const cookies = new Cookies();

class CompanysItemToMainPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
		      open: false,
					isOpen: false,
					profileImageHash: Date.now(),
					buttonName: "Read More",
					readState: 'read-less',
					token : cookies.get('authtoken').token,
			    myUserId: cookies.get('authtoken').userId,
					companyId: cookies.get('authtoken').currentCompanyId,
					companyPhotos: [],
					iconName: "angle-double-right",
					like: 0,
					comment: 0,
					q: -1,
					buysLikeCompanies: [],
					sellsLikeCompanies: [],
					sellsCommentList: [],
					buysCommentList: []
		    };
  };

	componentDidMount() {
		if(this.props.msg.offerPhotosUrl !== undefined && this.props.msg.offerPhotosUrl.length > 0){
			this.loadImage(this.props.msg.offerPhotosUrl[0]);
		}else{
			if(this.props.msg.videoLink !== undefined && this.props.msg.videoLink !== ''){
				this.setState({q: 16/9});
			}
		}

	};
  handleClick = () =>{
    this.setState( (prevState: { open: any; }) => ({
      open: !prevState.open
    }))
  }

	handleReadmore = (id) =>{
	const newClassName = this.state.readState === 'read-less' ? 'read' : 'read-less';
	this.setState({readState: newClassName});
	if(newClassName === 'read'){
		this.setState({buttonName: "Read",
									 iconName: "angle-double-left"});
	}else{
		this.setState({buttonName: "Read More",
									 iconName: "angle-double-right"});
	}
	}

	onIsOpen = () => {
	  this.setState({isOpen: !this.state.isOpen});
	}

	loadImage = imagesrc => {
		const img = new Image();
		let self = this;
		img.onload = function() {
			//alert(this.width + 'x' + this.height);
			self.setState({q: this.width / this.height})
		}
		img.src = imagesrc;
	}

	truncateString = (str, num) => {
		if (str.length <= num) {
			return str
  		}
  			return str.slice(0, num) + '...'
	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
		const {open} = this.state
		var images = [];
		var imagesplus = 0;
		var i=1;
		if (this.props.msg.offerPhotosUrl.length>0){
			for (const companyPhoto of this.props.msg.offerPhotosUrl){
					images.push({	src: companyPhoto });

				i++;
			}
			imagesplus = (i - 2);
		}
		let imagewidth = 900*this.state.q/(9*this.state.q+16);
		let videowidth = (100-imagewidth);
		let imagepercent = imagewidth+'%';
		let videopercent = videowidth+'%';

		let text ="";
		if (this.props.msg.article !== ''){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.props.msg.article, 'text/html');
			text =parsedHtml.documentElement.textContent;
		}
		let prevDate = Moment(Date.now());
		let prevDate1 = Moment(Date.now()).subtract(1, 'days');
		let prevDate2 = Moment(Date.now()).subtract(2, 'days');
		let tsmoment = Moment(this.props.msg.time);
    return (
<MDBListGroupItem style={{paddingTop: "0.15rem", paddingBottom: "0.15rem"}}>
	<MDBRow className="py-2">
		<MDBCol size="auto" className="text-center">
			{this.props.msg.companyId !==undefined?
				<MDBView hover zoom>
						<img
								src={this.props.msg.profileUrl +'?' + Date.now()}
								className="photo rounded"
								alt=""
						/>
				</MDBView>
			:false}
		</MDBCol>
		<MDBCol size="9" md="6" lg="8" xl="8">
			<MDBRow>
				<MDBCol size="7" md="12" lg="7" xl="auto">
				{this.props.msg.relationshipType ==="SELLS" ?
				<>
					{this.props.msg.product === undefined ?
						<small className="font-weight-bolder">{t('angebot_an')}'{this.props.productName}'</small> :
						<small className="font-weight-bolder">{t('angebot_an')}'{this.props.msg.product[0]}'</small>}
				<br/></>:
				<>
					{this.props.msg.product === undefined ?
						<small className="font-weight-bolder">{t('suche_nach')}'{this.props.productName}'</small> :
						<small className="font-weight-bolder">{t('suche_nach')}'{this.props.msg.product[0]}'</small>}
				<br/></>}

				<small className="history-text">{this.props.msg.companyName}</small>

				</MDBCol>
				<MDBCol size="5" md="12" lg="5" xl="auto" className="text-right">
			 		<small className="history-text">{tsmoment.locale(this.props.i18n.language).format('DD-MM-YYYY')}</small><br/>
			 		<img src={clock}  className="pr-2"/><a className="history-text">{tsmoment.format('h:mm a')}</a>
			 	</MDBCol>
		  	</MDBRow>
		</MDBCol>
	</MDBRow>
</MDBListGroupItem>
    );
  }
}

export default withTranslation()(CompanysItemToMainPage);
