import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import logo_first from "../../assets/media/images/markt/profil/Group 7.png";
import logo from "../../assets/media/images/markt/E-Bikebörse Logo 1.png";
import addBike from "../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import anmelden from "../../assets/media/images/markt/anmelden.png";
import searchnormalicon from "../../assets/media/images/markt/searchnormalicon.png";
import arrowUp from "../../assets/media/images/markt/arrowUp2.png";
import logo2 from "../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import logo3 from "../../assets/media/images/markt/Group 18.png";
import logo4 from "../../assets/media/images/markt/Group 19.png";
import logo5 from "../../assets/media/images/markt/Group 20.png";
import profile_Boda from "../../assets/media/images/b2b/profile_Boda.jpg";
import group_3 from "../../assets/media/images/b2b/Group 3.png";
import group_4 from "../../assets/media/images/b2b/Group 4.png";
import group_5 from "../../assets/media/images/b2b/Group 5.png";
import group_6 from "../../assets/media/images/b2b/Group 6.png";
import group_7 from "../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../assets/media/images/b2b/Group 8.png";
import group_21 from "../../assets/media/images/markt/Group 21.png";
import rectangle_1 from "../../assets/media/images/b2b/Rectangle-1.png";
import rectangle_2 from "../../assets/media/images/b2b/Rectangle-2.png";
import rectangle_3 from "../../assets/media/images/b2b/Rectangle-3.png";
import e_Bikeborse from "../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../assets/media/images/b2b/Interb2b.png";

import InterB2BAxios from '../../Service/InterB2BAxios';
import FooterMarktLanding from '../../components/Footer/FooterMarktLanding';
import GyikModul from "../../components/Gyik/GyikModul";
import Interb2bNewsItems from '../../components/News/Interb2bNewsItems';
import Interb2bNewsItemsFirst from '../../components/News/Interb2bNewsItemsFirst';
import MitteilungenItem from '../../components/Companys/MitteilungenItem';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../components/LazyLoad/LazyLoadItem';
import SonstigeNav from '../../components/NavbarComponent/SonstigeNav';
import BoersePageNavRespo from '../../components/NavbarComponent/BoersePageNavRespo';
import MenuSideNavBoerse from './BoersePage/MenuSideNavBoerse';

import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';
import { Scrollbar } from "react-scrollbars-custom";
const Parser = require('rss-parser');
const parser = new Parser();
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBSideNav
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
let urlName = '';
class NewsPage extends Component {

	constructor(props) {
    super(props);
		document.documentElement.style.setProperty('--scroll-y', `0px`);
		this.listInnerRefStart = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'NewsPage');
    this.state = {navbarPaddingTop: true,
									news:{}
									};
  };

	componentDidMount() {
			this.onRefStart();
			var params = window.location.href.split(".");
	    language = params[params.length - 1];
			var params2 = window.location.href.split("/");
			urlName = params2[params2.length - 1];
			document.addEventListener("scroll", this.scrollEventListener);
			if(this.props.userData.isLoggedInMarkt()){
				this.onGetMitteilungen();
			}else{
				this.onGetMitteilungenWithoutLogin();
			}
			this.onNews();
	}

	componentWillUnmount() {
		this.interAxios.cancel();
		document.removeEventListener("scroll", this.scrollEventListener);
	}

	onGetMitteilungen = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungen', function(response) {
			self.setState({mitteilungenlist: response.data});
			forceVisible();
		})
	};

	onGetMitteilungenWithoutLogin = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungenWithoutLogin', function(response) {
			self.setState({mitteilungenlist: response.data});
			forceVisible();
		})
	};

	onMoreGetMitteilungen = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungen', {params: {before: this.state.mitteilungenlist[this.state.mitteilungenlist.length-1].time}}, function(response) {
			let mitteilungenlist = self.state.mitteilungenlist;
			mitteilungenlist.push(...response.data);
			self.setState({mitteilungenlist: mitteilungenlist});
		});
	}

	onMoreGetMitteilungenWithoutLogin = () => {
		let self = this;
		this.interAxios.get('/api/mitteilungenWithoutLogin', {params: {before: this.state.mitteilungenlist[this.state.mitteilungenlist.length-1].time}}, function(response) {
			let mitteilungenlist = self.state.mitteilungenlist;
			mitteilungenlist.push(...response.data);
			self.setState({mitteilungenlist: mitteilungenlist});
		});
	}

	onNews = () => {
    let self = this;
    this.interAxios.get('/api/rss', function(response)
    {
      let promise = parser.parseString(response.data)

      promise.then((value) => {
        self.setState({news: value.items});
      });
    });
  };

	scrollEventListener = (event) => {
		const navPadding = window.scrollY < 50 ? true : false;
		this.setState({ navbarPaddingTop: navPadding});
	}

	onRefStart = () => {
		this.listInnerRefStart.current.scrollIntoView({
	 });
	}

	onMenuOpen = () => {
		this.setState({
			isMenuOpen: true
		});
	}

	onMenuClose = () => {
		this.setState({
			isMenuOpen: false
		});
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<>
			<div ref={this.listInnerRefStart}></div>
			<div className="d-none d-lg-block">
				<SonstigeNav  userData={this.props.userData}/>
			</div>
			<div className="d-lg-none d-block">
				<BoersePageNavRespo  userData={this.props.userData} onMenuOpen = {this.props.handleSideNavToggle}/>
			</div>
				<div className={styles.bodywrapper}>
						<div className="d-lg-none d-block">
							<div className="side-nav-menu">
								<MDBSideNav
									right
									triggerOpening={this.props.isSideNavOpen}
									onOverlayClick={this.props.handleSideNavToggle}
									breakWidth={1300}
									className="deep-purple darken-4"

								>
									<MenuSideNavBoerse
															userData={this.props.userData}
															toggle = {this.toggle}
															onMenuClose = {this.props.onEnableScroll}
															activeItem = {this.state.activeItem}
									/>
								</MDBSideNav>
							</div>
						</div>
						<section className="section landingmarkt-midle1">
						<div className={this.state.navbarPaddingTop ? "marktnews-nav-container fixed-top-marktnews" : "marktnews-nav-container-scroll fixed-top-marktnews"}>
							<MDBRow center className="padding-row-logo6 m-0 pb-2">
							<img alt="" className="e-bikeborse-logo-first" id="e-bikeborse-logo3" src={logo_first}></img>
							<span className="landingpage-text" id="e-bikeborse-logo3-text">{t('news')}</span>
								{!this.state.navbarPaddingTop ?
									<MDBBtn flat className="arrow-up-btn" onClick={this.onRefStart}>
										<img alt="" className="arrow-up" src={arrowUp}></img>
									</MDBBtn>:false}
							</MDBRow>
						</div>
							<MDBContainer>
								<MDBRow center className="padding-row-logo5 mx-0">
								{this.state.news.length > 0 ?
										<>
										{this.state.news.filter((item, index) => index < 1).map(
										(o, index) =>
														<Interb2bNewsItemsFirst
														 key={index}
														 msg={o}/>

									)}
								</>:false}
								{this.state.news.length > 0 ?
										<>
										{this.state.news.filter((item, index) => index > 0).map(
										(o, index) =>
														<Interb2bNewsItems
														 key={index}
														 msg={o}/>

									)}
								</>:false}
								</MDBRow>
							</MDBContainer>
						</section>

						<div className="rectangle-footer-markt d-none d-lg-block">
							<MDBContainer style={{maxWidth: "1250px"}}>
								<FooterMarktLanding/>
							</MDBContainer>
						</div>
				</div>
				{ this.props.userData.boersepage ?
					 <Redirect to={{
										 pathname: "/BoersePage",
										 state: { activeItem: this.state.activeItemKategorie, bikeKategorie: this.state.bikeKategorie }
					 }}
				 />:
				 false}
		</>
    );
  }
}

export default  withTranslation()(NewsPage);
