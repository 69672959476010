import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ProductPageDesktop from './ProductPage/ProductPageDesktop';
import ProductPageResponziv from './ProductPage/ProductPageResponziv';

class ProductPage extends Component {


	constructor(props) {
    super(props);
    this.state = {textFeld: this.props.location?.state?.textFeld !== undefined ? this.props.location.state.textFeld : false,
									textSearch2: this.props.userData.productname,
									activeItem: this.props.location?.state?.activeItem!== undefined ? this.props.location.state.activeItem : "",
									};
  };


	componentDidMount() {

	}

	componentWillUnmount() {
		this.setState({textSearch2: ""});
	}


	render() {
    return (
		<>{!window.matchMedia('(max-device-width: 992px)').matches ?
			<div className="d-none d-lg-block">
				<ProductPageDesktop userData={this.props.userData}
																 addFunctionFromChild = {this.props.addFunctionFromChild}
																 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																 textFeld = {this.state.textFeld}
																 textSearch2 = {this.state.textSearch2}
																 activeItem = {this.state.activeItem}
																 {...this.props}
																 />
			</div>:
			<div className="d-lg-none d-block">
				<ProductPageResponziv userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 textFeld = {this.state.textFeld}
																	 textSearch2 = {this.state.textSearch2}
																	 activeItem = {this.state.activeItem}
																	 {...this.props}
																	 />
			</div>}
		</>
    );
  }
}

export default  ProductPage;
