import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import styles from '../../assets/scss/modules/landing.module.scss';
import arrowWhite_down from "../../assets/media/images/markt/arrowDown.png";
import arrowWhite_up from "../../assets/media/images/markt/arrowUp.png";

import{
	MDBRow,
	MDBCol,
  MDBCollapseHeader,
	MDBCollapse,
} from 'mdbreact';

class GyikModul extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
			<MDBRow center className="py-2 m-0">
								<div className="faq-border">
        <MDBCollapseHeader onClick={this.props.toggleCollapse("collapse" + this.props.msg.id)} id="collapse-header">
          <MDBRow>
            <MDBCol md="10" size="10" middle className="text-left">
              <span className="collapse-titel">{this.props.msg.kerdes}</span>
            </MDBCol>
            <MDBCol md="2" size="2" middle  className="text-right">
              <img src={this.props.collapseID ==="collapse" + this.props.msg.id ? arrowWhite_up : arrowWhite_down } className="arrow-white"/>
            </MDBCol>
          </MDBRow>
        </MDBCollapseHeader>
        <MDBCollapse id={"collapse" + this.props.msg.id} isOpen={this.props.collapseID ==="collapse" + this.props.msg.id ? true : false}>
          <MDBRow className="collapse-text-row">
            <MDBCol md="12" size="12" middle className="text-left">
              <span className="collapse-text">
              {this.props.msg.valasz}</span>
            </MDBCol>
          </MDBRow>
        </MDBCollapse>
				</div>
			</MDBRow>
    );
  }
}
export default  withTranslation()(GyikModul);
