import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Redirect, Link } from 'react-router-dom';
import styles from '../../assets/scss/modules/landingmarkt.module.scss';
import group_21 from "../../assets/media/images/markt/Group 21.png";
import group_22 from "../../assets/media/images/b2b/E-Bikebörse Logo Neu.png";
import{
  MDBNavItem,
  MDBNavLink,
  MDBBtn
} from 'mdbreact';

class BikePartsLinkNavItem extends React.Component {

  render() {
    if (this.props.userData.getInterB2Bike() && this.props.userData.partner() !== 'Markt' && this.props.userData.partner() !== 'Supplier'
        || this.props.userData.partner() === 'Dealer') {
      let innerDiv = <div className="navlink-search2 text-right">
      <MDBBtn
            flat
            onClick={() => this.props.userData.onLogout()}
            className="p-0 m-0"
          >
            <img alt="" className="group-21-logo" src={group_21}></img>
          </MDBBtn>

      </div>;

      let link = '';
      if (this.props.userData.isRemoteBoersePage()) {
        link = <a href={"https://www.interb2bike.com" + this.props.userData.getBaseName()} className="nav-link Ripple-parent">{innerDiv}</a>;
      } else {
        link = <MDBNavLink link to="/LandingPageB2Bike" role="tab" >{innerDiv}</MDBNavLink>;
      }

      return (<MDBNavItem>
        {link}
      </MDBNavItem>);
    } else {
      return <></>;
    }
  }

}

export default BikePartsLinkNavItem;
