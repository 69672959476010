import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Moment from 'moment';
import Flags from 'country-flag-icons/react/3x2'
import Interb2bNewsItemsFirst from '../../../../components/News/Interb2bNewsItemsFirst';
import ChatsMainBikeB2B from '../../../../components/ChatBikeB2B/ChatsMainBikeB2B';
import AdvertBoxMain from '../../../../components/Adverts/AdvertBoxMain';
import AdvertBoxMobile from '../../../../components/Adverts/AdvertBoxMobile';
import Partners from '../../../../components/Adverts/Partners';
import FeedbackCardCompany from '../../../../components/RatingPage/FeedbackCardCompany';
import FooterB2BMainPage from '../../../../components/Footer/FooterB2BMainPage';
import SonstigeNavB2B from '../../../../components/NavbarComponentB2B/SonstigeNavB2B';
import InterB2BAxios from '../../../../Service/InterB2BAxios';
import ReactFullpage from '@fullpage/react-fullpage';
import { Scrollbar } from "react-scrollbars-custom";
import { Redirect, Link } from 'react-router-dom';
import logo from "../../../../assets/media/logo/1.png";
import ratingicon from "../../../../assets/media/newicons/rating.png";
import styles from '../../../../assets/scss/modules/landingmarkt.module.scss';
import searchnormalicon from "../../../../assets/media/images/markt/searchnormalicon.png";
import group_21 from "../../../../assets/media/images/markt/Group 21.png";
import addBike from "../../../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import logo2 from "../../../../assets/media/images/b2b/Group 19.png";
import anmelden from "../../../../assets/media/images/markt/anmelden.png";
import signinmarkt from "../../../../assets/media/images/markt/signinmarkt.png";
import signupmarkt from "../../../../assets/media/images/markt/signupmarkt.png";
import logo3 from "../../../../assets/media/images/b2b/news.png";
import logo5 from "../../../../assets/media/images/b2b/b2b-contact.png";
import meinprofilicon from '../../../../assets/media/newicons/meinprofil.png';
import clearOffericon from "../../../../assets/media/newicons/clearOffer.png";
import arrowLeft from "../../../../assets/media/images/arrowLeft.png";
import appentum from "../../../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../../../assets/media/images/b2b/Interb2b.png";
import e_Bikeborse from "../../../../assets/media/images/b2b/E-Bikebörse.png";
import profile_Boda from "../../../../assets/media/images/b2b/profile_Boda.jpg";
import rectangle_2 from "../../../../assets/media/images/b2b/Rectangle-1.png";
import animation from "../../../../assets/media/videos/animation.mp4";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardTitle,
	MDBCardBody,
	MDBCardFooter,
	MDBScrollbar,
	MDBIcon,
	MDBTabPane,
	MDBTabContent,
	MDBNav,
	MDBNavItem,
	MDBNavLink,
	MDBBtn,
	MDBMedia,
	MDBView,
	MDBCollapse,
	MDBInput,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle,
	MDBAutocomplete
} from 'mdbreact';
const Parser = require('rss-parser');
const parser = new Parser();

class MainPageHandlerDesktop extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'MainPageHandler');
    this.state = {werbungStatus: false,
									timeStamp: Date.now(),
									news:{},
									timeStamp: Date.now(),
									user: {},
									textSearch: '',
									textFeld: false,
									redirect: false,
									};
  };

  componentDidMount() {
		//this.props.userData.refresh();
		this.props.userData.onSearchInactive();
		this.onNews();
  };

	componentWillUnmount() {
		this.interAxios.cancel();
  }

	onNews = () => {
    let self = this;
    this.interAxios.get('/api/rss', function(response)
    {
      let promise = parser.parseString(response.data)

      promise.then((value) => {
        self.setState({news: value.items});
      });
    });
  }


	onTextSearchChange = event => {
     this.setState({ [event.target.name]: event.target.value });
   };

	onSearchProduct = () => {
		this.props.userData.onSearchProductCount(this.state.textSearch);
		this.onTextFalse();
		this.setState({redirect: true});
	}

	onTextTrue = () => {
		this.setState({textFeld: !this.state.textFeld});
	}

	onTextFalse = () => {
		this.setState({textFeld: false, textSearch: ""});
	}

	onKey = (ev) => {
		ev.preventDefault();
		if(this.state.textSearch !== "" ){
			this.onSearchProduct()
		}else{
			 this.onTextTrue();
		}
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		const anchors = ["InterB2Bike", "Newsrss", "Contacts"];
    return (
			<>
				<SonstigeNavB2B userData={this.props.userData}/>
				<ReactFullpage
					licenseKey = {'2C01A6F9-2F35483E-81C14CF4-C7FF7A52'}
					scrollingSpeed = {500}
					anchors={anchors}
					menu='#myMenu'
					navigation={true}
					navigationPosition={'right'}
					showActiveTooltip= {true}
					responsiveWidth={1350}
					responsiveHeight={680}

					render={({ state, fullpageApi }) => {
					return (
						<>
							<ReactFullpage.Wrapper>
				<div className={styles.bodywrapper}>
						<div ref={this.listInnerRefInterB2B}/>
						<section className="section landingmarkt-top">
							<div style={{ position: "absolute", left: 50, top: 120, width: 480, height: 270, zIndex: 100}}>
								<video width="480" height="270" controls loop autoPlay muted>
    								<source src={animation} type="video/mp4" />
    								Your browser does not support the video tag.
  								</video>
							</div>
							<MDBRow center className="padding-row-logo3 m-0">
								<img alt="" className="bike-parts-logo-main" src={logo2}></img>
							</MDBRow>
							<MDBRow center className="pt-3 m-0">
								<div className="product-search-b2b">
									<form className= ""
												>
										<MDBRow center className="pt-3 m-0">
											<MDBCol top md="10" className="main-col" middle>
									      <input
													onKeyPress={(ev) => {
															if (ev.key === "Enter") {
																this.onKey(ev);
															}
													}}
									        type="text"
									        className="form-control"
									        id="formGroupExampleInput"
													placeholder={t('produkt_eingeben_3')}
													name="textSearch"
													onChange={this.onTextSearchChange}
													value={this.state.textSearch}
									      />
												{!this.state.textFeld ?
												<label className="text-left pt-3" htmlFor="formGroupExampleInput">
													<span className="main-text">{t('Finden_Sie_die_Fahrradteile_die_Sie_brauchen')}</span>
												</label>:false}
												{this.props.userData.productname === "" && this.state.textFeld ?
												<MDBContainer className="text-center">
													<MDBRow center className="m-0 pt-5">
														<MDBCol bottom className="text-right pr-0" md="4">

														<img className="arrow-icon"
																 src={arrowLeft}/>
														</MDBCol>
														<MDBCol bottom md="8" className="text-left pl-2" >
														<span className="offer-text" >{t('geben_sie_einen_suchbegriff_in_das_feld_ein')}</span>
														</MDBCol>
													</MDBRow>
												</MDBContainer>:false}
											</MDBCol>
											<MDBCol top md="2" className="pr-0 main-col" >
												<MDBBtn type="button"
																className="borse-btn-b2b"
																onClick={this.state.textSearch !== "" ? this.onSearchProduct : this.onTextTrue}
																><img src={searchnormalicon} className="vector-1"/>
												</MDBBtn>
											</MDBCol>
										</MDBRow>
									</form>
								</div>
							</MDBRow>
						</section>
						<div ref={this.listInnerRefNews}/>
						<section className="section landingmarkt-midle1">
							<MDBRow center className="padding-row-logo7 m-0">
								<img alt="" className="e-bikeborse-logo3" src={logo3}></img>
							</MDBRow>
							<MDBContainer>
								<MDBRow center className="padding-row-logo4 m-0">
									<MDBCol md="12" className="mb-3 text-center">
									<Link to="NewsPageB2B" className="icon-newspage">
									<MDBCard className="blog-card4 py-5">
									<div className="allnews-btn-b2b">
									<span className="allnews">All News</span>
									</div>
										<Scrollbar
															id="scrollbar"
															style={{ width: "100%", maxHeight: "100%"}}
															maximalThumbYSize = {100}
															noScrollX = {true}
															noScrollY = {true}
										>
										{this.state.news.length > 0 ?
												<>
												{this.state.news.filter((item, index) => index < 2).map(
												(o, index) =>
																<Interb2bNewsItemsFirst
																 b2bplattform={true}
																 key={index}
																 msg={o}/>

											)}
										</>:false}
										</Scrollbar>
										<div className="gradient2"/>
									</MDBCard>
									</Link>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
						</section>
						<div ref={this.listInnerRefContact}/>
						<section className="section landingmarkt-bottom">
							<MDBRow center className="m-0 b2b-main-contact-padding">
								<img alt="" className="rectangle-18" src={logo5}></img>
							</MDBRow>
							<MDBRow center className="m-0">
								<MDBContainer style={{maxWidth: "1250px"}}>
								<MDBRow className="b2b-main-bottom-padding">
									<MDBCol middle md="7">
										<MDBRow start className="m-0 pb-3">
											<MDBCol md="auto" middle>
												<img className="profile" src={profile_Boda}></img>
											</MDBCol>
											<MDBCol middle>
											<span className="balazs-boda-interb-2-b">balazs.boda@interb2b.com</span><br/>
											<span className="balazs-boda-interb-2-b">(Projektleitung der Produktentwicklung)</span><br/>
											</MDBCol>
										</MDBRow>
										<MDBRow start className="m-0 py-1">
											<MDBCol middle md="auto" className="m-0 pr-2">
												<img className="rectangle-1" src={rectangle_2}></img>
											</MDBCol>
											<MDBCol middle className="m-0 pl-2">
												<span className="liebe-interessenten">{t('liebe_interessenten')}{t('der_prototyp_der_von_interB2B')}</span>
											</MDBCol>
										</MDBRow>
									</MDBCol>
									<MDBCol middle md="5">
										<MDBRow end className="pb-4">
											<img className="firmen" src={interb2b}></img>
										</MDBRow>
										<MDBRow end className="py-4">
												<a 	style={{textAlign:"right"}}
														href="https://www.studicore.hu/about"
														target="_blank" rel="noopener noreferrer">
														<img className="firmen" src={studiCore}></img>
												</a>
										</MDBRow>
										<MDBRow end className="pt-4">
											<a 	style={{textAlign:"right"}}
													href="https://appentum.de"
													target="_blank" rel="noopener noreferrer">
													<img className="firmen" src={appentum}></img>
											</a>
										</MDBRow>
									</MDBCol>
								</MDBRow>
								</MDBContainer>
							</MDBRow>
							<div className="rectangle-footer-markt">
								<MDBContainer style={{maxWidth: "1250px"}}>
									<FooterB2BMainPage/>
								</MDBContainer>
							</div>
						</section>
				</div>
											</ReactFullpage.Wrapper>
								 </>
								 );
							 }}
						 />
			{this.state.redirect ?
			<Redirect
						to={{pathname: "/ProductPage", state: { textFeld: this.state.textFeld}}}/>:false}
	<div className="chatwrapper-markt d-none d-xl-block">
		 <ChatsMainBikeB2B
							 className="text-center"
							 userData = {this.props.userData}
							 addFunctionFromChild= {this.props.addFunctionFromChild}
							 removeFunctionFromChild= {this.props.removeFunctionFromChild}
		 />
	</div>
	 </>
    );
  }
}

export default withTranslation()(MainPageHandlerDesktop);
