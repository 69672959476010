import React, { Component, Fragment } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
import foto2 from "../../assets/media/fotos/2.jpg";
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import ImageManager from './ImageManager';
import MapBox from '../MapBox/MapBox';
import Moment from 'moment';
import 'moment/locale/de';
import logo from "../../assets/media/logo/1.png";

import trahsicon from "../../assets/media/newicons/trash.png";
import checkdone from "../../assets/media/newicons/check_done.png";
import arrowcirclelefticon from "../../assets/media/newicons/arrow-circle-left2.png";
import galleryicon from "../../assets/media/newicons/gallery.png";
import plusimgicon from "../../assets/media/newicons/plusImg.png";
import editicon from "../../assets/media/newicons/editProfil.png";
import logouticon from "../../assets/media/newicons/logout.png";
import ib2bicon from "../../assets/media/images/ib2b.png";
import styles from '../../assets/scss/modules/pages.module.scss';
import Footer from '../Footer/Footer';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';


import{
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardTitle,
	MDBCardHeader,
	MDBCardBody,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBScrollbar,
  MDBContainer,
	MDBNav,
	MDBNavItem,
	MDBNavLink,
	MDBFormInline,
	MDBInput
} from 'mdbreact';

import InterB2BAxios from '../../Service/InterB2BAxios';

const cookies = new Cookies();

class InvitationPage extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    this.state = {outInvitationsList: {},
									inInvitationsList: {},
              	};
  };

	componentDidMount() {
    this.props.userData.refreshNavbar();
		//this.interAxios.refreshUserData(this.props.userData);
		this.props.userData.refresh();
  };

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	onEmailChange = (event) =>{
    this.setState({email: event.target.value});
  };

  onSubmit = () =>{
    let self = this;
    this.interAxios.post('/api/invite/new/'+ this.state.email, function(response) {
    })
		this.setState({email: ""});
		self.props.userData.refresh();
  };



	onRevoke = (id) => {
		let self = this;
		this.interAxios.get('/api/invite/revoke/' + id, function(response) {
			self.props.userData.refresh();
		});
	}

	onAccept = (id) => {
		let self = this;
		this.interAxios.get('/api/invite/accept/' + id, function(response) {
			self.props.userData.refresh();
		});
	}

	onReject = (id) => {
		let self = this;
		this.interAxios.get('/api/invite/reject/' + id, function(response) {
			self.props.userData.refresh();
		});
	}

  render() {
		const { t } = this.props;
		const client = this.props.client;
		let outInvitations = {};
		if(this.props.userData.getOutgoingInvitationslist() !== undefined && this.props.userData.getOutgoingInvitationslist().length>0){
			outInvitations = this.props.userData.getOutgoingInvitationslist().map(
				(o) =>
	      <div key={o.id}>
				<MDBRow>
				<MDBCol size="12" sm="5" md="4" lg="4" xl="4" middle>
					<span className="invitations-list-text">{o.email}</span><br/>
				</MDBCol>
				<MDBCol size="6" sm="3" md="3" lg="2" xl="2" middle>
					{o.status === "ACTIVE" ? <>
					<span className="invitations-list-text text-capitalize" style={{color: "#1DC93D"}}>Active</span><br/></>:false}
					{o.status === "ACCEPTED" ? <>
					<span className="invitations-list-text text-capitalize" style={{color: "#0360EC"}}>Accepted</span><br/></>:false}
					{o.status === "REJECTED" ? <>
					<span className="invitations-list-text text-capitalize" style={{color: "#30445B"}}>Rejected</span><br/></>:false}
					{o.status === "REVOKED" ? <>
					<span className="invitations-list-text text-capitalize" style={{color: "#30445B"}}>Revoked</span><br/></>:false}
				</MDBCol>
				{o.status === "ACTIVE" ?
				<MDBCol size="6" sm="2" md="5" lg="6" xl="6"middle>
					<MDBBtn
						className="invitations-btn-revoke"
						color="transparent"
						type="button"
						onClick={() => this.onRevoke(o.id)}><span className="text-capitalize">{t('widerrufen')}</span><br/></MDBBtn>
				</MDBCol>:false}
				</MDBRow>
	      </div>
	    );
		}
		let inInvitations = {};
		if(this.props.userData.getMyInvitationslist() !== undefined && this.props.userData.getMyInvitationslist().length>0){
			inInvitations = this.props.userData.getMyInvitationslist().map(
				(o) =>
	      <div key={o.invitationId}>
				<MDBRow>
				<MDBCol size="12" sm="4" md="4" lg="4" xl="4" middle>
					<span className="invitations-list-text text-capitalize">{o.companyName}</span><br/>
				</MDBCol>
				<MDBCol size="6" sm="4" md="3" lg="2" xl="2" middle>
					<MDBBtn
						className="invitations-btn-accept"
						color="transparent"
						type="button"
						onClick={() => this.onAccept(o.invitationId)}><span className="text-capitalize">{t('annehmen')}</span><br/></MDBBtn>
				</MDBCol>
				<MDBCol size="6" sm="2" md="5" lg="6" xl="6"middle>
					<MDBBtn
					className="invitations-btn-revoke"
					color="transparent"
					type="button"
						onClick={() => this.onReject(o.invitationId)}><span className="text-capitalize">{t('ablehnen')}</span><br/></MDBBtn>
				</MDBCol>
				</MDBRow>
	      </div>
	    );
		}

    return (
			<div className={styles.bodywrapper}>
				<MDBContainer>
					<MDBRow>
						<MDBCol size="12" sm="12" md="12" lg="6" xl="6">
							<MDBRow>
							{this.props.userData.getCurrentCompany()?.name != "" ?
								<MDBCol size="5" md="5" lg="5">
									<MDBCard className="company-card current">
										<MDBRow className="row-width">
											<MDBCol middle size="5" sm="6" md="6" lg="6" xl="6" className="pr-2 pl-0">
												<img
												src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
												className="img-profile"
												alt=""
												/>
											</MDBCol>
											<MDBCol middle size="7" sm="6" md="6" lg="6" xl="6" className="pl-2 pr-0">
												<span className="card-text text-capitalize">{this.props.userData.getCurrentCompany()?.name}</span>
											</MDBCol>
										</MDBRow>
									</MDBCard>
								</MDBCol>: false}
								<MDBCol size="6" md="5" lg="5" className="pl-4 pr-0">
									<MDBRow center className="pt-3">
										<span className="invitations-title-text-verwalten">{t('einladungen_verwalten')}</span>
									</MDBRow>
									<MDBRow center>
										<img className="invitations-b2b-icon  p-0" src={ib2bicon}/>
									</MDBRow>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol size="12" md="12" lg="12">
									<span className="invitations-title-text-Welcome">{t('willkommen_auf_der_einladungsseite')}</span><br/><br/>
									{!this.props.userData.getCurrentCompany()?.companyIsWorksFor && !this.props.userData.isCurrentCompanyAnonim() ?
									<>
									<span className="invitations-normal-text">{t('sie_haben_auch_die_möglichkeit_ihre_kollegen_einzuladen')} {t('ein_mitarbeiterkonto_ermöglicht_es_den_nutzern_zu_chatten')}</span>

									</>:false}
								</MDBCol>
							</MDBRow>
						</MDBCol>

						<MDBCol md="6" size="12">
							{!this.props.userData.getCurrentCompany()?.companyIsWorksFor && !this.props.userData.isCurrentCompanyAnonim() ? <>
								<MDBRow>
									<MDBCol size="12" md="12" lg="12">
										<span className="invitations-einladund-text">{t('bitte_geben_sie_unten_die_E-Mail_des_adressenten_ein')}</span>
									</MDBCol>
								</MDBRow>
								<MDBRow className="invitations-landing-form ">
									<MDBCol size="12" md="12" lg="12">
										<MDBInput
											className="landing-input"
											label= "Email"
											type="text"
											success="right"
											value={this.state.email}
											onChange={this.onEmailChange}
											/>
									</MDBCol>
							  </MDBRow>
								<MDBRow>
									<MDBCol size="12" md="12" lg="12">
										<MDBBtn
											className="invitations-btn-send"
											color="primary"
											type="button"
											onClick={() => this.onSubmit()}>{t('senden')}</MDBBtn>
									</MDBCol>
								</MDBRow>
							</>:false}
						</MDBCol>
					</MDBRow>



					{this.props.userData.getOutgoingInvitationslist() !== undefined && this.props.userData.getOutgoingInvitationslist().length>0 ?
						<MDBCard className="invitation-card">
							<MDBCardTitle>
								<span className="invitations-title-text">{t('liste_der_gesendeten_einladungen')}</span><br/>
								<MDBRow>
									<MDBCol md="4" size="6">
										<span className="invitations-status-text">Email</span>
									</MDBCol>
									<MDBCol md="2" size="2">
										<span className="invitations-status-text">Status</span>
									</MDBCol>
									<MDBCol md="6" size="2">
									</MDBCol>
								</MDBRow>
							</MDBCardTitle>
							<MDBCardBody>
								{outInvitations}
							</MDBCardBody>
						</MDBCard>
					:false}

					{this.props.userData.getMyInvitationslist() !== undefined && this.props.userData.getMyInvitationslist().length>0 ?
					<>
					<MDBRow>
						<MDBCol size="6" md="6" lg="6">
							<span className="invitations-normal-text-small">{t('sie_wurden_von_ihren_kollegen_eingeladen')}</span>
							<span className="invitations-normal-text-small-2">{t('mit_einem_mitarbeiterkonto_können_sie_chatten')}</span>
						</MDBCol>
					</MDBRow>
					<MDBCard className="invitation-card">
						<MDBCardTitle>
					<span className="invitations-title-text">{t('liste_der_eingehenden_einladungen')}</span><br/>
					<MDBRow>
						<MDBCol md="4" size="6">
							<span className="invitations-status-text">{t('firmenname')}</span>
						</MDBCol>
						<MDBCol md="2" size="2">

						</MDBCol>
						<MDBCol md="6" size="2">
						</MDBCol>
					</MDBRow>
					</MDBCardTitle>
					<MDBCardBody>
						{inInvitations}
					</MDBCardBody>
					</MDBCard>
					</>:false}
					<div className="pt-5">
						<Footer homeStatus={false}/>
					</div>
				</MDBContainer>
			</div>
    );
  }
}

export default withTranslation()(InvitationPage);
