import React, { Component } from "react";
import MyProfilePageB2BDesktop from './MyProfilePageB2B/MyProfilePageB2BDesktop';
import MyProfilePageB2BResponziv from './MyProfilePageB2B/MyProfilePageB2BResponziv';

class MyProfilePageB2B extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  };


  render() {
    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<MyProfilePageB2BDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<MyProfilePageB2BResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 {...this.props}
																		 />
				</div>}
	 		</>
    );
  }
}

export default MyProfilePageB2B;
