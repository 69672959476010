import React, { useState, useEffect } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { ChatFilterOptions } from "../../Config/Config.js";

import { ReactComponent as SearchSvg } from "../../assets/media/icons/search.svg";

import{
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBAutocomplete,
	MDBBtn,
	MDBRow,
	MDBCol
} from 'mdbreact';

const ChatFilter = (props) => {
  const [selectedTitle, setSelectedTitle] = useState("");

  useEffect(() => {
    switch (props.selectedOption) {
      case ChatFilterOptions.AllChats:
        setSelectedTitle("Alle Chats");
        break;
      case ChatFilterOptions.Friends:
        setSelectedTitle("Partner");
        break;
      case ChatFilterOptions.Groups:
        setSelectedTitle("Gruppen");
        break;
      default:
        break;
    }
    return () => {};
  }, [props.selectedOption]);

  const handleChange = async (eventKey) => {;
    await props.filterOptionChanged(parseInt(eventKey));
  };

	const { t } = props;
	const client = props.client;

  return (
<>
	<MDBAutocomplete
		data={props.roomNameList}
		placeholder={t('name_suchen')}
		clear
		id="chat-filter-input"
		getValue={props.handleSearch}
		value={props.search}
	/>

		</>
  );
};
export default withTranslation()(ChatFilter);
