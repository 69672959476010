import React, { Component } from "react";
import { MDBContainer,
         MDBBtn,
         MDBModal,
         MDBModalBody,
         MDBModalHeader,
         MDBModalFooter,
         MDBInput,
         MDBRow,

      } from 'mdbreact';
import Cookies from 'universal-cookie';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import InterB2BAxios from '../../Service/InterB2BAxios';
import { Scrollbar } from "react-scrollbars-custom";
const cookies = new Cookies();


class SortierungFilter extends Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {radio: 1,
                };
  };

  componentDidMount() {

  };

  componentWillUnmount() {
    this.interAxios.cancel();
  }

  handleClose = () => {
    this.props.handleClose();
    this.setState({show: false});
  }

  handleShow = () => {
    this.setState({show: true});
  }

  onClick = (nr) => () => {
    /*this.setState({
      radio: nr,
    });*/
    this.props.onGetsortedTyp(nr);
    this.props.handleClose();
  };


  render() {

    const { t } = this.props;
		const client = this.props.client;

    return (
<MDBContainer>
  <div className="soriterung-filter-modal">
  <MDBModal
         isOpen={this.props.show}
         toggle={this.handleClose}
         position="bottom-center">
    <MDBModalHeader toggle={this.handleClose}>
      <span className="filtercard-title-umkreis">{t('Sortieren_nach')}</span>
    </MDBModalHeader>
    <MDBModalBody className="pt-0 pb-3">
      <MDBInput
        onClick={this.onClick(1)}
        checked={this.props.radio === 1 ? true : false}
        label={t('Sortierung_nach_Datum')}
        type="radio"
        id="radio1"
      />
      <MDBInput
        onClick={this.onClick(2)}
        checked={this.props.radio === 2 ? true : false}
        label={t('Preis_aufsteigend')}
        type="radio"
        id="radio2"
      />
      <MDBInput
        onClick={this.onClick(3)}
        checked={this.props.radio === 3 ? true : false}
        label={t('Preis_absteigend')}
        type="radio"
        id="radio3"
      />
    </MDBModalBody>

  </MDBModal>
  </div>
</MDBContainer>
    );
  }
}
export default withTranslation()(SortierungFilter);
