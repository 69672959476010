import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Moment from 'moment';
import Newsfeed from '../../../../components/News/Newsfeed';
import Footer from '../../../../components/Footer/Footer';
import FooterHomePage from '../../../../components/Footer/FooterHomePage';
import Interb2bNews from '../../../../components/News/Interb2bNews';
import Companies from '../../../../components/Companys/Companies';
import ChatsMain from '../../../../components/Chat/ChatsMain';
import AdvertBoxMain from '../../../../components/Adverts/AdvertBoxMain';
import AdvertBoxMobile from '../../../../components/Adverts/AdvertBoxMobile';
import Partners from '../../../../components/Adverts/Partners';
import FeedbackCardCompany from '../../../../components/RatingPage/FeedbackCardCompany';
import MenuSideNavB2BikeMain from '../MainPageHandler/MenuSideNavB2BikeMain';
import LandingPageB2BikeNavRespo2 from '../../../../components/NavbarComponentB2B/LandingPageB2BikeNavRespo2';
import SonstigeNavB2B from '../../../../components/NavbarComponentB2B/SonstigeNavB2B';
import InterB2BAxios from '../../../../Service/InterB2BAxios';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../../components/LazyLoad/LazyLoadItem';
import { Scrollbar } from "react-scrollbars-custom";
import { Redirect, Link } from 'react-router-dom';
import styles from '../../../../assets/scss/modules/pages.module.scss';
import logo from "../../../../assets/media/logo/1.png";
import ratingicon from "../../../../assets/media/newicons/rating.png";
import searchnormalicon from "../../../../assets/media/images/markt/searchnormalicon.png";
import group_21 from "../../../../assets/media/images/markt/Group 21.png";
import addBike from "../../../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import logo2 from "../../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import anmelden from "../../../../assets/media/images/markt/anmelden.png";
import signinmarkt from "../../../../assets/media/images/markt/signinmarkt.png";
import signupmarkt from "../../../../assets/media/images/markt/signupmarkt.png";
import logo3 from "../../../../assets/media/images/markt/Group 18.png";
import meinprofilicon from '../../../../assets/media/newicons/meinprofil.png';
import clearOffericon from "../../../../assets/media/newicons/clearOffer.png";
import arrowLeft from "../../../../assets/media/images/arrowLeft.png";
import companyicon from "../../../../assets/media/images/b2b/Group 28.png";
import companyicon_gelb from "../../../../assets/media/images/b2b/Group 29.png";
import noImage from "../../../../assets/media/images/noImage_11.png";
import back_icon from "../../../../assets/media/images/b2b/Triangle.png";
import MyProfileB2BPublic from '../../../../components/MyProfile/MyProfileB2BPublic';
import Cookies from 'universal-cookie';

const cookies = new Cookies();


import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardTitle,
	MDBCardBody,
	MDBCardFooter,
	MDBScrollbar,
	MDBIcon,
	MDBTabPane,
	MDBTabContent,
	MDBNav,
	MDBNavItem,
	MDBNavLink,
	MDBBtn,
	MDBMedia,
	MDBView,
	MDBCollapse,
	MDBInput,
	MDBSpinner,
	MDBSideNav
} from 'mdbreact';
class CompanyPageWithProductResponziv extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'CompanyPageWithProduct');
    this.state = {currentCompany: {},
									isOpenFalse: false,
									textSearch2: this.props.userData.productname,
									redirect: false,
									msg: this.props.msg,
									activeItem: this.props.activeItem,
								};
  };

  componentDidMount() {
		this.props.userData.refreshNavbar();
		var params = window.location.href.split("/");
		let companyId = params[params.length - 1];
		this.onFindCompanyById(companyId);
  };

	componentWillUnmount() {
		this.interAxios.cancel();
  }

	onFindCompanyById = (id) => {
		let self = this;
		this.interAxios.get('/api/findCompanyById/' + id, function(response) {
			self.setState({currentCompany: response.data});

		});
	}

	onTextSearchChange = event => {
		 this.setState({ [event.target.name]: event.target.value });
	 };

	onSearchProduct = () => {
		this.setState({activeItem: ""});
		this.props.userData.onSearchInactive();
		this.props.userData.onSearchProductCount(this.state.textSearch2);
		this.onTextFalse();
	}

	onSearchFullProduct = () => {
		this.setState({activeItem: ""});
		this.props.userData.onSearchProductFull(this.props.userData.productname);
	}

	onTextTrue = () => {
		this.setState({textFeld: !this.state.textFeld});
	}

	onTextFalse = () => {
		this.setState({textFeld: false});
	}


	onKey = (ev) => {
		ev.preventDefault();
		if(this.state.textSearch2 !== "" ){
			this.onSearchProduct()
			this.setState({redirect: true});
		}else{
			 this.onTextTrue();
		}
	}

	highlightSearchText = (text, searchText) => {
		if (searchText === undefined || searchText === "")
			return text;
		let regexp = RegExp(searchText, 'gi')
		let foundIndex = text.search(regexp);
		let comp = [];
		while (foundIndex !== -1) {
			comp.push(text.substring(0, foundIndex));
			comp.push(<b>{text.substring(foundIndex, foundIndex + searchText.length)}</b>);
			text = text.substring(foundIndex + searchText.length);
			foundIndex = text.search(regexp);
		}
		comp.push(text);

		// mukodik case sensitive modon. a case insensitive mukodeshez kell egy kicsit meg reszelgetni
	/*		let array = text.split(searchText);
		let comp = [];
		for (let i = 0; i < array.length-1; i++) {
			comp.push(array[i]);
			comp.push(<b>{searchText}</b>);
		}
		comp.push(array[array.length-1]);*/
		return <>{comp}</>;
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		let companiesList = this.props.userData.availableCompanies.length > 0 ? this.props.userData.availableCompanies.map(
																	(o) => {
																			return (
																							 <div key={o.id} className="p-0">
																							 <MDBNavItem>
																								 <MDBNavLink link to={{pathname: "/ProductPage", state: { activeItem: o.id}}} active={this.props.location.state.activeItem === o.id} role="tab" >
																									 <MDBBtn
																											 className="product-filtercompany-button"
																											 flat
																											 onClick={() => this.props.userData.onSearchProductWithCompanyFilter(this.props.userData.productname, o.name)}>
																										<MDBRow className="m-0">
																											<MDBCol middle md="1" className="px-0 col-height text-left">
																											 <img alt="" className="profil-logo" src={this.state.activeItem === o.id ? companyicon_gelb : companyicon}></img>
																										 </MDBCol>
																										 <MDBCol middle  md="9" className="pl-2 pr-2 col-height text-left text-capitalize">
																											 <span className="profilpage-menu">{o.name}:</span>
																										 </MDBCol>
																											<MDBCol middle  md="2" className="p-0 text-right">
																											<span className="profilpage-menu">{o.count}</span>
																											</MDBCol>
																										 </MDBRow>
																									 </MDBBtn>
																								 </MDBNavLink>
																							 </MDBNavItem>
																							 </div>);
																		}
																) : {};

    return (
			<>
			<div ref={this.listInnerRefInterB2B}/>
			<MDBContainer fluid className="navbarcomponentRespo-nav-container-b2b">
				<div className={styles.bodywrapper3}>
				<div className="side-nav-menu-b2b">
				<MDBSideNav
				right
				triggerOpening={this.props.isSideNavOpen}
				onOverlayClick={this.props.handleSideNavToggle}
				breakWidth={1300}
				className="deep-purple darken-4"
				>
				<MenuSideNavB2BikeMain
					userData={this.props.userData}
					onMenuClose = {this.props.onEnableScroll}
					onItemClose = {this.props.onEnableScrollMain}
					activeItem = {1}
				/>
				</MDBSideNav>
				</div>
				<LandingPageB2BikeNavRespo2  activeItem = {"4"}
																				userData={this.props.userData}
																				onMenuOpen = {this.props.handleSideNavToggle}
																				onRefInterB2B={this.onRefInterB2B}/>

				<MDBCard className="product-description-card mb-3">
					<MDBRow className="m-0 pb-3">
					{this.props.userData.searchStatus ?
						<Link to={{pathname: "/ProductPage", state: { activeItem: this.state.activeItem}}}>
						<MDBBtn flat className="product-back-btn"
										><img src={back_icon}/><span className="text-capitalize">Back</span>
						</MDBBtn>
						</Link>:false}
					</MDBRow>
					<MDBRow className="m-0 pb-3">
						<MDBCol middle className="text-left p-0" md="auto" size="auto">
							<div className="avatar1 avatar2-card">
								<img alt="" className="photo" src={this.state.msg.detailPictureUrlHttps !== "" ? this.state.msg.detailPictureUrlHttps +'?' + Date.now(): noImage}></img>
								{this.state.msg.detailPictureUrlHttps === "" ?
								<span className="kein-bild">Kein Bild</span>:false}
							</div>
						</MDBCol>
						<MDBCol middle className="text-left" md="" size="9">
						<span className="product-description">{this.highlightSearchText(this.state.msg.description.value, this.props.userData.productname)}</span>
						</MDBCol>
					</MDBRow>
				</MDBCard>
				{this.state.currentCompany !== undefined ?
				<MyProfileB2BPublic
						currentCompany = {this.state.currentCompany}
						contactStatus = {true}
						userData = {this.props.userData}/>:false}
				</div>
			</MDBContainer>
{ this.state.redirect ?
		<Redirect to="/ProductPage"/>:
false}
</>
    );
  }
}

export default withTranslation()(CompanyPageWithProductResponziv);
