import React, { Component } from "react";

import { ReactComponent as SearchSvg } from "../../assets/media/icons/search.svg";
import checkdone from "../../assets/media/newicons/check_done.png";
import RatingPage from '../RatingPage/RatingPage';
import Cookies from 'universal-cookie';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import ReactStars from "react-rating-stars-component";
import{
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBContainer,
	MDBModalFooter,
	MDBBtn,
  MDBFileInput,
  MDBSelect,
	MDBRow
} from 'mdbreact';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const cookies = new Cookies();
var ertek = 0;

class FeedbackPage extends Component {
  state = {
            token : cookies.get('authtoken').token,
            myUserId: cookies.get('authtoken').userId,
						feedback: 0,
						};

		constructor(props) {
			super(props);
		}

  componentDidMount() {
  };

  setModalShow = () => {
    this.props.show();
  };

  setModalClose = () => {
    this.props.handleClose();
  };

	handlePickerValue = event => {
		 ertek = event;
	};

	onFeedbackChange = (editorState) => {
		if(this.props.interb2b){
			this.props.onFeedbackChange(editorState);
		}else{
			this.props.onFeedbackChange2(editorState);
		}
	}


  render() {
		const { t } = this.props;
    const client = this.props.client;
    return (
      <MDBContainer>
      <MDBModal isOpen={this.props.show} toggle={this.props.handleClose}>
        <MDBModalHeader toggle={this.props.handleClose}>
                  {t('feedback_geben')}
        </MDBModalHeader>
          <MDBModalBody>
					<CKEditor
                     editor={ ClassicEditor }
                     onChange={ ( event, editor ) => {
                         const data = editor.getData();
												 this.onFeedbackChange(data);
                     } }
                 />
					<ReactStars
				    count={5}
				    onChange={this.handlePickerValue}
				    size={24}
				    activeColor="#ffd700"
				  />

            </MDBModalBody>
            <MDBModalFooter>
						<MDBRow center>
							<MDBBtn	className="btn btn-done-feedback" onClick={() => this.props.handleSave(ertek)}><img src={checkdone} /> {t('feedback_senden')}</MDBBtn>
						</MDBRow>

            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
    );
  }
}
export default withTranslation()(FeedbackPage);
