import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { Link } from "react-router-dom";
import { ProfileType, UserStatus } from "../../../../Config/Config";
import Moment from 'moment';
import peoplewhiteicon from "../../../../assets/media/newicons/peoplewhite.png";
import peopleicon from "../../../../assets/media/newicons/people.png";
import InterB2BAxios from '../../../../Service/InterB2BAxios';
import {
  MDBListGroupItem,
  MDBRow,
  MDBCol,
  MDBLink
} from 'mdbreact';



class ChatMessageBike extends Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this, 'ChatMessageBike');
    this.state = {linkBackground: false
									};
  };

  componentDidMount() {
    this.onGetUser(this.props.partnerId);
    if(this.props.anzeigeId !== 0){
      this.onGetBike(this.props.anzeigeId);
    } else{
      this.onGetUser(this.props.partnerId);
    }
  };

  componentWillUnmount() {
	}

  onGetUser = (id) => {
    let self = this;
    this.interAxios.get('/api/user/anzeigeUserById/' + id, function(response) {
      self.setState({senderUser: response.data});
    })
  };
  onGetBike= (id) => {
		let self = this;
		this.interAxios.get('/api/bikeProductById/' + id, function(response) {
			self.setState({bikeProduct: response.data});
		})
	};

  onMdbLink = () => {
    this.setState({linkBackground: true});
    this.props.handleChatClick();
  }

  truncateString = (str, sum) => {
    if(str !== null){
      if (str.length <= sum) {
        //this.setState({buttonstatus: false});
        return str
      }
          //this.setState({buttonstatus: true});
          return str.slice(0, sum) + '...'

    }
  }


 render() {
   const { t } = this.props;
   const client = this.props.client;
   var isMyMessage = this.props.id == this.props.selectedChatroomId;
   let status = null;
   let tsmoment = Moment(Date.now());
   switch (this.props.status) {
     case UserStatus.Online:
       status = "user-online";
       break;
     case UserStatus.Offline:
       status = "user-offline";
       break;
     default:
       break;
   }


  return (
    <>

    <MDBListGroupItem
    	className={
        	"" +
			(this.props.chatroomId && window.location.href.search(this.props.chatroomId) > 0 ? "active " : " ") +
			(this.props.badge ? "unread" : "")
      	}
      style={{backgroundColor: (isMyMessage ? "#00A441" : ""), borderRadius: (isMyMessage ? "10px" : 0)}}
    >

    <MDBLink
        className="py-1"
        to={this.props.to}
        onClick={this.onMdbLink}
      >
      	<MDBRow className="m-0">
      		<MDBCol middle size="2" md="2" lg="3" className="text-left pl-1 pr-1 ">
            <div>
              {this.props.anzeigeId !== 0 ?
              <img alt="" className="photo" src={this.state.bikeProduct?.ebikeProductPhotosUrl[0] +'?' + Date.now()}></img>:
              <div>
                    {this.state.senderUser?.companyUser ?
                      <img alt="" className="user-photo-navbar" src={this.state.senderUser?.companyProfileUrl +'?' + Date.now()}></img>:<>
                        {this.state.senderUser?.profileUrl !== undefined ?
                          <img alt="" className="user-photo-navbar" src={this.state.senderUser?.profileUrl +'?' + Date.now()}></img>:
                          <div className="userProfileName-icon">{this.state.senderUser?.profilName}</div>}</>}
              </div>}
              <div className={status}/>
            </div>
      		</MDBCol>
      		<MDBCol middle size="10" md="10" lg="9" className="pr-0 pl-2">
            <MDBRow start className="m-0">
              <MDBCol middle className="px-0" md="7" size="8">
              {this.props.name.length>15 ?
                <span  className={isMyMessage ? "chat-name chat-name-white" : "chat-name"}>{this.truncateString(this.props.name, 15)}</span> :
                <span className={isMyMessage ? "chat-name chat-name-white" : "chat-name"}>{this.props.name}</span>}
              </MDBCol>
              <MDBCol middle className="pl-1 pr-0 text-right" md="3" size="3">
                {this.props.time !== null ?
                  <span className="chat-contacts-time">
                  {Moment(this.props.time).date() === tsmoment.date()?
                  <span>{Moment(this.props.time).format('h:mma')}</span>:
                  <span>{Moment(this.props.time).format('MM.DD.YY')} </span>} </span>:false}
              </MDBCol>
              <MDBCol middle className="pl-1 pr-0" md="2" size="1">
                {this.props.badge ?
                  <div className="badge badge-rounded badge-primary ml-1">
                    {this.props.badge}
                  </div>:false}
              </MDBCol>
            </MDBRow>
            <MDBRow start className="m-0">
    							<span  className={isMyMessage ? "chat-name-white" : ""}>{this.props.message === "Kein Nachrichten" ?
                    t('kein_nachrichten') :
                  this.props.message}</span>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBLink>
	</MDBListGroupItem>
{/*!isMyMessage ?
<div className="bottom"></div>:false*/}
</>
    );
  }
}
export default withTranslation()(ChatMessageBike);
