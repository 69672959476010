import React, { Component } from "react";
import { MDBPagination, MDBPageItem, MDBPageNav, MDBCol, MDBRow } from "mdbreact";

import Cookies from 'universal-cookie';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
const cookies = new Cookies();

class PaginationPage extends Component {

  constructor(props) {
    super(props);
    this.state = {token : cookies.get('authtoken').token,
                  myUserId: cookies.get('authtoken').userId,
                  aktuelleSeite: this.props.site + 1
									};
  };

  componentDidMount() {

  };

  onAktuelleSeite = (page) => {
    this.props.onWiederRufen(page)
    this.setState({aktuelleSeite: page+1});
  }


  render() {
    const { t } = this.props;
    const client = this.props.client;
  return (
    <MDBRow center>
        <MDBPagination circle>
          {this.props.site > 0 ?
          <MDBPageItem  onClick={() => this.onAktuelleSeite(0)}>
            <MDBPageNav className="page-link">
              <span>{t('anfang')}</span>
            </MDBPageNav>
          </MDBPageItem>:
          <MDBPageItem disabled>
            <MDBPageNav className="page-link">
              <span>{t('anfang')}</span>
            </MDBPageNav>
          </MDBPageItem>}
          {this.props.site > 0 ?
          <MDBPageItem  onClick={() => this.onAktuelleSeite(this.props.site - 1)}>
          <MDBPageNav className="page-link" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </MDBPageNav>
          </MDBPageItem>:
          <MDBPageItem disabled>
            <MDBPageNav className="page-link" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only">Previous</span>
            </MDBPageNav>
          </MDBPageItem>}
          <MDBPageItem active onClick={() => this.onAktuelleSeite(this.props.site)}>
            <MDBPageNav className="page-link">
              {this.props.site + 1} <span className="sr-only">(current)</span>
            </MDBPageNav>
          </MDBPageItem>
          {this.props.site + 2 < this.props.totalPages + 1 ?
          <MDBPageItem onClick={() => this.onAktuelleSeite(this.props.site + 1)}>
            <MDBPageNav className="page-link">
              {this.props.site + 2}
            </MDBPageNav>
          </MDBPageItem>:false}
          {this.props.site + 3 < this.props.totalPages + 1 ?
          <MDBPageItem onClick={() => this.onAktuelleSeite(this.props.site + 2)}>
            <MDBPageNav className="page-link">
              {this.props.site + 3}
            </MDBPageNav>
          </MDBPageItem>:false}

          {this.props.site + 1 < this.props.totalPages ?
          <MDBPageItem onClick={() => this.onAktuelleSeite(this.props.site + 1)}>
            <MDBPageNav className="page-link">
              &raquo;
            </MDBPageNav>
          </MDBPageItem>:
          <MDBPageItem disabled>
            <MDBPageNav>
              &raquo;
            </MDBPageNav>
          </MDBPageItem>}
          {this.props.site + 1 < this.props.totalPages ?
          <MDBPageItem onClick={() => this.onAktuelleSeite(this.props.totalPages - 1)}>
            <MDBPageNav className="page-link">
              {t('ende_pagination')}
            </MDBPageNav>
          </MDBPageItem>:
          <MDBPageItem disabled>
            <MDBPageNav className="page-link">
              {t('ende_pagination')}
            </MDBPageNav>
          </MDBPageItem>}
      </MDBPagination>
    </MDBRow>
  );
}
}

export default withTranslation()(PaginationPage);
