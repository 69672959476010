import React, { Component } from "react";
import CompanyPageWithProductDesktop from './CompanyPageWithProduct/CompanyPageWithProductDesktop';
import CompanyPageWithProductResponziv from './CompanyPageWithProduct/CompanyPageWithProductResponziv';

class CompanyPageWithProduct extends Component {

  constructor(props) {
    super(props);
    this.state = {msg: this.props.location?.state?.msg!== undefined ? this.props.location.state.msg : {},
    activeItem: this.props.location?.state?.activeItem!== undefined ? this.props.location.state.activeItem : "",
								};
  };

  render() {

    return (
			<>{!window.matchMedia('(max-device-width: 992px)').matches ?
				<div className="d-none d-lg-block">
					<CompanyPageWithProductDesktop userData={this.props.userData}
																	 addFunctionFromChild = {this.props.addFunctionFromChild}
																	 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																	 activeItem = {this.state.activeItem}
                 									 msg = {this.state.msg}
																	 {...this.props}
																	 />
				</div>:
				<div className="d-lg-none d-block">
					<CompanyPageWithProductResponziv userData={this.props.userData}
																		 addFunctionFromChild = {this.props.addFunctionFromChild}
																		 removeFunctionFromChild = {this.props.removeFunctionFromChild}
																		 activeItem = {this.state.activeItem}
                                     msg = {this.state.msg}
																		 {...this.props}
																		 />
				</div>}
			</>
    );
  }
}

export default CompanyPageWithProduct;
