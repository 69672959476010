import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import LazyLoad, { forceVisible } from 'react-lazyload';
import LazyLoadItem from '../../../../components/LazyLoad/LazyLoadItem';
import { Scrollbar } from "react-scrollbars-custom";

import InterB2BAxios from '../../../../Service/InterB2BAxios';
import NewsItem from '../../../../components/Companys/NewsItem';
import CompanysItemWithDesign from '../../../../components/Companys/CompanysItemWithDesign';
import BikeAnzeigeKarte from '../../../../components/Companys/BikeAnzeigeKarte';
import SonstigeNavB2B from '../../../../components/NavbarComponentB2B/SonstigeNavB2B';
import Footer from '../../../../components/Footer/Footer';
import Slider from '@mui/material/Slider';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from '../../../../assets/scss/modules/pages.module.scss';

import logo from "../../../../assets/media/logo/1.png";
import ratingicon from "../../../../assets/media/newicons/rating.png";
import searchnormalicon from "../../../../assets/media/images/markt/searchnormalicon.png";
import group_21 from "../../../../assets/media/images/markt/Group 21.png";
import addBike from "../../../../assets/media/images/markt/E-Bikebörse Logo 5.png";
import logo2 from "../../../../assets/media/images/markt/E-Bikebörse Logo 2.png";
import anmelden from "../../../../assets/media/images/markt/anmelden.png";
import signinmarkt from "../../../../assets/media/images/markt/signinmarkt.png";
import signupmarkt from "../../../../assets/media/images/markt/signupmarkt.png";
import logo3 from "../../../../assets/media/images/markt/Group 18.png";
import meinprofilicon from '../../../../assets/media/newicons/meinprofil.png';
import shadow from "../../../../assets/media/images/b2b/shadow.png";
import clearOffericon from "../../../../assets/media/newicons/clearOffer.png";
import arrowLeft from "../../../../assets/media/images/arrowLeft.png";
import companyicon from "../../../../assets/media/images/icons/Vector-3.png";
import trahsicon from "../../../../assets/media/newicons/trash.png";
import plusimgicon from "../../../../assets/media/images/b2b/addImgOrange.png";
import group_5 from "../../../../assets/media/images/b2b/Group 17.png";
const myregexp = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.*([^\s]{11})/gm;
import{
	MDBContainer,
  MDBCollapse,
	MDBCollapseHeader,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
	MDBTabPane,
	MDBTabContent,
	MDBNav,
	MDBAutocomplete,
	MDBSpinner,
	MDBIcon
} from 'mdbreact';

class NewsPageHandlerDesktop extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'NewsPageHandler');
    this.state = {selectedFiles: [], anzeigenlist : {}, videoLink: "", collapseID: "", activeItem: "1"
									};
  };


	componentDidMount() {
		this.props.addFunctionFromChild(this.onMessage);
		this.onGetAnzeigen();
	}

	componentWillUnmount() {
		this.props.removeFunctionFromChild(this.onMessage);
		this.interAxios.cancel();
	}

	onFileChange = (e) => {
    let list = this.state.selectedFiles;
    for (let i = 0; i < e.target.files.length; i++) {
      list.push(e.target.files[i]);
    }
    this.setState({selectedFiles: list});
  };

	onArticleChange = (editorState) => {
		let videoLink = '';
		let text = '';
		if (editorState.match(myregexp)){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(editorState.match(myregexp), 'text/html');
			text =parsedHtml.documentElement.textContent;

	    if (text.startsWith("https://www.youtube.com/watch")) {
				let newVideoLink = text.replace("watch?v=", "embed/");
				let andIndex = newVideoLink.indexOf("&");
				if (andIndex != -1) {
					videoLink = newVideoLink.substring(0, andIndex);
				} else {
					videoLink = newVideoLink;
				}
			} else {
				videoLink = text;
			}
			this.setState({videoLink: videoLink});
		}
		this.setState({article: editorState.replace(editorState.match(myregexp), '')});

	}
	renderPhotos = (source) => {
		return source.map((photo) => {
			return <div key={photo.url} >
						 <img style={{ height: '100px'}} src={photo.url} className="photos-content py-2"/>
						 <MDBBtn
						 			tag="a"
									className="m-0"
									floating
									color="elegant"
									onClick={() => this.onResetPhotos(photo.name)}
									style={{height: "auto", width: "auto", position: 'relative', top: '-27px', right: '14px', transform: 'translate(-50%)'}}>
			         <img src={trahsicon} style={{width: "60%"}} className="py-1"/>
			       </MDBBtn>
						 </div>
		})
	}

	onVideoLinkChange = (editorState) => {
	  this.setState({videoLink: editorState});
	}

	onTitleChange = (editorState) => {
	  this.setState({title: editorState});
	}

	onResetPhotos = (photo) => {
		var photos = [];
		for(let j = 0; j < this.state.selectedFiles.length; j++) {
			if(this.state.selectedFiles[j].name !== photo){
				photos.push(this.state.selectedFiles[j]);
			}
		}

		this.setState({selectedFiles: photos});
	}

	onResetVideoLink =() => {
	  this.setState({ videoLink: '', title: ''});
	}

	onResetArticle =() => {
	  this.setState({ article: '', title: ''});
	}

	onSelectedFilesDelete = () => {
		this.setState({selectedFiles: []});
	}

	onMessage = (message) => {
    let type = message.type;
		if (type === 'BUSINESS'){
	    this.onGetAnzeigen();
	  }

  };



	onAnzeige = (event) => {
	  let self = this;
		event.preventDefault();
	  const formData = new FormData
	    for (let i = 0; i < this.state.selectedFiles.length; i++) {
	      formData.append("photos", this.state.selectedFiles[i], this.state.selectedFiles[i].name);
	    }
	  formData.append("videoLink", this.state.videoLink);
	  formData.append("article", this.state.article);
	  formData.append("title", this.state.title);
	  this.setState({selectedFiles: [],
									 videoLink: '',
								   article: '',
								   title: ''});
		this.interAxios.post('/api/newAnzeige', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}, function(response) {
		})
	};

	onGetAnzeigen = () => {
	  let self = this;
	  this.interAxios.get('/api/anzeigen', function(response) {
	    self.setState({anzeigenlist: response.data});
			forceVisible();
	  })
	};

	onMoreGetAnzeigen = () => {
		let self = this;
		this.interAxios.get('/api/anzeigen', {params: {before: this.state.anzeigenlist[this.state.anzeigenlist.length-1].time}}, function(response) {
			let anzeigenlist = self.state.anzeigenlist;
			anzeigenlist.push(...response.data);
			self.setState({anzeigenlist: anzeigenlist});
		});
	}

	onAnzeigeWithSearchText = (searchText) => {
		let self = this;
		if(searchText !== ""){
			this.interAxios.get('/api/findanzeige', {params: {searchText: searchText}}, function(response) {
				self.setState({anzeigenlist: response.data});
			});
		}else{
			this.onGetAnzeigen();

		}

	}

	toggle = tab => e => {
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
				}
	}

	changeHandler = event => {
     this.setState({ [event.target.name]: event.target.value });
   };

	 onKey = (ev) => {
 		ev.preventDefault();
 		this.onAnzeigeWithSearchText(this.state.textSearch2);
 	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		var images = this.state.selectedFiles !== undefined ? this.state.selectedFiles.map((file) => ({url: URL.createObjectURL(file), name: file.name})) : [];
    return (
			<>

			<SonstigeNavB2B userData={this.props.userData} activeItem="1"/>

				<div className={styles.bodywrapper}>
							<MDBContainer style={{maxWidth: "1250px"}}>
								<MDBRow className="m-0">
									<MDBCol md="4" size="12">
										<div className="sticky-card-b2b">
											<MDBRow className="m-0 px-0 pb-3">
												<MDBNav className="search-tabs-b2b-news">
													<MDBNavItem>
														<MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab">
																Anbieten
														</MDBNavLink>
													</MDBNavItem>
													<MDBNavItem>
														<MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
																Suchen
														</MDBNavLink>
													</MDBNavItem>
												</MDBNav>
											</MDBRow>
											<MDBCard className="news-card-b2b">
												<MDBRow center className="m-0 pt-3">
													<MDBCol md="5" className="text-right pr-0" middle>
														<img className="group_5" src={group_5}></img>
													</MDBCol>
													<MDBCol md="7" middle>
														<MDBRow start className="m-0 pb-2">
															<span aria-hidden="true" className="newpost-1">New</span>
														</MDBRow>
														<MDBRow start className="m-0 pt-2">
															<span aria-hidden="true" className="newpost-2">Anzeige</span>
														</MDBRow>
													</MDBCol>
												</MDBRow>
												<MDBTabContent activeItem={this.state.activeItem} className="px-0 py-0">
													<MDBTabPane tabId="1" role="tabpanel">

														<MDBCardBody>
														<Scrollbar
															id="scrollbar-b2b"
															style={{ width: "100%", height: "409px"}}
															maximalThumbYSize = {150}
															noScrollX = {true}
															>
														<form className="row g-3 needs-validation m-0" noValidate>
															<div className="col-md-12 p-0">
																<div className="form-outline-b2b-news">
																		<label htmlFor="validationCustom01" className="form-label pt-2">Titel des Eintrags*</label>
																		<input type="text" className="form-control text-muted" name="title" onChange={this.changeHandler} value={this.state.title} id="validationCustom01" required />
																		<div className="invalid-feedback">Bitte geben Sie den Titel an!</div>
																</div>
															</div>
															<div className="col-md-12 p-0">
																<div className="form-outline-b2b-news pt-2">
																	<label htmlFor="validationCustom03" className="form-label pt-2">Ausführliche Beschreibung</label><br/>
																	<MDBRow className="m-0 p-0">
																		<MDBCol md="auto" className="pl-0 pr-1">
																			<MDBIcon icon="info-circle" />
																		</MDBCol>
																		<MDBCol className="pl-1 pr-0">
																			<p className="form-anmerkung">Wenn deine Beschreibung YouTube Links enthält, werden die Videos unterhalb der Beschreibung angezeigt.</p>
																		</MDBCol>
																	</MDBRow>
																	<div className="ckeditor-padding">
																	<CKEditor
																		editor={ ClassicEditor }
																		data={this.state.article}
																		config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
																		onChange={
																			( event, editor ) => {
																				const data = editor.getData();
																				this.onArticleChange(data);
																			}
																		}
																	/>
																	</div>
																</div>
															</div>
															{this.state.videoLink !== "" ?
															 <div className="col-md-12 p-0">
																 <div className="form-outline-markt pt-0">
																	 <br/><iframe src={this.state.videoLink} allowFullScreen autoPlay className="photos-content"></iframe>
																	 <MDBBtn
																			 tag="a"
																			 className="m-0"
																			 floating
																			 color="elegant"
																			 onClick={this.onResetVideoLink}
																			 style={{height: "auto", width: "auto", position: 'relative', top: '-130px', right: '14px', transform: 'translate(-50%)'}}>
																		<img src={trahsicon} style={{width: "60%"}} className="py-1"/>
																	</MDBBtn>
																</div>
															 </div>:false}
															<div className="col-md-12 p-0">
																<div className="form-outline-markt pt-0">
																	<p className="form-label">{t('anhange_zu_ihrem_beitrag')}</p>
																	<div className="form-row ml-2 mr-0 align-items-center">
																		{this.renderPhotos(images)}
																		<div className="file-field md-form m-0">
																			<span className="btn-bild-hinzu plusimage-icon text-center">
																				<img src={plusimgicon} style={{width: "80%", paddingTop: "0.8rem"}} />
																				<input multiple type="file" accept=".jpg,.jpeg,.png" onChange={this.onFileChange}/>
																			</span>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-12">
																<MDBRow center className="p-0">
																	<button className="btn btn-done-b2b-news"
																					type="submit"
																					onClick={(event) => this.onAnzeige(event)} >
																					Senden
																	</button>
																</MDBRow>
															</div>
														</form>
														</Scrollbar>
														</MDBCardBody>
													</MDBTabPane>
													<MDBTabPane tabId="2" role="tabpanel">
													<div className="product-search-b2b-anzeigepage">
														<form className= "form-inputs" >
															<MDBRow center className="p-0 m-0">
																<MDBCol top md="10" className="px-0" middle>
																	<input
																		onKeyPress={(ev) => {
																				if (ev.key === "Enter") {
																					this.onKey(ev);
																				}
																		}}
																		type="text"
																		className="form-control"
																		id="formGroupExampleInput2"
																		placeholder={t('produkt_eingeben_3')}
																		name="textSearch2"
																		onChange={this.changeHandler}
																		value={this.state.textSearch2}
																	/>
																</MDBCol>
																<MDBCol top md="2" className="pr-0" >
																	<MDBBtn type="button"
																					className="borse-btn-b2b-small"
																					onClick={() => this.onAnzeigeWithSearchText(this.state.textSearch2)}
																					><img src={searchnormalicon} className="vector-1"/>
																	</MDBBtn>
																</MDBCol>
															</MDBRow>
														</form>
													</div>
													</MDBTabPane>
												</MDBTabContent>
											</MDBCard>
											<MDBRow center>
											<div style={{width: "80%"}}>
											<Footer/>
											</div>
											</MDBRow>
										</div>
									</MDBCol>
									<MDBCol md="8" size="12" className="text-center">
									<MDBRow className="pl-3">
									<img className="blog-shadow" src={shadow}/>
										{this.state.anzeigenlist.length > 0 ? this.state.anzeigenlist.map(
											(o, index) =>
											<MDBCol md="6" size="12" className="p-0" style={{width: "750px"}}>
												<LazyLoad key={index} height={200} offset={[-100, 0]}>
													<LazyLoadItem index={index} size={this.state.anzeigenlist.length} loadFunction={this.onMoreGetMitteilungen}>
															<NewsItem
																searchText = {this.state.textSearch2}
																key={o.id}
																msg={o}
															/>
														</LazyLoadItem>
											 </LazyLoad>
											 </MDBCol>
										):false}
									</MDBRow>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
				</div>
		</>
    );
  }
}

export default  withTranslation()(NewsPageHandlerDesktop);
