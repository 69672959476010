import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import CompaniesList from '../Companys/CompaniesList';
import InterB2BAxios from '../../Service/InterB2BAxios';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'

import{
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBCardFooter,
	MDBMedia,
	MDBListGroup,
	MDBRow,
	MDBCol,
	MDBBtn,
	MDBCollapse,
	MDBScrollbar,
	MDBIcon
} from 'mdbreact';

import Cookies from 'universal-cookie';

const cookies = new Cookies();


class Companies extends Component {
  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
	  this.state = {companieslist: [],
								  statusText: ''};

  };
  componentDidMount() {
	this.props.addFunctionFromChild(this.onMessage);
		//this.interAxios.refreshUserData(this.props.userData);
	 this.onAllCompanies();
  };

	componentWillUnmount() {
    this.props.removeFunctionFromChild(this.onMessage);
		this.interAxios.cancel();
  }

	onMessage = (message) => {
		if (message.type === 'FOLLOW'){ // nekunk szol...
			this.onAllCompanies();
		}
	}

	onAllCompanies = () => {
		this.setState({companieslist: []});
		let self = this;
		this.interAxios.get('/api/firstForNotFollowedCompanies', function(response)
		{
					self.setState({companieslist: response.data});
		});
	};

	toggleCollapse = collapseID => () => {
	  this.setState(prevState => ({
	    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
	  }));
	}

  render() {
		if (this.props.userData.getCurrentCompany() === undefined)
			return false;
		const { t } = this.props;
    const client = this.props.client;
		let companyiesLis = this.state.companieslist.filter(o => (o.partner === "Supplier"));
		let firstCompanies = [];
		let lastCompanies = [];
		let firstCompaniesLis = [];
		let lastCompaniesLis = [];
		if(companyiesLis.length>3){
			for (let i = 0; i < 3; i++){
				firstCompanies.push(companyiesLis[i]);
			}
			for (let i = 3; i < 4; i++){
				lastCompanies.push(companyiesLis[i]);
			}
			firstCompaniesLis = firstCompanies.map(
				(o) =>
								<CompaniesList
								 key={o.id}
								 msg={o}
								 statusText={this.state.statusText}/>
			);
			lastCompaniesLis = lastCompanies.map(
				(o) =>
								<CompaniesList
								 key={o.id}
								 msg={o}
								 statusText={this.state.statusText}/>
			);
		}else{
			firstCompaniesLis = companyiesLis.map(
				(o) =>
								<CompaniesList
								 key={o.id}
								 msg={o}
								 statusText={this.state.statusText}/>
			);
		}
    return(

<MDBCard className="interb2b-card netzwerk-card">
	<MDBCardHeader className="text-center"
		style={{ backgroundImage: `url(${this.props.userData.getCurrentCompany()?.backgroundUrl+ '?' + this.props.timeStamp})`,
			width: '100%',
			minHeight: '50px',
			backgroundSize: 'cover'}}>
	</MDBCardHeader>
	<MDBCardBody className="p-0">
		<MDBRow>
			<MDBCol className="text-center">
				<img style={{ width: '50px',
					height: '50px', 'marginTop': '-20px',
					border: '2px solid #ffffff'}}
					src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + this.props.timeStamp}
					className="rounded-circle"/>
			</MDBCol>
		</MDBRow>
			<h4 className="card-title text-center">{t('ihr_netzwerk_verwalten')}</h4>
			<MDBListGroup className="center-col" style={{maxHeight: "435px"}}>
				{firstCompaniesLis}
				{lastCompanies.length>0 ?
				<MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
					{lastCompaniesLis}
				</MDBCollapse>:false}
			</MDBListGroup>
	</MDBCardBody>
	<MDBCardFooter className="p-0"  style={{borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px"}}>
		{this.state.collapseID ?
			<div className="text-center">
		{!this.props.userData.isGastAdmin() ?
			<Link to={'/FollowsPage'}><small className="text-capitalize font-weight-lighter text-center" style={{color: "#0360EC"}}>{t('alle_ansehen')}</small></Link>:
			<small className="text-capitalize font-weight-lighter text-muted text-center">{t('alle_ansehen')}</small>}<br/>
			</div>:false}
		{lastCompanies.length>0 ?
			<MDBBtn flat
				className='btn-block'
				onClick={this.toggleCollapse("basicCollapse")}
			>
				{this.state.collapseID ?
				<MDBIcon icon="chevron-up" />
				:
				<MDBIcon icon="chevron-down" />}
			</MDBBtn>:<small>&nbsp;</small>}

	</MDBCardFooter>
</MDBCard>


      );
  	}
  }
export default withTranslation()(Companies);
