import React, { Component, Fragment } from "react";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
import foto2 from "../../assets/media/fotos/2.jpg";
import LightboxPageToMitteilungen from '../LightboxPage/LightboxPageToMitteilungen';
import ImageManager from './ImageManager';
import MapBox from '../MapBox/MapBox';
import Moment from 'moment';
import 'moment/locale/de';
import logo from "../../assets/media/logo/1.png";

import trahsicon from "../../assets/media/newicons/trash.png";
import checkdone from "../../assets/media/newicons/check_done.png";
import arrowcirclelefticon from "../../assets/media/newicons/arrow-circle-left2.png";
import galleryicon from "../../assets/media/newicons/gallery.png";
import plusimgicon from "../../assets/media/newicons/plusImg.png";
import editicon from "../../assets/media/newicons/editProfil.png";
import logouticon from "../../assets/media/newicons/logout.png";
import ib2bicon from "../../assets/media/images/ib2b.png";
import styles from '../../assets/scss/modules/pages.module.scss';
import Footer from '../Footer/Footer';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';


import{
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOptions,
	MDBSelectOption,
	MDBScrollbar,
  MDBContainer,
	MDBCardTitle
} from 'mdbreact';

import InterB2BAxios from '../../Service/InterB2BAxios';

const cookies = new Cookies();

class MyManagement extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
		this.state = { };
		let self = this;
		this.interAxios.get('/api/preis/paketpreis', function(response) {
			self.setState({
				preisList: response.data
			});
			for (const packet of response.data){
				if(packet.packet === "basicMonatlich"){
					self.setState({
						basicMonatlich: packet.preis
					});
				}
				if(packet.packet === "basicJahrlich"){
					self.setState({
						basicJahrlich: packet.preis
					});
				}
				if(packet.packet === "premiumMonatlich"){
					self.setState({
						premiumMonatlich: packet.preis
					});
				}
				if(packet.packet === "premiumJahrlich"){
					self.setState({
						premiumJahrlich: packet.preis
					});
				}
			}

		});
  };

	componentDidMount() {
    this.props.userData.refreshNavbar();
		this.props.userData.onMySubscription();
		//this.interAxios.refreshUserData(this.props.userData);
  }

	componentWillUnmount() {
		this.interAxios.cancel();
	}

	onCustomerPortal = () => {
		let self = this;
		this.interAxios.get('/ecommerce/stripe-customer-portal', function(response) {
			window.open(response.data);
		});
	}


  render() {
		const { t } = this.props;
		const client = this.props.client;
		const textbasic = t('sie_können_das_abonnement_hier_andern')
		const textbm = t('jeden_monat_werden_ihrem_konto') + this.state.basicMonatlich + t('euro_abgezogen')
		const textbj = t('jedes_jahr_werden_ihrem_konto') + this.state.basicJahrlich*12 + t('euro_abgezogen')
		const textpremium = t('sie_können_das_abonnement_hier_andern')
		const textpm = t('jeden_monat_werden_ihrem_konto') + this.state.premiumMonatlich + t('euro_abgezogen')
		const textpj = t('jedes_jahr_werden_ihrem_konto') + this.state.premiumJahrlich*12 + t('euro_abgezogen')
		console.log(this.props.userData.getMySubscription());
    return (

      	<div className={styles.bodywrapper}>
					<MDBContainer>
							<MDBCard className="interb2b-card p-2 m-0" style={{backgroundColor: "transparent"}}>
									<MDBRow center className="px-4">

										<MDBCol md="4">
											<MDBRow center>
												<h4>{t('abonnement_verwalten')}</h4>
											</MDBRow>
											<MDBRow center>
											<img className="netzwerk-ib2b pt-4" style={{width: "50%"}} src={ib2bicon}/>
											</MDBRow>
										</MDBCol>
									</MDBRow>
									<MDBRow center>
										{this.props.userData.getMySubscription()?.currentPackage ==="bm" ?
										<MDBCard className="price-card final">
										<MDBCardTitle className="py-0">
										<MDBRow start className="p-3">
											<MDBCol md="6">
												<img
												src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
												className="photo rounded"
												alt=""
												/>
											</MDBCol>
											<MDBCol middle md="6" >
												 <h3 className="unternehmen-text text-capitalize">{this.props.userData.getCurrentCompany()?.name}</h3>
											</MDBCol>
										</MDBRow>
										</MDBCardTitle>
										<MDBCardBody className="text-center pb-3">
											<h1>{t('basic_monatlich')}</h1>
											<div className="price">
												<span className="currency">€</span>
												<span className="value">{this.state.basicMonatlichJahrlich}</span>
												<span className="period">/mo</span><br/>
												<span className="period">{t('gültig_bis')}</span>
												<span className="period">{Moment(this.props.userData.getMySubscription()?.gultigBis).locale('de').format('DD MMMM YYYY')}</span><br/>
											</div>
										</MDBCardBody>
										</MDBCard>:false}
										{this.props.userData.getMySubscription()?.currentPackage ==="pm" ?
										<MDBCard className="price-card final">
										<MDBCardTitle className="py-0">
										<MDBRow start className="p-3">
										<MDBCol md="6">
												<img
												src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
												className="photo rounded"
												alt=""
												/>
											</MDBCol>
											<MDBCol middle md="6" >
												 <h3 className="unternehmen-text text-capitalize">{this.props.userData.getCurrentCompany()?.name}</h3>
											</MDBCol>
										</MDBRow>
										</MDBCardTitle>
										<MDBCardBody className="text-center pb-3">
											<h1>{t('premium_monatlich')}</h1>
											<div className="price">
												<span className="currency">€</span>
												<span className="value">{this.state.premiumMonatlich}</span>
												<span className="period">/mo</span><br/>
												<span className="period">{t('gültig_bis')}</span>
												<span className="period">{Moment(this.props.userData.getMySubscription()?.gultigBis).locale('de').format('DD MMMM YYYY')}</span><br/>
											</div>
										</MDBCardBody>
										</MDBCard>:false}
										{this.props.userData.getMySubscription()?.currentPackage ==="bj" ?
										<MDBCard className="price-card final">
										<MDBCardTitle className="py-0">
										<MDBRow start className="p-3">
										<MDBCol md="6">
												<img
												src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
												className="photo rounded"
												alt=""
												/>
											</MDBCol>
											<MDBCol middle md="6" >
												 <h3 className="unternehmen-text text-capitalize">{this.props.userData.getCurrentCompany()?.name}</h3>
											</MDBCol>
										</MDBRow>
										</MDBCardTitle>
										<MDBCardBody className="text-center pb-3">
											<h1>{t('basic_jahrlich')}</h1>
											<div className="price">
												<span className="currency">€</span>
												<span className="value">{this.state.basicJahrlich}</span>
												<span className="period">/mo</span><br/>
												<span className="period">{t('gültig_bis')}</span>
												<span className="period">{Moment(this.props.userData.getMySubscription()?.gultigBis).locale('de').format('DD MMMM YYYY')}</span><br/>
											</div>
										</MDBCardBody>
										</MDBCard>:false}
										{this.props.userData.getMySubscription()?.currentPackage ==="pj" ?
										<MDBCard className="price-card final">
										<MDBCardTitle className="py-0">
										<MDBRow start className="p-3">
										<MDBCol md="6">
												<img
												src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
												className="photo rounded"
												alt=""
												/>
											</MDBCol>
											<MDBCol middle md="6" >
												 <h3 className="unternehmen-text text-capitalize">{this.props.userData.getCurrentCompany()?.name}</h3>
											</MDBCol>
										</MDBRow>
										</MDBCardTitle>
											<MDBCardBody className="text-center pb-3">
												<h1>{t('premium_jahrlich')}</h1>
												<div className="price">
													<span className="currency">€</span>
													<span className="value">{this.state.premiumJahrlich}</span>
													<span className="period">/mo</span><br/>
													<span className="period">{t('gültig_bis')}</span>
													<span className="period">{Moment(this.props.userData.getMySubscription()?.gultigBis).locale('de').format('DD MMMM YYYY')}</span><br/>
												</div>
											</MDBCardBody>
										</MDBCard>:false}
									</MDBRow>
									<MDBRow center className="pt-2">
										<MDBCol md="2">
										</MDBCol>
										<MDBCol md="8" className="px-4">
										{this.props.userData.getMySubscription()?.currentPackage ==="bm" ?
										<>
										<p className="text-center font-weight-bolder px-4">{textbm}</p>
										<p className="text-center font-weight-bolder px-4">{textbasic}</p></>:false}
										{this.props.userData.getMySubscription()?.currentPackage ==="bj" ?
										<>
										<p className="text-center font-weight-bolder px-4">{textbj}</p>
										<p className="text-center font-weight-bolder px-4">{textbasic}</p></>:false}
										{this.props.userData.getMySubscription()?.currentPackage ==="pm" ?
										<>
										<p className="text-center font-weight-bolder px-4">{textpm}</p>
										<p className="text-center font-weight-bolder px-4">{textpremium}</p></>:false}
										{this.props.userData.getMySubscription()?.currentPackage ==="pj" ?
										<>
										<p className="text-center font-weight-bolder px-4">{textpj}</p>
										<p className="text-center font-weight-bolder px-4">{textpremium}</p></>:false}

										</MDBCol>
										<MDBCol md="2">
										</MDBCol>
									</MDBRow>
									<MDBRow center className="p-4">
											<MDBBtn type="button"
															className="management-btn"
															rounded
															size ="sm"
															onClick={this.onCustomerPortal}>Abonnement verwalten
											</MDBBtn>
									</MDBRow>
							</MDBCard>
					<Footer homeStatus={false}/>
			</MDBContainer>
			</div>
    );
  }
}

export default withTranslation()(MyManagement);
